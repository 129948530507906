import React, { useCallback, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonBackButton,
  IonButtons,
  IonPage,
  IonText,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonIcon,
  IonCheckbox,
  IonInput,
  IonTextarea,
  IonButton,
  IonSelect,
  IonSelectOption,
  IonLoading,
  IonChip,
} from "@ionic/react";
// import "./NewNote.css";
import { chevronBack, chevronDown, add, close } from "ionicons/icons";
import CryptoJS from 'crypto-js';
import api from "src/api";
import { RouteComponentProps } from "react-router";
import moment from "moment";
import momentTZ from "moment-timezone";
import calendargold from "src/icons/calendargold.svg";
import CustDatePicker from "src/components/CustDatePicker/CustDatePicker";
import IMask from "imask";
import dollar from "src/icons/dollar.svg";
import {Device,Plugins } from "@capacitor/core";
// import { useHistory } from "react-router-dom";
import AttendeeSearch from "./AttendeeSearch";
import useWindowDimensions from "src/components/useWindowDimensions";
import * as Yup from "yup";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";
const { Storage } = Plugins;

const styles = {
  toolbar: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
  },
  headerRow: {
    marginTop: 24,
    marginLeft: 32,
  },
  headerRowOpt: {
    marginTop: 24,
    marginBottom: 8,
  },
  arbHeaderRow: {
    marginTop: 8,
    marginLeft: 32,
  },
  engName: {
    fontWeight: 300,
    fontSize: 28,
    lineHeight: "120%",
    color: "#828282",
  },
  arbName: {
    fontWeight: 400,
    fontSize: 18,
    lineHeight: "120%",
    color: "#828282",
    fontFamily: "Almarai",
  },
  reqText: {
    fontWeight: 700,
    fontSize: 14,
    color: "#FFFFFF",
  },
  formHeader: {
    fontWeight: 400,
    fontSize: 22,
    lineHeight: "120%",
    color: "#AAAAAA",
  },
  formHeaderSmall: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "120%",
    color: "#AAAAAA",
    marginTop: 10,
    marginLeft: 5,
  },
  fieldLabel: {
    fontWeight: 400,
    fontSize: 17,
    color: "#FFFFFF",
  },
  optionLabel: {
    fontWeight: 400,
    fontSize: 14,
    color: "#C7C7C7",
  },
  optionItem: {
    marginLeft: -16,
    marginBottom: -12,
  },
  checkIcon: {
    "--background-checked": "#A5D2DC",
    "--checkmark-color": "#222222",
    "--checkmark-width": 3,
    "--border-color-checked": "#A5D2DC",
    height: 20,
    width: 20,
    marginInlineEnd: 8,
  },
  radioIcon: {
    "--color-checked": "#A5D2DC",
    marginInlineEnd: 8,
  },
  dateItem: {
    "--background": "#222222",
    marginBottom: 2,
    borderRadius: 0,
    overflow: "visible",
    "--padding-start": "8px",
  },
  inputItem: {
    "--background": "#222222",
    marginBottom: 2,
    width: "100%",
    marginTop: 8,
    height: 32,
    "--min-height": "100px",
  },
  textAreaRow: {
    marginTop: 8,
    marginLeft: 32,
    marginRight: 32,
  },
  textAreaRowOpt: {
    marginTop: 8,
    marginLeft: 27,
    marginRight: 27,
  },
  textArea: {
    "--background": "#222222",
    // height: 102,
    padding: 10,
    color: "#828282",
    fontWeight: 400,
    fontSize: 14,
  },
  acordHeader: {
    color: "#B99855",
    fontWeight: 400,
    fontSize: 17,
    lineHeight: "120%",
  },
  addIcon: {
    color: "#B99855",
    size: 16,
    marginRight: 5,
  },
  dropdown: {
    marginTop: 5,
    width: "90%",
    "--background": "#222222",
    marginBottom: 2,
    borderRadius: 8,
  },
  textbox: {
    marginTop: 5,
    width: "90%",
    "--background": "#222222",
    marginBottom: 24,
    borderRadius: 0,
    "--padding-start": "15px",
  },
  //   chip: {
  //     backgroundColor: "#A5D2DC",
  //     color: "#222222",
  //     fontWeight: "400",
  //     fontSize: 12,
  //     marginTop: "auto",
  //     marginBottom: "auto"
  // }
};
interface InpProps
  extends RouteComponentProps<{
    contactId?: any;
  }> {}

const optionsA = {
  cssClass: "my-custom-interface",
};
const NewEvent: React.FC<InpProps> = ({ location, history, match }) => {
  const [openNextStep, setOpenNextStep] = React.useState(true);

  const [maintitle, setmaintitle] = useState<string>("New Event");

  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [togglesearch, settogglesearch] = useState<any>(false);
  const [cntactids, setcontactids] = useState<any>([]);
  const [cntactnames, setcntactnames] = useState<any>([]);
  const [cntactemail, setcntactemail] = useState<any>([]);
  const [optcntactids, setoptcontactids] = useState<any>([]);
  const [optcntactnames, setoptcntactnames] = useState<any>([]);
  const [optcntactemail, setoptcntactemail] = useState<any>([]);
  const [fromdueDate, setfromdueDate] = useState<any>();
  const [todueDate, settodueDate] = useState<any>();
  const [isOpt, setisOpt] = useState<boolean>(false);
  const [EditReminders, setEditReminders] = useState<any>([]);
  const [evntId, seteventId]= useState<any>();

  const [backtext, setbacktext] = useState<string>("Calendar");

  useEffect(() => {


    if (location.state) {
      let locState:any = location.state
      console.log(locState.contId);
      Device.getInfo().then(deviceInfo => {
        getUserEmail().then(useremail => {
          if(deviceInfo.platform == "web")
              {
                window.globalThis.firebase.analytics().setUserId(useremail);
                window.globalThis.firebase.analytics().logEvent('select_content', {
                user_name: useremail,
                content_type: 'NewMeeting',
                item_id: locState.contId,
                device_id:deviceInfo?deviceInfo.uuid:"",
                platform:deviceInfo?deviceInfo.platform:"",
                device_model:deviceInfo?deviceInfo.model:"",
                device_type:deviceInfo?deviceInfo.osVersion:"",
                device_os:deviceInfo?deviceInfo.operatingSystem:"",
                  });
    
              }
             else
              {
                FirebaseAnalytics.setUserId(useremail);
                FirebaseAnalytics.logEvent("select_content", {user_name: useremail});
                FirebaseAnalytics.logEvent("select_content", {content_type: 'NewMeeting'});
                FirebaseAnalytics.logEvent("select_content", {item_id: locState.contId});
                FirebaseAnalytics.logEvent("select_content", {device_id: deviceInfo?deviceInfo.uuid:""});
                FirebaseAnalytics.logEvent("select_content", {platform: deviceInfo?deviceInfo.platform:""});
                FirebaseAnalytics.logEvent("select_content", {device_model: deviceInfo?deviceInfo.model:""});
                FirebaseAnalytics.logEvent("select_content", {device_type:deviceInfo?deviceInfo.osVersion:""});
                FirebaseAnalytics.logEvent("select_content", {device_os:deviceInfo?deviceInfo.operatingSystem:""});
              }
        })
        })

      let a: any = location.state;
       
      if (a.eventInfo) {
        // console.log(moment(a.eventInfo.start).format("HH:mm:ss"))
        // console.log(moment(a.eventInfo.end).format("HH:mm:ss"))
        setmaintitle("Edit Event");
        seteventId(a.eventInfo.engId)
        formik.setFieldValue("dueDate", moment(a.eventInfo.start).format("YYYY-MM-DD"));
        formik.setFieldValue("dueTimeStart", moment(a.eventInfo.start).format("HH:mm:ss"));
        formik.setFieldValue("dueTimeEnd", moment(a.eventInfo.end).format("HH:mm:ss"));
        formik.setFieldValue("eventTitle", a.eventInfo.title);
        formik.setFieldValue("eventDesc", a.eventInfo.body ? a.eventInfo.body.replace(/(<([^>]+)>)/ig,"") : "");
        let attArr = a.eventInfo.name.split(",")
        // console.log(attArr)
        setcntactnames(attArr)
        setcontactids(attArr)
        formik.setFieldValue("reqdAttendees", attArr);
        let emailArr = a.eventInfo.email ? a.eventInfo.email.split(",") : ""
        setcntactemail(emailArr)
      }

      if (a.contactName) {
        if (a.from && a.from === "Todo") {
          setbacktext("To-Do");
        } else if (a.from && a.from === "Dashboard") {
          setbacktext("Dashboard");
        } else if (a.source === "Client") {
          setbacktext("Client Profile");
        } else if (a.source === "Prospect") {
          setbacktext("Prospect Profile");
        } else {
          setbacktext("Prospect Profile");
        }
        setcontactids([a.contId]);
        setcntactnames([a.contactName]);
        setcntactemail([a.contEmail])
        formik.setFieldValue("reqdAttendees", a.contactName);
        setfromdueDate(
          convertDateTime(moment().format("YYYY-MM-DD"), "10:00 AM")
        );
        settodueDate(
          convertDateTime(moment().format("YYYY-MM-DD"), "10:30 AM")
        );
      }
    }
  }, [location.state]);

  const toggleNextStep = () => {
    setOpenNextStep(!openNextStep);
  };
  const timePickerOptions: any = [
    {val: "0:00 AM",fVal:"00:00:00"},
    {val: "0:30 AM",fVal:"00:30:00"},
    {val: "1:00 AM",fVal:"01:00:00"},
    {val: "1:30 AM",fVal:"01:30:00"},
    {val: "2:00 AM",fVal:"02:00:00"},
    {val: "2:30 AM",fVal:"02:30:00"},
    {val: "3:00 AM",fVal:"03:00:00"},
    {val: "3:30 AM",fVal:"03:30:00"},
    {val: "4:00 AM",fVal:"04:00:00"},
    {val: "4:30 AM",fVal:"04:30:00"},
    {val: "5:00 AM",fVal:"05:00:00"},
    {val: "5:30 AM",fVal:"05:30:00"},
    {val: "6:00 AM",fVal:"06:00:00"},
    {val: "6:30 AM",fVal:"06:30:00"},
    { val: "7:00 AM",fVal:"07:00:00"},
    { val: "7:30 AM",fVal:"07:30:00"},
    { val: "8:00 AM",fVal:"08:00:00"},
    { val: "8:30 AM",fVal:"08:30:00" },
    { val: "9:00 AM",fVal:"09:00:00" },
    { val: "9:30 AM",fVal:"09:30:00" },
    { val: "10:00 AM",fVal:"10:00:00"},
    { val: "10:30 AM",fVal:"10:30:00"},
    { val: "11:00 AM",fVal:"11:00:00"},
    { val: "11:30 AM" ,fVal:"11:30:00" },
    { val: "12:00 PM",fVal:"12:00:00" },
    { val: "12:30 PM",fVal:"12:30:00" },
    { val: "1:00 PM",fVal:"13:00:00" },
    { val: "1:30 PM",fVal:"13:30:00" },
    { val: "2:00 PM",fVal:"14:00:00" },
    { val: "2:30 PM",fVal:"14:30:00" },
    { val: "3:00 PM",fVal:"15:00:00" },
    { val: "3:30 PM",fVal:"15:30:00" },
    { val: "4:00 PM",fVal:"16:00:00" },
    { val: "4:30 PM",fVal:"16:30:00" },
    { val: "5:00 PM",fVal:"17:00:00" },
    { val: "5:30 PM",fVal:"17:30:00" },
    { val: "6:00 PM",fVal:"18:00:00" },
    { val: "6:30 PM",fVal:"18:30:00" },
    { val: "7:00 PM",fVal:"19:00:00" },
    { val: "7:30 PM",fVal:"19:30:00" },
    { val: "8:00 PM",fVal:"20:00:00" },
    { val: "8:30 PM",fVal:"20:30:00" },
    { val: "9:00 PM",fVal:"21:00:00" },
    { val: "9:30 PM",fVal:"21:30:00" },
    { val: "10:00 PM",fVal:"22:00:00" },
    { val: "10:30 PM",fVal:"22:30:00" },
    { val: "11:00 PM",fVal:"23:00:00" },
    { val: "11:30 PM",fVal:"23:30:00" },
    { val: "12:00 PM",fVal:"24:00:00" },
    ];

  const decrypt = (decryptVlaue) => {
      let passphrase = "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9"
      const bytes = CryptoJS.AES.decrypt(decryptVlaue, passphrase);
      const originalText = bytes.toString(CryptoJS.enc.Utf8);
      return originalText;
  };

  const getUserId = async () => {
    const ret: any = await Storage.get({ key: "userId" });
    const temp = JSON.parse(decrypt(ret?.value));
    return temp?.id;
  };

  const getUserEmail = async () => {
    const ret: any = await Storage.get({ key: "userId" })
    const temp = JSON.parse(decrypt(ret.value))
    return temp && temp.email ? temp.email : null
}

  const [user, setUser] = React.useState<any>();
  // const history = useHistory();

  getUserId().then((userID) => {
    setUser(userID);
  });

  const triggerSearch = () => {
    //history.push('/newevent/attendeesearch')
    settogglesearch(true);
    // console.log(formik.values.eventDesc);
  };
  const opttriggerSearch = () => {
    //history.push('/newevent/attendeesearch')
    setisOpt(true);
    settogglesearch(true);
    // console.log(formik.values.eventDesc);
  };

  const validate = (values) => {
    const errors: any = {};
    // if (!values.eventTitle) {
    //   errors.eventTitle = "(Required)";
    // }
    // if (!values.meetingNote) {
    //   errors.meetingNote = "(Required)";
    // }
    return errors;
  };
  // const convertDateTime = (date, time) => {
  //   let dateTimeStr = date + " " + time;
  //   let DateTimeDt = new Date(dateTimeStr);
  //   let dateTimeUTC = DateTimeDt.toUTCString();
  //   let isounixVal = parseInt(moment(dateTimeUTC).format("x"));
  //   return isounixVal;
  // };

  const convertDateTime = (date, time) => {
    let dateTimeStr = date + "T" + time;
    return dateTimeStr;
  };

  function GetUserTimeZoneID(){
    var timezone=String(new Date());
     return timezone.substring(timezone.lastIndexOf('(')+1).replace(')','').trim();
     }

  function CancelEvent(evntId){
    getUserEmail().then(useremail => { 
    api.cancelCalendarEvent(evntId,useremail).then((resp) => {
      // console.log(JSON.stringify(resp));
      history.push("/calendar");
      // setLoading(false);
    }).catch((e) => console.log(JSON.stringify(e)))
    })
    }
  

  const formik = useFormik({
    // validate,
    initialValues: {
      eventTitle: "",
      // dueDate: moment(currDate).format("YYYY-MM-DD"),
      dueDate: moment().format("YYYY-MM-DD"),
      dueTimeStart: "10:00:00",
      dueTimeEnd: "10:30:00",
      eventDesc: "",
      reqdAttendees: "",
      emailRemdate: moment().format("YYYY-MM-DD"),
      emailRemtime: "10:00:00",
    },
    validationSchema: Yup.object().shape({
      eventTitle: Yup.string()
        .max(255)
        .required("Enter the Title for the Event"),
      reqdAttendees: Yup.string().required(
        "Select atleast one attendee for the meeting"
      ),
    }),
    onSubmit: (values) => {
      // console.log("values", values)
      setLoading(true);
      //console.log(cntactids);

      if (maintitle === "New Event") {
        // const taskPayload = {
        //   engagement: {
        //     active: true,
        //     type: "MEETING",
        //     ownerId: user,
        //   },
        //   associations: {
        //     //contactIds: [new Function('return '+cntactids)()],
        //     contactIds: [...cntactids, ...optcntactids],
        //     companyIds: [],
        //     dealIds: [],
        //     ownerIds: [user],
        //     ticketIds: [],
        //     reminders: [
        //       convertDateTime(
        //         moment(values.emailRemdate).format("YYYY-MM-DD"),
        //         values.emailRemtime
        //       ),
        //     ],
        //   },
        //   metadata: {
        //     body: values.eventDesc,
        //     startTime: fromdueDate,
        //     endTime: todueDate,
        //     title: values.eventTitle,
        //   },
        // };
        // api.createTask(taskPayload).then((resp) => {
        //   // console.log(resp);
        //   history.push("/calendar");
        //   setLoading(false);
        // });

        let arrLen = cntactnames.length
        let attendeeArr : any = [] 
        for(var i = 0; i < arrLen; i++)
        {
          attendeeArr.push(
            {
              "emailAddress": {
                "address":cntactemail[i],
                "name": cntactnames[i]
              },
              "type": "required"
            }
          )
        }
        
       
        // var timezone = GetUserTimeZoneID();
        var timezone = momentTZ.tz.guess()
        getUserEmail().then(useremail => { 
        const calendarEventPayload = {
          "subject": values.eventTitle,
          "body": {
                    "contentType": "HTML",
                    "content": values.eventDesc
          },
          "start": {
              "dateTime": fromdueDate,
              "timeZone": timezone
          },
          "end": {
              "dateTime": todueDate,
              "timeZone": timezone
          },
          "attendees": attendeeArr,
          "allowNewTimeProposals": false,
        }

        // console.log(calendarEventPayload)
        api.createCalendarEvent(calendarEventPayload, useremail).then((resp) => {
          // console.log(JSON.stringify(resp));
          history.push("/calendar");
          setLoading(false);
        })
        .catch((e) => {
          console.log(JSON.stringify(e))
          setLoading(false);
        })

      })
      } else {
        // const patchtaskPayload = {
        //   engagement: {
        //     ownerId: user,
        //     type: "MEETING",
        //     active: true,
        //   },
        //   associations: {
        //     contactIds: [...cntactids, ...optcntactids],
        //     reminders: [
        //       convertDateTime(
        //         moment(values.emailRemdate).format("YYYY-MM-DD"),
        //         values.emailRemtime
        //       ),
        //     ],
        //   },
        //   metadata: {
        //     body: values.eventDesc,
        //     startTime: fromdueDate,
        //     endTime: todueDate,
        //     title: values.eventTitle,
        //   },
        // };
        // let a: any = location.state;
        // api
        //   .patchTask(a.engInfo.engagement.id, patchtaskPayload)
        //   .then((resp) => {
        //     // console.log(resp);
        //     history.push("/calendar");
        //     setLoading(false);
        //   });
        let arrLen = cntactnames.length
        let attendeeArr : any = [] 
        for(var i = 0; i < arrLen; i++)
        {
          attendeeArr.push(
            {
              "emailAddress": {
                "address":cntactemail[i],
                "name": cntactnames[i]
              },
              "type": "required"
            }
          )
        }
        
       
        // var timezone = GetUserTimeZoneID();
        var timezone = momentTZ.tz.guess()
        getUserEmail().then(useremail => { 
        const calendarEventUpdatePayload = {
          "subject": values.eventTitle,
          "body": {
                    "contentType": "HTML",
                    "content": values.eventDesc
          },
          "start": {
              "dateTime": convertDateTime(values.dueDate, values.dueTimeStart),
              "timeZone": timezone
          },
          "end": {
              "dateTime": convertDateTime(values.dueDate, values.dueTimeEnd),
              "timeZone": timezone
          },
          "attendees": attendeeArr,
          "allowNewTimeProposals": false,
        }

        // console.log(calendarEventUpdatePayload)
        api.updateCalendarEventDetail(useremail,evntId,calendarEventUpdatePayload).then((resp) => {
          // console.log(JSON.stringify(resp));
          history.push("/calendar");
          setLoading(false);
        })
        .catch((e) => {
          console.log(JSON.stringify(e))
          setLoading(false);
        })

      })
        
      }
    },
  });
  // console.log("form value", formik.values);

  useEffect(() => {
    if (cntactids.length === 0) {
      formik.setFieldError(
        "reqdAttendees",
        "Select atleast one attendee for the meeting"
      );
      formik.setFieldTouched("reqdAttendees", true);
    } else {
      formik.setFieldError("reqdAttendees", undefined);
      formik.setFieldTouched("reqdAttendees", false);
    }
  }, [cntactids]);

  function retrievedData(returnval) {
    //  console.log(returnval);
    settogglesearch(false);
    setfromdueDate(convertDateTime(returnval[2], returnval[3]));
    settodueDate(convertDateTime(returnval[2], returnval[4]));
    let tempcontid = cntactids;
    let opttempcontid = optcntactids;
    let tempcontnames = cntactnames;
    let opttempcontnames = optcntactnames;
    let tempcontemail = cntactemail ? cntactemail : []
    let opttempcontemail = optcntactemail ? optcntactemail : [];
    // if (returnval[6]) {
    //   isOpt ? opttempcontid.push(returnval[6]) : tempcontid.push(returnval[6]);
    // }
    if (returnval[10]) {
      // console.log(tempcontemail)
      isOpt ? opttempcontemail.push(returnval[10]) : tempcontemail.push(returnval[10]);
      // console.log(tempcontemail)
    }
    if (returnval[7]) {
      if (returnval[9]) {
        isOpt
          ? opttempcontnames.push(returnval[7] + " " + returnval[9])
          : tempcontnames.push(returnval[7] + " " + returnval[9]);
      } else
        isOpt
          ? opttempcontnames.push(returnval[7])
          : tempcontnames.push(returnval[7]);
    }
    //setcontactids(returnval[6]?tempcontid?tempcontid+','+returnval[6]:'':'');
    //setcontactids(tempcontid?tempcontid+','+returnval[6]:returnval[6]?returnval[6]:'');
    setcontactids(tempcontid);
    setoptcontactids(opttempcontid);
    setcntactnames(tempcontnames);
    setoptcntactnames(opttempcontnames);
    setcntactemail(tempcontemail);
    setoptcntactemail(opttempcontemail);
    formik.setFieldValue("reqdAttendees",returnval[7])
    formik.values.reqdAttendees =
      returnval[5] + returnval[7]
        ? returnval[9]
          ? returnval[7] + " " + returnval[9]
          : returnval[7]
        : "-" + ",";
    if (isOpt) {
      setisOpt(false);
    }
  }

  function deletechip(val) {
    let indexval = cntactids.indexOf(val.toString());

    // console.log(val.toString())
    let temp = [...cntactids];
    let temp1 = [...cntactnames];
    temp.splice(indexval, 1);
    temp1.splice(indexval, 1);
    setcontactids(temp);
    // console.log(temp1)
    setcntactnames(temp1);
    // cntactids.pop(indexval);
    // cntactnames.pop(indexval);
  }
  function optdeletechip(val) {
    let indexval = optcntactids.indexOf(val.toString());
    // console.log(val.toString())
    let temp = [...optcntactids];
    let temp1 = [...optcntactnames];
    temp.splice(indexval, 1);
    temp1.splice(indexval, 1);
    setoptcontactids(temp);
    setoptcntactnames(temp1);
    // cntactids.pop(indexval);
    // cntactnames.pop(indexval);
  }

  function revertBack() {
    settogglesearch(false);
  }
  const { height, width } = useWindowDimensions();
  return togglesearch ? (
    <AttendeeSearch
      isOpt={isOpt}
      retrievedData={retrievedData}
      revertBack={revertBack}
      cntactids={cntactids}
      eventtitle={formik.values.eventTitle}
      eventDesc={formik.values.eventDesc}
      dueDate={formik.values.dueDate}
      dueTimeStart={formik.values.dueTimeStart}
      dueTimeEnd={formik.values.dueTimeEnd}
      reqdAttendees={formik.values.reqdAttendees}
    />
  ) : (
    <IonPage>
      <IonLoading
        cssClass="my-custom-loading-class"
        isOpen={isLoading}
        message={"Please wait..."}
      />
      <IonHeader>
        <IonToolbar mode="md" style={styles.toolbar}>
          <IonButtons slot="start">
            <IonBackButton
              style={{ textTransform: "none" }}
              color="white"
              text={backtext}
              icon={chevronBack}
            ></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form className="newevent-content" onSubmit={formik.handleSubmit}>
          <IonRow class="mobile-margin-right" style={styles.headerRow}>
            <IonCol>
              {/* <IonText class="new-event-desktop" style={styles.engName}>{maintitle}</IonText> */}
              <span className="to-do" style={{ fontWeight: 300, fontSize: 28, color:"GrayText", whiteSpace:"nowrap" }}>{maintitle}</span>
            </IonCol>
          </IonRow>
          <IonRow class="mobile-margin-right" style={styles.headerRow}>
            <IonCol>
              <IonText class="event-info-desktop" style={styles.formHeader}>Event Information</IonText>
            </IonCol>
          </IonRow>
          <IonRow class="mobile-margin-right" style={styles.headerRow}>
            <IonCol size-xs="12" size-sm="6" style={{ padding: 0 }}>
              <IonRow style={{ marginBottom: 6 }}>
                <IonLabel style={styles.fieldLabel}>* Event Title</IonLabel>
                {formik.errors.eventTitle && formik.touched.eventTitle ? (
                  <IonText color="danger" style={{ marginLeft: 10 }}>
                    {formik.errors.eventTitle}
                  </IonText>
                ) : null}
              </IonRow>
              <IonRow>
                <IonInput
                  style={styles.textbox}
                  placeholder="Enter a title for the event"
                  name="eventTitle"
                  mode="md"
                  value={formik.values.eventTitle}
                  onIonChange={formik.handleChange}
                  spellcheck={true}
                  autocapitalize="sentences"
                ></IonInput>
              </IonRow>
            </IonCol>
            <IonCol size-xs="12" size-sm="6"></IonCol>
          </IonRow>
          <IonRow class="mobile-margin-right m-marginright-0 m-margintop-4" style={styles.headerRow}>
            <IonLabel style={styles.fieldLabel}>* Date & Time</IonLabel>
          </IonRow>
          <IonRow class="mobile-margin-right m-margintop-4" style={styles.headerRow}>
            <IonCol
              size-xs="12"
              sizeSm="6"
              sizeLg="4"
              style={{ paddingLeft: 0 }}
            >
              <IonItem className="m-marginrightn4" lines="none" style={styles.dateItem}>
                <CustDatePicker
                  dateFormat="dddd, LL"
                  date={formik.values.dueDate}
                  onSetDateVal={(val) => {
                    formik.setFieldValue(
                      "dueDate",
                      moment(val).format("YYYY-MM-DD")
                    );
                    settodueDate(
                      convertDateTime(
                        moment(val).format("YYYY-MM-DD"),
                        formik.values.dueTimeEnd
                      )
                    );
                    setfromdueDate(
                      convertDateTime(
                        moment(val).format("YYYY-MM-DD"),
                        formik.values.dueTimeStart
                      )
                    );
                  }}
                />
                <IonIcon
                  style={{ width: 24, height: 24 }}
                  src={calendargold}
                  slot="end"
                ></IonIcon>
              </IonItem>
            </IonCol>
            <IonCol
              size-xs="12"
              sizeSm="2"
              sizeLg="2"
              className="nxttimepickerCol"
            >
              <IonItem className="m-marginleftn4 m-marginrightn4" lines="none" style={styles.dateItem}>
                <IonSelect
                  interface={width < 576 ? "action-sheet" : "popover"}
                  value={formik.values.dueTimeStart}
                  onIonChange={(e) => {
                    formik.setFieldValue("dueTimeStart", e.detail.value);
                    setfromdueDate(
                      convertDateTime(
                        moment(formik.values.dueDate).format("YYYY-MM-DD"),
                        e.detail.value
                      )
                    )
                    let endVal :  any = null;
                    for(var i in timePickerOptions){
                      if(timePickerOptions[parseInt(i)].fVal === e.detail.value){  
                        endVal = timePickerOptions[parseInt(i)+1].fVal
                      }  
                    }
                    formik.setFieldValue("dueTimeEnd",endVal);
                    settodueDate(
                      convertDateTime(
                        moment(formik.values.dueDate).format("YYYY-MM-DD"),endVal
                      )
                    );
                  }}
                  name="dueTimeStart"
                  interfaceOptions={optionsA}
                  mode="ios"
                >
                  {/* {timePickerOptions.map(({ val }, i) => (
                    <IonSelectOption key={val} value={val}>
                      {val}
                    </IonSelectOption>
                  ))} */}
                  {timePickerOptions.map((val , i) => (
                    <IonSelectOption key={i} value={val.fVal}>
                      {val.val}
                    </IonSelectOption>
                  ))}
                </IonSelect>
                <IonIcon
                  slot="end"
                  color="gold"
                  style={{
                    position: "absolute",
                    right: 10,
                  }}
                  src={chevronDown}
                ></IonIcon>
              </IonItem>
            </IonCol>
            <IonCol
              size-xs="1"
              sizeSm="1"
              sizeLg="1"
              style={{ display: "flex" }}
            >
              <IonText style={{ margin: "auto" }}>To</IonText>
            </IonCol>
            <IonCol
              size-xs="12"
              sizeSm="2"
              sizeLg="2"
              className="nxttimepickerCol"
            >
              <IonItem className="m-marginleftn4 m-marginrightn4" lines="none" style={styles.dateItem}>
                <IonSelect
                  interface={width < 576 ? "action-sheet" : "popover"}
                  value={formik.values.dueTimeEnd}
                  onIonChange={(e) => {
                    formik.setFieldValue("dueTimeEnd", e.detail.value);
                    settodueDate(
                      convertDateTime(
                        moment(formik.values.dueDate).format("YYYY-MM-DD"),
                        e.detail.value
                      )
                    );
                  }}
                  name="dueTimeEnd"
                  interfaceOptions={optionsA}
                  mode="ios"
                >
                  {/* {timePickerOptions.map(({ val }, i) => (
                    <IonSelectOption key={val} value={val}>
                      {val}
                    </IonSelectOption>
                  ))} */}
                  {timePickerOptions.filter((v)=>v.fVal > formik.values.dueTimeStart).map((val , i) => (
                    <IonSelectOption key={i} value={val.fVal}>
                      {val.val}
                    </IonSelectOption>
                  ))}
                </IonSelect>
                <IonIcon
                  slot="end"
                  color="gold"
                  style={{
                    position: "absolute",
                    right: 10,
                  }}
                  src={chevronDown}
                ></IonIcon>
              </IonItem>
            </IonCol>
            {/* <IonCol sizeSm="3" sizeLg="3"></IonCol> */}
          </IonRow>

          {maintitle === "New Event" ||
          (maintitle === "Edit Event" && EditReminders.length > 0) ? (
            // <React.Fragment>
            //   <IonRow class="mobile-margin-right" style={styles.headerRow}>
            //     <IonLabel style={styles.fieldLabel}>Email Reminder</IonLabel>
            //   </IonRow>
            //   <IonRow class="mobile-margin-right" style={styles.headerRow}>
            //     <IonCol
            //       size-xs="12"
            //       sizeSm="6"
            //       sizeLg="4"
            //       style={{ paddingLeft: 0 }}
            //     >
            //       <IonItem lines="none" style={styles.dateItem}>
            //         <CustDatePicker
            //           dateFormat="LL"
            //           date={formik.values.emailRemdate}
            //           onSetDateVal={(val) => {
            //             formik.setFieldValue(
            //               "emailRemdate",
            //               moment(val).format("YYYY-MM-DD")
            //             );
            //           }}
            //         />
            //         <IonIcon
            //           style={{ width: 24, height: 24 }}
            //           src={calendargold}
            //           slot="end"
            //         ></IonIcon>
            //       </IonItem>
            //     </IonCol>
            //     <IonCol
            //       size-xs="12"
            //       sizeSm="2"
            //       sizeLg="2"
            //       className="nxttimepickerCol"
            //     >
            //       <IonItem lines="none" style={styles.dateItem}>
            //         <IonSelect
            //           interface={width < 576 ? "action-sheet" : "popover"}
            //           value={formik.values.emailRemtime}
            //           onIonChange={formik.handleChange}
            //           name="emailRemtime"
            //           interfaceOptions={optionsA}
            //           mode="ios"
            //         >
            //           {/* {timePickerOptions.map(({ val }, i) => (
            //             <IonSelectOption key={val} value={val}>
            //               {val}
            //             </IonSelectOption>
            //           ))} */}
            //           {timePickerOptions.map((val , i) => (
            //         <IonSelectOption key={i} value={val.fVal}>
            //           {val.val}
            //         </IonSelectOption>
            //       ))}
            //         </IonSelect>
            //         <IonIcon
            //           slot="end"
            //           color="gold"
            //           style={{
            //             position: "absolute",
            //             right: 10,
            //           }}
            //           src={chevronDown}
            //         ></IonIcon>
            //       </IonItem>
            //     </IonCol>
            //     <IonCol size-xs="12" sizeSm="4" sizeLg="4"></IonCol>
            //   </IonRow>
            // </React.Fragment>
            null
          ) : null}

          <IonRow class="mobile-margin-right" style={styles.headerRow}>
            <IonLabel style={styles.fieldLabel}>Description</IonLabel>
          </IonRow>
          <IonRow class="desktop-margin" style={styles.textAreaRow}>
            <IonTextarea
              style={styles.textArea}
              placeholder="Enter a description of the event"
              value={formik.values.eventDesc}
              onIonChange={formik.handleChange}
              rows={6}
              name="eventDesc"
              spellcheck={true}
              autocapitalize="sentences"
            ></IonTextarea>
          </IonRow>
          <IonRow class="mobile-margin-right" style={styles.headerRow}>
            <IonLabel style={styles.fieldLabel}>
              Invite Required Attendees
            </IonLabel>
            {formik.errors.reqdAttendees && formik.touched.reqdAttendees ? (
              <IonText color="danger" style={{ marginLeft: 10 }}>
                {formik.errors.reqdAttendees}
              </IonText>
            ) : null}
          </IonRow>
          <IonRow class="desktop-margin desk-inv-req-att" style={styles.textAreaRow}>
            <IonItem
            class="mw-78"
              onClick={triggerSearch}
              lines="none"
              style={styles.inputItem}
            ></IonItem>
            {cntactnames
              ? cntactnames.map((val, i) => (
                  <IonChip key={i}>
                    <IonLabel custom-id={cntactids[i]}>{val}</IonLabel>
                    <IonIcon
                      onClick={(event) => {
                        deletechip(
                          (event.target as HTMLInputElement).getAttribute(
                            "custom-id"
                          )
                        );
                      }}
                      custom-id={cntactids[i]}
                      icon={close}
                    />
                  </IonChip>
                ))
              : null}
          </IonRow>
          {/* <IonRow>
              <IonCol>
                {openNextStep ? (
                  <React.Fragment>
                      <IonItem onClick={toggleNextStep} style={{ marginRight: 32 }}>
                        <IonIcon src={add} style={styles.addIcon}></IonIcon>
                        <IonText style={styles.acordHeader}>
                          Add Optional Attendees
                        </IonText>
                      </IonItem>
                  </React.Fragment>
                ) : (
                    <React.Fragment>
                      <IonItem onClick={toggleNextStep} style={{ marginRight: 32 }} lines="none">
                        <IonIcon src={close} style={styles.addIcon}></IonIcon>
                        <IonText style={styles.acordHeader}>Close Optional Attendees Section</IonText>
                        {formik.errors.eventTitle ? (
                          <IonText style={{ color: "red", marginLeft: 10 }}>
                            {formik.errors.eventTitle}
                          </IonText>
                        ) : null}
                      </IonItem>
                      <IonRow style={styles.textAreaRowOpt}>
                      <IonRow style={styles.headerRowOpt}>
                        <IonLabel style={styles.fieldLabel}>
                          Invite Optional Attendees
                      </IonLabel>
                      </IonRow>
                        <IonItem onClick={opttriggerSearch} lines="none" style={styles.inputItem}>
                        </IonItem>
                        {
                          optcntactnames ? optcntactnames.map((val, i) => (
                            <IonChip key={i}>
                              <IonLabel custom-id={optcntactids[i]}>{val}</IonLabel>
                              <IonIcon onClick={(event) => { optdeletechip((event.target as HTMLInputElement).getAttribute('custom-id')) }} custom-id={optcntactids[i]} icon={close} />
                            </IonChip>
                          )) : null
                        }
                      </IonRow>
                    </React.Fragment>
                  )}
              </IonCol>
            </IonRow> */}
          <IonRow>
            <IonCol>
              <IonItem lines="none"></IonItem>
            </IonCol>
          </IonRow>
          <IonRow >
            <IonButton
              type="submit"
              class="ion-float-left customformbtn"
              style={{
                fontSize: 12,
                borderColor: "#B99855",
                marginLeft: 32,
              }}
              color="#ffffff"
            >
              <IonText class="ion-text-capitalize" color="gold">
                {maintitle === "New Event" ? "Create Event" : "Save Event"}
              </IonText>
            </IonButton>  
            {maintitle === "Edit Event" ? 
            (<IonButton
              class="ion-float-left customformbtn"
              style={{
                fontSize: 12,
                borderColor: "#B99855",
                marginLeft: 32,
              }}
              color="#ffffff"
              onClick={() => {
                CancelEvent(evntId)
              }}
            >
              <IonText class="ion-text-capitalize" color="gold">
                Cancel Event
              </IonText>
            </IonButton>):null
            }
            <IonButton
              type="reset"
              class="ion-float-left customformbtn"
              onClick={() => {
                // history.push("/calendar")
                history.goBack();
              }}
              style={{
                fontSize: 12,
                border: "none",
                marginLeft: 32,
              }}
              color="#ffffff"
            >
              <IonText class="ion-text-capitalize" color="gold">
                Cancel
              </IonText>
            </IonButton>
          </IonRow>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default NewEvent;
