import {
  IonButton,
  IonButtons,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonText,
  IonToolbar,
} from "@ionic/react";
import { chevronBack } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import Segments from "src/components/Segments/Segments";
import ProspectGeo from "./ProspectGeo/ProspectGeo"
import { RouteComponentProps } from "react-router";
import { useHistory } from "react-router-dom";

// import ClientGeo from "./ClientGeo/ClientGeo"

const styles = {
  toolbar: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
    height: 75,
  },
};

const segs = [
  {
    label: "Prospects",
    value: "prospects",
  },
  //  {
  //   label: "Clients",
  //   value: "clients",
  // }, 
]

const retComp = (segVal,countryarr,countrydatesarr) => {
  
  switch (segVal) {
    case "prospects":
      return <ProspectGeo type="prospect" countrydatesarr={countrydatesarr} countryarr={countryarr}/>;
    case "clients":
      return <ProspectGeo type="client" countrydatesarr={countrydatesarr} countryarr={countryarr}/>;
    default:
      return null
  }
};

interface GeoProps extends RouteComponentProps<{

}> {}

const Geographies: React.FC<GeoProps> = ({location}) => {
  const hist = useHistory()
   const [selSeg, setselSeg] = useState<any>("");
   const [countryarr, setcountryarr] = useState<any>([]);
   const [countrydatesarr, setcountrydatesarr] = useState<any>([]);
   const [backtext, setbacktext] = useState<any>("Prospect Dashboard");

   useEffect(() => {
    let a: any = location.state
    // console.log(a)
    if(a?.source){
      if(a.source === "Client"){
        setselSeg("clients")
        setbacktext("Clients Dashboard")
      }
      else{
        setselSeg("prospects")
        setbacktext("Prospects Dashboard")
      }
    }
    if(a?.countryarr){
      setcountryarr(a.countryarr)
    }
    if(a?.ProspCntryDate)
    {
      setcountrydatesarr(a.ProspCntryDate)
    }
   },[location.state])

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode="md" style={styles.toolbar}>
          <IonButtons slot="start">
            <IonButton
              style={{ textTransform: "none" }}
              color="white"
              // routerLink="/dashboard"
              onClick={() => {
                hist.push(`/dashboard`, {to: selSeg});
            }}
            >
              <IonIcon icon={chevronBack}></IonIcon>
              {backtext}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow class="margin-set" style={{ marginTop: 24, marginLeft: 32 }}>
            <IonText
            class="fs-28"
              style={{ fontWeight: 300, fontSize: 28 }}
              color="greyscale"
            >
              Geographies
          </IonText>
        </IonRow>
          <Segments buttons={segs}
          startbtn={selSeg}
          selectedValue={(val) => {
            setselSeg(val);
            val === "prospects" ? setbacktext("Prospects Dashboard") : setbacktext("Clients Dashboard") 
          }} />
        </IonGrid>
        {retComp(selSeg,countryarr,countrydatesarr)}
      </IonContent>
    </IonPage>
  )
}
export default Geographies