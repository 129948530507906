import { IonPage, IonHeader, IonToolbar, IonSearchbar, IonContent, IonGrid, IonRow, IonCol, IonButton, IonText, IonItem, IonIcon, IonLabel } from '@ionic/react'
import React, { useState, useEffect } from 'react'
import CryptoJS from 'crypto-js';
import { useHistory } from "react-router-dom";
//import TopTargetSearchResults from "./TopTargetSearchResult";
import "./TopTargetSearch.css"
import personstar from 'src/icons/personstar.svg'
import api from 'src/api';
import useWindowDimensions from "src/components/useWindowDimensions";
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics';
import { Plugins } from "@capacitor/core";
const { Storage, Device } = Plugins


const styles = {
    btncol: {
        display: "flex"
    },
    btn: {
        marginTop: "auto",
        marginBottom: "auto"
    },
    header: {
        marginLeft: 32,
        fontWeight: 300
    },
    personlabel: {
        marginLeft: 8
    },
    item: {
        paddingRight: 16,
        paddingLeft: 16
    }
}

const decrypt = (decryptVlaue) => {
    let passphrase = "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9"
    const bytes = CryptoJS.AES.decrypt(decryptVlaue, passphrase);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  };
  
const getUserId = async () => {
    const ret: any = await Storage.get({ key: "userId" })
    const temp = JSON.parse(decrypt(ret.value))
    return temp.id
}
const getUserEmail = async () => {
    const ret: any = await Storage.get({ key: "userId" })
    const temp = JSON.parse(decrypt(ret.value))
    return temp && temp.email ? temp.email : null
}
interface InpProps {
    srchQry: string,
    clrSrch: boolean,
    resetClrSrch: Function,
}

const ProspectSearch: React.FC = () => {

    const history = useHistory()
    const [srchQry, setsrchQry] = useState<string>("")
    const [clrSrch, setclrSrch] = useState<boolean>(false)
    const [results, setResults] = useState<any>([])
    const [deviceInfo, setdeviceInfo] = useState<any>();
    const resetClrSrch = () => {
        setclrSrch(false)
    }
    const handleChange = (e) => {
        // console.log(e.target.value)
        //setsrchQry(e.target.value)
        //if(e.keyCode === 13)
        if(e.target.value.length >= 3)
        {
            setsrchQry(e.target.value);
            setTimeout(() => {
                const htmlele: HTMLInputElement | null = document.querySelector(
                    ".searchbar-input"
                  );
                  htmlele?.focus();
            }, 0);
        }
    }

    const handleClear = () => {
        setclrSrch(true)
    }

    useEffect(() => {
        Device.getInfo().then(info => {
            setdeviceInfo(info)
        })
    }, [])

    useEffect(() => {
        if (srchQry !== "") {
            let payload: any = { "query": srchQry, properties: ["firstname,lastname,middle_name,id"] }
            const getSearchRes = async () => {
                // return api.filterProspects(payload);                
                const uId = await getUserId()
                let filterGroups = [
                    {
                        filters: [
                            {
                                operator: "EQ",
                                propertyName: "hubspot_owner_id",
                                value: parseInt(uId)
                            },
                            {
                                operator: "NOT_HAS_PROPERTY",
                                propertyName: "hs_lifecyclestage_customer_date",
                            }
                        ]
                    }
                ]
                api.getTTfromStore().then(targArr => {
                    api.searchProspect({ ...payload, filterGroups })
                        .then((response) => {
                            setResults(response.results.filter(o => {
                                let retval = true
                                for(const a of targArr) {
                                    if(a.contactid === parseInt(o.id)){
                                        retval = false
                                    }
                                }
                                return retval
                            }))
                        })
                        .catch((error) => {
                            console.error("Error from API", error);
                            throw (error);
                        });
                })
            }
            getSearchRes()
        } else {
            setResults([])
        }
    }, [srchQry])

    useEffect(() => {
        if (clrSrch) {
            setResults([])
            resetClrSrch()
        }
    }, [clrSrch, resetClrSrch])

    function addRecord(contId) {
        //Device.getInfo().then(info => {
        getUserEmail().then(useremail => { 
        getUserId().then(userId => {
            let updContArray: any = [];
            api.getTTfromStore().then(resp => {
                updContArray = resp;
                // console.log(resp);
                updContArray.push({ "contactid": Number(contId), "rank": (resp.length) + 1 });
                // console.log(updContArray);
                let payloads: any = {
                    "rm_id": userId,
                    // "created_date": "2020-12-19T09:38:26",
                    // "updated_date": "2020-12-10T11:40:08",
                    //"prospect_list": "[{contactid:18651,rank:3},{contactid:18951,rank:1},{contactid:19001,rank:2}]"
                    "prospect_list": JSON.stringify(updContArray)
                }
                api.postTopTargets(payloads).then((resp) => {
                    // console.log("updContArray",updContArray)
                    // console.log("payloads",payloads)
                    let temp = updContArray !== "" && updContArray !== null ? updContArray : []
                    api.setTopTargetsinStorage(JSON.stringify(temp)).then(() => {
                        // console.log("Inside storage set then")
                    if(deviceInfo.platform == "web")
                    {
                            window.globalThis.firebase.analytics().setUserId(useremail);
                            window.globalThis.firebase.analytics().logEvent('select_content', {
                            user_name: useremail,
                            content_type: 'AddTopTarget',
                            item_id: Number(contId),
                            device_id:deviceInfo?deviceInfo.uuid:"",
                            platform:deviceInfo?deviceInfo.platform:"",
                            device_model:deviceInfo?deviceInfo.model:"",
                            device_type:deviceInfo?deviceInfo.osVersion:"",
                            device_os:deviceInfo?deviceInfo.operatingSystem:"",
                          });
                    }
                    else
                    {
                        FirebaseAnalytics.setUserId(useremail);
                        FirebaseAnalytics.logEvent("select_content", {user_name: useremail});
                        FirebaseAnalytics.logEvent("select_content", {content_type: 'AddTopTarget'});
                        FirebaseAnalytics.logEvent("select_content", {item_id: Number(contId)});
                        FirebaseAnalytics.logEvent("select_content", {device_id: deviceInfo?deviceInfo.uuid:""});
                        FirebaseAnalytics.logEvent("select_content", {platform: deviceInfo?deviceInfo.platform:""});
                        FirebaseAnalytics.logEvent("select_content", {device_model: deviceInfo?deviceInfo.model:""});
                        FirebaseAnalytics.logEvent("select_content", {device_type:deviceInfo?deviceInfo.osVersion:""});
                        FirebaseAnalytics.logEvent("select_content", {device_os:deviceInfo?deviceInfo.operatingSystem:""});

                    }
                       
                        history.push("/prospects/targets")
                    })
                })
            })
        })
    })
        //})
    }
    const { height, width } = useWindowDimensions();
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar
                class="mobile-toolbar-top"
                    style={{ "--background": "#1b1b1b" }}
                >
                    <IonGrid
                        className="ion-margin-horizontal"
                    >
                        <IonRow className="searchbar-pros-client">
                             {deviceInfo && deviceInfo.platform === "web" && width > 1024 ? 
                            <IonCol sizeLg="1"/> : ""}
                            <IonCol sizeLg="9" size-xs="9" size-sm="11" >
                                <IonSearchbar
                                    placeholder="Search by Prospect Name..."
                                    mode="md"
                                    debounce={500}
                                    onIonChange={handleChange}
                                    //onKeyUp ={handleChange}
                                    onIonClear={handleClear}
                                    style={{ "--background": "#222222", "--box-shadow": "none","--padding-top":"0px" }}
                                />
                            </IonCol>
                            <IonCol class="btncol-mobile" sizeLg="2" style={styles.btncol} size-xs="3" size-sm="1">
                                <IonButton mode="ios" style={styles.btn} fill="clear" color="gold" onClick={() => history.goBack()}>
                                    Cancel
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className="search-content">
                {/* <TopTargetSearchResults srchQry={srchQry} clrSrch={clrSrch} resetClrSrch={resetClrSrch} /> */}
                <div className="ion-padding">
                    <IonRow class="margin-set mt-6" style={{ marginTop: 24, marginLeft: 32, marginBottom: 24 }}>
                        <IonText
                                class="fs-28"
                            style={{ fontWeight: 300, fontSize: 28 }}
                            color="greyscale"
                        >
                            Search
                </IonText>
                    </IonRow>
                    {
                        results && results.length > 0 ?
                            results.map(contact => (

                                <IonItem
                                class="margin-set padding-unset" 
                                    button
                                    detail={false}
                                    custom-id={contact.id}
                                    onClick={(event) => {
                                        addRecord((event.target as HTMLInputElement).getAttribute('custom-id'))
                                    }}
                                    key={contact.id}
                                    style={styles.item}
                                >
                                    <IonIcon icon={personstar} color="white" size="small" style={{ marginLeft: 12 }} />
                                    <IonLabel custom-id={contact.id} style={styles.personlabel}>
                                        {
                                            `${contact.properties.firstname}${contact.properties.middle_name ? " " + contact.properties.middle_name : ''} ${contact.properties.lastname}`
                                        }
                                    </IonLabel>
                                </IonItem>
                            )
                            ) : null
                    }
                </div>
            </IonContent>
        </IonPage>
    )
}

export default ProspectSearch
