import React, { useEffect, useState } from "react";
import {
  IonGrid,
  IonCard,
  IonLoading,
  IonLabel,
  IonText,
  IonIcon,
  IonItem,
  IonRow,
  IonButton,
  IonCol,
  IonCardHeader,
  IonAvatar,
  IonCardContent,
  IonButtons,
  IonPopover,
  IonSelect,
  IonSelectOption,
  IonSlides,
  IonFooter,
  IonDatetime,
  useIonViewDidEnter,
} from "@ionic/react";
import prospectmet from "src/icons/prospectmet.svg";
import CryptoJS from "crypto-js";
import prospectmeeting from "src/icons/prospectmeeting.svg";
import prospectflg1 from "src/icons/prospectflag1.svg";
import wallet from "src/icons/wallet.svg";
import royal from "src/icons/pros-people.svg";
import avgportfolio from "src/icons/avgportfoliosize.svg";
import teamtotal from "src/icons/teamtotal.svg";
import mytotal from "src/icons/mytotal.svg";
import managed from "src/icons/managed.svg";
import reinvestment from "src/icons/reinvestment.svg";
import persondown from "src/icons/persondown.svg";
import prospectclock from "src/icons/clock.svg";
import prospectflg2 from "src/icons/prospectflag2.svg";
import checktodo from "src/icons/checktodo.svg";
import saudiarabia from "src/icons/saudiarabia.svg";
import kuwait from "src/icons/kuwait.svg";
import world from "src/icons/world.svg";
import bahrain from "src/icons/bahrain.svg";
import dollar from "src/icons/dollar.svg";
import briefcase from "src/icons/briefcase.svg";
import calendarschedule from "src/icons/calendarschedule.svg";
import Chart from "src/components/Chart/Chart";
import BarChart from "src/components/Chart/BarChart";
import GeneralChart from "src/components/Chart/GeneralChart";
import "./Prospects.css";
import TopTargets from "./TopTargets";
import UpcomingSch from "./UpcomingSchedule";
import PersonaBreakdown from "./PersonaBreakdown";
import NextTodos from "./NexToDos";
import Geographies from "../Geographies/Geographies";
import ClientReinvestment from "./ClientReinvestment";
import { chevronDown, statsChartSharp } from "ionicons/icons";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { prosMeetingArr, prosPersonaArr, prosStatArr } from "./DashboardUtils";
// const imgbaseuri = "https://cdn2.hubspot.net/";
import { Plugins, Network, NetworkStatus } from "@capacitor/core";
import api from "src/api";
import ClientData from "./ClientData";
import useWindowDimensions from "src/components/useWindowDimensions";
import ProspectReferral from "./ProspectReferral";
import { validateYupSchema } from "formik";
import CustDatePicker from "src/components/CustDatePicker/CustDatePicker";
const { Storage } = Plugins;

const styles = {
  selectItem: {
    "--padding-start": "0px",
    backgroundColor: "#222222",
  },
  // select: {
  //   "--padding-start": "8px",
  //   color: "#B99855",
  //   textAlign: "right",

  // },

  select: {
    color: "#B99855",
    textAlign: "right",
    width: "100%",
  },
  prosstats: {
    borderRadius: 25,
    background: "#263134",
    "--padding-start": "11px",
    "--inner-padding-end": "13px",
    "--inner-padding-start": "2px",
  },
  propsmeet: {
    borderRadius: 25,
    background: "#4B3933",
    "--padding-start": "11px",
    "--inner-padding-end": "13px",
    "--inner-padding-start": "2px",
  },
  schedulecard: {
    marginLeft: 0,
    marginTop: 16,
    backgroundColor: "#1A1A1A",
  },
  schedulecardupd: {
    marginLeft: 0,
    marginTop: 16,
    backgroundColor: "#1A1A1A",
    cursor: "pointer",
  },
  card: {
    "--background": "#1A1A1A",
    marginInlineStart: 0,
    marginInlineEnd: 0,
    margin: 0,
  },
  upcomingSchdulecard: {
    background: "#4B3933",
    marginInlineStart: 0,
    marginInlineEnd: 0,
    margin: 0,
  },
  next5todocard: {
    background: "#1A1A1A",
    marginInlineStart: 0,
    marginInlineEnd: 0,
    marginLeft: 16,
  },
  cardHeader: {},
  grid: {
    marginLeft: 24,
    marginRight: 24,
    marginTop: 32,
    padding: 0,
  },
  dashgrid: {
    marginLeft: 32,
    marginRight: 32,
  },
  item: {
    /* "--background": "#222222",
        marginBottom: 2,
        borderRadius: 8, */
    "--padding-start": "0px",
    height: 50,
    display: "flex",
  },
  desc: {
    fontFamily: "Almarai",
    fontWeight: 300,
    fontSize: 16,
    color: "#828282",
    marginBottom: 8,
  },
  label: {
    marginTop: 14.5,
    marginBottom: 14.5,
    marginLeft: 16,
  },
  title: {
    fontWeight: 400,
    fontSize: 18,
  },
  subText: {
    "--padding-start": "0px",
  },
  nopad: {
    padding: 0,
  },
};
const options = {
  cssClass: "my-custom-interface",
};
const expected_closing_amount = "";

const totProsMeet = "Total Prospect Meet";
const totClientMeetName = "Total Client Meet";
const ProsStatistics = "Prospect Stats";
const ProsGeographies = "Prospect Geographies";
const ProsPersonaBreakdown = "Prospect Persona Breakdown";
const totGenMeet = "Total Number of Meetings";

const decrypt = (decryptVlaue) => {
  let passphrase =
    "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9";
  const bytes = CryptoJS.AES.decrypt(decryptVlaue, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};
const getUserId = async () => {
  const ret: any = await Storage.get({ key: "userId" });
  return JSON.parse(decrypt(ret?.value))?.id;
};

const getUserEmail = async () => {
  const ret: any = await Storage.get({ key: "userId" });
  const temp = JSON.parse(decrypt(ret.value));
  return temp && temp.email ? temp.email : null;
};

const getTeams = async () => {
  const ret: any = await Storage.get({ key: "userId" });
  const temp = JSON.parse(decrypt(ret.value));
  return temp && temp.teams ? temp.teams : null;
};

const decimalFunc = (Num) => {
  let temp = Num.toString().split(".");
  let retVal = 0;
  if (temp[1]) {
    retVal = Num.toFixed(0);
  } else {
    retVal = Num;
  }
  return retVal;
};
const retExpClsAmt = (clsAmt) => {
  let closingamt_disp: number = 0;
  Number.isNaN(clsAmt) || clsAmt === null || clsAmt === ""
    ? (closingamt_disp = 0)
    : (closingamt_disp = parseInt(clsAmt));
  return Math.abs(Number(closingamt_disp)) >= 1.0e9
    ? decimalFunc(Math.abs(Number(closingamt_disp)) / 1.0e9) + "b"
    : // Six Zeroes for Millions
    Math.abs(Number(closingamt_disp)) >= 1.0e6
    ? decimalFunc(Math.abs(Number(closingamt_disp)) / 1.0e6) + "m"
    : // Three Zeroes for Thousands
    Math.abs(Number(closingamt_disp)) >= 1.0e3
    ? decimalFunc(Math.abs(Number(closingamt_disp)) / 1.0e3) + "k"
    : decimalFunc(Math.abs(Number(closingamt_disp)));
};

const Prospects = (props) => {
  //const[type,settype] = useState(props.type);
  let type = props.type;
  //const[typeState,settypeState] = useState(type);
  const timelinedata: any = [];
  const timelinedataprospmeet: any = [];
  const timelinedatacustom: any = [];

  let thisdate = new Date();
  let thisyear = thisdate.getFullYear();
  let thismonth = thisdate.getMonth();
  let curquater: any = thisdate.getMonth();
  let curquateryear: any = "";
  let curquatstartmonth: number = 0;
  if (curquater <= 2) {
    curquateryear = "Q1 " + thisyear;
    curquatstartmonth = 0;
  } else if (curquater <= 5) {
    curquateryear = "Q2 " + thisyear;
    curquatstartmonth = 3;
  } else if (curquater <= 8) {
    curquateryear = "Q3 " + thisyear;
    curquatstartmonth = 6;
  } else {
    curquateryear = "Q4 " + thisyear;
    curquatstartmonth = 9;
  }
  const [curquatyear, setcurquatyear] = useState(curquateryear);

  timelinedata.push(thisyear - 3);
  timelinedata.push(thisyear - 2);
  timelinedata.push(thisyear - 1);
  timelinedata.push("Last 30 days");
  timelinedata.push("Q1 " + thisyear);
  if (thismonth >= 2) {
    timelinedata.push("Q2 " + thisyear);
  }
  if (thismonth >= 5) {
    timelinedata.push("Q3 " + thisyear);
  }
  if (thismonth >= 8) {
    timelinedata.push("Q4 " + thisyear);
  }
  timelinedata.push("Year-to-Date");
  timelinedata.push("Last 12 Months");
  timelinedata.push("All Time");
  timelinedata.push("Next Year");

  timelinedataprospmeet.push(thisyear - 3);
  timelinedataprospmeet.push(thisyear - 2);
  timelinedataprospmeet.push(thisyear - 1);
  timelinedataprospmeet.push("Last 30 days");
  timelinedataprospmeet.push("Q1 " + thisyear);
  if (thismonth >= 2) {
    timelinedataprospmeet.push("Q2 " + thisyear);
  }
  if (thismonth >= 5) {
    timelinedataprospmeet.push("Q3 " + thisyear);
  }
  if (thismonth >= 8) {
    timelinedataprospmeet.push("Q4 " + thisyear);
  }
  timelinedataprospmeet.push("Year-to-Date");
  timelinedataprospmeet.push("Last 12 Months");
  timelinedataprospmeet.push("Next Year");

  timelinedatacustom.push(thisyear - 3);
  timelinedatacustom.push(thisyear - 2);
  timelinedatacustom.push(thisyear - 1);
  timelinedatacustom.push("Last 30 days");
  timelinedatacustom.push("Q1 " + thisyear);
  if (thismonth >= 2) {
    timelinedatacustom.push("Q2 " + thisyear);
  }
  if (thismonth >= 5) {
    timelinedatacustom.push("Q3 " + thisyear);
  }
  if (thismonth >= 8) {
    timelinedatacustom.push("Q4 " + thisyear);
  }
  timelinedatacustom.push("Year-to-Date");
  timelinedatacustom.push("Last 12 Months");
  timelinedatacustom.push("Next Year");
  timelinedatacustom.push("Custom");

  let QuaterSDate = new Date();
  QuaterSDate.setDate(1);
  QuaterSDate.setMonth(curquatstartmonth);
  QuaterSDate.setHours(0, 0, 1, 0);
  let meetSDate: any = moment(QuaterSDate);
  let meetEDate: any = moment(thisdate);

  // const[totProspMeetSDate,settotProspMeetSDate]=useState(moment(QuaterSDate))

  const [TotProspMeetPeriod, setTotProspMeetPeriod] = useState(curquatyear);
  //hema
  const [TotGenMeetPeriod, setTotGenMeetPeriod] = useState(curquatyear);
  const [TotClientMeetPeriod, setTotClientMeetPeriod] = useState(curquatyear);
  const [ProspStatPeriod, setProspStatPeriod] = useState(curquatyear);

  const [TotProspMeetDate, setTotProspMeetDate] = useState({
    startdate: moment(QuaterSDate).format("DD-MMM-YYYY"),
    enddate: moment(thisdate).format("DD-MMM-YYYY"),
    value: curquatyear,
  });

  const [TotGenMeetDate, setTotGenMeetDate] = useState({
    startdate: moment(QuaterSDate).format("DD-MMM-YYYY"),
    enddate: moment(thisdate).format("DD-MMM-YYYY"),
    value: curquatyear,
  });

  const [TotClientMeetDate, setTotClientMeetDate] = useState({
    startdate: moment(QuaterSDate).format("DD-MMM-YYYY"),
    enddate: moment(thisdate).format("DD-MMM-YYYY"),
    value: curquatyear,
  });

  const [ProspStatDate, setProspStatDate] = useState({
    startdate: moment(QuaterSDate).format("DD-MMM-YYYY"),
    enddate: moment(thisdate).format("DD-MMM-YYYY"),
    value: curquatyear,
  });
  const [ProspCntryDate, setProspCntryDate] = useState({
    startdate: moment(QuaterSDate).format("DD-MMM-YYYY"),
    enddate: moment(thisdate).format("DD-MMM-YYYY"),
    value: curquatyear,
  });
  const [ProspPersonaDate, setProspPersonaDate] = useState({
    startdate: moment(QuaterSDate).format("DD-MMM-YYYY"),
    enddate: moment(thisdate).format("DD-MMM-YYYY"),
    value: curquatyear,
  });

  const [selectedDropDown, setselectedDropDown] = useState("");

  //const[] = useState <any>()
  function timelinechange(timeline, sectionName) {
    if (timeline) {
      let startDate = new Date();
      let endDate: Date;
      if (timeline != "Custom") {
        if (
          timeline === thisyear - 3 ||
          timeline === thisyear - 2 ||
          timeline === thisyear - 1
        ) {
          let tempsdate = new Date();
          tempsdate.setDate(1);
          tempsdate.setMonth(0);
          tempsdate.setFullYear(timeline);
          let tempedate = new Date(timeline, 11, 31);
          // tempedate.setDate(31);
          // tempedate.setMonth(11);
          // tempedate.setFullYear(timeline);
          // if(timeline === (thisyear - 1))
          // {
          //     tempedate.setFullYear(thisyear-1);
          // }
          // else if(timeline === (thisyear - 2))
          // {
          //     tempedate.setFullYear(thisyear-2);
          // }
          // else if(timeline === (thisyear - 3))
          // {
          //     tempedate.setFullYear(thisyear-3);
          // }
          meetSDate = moment(tempsdate).format("DD-MMM-YYYY");
          meetEDate = moment(tempedate).format("DD-MMM-YYYY");

          if (sectionName === "Total Prospect Meet") {
            setTotProspMeetDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
            // setTotProspMeetPeriod(timeline) ;
          } else if (sectionName === "Total Client Meet") {
            setTotClientMeetDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
            //setTotClientMeetPeriod(timeline);
          } else if (sectionName === "Prospect Stats") {
            setProspStatDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
            //setProspStatPeriod(timeline) ;
          } else if (sectionName === "Prospect Geographies") {
            setProspCntryDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
          } else if (sectionName === "Prospect Persona Breakdown") {
            setProspPersonaDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
          } else if (sectionName === "Total Number of Meetings") {
            setTotGenMeetDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
            //setTotGenMeetPeriod(timeline);
          }
          //onsole.log(ProspStatDate)
        } else if (timeline === "Last 30 days") {
          let tempsdate: any = new Date();
          tempsdate.setDate(tempsdate.getDate() - 30);
          let tempedate = new Date();
          meetSDate = moment(tempsdate).format("DD-MMM-YYYY");
          meetEDate = moment(tempedate).format("DD-MMM-YYYY");
          if (sectionName === "Total Prospect Meet") {
            setTotProspMeetDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
            // setTotProspMeetPeriod(timeline) ;
          } else if (sectionName === "Total Client Meet") {
            setTotClientMeetDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
            //setTotClientMeetPeriod(timeline);
          } else if (sectionName === "Prospect Stats") {
            setProspStatDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
            //setProspStatPeriod(timeline) ;
          } else if (sectionName === "Prospect Geographies") {
            setProspCntryDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
            //setcurquatyear(timeline) ;
          } else if (sectionName === "Prospect Persona Breakdown") {
            setProspPersonaDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
          } else if (sectionName === "Total Number of Meetings") {
            setTotGenMeetDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
            //setTotGenMeetPeriod(timeline);
          }
        } else if (timeline === "Year-to-Date") {
          let tempsdate = new Date();
          tempsdate.setDate(1);
          tempsdate.setMonth(0);
          let tempedate = new Date();
          meetSDate = moment(tempsdate).format("DD-MMM-YYYY");
          meetEDate = moment(tempedate).format("DD-MMM-YYYY");
          if (sectionName === "Total Prospect Meet") {
            setTotProspMeetDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
            // setTotProspMeetPeriod(timeline);
          } else if (sectionName === "Total Client Meet") {
            setTotClientMeetDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
            //setTotClientMeetPeriod(timeline);
          } else if (sectionName === "Prospect Stats") {
            setProspStatDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
            //setProspStatPeriod(timeline) ;
          } else if (sectionName === "Prospect Geographies") {
            setProspCntryDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
          } else if (sectionName === "Prospect Persona Breakdown") {
            setProspPersonaDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
          } else if (sectionName === "Total Number of Meetings") {
            setTotGenMeetDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
            //setTotGenMeetPeriod(timeline);
          }
        } else if (timeline === "Last 12 Months") {
          let tempsdate: any = new Date();
          tempsdate.setFullYear(tempsdate.getFullYear() - 1);
          let tempedate = new Date();
          meetSDate = moment(tempsdate).format("DD-MMM-YYYY");
          meetEDate = moment(tempedate).format("DD-MMM-YYYY");
          if (sectionName === "Total Prospect Meet") {
            setTotProspMeetDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
            // setTotProspMeetPeriod(timeline);
          } else if (sectionName === "Total Client Meet") {
            setTotClientMeetDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
            // setTotClientMeetPeriod(timeline);
          } else if (sectionName === "Prospect Stats") {
            setProspStatDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
            // setProspStatPeriod(timeline) ;
          } else if (sectionName === "Prospect Geographies") {
            setProspCntryDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
            //setcurquatyear(timeline) ;
          } else if (sectionName === "Prospect Persona Breakdown") {
            setProspPersonaDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
          } else if (sectionName === "Total Number of Meetings") {
            setTotGenMeetDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
            // setTotGenMeetPeriod(timeline);
          }
        } else if (
          timeline.includes("Q1") ||
          timeline.includes("Q2") ||
          timeline.includes("Q3") ||
          timeline.includes("Q4")
        ) {
          let tempsdate = new Date();
          let tempedate = new Date();
          if (timeline.includes("Q1")) {
            if (thismonth <= 2) {
              tempsdate.setDate(1);
              tempsdate.setMonth(0);
            } else {
              tempsdate.setDate(1);
              tempsdate.setMonth(0);

              tempedate.setDate(31);
              tempedate.setMonth(2);
            }
            meetSDate = moment(tempsdate).format("DD-MMM-YYYY");
            meetEDate = moment(tempedate).format("DD-MMM-YYYY");
          } else if (timeline.includes("Q2")) {
            if (thismonth > 2 && thismonth <= 5) {
              tempsdate.setDate(1);
              tempsdate.setMonth(3);
            } else {
              tempsdate.setDate(1);
              tempsdate.setMonth(3);
              tempedate.setDate(30);
              tempedate.setMonth(5);
            }
            meetSDate = moment(tempsdate).format("DD-MMM-YYYY");
            meetEDate = moment(tempedate).format("DD-MMM-YYYY");
          } else if (timeline.includes("Q3")) {
            if (thismonth > 5 && thismonth <= 8) {
              tempsdate.setDate(1);
              tempsdate.setMonth(6);
            } else {
              tempsdate.setDate(1);
              tempsdate.setMonth(6);
              tempedate.setDate(30);
              tempedate.setMonth(8);
            }
            meetSDate = moment(tempsdate).format("DD-MMM-YYYY");
            meetEDate = moment(tempedate).format("DD-MMM-YYYY");
          } else if (timeline.includes("Q4")) {
            if (thismonth > 8 && thismonth <= 11) {
              tempsdate.setDate(1);
              tempsdate.setMonth(9);
            } else {
              tempsdate.setDate(1);
              tempsdate.setMonth(9);
              tempedate.setDate(31);
              tempedate.setMonth(11);
            }
            meetSDate = moment(tempsdate).format("DD-MMM-YYYY");
            meetEDate = moment(tempedate).format("DD-MMM-YYYY");
          }

          if (sectionName === "Total Prospect Meet") {
            setTotProspMeetDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
          } else if (sectionName === "Total Client Meet") {
            setTotClientMeetDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
            //setTotClientMeetPeriod(timeline);
          } else if (sectionName === "Prospect Stats") {
            setProspStatDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
            // setProspStatPeriod(timeline) ;
          } else if (sectionName === "Prospect Geographies") {
            setProspCntryDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
            // setcurquatyear(timeline) ;
          } else if (sectionName === "Prospect Persona Breakdown") {
            setProspPersonaDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
          } else if (sectionName === "Total Number of Meetings") {
            setTotGenMeetDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
            //setTotGenMeetPeriod(timeline);
          }
        } else if (timeline === "All Time") {
          //01-MAR-1990
          let tempsdate = new Date();
          let tempedate = new Date();
          tempsdate.setDate(1);
          tempsdate.setMonth(2);
          tempsdate.setFullYear(1990);
          meetSDate = moment(tempsdate).format("DD-MMM-YYYY");
          meetEDate = moment(tempedate).format("DD-MMM-YYYY");
          if (sectionName === "Total Prospect Meet") {
            setTotProspMeetDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
            //setTotProspMeetPeriod(timeline);
          } else if (sectionName === "Total Client Meet") {
            setTotClientMeetDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
            //setTotClientMeetPeriod(timeline);
          } else if (sectionName === "Prospect Stats") {
            setProspStatDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
            //setProspStatPeriod(timeline) ;
          } else if (sectionName === "Prospect Geographies") {
            setProspCntryDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
            // setcurquatyear(timeline) ;
          } else if (sectionName === "Prospect Persona Breakdown") {
            setProspPersonaDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
          } else if (sectionName === "Total Number of Meetings") {
            setTotGenMeetDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
            //setTotGenMeetPeriod(timeline);
          }
        } else if (timeline === "Next Year") {
          let tempsdate = new Date();
          tempsdate.setDate(1);
          tempsdate.setMonth(0);
          tempsdate.setFullYear(tempsdate.getFullYear() + 1);
          let tempedate = new Date();
          tempedate.setDate(31);
          tempedate.setMonth(11);
          tempedate.setFullYear(tempsdate.getFullYear() + 1);
          meetSDate = moment(tempsdate).format("DD-MMM-YYYY");
          meetEDate = moment(tempedate).format("DD-MMM-YYYY");
          if (sectionName === "Total Prospect Meet") {
            setTotProspMeetDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
            // setTotProspMeetPeriod(timeline);
          } else if (sectionName === "Total Client Meet") {
            setTotClientMeetDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
            //setTotClientMeetPeriod(timeline);
          } else if (sectionName === "Prospect Stats") {
            setProspStatDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
            //setProspStatPeriod(timeline) ;
          } else if (sectionName === "Prospect Geographies") {
            setProspCntryDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
            //setcurquatyear(timeline) ;
          } else if (sectionName === "Prospect Persona Breakdown") {
            setProspPersonaDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
          } else if (sectionName === "Total Number of Meetings") {
            setTotGenMeetDate({
              startdate: meetSDate,
              enddate: meetEDate,
              value: timeline,
            });
            //setTotGenMeetPeriod(timeline);
          }
        }
        // }else if(timeline === "Custom"){
        //  setTotProspMeetPeriod(TotGenMeetPeriod)
      } else {
        if (
          sectionName === "Total Prospect Meet" ||
          sectionName === "Prospect Stats" ||
          sectionName === "Total Client Meet" ||
          sectionName === "Total Number of Meetings"
        ) {
          if (sectionName === "Total Prospect Meet") {
            //  setTotProspMeetPeriod("Custom");
          } else if (sectionName === "Prospect Stats") {
            // if(timeline === ProspStatDate.value)
            // setDatePopover({ open: true, event: undefined });
            // setProspStatPeriod("Custom");
          } else if (sectionName === "Total Client Meet") {
            // if(timeline === TotClientMeetDate.value)
            // setDatePopover({ open: true, event: undefined });
            //setTotClientMeetPeriod("Custom");
          } else {
            // if(timeline === TotGenMeetDate.value)
            // setDatePopover({ open: true, event: undefined });
            //setTotGenMeetPeriod("Custom");
          }
          setselectedDropDown(sectionName);
          let lastWeekDate: any = new Date().setDate(new Date().getDate() - 7);
          let todayDate: any = new Date();
          setSelectedEndDate(moment(todayDate).format("YYYY-MM-DD"));
          setSelectedStartDate(moment(lastWeekDate).format("YYYY-MM-DD"));
          setDatePopover({ open: true, event: undefined });
        }
      }
    }
  }
  const [prosMet, setprosMet] = useState<any>("-");
  const [clientMet, setclientMet] = useState<any>("-");
  const [totProsp, settotProsp] = useState<any>("-");
  const [totalProsp, settotalProsp] = useState<any>("-");
  const [totClient, settotClient] = useState<any>("-");
  const [meetCnt, setmeetCnt] = useState<any>(0);
  const [clientmeetCnt, setclientmeetCnt] = useState<any>(0);

  const [genTotMeetings, setgenTotMeetings] = useState<any>("-");
  const [genallMeetings, setgenallMeetings] = useState<any>("-");
  const [gentotAssigned, setgentotAssigned] = useState<any>(0);
  const [genMeetingGoal, setgenMeetingGoal] = useState<any>(0);

  const [totMeet, settotMeet] = useState<any>("-");
  const [gentotGoal, setgentotGoal] = useState<any>("-");
  const [totClientMeet, settotClientMeet] = useState<any>("-");
  //settotClientMeet
  const [droppedCnt, setdroppedCnt] = useState<any>("-");
  const [timetoConvert, settimetoConvert] = useState<any>("-");
  const [refCnt, setrefCnt] = useState<any>("-");
  const [saudiCnt, setSaudiCnt] = useState(0);
  const [kuwaitCnt, setkuwaitCnt] = useState(0);
  const [bahrainCnt, setbahrainCnt] = useState(0);
  const [worldCnt, setworldCnt] = useState(0);
  const [totalCntryCnt, settotalCntryCnt] = useState(0);
  const [personaData, setpersonaData] = useState<any>();
  const [isLoading, setLoading] = React.useState(false);
  const [countryarr, setcountryarr] = useState<any>();
  // const[source,setSource] = useState<any>("Prospect");
  const [countrystate, setcountrystate] = useState<any>(false);
  const [totalManagedClients, setTotalManagedClients] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [totalAUM, setTotalAUM] = useState<number>(0);
  const [mytotalgen, setmytotalgen] = useState<number>(0);
  const [teamtotalgen, setteamtotalgen] = useState<number>(0);

  const [totalReinvestment, setTotalReinvestment] = useState<any>(0);

  const history = useHistory();

  function callStateView(): void {
    history.push("/geographies", {
      countryarr,
      ProspCntryDate,
      source:
        props.type == "prospects"
          ? "prospect"
          : props.type == "clients"
          ? "client"
          : "all",
    });
  }
  function callClientReinvestment(): void {
    history.push("/clientreinvestment");
  }

  function callProspectReferral(): void {
    let type = "referral";
    history.push("/ProspectReferral", { ProspStatDate, type });
  }

  function callProspectDropped(): void {
    let type = "dropped";
    history.push("/ProspectReferral", { ProspStatDate, type });
  }

  useEffect(() => {
    Network.getStatus().then((network) => {
      if (network.connected) {
        let type =
          props.type == "prospects"
            ? "prospect"
            : props.type == "clients"
            ? "client"
            : "all";
        setLoading(true);
        getUserId().then((userId) => {
          // api.GetDashboardCountry('47735531','01-MAR-1990','01-MAR-2021').then(resp => {
          api
            .GetDashboardCountry(
              userId,
              ProspCntryDate.startdate,
              ProspCntryDate.enddate,
              type
            )
            .then((resp) => {
              let tempcnt: number = 0;
              let tempothercnt: number = 0;
              setcountryarr(resp);
              setSaudiCnt(0);
              setkuwaitCnt(0);
              setbahrainCnt(0);
              setworldCnt(0);              
              resp && resp.map((obj) => {
                if (obj.country == "Saudi Arabia") {
                  setSaudiCnt(obj.count);
                } else if (obj.country == "Kuwait") {
                  setkuwaitCnt(obj.count);
                } else if (obj.country == "Bahrain") {
                  setbahrainCnt(obj.count);
                } else {
                  tempothercnt = tempothercnt + parseInt(obj.count);
                  setworldCnt(tempothercnt);
                }
                tempcnt = tempcnt + parseInt(obj.count);
                settotalCntryCnt(tempcnt);
              });
              setLoading(false);
            });
        });
      }
    });
  }, [ProspCntryDate, props.type]);

  useEffect(() => {
    let type =
      props.type == "prospects"
        ? "prospect"
        : props.type == "clients"
        ? "client"
        : "all";
    //setLoading(true);

    getUserId().then((userId) => {
      api
        .getPersona(
          userId,
          ProspPersonaDate.startdate,
          ProspPersonaDate.enddate,
          type
        )
        .then((resp) => {
          setpersonaData([]);
          setpersonaData(resp);
          //setLoading(false);
        });
    });
    //return() => {setpersonaData([]); console.log("cleanup")}
  }, [ProspPersonaDate, props.type]);

  useEffect(() => {
    Network.getStatus().then((network) => {
      if (network.connected) {
        //api.getDashboardProspMeet('47735531','01-MAR-1990','01-MAR-2021').then(resp => {
        setLoading(true);

        getUserId().then((userId) => {
          api
            .getDashboardProspMeet(
              userId,
              TotProspMeetDate.startdate,
              TotProspMeetDate.enddate
            )
            .then((resp) => {
              setprosMet("-");
              setmeetCnt("-");
              settotProsp("-");
              settotMeet("-");
              let totmeetcnt: number = 0;
              setprosMet(
                resp[0] && resp[0].prospectMeetings
                  ? resp[0].prospectMeetings
                  : "-"
              );
              setmeetCnt(
                resp[0] && resp[0].totalMeetings ? resp[0].totalMeetings : "-"
              );
              settotProsp(
                resp[0] && resp[0].totalAssignedProspects
                  ? resp[0].totalAssignedProspects
                  : "-"
              );

              // console.log(resp[0] && resp[0].prospectMeetings? resp[0].prospectMeetings: '-');
              // console.log(resp[0] && resp[0].totalMeetings? resp[0].totalMeetings: '-');
              // console.log(resp[0] && resp[0].totalAssignedProspects? resp[0].totalAssignedProspects: '-');

              if (resp[0] && resp[0].meetingGoal) {
                //console.log("995");
                let finalGoalValProsp: number = 0;
                let goalValProsp = resp[0].meetingGoal;
                let goalValArrProsp = goalValProsp.split(",");
                if (goalValArrProsp.length > 0) {
                  for (let j = 0; j < goalValArrProsp.length; j++) {
                    finalGoalValProsp =
                      finalGoalValProsp + parseInt(goalValArrProsp[j]);
                  }
                }

                if (
                  TotProspMeetDate.value.toString().includes("Q1") ||
                  TotProspMeetDate.value.toString().includes("Q2") ||
                  TotProspMeetDate.value.toString().includes("Q3") ||
                  TotProspMeetDate.value.toString().includes("Q4")
                ) {
                  settotMeet(Math.round(finalGoalValProsp / 4));
                } else if (
                  TotProspMeetDate.value.toString().includes("Last 30")
                ) {
                  if (goalValArrProsp.length == 1) {
                    settotMeet(Math.round(finalGoalValProsp / 12));
                  } else {
                    settotMeet(Math.round(finalGoalValProsp / 24));
                  }
                } else if (
                  TotProspMeetDate.value.toString().includes("Last 12 Months")
                ) {
                  if (goalValArrProsp.length == 1) {
                    settotMeet(Math.round(finalGoalValProsp));
                  } else {
                    settotMeet(Math.round(finalGoalValProsp / 2));
                  }
                } else {
                  settotMeet(Math.round(finalGoalValProsp));
                }
              }

              setLoading(false);
            });
        });
      }
    });
  }, [TotProspMeetDate]);

  useEffect(() => {
    Network.getStatus().then((network) => {
      if (network.connected) {
        setLoading(true);
        getUserId().then((userId) => {
          getTeams().then((teams) => {
            api
              .getGeneralMeetings(
                userId,
                teams ? parseInt(teams[0].id) : "0",
                TotGenMeetDate.startdate,
                TotGenMeetDate.enddate
              )
              .then((resp) => {
                // console.log(resp.data[0]);

                //totalMeetings":"0","allMeetings":"0","totalAssigned":"73","meetingGoal"

                setgenTotMeetings("-");
                setgenallMeetings("-");
                setgentotAssigned("-");
                setgenMeetingGoal("-");

                let totmeetcnt: number = 0;
                setgenTotMeetings(
                  resp.data[0] && resp.data[0].totalMeetings
                    ? resp.data[0].totalMeetings
                    : "-"
                );
                setgenallMeetings(
                  resp.data[0] && resp.data[0].allMeetings
                    ? resp.data[0].allMeetings
                    : "-"
                );
                setgentotAssigned(
                  resp.data[0] && resp.data[0].totalAssigned
                    ? resp.data[0].totalAssigned
                    : "-"
                );
                let finalgoalValGen: number = 0;
                if (resp.data[0] && resp.data[0].meetingGoal) {
                  let goalValGen = resp.data[0].meetingGoal;
                  let goalValArrGen = goalValGen.split(",");
                  if (goalValArrGen.length > 0) {
                    for (let j = 0; j < goalValArrGen.length; j++) {
                      finalgoalValGen =
                        finalgoalValGen + parseInt(goalValArrGen[j]);
                    }
                  }
                  //console.log(goalValArrGen);

                  if (
                    TotGenMeetDate.value.toString().includes("Q1") ||
                    TotGenMeetDate.value.toString().includes("Q2") ||
                    TotGenMeetDate.value.toString().includes("Q3") ||
                    TotGenMeetDate.value.toString().includes("Q4")
                  ) {
                    setgentotGoal(Math.round(finalgoalValGen / 4));
                  } else if (
                    TotGenMeetDate.value.toString().includes("Last 30")
                  ) {
                    if (goalValArrGen.length == 1) {
                      setgentotGoal(Math.round(finalgoalValGen / 12));
                    } else {
                      setgentotGoal(Math.round(finalgoalValGen / 24));
                    }
                  } else if (
                    TotGenMeetDate.value.toString().includes("Last 12 Months")
                  ) {
                    if (goalValArrGen.length == 1) {
                      setgentotGoal(Math.round(finalgoalValGen));
                    } else {
                      setgentotGoal(Math.round(finalgoalValGen / 2));
                    }
                  } else {
                    setgentotGoal(Math.round(finalgoalValGen));
                  }
                } else {
                  setgentotGoal("-");
                }

                // settotMeet(JSON.parse(resp[0].meetingGoal));
                setLoading(false);
              });
          });
        });
      }
    });
  }, [TotGenMeetDate]);

  useEffect(() => {
    Network.getStatus().then((network) => {
      if (network.connected) {
        //api.getDashboardProspMeet('47735531','01-MAR-1990','01-MAR-2021').then(resp => {
        setLoading(true);
        getUserId().then((userId) => {
          api
            .getClientMeetings(
              userId,
              TotClientMeetDate.startdate,
              TotClientMeetDate.enddate
            )
            .then((resp) => {
              // console.log(resp.data[0]);
              setclientMet("-");
              setclientmeetCnt("-");
              let totmeetcntclient: number = 0;
              setclientMet(
                resp && resp.data[0] && resp.data[0].clientMeetings
                  ? resp.data[0].clientMeetings
                  : "-"
              );
              setclientmeetCnt(
                resp && resp.data[0] && resp.data[0].totalMeetings
                  ? resp.data[0].totalMeetings
                  : "-"
              );
              if (resp && resp.data[0] && resp.data[0].meetingGoal) {
                let finalGoalVal: number = 0;
                let goalVal = resp.data[0].meetingGoal;
                let goalValArr = goalVal.split(",");
                if (goalValArr.length > 0) {
                  for (let j = 0; j < goalValArr.length; j++) {
                    finalGoalVal = finalGoalVal + parseInt(goalValArr[j]);
                  }
                }
                if (
                  TotClientMeetDate.value.toString().includes("Q1") ||
                  TotClientMeetDate.value.toString().includes("Q2") ||
                  TotClientMeetDate.value.toString().includes("Q3") ||
                  TotClientMeetDate.value.toString().includes("Q4")
                ) {
                  settotClientMeet(Math.round(finalGoalVal / 4));
                  // let startloop:number = 0;
                  // let endloop:number   = 12;
                  // if(TotClientMeetDate.value.toString().includes("Q1")) {
                  //     startloop = 1;
                  //     endloop = 3;
                  //     settotClientMeet( Math.round(finalGoalVal) )
                  // } else if (TotClientMeetDate.value.toString().includes("Q2")) {
                  //     startloop = 4;
                  //     endloop = 6;
                  //     settotClientMeet( Math.round(finalGoalVal) )
                  // } else if (TotClientMeetDate.value.toString().includes("Q3")) {
                  //     startloop = 7;
                  //     endloop =   9;
                  //     settotClientMeet( Math.round(finalGoalVal) )
                  // } else if (TotClientMeetDate.value.toString().includes("Q4")) {
                  //     startloop = 10;
                  //     endloop =   12;
                  //     settotClientMeet( Math.round(finalGoalVal) )
                  // } else if (TotClientMeetDate.value.toString().includes("Last 30")) {
                  //     settotClientMeet( Math.round(finalGoalVal) )
                  // } else {
                  //     settotClientMeet( Math.round(finalGoalVal) )
                  // }
                  // for(let i:number = startloop; i<= endloop; i++) {
                  //     if(JSON.parse(resp.data[0].meetingGoal)[i]) {
                  //         totmeetcntclient = totmeetcntclient + Number(JSON.parse(resp.data[0].meetingGoal)[i]);
                  //     }
                  // }
                } else if (
                  TotClientMeetDate.value.toString().includes("Last 30")
                ) {
                  // settotClientMeet( Math.round(finalGoalVal) )
                  if (goalValArr.length == 1) {
                    settotClientMeet(Math.round(finalGoalVal / 12));
                  } else {
                    settotClientMeet(Math.round(finalGoalVal / 24));
                  }
                } else if (
                  TotClientMeetDate.value.toString().includes("Last 12 Months")
                ) {
                  // settotClientMeet( Math.round(finalGoalVal) )
                  if (goalValArr.length == 1) {
                    settotClientMeet(Math.round(finalGoalVal));
                  } else {
                    settotClientMeet(Math.round(finalGoalVal / 2));
                  }
                } else {
                  settotClientMeet(Math.round(finalGoalVal));
                }
              }
              // settotClient(JSON.parse(resp[0].meetingGoal));
              setLoading(false);
            });
          let payload: any = { query: "", properties: [] };
          let filterGroups = [
            {
              filters: [
                {
                  operator: "EQ",
                  propertyName: "hubspot_owner_id",
                  value: parseInt(userId),
                },
                {
                  operator: "HAS_PROPERTY",
                  propertyName: "hs_lifecyclestage_customer_date",
                },
              ],
            },
          ];
          api.searchProspect({ ...payload, filterGroups }).then((resp) => {
            settotClient("-");
            settotClient(resp.total ? resp.total : "-");
          });
        });
      }
    });
  }, [TotClientMeetDate]);

  useEffect(() => {
    Network.getStatus().then((network) => {
      if (network.connected) {
        //api.getDashboardStats('47735531','01-MAR-1990','01-MAR-2021').then(resp => {
        setLoading(true);
        getUserId().then((userId) => {
          api
            .getDashboardStats(
              userId,
              ProspStatDate.startdate,
              ProspStatDate.enddate,
              "count"
            )
            .then((resp) => {
              if (resp.length > 0) {
                setdroppedCnt("-");
                setrefCnt("-");
                settimetoConvert("-");
                setdroppedCnt(
                  resp[0].droppedCount ? resp[0].droppedCount : "-"
                );
                setrefCnt(resp[0].referrals ? resp[0].referrals : "-");
                settimetoConvert(
                  resp[0].avergeDaysToClose ? resp[0].avergeDaysToClose : "-"
                );
                setLoading(false);
              }

              let filterGroups = [
                {
                  filters: [
                    {
                      operator: "EQ",
                      //propertyName: teams?"hubspot_team_id":"hubspot_owner_id",
                      // value: teams?parseInt(teams[0].id):parseInt(userId),
                      propertyName: "hubspot_owner_id",
                      value: parseInt(userId),
                    },
                    {
                      operator: "NOT_HAS_PROPERTY",
                      propertyName: "hs_lifecyclestage_customer_date",
                    },
                  ],
                },
              ];
              api.filterProspects({ filterGroups }).then((response) => {
                settotalProsp(response.total);
              });
            });
        });
      }
    });
  }, [ProspStatDate]);

  useEffect(() => {
    if (props.type === "clients") {
      getUserEmail().then((useremail) => {
        // api.getClientStatistics("s.shehadeh@tfoco.com").then(resp =>{
        api.getClientStatistics(useremail).then((resp) => {
          setTotalManagedClients(resp[0].totalManagedClients);
          setTotalAUM(resp[0].aum / resp[0].totalManagedClients);
          setTotal(resp.total);
          console.log(resp[0].totalManagedClients);
          console.log();
        });

        let startDate = "01-Jan-" + moment().format("YYYY");
        // let startDate = "01-Jan-2017"
        let endDate = moment().format("DD-MMM-YYYY");
        // api.getClientDashboardReinvestment("s.shehadeh@tfoco.com", startDate, endDate).then(resp =>{
        api
          .getClientDashboardReinvestment(useremail, startDate, endDate)
          .then((resp) => {
            resp.data = resp.data.conversions;
            if (resp.data && resp.data[0])
              setTotalReinvestment(
                retExpClsAmt(resp.data[0].totalReinvestmentAmount)
              );
          });
      });
    }
    if (props.type === "general") {
      getTeams().then((teams) => {
        getUserEmail().then((useremail) => {
          api.getOwnerswithProps(useremail).then((resp) => {
            // api.getUncalledCommitments("s.shehadeh@tfoco.com",teams?parseInt(teams[0].id):"").then(resp =>{
            api
              .getUncalledCommitments(
                useremail,
                teams ? parseInt(teams[0].id) : "0"
              )
              .then((resp) => {
                setmytotalgen(resp.data[0].myTotal ? resp.data[0].myTotal : 0);
                setteamtotalgen(
                  resp.data[0].myTeamTotal ? resp.data[0].myTeamTotal : 0
                );
              });
          });
        });
      });
    }
  }, [props.type]);

  const { height, width } = useWindowDimensions();

  const [DatePopover, setDatePopover] = useState<{
    open: boolean;
    event: Event | undefined;
  }>({
    open: false,
    event: undefined,
  });
  const [SelectedEndDate, setSelectedEndDate] = useState("2004-01-01");
  const [SelectedStartDate, setSelectedStartDate] = useState("2004-01-01");
  const disabledDate = (current) => {
    if (!current) {
      // allow empty select
      return false;
    }
    const date = moment(SelectedStartDate);
    return current.valueOf() <= date.valueOf();
  };

  function callApiFunc(sectionName) {
    meetSDate = moment(SelectedStartDate).format("DD-MMM-YYYY");
    meetEDate = moment(SelectedEndDate).format("DD-MMM-YYYY");
    if (sectionName === "Total Prospect Meet") {
      //hema prop vallll

      setTotProspMeetPeriod(meetSDate + "  " + "to" + "  " + meetEDate);
      setTotProspMeetDate({
        startdate: meetSDate,
        enddate: meetEDate,
        value: "Custom",
      });
    } else if (sectionName === "Prospect Stats") {
      setProspStatPeriod(meetSDate + "  " + "to" + "  " + meetEDate);
      setProspStatDate({
        startdate: meetSDate,
        enddate: meetEDate,
        value: "Custom",
      });
      //setProspStatPeriod("Custom") ;
    } else if (sectionName === "Total Client Meet") {
      setTotClientMeetPeriod(meetSDate + "  " + "to" + "  " + meetEDate);
      setTotClientMeetDate({
        startdate: meetSDate,
        enddate: meetEDate,
        value: "Custom",
      });
      // setTotClientMeetPeriod("Custom");
    } else if (sectionName === "Total Number of Meetings") {
      setTotGenMeetPeriod(meetSDate + "  " + "to" + "  " + meetEDate);
      setTotGenMeetDate({
        startdate: meetSDate,
        enddate: meetEDate,
        value: "Custom",
      });
      //setTotGenMeetPeriod("Custom");
    }
  }
  return (
    <React.Fragment>
      {props.type == "prospects" ? (
        <Chart />
      ) : props.type == "clients" ? (
        <BarChart />
      ) : (
        <GeneralChart />
      )}
      <IonLoading
        cssClass="my-custom-loading-class"
        isOpen={isLoading}
        onDidDismiss={() => setLoading(false)}
        message={"Please wait..."}
      />
      <IonGrid class="dashgrid-mobile  mr-13 ml-13" style={styles.dashgrid}>
        {/* Meetings */
        props.type == "prospects" ? (
          <React.Fragment>
            <IonRow class="ion-margin-top">
              <IonCol
                size-xs="6"
                size-sm="5"
                style={{ fontWeight: 400 }}
                className="font18 ion-margin-top"
              >
                <span style={{ whiteSpace: "nowrap" }}>
                  Total Prospect Meetings
                </span>
              </IonCol>

              <IonCol size-xs="1" size-sm="4"></IonCol>
              <IonCol size-xs="5" size-sm="3" class="ion-float-right">
                <IonItem lines="none" style={{ "--padding-start": "0px" }}>
                  <IonSelect
                    interfaceOptions={options}
                    onIonChange={(e) => {
                      // if(e.detail.value === "Custom"){
                      //   setTotProspMeetPeriod("hema")
                      // } else {

                      setTotProspMeetPeriod(e.detail.value);
                      timelinechange(e.detail.value, totProsMeet);
                      // }
                    }}
                    style={styles.select}
                    name="salutation"
                    mode="ios"
                    class="custTotMeetSelect"
                    value={TotProspMeetPeriod}
                    placeholder={TotProspMeetPeriod}
                    //hema
                    interface={width < 576 ? "action-sheet" : "popover"}
                  >
                    {timelinedatacustom.map((val) => (
                      <IonSelectOption key={val} value={val}>
                        {val}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                  <IonIcon color="gold" icon={chevronDown} />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow class="margintopn20">
              <IonCol size-xs="12" size-sm="4">
                <IonCard
                  style={styles.schedulecard}
                  className="schCard"
                  class="totProspMeet ion-padding"
                >
                  <IonItem lines="none" style={{ "--padding-start": "0px" }}>
                    <IonIcon
                      style={{ width: 48, height: 48 }}
                      icon={prospectmet}
                    />
                    <IonGrid className="cardInGrid">
                      <IonRow style={{ marginBottom: 8 }}>
                        <IonText
                          className="cardTitle"
                          style={{
                            textAlign: "center",
                            fontSize: 43,
                            fontWeight: 300,
                          }}
                        >
                          {prosMet}/{totProsp}
                        </IonText>
                      </IonRow>
                      <IonRow
                        style={{ justifyContent: "left", fontSize: 14 }}
                        class="grayscale4"
                      >
                        <IonLabel>
                          {props.type == "prospects"
                            ? "Prospects Met"
                            : "Clients Met"}
                        </IonLabel>
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                </IonCard>
              </IonCol>
              <IonCol size-xs="12" size-sm="4">
                <IonCard
                  style={styles.schedulecard}
                  className="schCard"
                  class="totProspMeet ion-padding"
                >
                  <IonItem lines="none" style={{ "--padding-start": "0px" }}>
                    <IonIcon
                      style={{ width: 48, height: 48 }}
                      icon={prospectmeeting}
                    />
                    <IonGrid className="cardInGrid">
                      <IonRow style={{ marginBottom: 8 }}>
                        <IonText
                          className="cardTitle"
                          style={{
                            textAlign: "center",
                            fontSize: 43,
                            fontWeight: 300,
                          }}
                        >
                          {meetCnt}/{totMeet}
                        </IonText>
                      </IonRow>
                      <IonRow
                        style={{ justifyContent: "left", fontSize: 14 }}
                        class="grayscale4"
                      >
                        <IonLabel>Meetings</IonLabel>
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                </IonCard>
              </IonCol>
              <IonCol size-xs="12" size-sm="4">
                <IonCard
                  style={styles.schedulecard}
                  className="schCard"
                  class="totProspMeet ion-padding"
                >
                  <IonItem lines="none" style={{ "--padding-start": "0px" }}>
                    <IonIcon
                      style={{ width: 48, height: 48 }}
                      icon={prospectflg1}
                    />
                    <IonGrid className="cardInGrid">
                      <IonRow style={{ marginBottom: 8 }}>
                        <IonText
                          className="cardTitle"
                          style={{
                            textAlign: "center",
                            fontSize: 43,
                            fontWeight: 300,
                          }}
                        >
                          {Number.isNaN(meetCnt) ||
                          meetCnt === "-" ||
                          totMeet === "-" ||
                          totMeet === 0
                            ? "-"
                            : (Number(meetCnt) / Number(totMeet)) * 100 > 0
                            ? (
                                (Number(meetCnt) / Number(totMeet)) *
                                100
                              ).toFixed(1)
                            : 0}
                          %
                        </IonText>
                      </IonRow>
                      <IonRow
                        style={{ justifyContent: "left", fontSize: 14 }}
                        class="grayscale4"
                      >
                        <IonLabel>to Meeting Goal</IonLabel>
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                </IonCard>
              </IonCol>
            </IonRow>
          </React.Fragment>
        ) : props.type == "clients" ? (
          <React.Fragment>
            <IonRow class="ion-margin-top">
              <IonCol
                size="4"
                style={{ fontWeight: 400 }}
                className="font18 ion-float-left ion-margin-top"
              >
                <span style={{ whiteSpace: "nowrap" }}>
                  Total Client Meetings
                </span>
              </IonCol>
              <IonCol size="4"></IonCol>
              <IonCol size="4" class="ion-float-right">
                <IonItem lines="none" style={{ "--padding-start": "0px" }}>
                  <IonSelect
                    interfaceOptions={options}
                    onIonChange={(e) => {
                      setTotClientMeetPeriod(e.detail.value);
                      timelinechange(e.detail.value, totClientMeetName);
                    }}
                    style={styles.select}
                    name="salutation"
                    mode="ios"
                    class="custTotMeetSelect"
                    value={TotClientMeetPeriod}
                    placeholder={TotClientMeetPeriod}
                    interface={width < 576 ? "action-sheet" : "popover"}
                  >
                    {timelinedatacustom.map((val) => (
                      <IonSelectOption key={val} value={val}>
                        {val}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                  <IonIcon color="gold" icon={chevronDown} />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow class="margintopn20">
              <IonCol size-xs="12" size-sm="4">
                <IonCard
                  style={styles.schedulecard}
                  className="schCard"
                  class="totProspMeet ion-padding"
                >
                  <IonItem lines="none" style={{ "--padding-start": "0px" }}>
                    <IonIcon
                      style={{ width: 48, height: 48 }}
                      icon={prospectmet}
                    />
                    <IonGrid className="cardInGrid">
                      <IonRow style={{ marginBottom: 8 }}>
                        <IonText
                          className="cardTitle"
                          style={{
                            textAlign: "center",
                            fontSize: 43,
                            fontWeight: 300,
                          }}
                        >
                          {clientMet}/{totClient}
                        </IonText>
                      </IonRow>
                      <IonRow
                        style={{ justifyContent: "left", fontSize: 14 }}
                        class="grayscale4"
                      >
                        <IonLabel>Clients Met</IonLabel>
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                </IonCard>
              </IonCol>
              <IonCol size-xs="12" size-sm="4">
                <IonCard
                  style={styles.schedulecard}
                  className="schCard"
                  class="totProspMeet ion-padding"
                >
                  <IonItem lines="none" style={{ "--padding-start": "0px" }}>
                    <IonIcon
                      style={{ width: 48, height: 48 }}
                      icon={prospectmeeting}
                    />
                    <IonGrid className="cardInGrid">
                      <IonRow style={{ marginBottom: 8 }}>
                        <IonText
                          className="cardTitle"
                          style={{
                            textAlign: "center",
                            fontSize: 43,
                            fontWeight: 300,
                          }}
                        >
                          {clientmeetCnt}/{totClientMeet}
                        </IonText>
                      </IonRow>
                      <IonRow
                        style={{ justifyContent: "left", fontSize: 14 }}
                        class="grayscale4"
                      >
                        <IonLabel>Meetings</IonLabel>
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                </IonCard>
              </IonCol>
              <IonCol size-xs="12" size-sm="4">
                <IonCard
                  style={styles.schedulecard}
                  className="schCard"
                  class="totProspMeet ion-padding"
                >
                  <IonItem lines="none" style={{ "--padding-start": "0px" }}>
                    <IonIcon
                      style={{ width: 48, height: 48 }}
                      icon={prospectflg1}
                    />
                    <IonGrid className="cardInGrid">
                      <IonRow style={{ marginBottom: 8 }}>
                        <IonText
                          className="cardTitle"
                          style={{
                            textAlign: "center",
                            fontSize: 43,
                            fontWeight: 300,
                          }}
                        >
                          {Number.isNaN(clientmeetCnt) ||
                          clientmeetCnt === "-" ||
                          totClientMeet === "-" ||
                          totClientMeet === 0
                            ? "-"
                            : (Number(clientmeetCnt) / Number(totClientMeet)) *
                                100 >
                              0
                            ? (
                                (Number(clientmeetCnt) /
                                  Number(totClientMeet)) *
                                100
                              ).toFixed(1)
                            : 0}
                          %
                        </IonText>
                      </IonRow>
                      <IonRow
                        style={{ justifyContent: "left", fontSize: 14 }}
                        class="grayscale4"
                      >
                        <IonLabel>to Meeting Goal</IonLabel>
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                </IonCard>
              </IonCol>
            </IonRow>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <IonRow class="ion-margin-top">
              <IonCol
                size-xs="6"
                size-sm="5"
                style={{ fontWeight: 400 }}
                className="font18 ion-float-left ion-margin-top"
              >
                <span style={{ whiteSpace: "nowrap" }}>
                  Total Number of Meetings
                </span>
              </IonCol>
              <IonCol size-xs="1" size-sm="4"></IonCol>
              <IonCol size-xs="5" size-sm="3" class="ion-float-right">
                <IonItem lines="none" style={{ "--padding-start": "0px" }}>
                  <IonSelect
                    interfaceOptions={options}
                    onIonChange={(e) => {
                      setTotGenMeetPeriod(e.detail.value);
                      timelinechange(e.detail.value, totGenMeet);
                    }}
                    style={styles.select}
                    name="salutation"
                    mode="ios"
                    class="custTotMeetSelect"
                    value={TotGenMeetPeriod}
                    placeholder={TotGenMeetPeriod}
                    interface={width < 576 ? "action-sheet" : "popover"}
                  >
                    {timelinedatacustom.map((val) => (
                      <IonSelectOption key={val} value={val}>
                        {val}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                  <IonIcon color="gold" icon={chevronDown} />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow class="margintopn20">
              <IonCol size-xs="12" size-sm="4">
                <IonCard
                  style={styles.schedulecard}
                  className="schCard"
                  class="totProspMeet ion-padding"
                >
                  <IonItem lines="none" style={{ "--padding-start": "0px" }}>
                    <IonIcon
                      style={{ width: 48, height: 48 }}
                      icon={prospectmet}
                    />
                    <IonGrid className="cardInGrid">
                      <IonRow style={{ marginBottom: 8 }}>
                        <IonText
                          className="cardTitle"
                          style={{
                            textAlign: "center",
                            fontSize: 43,
                            fontWeight: 300,
                          }}
                        >
                          {genallMeetings}/{gentotAssigned}
                        </IonText>
                      </IonRow>
                      <IonRow
                        style={{ justifyContent: "left", fontSize: 14 }}
                        class="grayscale4"
                      >
                        <IonLabel>Meetings by RM</IonLabel>
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                </IonCard>
              </IonCol>
              <IonCol size-xs="12" size-sm="4">
                <IonCard
                  style={styles.schedulecard}
                  className="schCard"
                  class="totProspMeet ion-padding"
                >
                  <IonItem lines="none" style={{ "--padding-start": "0px" }}>
                    <IonIcon
                      style={{ width: 48, height: 48 }}
                      icon={prospectmeeting}
                    />
                    <IonGrid className="cardInGrid">
                      <IonRow style={{ marginBottom: 8 }}>
                        <IonText
                          className="cardTitle"
                          style={{
                            textAlign: "center",
                            fontSize: 43,
                            fontWeight: 300,
                          }}
                        >
                          {genTotMeetings}/{gentotGoal}
                        </IonText>
                      </IonRow>
                      <IonRow
                        style={{ justifyContent: "left", fontSize: 14 }}
                        class="grayscale4"
                      >
                        <IonLabel>Meetings for Team</IonLabel>
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                </IonCard>
              </IonCol>
              <IonCol size-xs="12" size-sm="4">
                <IonCard
                  style={styles.schedulecard}
                  className="schCard"
                  class="totProspMeet ion-padding"
                >
                  <IonItem lines="none" style={{ "--padding-start": "0px" }}>
                    <IonIcon
                      style={{ width: 48, height: 48 }}
                      icon={prospectflg1}
                    />
                    <IonGrid className="cardInGrid">
                      <IonRow style={{ marginBottom: 8 }}>
                        <IonText
                          className="cardTitle"
                          style={{
                            textAlign: "center",
                            fontSize: 43,
                            fontWeight: 300,
                          }}
                        >
                          {Number.isNaN(genTotMeetings) ||
                          genTotMeetings === "-" ||
                          gentotGoal === "-" ||
                          gentotGoal === 0
                            ? "-"
                            : (Number(genTotMeetings) / Number(gentotGoal)) *
                                100 >
                              0
                            ? (
                                (Number(genTotMeetings) / Number(gentotGoal)) *
                                100
                              ).toFixed(1)
                            : 0}
                          %
                        </IonText>
                      </IonRow>
                      <IonRow
                        style={{ justifyContent: "left", fontSize: 14 }}
                        class="grayscale4"
                      >
                        <IonLabel>to Meeting Goal</IonLabel>
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                </IonCard>
              </IonCol>
            </IonRow>
          </React.Fragment>
        )}

        {/* Prospects Stats */}
        {props.type == "prospects" ? (
          <React.Fragment>
            <IonRow class="ion-margin-top">
              <IonCol
                size="5"
                className="font18 align-mobile"
                style={{ fontWeight: 400 }}
              >
                Prospect Stats
              </IonCol>
              <IonCol size-xs="2" size-sm="4"></IonCol>
              <IonCol size-xs="5" size-sm="3" class="ion-float-right">
                <IonItem
                  mode="md"
                  lines="none"
                  style={{ "--padding-start": "0px" }}
                >
                  <IonSelect
                    interfaceOptions={options}
                    style={styles.select}
                    name="salutation"
                    mode="ios"
                    class="custTotMeetSelect"
                    value={ProspStatPeriod}
                    onIonChange={(e) => {
                      setProspStatPeriod(e.detail.value);
                      timelinechange(e.detail.value, ProsStatistics);
                    }}
                    // placeholder="Select Salutation"
                    placeholder={ProspStatPeriod}
                    interface={width < 576 ? "action-sheet" : "popover"}
                  >
                    {timelinedatacustom.map((val) => (
                      <IonSelectOption key={val} value={val}>
                        {val}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                  <IonIcon color="gold" icon={chevronDown} />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow style={{ visibility: "inherit" }} class="margintopn20">
              <IonCol size-xs="12" size-sm="4">
                <IonCard
                  onClick={(e) => {
                    callProspectDropped();
                  }}
                  style={styles.schedulecardupd}
                  className="schCard"
                  class="ProspStat ion-padding"
                >
                  <IonItem lines="none" style={{ "--padding-start": "0px" }}>
                    <IonIcon
                      style={{ width: 48, height: 48 }}
                      icon={persondown}
                    />
                    <IonGrid className="cardInGrid">
                      <IonRow style={{ marginBottom: 8 }}>
                        <IonText
                          className="cardTitle"
                          style={{
                            textAlign: "center",
                            fontSize: 43,
                            fontWeight: 300,
                          }}
                        >
                          {droppedCnt}
                        </IonText>
                      </IonRow>
                      <IonRow
                        style={{ justifyContent: "left", fontSize: 14 }}
                        class="grayscale4"
                      >
                        <IonLabel>Dropped</IonLabel>
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                </IonCard>
              </IonCol>
              <IonCol size-xs="12" size-sm="4">
                <IonCard
                  style={styles.schedulecard}
                  className="schCard"
                  class="ProspStat ion-padding"
                >
                  <IonItem lines="none" style={{ "--padding-start": "0px" }}>
                    <IonIcon
                      style={{ width: 48, height: 48 }}
                      icon={prospectclock}
                    />
                    <IonGrid className="cardInGrid">
                      <IonRow style={{ marginBottom: 8 }}>
                        <IonText
                          className="cardTitle"
                          style={{
                            textAlign: "center",
                            fontSize: 43,
                            fontWeight: 300,
                          }}
                        >
                          {timetoConvert}
                          <IonText style={{ fontSize: 14, fontWeight: 300 }}>
                            {" "}
                            week avg.
                          </IonText>
                        </IonText>
                      </IonRow>
                      <IonRow
                        style={{ justifyContent: "left", fontSize: 14 }}
                        class="grayscale4"
                      >
                        <IonLabel>Time to Convert</IonLabel>
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                </IonCard>
              </IonCol>
              <IonCol size-xs="12" size-sm="4">
                <IonCard
                  onClick={(e) => {
                    callProspectReferral();
                  }}
                  style={styles.schedulecardupd}
                  className="schCard"
                  class="ProspStat ion-padding"
                >
                  <IonItem lines="none" style={{ "--padding-start": "0px" }}>
                    <IonIcon
                      style={{ width: 48, height: 48 }}
                      icon={prospectflg2}
                    />
                    <IonGrid className="cardInGrid">
                      <IonRow style={{ marginBottom: 8 }}>
                        <IonText
                          className="cardTitle"
                          style={{
                            textAlign: "center",
                            fontSize: 43,
                            fontWeight: 300,
                          }}
                        >
                          {refCnt}
                        </IonText>
                      </IonRow>
                      <IonRow
                        style={{ justifyContent: "left", fontSize: 14 }}
                        class="grayscale4"
                      >
                        <IonLabel>Referrals</IonLabel>
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                </IonCard>
              </IonCol>
            </IonRow>
          </React.Fragment>
        ) : props.type == "clients" ? (
          <React.Fragment>
            <IonRow class="ion-margin-top">
              <IonCol size="5" className="font18" style={{ fontWeight: 400 }}>
                <span style={{ whiteSpace: "nowrap" }}>
                  Current Client Status
                </span>
              </IonCol>
              <IonCol size="4"></IonCol>
              <IonCol size="3" class="ion-float-right">
                {/* <IonItem  mode="md" lines="none" style={{ "--padding-start": "0px" }}>
                          <IonSelect 
                          interfaceOptions={options}
                          style={styles.select}
                          name="salutation"
                          mode="ios"
                          class="customIonSelect"
                          value={ProspStatDate.value} 
                          onIonChange={(e) => {timelinechange(e.detail.value,ProsStatistics)}} 
                          placeholder="Select Salutation"
                          interface="popover">
                              {
                                  timelinedata.map((val) => (
                                      <IonSelectOption key={val} value={val}>{val}</IonSelectOption>
                                  ))
                              }
                          </IonSelect>
                          <IonIcon color="gold" icon={chevronDown} />
                      </IonItem> */}
              </IonCol>
            </IonRow>
            <IonRow style={{ visibility: "inherit" }} class="">
              <IonCol size="4">
                <IonCard
                  style={styles.schedulecard}
                  className="schCard"
                  class="ProspStat ion-padding"
                >
                  <IonItem lines="none" style={{ "--padding-start": "0px" }}>
                    <IonIcon
                      style={{ width: 48, height: 48 }}
                      icon={avgportfolio}
                    />
                    <IonGrid className="cardInGrid">
                      <IonRow style={{ marginBottom: 8 }}>
                        <IonIcon
                          className="clientstatdolicon"
                          icon={dollar}
                          color="white"
                          style={{
                            position: "absolute",
                            height: "17px!important",
                            left: 58,
                            top: 10,
                          }}
                        ></IonIcon>
                        <IonText
                          className="cardTitle"
                          style={{
                            textAlign: "center",
                            fontSize: 43,
                            fontWeight: 300,
                          }}
                        >
                          {retExpClsAmt(totalAUM)}
                          <IonText style={{ fontSize: 14, fontWeight: 300 }}>
                            per client
                          </IonText>
                        </IonText>
                      </IonRow>
                      <IonRow
                        style={{ justifyContent: "left", fontSize: 14 }}
                        class="grayscale4"
                      >
                        <IonLabel>Avg. Portfolio Size</IonLabel>
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                </IonCard>
              </IonCol>
              <IonCol size="4">
                <IonCard
                  style={styles.schedulecard}
                  className="schCard"
                  class="ProspStat ion-padding"
                >
                  <IonItem lines="none" style={{ "--padding-start": "0px" }}>
                    <IonIcon style={{ width: 48, height: 48 }} icon={managed} />
                    <IonGrid className="cardInGrid">
                      <IonRow style={{ marginBottom: 8 }}>
                        <IonText
                          className="cardTitle"
                          style={{
                            textAlign: "center",
                            fontSize: 43,
                            fontWeight: 300,
                          }}
                        >
                          {totalManagedClients}
                        </IonText>
                      </IonRow>
                      <IonRow
                        style={{ justifyContent: "left", fontSize: 14 }}
                        class="grayscale4"
                      >
                        <IonLabel>Managed</IonLabel>
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                </IonCard>
              </IonCol>
              <IonCol size="4">
                <IonCard
                  onClick={(e) => {
                    callClientReinvestment();
                  }}
                  style={styles.schedulecard}
                  className="schCard"
                  class="ProspStat ion-padding"
                >
                  <IonItem
                    lines="none"
                    style={{ "--padding-start": "0px", cursor: "pointer" }}
                  >
                    <IonIcon
                      style={{ width: 48, height: 48 }}
                      icon={reinvestment}
                    />
                    <IonGrid className="cardInGrid">
                      <IonRow style={{ marginBottom: 8 }}>
                        <IonIcon
                          className="clientstatdolicon"
                          icon={dollar}
                          color="white"
                          style={{
                            position: "absolute",
                            height: "17px!important",
                            left: 58,
                            top: 10,
                          }}
                        ></IonIcon>
                        <IonText
                          className="cardTitle"
                          style={{
                            textAlign: "center",
                            fontSize: 43,
                            fontWeight: 300,
                          }}
                        >
                          {totalReinvestment}
                        </IonText>
                      </IonRow>
                      <IonRow
                        style={{ justifyContent: "left", fontSize: 14 }}
                        class="grayscale4"
                      >
                        <IonLabel>Reinvestment YTD</IonLabel>
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                </IonCard>
              </IonCol>
            </IonRow>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <IonRow class="ion-margin-top">
              <IonCol
                size="5"
                sizeXs="12"
                className="font18"
                style={{ fontWeight: 400 }}
              >
                Current Uncalled Commitments
              </IonCol>
              <IonCol size="4"></IonCol>
              <IonCol size="3" class="ion-float-right">
                {/* <IonItem  mode="md" lines="none" style={{ "--padding-start": "0px" }}>
                          <IonSelect 
                          interfaceOptions={options}
                          style={styles.select}
                          name="salutation"
                          mode="ios"
                          class="customIonSelect"
                          value={ProspStatDate.value} 
                          onIonChange={(e) => {timelinechange(e.detail.value,ProsStatistics)}} 
                          placeholder="Select Salutation"
                          interface="popover">
                              {
                                  timelinedata.map((val) => (
                                      <IonSelectOption key={val} value={val}>{val}</IonSelectOption>
                                  ))
                              }
                          </IonSelect>
                          <IonIcon color="gold" icon={chevronDown} />
                      </IonItem> */}
              </IonCol>
            </IonRow>
            <IonRow style={{ visibility: "inherit" }} class="">
              <IonCol size="4">
                <IonCard
                  style={styles.schedulecard}
                  className="schCard"
                  class="ProspStat ion-padding"
                >
                  <IonItem lines="none" style={{ "--padding-start": "0px" }}>
                    <IonIcon style={{ width: 48, height: 48 }} icon={mytotal} />
                    <IonGrid className="cardInGrid">
                      <IonRow style={{ marginBottom: 8 }}>
                        <IonIcon
                          className="clientstatdolicon"
                          icon={dollar}
                          color="white"
                          style={{
                            position: "absolute",
                            height: "17px!important",
                            left: 58,
                            top: 10,
                          }}
                        ></IonIcon>
                        <IonText
                          className="cardTitle"
                          style={{
                            textAlign: "center",
                            fontSize: 43,
                            fontWeight: 300,
                          }}
                        >
                          {retExpClsAmt(mytotalgen)}
                          {/* <IonText style={{ fontSize: 14, fontWeight: 300 }}>
                          per client
                        </IonText> */}
                        </IonText>
                      </IonRow>
                      <IonRow
                        style={{ justifyContent: "left", fontSize: 14 }}
                        class="grayscale4"
                      >
                        <IonLabel>My Total</IonLabel>
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                </IonCard>
              </IonCol>
              <IonCol size="4">
                <IonCard
                  style={styles.schedulecard}
                  className="schCard"
                  class="ProspStat ion-padding"
                >
                  <IonItem lines="none" style={{ "--padding-start": "0px" }}>
                    <IonIcon
                      style={{ width: 48, height: 48 }}
                      icon={teamtotal}
                    />
                    <IonGrid className="cardInGrid">
                      <IonRow style={{ marginBottom: 8 }}>
                        <IonIcon
                          className="clientstatdolicon"
                          icon={dollar}
                          color="white"
                          style={{
                            position: "absolute",
                            height: "17px!important",
                            left: 58,
                            top: 10,
                          }}
                        ></IonIcon>
                        <IonText
                          className="cardTitle"
                          style={{
                            textAlign: "center",
                            fontSize: 43,
                            fontWeight: 300,
                          }}
                        >
                          {retExpClsAmt(teamtotalgen)}
                        </IonText>
                      </IonRow>
                      <IonRow
                        style={{ justifyContent: "left", fontSize: 14 }}
                        class="grayscale4"
                      >
                        <IonLabel>Team Total</IonLabel>
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                </IonCard>
              </IonCol>
              <IonCol size="4">
                <IonCard
                  style={styles.schedulecard}
                  className="schCard"
                  class="ProspStat ion-padding"
                >
                  <IonItem lines="none" style={{ "--padding-start": "0px" }}>
                    <IonIcon
                      style={{ width: 48, height: 48 }}
                      icon={reinvestment}
                    />
                    <IonGrid className="cardInGrid">
                      <IonRow style={{ marginBottom: 8 }}>
                        {/* <IonIcon
                        className="clientstatdolicon"
                        icon={dollar}
                        color="white"
                        style={{
                          position: "absolute",
                          height: "17px!important",
                          left: 58,
                          top: 10,
                        }}
                      ></IonIcon> */}
                        <IonText
                          className="cardTitle"
                          style={{
                            textAlign: "center",
                            fontSize: 43,
                            fontWeight: 300,
                          }}
                        >
                          {teamtotalgen == 0
                            ? 0
                            : ((mytotalgen / teamtotalgen) * 100).toFixed(2)}
                          %
                        </IonText>
                      </IonRow>
                      <IonRow
                        style={{ justifyContent: "left", fontSize: 14 }}
                        class="grayscale4"
                      >
                        <IonLabel>% of commitments</IonLabel>
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                </IonCard>
              </IonCol>
            </IonRow>
          </React.Fragment>
        )}
        {props.type == "prospects" ? (
          <React.Fragment>
            <IonItem style={{ marginLeft: "-20px" }} lines="none">
              <IonLabel
                className="font18  align-mobile"
                style={{ whiteSpace: "break-spaces" }}
              >
                Top Targets to Pursue
              </IonLabel>
              <IonButton
                className="font14"
                fill="clear"
                size="small"
                mode="ios"
                color="gold"
                slot="end"
                routerLink="/prospects/targets"
              >
                View All Targets
              </IonButton>
            </IonItem>
            <TopTargets />
          </React.Fragment>
        ) : props.type == "clients" ? (
          <ClientData />
        ) : null}

        {props.type == "prospects" || props.type == "clients" ? (
          <React.Fragment>
            <IonItem
              class="ion-margin-top"
              lines="none"
              style={{ "--padding-start": "0px" }}
            >
              <IonLabel className="font18" style={{ fontWeight: 400 }}>
                Upcoming Schedule
              </IonLabel>
              <IonButton
                className="font14"
                fill="clear"
                mode="ios"
                size="small"
                color="gold"
                slot="end"
                routerLink="/calendar"
              >
                View Calendar
              </IonButton>
            </IonItem>
            <UpcomingSch
              type={props.type == "prospects" ? "prospect" : "client"}
            />
            <IonItem
              class="ion-margin-top"
              lines="none"
              style={{ "--padding-start": "0px" }}
            >
              <IonLabel className="font18">
                {props.type == "prospects"
                  ? "Next 5 Prospects To-Do Items"
                  : "Next 5 Clients To-Do Items"}
              </IonLabel>
              <IonButton
                className="font14"
                fill="clear"
                mode="ios"
                size="small"
                color="gold"
                slot="end"
                routerLink="/todo"
              >
                View All To-Do Items
              </IonButton>
            </IonItem>
            <NextTodos
              type={props.type == "prospects" ? "prospect" : "client"}
            />
          </React.Fragment>
        ) : null}
        {props.type == "prospects" ? (
          <IonItem
            class="ion-margin-top"
            lines="none"
            detail={false}
            style={{ "--padding-start": "0px" }}
          >
            <IonLabel className="font18" style={{ fontWeight: 400 }}>
              {props.type == "prospects"
                ? "Prospect Geographies"
                : "Client Geographies"}
            </IonLabel>
            <IonItem lines="none" style={{ "--padding-start": "0px" }}>
              <IonSelect
                interfaceOptions={options}
                style={styles.select}
                name="salutation"
                mode="ios"
                class="customIonSelect"
                value={ProspCntryDate.value}
                onIonChange={(e) => {
                  timelinechange(e.detail.value, ProsGeographies);
                }}
                placeholder="Select Salutation"
                interface={width < 576 ? "action-sheet" : "popover"}
              >
                {timelinedata.map((val) => (
                  <IonSelectOption key={val} value={val}>
                    {val}
                  </IonSelectOption>
                ))}
              </IonSelect>
              <IonIcon color="gold" icon={chevronDown} />
            </IonItem>
          </IonItem>
        ) : (
          ""
        )}

        {props.type == "prospects" ? (
          <IonRow class="margintopn20">
            <IonCol
              size-xs="12"
              size-sm="3"
              className="spaceAlingRight"
              style={{ margin: "auto" }}
            >
              <IonCard
                className="schCard CardGeo CardGeoMobile"
                onClick={(e) => {
                  callStateView();
                }}
              >
                <IonItem lines="none" style={{ "--padding-start": "10px" }}>
                  <IonGrid style={{ textAlign: "center" }}>
                    <IonRow>
                      <IonIcon className="GeoIcon" icon={saudiarabia} />
                    </IonRow>
                    <IonRow className="titleRow">
                      <IonText
                        className="cardTitleGeo"
                        style={{ fontSize: 43, fontWeight: 300 }}
                      >
                        {saudiCnt + kuwaitCnt + bahrainCnt + worldCnt == 0
                          ? 0
                          : ((saudiCnt * 100) / totalCntryCnt).toFixed(1)}
                        %
                      </IonText>
                      <IonText class="cardSubTitleGeo grayscale4">
                        ({saudiCnt})
                      </IonText>
                    </IonRow>
                    <IonRow>
                      <IonText class="cardCountryGeo grayscale4">
                        Saudi Arabia
                      </IonText>
                    </IonRow>
                  </IonGrid>
                </IonItem>
              </IonCard>
            </IonCol>
            <IonCol size-xs="12" size-sm="3" className="spaceAlingRight">
              <IonCard
                className="schCard CardGeo CardGeoMobile"
                onClick={(e) => {
                  callStateView();
                }}
              >
                <IonItem lines="none" style={{ "--padding-start": "10px" }}>
                  <IonGrid style={{ textAlign: "center" }}>
                    <IonRow>
                      <IonIcon className="GeoIcon" icon={bahrain} />
                    </IonRow>
                    <IonRow className="titleRow">
                      <IonText
                        className="cardTitleGeo"
                        style={{ fontSize: 43, fontWeight: 300 }}
                      >
                        {saudiCnt + kuwaitCnt + bahrainCnt + worldCnt == 0
                          ? 0
                          : ((bahrainCnt * 100) / totalCntryCnt).toFixed(1)}
                        %
                      </IonText>
                      <IonText class="cardSubTitleGeo grayscale4">
                        ({bahrainCnt})
                      </IonText>
                    </IonRow>
                    <IonRow>
                      <IonText class="cardCountryGeo grayscale4">
                        Bahrain
                      </IonText>
                    </IonRow>
                  </IonGrid>
                </IonItem>
              </IonCard>
            </IonCol>
            <IonCol size-xs="12" size-sm="3" className="spaceAlingRight">
              <IonCard
                className="schCard CardGeo CardGeoMobile"
                onClick={(e) => {
                  callStateView();
                }}
              >
                <IonItem lines="none" style={{ "--padding-start": "10px" }}>
                  <IonGrid style={{ textAlign: "center" }}>
                    <IonRow>
                      <IonIcon className="GeoIcon" icon={kuwait} />
                    </IonRow>
                    <IonRow className="titleRow">
                      <IonText
                        className="cardTitleGeo"
                        style={{ fontSize: 43, fontWeight: 300 }}
                      >
                        {saudiCnt + kuwaitCnt + bahrainCnt + worldCnt == 0
                          ? 0
                          : ((kuwaitCnt * 100) / totalCntryCnt).toFixed(1)}
                        %
                      </IonText>
                      <IonText class="cardSubTitleGeo grayscale4">
                        ({kuwaitCnt})
                      </IonText>
                    </IonRow>
                    <IonRow>
                      <IonText class="cardCountryGeo grayscale4">
                        Kuwait
                      </IonText>
                    </IonRow>
                  </IonGrid>
                </IonItem>
              </IonCard>
            </IonCol>
            <IonCol size-xs="12" size-sm="3" className="spaceAlingRight">
              <IonCard
                className="schCard CardGeo CardGeoMobile"
                onClick={(e) => {
                  callStateView();
                }}
              >
                <IonItem lines="none" style={{ "--padding-start": "10px" }}>
                  <IonGrid style={{ textAlign: "center" }}>
                    <IonRow>
                      <IonIcon className="GeoIcon" icon={world} />
                    </IonRow>
                    <IonRow className="titleRow">
                      <IonText
                        className="cardTitleGeo"
                        style={{ fontSize: 43, fontWeight: 300 }}
                      >
                        {saudiCnt + kuwaitCnt + bahrainCnt + worldCnt == 0
                          ? 0
                          : ((worldCnt * 100) / totalCntryCnt).toFixed(1)}
                        %
                      </IonText>
                      <IonText class="cardSubTitleGeo grayscale4">
                        ({worldCnt})
                      </IonText>
                    </IonRow>
                    <IonRow>
                      <IonText class="cardCountryGeo grayscale4">World</IonText>
                    </IonRow>
                  </IonGrid>
                </IonItem>
              </IonCard>
            </IonCol>
          </IonRow>
        ) : (
          ""
        )}
        <IonRow style={{ margintop: "10px" }}>
          {props.type == "prospects" || props.type == "clients" ? (
            <IonCol
              className="spaceAlingRight"
              size-xs="12"
              size-sm="12"
              size-md="3"
              style={{ paddingLeft: 0 }}
            >
              <IonCard
                class="schCard CardGeo CardGeoMobile"
                style={{
                  backgroundColor: "#1a1a1a",
                  margin: 0,
                  height: "100%",
                }}
              >
                <IonItem
                  lines="none"
                  class="item md item-lines-none ion-focusable"
                  style={{ "--paddingStart": "10px", height: "100%" }}
                >
                  <IonGrid class="" style={{ textAlign: "center" }}>
                    <IonIcon
                      class="GeoIcon"
                      icon={briefcase}
                      color="gold"
                      style={{
                        marginTop: "25px",
                        marginBottom: "10px",
                        width: "100px",
                        height: "25px",
                      }}
                    ></IonIcon>
                    <IonRow class="titleRow">
                      <IonText
                        class="cardTitleGeo"
                        style={{
                          fontSize: "43px",
                          fontWeight: "300",
                          margin: "0 auto",
                        }}
                      >
                        {props.type == "prospects" ? totalProsp : totClient}
                      </IonText>
                    </IonRow>
                    <IonRow class="">
                      <IonText class="cardCountryGeo grayscale4 ">
                        {props.type == "prospects"
                          ? "Total number of prospects you manage"
                          : "Total number of clients you manage"}
                      </IonText>
                    </IonRow>
                  </IonGrid>
                </IonItem>
              </IonCard>
            </IonCol>
          ) : (
            ""
          )}
          {props.type == "prospects" || props.type == "clients" ? (
            <IonCol size="9">
              <IonItem
                class="ion-margin-top"
                lines="none"
                style={{ "--padding-start": "0px" }}
              >
                <IonLabel
                  className="font18  align-mobile"
                  style={{ whiteSpace: "break-spaces" }}
                >
                  {props.type == "prospects"
                    ? "Prospect Persona Breakdown"
                    : "Clients Persona Breakdown"}
                </IonLabel>
                <IonItem lines="none" style={{ "--padding-start": "0px" }}>
                  <IonSelect
                    interfaceOptions={options}
                    style={styles.select}
                    name="salutation"
                    mode="ios"
                    class="customIonSelect"
                    onIonChange={(e) => {
                      timelinechange(e.detail.value, ProsPersonaBreakdown);
                    }}
                    value={ProspPersonaDate.value}
                    placeholder="Select Salutation"
                    interface={width < 576 ? "action-sheet" : "popover"}
                  >
                    {timelinedata.map((val) => (
                      <IonSelectOption key={val} value={val}>
                        {val}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                  <IonIcon color="gold" icon={chevronDown} />
                </IonItem>
              </IonItem>
              <IonRow class="margintopn20">
                <PersonaBreakdown personaData={personaData} />
              </IonRow>
            </IonCol>
          ) : (
            ""
          )}
          {props.type != "prospects" && props.type != "clients" ? (
            <IonCol size="12">
              <IonItem
                class="ion-margin-top"
                lines="none"
                style={{ "--padding-start": "0px" }}
              >
                <IonLabel
                  className="font18  align-mobile"
                  style={{ whiteSpace: "break-spaces" }}
                >
                  {props.type == "prospects"
                    ? "Prospect Persona Breakdown"
                    : "Clients Persona Breakdown"}
                </IonLabel>
                <IonItem lines="none" style={{ "--padding-start": "0px" }}>
                  <IonSelect
                    interfaceOptions={options}
                    style={styles.select}
                    name="salutation"
                    mode="ios"
                    class="customIonSelect"
                    onIonChange={(e) => {
                      timelinechange(e.detail.value, ProsPersonaBreakdown);
                    }}
                    value={ProspPersonaDate.value}
                    placeholder="Select Salutation"
                    interface={width < 576 ? "action-sheet" : "popover"}
                  >
                    {timelinedata.map((val) => (
                      <IonSelectOption key={val} value={val}>
                        {val}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                  <IonIcon color="gold" icon={chevronDown} />
                </IonItem>
              </IonItem>
              <IonRow class="margintopn20">
                <PersonaBreakdown personaData={personaData} />
              </IonRow>
            </IonCol>
          ) : (
            ""
          )}
        </IonRow>

        <IonRow className="ion-margin-top ion-margin-bottom"></IonRow>
        <IonPopover
          cssClass="fromToDate marlft8"
          isOpen={DatePopover.open}
          event={DatePopover.event}
          onDidDismiss={(e) =>
            setDatePopover({ open: false, event: undefined })
          }
        >
          {/*sree */}

          <IonGrid class="dateSelectPop">
            <IonRow class="row rmvtarget marlft100">
              {width > 576 ? (
                <IonItem>
                  <IonLabel style={{ paddingLeft: "5%" }}>Start Date</IonLabel>
                  <IonLabel>
                    <CustDatePicker
                      dateFormat="DD-MMM-YYYY"
                      date={SelectedStartDate}
                      onSetDateVal={(val) => {
                        setSelectedStartDate(val!);
                      }}
                    />
                  </IonLabel>
                </IonItem>
              ) : (
                <IonItem>
                  <IonLabel>Start Date</IonLabel>
                  <IonDatetime
                    value={SelectedStartDate}
                    displayFormat="DD-MMM-YYYY"
                    onIonChange={(e) => setSelectedStartDate(e.detail.value!)}
                    placeholder="Select Date"
                  ></IonDatetime>
                </IonItem>
              )}
            </IonRow>
            <IonRow class="conrmvtarget marlft100">
              {width > 576 ? (
                <IonItem>
                  <IonLabel style={{ paddingLeft: "5%" }}>End Date</IonLabel>
                  <IonLabel>
                    <CustDatePicker
                      dateFormat="DD-MMM-YYYY"
                      date={SelectedEndDate}
                      disabledDate={disabledDate}
                      // min={SelectedStartDate}
                      onSetDateVal={(val) => {
                        setSelectedEndDate(val!);
                      }}
                    />
                  </IonLabel>
                </IonItem>
              ) : (
                <IonItem>
                  <IonLabel>End Date</IonLabel>
                  <IonDatetime
                    value={SelectedEndDate}
                    min={SelectedStartDate}
                    displayFormat="DD-MMM-YYYY"
                    onIonChange={(e) => setSelectedEndDate(e.detail.value!)}
                    placeholder="Select Date"
                  ></IonDatetime>
                </IonItem>
              )}
            </IonRow>
            <IonFooter class="conrmvfooter1 marlft60">
              <IonRow>
                {/* <IonCol>
                      <IonButton
                        onClick={() =>
                          setDatePopover({
                            open: false,
                            event: undefined,
                          })
                        }
                        class="ConfirmationButton"
                        fill="clear"
                      >
                        Cancel
                      </IonButton>
                    </IonCol>  */}
                <IonCol>
                  <IonButton
                    onClick={() => {
                      setDatePopover({
                        open: false,
                        event: undefined,
                      });

                      callApiFunc(selectedDropDown);
                    }}
                    class="ConfirmationButton"
                    fill="clear"
                  >
                    Ok
                    {/* hema */}
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonFooter>
          </IonGrid>
        </IonPopover>
      </IonGrid>
    </React.Fragment>
  );
};

export default Prospects;
