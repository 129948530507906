import { IonItem, IonText, IonIcon, IonGrid, IonButton } from "@ionic/react";
import { ellipse } from "ionicons/icons";
import file from "src/icons/file.svg";
import React, { useState, useEffect } from "react";
import "./Documents.css";
import api from "src/api";
import CentralLibrary from "./CentralLibrary";
import { useHistory } from "react-router";

const styles = {
  grid: {
    marginLeft: 32,
    marginRight: 32,
    marginTop: 32
  },
  item: {
    "--background": "#222222",
    marginBottom: 2,
    borderRadius: 8,
  },
  period: {
    fontSize: 6,
    marginLeft: 4,
    marginRight: 4,
    marginTop: 8,
    color: "#828282",
  },
  size: {
    color: "#828282",
  },
  filtericonItem: {
    paddingRight: 0,
  },
  txt: {
    paddingTop: 23.5,
    paddingBottom: 23.5,
    fontWeight: "700",
  },
};

interface docProps {
  engagements: any;
}

interface docEngObj {
  attach: string;
}

interface sArr extends Array<docEngObj> {}
// function CentralLibrary: any (
//   <CentralLibrary/>
// )

const Documents: React.FC<docProps> = ({ engagements }) => {
  const [docArr, setDocArr] = useState<sArr>([]);
  const [attArr, setAttArr] = useState<any>([]);
  const[centlib, setCentralLibrary] = useState<any>([]);
  const history = useHistory()
  // const centlib = () => {
  //   <CentralLibrary/>
  // };

  function callCentralLibrary(): void {
    
    history.push("/CentralLibrary");
  }
 
  useEffect(() => {
    if (engagements) {
      let tmpEngArr: any = [];
      const res = engagements.results
      if (res && res.length > 0) {
        // console.log(res);
        res.forEach((obj) => {
          let att: any = [];
          att = obj.attachments;
          if (att.length > 0) {
            // console.log(att);
            att.forEach((o) => {
              tmpEngArr.push({
                attach: o.id,
              });
            });

            // setDocArr(
            //   att.map((attObj) => {
            //     return {
            //       attach: attObj.id,
            //     };
            //   })
            // );
          }
        });
        setDocArr(tmpEngArr);
      }
    }
  }, [engagements]);

  useEffect(() => {
    // console.log(docArr);
    let tmpArr: any = [];
    if (docArr.length > 0) {
      let promises: any = [];
      docArr.forEach((doc) => {
        let docid = doc.attach;
        promises.push(
          new Promise((res) => {
            api.getDocumentsById(docid).then((resp) => {
              tmpArr.push({
                ext: resp.extension,
                name: resp.name,
                size: resp.size,
                id: resp.id,
              });
              res("success");
            })
            .catch((e) => {
              console.log(JSON.stringify(e))
            })
          })
        );
      });
      Promise.all(promises).then(() => {
        setAttArr(tmpArr);
      });
    }
  }, [docArr]);

  // function CentralLibrary (
  //   <CentralLibrary/>
  // )
  return (
    <IonGrid style={styles.grid}>
      <IonItem style={styles.filtericonItem} lines="none" >
        {/* <IonButton
          fill="clear"
          color="gold"
          slot="end"
          // onClick(e)={<CentralLibrary/>}
          onClick={(e) => {
            callCentralLibrary();
          }}
        >
          View Central Library
          </IonButton>
           */}
      </IonItem>
<IonGrid>
      {attArr.map((attachment) => ( 
        <IonItem key={attachment.id} className="CardIonItem" style={styles.item} lines="none">
          <IonIcon size="large" slot="start" icon={file} />
          <IonText style={styles.txt}>
            {attachment.name + "." + attachment.ext}
          </IonText>
          <IonIcon style={styles.period} icon={ellipse}></IonIcon>
          <IonText style={styles.size}>
          {attachment.size > 1000000 
              ? "(" + (attachment.size / 1000000).toFixed(2) + " mb)"
              : "(" + (attachment.size / 1000).toFixed(2) + " kb)"}
         </IonText> 
       <IonIcon
           color="white"
           size="small"
            icon={ellipse}
          slot="end"
         />
       </IonItem> 
       ))} 
       </IonGrid>
      {/* <IonGrid>
        <CentralLibrary/>
      </IonGrid> */}
      
      
    </IonGrid>
  );
};

export default Documents;
