
import { Plugins } from "@capacitor/core";
import { Network } from "@ionic-native/network";
import onConnect from "./onConnect";
import onConnectMeetingNote from "./onConnectMeetingNote";
import { loadMeetingNotesForRM, noteTableCreate } from "src/offlineUtils/DbOperations";
import { loadContactsforRM, prospTableCreate } from "src/offlineUtils/ProspectDbOperations";
import { userLoad, userTableCreate } from "src/offlineUtils/UserOperations";

const { Device } = Plugins;

export const synProsp: () => Promise<any> = async () => {
    onConnect.getDirtyProspects("INSERT").then(Arr => {
        // console.log("Line No 10"+JSON.stringify(Arr))
        if(Arr.length > 0) {
            onConnect.syncProspectCreation(Arr).then(vals => {
                // console.log(JSON.stringify(vals))
            }).catch(e => console.log("Error 14"+JSON.stringify(e)))
        }    
    }).catch(e => console.log("ERROR 15"+JSON.stringify(e)))
}

export const synUpdateProsp: () => Promise<any> = async () => {
onConnect.getDirtyUpdateProspects("UPDATE").then(Arr => {  
    //console.log("onetwo1"+JSON.stringify(Arr))
    // console.log("inside simple console");
   // console.log("onetwo3"+JSON.stringify(Arr))
    let Contactid = Arr[0].id;
    // console.log("onetwo2"+Contactid)
    var key = "id";
    delete Arr[0][key];

    let ArrUpd:any = Arr;
    // console.log("synUpdateProsp25"+JSON.stringify(ArrUpd))
    if(ArrUpd.length > 0) {
       onConnect.syncProspectUpdate(ArrUpd,Contactid).then(vals => {
        //onConnect.syncProspectUpdate(Arr).then(vals => {
            // console.log("syncProspectUpdate"+JSON.stringify(vals))
        }).catch(e => console.log("synUpdatePros 29"+JSON.stringify(e)))
    }    
}).catch(e => console.log("ERROR synUpdateProsp31"+JSON.stringify(e)))
}

export const syncMeetingNotes: () => any = () => {
    onConnectMeetingNote.getdirtyMeetingNotes("INSERT").then(Arr => {
        // console.log("Line 22"+ JSON.stringify(Arr))
        if(Arr.length > 0) {
            onConnectMeetingNote.syncMeetingNoteCreation(Arr).then(vals => {
                // console.log("Line 25"+ JSON.stringify(vals))
            }).catch(e => console.log("Error 26"+JSON.stringify(e)))
        }
    })
}


export const initNetworkListen: () => any = async () => {
    const infoA = await Device.getInfo()
    if(infoA.platform === "android" || infoA.platform === "ios") {
        await noteTableCreate()
        await prospTableCreate()
        await userTableCreate()
        await userLoad()
        await loadMeetingNotesForRM();
        await loadContactsforRM();
        Network.onConnect().subscribe( async () => {
        await synProsp()
        await synUpdateProsp()
        syncMeetingNotes()
        })
    }
}


