import { IonPage, IonHeader, IonToolbar, IonSearchbar, IonContent, IonGrid, IonRow, IonCol, IonButton, IonInput } from '@ionic/react'
import React, { HtmlHTMLAttributes, RefObject, useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import Results from "./Results/Results";
import { Plugins } from "@capacitor/core";
import "./ProspectSearch.css"
import useWindowDimensions from "src/components/useWindowDimensions";
const { Device } = Plugins
const styles = {
    btncol: {
        display: "flex"
    },
    btn: {
        marginTop: "auto",
        marginBottom: "auto"
    },
}

const ProspectSearch = (props) => {

        

    const history = useHistory()
    const [srchQry, setsrchQry] = useState<string>("");
    const[queryName,setqueryName] = useState<string>("name");
    const [clrSrch, setclrSrch] = useState<boolean>(false)
    const [deviceInfo, setdeviceInfo] = useState<any>();

    //const inpref: RefObject<HTMLIonInputElement> = React.createRef<HTMLIonInputElement>();

    const inpref: RefObject<HTMLIonSearchbarElement> = React.createRef<HTMLIonSearchbarElement>();

    const handleChange = (e) => {      
        //console.log(e.target.value);  
        //console.log(Number.isInteger(e.target.value))
        //console.log(isNaN(e.target.value));
        // if(e.keyCode === 13)
        // {
        //     setsrchQry(e.target.value);
        // }
        
        if(e.target.value.length >= 3 && isNaN(e.target.value))
        {           
            
            setqueryName("name");
            setsrchQry(e.target.value);     
            setTimeout(() => {inpref.current?.setFocus(); inpref.current?.focus();}, 4000);
            //e.target.focus();
        }
        else if(e.target.value.length >= 3 && !(isNaN(e.target.value)))
        {       
             
            setqueryName("id");
            setsrchQry(e.target.value);     
            setTimeout(() => {inpref.current?.setFocus(); inpref.current?.focus();}, 4000);          
        }
        //setqueryName("id");
    }

    const handleClear = () => {
        setclrSrch(true)
    }
    
    const resetClrSrch = () => {
        setclrSrch(false)
    }
    const { height, width } = useWindowDimensions();

    useEffect(() => {
        Device.getInfo().then(info => {
            setdeviceInfo(info)
        })
    }, [])
    
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar
                class="mobile-toolbar-top"
                    style={{"--background": "#1b1b1b"}}
                >
                    <IonGrid
                        className="ion-margin-horizontal"
                    >
                        <IonRow className="searchbar-pros-client">
                            {deviceInfo && deviceInfo.platform === "web" && width > 1024? 
                            <IonCol sizeLg="1"/> : ""}
                            <IonCol size-xs="9" size-sm="11" sizeLg="9">
                                <IonSearchbar
                                    placeholder="Search by Prospect or Client Name..."
                                    mode="md"
                                    debounce={500}
                                    onIonChange={handleChange}
                                    ref={inpref}
                                    //onKeyUp ={handleChange}
                                    enterkeyhint="search"
                                    onIonClear={handleClear}
                                    style={{"--background": "#222222", "--box-shadow": "none","--padding-top":"0px"}}
                                />
                            </IonCol>
                            <IonCol class="btncol-mobile" size-xs="3" size-sm="1" sizeLg="2" style={ styles.btncol}>
                                <IonButton 
                                    style={styles.btn}
                                    fill="clear"
                                    color="gold"
                                    onClick={() => {
                                        props.forTodo ? props.closeSrch() : history.goBack()
                                    
                                    }}>
                                    Cancel
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonToolbar>
            </IonHeader> 
            <IonContent fullscreen className="search-content">
                <Results queryName={queryName} assocId={props.assocId} forTodo={props.forTodo} setContProps={props.setContProps} srchQry={srchQry} clrSrch={clrSrch} resetClrSrch={resetClrSrch}/>
            </IonContent>
        </IonPage>
    )
}


// const ProspectSearch: React.FC<{
//     forTodo?: boolean;
//     closeSrch?: any;
//     setContProps?: any;
//     assocId?: any;
// }> = ({
//     forTodo,
//     closeSrch,
//     setContProps,
//     assocId
// }) => {
    
//     const history = useHistory()
//     const [srchQry, setsrchQry] = useState<string>("");
//     const[queryName,setqueryName] = useState<string>("name");
//     const [clrSrch, setclrSrch] = useState<boolean>(false)
//     const [deviceInfo, setdeviceInfo] = useState<any>();

//     //const inpref: RefObject<HTMLIonInputElement> = React.createRef<HTMLIonInputElement>();

//     const inpref: RefObject<HTMLIonSearchbarElement> = React.createRef<HTMLIonSearchbarElement>();

//     const handleChange = (e) => {      
//         //console.log(e.target.value);  
//         //console.log(Number.isInteger(e.target.value))
//         //console.log(isNaN(e.target.value));
//         // if(e.keyCode === 13)
//         // {
//         //     setsrchQry(e.target.value);
//         // }
        
//         if(e.target.value.length >= 3 && isNaN(e.target.value))
//         {           
            
//             setqueryName("name");
//             setsrchQry(e.target.value);     
//             setTimeout(() => {inpref.current?.setFocus(); inpref.current?.focus();}, 4000);
//             //e.target.focus();
//         }
//         else if(e.target.value.length >= 3 && !(isNaN(e.target.value)))
//         {       
             
//             setqueryName("id");
//             setsrchQry(e.target.value);     
//             setTimeout(() => {inpref.current?.setFocus(); inpref.current?.focus();}, 4000);          
//         }
//         //setqueryName("id");
//     }

//     const handleClear = () => {
//         setclrSrch(true)
//     }
    
//     const resetClrSrch = () => {
//         setclrSrch(false)
//     }
//     const { height, width } = useWindowDimensions();

//     useEffect(() => {
//         Device.getInfo().then(info => {
//             setdeviceInfo(info)
//         })
//     }, [])
    
//     return (
//         <IonPage>
//             <IonHeader>
//                 <IonToolbar
//                 class="mobile-toolbar-top"
//                     style={{"--background": "#1b1b1b"}}
//                 >
//                     <IonGrid
//                         className="ion-margin-horizontal"
//                     >
//                         <IonRow className="searchbar-pros-client">
//                             {deviceInfo && deviceInfo.platform === "web" && width > 1024? 
//                             <IonCol sizeLg="1"/> : ""}
//                             <IonCol size-xs="9" size-sm="11" sizeLg="9">
//                                 <IonSearchbar
//                                     placeholder="Search by Prospect or Client Name..."
//                                     mode="md"
//                                     debounce={500}
//                                     onIonChange={handleChange}
//                                     ref={inpref}
//                                     //onKeyUp ={handleChange}
//                                     enterkeyhint="search"
//                                     onIonClear={handleClear}
//                                     style={{"--background": "#222222", "--box-shadow": "none","--padding-top":"0px"}}
//                                 />
//                             </IonCol>
//                             <IonCol class="btncol-mobile" size-xs="3" size-sm="1" sizeLg="2" style={ styles.btncol}>
//                                 <IonButton 
//                                     style={styles.btn}
//                                     fill="clear"
//                                     color="gold"
//                                     onClick={() => {
//                                         forTodo ? closeSrch() : history.goBack()
                                    
//                                     }}>
//                                     Cancel
//                                 </IonButton>
//                             </IonCol>
//                         </IonRow>
//                     </IonGrid>
//                 </IonToolbar>
//             </IonHeader>
//             <IonContent fullscreen className="search-content">
//                 <Results queryName={queryName} assocId={assocId} forTodo={forTodo} setContProps={setContProps} srchQry={srchQry} clrSrch={clrSrch} resetClrSrch={resetClrSrch} />
//             </IonContent>
//         </IonPage>
//     )
// }

export default ProspectSearch
