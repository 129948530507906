import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonToolbar,
  IonIcon,
  IonButtons,
  IonAvatar,
  IonGrid,
  IonButton,
  IonText,
  IonLoading,
  IonPopover,
  IonRow,
  IonFooter,
  IonCol,
} from "@ionic/react";

import { chevronBack } from "ionicons/icons";
import CryptoJS from 'crypto-js';
// import share from "src/icons/share.svg"
import edit from "src/icons/pen-edit.svg";
import plusadd from "src/icons/plus-add.svg";
import { RouteComponentProps, useHistory } from "react-router";
// import C3Chart from 'react-c3js';
import Details from "./Details/Details";
import Notes from "./Notes/Notes";
import Documents from "./Documents/Documents";
import Associations from "./Associations/Associations";
import Sharepopup from "./Popup/Sharepopup";
import Newpopup from "./Popup/Newpopup";
import TimeLine from "./Timeline/Timeline";
import Engagement from "./Engagement/Engagement";
import Segments from "src/components/Segments/Segments";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";
import api from "src/api";
import Todos from "./Todos/Todos";
import "c3/c3.css";
import "./ContactInfo.css";
import toptargetstar from "src/icons/toptargetstar.svg";
import shariacompliant from "src/icons/sharia-compliant.svg";
import politicallyexposed from "src/icons/politically-exposed.svg";
import plusicon from "src/icons/plusicon.svg";
import star from "src/icons/star.svg";
import FilterMenu from "./Notes/FilterMenu";
import { ellipse } from "ionicons/icons";
import moment from "moment";
import { useLocation } from "react-router";
import { Plugins } from "@capacitor/core";
import useWindowDimensions from "src/components/useWindowDimensions";

const { Storage, Device } = Plugins;
//star

const imgbaseuri = "https://cdn2.hubspot.net/";

const filterClientCheckboxes = [
  {
    id: 1,
    label: "Contact Stage",
    options: ["Prospect", "Client"],
  },
  {
    id: 2,
    label: "Prospect/ Client Type",
    options: ["Principle", "Advisor", "Family member", "Other"],
  },
  {
    id: 3,
    label: "Meeting Topic",
    propName: "country",
    options: [
      "1st Meeting",
      "2nd Meeting",
      "Proposal Review",
      "Onboarding Portfolio Review",
      "Deal/Program Discussion",
      "Quarterly Report Review",
    ],
  },
];
const filterCheckboxes = [
  
  {
    id: 1,
    label: "Prospect/ Client Type",
    options: ["Principle", "Advisor", "Family member", "Other"],
  },
  {
    id: 2,
    label: "Meeting Topic",
    propName: "country",
    options: [
      "1st Meeting",
      "2nd Meeting",
      "Proposal Review",
      "Onboarding Portfolio Review",
      "Deal/Program Discussion",
      "Quarterly Report Review",
    ],
  },
];
const segs = [
  {
    label: "Details",
    value: "details",
  },
  {
    label: "Timeline",
    value: "timeline",
  },
  {
    label: "Meeting Notes",
    value: "notes",
  },
  {
    label: "To-Dos",
    value: "todos",
  },
  {
    label: "Documents",
    value: "documents",
  },
  {
    label: "Associations",
    value: "associations",
  },
  {
    label: "Engagement",
    value: "engagement",
  },
];

/* const sharia = ({
  shariacompliant
}) => {
  return(
    shariacompliant === "true" ? <IonIcon />Sharia : null
  )
} */

const styles = {
  toolbar: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
    height: 75,
  },
  period: {
    fontSize: 6,
    marginLeft: 4,
    marginRight: 4,
    marginTop: 8,
    color: "#828282",
  },
};

const announcement = [
  {
    announcementTitle: "Test1",
    announcementDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
];

interface ContactDetailPageProps
  extends RouteComponentProps<{
    id: string;
  }> {}

interface ShwAddPopup {
  open: boolean;
  event: Event | undefined;
}
interface ShwSharePopup {
  open: boolean;
  event: Event | undefined;
}

const ContactDetail: React.FC<ContactDetailPageProps> = ({
  match,
  history,
  location,
}) => {
  const [addMenu, setAddMenu] = useState<ShwAddPopup>({
    open: false,
    event: undefined,
  });
  const hist = useHistory();
  const loctn =  useLocation()
  
  // const openMenu = (e) => {
  //   setAddMenu({
  //     open: true,
  //     event: e.nativeEvent
  //   })
  // }

  const [ShareMenu, setShareMenu] = useState<ShwSharePopup>({
    open: false,
    event: undefined,
  });

  const decrypt = (decryptVlaue) => {
    let passphrase = "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9"
    const bytes = CryptoJS.AES.decrypt(decryptVlaue, passphrase);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  };

  const getUserEmail = async () => {
    const ret: any = await Storage.get({ key: "userId" });
    const temp = JSON.parse(decrypt(ret.value));
    return temp && temp.email ? temp.email : null;
  };
  const getUserId = async () => {
    const ret: any = await Storage.get({ key: "userId" });
    return JSON.parse(decrypt(ret.value)).id;
  };
  useEffect(() => {
    if (location.state) {
      let a: any = location.state;
      if (a.to) {
        
        setselSeg(a.to);
      }
    }
  }, [location.state]);

  // const openShareMenu = (e) => {
  //   setShareMenu({
  //     open: true,
  //     event: e.nativeEvent
  //   })
  // }
  const [contact, setContact] = React.useState<any>();
  // const [isLoading, setLoading] = React.useState(false);
  const [arabicfn, arabicfnFunc] = React.useState("");
  const [isClient, isClientFunc] = React.useState("");  
  const [arabicFirst, arabicFirstFunc] = React.useState("");
  // const [arabicln,arabiclnFunc] = React.useState("");
  const [arabicmn, arabicmnFunc] = React.useState("");
  const [poliexposed, poliexposedFunc] = React.useState("");
  const [sharcompliant, sharcompliantFunc] = React.useState("");
  //const[journeystage,journeystageFunc]= React.useState("");
  const [isLoading, setLoading] = useState<boolean>(false);

  const [selSeg, setselSeg] = useState<any>("details");
  const [engData, setengData] = useState<any>();
  const [associationId, setAssociationId] = useState<any>("-");

  const [genViewComp, genViewCompFunc] = useState("");
  const [InvObj, InvObjFunc] = useState("");
  const [InvLiq, InvLiqFunc] = useState("");
  const [OptIntMkt, OptIntMktFunc] = useState("");
  const [AppIntExp, AppIntExpFunc] = useState("");
  const [OptLocEco, OptLocEcoFunc] = useState("");
  const [OptCoreBus, OptCoreBusFunc] = useState("");
  const [noteArr, setNoteArr] = useState<sArr>([]);
  const [ShowInsights, setShowInsights] = useState<boolean>(false);
  const [JourneyStages, setJourneyStages] = useState<any>(false);
  const [toptarget, settoptarget] = useState<boolean>(false);
  const [targetrank, settargetrank] = useState<number>(0);

  const [activeFilters, setactiveFilters] = useState<Array<string>>([]);
  const [clrfil, setclrfil] = useState<boolean>(false);
  const [filFields, setfilFields] = useState<Array<string>>([]);

  const [noteRef, setnoteRef] = useState<boolean>(false);

  const [onboardingId, setOnboardingId] = useState<any>("");
  const [clientAUM, setClientAUM] = useState<any>("");
  const [clientSince, setClientSince] = useState<any>("");
  const [unAuthorisesMessage,setUnAuthorisesMessage] = useState(false)

  let contId = match.params.id;
  interface noteObj {
    noteDesc: string;
    inceptionDate: string;
    noteTiming: string;
    noteAccess: string;
    noteid: any;
    clientType: string;
  }

  interface sArr extends Array<noteObj> {}
  const setAssociationSeg = (segName) => {
    setselSeg(segName);
  };

  useEffect(() => {
    if (noteRef) {
      setLoading(true);
      api
        .GetNoteByProspectId(contId,onboardingId)
        .then((data) => {
          // console.log(JSON.stringify(data));
          setNoteArr(
            data
              .sort((a, b) => {
                return b.meetingDateTime > a.meetingDateTime ? 1 : -1;
              })
              .map((obj) => {
                return {
                  noteTiming: obj.meetingDateTime,
                  noteid: obj.rowId,
                  noteDesc: obj.meetingTopic,
                  inceptionDate:obj.inceptionDate,
                  noteAccess: obj.meetingType,
                  clientType: obj.clientType,
                };
              })
          );
          setnoteRef(false);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }
  }, [noteRef]);

  const delRefreshNotes = () => {
    setnoteRef(true);
  };

  const refreshEng = (EngData) => {
    setLoading(true);

    let engDataCp = { ...engData };
    engDataCp.results.forEach((eng) => {
      if (EngData.engagement.id === eng.engagement.id) {
        eng.metadata.status = EngData.metadata.status;
      }
    });
    setengData(engDataCp);
    setLoading(false);
  };

  const retComp = (segVal) => {
    switch (segVal) {
      case "details":
        return (
          <Details
            JourneyStagesArray={JourneyStages}
            ShowInsights={ShowInsights}
            genViewComp={genViewComp}
            AppIntExp={AppIntExp}
            OptLocEco={OptLocEco}
            OptCoreBus={OptCoreBus}
            InvObj={InvObj}
            InvLiq={InvLiq}
            OptIntMkt={OptIntMkt}
            journeystage={journeystage}
            contact={contact}
            engagements={engData}
            associationId={associationId}
            setAsscSeg={setAssociationSeg}
            clientAUM={clientAUM}
          />
        );
      case "notes":
        return (
          <Notes
            contact={contact}
            notes={noteArr}
            filFields={filFields}
            delRefreshNotes={delRefreshNotes}
          />
        );
      case "documents":
        return <Documents engagements={engData} />;
      case "associations":
        return <Associations contactId={match.params.id} />;
      case "timeline":
        return (
          <TimeLine
            contact={contact}
            announcement={announcement}
            engagements={engData}
          />
        );
      case "engagement":
        return <Engagement contactId={match.params.id} />;
      case "todos":
        return (
          <Todos
            contactcall={contact.properties.phone}
            contactemail={contact.properties.email}
            announcement={announcement}
            engagements={engData}
            contProps={contact}
            refreshEng={refreshEng}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    api
      .getContactDetails(contId)
      .then((data) => {
        // console.log("here------",data);
        // if(data?.message?.match(/You are not authorized/)){
        if(data?.message){
          // console.log("set-----");
          setUnAuthorisesMessage(true);
          
        }else{
          setContact(data);
        if (data?.properties?.arabic_last_name == null) {
          arabicfnFunc("");
        } else {
          arabicfnFunc(data.properties.arabic_last_name);
        }
        if (data.properties.hs_lifecyclestage_customer_date == null) {
          isClientFunc("");
        } else {
          isClientFunc(data.properties.hs_lifecyclestage_customer_date);
        }
        //
        if (
          data.properties.first_name_arabic !== null &&
          data.properties.first_name_arabic !== ""
        ) {
          arabicFirstFunc(data.properties.first_name_arabic);
        }
        if (data.properties.arabic_middle_name == null) {
          arabicmnFunc("");
        } else {
          arabicmnFunc(data.properties.arabic_middle_name);
        }
        // if (data.properties.politically_exposed_persons_pep_ == null) {
        if (data.properties.politically_exposed_persons_pep == null) {
          poliexposedFunc("");
        } else {
          // poliexposedFunc(data.properties.politically_exposed_persons_pep_);
          poliexposedFunc(data.properties.politically_exposed_persons_pep);
        }
        if (data.properties.sharia_compliant == null) {
          sharcompliantFunc("");
        } else {
          sharcompliantFunc(data.properties.sharia_compliant);
        }
        if (data.properties.onboarding_id == null) {
          setOnboardingId("");
        } else {
          setOnboardingId(data.properties.onboarding_id);
        }
      
      // Get meeting notes with onboarding id
      api
      .GetNoteByProspectId(contId,data.properties.onboarding_id)
      .then((data) => {
        // console.log(JSON.stringify(data))
        setNoteArr(
          data
            .sort((a, b) => {
              return b.meetingDateTime > a.meetingDateTime ? 1 : -1;
            })
            .map((obj) => {
              return {
                noteTiming: obj.meetingDateTime,
                noteid: obj.rowId,
                noteDesc: obj.meetingTopic,
                inceptionDate:obj.inceptionDate,
                noteAccess: obj.meetingType,
                clientType: obj.clientType,
              };
            })
        );
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
    }
      })

      .catch((error) => {
        console.error("Error from Conacts API: ", error);
      });
    
    api
      .getEngagementsByContactId(contId)
      .then((resp) => {
        setengData(resp);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
    api
      .getTTfromStore()
      .then((resp) => {
        if (resp && resp !== null && resp.length > 0) {
          resp.map((contarr) => {
            if (Number(contarr.contactid) === Number(contId)) {
              settoptarget(true);
              settargetrank(contarr.rank);
            }
          });
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
    api
      // .getContactPropertiesByProperty("lifecycle_stage_status")
      .getContactPropertiesByProperty("lead_stage")
      .then((resp) => {
        let filterset11 = resp.options.filter((item)=> item.label=="New Lead" || item.label=="Cleared Lead"
        || item.label=="SQL"|| item.label=="Booked Meeting"|| item.label=="Meeting face 2 face"
        || item.label=="RISK QUESTIONNAIRE ANSWERED"|| item.label=="OFFER SENT"
        || item.label=="15 DAYS TO CLOSE"|| item.label=="30 DAYS TO CLOSE"
        || item.label=="60+ DAYS TO CLOSE"|| item.label=="COMMITTED CAPITAL"
        // ) 
        // let filterset12 = resp.options.filter((item)=>  
        ||item.label=="INACTIVE"
        || item.label=="LOST" || item.label=="Competition" || item.label=="Disqualified"|| item.label=="Not interested SQL"
        || item.label=="Unresponsive SQL"
        )
        if (resp.type === "enumeration") {
          setJourneyStages(           
              filterset11.map((o) => {
              return { val: o.value, label: o.label };
            })
            
          );
        //   setLeadStages(           
        //     filterset12.map((o) => {
        //     return { val: o.value, label: o.label };
        //   })
          
        // );
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
    

    api
      .getAssociations(contId)
      .then((data) => {
        if (data.length > 0) {
          let dat = data.filter((o) => o.referral);
          let AsscId =
            dat && dat[0] && dat[0].sourceProspectId
              ? dat[0].sourceProspectId
              : "-";
          setAssociationId(AsscId);
        } else {
          setAssociationId("-");
        }
      })
      .catch((error) => {
        console.error("error in get association API", error);
        setLoading(false);
      });
   
  }, [contId]);

  useEffect(() => {
    if (onboardingId) {
      api
        .getClientDetails(onboardingId)
        .then((resp) => {
          if (resp.length > 0) {
            setClientAUM(resp[0].aum);
            setClientSince(resp[0].inceptionDate);
          }
        })
        .catch((error) => {
          console.error("Error from Conacts API: ", error);
          setLoading(false);
        });
    }
  }, [onboardingId]);

  useEffect(() => {
    if (noteArr[0]) {
      api
        .GetNotesInsights(noteArr[0].noteid)
        .then((data) => {
          setShowInsights(true);
          OptCoreBusFunc(data.optCorebusiness);
          genViewCompFunc(data.gvc);
          InvObjFunc(data.invObjective);
          InvLiqFunc(data.invLiquidity);
          OptIntMktFunc(data.optIntlEconomy);
          AppIntExpFunc(data.apetiteIntlExposure);
          OptLocEcoFunc(data.optLocalEconomy);
        })
        .catch((error) => {
          console.error("Error from Conacts API: ", error);
          setLoading(false);
        });
    }
  }, [noteArr]);

  const journeystage = () => {
    setLoading(true);
    api
      .getContactDetails(match.params.id)
      .then((data) => {
        setContact(data);
        if (data.properties.arabic_last_name == null) {
          arabicfnFunc("");
        } else {
          arabicfnFunc(data.properties.arabic_last_name);
        }
        if (
          data.properties.first_name_arabic !== null &&
          data.properties.first_name_arabic !== ""
        ) {
          arabicFirstFunc(data.properties.first_name_arabic);
        }
        if (data.properties.arabic_middle_name == null) {
          arabicmnFunc("");
        } else {
          arabicmnFunc(data.properties.arabic_middle_name);
        }
        // if (data.properties.politically_exposed_persons_pep_ == null) {
        if (data.properties.politically_exposed_persons_pep == null) {
          poliexposedFunc("");
        } else {
          // poliexposedFunc(data.properties.politically_exposed_persons_pep_);
          poliexposedFunc(data.properties.politically_exposed_persons_pep);
        }
        if (data.properties.sharia_compliant == null) {
          sharcompliantFunc("");
        } else {
          sharcompliantFunc(data.properties.sharia_compliant);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error from Conacts API: ", error);
        setLoading(false);
      });
  };

  const setFilterFields = (Obj, SortOpt) => {
    setfilFields(Obj);
    // setSortOpt(SortOpt)
  };

  const trigClr = () => {
    setFilterFields({}, "Interaction Date (Newest to Oldest)");
    setactiveFilters([]);
    setclrfil(true);
  };

  function AddTopTarget(id) {
    let updContArray: any = [];
    Device.getInfo().then((deviceInfo) => {
      getUserId().then((userId) => {
        getUserEmail().then((useremail) => {
          api
            .getTTfromStore()
            .then((resp) => {
              if (resp.length + 1 == 26) {
                setshow25Popover({ open: true, event: undefined });
              } else {
                updContArray = resp;
                updContArray.push({
                  contactid: Number(id),
                  rank: resp.length + 1,
                });
                let payloads: any = {
                  rm_id: id,
                  prospect_list: JSON.stringify(updContArray),
                };
                api
                  .postTopTargets(payloads)
                  .then((resp) => {
                    let temp =
                      updContArray !== "" && updContArray !== null
                        ? updContArray
                        : [];
                    api
                      .setTopTargetsinStorage(JSON.stringify(temp))
                      .then(() => {
                        if (deviceInfo.platform == "web") {
                          window.globalThis.firebase
                            .analytics()
                            .setUserId(useremail);
                          window.globalThis.firebase
                            .analytics()
                            .logEvent("select_content", {
                              user_name: useremail,
                              content_type: "AddTopTarget",
                              item_id: Number(id),
                              device_id: deviceInfo ? deviceInfo.uuid : "",
                              platform: deviceInfo ? deviceInfo.platform : "",
                              device_model: deviceInfo ? deviceInfo.model : "",
                              device_type: deviceInfo
                                ? deviceInfo.osVersion
                                : "",
                              device_os: deviceInfo
                                ? deviceInfo.operatingSystem
                                : "",
                            });
                        } else {
                          FirebaseAnalytics.setUserId(useremail);
                          FirebaseAnalytics.logEvent("select_content", {
                            user_name: useremail,
                          });
                          FirebaseAnalytics.logEvent("select_content", {
                            content_type: "AddTopTarget",
                          });
                          FirebaseAnalytics.logEvent("select_content", {
                            item_id: Number(id),
                          });
                          FirebaseAnalytics.logEvent("select_content", {
                            device_id: deviceInfo ? deviceInfo.uuid : "",
                          });
                          FirebaseAnalytics.logEvent("select_content", {
                            platform: deviceInfo ? deviceInfo.platform : "",
                          });
                          FirebaseAnalytics.logEvent("select_content", {
                            device_model: deviceInfo ? deviceInfo.model : "",
                          });
                          FirebaseAnalytics.logEvent("select_content", {
                            device_type: deviceInfo ? deviceInfo.osVersion : "",
                          });
                          FirebaseAnalytics.logEvent("select_content", {
                            device_os: deviceInfo
                              ? deviceInfo.operatingSystem
                              : "",
                          });
                        }

                        hist.push(`/prospects/targets`);
                      })
                      .catch((error) => {
                        console.error("Error from Conacts API: ", error);
                        setLoading(false);
                      });
                  })
                  .catch((error) => {
                    console.error("Error from Conacts API: ", error);
                    setLoading(false);
                  });
              }
            })
            .catch((error) => {
              console.error("Error from Conacts API: ", error);
              setLoading(false);
            });
        });
      });
    });
  }

  const [show25Popover, setshow25Popover] = useState<{
    open: boolean;
    event: Event | undefined;
  }>({
    open: false,
    event: undefined,
  });
  function callFirebase(arg1, arg2) {
    Device.getInfo().then((deviceInfo) => {
      getUserEmail().then((useremail) => {
        let conType = "";
        let cntId = "";
        if (arg1) {
          conType = "EditClient";
          cntId = arg1;
        } else {
          conType = "EditProspect";
          cntId = arg2;
        }
        if (deviceInfo.platform == "web") {
          window.globalThis.firebase.analytics().setUserId(useremail);
          window.globalThis.firebase.analytics().logEvent("select_content", {
            user_name: useremail,
            content_type: conType,
            item_id: cntId,
            device_id: deviceInfo ? deviceInfo.uuid : "",
            platform: deviceInfo ? deviceInfo.platform : "",
            device_model: deviceInfo ? deviceInfo.model : "",
            device_type: deviceInfo ? deviceInfo.osVersion : "",
            device_os: deviceInfo ? deviceInfo.operatingSystem : "",
          });
        } else {
          FirebaseAnalytics.setUserId(useremail);
          FirebaseAnalytics.logEvent("select_content", {
            user_name: useremail,
          });
          FirebaseAnalytics.logEvent("select_content", {
            content_type: conType,
          });
          FirebaseAnalytics.logEvent("select_content", { item_id: cntId });
          FirebaseAnalytics.logEvent("select_content", {
            device_id: deviceInfo ? deviceInfo.uuid : "",
          });
          FirebaseAnalytics.logEvent("select_content", {
            platform: deviceInfo ? deviceInfo.platform : "",
          });
          FirebaseAnalytics.logEvent("select_content", {
            device_model: deviceInfo ? deviceInfo.model : "",
          });
          FirebaseAnalytics.logEvent("select_content", {
            device_type: deviceInfo ? deviceInfo.osVersion : "",
          });
          FirebaseAnalytics.logEvent("select_content", {
            device_os: deviceInfo ? deviceInfo.operatingSystem : "",
          });
        }
      });
    });
  }
  const { height, width } = useWindowDimensions();

  return (
   
    <IonPage>
       {unAuthorisesMessage===true?
       
       <IonHeader>
       <IonToolbar mode="md" style={styles.toolbar}>
       <IonButtons slot="start">
                <IonButton
                  style={{ textTransform: "none","--background-hover":"transparent" }}
                  color="white"
                  onClick={() => history.goBack()}>
                  <IonIcon icon={chevronBack}></IonIcon>
                  Go Back
                </IonButton>
              </IonButtons>       
         </IonToolbar>
       <IonItem  class="ml-13 mr-13" style={{ "margin-top": "10%","padding-left":"20%" }} >
      <IonLabel style={{ "margin-bottom": "15px"}} className="font32"> "You are Not Authorised to View this Contact"</IonLabel>
      </IonItem>
      </IonHeader>
     
      :
      <IonHeader>
        <IonToolbar mode="md" style={styles.toolbar}>
          {contact ? (
            contact.properties?.hs_lifecyclestage_customer_date ? (
              <IonButtons slot="start">
                <IonButton
                  style={{ textTransform: "none" }}
                  color="white"
                  onClick={() => {
                    hist.push(`/clients/all`, { to: "clients" });
                  }}
                >
                  <IonIcon icon={chevronBack}></IonIcon>
                  Client List
                </IonButton>
              </IonButtons>
            ) : (
              <IonButtons slot="start">
                <IonButton
                  style={{ textTransform: "none" }}
                  color="white"
                  routerLink="/prospects/all"                >
                  <IonIcon icon={chevronBack}></IonIcon>
                  Prospect List
                </IonButton>
              </IonButtons>
            )
          ) : null}
          <IonButtons slot="end">
            {/* <IonButton mode="ios" color="gold" fill="clear" slot="end" onClick={(e) => { setShareMenu({ open: true, event: e.nativeEvent }) }}><IonIcon color="white" size="small" icon={share} slot="start"></IonIcon>Share</IonButton> */}
            <IonButton
              cust-temp="test"
              // routerLink={`/prospect/edit/${match.params.id}`}
              // onClick={() => {
              //   callFirebase(
              //     contact.properties.onboarding_id,
              //     contact.properties.hs_object_id
              //   );
              // }}
              // routerLink={
              //   contact
              //     ? contact.properties?.hs_lifecyclestage_customer_date
              //       ? `/client/edit/${match.params.id}`
              //       : `/prospect/edit/${match.params.id}`
              //     : ``
              // }
              onClick={(e)=>{
                callFirebase(
                  contact.properties.onboarding_id,
                  contact.properties.hs_object_id
                );                 
                history.push({
                pathname:  contact
                ? contact.properties?.hs_lifecyclestage_customer_date
                  ? `/client/edit`
                  : `/prospect/edit`
                : ``,
                state: {id:contId}
              })                         

            }}
              mode="ios"
              color="gold"
              fill="clear"
              slot="end"
            >
              <IonIcon
                color="white"
                size="small"
                icon={edit}
                slot="start"
              ></IonIcon>

              {width < 576 ? "" : "Edit"}
            </IonButton>
            <IonButton
              mode="ios"
              color="gold"
              fill="clear"
              slot="end"
              onClick={(e) => {
                setAddMenu({ open: true, event: e.nativeEvent });
              }}
            >
              <IonIcon
                color="white"
                size="small"
                icon={plusadd}
                slot="start"
              ></IonIcon>
              {width < 576 ? "" : "New"}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
}
 {unAuthorisesMessage===true?<IonLabel></IonLabel>:
      contact ? (
        <React.Fragment>
          <FilterMenu
            activeFilters={activeFilters}
            clrfil={clrfil}
            trigClr={trigClr}
            setActiveArrayFunc={(a) => {
              setactiveFilters(a);
              setclrfil(false);
            }}
            setFilterFields={setFilterFields}
            sfilterCheckboxes={isClient?filterClientCheckboxes:filterCheckboxes}
          />
          <IonContent fullscreen id="contact-info-content">
            <IonLoading
              cssClass="my-custom-loading-class"
              isOpen={isLoading}
              message={"Please wait..."}
            />
            {width < 576 ? (
              <>
                <IonRow style={{ marginTop: "2vw" }}>
                  <IonCol size-xs="4">
                    <div style={{ marginLeft: "6vw" }}>
                      <IonAvatar slot="start" style={{ width: 75, height: 75 }}>
                        <img
                          src={
                            contact
                              ? contact.properties.hs_avatar_filemanager_key
                                ? `${imgbaseuri}${contact.properties.hs_avatar_filemanager_key}`
                                : "/assets/empty-avatar.svg"
                              : "/assets/empty-avatar.svg"
                          }
                          alt=""
                        />
                        {toptarget && onboardingId ? (
                          <div className="star-group">
                            <IonIcon
                              class="toptargetstar"
                              color="greyscale"
                              icon={toptargetstar}
                            />
                            <IonText class="toptargetrank">
                              {targetrank}
                            </IonText>
                          </div>
                        ) : null}
                      </IonAvatar>
                    </div>
                    <IonPopover
                      cssClass="delete25popover"
                      isOpen={show25Popover.open}
                      event={show25Popover.event}
                      onDidDismiss={(e) =>
                        setshow25Popover({ open: false, event: undefined })
                      }
                    >
                      <IonGrid class="rmvpopover_a">
                        <IonRow class="rmvtarget">
                          {" "}
                          Reached limit of 25 targets{" "}
                        </IonRow>
                        <IonRow class="conrmvtarget">
                          Please remove some of your top targets to add another{" "}
                        </IonRow>
                        <IonFooter class="conrmvfooter">
                          <IonRow>
                            <IonCol>
                              <IonButton
                                onClick={() =>
                                  setshow25Popover({
                                    open: false,
                                    event: undefined,
                                  })
                                }
                                class="ConfirmationButton"
                                fill="clear"
                              >
                                OK
                              </IonButton>
                            </IonCol>
                          </IonRow>
                        </IonFooter>
                      </IonGrid>
                    </IonPopover>
                  </IonCol>
                  <IonCol size-xs="8">
                    <IonLabel>
                      <IonText className="to-do nameClass">
                        {contact
                          ? contact.properties.title_salutation
                            ? `${contact.properties.title_salutation} ${
                                contact.properties.firstname
                              }${
                                contact.properties.middle_name !== "" &&
                                contact.properties.middle_name !== null
                                  ? " " + contact.properties.middle_name
                                  : ""
                              } ${contact.properties.lastname}`
                            : `${contact.properties.firstname}${
                                contact.properties.middle_name !== "" &&
                                contact.properties.middle_name !== null
                                  ? " " + contact.properties.middle_name
                                  : ""
                              } ${contact.properties.lastname}`
                          : ""}
                      </IonText>
                      <p>
                        <IonText
                          style={{ fontFamily: "Almarai", fontSize: 18 }}
                        >
                          {contact
                            ? arabicFirst + " " + arabicmn + " " + arabicfn
                            : ""}
                        </IonText>
                        {onboardingId ? (
                          <React.Fragment>
                            <IonIcon
                              class="fs-6"
                              icon={ellipse}
                              style={styles.period}
                            />
                            <IonText>{onboardingId}</IonText>
                            <IonIcon
                              class="fs-6"
                              icon={ellipse}
                              style={styles.period}
                            />
                            <IonText>
                              Client Since:{" "}
                              {clientSince
                                ? moment(clientSince).format("MMM YYYY")
                                : "-"}
                            </IonText>
                          </React.Fragment>
                        ) : null}
                      </p>
                    </IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow style={{ marginLeft: "2vw" }}>
                  {poliexposed === "Yes, I am a PEP related person" ||
                  poliexposed === "Yes, I am a PEP" ? (
                    <IonCol size-xs="7">
                      <IonText
                        style={{ whiteSpace: "nowrap" }}
                        className="ion-margin-end"
                      >
                        <IonIcon
                          className="polexposedicon"
                          icon={politicallyexposed}
                        ></IonIcon>
                        Politically Exposed Person
                      </IonText>
                    </IonCol>
                  ) : (
                    ""
                  )}
                  {sharcompliant === "true" ? (
                    <IonCol size-xs="5">
                      <IonText
                        style={{ whiteSpace: "nowrap" }}
                        className="ion-margin-end"
                      >
                        <IonIcon
                          className="shariacomp"
                          icon={shariacompliant}
                        ></IonIcon>
                        Sharia Compliant
                      </IonText>
                    </IonCol>
                  ) : (
                    ""
                  )}
                  {contact &&
                  (contact.properties.hs_lifecyclestage_customer_date ||
                    !contact.properties.hubspot_owner_id) ? null : toptarget ? (
                    <IonCol size-xs="6">
                      <IonText style={{ whiteSpace: "nowrap" }}>
                        <IonIcon class="startarget" icon={star}></IonIcon>#
                        {targetrank} Top Target
                        <IonButton
                          size="small"
                          color="gold"
                          fill="clear"
                          class="toptargetedit"
                          mode="ios"
                          routerLink="/prospects/targets"
                        >
                          {"(edit)"}
                        </IonButton>
                      </IonText>
                    </IonCol>
                  ) : (
                    <IonCol size-xs="6">
                      <IonButton
                        fill="clear"
                        class="addastoptrgt"
                        mode="ios"
                        // routerLink="/prospects/targets"
                        onClick={() => AddTopTarget(match.params.id)}
                      >
                        <IonIcon
                          style={{ fontSize: 10 }}
                          className="toptargeticon"
                          icon={plusicon}
                        ></IonIcon>
                        Add as a Top Target
                      </IonButton>
                    </IonCol>
                  )}
                </IonRow>
              </>
            ) : (
              <IonItem
                class="mt-6 margin-set"
                lines="none"
                style={{
                  "--padding-start": "32px",
                  "--padding-end": "32px",
                  marginTop: 32,
                  marginBottom: 32,
                }}
              >
                <IonAvatar slot="start" style={{ width: 75, height: 75 }}>
                  <img
                    src={
                      contact
                        ? contact.properties?.hs_avatar_filemanager_key
                          ? `${imgbaseuri}${contact.properties.hs_avatar_filemanager_key}`
                          : "/assets/empty-avatar.svg"
                        : "/assets/empty-avatar.svg"
                    }
                    alt=""
                  />
                  {toptarget && onboardingId ? (
                    <div className="star-group">
                      <IonIcon
                        class="toptargetstar"
                        color="greyscale"
                        icon={toptargetstar}
                      />
                      <IonText class="toptargetrank">{targetrank}</IonText>
                    </div>
                  ) : null}
                </IonAvatar>
                <IonPopover
                  cssClass="delete25popover"
                  isOpen={show25Popover.open}
                  event={show25Popover.event}
                  onDidDismiss={(e) =>
                    setshow25Popover({ open: false, event: undefined })
                  }
                >
                  <IonGrid class="rmvpopover_a">
                    <IonRow class="rmvtarget">
                      {" "}
                      Reached limit of 25 targets{" "}
                    </IonRow>
                    <IonRow class="conrmvtarget">
                      Please remove some of your top targets to add another{" "}
                    </IonRow>
                    <IonFooter class="conrmvfooter">
                      <IonRow>
                        <IonCol>
                          <IonButton
                            onClick={() =>
                              setshow25Popover({
                                open: false,
                                event: undefined,
                              })
                            }
                            class="ConfirmationButton"
                            fill="clear"
                          >
                            OK
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    </IonFooter>
                  </IonGrid>
                </IonPopover>
                <IonLabel>
                  <IonText className="to-do nameClass">
                    {contact
                      ? contact?.properties?.title_salutation
                        ? `${contact.properties.title_salutation} ${
                            contact?.properties?.firstname
                          }${
                            contact.properties.middle_name !== "" &&
                            contact.properties.middle_name !== null
                              ? " " + contact.properties.middle_name
                              : ""
                          } ${contact.properties.lastname}`
                        : `${contact.properties.firstname}${
                            contact.properties.middle_name !== "" &&
                            contact.properties.middle_name !== null
                              ? " " + contact.properties.middle_name
                              : ""
                          } ${contact.properties.lastname}`
                      : ""}
                  </IonText>
                  <p>
                    <IonText style={{ fontFamily: "Almarai", fontSize: 18 }}>
                      {contact
                        ? arabicFirst + " " + arabicmn + " " + arabicfn
                        : ""}
                    </IonText>
                    {onboardingId ? (
                      <React.Fragment>
                        <IonIcon
                          class="fs-6"
                          icon={ellipse}
                          style={styles.period}
                        />
                        <IonText>{onboardingId}</IonText>
                        <IonIcon
                          class="fs-6"
                          icon={ellipse}
                          style={styles.period}
                        />
                        <IonText>
                          Client Since:{" "}
                          {clientSince
                            ? moment(clientSince).format("MMM YYYY")
                            : "-"}
                        </IonText>
                      </React.Fragment>
                    ) : null}
                  </p>
                  <p className="baseaddInfo ion-margin-end">
                    {poliexposed === "Yes, I am a PEP related person" ||
                    poliexposed === "Yes, I am a PEP" ? (
                      <IonText className="ion-margin-end">
                        <IonIcon
                          className="polexposedicon"
                          icon={politicallyexposed}
                        ></IonIcon>
                        Politically Exposed Person
                      </IonText>
                    ) : (
                      ""
                    )}
                    {sharcompliant === "true" ? (
                      <IonText className="ion-margin-end">
                        <IonIcon
                          className="shariacomp"
                          icon={shariacompliant}
                        ></IonIcon>
                        Sharia Compliant
                      </IonText>
                    ) : (
                      ""
                    )}
                    {contact &&
                    (contact.properties.hs_lifecyclestage_customer_date ||
                      !contact.properties
                        .hubspot_owner_id) ? null : toptarget ? (
                      <IonText>
                        <IonIcon class="startarget" icon={star}></IonIcon>#
                        {targetrank} Top Target
                        <IonButton
                          size="small"
                          color="gold"
                          fill="clear"
                          class="toptargetedit"
                          mode="ios"
                          routerLink="/prospects/targets"
                        >
                          {"(edit)"}
                        </IonButton>
                      </IonText>
                    ) : (
                      <IonButton
                        fill="clear"
                        class="addastoptrgt"
                        mode="ios"
                        // routerLink="/prospects/targets"
                        onClick={() => AddTopTarget(match.params.id)}
                      >
                        <IonIcon
                          style={{ fontSize: 10 }}
                          className="toptargeticon"
                          icon={plusicon}
                        ></IonIcon>
                        Add as a Top Target
                      </IonButton>
                    )}
                  </p>
                </IonLabel>
              </IonItem>
            )}
            <IonGrid class="ml-13 mt-1 mr-13">
              <Segments
                buttons={segs}
                startbtn={selSeg}
                selectedValue={setselSeg}
              />
            </IonGrid>
            {retComp(selSeg)}
            <Newpopup
              open={addMenu.open}
              event={addMenu.event}
              contactId={match.params.id}
              contactObj={contact}
              setShowPopover={() =>
                setAddMenu({ open: false, event: undefined })
              }
            />
            <Sharepopup
              open={ShareMenu.open}
              event={ShareMenu.event}
              setShowPopover={() =>
                setShareMenu({ open: false, event: undefined })
              }
            />
          </IonContent>
        </React.Fragment>
      ) : (
        <IonLoading
          cssClass="my-custom-loading-class"
          isOpen={true}
          message={"Please wait..."}
        />
      )}
      
    </IonPage>
  );
};

export default ContactDetail;
