import {
    SQLiteDatabaseConfig,
    SQLite,
  } from "@ionic-native/sqlite"
import { getDirtyProspectsQry } from "./prospQueries";
import api from "src/api";
import { CreateProspectResponse } from "src/types/CreateProspTypes";
import { useState } from "react";


interface Properties {
    hubspot_owner_id: number;
    title_salutation: string;
    firstname: string;
    middle_name: string;
    lastname: string;
    first_name_arabic: string;
    arabic_last_name: string;
    arabic_middle_name: string;
    gender: string;
    age_range: string;
    email: string;
    phone: string;
    mobilephone: string;
    address_line_1: string;
    address_line_2: string;
    city: string;
    country: string;
    nationality_1_: string;
    company: string;
    position: string;
    industry_: string;
    source_of_wealth: string;
    expected_wealth_range: string;
    bio_profile: string;
    general_remarks: string;
    preferred_channel: string;
    school: string;
    degree: string;
    segment: string;
    contact_type: string;
    lifecyclestage: string;
    //lifecycle_stage_status: string;
    lead_stage: string,
    hs_lead_status: string;
    financial_sophistication: string;
    technology_sophistication: string;
    building_details: string;
    politically_exposed_persons_pep: string;
    sharia_compliant: boolean;
    expected_closing_probability: string;
    amount_status: number;
    expected_month_of_closing: string;
    source_of_name: string;
    preferred_time_of_day: string;
}

interface CreateProspectApiType {
    properties: Properties;
}

interface UpdProperties {
    hubspot_owner_id: number;
    title_salutation: string;
    firstname: string;
    middle_name: string;
    lastname: string;
    first_name_arabic: string;
    arabic_last_name: string;
    arabic_middle_name: string;
    gender: string;
    age_range: string;
    email: string;
    phone: string;
    mobilephone: string;
    address_line_1: string;
    address_line_2: string;
    city: string;
    country: string;
    nationality_1_: string;
    company: string;
    position: string;
    industry_: string;
    source_of_wealth: string;
    expected_wealth_range: string;
    bio_profile: string;
    general_remarks: string;
    preferred_channel: string;
    school: string;
    degree: string;
    segment: string;
    contact_type: string;
    lifecyclestage: string;
    // lifecycle_stage_status: string;
    lead_stage: string,
    hs_lead_status: string;
    financial_sophistication: string;
    technology_sophistication: string;
    building_details: string;
    politically_exposed_persons_pep: string;
    sharia_compliant: boolean;
    expected_closing_probability: string;
    amount_status: number;
    expected_month_of_closing: string;
    source_of_name: string;
    preferred_time_of_day: string;
}

interface UpdateProspectApiType {
   id:string;
    properties: UpdProperties;    
}

const DbConfig: SQLiteDatabaseConfig = {
name: "data.db",
location: "default",
};
const getDirtyProspects: (Op: "INSERT"|"UPDATE") => Promise<CreateProspectApiType[]> = async (FilterOperation) => {
    const dbInstance = await SQLite.create(DbConfig)
    return dbInstance.executeSql(getDirtyProspectsQry, [FilterOperation]).then((dirtyRes) => {
        // console.log("Dirty Results"+JSON.stringify(dirtyRes))
       
        const respObj: CreateProspectApiType[] = []
        if(dirtyRes.rows?.length > 0) {
            for(var i=0;i < dirtyRes.rows?.length; i++) {
                let temp = dirtyRes.rows.item(i)
                const {
                    hubspot_owner_id,
                    title_salutation,
                    firstname,
                    middle_name,
                    lastname,
                    first_name_arabic,
                    arabic_last_name,
                    arabic_middle_name,
                    gender,
                    age_range,
                    email,
                    phone,
                    mobilephone,
                    address_line_1,
                    address_line_2,
                    city,
                    country,
                    nationality_1_,
                    company,
                    position,
                    industry_,
                    source_of_wealth,
                    expected_wealth_range,
                    bio_profile,
                    general_remarks,
                    preferred_channel,
                    school,
                    degree,
                    segment,
                    contact_type,
                    lifecyclestage,
                    // lifecycle_stage_status,
                    lead_stage: string,
                    hs_lead_status,
                    financial_sophistication,
                    technology_sophistication,
                    building_details,
                    politically_exposed_persons_pep,
                    sharia_compliant,
                    expected_closing_probability,
                    amount_status,
                    expected_month_of_closing,
                    source_of_name,
                    preferred_time_of_day,
                } = temp
                respObj.push({
                    properties: {
                        address_line_1,
                        address_line_2,
                        age_range,
                        amount_status: parseInt(amount_status),
                        arabic_last_name,
                        arabic_middle_name,
                        bio_profile,
                        building_details,
                        city,
                        company,
                        contact_type,
                        country,
                        degree,
                        email,
                        expected_closing_probability,
                        expected_month_of_closing,
                        expected_wealth_range,
                        financial_sophistication,
                        first_name_arabic,
                        firstname,
                        gender,
                        general_remarks,
                        hs_lead_status,
                        hubspot_owner_id: parseInt(hubspot_owner_id),
                        industry_,
                        lastname,
                        // lifecycle_stage_status,
                        lead_stage: string,
                        lifecyclestage,
                        middle_name,
                        mobilephone,
                        nationality_1_,
                        phone,
                        politically_exposed_persons_pep,
                        position,
                        preferred_channel,
                        preferred_time_of_day,
                        school,
                        segment,
                        sharia_compliant,
                        source_of_name,
                        source_of_wealth,
                        technology_sophistication,
                        title_salutation
                    }
                })
            }
        }
        return respObj
    })

}

const getDirtyUpdateProspects: (Op: "UPDATE") => Promise<UpdateProspectApiType[]> = async (FilterOperation) => {
    const dbInstance = await SQLite.create(DbConfig)
    return dbInstance.executeSql(getDirtyProspectsQry, [FilterOperation]).then((dirtyRes) => {
        // console.log("getDirtyUpdateProspects"+JSON.stringify(dirtyRes))//id
        const respObj: UpdateProspectApiType[] = [];
        const id = dirtyRes.id;
        if(dirtyRes.rows?.length > 0) {
            for(var i=0;i < dirtyRes.rows?.length; i++) {
                let temp = dirtyRes.rows.item(i);
                // console.log("in getDirtyUpdateProspects"+JSON.stringify(temp))
                const {
                    id,
                    hubspot_owner_id,
                    title_salutation,
                    firstname,
                    middle_name,
                    lastname,
                    first_name_arabic,
                    arabic_last_name,
                    arabic_middle_name,
                    gender,
                    age_range,
                    email,
                    phone,
                    mobilephone,
                    address_line_1,
                    address_line_2,
                    city,
                    country,
                    nationality_1_,
                    company,
                    position,
                    industry_,
                    source_of_wealth,
                    expected_wealth_range,
                    bio_profile,
                    general_remarks,
                    preferred_channel,
                    school,
                    degree,
                    segment,
                    contact_type,
                    lifecyclestage,
                    // lifecycle_stage_status,
                    lead_stage: string,
                    hs_lead_status,
                    financial_sophistication,
                    technology_sophistication,
                    building_details,
                    politically_exposed_persons_pep,
                    sharia_compliant,
                    expected_closing_probability,
                    amount_status,
                    expected_month_of_closing,
                    source_of_name,
                    preferred_time_of_day,
                } = temp
                respObj.push({      
                    id:id,              
                    properties: {                                                
                        address_line_1,
                        address_line_2,
                        age_range,
                        amount_status: parseInt(amount_status),
                        arabic_last_name,
                        arabic_middle_name,
                        bio_profile,
                        building_details,
                        city,
                        company,
                        contact_type,
                        country,
                        degree,
                        email,
                        expected_closing_probability,
                        expected_month_of_closing,
                        expected_wealth_range,
                        financial_sophistication,
                        first_name_arabic,
                        firstname,
                        gender,
                        general_remarks,
                        hs_lead_status,
                        hubspot_owner_id: parseInt(hubspot_owner_id),
                        industry_,
                        lastname,
                        // lifecycle_stage_status,
                        lead_stage: string,
                        lifecyclestage,
                        middle_name,
                        mobilephone,
                        nationality_1_,
                        phone,
                        politically_exposed_persons_pep,
                        position,
                        preferred_channel,
                        preferred_time_of_day,
                        school,
                        segment,
                        sharia_compliant,
                        source_of_name,
                        source_of_wealth,
                        technology_sophistication,
                        title_salutation
                    }
                })
                
            }
        }
        return respObj
    })

}



const syncProspectCreation: (inp: CreateProspectApiType[]) => Promise<any> = (payloadArr) => {

    let promises: Promise<any>[] = []
    payloadArr.forEach((payload) => {
        promises.push(new Promise((res) => {
            api.createProspectAPI(payload).then((createProspResponse: CreateProspectResponse) => {
                res(createProspResponse)
            }).catch(e => console.log("Error:178"+JSON.stringify(e)))
        }))
    })

    return Promise.all(promises).then(val => {
        console.log("Prospect Sync Promise All then"+JSON.stringify(val))
        return val
    }).catch(e => console.log(JSON.stringify(e)))
    

}

const syncProspectUpdate: (inp: UpdateProspectApiType[],contid:any) => Promise<any> = (payloadArr,contid) => {
    
    let promises: Promise<any>[] = []
    payloadArr.forEach((payload) => {
        // console.log("in syncProspectUpdate"+JSON.stringify(payload));
        promises.push(new Promise((res) => {
            // console.log("syncProspectUpdate"+JSON.stringify(res));
            // console.log(contid);
            api.updateProspect(contid,payload).then((createProspResponse: CreateProspectResponse) => {
                res(createProspResponse)
            }).catch(e => console.log("Error:178"+JSON.stringify(e)))
        }))
    })

    return Promise.all(promises).then(val => {
        // console.log("Prospect Sync Promise All then"+JSON.stringify(val))
        return val
    }).catch(e => console.log(JSON.stringify(e)))
    

}

export default {
    getDirtyProspects,
    getDirtyUpdateProspects,
    syncProspectCreation,
    syncProspectUpdate
}