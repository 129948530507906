import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonIcon,
  IonRow,
  IonText,
  IonSlide,
  IonSlides,
  IonCol,
} from "@ionic/react";
import { add } from "ionicons/icons";
import "./Segments.css";
import useWindowDimensions from "src/components/useWindowDimensions";
const styles = {
  row: {
    marginTop: 24,
    marginLeft: 32,
    marginRight: 32,
    // marginBottom: 24
  },
  buttons: {
    marginRight: 14,
    paddingBottom: 12,
    cursor: "pointer",
    borderBottom: "4px solid transparent",
    transition: "border 200ms linear",
    color: "#828282",
    fontWeight: "400",
  },
  slide: {
    marginRight: 0,
  },
};

interface button {
  label: string;
  value: string;
}

interface SegProps {
  buttons: Array<button>;
  selectedValue: Function;
  viewAllProsp?: boolean | undefined;
  startbtn?: string | undefined;
  createNewTodo?: boolean;
  viewAllClient?: boolean | undefined;
}

const Segments: React.FC<SegProps> = ({
  buttons,
  selectedValue,
  viewAllProsp,
  startbtn,
  createNewTodo,
  viewAllClient,
}) => {
  const [selected, setSelected] = useState<string>("");
  const [slideOpts, setslideOpts] = useState<any>({
    initialSlide: 0,
    speed: 400,
    freeMode: true,
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    },
    //spaceBetween: 2,
  });

  const retActiveClass = (button) => {
    return button.value === selected ? "SegActive" : "SegInactive";
  };
  const handleClick = (button) => {
    setSelected(button.value);
    selectedValue(button.value);
  };

  useEffect(() => {
    if (startbtn) {
      setSelected(startbtn);
    } else {
      setSelected(buttons[0].value);
    }
    // selectedValue(buttons[0].value)
  }, [buttons, startbtn]);

  const { height, width } = useWindowDimensions();

  return (
    <IonRow style={styles.row} class="margin-set mobile-slider-row">
      {width > 576 ? (
        <>
          {buttons.map((button) => (
            <IonText
              key={button.value}
              style={styles.buttons}
              onClick={() => {
                handleClick(button);
              }}
              className={retActiveClass(button)}
            >
              {button.label}
            </IonText>
          ))}
          {viewAllProsp ? (
            <IonButton
              mode="ios"
              fill="clear"
              size="small"
              color="gold"
              style={{
                marginBottom: "auto",
                marginLeft: "auto",
                marginTop: "auto",
              }}
              routerLink="/prospects/all"
            >
              View All Prospects
            </IonButton>
          ) : null}
          {viewAllClient ? (
            <IonButton
              mode="ios"
              fill="clear"
              size="small"
              color="gold"
              style={{
                marginBottom: "auto",
                marginLeft: "auto",
                marginTop: "auto",
              }}
              routerLink="/clients/all"
            >
              View All Clients
            </IonButton>
          ) : null}
          {createNewTodo ? (
            <IonButton
              mode="ios"
              fill="clear"
              size="small"
              color="gold"
              style={{
                marginBottom: "auto",
                marginLeft: "auto",
                marginTop: "auto",
              }}
              routerLink="/create/todo"
            >
              <IonIcon slot="start" icon={add} color="gold" />
              New To-Do Item
            </IonButton>
          ) : null}
        </>
      ) : (
        <>
          <IonCol
            size-xs={
              buttons.find((item) => item.label.indexOf("General") === 0)
                ? "8"
                : "12"
            }
          >
            <IonSlides options={slideOpts}>
              {buttons.map((button, i) => (
                <IonSlide key={i}>
                  <IonText
                    class="mobile-header-text"
                    key={button.value}
                    style={styles.buttons}
                    onClick={() => {
                      handleClick(button);
                    }}
                    className={retActiveClass(button)}
                  >
                    {button.label}
                  </IonText>
                </IonSlide>
              ))}
            </IonSlides>
          </IonCol>
          <IonCol size-xs={
              buttons.find((item) => item.label.indexOf("General") === 0)
                ? "4"
                : "12"
            }>
            {viewAllProsp ? (
              <IonButton
                mode="ios"
                fill="clear"
                size="small"
                color="gold"
                style={{
                  marginBottom: "auto",
                  marginLeft: "auto",
                  marginTop: "auto",
                }}
                routerLink="/prospects/all"
              >
                View All Prospects
              </IonButton>
            ) : null}
            {viewAllClient ? (
              <IonButton
                mode="ios"
                fill="clear"
                size="small"
                color="gold"
                style={{
                  marginBottom: "auto",
                  marginLeft: "auto",
                  marginTop: "auto",
                }}
                routerLink="/clients/all"
              >
                View All Clients
              </IonButton>
            ) : null}
            {createNewTodo ? (
              <IonButton
                mode="ios"
                fill="clear"
                size="small"
                color="gold"
                style={{
                  marginBottom: "auto",
                  marginLeft: "auto",
                  marginTop: "auto",
                }}
                routerLink="/create/todo"
                class="ion-float-right"
              >
                <IonIcon slot="start" icon={add} color="gold" />
                New To-Do Item
              </IonButton>
            ) : null}
          </IonCol>
        </>
      )}
    </IonRow>
  );
};

export default Segments;
