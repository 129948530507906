import React, { useState, useEffect } from 'react';
import {
  IonContent,
  IonPage,
  IonLoading,
  IonRow,
  IonText,
  IonMenuButton,
  IonButton,
  IonIcon
} from '@ionic/react';
import SearchHeader from "src/components/SearchHeader/SearchHeader";
import CryptoJS from 'crypto-js';
import './ToDo.css';
import Segments from 'src/components/Segments/Segments';
import Todos from "./TodoCards/Prospects/Todos"
import api from "src/api";
import { Network, Plugins  } from "@capacitor/core";
import moment from 'moment';
import FilterMenu from './TodoCards/Prospects/FilterMenu';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics';
import { funnel } from 'ionicons/icons';
import { parse } from 'url';


const { Storage, Device } = Plugins


const segs = [
  {
    label: "Prospects",
    value: "prospectsTodo"
  },
  {
    label: "Clients",
    value: "clientsTodo"
  },
]

const decrypt = (decryptVlaue) => {
  let passphrase = "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9"
  const bytes = CryptoJS.AES.decrypt(decryptVlaue, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};
const getUserId = async () => {
  const ret: any = await Storage.get({ key: "userId" })
  return JSON.parse(decrypt(ret?.value))?.id
}

const retComp = (selectedVal: string, prospTodos: Array<any>, arrforchips:Array<any>, triggerClr:any, refreshEng:any) => {
  switch (selectedVal) {
    case "prospectsTodo":
      return (
      <Todos
        Todos={prospTodos}
        activeFilters={arrforchips}
        clrFilters={triggerClr}
        refreshEng={refreshEng}
      />
      );
      case "clientsTodo":
      return (
      <Todos
        Todos={prospTodos}
        activeFilters={arrforchips}
        clrFilters={triggerClr}
        refreshEng={refreshEng}
      />
      );
    default:
      return null;
  }
}

const filterCheckboxes = [
  {
      id: 1,
      label: "Type",
      options: [
          "Call",
          "Email",
          "To-Do",
      ],
  },
  {
      id: 2,
      label: "Priority",
      options: [
          "None",
          "High",
      ],
  },
]

interface result1 {
  activityDate: any;
  bodyPreview: any;
  engagementId: any;
}

const ToDo: React.FC<any> = () => {

  const [isLoading, setLoading] = useState(false);
  const [selSeg, setselSeg] = useState<string>("prospectsTodo");
  const [prospTodos, setprospTodos] = useState<Array<any>>([])
  const [prospTodosFil, setprospTodosFil] = useState<Array<any>>([])
  const [arrforchips, setarrforchips] = useState<any>([])
  const [FilterObj, setFilterObj] = useState<any>()
  const [trigClrfromChips, settrigClrfromChips] = useState<number>(0)

  const selectedVal = (value) => {
    setselSeg(value)
  }

  const setArrayforChips = (A) => {
    setarrforchips(A)
  }
  const setArrayforFilterObj = (A) => {
    setFilterObj(A)
  }

  const triggerClr = () => {
    trigClr()
    settrigClrfromChips(trigClrfromChips+1)
  }

  const trigClr = () => {
    setprospTodosFil(prospTodos)
    setarrforchips([])
  }
  const getUserEmail = async () => {
    const ret: any = await Storage.get({ key: "userId" })
    const temp = JSON.parse(decrypt(ret.value))
    return temp && temp.email ? temp.email : null
}

  useEffect(() => {
    Device.getInfo().then(deviceInfo => {
      getUserId().then((userId) => {
    getUserEmail().then(useremail => {
      if(deviceInfo.platform == "web")
      {
          window.globalThis.firebase.analytics().setUserId(useremail);
          window.globalThis.firebase.analytics().logEvent('select_content', {
          user_name: useremail,
          content_type: 'ViewToDo',
          item_id: useremail,
          device_id:deviceInfo?deviceInfo.uuid:"",
          platform:deviceInfo?deviceInfo.platform:"",
          device_model:deviceInfo?deviceInfo.model:"",
          device_type:deviceInfo?deviceInfo.osVersion:"",
          device_os:deviceInfo?deviceInfo.operatingSystem:"",
        });
      }
      else
      {
            
            FirebaseAnalytics.setUserId(useremail);
            FirebaseAnalytics.logEvent("select_content", {user_name: useremail});
            FirebaseAnalytics.logEvent("select_content", {content_type: 'ViewToDo'});
            FirebaseAnalytics.logEvent("select_content", {item_id: useremail});
            FirebaseAnalytics.logEvent("select_content", {device_id: deviceInfo?deviceInfo.uuid:""});
            FirebaseAnalytics.logEvent("select_content", {platform: deviceInfo?deviceInfo.platform:""});
            FirebaseAnalytics.logEvent("select_content", {device_model: deviceInfo?deviceInfo.model:""});
            FirebaseAnalytics.logEvent("select_content", {device_type:deviceInfo?deviceInfo.osVersion:""});
            FirebaseAnalytics.logEvent("select_content", {device_os:deviceInfo?deviceInfo.operatingSystem:""});
      }
      
    })
    })
  })
    if(FilterObj) {

      let AllTodos = [...prospTodos]

      let filType: any = []
      let isTypeFilter: boolean = false
      if(FilterObj.Type && FilterObj.Type.vals.length > 0) {
        isTypeFilter = true
        let filarr: Array<any> = FilterObj.Type.vals;
        filType = AllTodos.filter(obj => {
          let retVal = false
          for(const x of filarr) {
            if(x === "Call" && obj.metaDataTaskType === "CALL") {
              retVal = true
            } else if(x === "Email" && obj.metaDataTaskType === "EMAIL") {
              retVal = true
            } else if(x === "To-Do" && obj.metaDataTaskType === "TODO") {
              retVal = true
            }
        }
        return retVal
      })
    }
    
    let FilteredTodos = isTypeFilter ? filType : AllTodos
    let filAll: any = []
    let isPriorityFilter: boolean = false
    if(FilterObj.Priority && FilterObj.Priority.vals.length > 0) {
      isPriorityFilter = true
      let filarr: Array<any> = FilterObj.Priority.vals;
      filAll = FilteredTodos.filter(obj => {
        let retVal = false
        for(const x of filarr) {
          if(x === "None" && obj.metaDataPriority === "NONE") {
            retVal = true
          } else if(x === "High" && obj.metaDataPriority  === "HIGH") {
            retVal = true
          }
      }
        return retVal
      })

    }
      isTypeFilter && !isPriorityFilter ?  setprospTodosFil(filType) : isPriorityFilter ? setprospTodosFil(filAll) : setprospTodosFil(AllTodos)
    }
  },[FilterObj])
  
  useEffect(() => {
    let type = (selSeg === "prospectsTodo" ? "prospect" : "client")
    getUserId().then(userId => {

      Network.getStatus().then((network) => {
        if(network.connected) {        
        setLoading(true)
        api.GetToDo(userId,type).then((resp) => {
        // api.GetToDo(55058072).then((resp) => {
          if (resp && resp.length > 0) {
            let manipResp: Array<any> = []
            let contactPayload: any = {
              "inputs": [],
              "properties": ["email,phone,firstname,lastname"]
            };
            resp.filter(r => moment(r.activityDate).format("x") >= moment().subtract(1, "weeks").format("x")).forEach(r => {
              contactPayload.inputs.push({
                id: r.associationContactIds ? r.associationContactIds : 0
              })
            })
            api.getTopTargetContacts(contactPayload).then(batchresp => {
              if(batchresp.results && batchresp.results.length > 0) {

              manipResp = resp.filter(r => moment(r.activityDate).format("x") >= moment().subtract(1, "weeks").format("x")).map(respInd => {
                return {
                  ...respInd,
                  contProps: batchresp.results.filter(o => o.id === respInd.associationContactIds)[0]
                }
              })
              } else {
                manipResp = [...resp]
              }
              setprospTodos(
                manipResp
                  .filter(r => moment(r.activityDate).format("x") >= moment().subtract(1, "weeks").format("x"))
                  .sort((a, b) => { return moment(a.activityDate).isAfter(moment(b.activityDate)) ? 1 : -1 })
              )
              setprospTodosFil(
                manipResp
                  .filter(r => moment(r.activityDate).format("x") >= moment().subtract(1, "weeks").format("x"))
                  .sort((a, b) => { return moment(a.activityDate).isAfter(moment(b.activityDate)) ? 1 : -1 })
              )
              
              setLoading(false)
            }).catch(() => {
              setLoading(false)
            })
          } else {
            setLoading(false)
          }
        })
      }})

    })
  }, [selSeg])
  
  const refreshEng = (EngData) => {
    let temp = [...prospTodosFil]
    temp.forEach(eng => {
      if(parseInt(EngData.engagement.id) === parseInt(eng.engagementId)) {
        eng.metaDataStatus = EngData.metadata.status
      }
    })
    setprospTodosFil(temp)
  }


  return (
    <IonPage>
      <SearchHeader />
      <FilterMenu 
        // activeFilters={activeFilters}
        // clrfil={clrfil}
        trigClr={trigClr}
        setActiveArrayFunc={setArrayforChips}
        // setFilterFields={setFilterFields}
        setFilterFields={setArrayforFilterObj}
        sfilterCheckboxes={filterCheckboxes}
        trigClrfromChips={trigClrfromChips}
      />
      <IonContent id="todo-menu-id">
        <IonLoading
          cssClass='my-custom-loading-class'
          isOpen={isLoading}
          onDidDismiss={() => setLoading(false)}
          message={'Please wait...'}
        />

        <IonRow class="mt-6 margin-set" style={{ marginTop: 24, marginLeft: 32 }}>
          {/* <IonText
          class="to-do"
            style={{ fontWeight: 300, fontSize: 28 }}
            color="greyscale"
          >
            To-Do
          </IonText> */}
          <span className="to-do" style={{ fontWeight: 300, fontSize: 28, color:"GrayText", whiteSpace:"nowrap" }}>
          To-Do
          </span>
          {
            // selSeg === "prospectsTodo" ?
              <IonMenuButton
                style={{ marginLeft: "auto", marginBottom: "auto" }}
              >
                <IonButton
                  fill="clear"
                  size="small"
                >
                  <IonIcon
                    color="gold"
                    icon={funnel}
                    size="small"
                  />
                </IonButton>
              </IonMenuButton>
              //  : null
          }
        </IonRow>

        <Segments
          buttons={segs}
          startbtn="prospectsTodo"
          selectedValue={selectedVal}
          createNewTodo={true}
        />
        <IonRow
        class="margin-set mt-1"
          style={{marginLeft: 32, marginTop: 24}}
        >
          <IonText color="greyscale">*Todos are synced with the server every 15 minutes</IonText>
        </IonRow>

        {
          retComp(selSeg, prospTodosFil, arrforchips, triggerClr, refreshEng)
        }

      </IonContent>
    </IonPage>

  );
};

export default ToDo;
