import {
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
  IonCol,
  IonInput,
  IonTextarea,
  IonListHeader,
  IonRadio,
  IonRadioGroup,
} from "@ionic/react";
import React, { useState } from "react";
import { Field } from "formik";

const cadencelit = [
  { val: "Meeting as Needed" },
  { val: "Every Month" },
  { val: "Every Quarter" },
  { val: "Every 6 Months" },
];

const styles = {
  root: {
    padding: 27,
  },
  selectItem: {
    "--padding-start": "0px",
    backgroundColor: "#222222",
    marginTop: 8,
  },
  select: {
    "--padding-start": "8px",
  },
  label: {
    // marginBottom: 8
  },
  checklist: {
    "--padding-start": "0px",
  },
  listHeader: {
    fontSize: 14,
    paddingInlineStart: 0,
    marginBottom: 8,
    maxHeight: 0,
    fontWeight: "normal",
  },
};

const OtherInfo: React.FC<any> = ({
  contact,
  values,
  handleChange,
  setFieldValue,
  isedit,
  handleBlur,
}) => {
  const [instArr, setinstArr] = useState<any>([
    {
      id: 1,
    },
  ]);
  // const addtnDeg = values && values.additional_qualification_degree ? values.additional_qualification_degree : {}

  // useEffect(() => {
  //     if(isedit) {
  //         let EduArrObj = JSON.parse(addtnDeg)
  //         let EduArr = EduArrObj && EduArrObj !== null && EduArrObj.edu ? EduArrObj.edu : []
  //         let temp: any = []
  //         if(EduArr.length > 0) {

  //             EduArr.forEach((o,i) => {
  //                 temp.push({id: i+1})
  //             })
  //             setinstArr(temp)
  //         }
  //     }
  // },[isedit,addtnDeg])

  // const handleAddDegree= () => {
  //     let curlen = instArr.length
  //     let tempArr: any = []
  //     for(var k=1;k < curlen + 2 && k < 6;k++) {
  //         tempArr.push({
  //             id: k
  //         })
  //     }
  //     setinstArr(tempArr)
  // }

  return (
    <React.Fragment>
      <IonGrid class="padding-unset" style={styles.root}>
        <IonRow>
          <IonCol size-lg="5" size-xs="4" size-sm="5"></IonCol>
          <IonCol size-lg="5" size-xs="4" size-sm="5"></IonCol>
          <IonCol
            size-xs="4"
            size-sm="2"
            style={{ fontSize: 14, textAlign: "right" }}
          >
            * = required
          </IonCol>
        </IonRow>
        <IonRow>
          {instArr.map(({ id }) => (
            <React.Fragment key={id}>
              <IonCol size-lg="4" size-xs="12" size-sm="5">
                <IonLabel class="font14" style={styles.label}>
                  Academic Institution
                </IonLabel>
                <IonItem lines="none" class="ion-item-height" style={styles.selectItem}>
                  <Field
                    as={IonInput}
                    class="inpClass font14"
                    onIonBlur={handleBlur}
                    onIonChange={handleChange}
                    // value={values["acdInst" + id]}
                    // name={"acdInst" + id}
                    value={values.acdInst}
                    name={"acdInst"}
                    placeholder="Enter Academic Institution"
                    autocapitalize="sentences"
                  />
                </IonItem>
              </IonCol>
              <IonCol size-lg="4" size-xs="12" size-sm="5">
                <IonLabel class="font14" style={styles.label}>
                  Degree
                </IonLabel>
                <IonItem lines="none" class="ion-item-height" style={styles.selectItem}>
                  <Field
                    as={IonInput}
                    class="inpClass font14"
                    onIonBlur={handleBlur}
                    onIonChange={handleChange}
                    // value={values["degree" + id]}
                    // name={"degree" + id}
                    value={values.degree}
                    name={"degree"}
                    placeholder="Enter Degree"
                    autocapitalize="sentences"
                  />
                </IonItem>
              </IonCol>
            </React.Fragment>
          ))}
        </IonRow>
        {/* <IonRow class="ion-margin-bottom">
                    <IonButton
                        fill="clear"
                        color="gold"
                        mode="ios"
                        size="small"
                        onClick={handleAddDegree}
                    >
                        <IonIcon color="gold" icon={add}></IonIcon>
                        <IonText>Add Another Degree</IonText>
                    </IonButton>
                </IonRow> */}
        <IonRow>
          <IonCol size-lg="9">
            <IonLabel class="font14" style={styles.label}>
              Remarks
            </IonLabel>
            <IonItem lines="none" style={styles.selectItem}>
              <Field
                as={IonTextarea}
                rows={6}
                class="inpClass font14"
                onIonBlur={handleBlur}
                onIonChange={handleChange}
                value={values.meeting_notes}
                spellcheck={true}
                name="meeting_notes"
                placeholder="Enter any notes on the prospect"
                autocapitalize="sentences"
              />
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size-lg="3" size-xs="12" size-sm="3">
            <Field
              as={IonRadioGroup}
              value={values.cadence}
              onIonChange={handleChange}
              onIonBlur={handleBlur}
              name="cadence"
            >
              <IonListHeader style={styles.listHeader}>
                <IonLabel>Meeting Cadence</IonLabel>
              </IonListHeader>
              {cadencelit.map(({ val }, i) => (
                <IonItem
                  style={styles.checklist}
                  class="grayscale6 margintopn20"
                  lines="none"
                  key={i}
                >
                  <IonRadio mode="md" color="actblue" value={val} />
                  <IonLabel
                    style={{ fontSize: 12 }}
                    class="marginleftp10 font12"
                  >
                    {val}
                  </IonLabel>
                </IonItem>
              ))}
            </Field>
          </IonCol>
        </IonRow>
      </IonGrid>
    </React.Fragment>
  );
};

export default OtherInfo;
