import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonIcon,
  IonItem,
  IonLoading,
  IonPage,
  IonRow,
  IonText,
} from "@ionic/react";
import { chevronBack, chevronForward, chevronDown, add } from "ionicons/icons";
import CryptoJS from 'crypto-js';
import { Calendar, momentLocalizer } from "react-big-calendar";
import clsx from "clsx";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./Calendar.css";
import CustCalendar from "src/components/CustomCalendar/CustomCalendar";
import calendargold from "src/icons/calendargold.svg";
import api from "src/api";
import { ellipse } from "ionicons/icons";
import { Plugins } from "@capacitor/core";
import SearchHeader from "src/components/SearchHeader/SearchHeader";
import { RouteComponentProps } from "react-router";
import useWindowDimensions from "src/components/useWindowDimensions";
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics';
const { Storage , Device } = Plugins;

const MyEvent: React.FC<any> = ({ event }) => {
  return (
    <div>
      <IonText className="tfo-cal-event-title">{event.title}</IonText>
      <IonIcon
        className="period-ellipse"
        style={{
          fontSize: 8,
          marginLeft: 3,
          marginRight: 3,
          marginTop: 3,
          color: "#C7C7C7",
        }}
        icon={ellipse}
      ></IonIcon>
      <IonText className="tfo-cal-event-name">{event.name}</IonText>
      <br />
      <IonText className="tfo-cal-event-dt-time">
        {moment(event.start).format("h:mm a") +
          " - " +
          moment(event.end).format("h:mm a") +
          " | " +
          moment(event.start).format("ddd, MMM D, YYYY")}
      </IonText>
    </div>
  );
};

const MyToolBar = (props) => {
  let {
    localizer: { messages },
  } = props;

  let pickerMode = props.view === "month" ? "month" : "date";
  let dateFormat = "ddd, MMM D, YY";
  let weekLabel = "";
  let itemClass = "tfo-calendar-item";
  let InputClassName = "tfo-calendar-picker-input tfo-input";

  if (props.view === "month") {
    dateFormat = "MMMM, YY";
  } else if (props.view === "week") {
    dateFormat = "";
    weekLabel = props.label;
    itemClass =
      weekLabel.length > 18
        ? "tfo-calendar-item-biggest"
        : "tfo-calendar-item-big";
    InputClassName = "tfo-calendar-picker-input tfo-input input-extended";
  }

  let navigateLabel = {
    PREVIOUS: "PREV",
    NEXT: "NEXT",
    TODAY: "TODAY",
    DATE: "DATE",
  };

  const navigate = (action) => {
    props.onNavigate(action);
  };

  const setView = (view) => {
    props.onView(view);
  };

  function viewNamesGroup(messages) {
    let viewNames = props.views;
    const view = props.view;
    if (viewNames.length > 1) {
      return viewNames.map((name) => (
        <IonButton
          mode="ios"
          color="medium"
          key={name}
          className={clsx({ "cal-tab-active": view === name })}
          onClick={() => setView(name)}
        >
          {messages[name]}
        </IonButton>
      ));
    }
  }

  const onSetDateVal = (date) => {
    props.onNavigate(navigateLabel.DATE, date.toDate());
  };
  const { height, width } = useWindowDimensions();
  return (
    <React.Fragment>
      <IonRow className="rbc-toolbar">
        <IonRow class="mobile-margin-calander">
          <IonText className="calendar-heading">Calendar</IonText>
        </IonRow>
{width>576?(<><span className="rbc-toolbar-label"></span>
        <IonItem lines="none" className={itemClass}>
          <IonIcon
            src={calendargold}
            slot="start"
            style={{ marginRight: "4%" }}
          ></IonIcon>
          <CustCalendar
            date={props.date}
            // date={new Date()}
            mode={pickerMode}
            onSetDateVal={onSetDateVal}
            dateFormat={dateFormat}
            PickerStyle={{ zIndex: 1001 }}
            InputClassName={InputClassName}
            weekLabel={weekLabel}
          ></CustCalendar>
          <IonIcon
            slot="end"
            color="gold"
            src={chevronDown}
            style={{ marginRight: 0 }}
          ></IonIcon>
        </IonItem>
        <IonButton
          color="gold"
          fill="outline"
          mode="ios"
          placeholder="today"
          onClick={() => navigate(navigateLabel.TODAY)}
          className="tfo-cal-today-btn"
        >
          {messages.today}
        </IonButton>

        <IonButtons>
          <IonButton
            color="gold"
            mode="ios"
            onClick={() => navigate(navigateLabel.PREVIOUS)}
            class="mobile-nav-btn"
          >
            <IonIcon slot="icon-only" icon={chevronBack} />
          </IonButton>
          <IonButton
            color="gold"
            mode="ios"
            class="mobile-nav-btn"
            onClick={() => navigate(navigateLabel.NEXT)}
          >
            <IonIcon slot="icon-only" icon={chevronForward} />
          </IonButton>
        </IonButtons></>)
        :
        (<IonRow><span className="rbc-toolbar-label"></span>
        <IonItem class="mobile-calander-inp" lines="none" className={itemClass}>
          <IonIcon
            src={calendargold}
            slot="start"
            style={{ marginRight: "4%" }}
          ></IonIcon>
          <CustCalendar
            date={props.date}
            // date={new Date()}
            mode={pickerMode}
            onSetDateVal={onSetDateVal}
            dateFormat={dateFormat}
            PickerStyle={{ zIndex: 1001 }}
            InputClassName={InputClassName}
            weekLabel={weekLabel}
          ></CustCalendar>
          <IonIcon
            slot="end"
            color="gold"
            src={chevronDown}
            style={{ marginRight: 0 }}
          ></IonIcon>
        </IonItem>
        <IonButton
          color="gold"
          fill="outline"
          mode="ios"
          placeholder="today"
          onClick={() => navigate(navigateLabel.TODAY)}
          className="tfo-cal-today-btn"
        >
          {messages.today}
        </IonButton>

        <IonButtons>
          <IonButton
            color="gold"
            mode="ios"
            onClick={() => navigate(navigateLabel.PREVIOUS)}
            class="mobile-nav-btn"
          >
            <IonIcon slot="icon-only" icon={chevronBack} />
          </IonButton>
          <IonButton
            color="gold"
            mode="ios"
            class="mobile-nav-btn"
            onClick={() => navigate(navigateLabel.NEXT)}
          >
            <IonIcon slot="icon-only" icon={chevronForward} />
          </IonButton>
        </IonButtons></IonRow>)}
        
        
      </IonRow>
      <IonRow className="rbc-toolbar rbc-toolbar-btm">
        <IonButtons className="views-seg">
          {viewNamesGroup(messages)}
        </IonButtons>
        <IonButton
          className="new-event-btn-calendar"
          routerLink="/newevent"
          fill="clear"
          mode="ios"
        >
          <IonIcon
            src={add}
            style={{
              color: "#B99855",
              size: 16,
              marginRight: 5,
              verticalAlign: "middle",
            }}
          ></IonIcon>
          New Event
        </IonButton>
      </IonRow>
      {/* <IonRow style={{ marginLeft: 4, marginTop: -16, marginBottom: 16 }}>
        <IonText color="greyscale">
          *Calendar Events are synced with the server every 15 minutes
        </IonText>
      </IonRow> */}
    </React.Fragment>
  );
};

interface CalendarPageProps
  extends RouteComponentProps<{
    id: string;
  }> {}

const MyCalendar: React.FC<CalendarPageProps> = ({ match, history }) => {
  const [eventList, setEventList] = useState<any>([]);
  const [isLoading, setLoading] = React.useState(false);

  const decrypt = (decryptVlaue) => {
    let passphrase = "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9"
    const bytes = CryptoJS.AES.decrypt(decryptVlaue, passphrase);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  };

  const getUserId = async () => {
    const ret: any = await Storage.get({ key: "userId" });
    return JSON.parse(decrypt(ret?.value))?.id;
  };

  const getUserEmail = async () => {
    const ret: any = await Storage.get({ key: "userId" })
    const temp = JSON.parse(decrypt(ret?.value))
    return temp && temp.email ? temp.email : null
}

const today = new Date();
const thisMonth = today.getMonth()
const [selectedDate, setSelectedDate] = useState<any>(today)
const [selectedMonth, setselectedMonth] = useState<any>(thisMonth)

useEffect(() => {
  setselectedMonth(selectedDate.getMonth())
},[selectedDate])



  useEffect(() => {
    setLoading(true);
    const getMeetings = () => {
      Device.getInfo().then(deviceInfo => { 
      getUserEmail().then(useremail => {  
      getUserId().then((rmid) => {
        if(deviceInfo.platform == "web")
          {
              window.globalThis.firebase.analytics().setUserId(useremail);
             window.globalThis.firebase.analytics().logEvent('select_content', {
              user_name: useremail,
              content_type: 'ViewCalender',
              item_id: useremail,
              device_id:deviceInfo?deviceInfo.uuid:"",
              platform:deviceInfo?deviceInfo.platform:"",
              device_model:deviceInfo?deviceInfo.model:"",
              device_type:deviceInfo?deviceInfo.osVersion:"",
              device_os:deviceInfo?deviceInfo.operatingSystem:"",
            });
          }
          else
          {   
                FirebaseAnalytics.setUserId(useremail);
                FirebaseAnalytics.logEvent("select_content", {user_name: useremail});
                FirebaseAnalytics.logEvent("select_content", {content_type: 'ViewCalender'});
                FirebaseAnalytics.logEvent("select_content", {item_id: useremail});
                FirebaseAnalytics.logEvent("select_content", {device_id: deviceInfo?deviceInfo.uuid:""});
                FirebaseAnalytics.logEvent("select_content", {platform: deviceInfo?deviceInfo.platform:""});
                FirebaseAnalytics.logEvent("select_content", {device_model: deviceInfo?deviceInfo.model:""});
                FirebaseAnalytics.logEvent("select_content", {device_type:deviceInfo?deviceInfo.osVersion:""});
                FirebaseAnalytics.logEvent("select_content", {device_os:deviceInfo?deviceInfo.operatingSystem:""});
          }
       
        // return api
        //   .GetMeetings(rmid,"all")
        //   .then((response) => {
        //     if (response) {
        //       // settemp(response)
        //       setEventList(
        //         response.map((obj) => {
        //           const {
        //             contactFirstName,
        //             contactMiddleName,
        //             contactLastName,
        //             metaDataTitle,
        //             metaDataStartTime,
        //             metaDataEndTime,
        //             metaDataBody,
        //             engagementId,
        //           } = obj;
        //           let tempmetaDataStartTime = metaDataStartTime;
        //           console.log(metaDataStartTime)
        //           tempmetaDataStartTime =
        //             metaDataStartTime.slice(0, 10) +
        //             "T" +
        //             metaDataStartTime.slice(11, 21);
        //             console.log(tempmetaDataStartTime)
        //           let stdt = new Date(new Date(tempmetaDataStartTime));
        //           console.log(stdt)
        //           stdt.setMinutes(stdt.getMinutes() - stdt.getTimezoneOffset());
        //           console.log(stdt)

        //           let tempmetaDataEndTime = metaDataEndTime;
        //           tempmetaDataEndTime =
        //             metaDataEndTime.slice(0, 10) +
        //             "T" +
        //             metaDataEndTime.slice(11, 21);
        //           let enddt = new Date(new Date(tempmetaDataEndTime));
        //           enddt.setMinutes(
        //             enddt.getMinutes() - enddt.getTimezoneOffset()
        //           );
        //           console.log(metaDataStartTime)
                  
                  

        //           return {
        //             name:
        //               contactMiddleName && contactMiddleName !== ""
        //                 ? `${contactFirstName} ${contactMiddleName} ${contactLastName}`
        //                 : `${contactFirstName} ${contactLastName}`,
        //             title:
        //               metaDataTitle !== null && metaDataTitle !== ""
        //                 ? metaDataTitle
        //                 : "-",
        //             start: stdt,
        //             end: enddt,
        //             body: metaDataBody,
        //             engId: engagementId,
        //           };
        //         })
        //       );
        //     }
        //     return response;
        //   })
        //   .catch((error) => {
        //     console.error(error);
        //   });

    //  maveric1@tfoco.com?startDateTime=2021-02-01T00:00:00&endDateTime=2021-02-12T23:00:00&$top=200

        let startDateTime = selectedDate.getFullYear() + "-" + moment(selectedDate).format("MM") + "-01T00:00:00"
        let endDateTime = selectedDate.getFullYear() + "-" + moment(selectedDate).format("MM") + "-" + moment(selectedDate).daysInMonth() + "T23:59:59"
       

        return api
            .getCalendarEvent(useremail,startDateTime,endDateTime) //startDateTime,endDateTime
            .then((response) => {
               
              if (response) {
                      setEventList(
                        response.value.map((obj) => {
                          const {
                            subject,
                            start,
                            end,
                            id,
                            attendees
                          } = obj;

                          let tempmetaDataStartTime = start.dateTime.substring(0,21);
                          let stdt = new Date(new Date(tempmetaDataStartTime));
                          stdt.setMinutes(stdt.getMinutes() - stdt.getTimezoneOffset());

                          let tempmetaDataEndTime = end.dateTime.substring(0,21);
                          let enddt = new Date(new Date(tempmetaDataEndTime));
                          enddt.setMinutes(
                            enddt.getMinutes() - enddt.getTimezoneOffset()
                          );

                          let attLen = attendees.length
                          let name =  ""
                          let email = ""
                          if(attLen > 0)
                          {
                            for(let i = 0; i < attLen; i++)
                            {
                              if(name === "")
                                name = attendees[i].emailAddress.name
                              else
                                name = name + "," + attendees[i].emailAddress.name

                              if(email === "")
                                email = attendees[i].emailAddress.address
                              else
                                email = email + "," + attendees[i].emailAddress.address
                            }
                          }

                          return {
                            name: name,
                            title: subject,
                            start: stdt,
                            end: enddt,
                            body: subject,
                            engId: id,
                            email: email
                          }
                        })
                      )
                      setLoading(false);
                    }
                    else
                    {
                      setLoading(false);
                    }
                  })
                .catch((error) => {
                  setLoading(false);
                  console.error(error);
                });
      });
    })
      })
    };

    getMeetings();
  }, [selectedMonth]);

  const localizer = momentLocalizer(moment);

  const eventColors = (event) => {
    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor,
    };
  };

  let formats = {
    dateFormat: (date, culture, localizer) =>
      localizer.format(date, "D", culture),

    dayFormat: (date, culture, localizer) =>
      localizer.format(date, "D ddd", culture),

    timeGutterFormat: (date, culture, localizer) =>
      localizer.format(date, "h a", culture),

    eventTimeRangeFormat: ({ start, end }, culture, localizer) => "",
  };

  const handleSlotSelect = (slotInfo) => {
    history.push("/newevent", {
      start: moment(slotInfo.start).format("YYYY-MM-DD hh:mm A"),
      end: moment(slotInfo.end).format("YYYY-MM-DD hh:mm A"),
    });
  };

  

  return (
    <IonPage>
      <IonLoading
      cssClass="my-custom-loading-class"
      isOpen={isLoading}
      onDidDismiss={() => setLoading(false)}
      message={"Please wait..."}
    />
      <SearchHeader />
      <IonContent className="ion-padding cal-content">
        <Calendar
          selectable={true}
          formats={formats}
          defaultDate={new Date()}
          date={selectedDate}
          scrollToTime={new Date()}
          defaultView="day"
          // toolbar={false}
          onSelectSlot={handleSlotSelect}
          localizer={localizer}
          events={eventList}
          views={["day", "week", "month"]}
          min={
            new Date(today.getFullYear(), today.getMonth(), today.getDate(), 7)
          }
          max={
            new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23)
          }
          components={{
            // event:MyEvent,
            // day:{header: MyCustomHeader},
            day: { event: MyEvent },
            toolbar: MyToolBar,
          }}
          startAccessor="start"
          endAccessor="end"
          eventPropGetter={eventColors}
          onSelectEvent={(event) => {
            history.push("/viewevent", event);
          }}
          onNavigate={(date) => setSelectedDate(date)}
        />
      </IonContent>
    </IonPage>
  );
};

export default MyCalendar;
