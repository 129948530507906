import React from 'react'
import { 
    IonButton,
    IonChip,
    IonRow
} from "@ionic/react";
import "./FilterChips.css"

const styles = {
    row: {
        display: "flex",
        height: 40,
        paddingLeft: 8,
        paddingRight: 8,
        // marginBottom: 12,
    },
    chip: {
        backgroundColor: "#A5D2DC",
        color: "#222222",
        fontWeight: "400",
        fontSize: 12,
        marginTop: "auto",
        marginBottom: "auto"
    },
    clearbtn: {
        marginTop: "auto",
        marginBottom: "auto"
    }
}

interface ChipProps {
    chipArr: Array<string>;
    clrFilters: Function;
}

const FilterChips: React.FC<ChipProps> = ({
    chipArr,
    clrFilters
}) => {
    return(
        chipArr.length > 0 ? 
        <IonRow className="margintop-desktop" style={styles.row}>
            {
                chipArr.map(chip => 
                    <IonChip class="mh-18" key={chip} style={styles.chip}>{chip}</IonChip>
                )
            }
            <IonButton
            class="fs-13"
                fill="clear"
                mode="ios"
                color="gold"
                size="small"
                style={styles.clearbtn}
                onClick={()=>{clrFilters()}}
            >
                Clear All Filters
            </IonButton>
        </IonRow> :
         <IonRow className="margintop-desktop" style={styles.row}/>
    )
}

export default FilterChips