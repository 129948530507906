import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonContent,
  IonLoading,
  IonButton,
  IonButtons,
  IonCard,
  IonPopover,
  IonCardContent,
  IonFooter,
  IonText,
} from "@ionic/react";
import { useHistory } from "react-router";
import "./DealAnalysis.css";
import { chevronBack, funnel } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import api from "src/api";
import CryptoJS from "crypto-js";
import { Device, Plugins } from "@capacitor/core";
import DealAnalysisCard from "src/components/Deals/DealAnalysisCard";
import moment from "moment";
import { meetingNotePayload } from "src/types";
import SearchBox from "src/components/Deals/SearchBox";

import ReactPaginate from "react-paginate";
export default function DealAnalysis(match) {
  const styles = {
    toolbar: {
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 16,
      paddingRight: 16,
    },
    heroText: {
      fontWeight: 300,
      fontSize: 24,
      lineHeight: "120%",
      color: "#C7C7C7",
    },
  };
  const getId = match.match.params.id;
  const { Storage } = Plugins;
  const [isLoading, setLoading] = useState(false);
  const [selSeg, setselSeg] = React.useState<string>("prospects");
  const [backButtonText, setBackButtonText] = useState<string>("Dashboard ");
  const [prospectList, setProspectList] = useState<any>();
  const [dealDetails, setDealDetails] = useState<any>();
  const [isFilter, setIsFilter] = useState(false);
  const [dealAnalysisData, setDealAnalysisData] = useState<any>();
  const inpref = React.useRef<HTMLInputElement>(null);
  const [mainArray, setMainArray] = useState<any>();
  const [submittedList, setSubmittedList] = useState<any>();
  const [showPopover, setShowPopover] = useState<{
    open: boolean;
    event: Event | undefined;
  }>({
    open: false,
    event: undefined,
  });

  const itemsPerPage = 20;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;

  const [currentItems, setCurrentItems] = useState<any>();
  const pageCount = Math.ceil(prospectList?.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState<any>(0);
  const [activeBtn, setActiveBtn] = useState(false);
  const [clientCount, setclientCount] = useState<any>();
  const [prospectCount, setprospectCount] = useState<any>();
  // Invoke when user click to request another page.
  useEffect(() => {
    let client_Count = sessionStorage.getItem("clientCount");
    let prospect_Count = sessionStorage.getItem("prospectCount");
    setclientCount(client_Count);
    setprospectCount(prospect_Count);
    if (!prospectList) return;
    const items = prospectList?.slice(itemOffset, endOffset);
    setCurrentItems(items);
  }, [prospectList, itemOffset]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % prospectList.length;
    setItemOffset(newOffset);
    setCurrentPage(event.selected);
  };
  const hist = useHistory();
  const decimalFunc = (Num) => {
    let temp = Num.toString().split(".");
    let retVal = 0;
    if (temp[1]) {
      retVal = Num.toFixed(1);
    } else {
      retVal = Num;
    }
    return retVal;
  };
  function iOS() {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) || navigator.userAgent.includes("Mac")
    );
  }
  const convertDateTime = (date, time) => {
    let dateTimeStr = date + "T" + time;
    let dateTimeStr_new = new Date(new Date(dateTimeStr));
    let dateTimeStr_ios = dateTimeStr_new.setMinutes(
      dateTimeStr_new.getMinutes() + dateTimeStr_new.getTimezoneOffset()
    );
    let DateTimeDt = new Date(dateTimeStr);
    let dateTimeUTC = DateTimeDt.toUTCString();
    let isounixVal = parseInt(moment(dateTimeUTC).format("x"));

    if (iOS()) {
      return isounixVal;
    } else {
      return isounixVal;
    }
  };
  const retExpClsAmt = (clsAmt) => {
    let closingamt_disp: number = 0;
    Number.isNaN(clsAmt) || clsAmt === null || clsAmt === ""
      ? (closingamt_disp = 0)
      : (closingamt_disp = parseInt(clsAmt));
    return Math.abs(Number(closingamt_disp)) >= 1.0e9
      ? decimalFunc(Math.abs(Number(closingamt_disp)) / 1.0e9) + "b"
      : // Six Zeroes for Millions
      Math.abs(Number(closingamt_disp)) >= 1.0e6
      ? decimalFunc(Math.abs(Number(closingamt_disp)) / 1.0e6) + "m"
      : // Three Zeroes for Thousands
      Math.abs(Number(closingamt_disp)) >= 1.0e3
      ? decimalFunc(Math.abs(Number(closingamt_disp)) / 1.0e3) + "k"
      : decimalFunc(Math.abs(Number(closingamt_disp)));
  };
  const decrypt = (decryptVlaue) => {
    let passphrase =
      "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9";
    const bytes = CryptoJS.AES.decrypt(decryptVlaue, passphrase);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  };
  const getUserId = async () => {
    const ret: any = await Storage.get({ key: "userId" });
    return JSON.parse(decrypt(ret?.value))?.id;
  };
  const seperateSubmitArray = (searchApi, dealAnalysisApi) => {
    let submittedList: any = [];
    let unSubmittedList: any = [];

    searchApi.map((array) => {
      let dealAnalysisObj = dealAnalysisApi.find(({ prospectId }) => {
        return prospectId == array.id;
      });
      let result = {
        ...array,
        isDiscussed: dealAnalysisObj ? dealAnalysisObj.isDiscussed : "",
        isInterested: dealAnalysisObj ? dealAnalysisObj.isInterested : "",
        meetingNoteId: dealAnalysisObj ? dealAnalysisObj.meetingNoteId : "",
        potentialInvestment: dealAnalysisObj
          ? dealAnalysisObj.potentialInvestment
          : "",
        reason: dealAnalysisObj ? dealAnalysisObj.reason : "",
        comment: dealAnalysisObj ? dealAnalysisObj.comment : "",
        cardId: dealAnalysisObj ? dealAnalysisObj.id : "",
      };
      if (dealAnalysisObj) {
        submittedList.push(result);
      } else {
        unSubmittedList.push(result);
      }
    });
    setSubmittedList(submittedList);
    return [...unSubmittedList, ...submittedList];
  };

  const seperateTopTargetArray = (searchApi, topTargetResponse) => {
    const common = searchApi.filter((combinedArrayElement) => {
      return topTargetResponse.some((topTargetElement) => {
        return topTargetElement.contactid == combinedArrayElement.id;
      });
    });
    const unique = searchApi.filter((combinedArrayElement) => {
      return topTargetResponse.some((topTargetElement) => {
        return topTargetElement.contactid != combinedArrayElement.id;
      });
    });

    return [...common, ...unique];
  };

  useEffect(() => {
    try {
      api.getSingleDeal(getId).then((resp) => {
        if (resp.story) {
          setDealDetails(resp.story);
        }
      });
    } catch (error) {
      console.log("Error", error);
    }
  }, []);

  useEffect(() => {
    getDealAnalysisData();
  }, []);
  const getDealAnalysisData = () => {
    getUserId().then((userId) => {
      try {
        api.getDealAnalysisData(userId, getId).then((resp) => {
          setDealAnalysisData(resp);
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  useEffect(() => {
    if (dealAnalysisData) {
      getProspectOrClientList();
    }
  }, [activeBtn, dealAnalysisData, isFilter]);
  const getProspectOrClientList = () => {
    const properties = [
      "hubspot_owner_id",
      "title_salutation",
      "firstname",
      "middle_name",
      "lastname",
      "first_name_arabic",
      "arabic_last_name",
      "arabic_middle_name",
      "gender",
      "age_range",
      "email",
      "phone",
      "mobilephone",
      "address_line_1",
      "address_line_2",
      "city",
      "country",
      "nationality_1_",
      "company",
      "position",
      "industry_",
      "source_of_wealth",
      "expected_wealth_range",
      "bio_profile",
      "general_remarks",
      "preferred_channel",
      "school",
      "degree",
      "segment",
      "contact_type",
      "lifecyclestage",
      "lead_stage",
      "hs_lead_status",
      "financial_sophistication",
      "technology_sophistication",
      "building_details",
      "politically_exposed_persons_pep",
      "sharia_compliant",
      "expected_closing_probability",
      "amount_status",
      "expected_month_of_closing",
      "source_of_name",
      "preferred_time_of_day",
      "notes_last_contacted",
      "onboarding_id",
      "hs_avatar_filemanager_key",
      "hs_lifecyclestage_customer_date",
    ];
    getUserId().then((userId) => {
      setLoading(true);
      let payload: any = {
        sorts: [
          {
            propertyName: "notes_last_contacted",
            direction: "ASCENDING",
          },
        ],
        limit: prospectCount,
        order: ["notes_last_contacted"],
        filterGroups: [
          {
            filters: [
              {
                operator: "EQ",
                propertyName: "hubspot_owner_id",
                value: parseInt(userId),
              },
              {
                operator: "NOT_HAS_PROPERTY",
                propertyName: "hs_lifecyclestage_customer_date",
              },
            ],
          },
        ],
        properties: properties,
      };
      if (activeBtn == false) {
        api.filterProspectsAPI({ ...payload }).then((resp) => {
          if (resp) {
            api
              .getTTfromStore()
              .then((topTargetResponse) => {
                if (topTargetResponse.length > 0) {
                  const getTopTargetArray = seperateTopTargetArray(
                    resp.results,
                    topTargetResponse
                  );
                  const getSeperateSubmitArray = seperateSubmitArray(
                    getTopTargetArray,
                    dealAnalysisData
                  );
                  isFilter
                    ? setProspectList(submittedList)
                    : setProspectList(getSeperateSubmitArray);
                  isFilter
                    ? setMainArray(submittedList)
                    : setMainArray(getSeperateSubmitArray);
                } else {
                  const getSeperateSubmitArray = seperateSubmitArray(
                    resp.results,
                    dealAnalysisData
                  );
                  isFilter
                    ? setProspectList(submittedList)
                    : setProspectList(getSeperateSubmitArray);
                  isFilter
                    ? setMainArray(submittedList)
                    : setMainArray(getSeperateSubmitArray);
                }
              })
              .catch((error) => {
                console.error("Error from Conacts API: ", error);
                setLoading(false);
              });
          }
          setLoading(false);
        });
      } else {
        let payload: any = {
          sorts: [
            {
              propertyName: "notes_last_contacted",
              direction: "ASCENDING",
            },
          ],
          limit: clientCount,
          order: ["notes_last_contacted"],
          filterGroups: [
            {
              filters: [
                {
                  operator: "EQ",
                  propertyName: "hubspot_owner_id",
                  value: parseInt(userId),
                },
                {
                  operator: "HAS_PROPERTY",
                  propertyName: "hs_lifecyclestage_customer_date",
                },
              ],
            },
          ],
          properties: properties,
        };
        api.filterProspectsAPI({ ...payload }).then((resp) => {
          if (resp) {
            api
              .getTTfromStore()
              .then((topTargetResponse) => {
                if (topTargetResponse.length > 0) {
                  const getTopTargetArray = seperateTopTargetArray(
                    resp.results,
                    topTargetResponse
                  );
                  const getSeperateSubmitArray = seperateSubmitArray(
                    getTopTargetArray,
                    dealAnalysisData
                  );
                  isFilter
                    ? setProspectList(submittedList)
                    : setProspectList(getSeperateSubmitArray);
                  isFilter
                    ? setMainArray(submittedList)
                    : setMainArray(getSeperateSubmitArray);
                } else {
                  const getSeperateSubmitArray = seperateSubmitArray(
                    resp.results,
                    dealAnalysisData
                  );
                  isFilter
                    ? setProspectList(submittedList)
                    : setProspectList(getSeperateSubmitArray);
                  isFilter
                    ? setMainArray(submittedList)
                    : setMainArray(getSeperateSubmitArray);
                }
              })
              .catch((error) => {
                console.error("Error from Conacts API: ", error);
                setLoading(false);
              });
          }
          setLoading(false);
        });
      }
    });
  };
  const handleSubmit = (values) => {
    const notes =
      values.isInterested == "yes"
        ? `Deal Name:${dealDetails.name}\n Discussed:${
            values.isDiscussed == "on" ? "Yes" : "No"
          }\n Interested:${
            values.isInterested == "yes" ? "Yes" : "No"
          }\n Potential Investment:${values.potentialInvestment}\n Feedback:${
            values.comment
          }`
        : `Deal Name:${dealDetails.name}\n Discussed:${
            values.isDiscussed == "on" ? "Yes" : "No"
          }\n Interested:${
            values.isInterested == "yes" ? "Yes" : "No"
          }\n Reason:${values.reason}\n Feedback:${values.comment}`;
    getUserId().then(async (userId) => {
      const getTime = new Date();
      const meetingPayload: meetingNotePayload = {
        createdBy: userId,
        updatedBy: userId,
        description: "",
        meetingDateTime: convertDateTime(
          moment(getTime).format("YYYY-MM-DD"),
          moment(getTime).format("HH:mm:00")
        ),
        meetingEndDateTime: convertDateTime(
          moment(getTime).format("YYYY-MM-DD"),
          moment(getTime).format("HH:mm:00")
        ),
        clientType: "Principle",
        meetingTopic: "Deal/Program Discussion",
        meetingType: "Phone Call Meeting",
        notes: notes,
        hubspotMeetingId: values.hubspotOwnerId ? values.hubspotOwnerId : "",
        gvc: "",
        invObjective: "",
        invLiquidity: "",
        optLocalEconomy: "",
        optIntlEconomy: "",
        optCorebusiness: "",
        apetiteIntlExposure: "",
        liquidityEvent: "",
        followupTaskId: 0,
        attendees: "",
      };
      let submitData = {
        dealId: dealDetails.id.toString(),
        dealName: dealDetails.name,
        rmid: userId.toString(),
        prospectId: values.prospectId,
        isDiscussed: values.isDiscussed == "yes" ? true : false,
        isInterested: values.isInterested == "yes" ? true : false,
        comment: values.comment,
        reason: values.reason,
        potentialInvestment: values.potentialInvestment
          ? parseInt(values.potentialInvestment)
          : 0,
      };
      if (values.method == "submit") {
        try {
          api.createNote(values.prospectId, meetingPayload).then((res) => {
            api
              .sendDealAnalysisData({
                ...submitData,
                meetingNoteId: res.rowId.toString(),
              })
              .then((res) => {});
          });

          setShowPopover({ open: true, event: undefined });
        } catch (err) {
          console.log("Error", err);
        }
      } else {
        try {
          api.UpdateMeetingNoteById(values.meetingNoteId, meetingPayload);
          api
            .updateDealAnalysisData(values.dealId, submitData)
            .then((res) => {});
          setShowPopover({ open: true, event: undefined });
        } catch (err) {
          console.log("Error", err);
        }
      }
    });
  };
  const handleKeyUp = () => {
    if (inpref.current) {
      const currentInp = inpref.current;
      if (currentInp.value == "") {
        getProspectOrClientList();
      }
      if (currentInp.value.length >= 3) {
        const searchedData = mainArray.filter((el) => {
          if (currentInp.value != el.properties.onboarding_id) {
            let fullname = `${el.properties.firstname.toLowerCase()} ${el.properties.lastname.toLowerCase()}`;
            return fullname.includes(currentInp.value.toLowerCase());
          } else {
            let onboarding_id = `${el.properties.onboarding_id}`;
            return onboarding_id.includes(currentInp.value);
          }
        });
        setProspectList(searchedData);
      }
    }
  };
  const handleFilter = () => {
    setCurrentPage(0);
    setItemOffset(0);
    setIsFilter(true);
  };
  const handleClearFilter = () => {
    setCurrentPage(0);
    setItemOffset(0);
    setIsFilter(false);
  };
  const handelPopOverClose = () => {
    getDealAnalysisData();
    setShowPopover({
      open: false,
      event: undefined,
    });
  };

  return (
    <IonPage>
      <IonHeader className="dealHeader">
        <IonToolbar mode="md" style={styles.toolbar}>
          <IonButtons slot="start">
            <IonButton
              style={{ textTransform: "none" }}
              color="white"
              onClick={() => {
                selSeg === "allclients"
                  ? hist.push(`/dashboard`, { to: "clients" })
                  : hist.push(`/dashboard`, { to: "deals" });
              }}
            >
              <IonIcon icon={chevronBack}></IonIcon>
              {backButtonText}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent
        style={{
          "--background": "none",
        }}
      >
        <IonLoading
          cssClass="my-custom-loading-class"
          isOpen={isLoading}
          onDidDismiss={() => setLoading(false)}
          message={"Please wait..."}
        />

        <IonRow class="mt-6 ion-align-items-center margin-set dashgrid-mobile">
          <IonCol style={styles.heroText}>Deal Analysis</IonCol>
          <IonCol>
            <SearchBox
              isActive={activeBtn}
              handleKey={handleKeyUp}
              inpRef={inpref}
            />
          </IonCol>
        </IonRow>
        <IonRow
          style={{ padding: 5 }}
          class="mt-6 ion-align-items-center margin-set dashgrid-mobile"
        >
          <IonCol>
            <button
              className={`tab ${!activeBtn ? "activeTab" : ""}`}
              onClick={() => {
                setActiveBtn(false);
                setIsFilter(false);
              }}
            >
              Prospects
            </button>
            <button
              className={`tab ${activeBtn ? "activeTab" : ""}`}
              onClick={() => {
                setActiveBtn(true);
                setIsFilter(false);
              }}
            >
              Clients
            </button>
          </IonCol>
          {isFilter == false ? (
            <IonButton
              fill="clear"
              size="small"
              color="gold"
              class="ion-text-capitalize"
              onClick={() => {
                handleFilter();
              }}
            >
              Submitted <IonIcon color="gold" icon={funnel} size="small" />
            </IonButton>
          ) : (
            <IonButton
              fill="clear"
              size="small"
              class="ion-text-capitalize"
              onClick={handleClearFilter}
            >
              <IonText color="gold">Clear</IonText>
            </IonButton>
          )}
        </IonRow>

        <IonRow class="mt-6 margin-set dashgrid-mobile">
          <IonCol style={{ padding: 0 }}>
            {currentItems && currentItems.length > 0
              ? currentItems.map((value, i) => (
                  <div key={i}>
                    {dealDetails ? (
                      <DealAnalysisCard
                        clientName={`${value.properties.firstname} ${value.properties.lastname}`}
                        percentage={`${value.properties.expected_closing_probability}`}
                        amount={`$${retExpClsAmt(
                          value.properties.amount_status
                        )}`}
                        email={value.properties.email}
                        segment={value.properties.segment}
                        number={value.properties.phone}
                        dealName={dealDetails.name}
                        hubSpotOwnerId={value.properties.hubspot_owner_id}
                        submit={handleSubmit}
                        id={value.id}
                        dealId={dealDetails.id.toString()}
                        dealAnalysisData={dealAnalysisData}
                        prospectList={prospectList}
                        isDiscussed={value.isDiscussed}
                        isInterested={value.isInterested}
                        reason={value.reason}
                        comment={value.comment}
                        meetingNoteId={value.meetingNoteId}
                        potentialInvestment={value.potentialInvestment}
                        cardId={value.cardId}
                      />
                    ) : null}
                  </div>
                ))
              : !isLoading && <IonText>No Data Found</IonText>}
            <IonCardContent>
              <IonPopover
                isOpen={showPopover.open}
                backdropDismiss={false}
                cssClass="deletetargetpopoverclass"
                event={showPopover.event}
                onDidDismiss={(e) =>
                  setShowPopover({ open: false, event: undefined })
                }
                mode="md"
              >
                <IonCard>
                  {
                    <div>
                      <IonGrid class="popoverGridClass">
                        <IonRow
                          style={{
                            color: "#828282",
                            justifyContent: "center",
                            fontSize: 14,
                          }}
                          class="ion-text-center ion-margin-top ion-margin-bottom"
                        >
                          Successfully submitted response and created a meeting
                          note.
                        </IonRow>
                        <IonRow
                          class="ion-text-center ion-margin-bottom ion-margin-top"
                          style={{
                            color: "#B99855",
                            marginTop: "30px",
                            borderTop: "1px solid rgba(34,34,34,0.5)",
                            cursor: "pointer",
                            justifyContent: "center",
                          }}
                        >
                          <IonCol>
                            <IonButton
                              onClick={handelPopOverClose}
                              class="ConfirmationButton"
                              fill="clear"
                            >
                              Close
                            </IonButton>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </div>
                  }
                </IonCard>
              </IonPopover>
            </IonCardContent>
          </IonCol>
        </IonRow>
      </IonContent>
      <IonFooter>
        <IonRow
          class="mt-6 margin-set dashgrid-mobile"
          style={{
            justifyContent: "end",
            marginBottom: "2%",
          }}
        >
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            pageCount={pageCount}
            previousLabel="< previous"
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
            forcePage={currentPage}
          />
        </IonRow>
      </IonFooter>
    </IonPage>
  );
}
