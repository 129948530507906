import React, { useState, useEffect } from 'react'
import { useLocation, Redirect } from 'react-router-dom'
import { RouteComponentProps } from "react-router";
import CryptoJS from 'crypto-js';
import Base64 from 'crypto-js/enc-base64';
import { Plugins } from "@capacitor/core";
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics';
import jwt_decode from "jwt-decode";
import { IonLoading } from "@ionic/react";
import { Geolocation, Geoposition } from '@ionic-native/geolocation';
import { registerBioMetricAuth , loginWithBioMetricAuth } from 'src/pages/Auth/BioMetricAuth';
import api from "src/api";
import moment from 'moment';
import { initNetworkListen } from 'src/offlineUtils/networkUtil';
const { Storage } = Plugins
const { Device } = Plugins;

const encrypt = (text) => {  
    let passphrase = "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9"
    return CryptoJS.AES.encrypt(text, passphrase).toString();
  };
  const decrypt = (decryptVlaue) => {
    let passphrase = "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9"
   const bytes = CryptoJS.AES.decrypt(decryptVlaue, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
  };



const setTokens = async (hash: Array<any>) => {
    await Storage.set({
        key: "cognito",
        value: JSON.stringify({
            access_token: hash[0],
            id_token: hash[0]
        })
    })
}

const setUId = async (Obj) => {
    await Storage.set({
        key: "userId",
        value: encrypt(JSON.stringify({
            id: Obj.id,
            fName: Obj.fName,
            lName: Obj.lName,
            email: Obj.email,
            teams: Obj.teams
        })
        )
    })
}

const setProperties = async (Obj) => {
    await Storage.set({
        key: "enumproperties",
        value: JSON.stringify({
            resultsArr: Obj
        })
    })
}

const setTopTargets = async (Obj) => {
    await Storage.set({
        key: "toptargets",
        value: JSON.stringify({
            result: Obj
        })
    })
}
const getUserEmail = async () => {
    const ret: any = await Storage.get({ key: "userId" })
    const temp = JSON.parse(decrypt(ret.value))
    return temp && temp.email ? temp.email : null
}
const getUserId = async () => {
    const ret: any = await Storage.get({ key: "userId" });
    return JSON.parse(decrypt(ret.value)).id;
  };
const getLocation = async () => {           
    const position = await Geolocation.getCurrentPosition();
    //console.log(Geolocation);
    //setPosition(position);  
    return position      
}
function calltoUpdateLocation()
{                
    Device.getInfo().then(deviceInfo => {  
        getUserId().then(userId => {
    getUserEmail().then(useremail => {  
        //console.log(useremail); 
        if(deviceInfo.platform == "web")
            {
                window.globalThis.firebase.analytics().setUserId(useremail);
                window.globalThis.firebase.analytics().logEvent('select_content', {
                user_name: useremail,
                content_type: 'LoggedIn',
                item_id: useremail,
                device_id:deviceInfo?deviceInfo.uuid:"",
                platform:deviceInfo?deviceInfo.platform:"",
                device_model:deviceInfo?deviceInfo.model:"",
                device_type:deviceInfo?deviceInfo.osVersion:"",
                device_os:deviceInfo?deviceInfo.operatingSystem:"",
                });
            }
        else
            {
                FirebaseAnalytics.setUserId(useremail);
                FirebaseAnalytics.logEvent("select_content", {user_name: useremail});
                FirebaseAnalytics.logEvent("select_content", {content_type: 'LoggedIn'});
                FirebaseAnalytics.logEvent("select_content", {item_id: useremail});
                FirebaseAnalytics.logEvent("select_content", {device_id: deviceInfo?deviceInfo.uuid:""});
                FirebaseAnalytics.logEvent("select_content", {platform: deviceInfo?deviceInfo.platform:""});
                FirebaseAnalytics.logEvent("select_content", {device_model: deviceInfo?deviceInfo.model:""});
                FirebaseAnalytics.logEvent("select_content", {device_type:deviceInfo?deviceInfo.osVersion:""});
                FirebaseAnalytics.logEvent("select_content", {device_os:deviceInfo?deviceInfo.operatingSystem:""});
            }
        
        getLocation().then(loc=>
        {
            //console.log(loc.coords);
            let newdate = new Date();
            let currDate  = moment(newdate).utc().format("x");
            //console.log(currDate);
            let loction:any = "";
            //if(loc && loc.coords)
            loction = loc && loc.coords ? loc.coords.latitude + "," + loc.coords.longitude:"";
            let payloads: any = {
                "lastLogin": currDate,
                "rmEmail": useremail,
                "location":loction
            }
            api.updatelocation(payloads).then((resp) => {
                //console.log(resp);
               // api.getlocation(useremail).then(resp => { 
                   // console.log(resp);
                // })
            })
        }).catch((e) => {
            //console.log(e.message);
            if(e.message == "User denied Geolocation")
            {
                let newdate = new Date();
                let currDate  = moment(newdate).utc().format("x");                
                let payloads: any = {
                    "lastLogin": currDate,
                    "rmEmail": useremail,
                    "location":""
                }
                api.updatelocation(payloads).then((resp) => {                    
                    // api.getlocation(useremail).then(resp => {
                    // // console.log(resp);
                    // })
                })
            }
        })             
    })  
})
    })      
}
const CheckRedirect: React.FC<any> = () => {

    

    const [to, setto] = useState<string>("/nowhere")
    const loc = useLocation()
    console.log(loc);
    useEffect(() => {
        console.log('CheckRedirect useEffect');
        let hashArr = loc.hash.split("&")
        // let to = "/login"
        hashArr && hashArr[0] ? setto('/nowhere') : setto('/login')
        if (hashArr && hashArr[0]) {
            setTokens(hashArr)
            var token = hashArr[0].split("=")[1]
            var decoded: any = jwt_decode(token);
            console.log('Bearer ',token);
            console.log('decoded',decoded);
            if (decoded.unique_name) {
                let RM_unique_name= "RMappprovider_"+decoded.unique_name;
                console.log(RM_unique_name);
                let username = RM_unique_name.split("_")[1]
                console.log(username);
                api.getOwnerswithProps(username).then((resp) => {
                    console.log(resp);
                    if (resp.results[0]) {
                        let UserID =  resp.results[0].id;
                        // console.log(resp.results[0]);
                        setUId({
                            id: resp.results[0].id,
                            fName: resp.results[0].firstName,
                            lName: resp.results[0].lastName,
                            email: resp.results[0].email,
                            teams:resp.results[0].teams,
                        }).then(() => {
                            api.getContactPropertiesByPropertyBatch().then(resp => {
                                setProperties(resp.results).then(() => {
                                    api.getTopTargets(UserID).then((ttresp) => {
                                        let temptt: any = {}
                                        ttresp && ttresp.prospect_list ? temptt = ttresp.prospect_list : temptt = []
                                        setTopTargets(temptt).then(async() => {
                                            
                                            await initNetworkListen()
                                            setto("/dashboard")
                                            calltoUpdateLocation();
                                            await registerBioMetricAuth();   
                                        })
                                    })
                                }).catch(() => {
                                    setto("/login")
                                })
                            })
                        })
                    }
                    else {
                        setto("/login")
                    }
                    // setLoading(false)
                });
            } else {
                setto("/login")
            }
        } else {
            setto("/login")
        }
    }, [loc.pathname])


   

    return (
        to === "/nowhere" ?
            <IonLoading
                isOpen={to === "/nowhere"}
                message={'Please wait...'}
            />
            : <Redirect  to={to} />
    )
}

export default CheckRedirect