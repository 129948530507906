import React, { useEffect, useState } from "react";
import {
  IonCard,
  IonRow,
  IonCol,
  IonIcon,
  IonText,
  IonButton,
  IonLabel,
  IonItem,
  IonRadioGroup,
  IonRadio,
} from "@ionic/react";
import smallWallet from "src/icons/smallWallet.svg";
import email from "src/icons/email.svg";
import chevron from "src/icons/Chevron.svg";
import royal from "src/icons/royal.svg";
import phone from "src/icons/phone.svg";
import * as Yup from "yup";
import { useFormik } from "formik";
import dollar from "src/icons/dollar.svg";
import "./DealAnalysisCard.css";
export default function DealAnalysisCard(props) {
  const [isDisable, setIsDisabled] = useState(false);
  const [discussed, setDiscussed] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [IsUpdating, setIsUpdating] = useState(false);
  const [updateEdit, setUpdateEdit] = useState(false);
  const [isLoading, setLoading] = useState(true);
  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 500);
  // }, []);
  // if (isLoading) {
  //   return <IonText>Please wait...</IonText>;
  // }

  useEffect(() => {
    formik.resetForm();
    return () => {
      formik.resetForm();
    };
  }, [props.clientName]);

  useEffect(() => {
    setDiscussed(false);
    setIsDisabled(false);
    setIsEditable(false);
    if (props.isDiscussed === true) {
      setDiscussed(true);
      setIsDisabled(true);
      setIsEditable(true);
    }
  }, [props.clientName]);
  const formik = useFormik({
    initialValues: {
      isDiscussed: props.isDiscussed === true ? "yes" : "off",
      isInterested: props.isInterested === true ? "yes" : "no",
      potentialInvestment:
        props.potentialInvestment.toString().length > 0
          ? `${props.potentialInvestment}`
          : "0",
      reason:
        props.reason.length > 0 ? props.reason : "No liquidity at the moment",
      comment: props.comment.length > 0 ? props.comment : "",
    },
    validationSchema: Yup.object().shape({
      isDiscussed: Yup.string().required("Required"),
      isInterested: Yup.string().required("Required"),
      potentialInvestment: Yup.string().required("Required"),
      comment: Yup.string(),
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      values.isInterested == "yes"
        ? (values.reason = "")
        : (values.potentialInvestment = "");
      props.submit({
        ...values,
        hubSpotOwnerId: props.hubSpotOwnerId,
        prospectId: props.id,
        method: !IsUpdating ? "submit" : "update",
        meetingNoteId: props.meetingNoteId,
        dealId: props.cardId,
      });
      // setIsDisabled(true);
      formik.resetForm();
    },
  });
  const handleEdit = () => {
    setIsDisabled(false);
    setIsEditable(false);
    setIsUpdating(true);
  };
  const styles = {
    analysisCard: {
      margin: 0,
      marginBottom: 16,
      paddingTop: 24,
      paddingBottom: 24,
      paddingLeft: 16,
      paddingRight: 16,
      backgroundColor: "#1A1A1A",
      borderRadius: 8,
    },
    heroText: {
      fontWeight: 300,
      fontSize: 24,
      lineHeight: "120%",
      color: "#C7C7C7",
    },
    subTitle: {
      padding: 0,
      color: "#FFFFFF",
      fontWeight: 700,
      fontSize: 14,
      lineHeight: "120%",
    },
    clientsDetails: {
      marginLeft: 8,
      fontSize: 12,
      color: "#FFFFFF",
    },
    formContainer: {
      marginTop: 20,
      marginBottom: 0,
      boxShadow: "none",
      backgroundColor: "#1A1A1A",
      marginLeft: 0,
      marginRight: 0,
    },
    dealsName: {
      padding: 10,
      border: "1px solid #222222",
      borderRadius: 2,
      marginRight: 25,
      color: "#ffffff",
    },
    radioCss: {
      color: "#C7C7C7",
      fontSize: 12,
      display: "flex",
      marginRight: 25,
      alignItems: "center",
      marginLeft: 10,
    },
    subBtn: {
      "--background": "transparent",
      border: "2px solid #B99855",
      color: "#B99855",
      margin: 0,
      marginTop: 3,
      width: 134,
      textTransform: "capitalize",
    },
    inputDesign: {
      marginLeft: 12,
      "--inner-border-width": 0,
      "--min-height": 0,
      backgroundColor: "#222222",
      borderRadius: 2,
      "--padding-start": 0,
      "--inner-padding-end": 0,
      padding: 0,
      display: "flex",
    },
    interested: {
      marginRight: 25,
      paddingLeft: 8,
      paddingRight: 36,
      border: 0,
      backgroundColor: "#222222",
      color: "#ffffff",
      backgroundImage: `url(${chevron})`,
      backgroundRepeat: "no-repeat",
      backgroundPositionX: "95%",
      backgroundPositionY: "50%",
    },
    potentialInput: {
      background: "none",
      padding: 10,
      border: "0px",
    },
    reason: {
      width: "auto",
      border: 0,
      background: "none",
    },
    selectItem: {
      "--padding-start": "0px",
      backgroundColor: "#222222",
      "--min-height": 0,
    },
  };
  const ReasonsList = [
    "No liquidity at the moment",
    "Not income generating",
    "Low yield for such an investment",
    "Not a Growth deal",
    "Low return for a growth deal",
    "Not shariah compliant",
    "Too long of a tenor",
  ];
  return (
    <IonCard style={{ ...styles.analysisCard, opacity: isDisable ? 0.6 : 1 }}>
      <IonRow class="ion-justify-content-between">
        <IonCol style={styles.subTitle}>{props.clientName}</IonCol>
        <IonRow>
          <IonRow class="ion-align-self-center" style={{ marginLeft: 24 }}>
            <IonIcon icon={smallWallet}></IonIcon>
            <IonText style={styles.clientsDetails}>
              {props.amount}/{props.percentage}
            </IonText>
          </IonRow>
          <IonRow class="ion-align-self-center" style={{ marginLeft: 24 }}>
            <IonIcon icon={email}></IonIcon>
            <IonText style={styles.clientsDetails}>{props.email}</IonText>
          </IonRow>
          <IonRow class="ion-align-self-center" style={{ marginLeft: 24 }}>
            <IonIcon icon={royal}></IonIcon>
            <IonText style={styles.clientsDetails}>{props.segment}</IonText>
          </IonRow>
          <IonRow class="ion-align-self-center" style={{ marginLeft: 24 }}>
            <IonIcon icon={phone}></IonIcon>
            <IonText style={styles.clientsDetails}>+{props.number}</IonText>
          </IonRow>
        </IonRow>
      </IonRow>
      <IonCard style={styles.formContainer}>
        <form onSubmit={formik.handleSubmit}>
          <IonRow class="ion-justify-content-between">
            <IonCard
              style={{
                boxShadow: "none",
                backgroundColor: "#1A1A1A",
                margin: 0,
                marginTop: 3,
              }}
            >
              <IonRow>
                <IonRow style={styles.dealsName} class="ion-align-items-center">
                  {props.dealName}
                </IonRow>
                ​
                <IonRadioGroup
                  name="isDiscussed"
                  value={formik.values.isDiscussed}
                  style={{ display: "flex" }}
                  class="ion-align-items-center"
                  // onIonChange={formik.handleChange}
                  onIonChange={(val) => {
                    formik.setFieldValue(
                      "isDiscussed",
                      val.detail.value || "no"
                    );
                    setDiscussed(!discussed);
                  }}
                  allowEmptySelection={true}
                >
                  <IonRadio
                    class="radioIcon"
                    disabled={isDisable}
                    slot="start"
                    mode="md"
                    value={"yes"}
                  />
                  <label style={styles.radioCss}>Discussed</label>
                </IonRadioGroup>
                ​
                <select
                  disabled={!discussed || isDisable}
                  style={{
                    ...styles.interested,
                    appearance: "none",
                    MozAppearance: "none",
                    WebkitAppearance: "none",
                  }}
                  onChange={formik.handleChange}
                  name="isInterested"
                  value={formik.values.isInterested}
                >
                  <option value="no">Not, Interested</option>
                  <option value="yes">Yes, Interested</option>
                </select>
                ​
                {formik.values.isInterested == "yes" ? (
                  <>
                    <IonLabel
                      style={{
                        color: "#C7C7C7",
                        marginRight: 2,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Potential investment:
                    </IonLabel>
                    ​
                    <IonItem lines="none" style={styles.selectItem}>
                      <IonIcon
                        style={{ height: 14, paddingLeft: "10px" }}
                        color="gold"
                        icon={dollar}
                      />
                      <input
                        name="potentialInvestment"
                        disabled={!discussed || isDisable}
                        onFocus={() => {
                          formik.setFieldValue("potentialInvestment", "");
                        }}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        style={styles.potentialInput}
                        value={formik.values.potentialInvestment}
                        placeholder="Enter the amount"
                        type="number"
                        min={1}
                      />
                    </IonItem>
                  </>
                ) : (
                  <>
                    <IonLabel
                      style={{
                        color: "#C7C7C7",
                        marginRight: 10,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Reason:
                    </IonLabel>
                    <select
                      disabled={!discussed || isDisable}
                      style={{
                        ...styles.interested,
                        marginRight: 5,
                        appearance: "none",
                        MozAppearance: "none",
                        WebkitAppearance: "none",
                      }}
                      onChange={formik.handleChange}
                      value={formik.values.reason}
                      name="reason"
                    >
                      {ReasonsList.map((item, i) => (
                        <option key={i} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </>
                )}
              </IonRow>
              <IonRow>
                <div
                  style={{ marginTop: 20, display: "flex", width: "100%" }}
                  className="ion-align-items-center"
                >
                  <IonLabel
                    style={{
                      color: "#C7C7C7",
                      marginRight: 2,
                      alignItems: "center",
                      flex: "0 0 auto",
                    }}
                  >
                    Feedback comment:
                  </IonLabel>
                  <IonItem style={{ ...styles.inputDesign, width: "100%" }}>
                    <textarea
                      onChange={formik.handleChange}
                      disabled={!discussed || isDisable}
                      value={formik.values.comment}
                      name="comment"
                      style={{
                        ...styles.potentialInput,
                        height: 35,
                        width: "100%",
                      }}
                      placeholder="Enter the feedback"
                      maxLength={500}
                    />
                  </IonItem>
                </div>
              </IonRow>
            </IonCard>
            <IonCard
              style={{
                boxShadow: "none",
                backgroundColor: "#1A1A1A",
                margin: 0,
              }}
            >
              {isEditable ? (
                <IonButton
                  type="button"
                  style={{ ...styles.subBtn, cursor: "pointer" }}
                  disabled={false}
                  onClick={handleEdit}
                >
                  Edit
                </IonButton>
              ) : (
                <IonButton
                  type="submit"
                  disabled={!discussed || isDisable || !formik.isValid}
                  style={styles.subBtn}
                >
                  Submit
                </IonButton>
              )}
            </IonCard>
          </IonRow>
        </form>
      </IonCard>
    </IonCard>
  );
}
