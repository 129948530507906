import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CryptoJS from "crypto-js";
import {
  IonAvatar,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonLoading,
  IonPage,
  IonRow,
  IonText,
  IonToolbar,
} from "@ionic/react";
import "./Dashboard.css";
import Segments from "src/components/Segments/Segments";
import Clients from "./Clients/Clients";
import Prospects from "./Prospects/Prospects";
import General from "./General/General";
import Deals from "./Deals/Deals";
import { search } from "ionicons/icons";
import { Plugins } from "@capacitor/core";
import ProfPopover from "src/components/ProfileModal/ProfPopover";
import useWindowDimensions from "src/components/useWindowDimensions";

const { Storage } = Plugins;

/* const styles = {
  segbtn: {
    fontFamily: "Gotham Book",
    "--padding-end": "0px",
    "--padding-start": "0px",
  }
} */

const segs = [
  {
    label: "General",
    value: "general",
  },
  {
    label: "Prospects",
    value: "prospects",
  },
  {
    label: "Clients",
    value: "clients",
  },
  {
    label: "Deal Analysis",
    value: "deals",
  },
];

const retComp = (segment: string) => {
  switch (segment) {
    case "general":
      return <Prospects type="general" />;
    case "prospects":
      return <Prospects type="prospects" />;
    case "clients":
      return <Prospects type="clients" />;
    case "deals":
      return <Deals type="deals" />;
    default:
      return <General />;
  }
};
interface ProfPopup {
  open: boolean;
  event: Event | undefined;
}

const Tab1: React.FC<any> = ({ location }) => {
  const { height, width } = useWindowDimensions();

  const [ProfPopovermenu, setProfPopovermenu] = useState<ProfPopup>({
    open: false,
    event: undefined,
  });
  const history = useHistory();
  const triggerSearch = () => {
    history.push("/prospects/search");
  };

  const [isLoading, setLoading] = React.useState(false);
  const [selSeg, setselSeg] = React.useState<string>("prospects");

  const selectedVal = (value) => {
    setselSeg(value);
  };

  function iOS() {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }
  const decrypt = (decryptVlaue) => {
    let passphrase =
      "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9";
    const bytes = CryptoJS.AES.decrypt(decryptVlaue, passphrase);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  };
  const getUserId = async () => {
    const ret: any = await Storage.get({ key: "userId" });
    const temp = JSON.parse(decrypt(ret.value));
    return temp && temp.id ? temp.id : null;
  };

  // useEffect(() => {
  //   getUserId().then((userId) => {
  //     if (!(userId && userId !== null && userId !== "")) {
  //       history.push("/login");
  //     }
  //   });
  // }, [history]);

  useEffect(() => {
    if (location.state) {
      let a: any = location.state;
      if (a.to) {
        setselSeg(a.to);
      }
    }
  }, [location.state]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          {iOS() === true ? (
            <IonGrid className="rootgrid margintopn10">
              <IonRow>
                <IonCol>
                  <IonImg
                    className="ion-text-start"
                    src="/assets/brand_logo.svg"
                    style={{ width: 75 }}
                  />
                </IonCol>
                <IonCol>
                  <IonGrid className="ion-float-right">
                    <IonRow>
                      <IonIcon
                        color="white"
                        icon={search}
                        size="small"
                        onClick={triggerSearch}
                        className="trig-srch"
                      ></IonIcon>
                      <IonText
                        className="srchplaceholder ion-margin-horizontal trig-srch"
                        onClick={triggerSearch}
                      >
                        {width > 575
                          ? "Search for any prospect or client"
                          : "Search"}
                      </IonText>
                      <IonAvatar
                        onClick={(e) => {
                          setProfPopovermenu({
                            open: true,
                            event: e.nativeEvent,
                          });
                        }}
                        style={{ width: 24, height: 24 }}
                      >
                        <img src="/assets/empty-avatar.svg" alt="" />
                      </IonAvatar>
                    </IonRow>
                  </IonGrid>
                </IonCol>
              </IonRow>
            </IonGrid>
          ) : (
            <IonGrid className="rootgrid">
              <IonRow>
                <IonCol>
                  <IonImg
                    className="ion-text-start"
                    src="/assets/brand_logo.svg"
                    style={{ width: 75 }}
                  />
                </IonCol>
                <IonCol>
                  <IonGrid className="searchCss ion-float-right">
                    <IonRow>
                      <IonIcon
                        color="white"
                        icon={search}
                        size="small"
                        onClick={triggerSearch}
                        className="trig-srch"
                      ></IonIcon>
                      <IonText
                        className="srchplaceholder ion-margin-horizontal trig-srch"
                        onClick={triggerSearch}
                      >
                        {width > 575
                          ? "Search for any prospect or client"
                          : "Search"}
                      </IonText>

                      <IonAvatar
                        onClick={(e) => {
                          setProfPopovermenu({
                            open: true,
                            event: e.nativeEvent,
                          });
                        }}
                        style={{ width: 24, height: 24 }}
                      >
                        <img src="/assets/empty-avatar.svg" alt="" />
                      </IonAvatar>
                    </IonRow>
                  </IonGrid>
                </IonCol>
              </IonRow>
            </IonGrid>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent style={{ "--background": "none" }}>
        {/* <IonHeader collapse="condense">
        <IonToolbar>
          <IonTitle size="large">Dashboard</IonTitle>
        </IonToolbar>
      </IonHeader> */}
        <IonLoading
          cssClass="my-custom-loading-class"
          isOpen={isLoading}
          onDidDismiss={() => setLoading(false)}
          message={"Please wait..."}
        />
        <IonRow
          class="mt-6 margin-set mobile-top-content m-marginleft-12"
          style={{ marginTop: 24, marginLeft: 32 }}
        >
          <span
            className="to-do"
            style={{
              fontWeight: 300,
              fontSize: 28,
              color: "GrayText",
              whiteSpace: "nowrap",
            }}
          >
            Dashboards
          </span>
        </IonRow>
        <Segments
          buttons={segs}
          startbtn={selSeg}
          selectedValue={selectedVal}
          viewAllProsp={selSeg === "prospects" ? true : false}
          viewAllClient={selSeg === "clients" ? true : false}
        />
        <IonRow
          class="mt-1 margin-set"
          style={{ marginLeft: 32, marginTop: 24 }}
        >
          <IonText color="greyscale">
            *Dashboard data is synced with the server every 15 minutes
          </IonText>
        </IonRow>
        {retComp(selSeg)}

        <ProfPopover
          open={ProfPopovermenu.open}
          event={ProfPopovermenu.event}
          setShowPopover={() =>
            setProfPopovermenu({ open: false, event: undefined })
          }
        />
      </IonContent>
    </IonPage>
  );
};

export default Tab1;
