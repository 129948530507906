import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonBackButton,
  IonButtons,
  IonPage,
  IonText,
  IonRow,
  IonCol,
  IonIcon,
  IonButton,
  IonLoading,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonItem,
  IonChip,
  IonLabel,
} from "@ionic/react";
import edit from "src/icons/pen-edit.svg";
import EditNote from "src/pages/ContactInfo/EditMeetingNote/EditNote";
import { chevronBack } from "ionicons/icons";
import api from "src/api";
import { RouteComponentProps } from "react-router";
import moment from "moment";
import ShowMoreText from "react-show-more-text";
import { Plugins } from "@capacitor/core";
import "./MeetingNoteDetails.css";
// const { Storage } = Plugins;
// import * as Yup from "yup";

const styles = {
  toolbar: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
  },
  headerRow: {
    marginTop: 24,
    marginLeft: 32,
  },
  arbHeaderRow: {
    marginTop: 8,
    marginLeft: 32,
  },
  engName: {
    fontWeight: 300,
    fontSize: 28,
    lineHeight: "120%",
    color: "#828282",
  },
  arbName: {
    fontWeight: 400,
    fontSize: 18,
    lineHeight: "120%",
    color: "#828282",
    fontFamily: "Almarai",
  },
  formHeader: {
    fontWeight: 400,
    fontSize: 22,
    lineHeight: "120%",
    color: "#AAAAAA",
  },

  fieldLabel: {
    fontWeight: 400,
    fontSize: 17,
    color: "#FFFFFF",
  },
  showmore: {
    fontWeight: 400,
    fontSize: 14,
    color: "#B99855",
  },
};

interface MeetingDetailPageProps
  extends RouteComponentProps<{
    contactId: string;
    meetingnoteId: string;
  }> {}

const optionsA = {
  cssClass: "my-custom-interface",
};

const MeetingNoteDetails: React.FC<MeetingDetailPageProps> = ({ location, match, history }) => {
  const [contact, setContact] = React.useState<any>();
  const [noteDetails, setNoteDetails] = React.useState<any>();
  const [taskDetails, setTaskDetails] = React.useState<any>();
  const [showMoreContent, setShowMoreContent] = React.useState<any>(false);
  const toggleShow = () => {
    setShowMoreContent(!showMoreContent);
  };
  const [showEdit, setShowEdit] = React.useState<any>(false);
  const toggleEdit = () => {
    setShowEdit(true);
  };
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const noteParam = history.location;
  const editPath = match.path;
  const [hubOwners, setOwners] = useState<any>();
  let noteData: any = noteParam.state;
  const contId = noteData?.contId;
  const noteId = noteData?.noteId;

  useEffect(() => {
    api
      .getContactDetails(contId)
      .then((data) => {
        setContact(data);
      })
      .catch((e) => {
        console.log(JSON.stringify(e));
        setLoading(false);
      });

    api
      .GetMeetingNoteById(noteId)
      .then((data) => {
        //console.log(data);
        api
          .getOwners()
          .then((resp) => {
            //console.log(data.attendees);
            let rmlist: any = [];
            resp.results.map((o) => {
              if (data.attendees.includes(o.id)) {
                if (o.firstName == "" || o.lastName == "") {
                  rmlist.push(o.email);
                } else {
                  rmlist.push(o.firstName + " " + o.lastName);
                }
                // if(rmlist)
                // {
                //   rmlist = rmlist +" , "+ o.firstName + o.lastName;
                // }
                // else
                // {
                //   rmlist.push(o.firstName +" "+ o.lastName)
                // }
              }
            });
            setOwners(rmlist);
            setNoteDetails(data);
            if (data.followupTaskId) {
              api
                .GetEngagement(data.followupTaskId)
                .then((taskdata) => {
                  setTaskDetails(taskdata);
                  //console.log(taskdata);
                })
                .catch((e) => {
                  console.log(JSON.stringify(e));
                  setLoading(false);
                });
            }
          })
          .catch((e) => {
            console.log(JSON.stringify(e));
            setLoading(false);
          });
      })
      .catch((e) => {
        console.log(JSON.stringify(e));
        setLoading(false);
      });
  }, [contId, noteId]);

  useEffect(() => {
    if (editPath.includes("meetingnotedetailsedit") && contact && noteDetails && taskDetails) {
      setShowEdit(true);
    }
  }, [contact, noteDetails, taskDetails, editPath]);

  function iOS() {
    return (
      ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(navigator.platform) ||
      navigator.userAgent.includes("Mac")
    );
  }

  function newdateDisplay(oldtime) {
    let newtime = oldtime;
    // if(iOS())
    // {
    //   newtime = oldtime + (new Date(moment(oldtime).format()).getTimezoneOffset())*60*1000;
    // }
    return newtime;
  }

  return (
    <IonPage>
      <IonLoading cssClass="my-custom-loading-class" isOpen={isLoading} message={"Please wait..."} />
      <IonHeader>
        <IonToolbar mode="md" style={styles.toolbar}>
          <IonButtons slot="start">
            <IonBackButton
              style={{ textTransform: "none" }}
              color="white"
              text={
                contact && contact?.properties?.hs_lifecyclestage_customer_date ? "Client Profile" : "Prospect Profile"
              }
              icon={chevronBack}
            ></IonBackButton>
          </IonButtons>
          {!showEdit ? (
            <IonButtons slot="end">
              <IonButton mode="ios" color="gold" fill="clear" slot="end" onClick={toggleEdit}>
                <IonIcon color="white" size="small" icon={edit} slot="start"></IonIcon>
                Edit
              </IonButton>
            </IonButtons>
          ) : null}
        </IonToolbar>
      </IonHeader>
      {!showEdit ? (
        <IonContent className="mnote-det-content">
          <IonRow style={styles.headerRow}>
            <IonCol>
              <IonText class="fs-28" style={styles.engName}>
                {contact
                  ? contact?.properties?.title_salutation
                    ? contact.properties.title_salutation +
                      " " +
                      contact.properties.firstname +
                      " " +
                      contact.properties.lastname
                    : contact.properties.firstname + " " + contact.properties.lastname
                  : ""}
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow style={styles.arbHeaderRow}>
            <IonCol>
              <IonText class="fs-18" style={styles.arbName}>
                {contact
                  ? contact.properties.first_name_arabic +
                    " " +
                    contact.properties.arabic_middle_name +
                    " " +
                    contact.properties.arabic_last_name
                  : ""}
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow style={styles.headerRow}>
            <IonCol>
              <IonText class="fs-22" style={styles.formHeader}>
                Meeting Information
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow style={styles.headerRow}>
            <IonCol sizeXs="12" sizeMd="4" sizeLg="4" sizeXl="4">
              <IonCard
                className="firstLeft firstLeftDesk firstLeft-mobile"
                style={{ position: "relative", height: 90 }}
              >
                <IonCardHeader
                  style={{
                    color: "#fff",
                    fontWeight: "400",
                    fontSize: 18,
                    paddingInline: 0,
                  }}
                  className="m-card-header-ml-20"
                >
                  Date & Time
                </IonCardHeader>
                <IonCardSubtitle
                  className="exp_sub"
                  color="#828282"
                  style={{
                    textTransform: "none",
                    fontWeight: "400",
                    fontSize: 14,
                  }}
                >
                  {noteDetails
                    ? moment(newdateDisplay(noteDetails.meetingDateTime)).format("ddd, MMM DD, YYYY (hh:mm a)")
                    : ""}
                </IonCardSubtitle>
              </IonCard>
            </IonCol>
            <IonCol sizeXs="12" sizeMd="4" sizeLg="4" sizeXl="4">
              <IonCard
                className="firstLeft firstLeftDesk firstLeft-mobile"
                style={{ position: "relative", height: 90 }}
              >
                <IonCardHeader
                  style={{
                    color: "#fff",
                    fontWeight: "400",
                    fontSize: 18,
                    paddingInline: 0,
                  }}
                  className="m-card-header-ml-20"
                >
                  Prospect/Client Type
                </IonCardHeader>
                <IonCardSubtitle
                  className="exp_sub"
                  color="#828282"
                  style={{
                    textTransform: "none",
                    fontWeight: "400",
                    fontSize: 14,
                  }}
                >
                  {noteDetails ? noteDetails.clientType.substring(1).replace("Other;", "") : ""}
                </IonCardSubtitle>
              </IonCard>
            </IonCol>
            <IonCol sizeXs="12" sizeMd="4" sizeLg="4" sizeXl="4">
              <IonCard
                className="firstLeftNr firstLeftDesk firstLeft-mobile"
                style={{ position: "relative", height: 90 }}
              >
                <IonCardHeader
                  style={{
                    color: "#fff",
                    fontWeight: "400",
                    fontSize: 18,
                    paddingInline: 0,
                  }}
                  className="m-card-header-ml-20 card-header-ml-20"
                >
                  Meeting Topic
                </IonCardHeader>
                <IonCardSubtitle
                  className="exp_sub"
                  color="#828282"
                  style={{
                    textTransform: "none",
                    fontWeight: "400",
                    fontSize: 14,
                  }}
                >
                  {noteDetails ? noteDetails.meetingTopic : ""}
                </IonCardSubtitle>
              </IonCard>
            </IonCol>
          </IonRow>
          <IonRow style={styles.headerRow}>
            <span style={styles.fieldLabel}>Meeting Notes</span>
          </IonRow>

          <IonRow style={styles.headerRow}>
            <ShowMoreText
              /* Default options */
              lines={1}
              more="Read more..."
              less="Read less"
              className="content-css showmore"
              anchorClass="my-anchor-css-class primarygold"
              expanded={false}
            >
              {console.log("noteDetails.notes", noteDetails)}
              {noteDetails ? noteDetails.notes.replace(/(<([^>]+)>)/gi, "") : ""}
            </ShowMoreText>
          </IonRow>

          <IonRow style={styles.headerRow}>Attendees List</IonRow>
          <IonRow style={styles.headerRow}>
            {/* <IonItem></IonItem> */}
            {hubOwners
              ? hubOwners.map((o, i) => (
                  <IonChip key={i}>
                    <IonLabel color="gold">{o} </IonLabel>
                  </IonChip>
                ))
              : null}
          </IonRow>
          <IonRow style={styles.headerRow}>
            <IonText style={styles.showmore} onClick={toggleShow}>
              {showMoreContent ? "Show less" : "Show more"}
            </IonText>
          </IonRow>
          {showMoreContent ? (
            <React.Fragment>
              <IonRow style={styles.headerRow}>
                <IonCol>
                  <IonText class="fs-22" style={styles.formHeader}>
                    Sentiments from Last meeting
                  </IonText>
                </IonCol>
              </IonRow>

              <IonRow style={styles.headerRow}>
                <IonCol sizeXs="12" sizeMd="4" sizeLg="4" sizeXl="4">
                  <IonCard
                    className="firstLeft firstLeftDesk firstLeft-mobile"
                    style={{ position: "relative", height: 90 }}
                  >
                    <IonCardHeader
                      style={{
                        color: "#fff",
                        fontWeight: "400",
                        fontSize: 18,
                        paddingInline: 0,
                      }}
                      className="m-card-header-ml-20 card-header-ml-20"
                    >
                      General View on Company
                    </IonCardHeader>
                    <IonCardSubtitle
                      className="exp_sub"
                      color="#828282"
                      style={{
                        textTransform: "none",
                        fontWeight: "400",
                        fontSize: 14,
                      }}
                    >
                      {noteDetails ? noteDetails.gvc : ""}
                    </IonCardSubtitle>
                  </IonCard>
                </IonCol>
                <IonCol sizeXs="12" sizeMd="4" sizeLg="4" sizeXl="4">
                  <IonCard
                    className="firstLeft firstLeftDesk firstLeft-mobile"
                    style={{ position: "relative", height: 90 }}
                  >
                    <IonCardHeader
                      style={{
                        color: "#fff",
                        fontWeight: "400",
                        fontSize: 18,
                        paddingInline: 0,
                      }}
                      className="m-card-header-ml-20 card-header-ml-20"
                    >
                      Investment Objectives
                    </IonCardHeader>
                    <IonCardSubtitle
                      className="exp_sub"
                      color="#828282"
                      style={{
                        textTransform: "none",
                        fontWeight: "400",
                        fontSize: 14,
                      }}
                    >
                      {noteDetails ? noteDetails.invObjective : ""}
                    </IonCardSubtitle>
                  </IonCard>
                </IonCol>
                <IonCol sizeXs="12" sizeMd="4" sizeLg="4" sizeXl="4">
                  <IonCard
                    className="firstLeftNr firstLeftDesk firstLeft-mobile"
                    style={{ position: "relative", height: 90 }}
                  >
                    <IonCardHeader
                      style={{
                        color: "#fff",
                        fontWeight: "400",
                        fontSize: 18,
                        paddingInline: 0,
                      }}
                      className="m-card-header-ml-20 card-header-ml-20"
                    >
                      Investment Liquidity
                    </IonCardHeader>
                    <IonCardSubtitle
                      className="exp_sub"
                      color="#828282"
                      style={{
                        textTransform: "none",
                        fontWeight: "400",
                        fontSize: 14,
                      }}
                    >
                      {noteDetails ? noteDetails.invLiquidity : ""}
                    </IonCardSubtitle>
                  </IonCard>
                </IonCol>
              </IonRow>
              <IonRow style={styles.headerRow}>
                <IonCol sizeXs="12" sizeMd="4" sizeLg="4" sizeXl="4">
                  <IonCard
                    className="firstLeft firstLeftDesk firstLeft-mobile"
                    style={{ position: "relative", height: 90 }}
                  >
                    <IonCardHeader
                      style={{
                        color: "#fff",
                        fontWeight: "400",
                        fontSize: 18,
                        paddingInline: 0,
                      }}
                      className="m-card-header-ml-20 card-header-ml-20"
                    >
                      Optimism on local economy
                    </IonCardHeader>
                    <IonCardSubtitle
                      className="exp_sub"
                      color="#828282"
                      style={{
                        textTransform: "none",
                        fontWeight: "400",
                        fontSize: 14,
                      }}
                    >
                      {noteDetails ? noteDetails.optLocalEconomy : ""}
                    </IonCardSubtitle>
                  </IonCard>
                </IonCol>
                <IonCol sizeXs="12" sizeMd="4" sizeLg="4" sizeXl="4">
                  <IonCard
                    className="firstLeft firstLeftDesk firstLeft-mobile"
                    style={{ position: "relative", height: 90 }}
                  >
                    <IonCardHeader
                      style={{
                        color: "#fff",
                        fontWeight: "400",
                        fontSize: 18,
                        paddingInline: 0,
                      }}
                      className="m-card-header-ml-20 card-header-ml-20"
                    >
                      Optimism on intl. Market
                    </IonCardHeader>
                    <IonCardSubtitle
                      className="exp_sub"
                      color="#828282"
                      style={{
                        textTransform: "none",
                        fontWeight: "400",
                        fontSize: 14,
                      }}
                    >
                      {noteDetails ? noteDetails.optIntlEconomy : ""}
                    </IonCardSubtitle>
                  </IonCard>
                </IonCol>
                <IonCol sizeXs="12" sizeMd="4" sizeLg="4" sizeXl="4">
                  <IonCard
                    className="firstLeftNr firstLeftDesk firstLeft-mobile"
                    style={{ position: "relative", height: 90 }}
                  >
                    <IonCardHeader
                      style={{
                        color: "#fff",
                        fontWeight: "400",
                        fontSize: 18,
                        paddingInline: 0,
                      }}
                      className="m-card-header-ml-20 card-header-ml-20"
                    >
                      Optimism on core business
                    </IonCardHeader>
                    <IonCardSubtitle
                      className="exp_sub"
                      color="#828282"
                      style={{
                        textTransform: "none",
                        fontWeight: "400",
                        fontSize: 14,
                      }}
                    >
                      {noteDetails ? noteDetails.optCorebusiness : ""}
                    </IonCardSubtitle>
                  </IonCard>
                </IonCol>
              </IonRow>
              <IonRow style={styles.headerRow}>
                <IonCol sizeXs="12" sizeMd="4" sizeLg="4" sizeXl="4">
                  <IonCard
                    className="firstLeft firstLeftDesk firstLeft-mobile"
                    style={{ position: "relative", height: 90 }}
                  >
                    <IonCardHeader
                      style={{
                        color: "#fff",
                        fontWeight: "400",
                        fontSize: 18,
                        paddingInline: 0,
                      }}
                      className="m-card-header-ml-20 card-header-ml-20"
                    >
                      Appetite for intl. exposure
                    </IonCardHeader>
                    <IonCardSubtitle
                      className="exp_sub"
                      color="#828282"
                      style={{
                        textTransform: "none",
                        fontWeight: "400",
                        fontSize: 14,
                      }}
                    >
                      {noteDetails ? noteDetails.apetiteIntlExposure : ""}
                    </IonCardSubtitle>
                  </IonCard>
                </IonCol>
                <IonCol></IonCol>
                <IonCol></IonCol>
              </IonRow>
              <IonRow style={styles.headerRow}>
                <IonCol>
                  <IonText class="fs-22" style={styles.formHeader}>
                    Financial Information
                  </IonText>
                </IonCol>
              </IonRow>
              <IonRow style={styles.headerRow}>
                <IonCol sizeXs="12" sizeMd="4" sizeLg="4" sizeXl="4">
                  <IonCard
                    className="firstLeft firstLeftDesk firstLeft-mobile"
                    style={{ position: "relative", height: 90 }}
                  >
                    <IonCardHeader
                      style={{
                        color: "#fff",
                        fontWeight: "400",
                        fontSize: 18,
                        paddingInline: 0,
                      }}
                      className="m-card-header-ml-20 card-header-ml-20"
                    >
                      Expected Closing Probability
                    </IonCardHeader>
                    <IonCardSubtitle
                      className="exp_sub"
                      color="#828282"
                      style={{
                        textTransform: "none",
                        fontWeight: "400",
                        fontSize: 14,
                      }}
                    >
                      {contact ? contact.properties.expected_closing_probability : ""}
                    </IonCardSubtitle>
                  </IonCard>
                </IonCol>
                <IonCol sizeXs="12" sizeMd="4" sizeLg="4" sizeXl="4">
                  <IonCard
                    className="firstLeft firstLeftDesk firstLeft-mobile"
                    style={{ position: "relative", height: 90 }}
                  >
                    <IonCardHeader
                      style={{
                        color: "#fff",
                        fontWeight: "400",
                        fontSize: 18,
                        paddingInline: 0,
                      }}
                      className="m-card-header-ml-20 card-header-ml-20"
                    >
                      Expected Month of Closing
                    </IonCardHeader>
                    <IonCardSubtitle
                      className="exp_sub"
                      color="#828282"
                      style={{
                        textTransform: "none",
                        fontWeight: "400",
                        fontSize: 14,
                      }}
                    >
                      {contact && contact.properties.expected_month_of_closing
                        ? moment(contact.properties.expected_month_of_closing).format("MMMM YYYY")
                        : ""}
                    </IonCardSubtitle>
                  </IonCard>
                </IonCol>
                <IonCol sizeXs="12" sizeMd="4" sizeLg="4" sizeXl="4">
                  <IonCard
                    className="firstLeftNr firstLeftDesk firstLeft-mobile"
                    style={{ position: "relative", height: 90 }}
                  >
                    <IonCardHeader
                      style={{
                        color: "#fff",
                        fontWeight: "400",
                        fontSize: 18,
                        paddingInline: 0,
                      }}
                      className="m-card-header-ml-20 card-header-ml-20"
                    >
                      Expected Closing Amount
                    </IonCardHeader>
                    <IonCardSubtitle
                      className="exp_sub"
                      color="#828282"
                      style={{
                        textTransform: "none",
                        fontWeight: "400",
                        fontSize: 14,
                      }}
                    >
                      {contact ? contact.properties.amount_status : ""}
                    </IonCardSubtitle>
                  </IonCard>
                </IonCol>
              </IonRow>
              <IonRow style={styles.headerRow}>
                <IonCol>
                  <IonText class="fs-22" style={styles.formHeader}>
                    Liquidity Events
                  </IonText>
                </IonCol>
              </IonRow>
              <IonRow style={styles.headerRow}>
                <IonCol style={{ marginRight: 20 }}>
                  <IonText
                    style={{
                      color: "#828282",
                      textTransform: "none",
                      fontWeight: "400",
                      fontSize: 14,
                      fontStyle: "normal",
                      lineHeight: "200%",
                    }}
                  >
                    {noteDetails ? noteDetails.liquidityEvent : ""}
                  </IonText>
                </IonCol>
              </IonRow>
              <IonRow style={styles.headerRow}>
                <IonCol>
                  <IonText class="fs-22" style={styles.formHeader}>
                    Next Steps
                  </IonText>
                </IonCol>
              </IonRow>
              <IonRow style={styles.headerRow}>
                <IonCol sizeXs="12" sizeMd="4" sizeLg="4" sizeXl="4">
                  <IonCard
                    className="firstLeft firstLeftDesk firstLeft-mobile"
                    style={{ position: "relative", height: 90 }}
                  >
                    <IonCardHeader
                      style={{
                        color: "#fff",
                        fontWeight: "400",
                        fontSize: 18,
                        paddingInline: 0,
                      }}
                      className="m-card-header-ml-20 card-header-ml-20"
                    >
                      Task Title
                    </IonCardHeader>
                    <IonCardSubtitle
                      className="exp_sub"
                      color="#828282"
                      style={{
                        textTransform: "none",
                        fontWeight: "400",
                        fontSize: 14,
                      }}
                    >
                      {taskDetails ? taskDetails.metadata.subject : ""}
                    </IonCardSubtitle>
                  </IonCard>
                </IonCol>
                <IonCol sizeXs="12" sizeMd="4" sizeLg="4" sizeXl="4">
                  <IonCard
                    className="firstLeft firstLeftDesk firstLeft-mobile"
                    style={{ position: "relative", height: 90 }}
                  >
                    <IonCardHeader
                      style={{
                        color: "#fff",
                        fontWeight: "400",
                        fontSize: 18,
                        paddingInline: 0,
                      }}
                      className="m-card-header-ml-20 card-header-ml-20"
                    >
                      Due Date
                    </IonCardHeader>
                    <IonCardSubtitle
                      className="exp_sub"
                      color="#828282"
                      style={{
                        textTransform: "none",
                        fontWeight: "400",
                        fontSize: 14,
                      }}
                    >
                      {taskDetails
                        ? moment(taskDetails.engagement.timestamp).format("ddd, MMM DD, YYYY (hh:ss a)")
                        : ""}
                    </IonCardSubtitle>
                  </IonCard>
                </IonCol>
                <IonCol sizeXs="12" sizeMd="4" sizeLg="4" sizeXl="4">
                  <IonCard
                    className="firstLeftNr firstLeftDesk firstLeft-mobile"
                    style={{ position: "relative", height: 90 }}
                  >
                    <IonCardHeader
                      style={{
                        color: "#fff",
                        fontWeight: "400",
                        fontSize: 18,
                        paddingInline: 0,
                      }}
                      className="m-card-header-ml-20 card-header-ml-20"
                    >
                      Type
                    </IonCardHeader>
                    <IonCardSubtitle
                      className="exp_sub"
                      color="#828282"
                      style={{
                        textTransform: "none",
                        fontWeight: "400",
                        fontSize: 14,
                      }}
                    >
                      {taskDetails ? taskDetails.metadata.taskType : ""}
                    </IonCardSubtitle>
                  </IonCard>
                </IonCol>
              </IonRow>
              <IonRow style={styles.headerRow}>
                <IonCol sizeXs="12" sizeMd="4" sizeLg="4" sizeXl="4">
                  <IonCard
                    className="firstLeft firstLeftDesk firstLeft-mobile"
                    style={{ position: "relative", height: 90 }}
                  >
                    <IonCardHeader
                      style={{
                        color: "#fff",
                        fontWeight: "400",
                        fontSize: 18,
                        paddingInline: 0,
                      }}
                      className="m-card-header-ml-20 card-header-ml-20"
                    >
                      Priority
                    </IonCardHeader>
                    <IonCardSubtitle
                      className="exp_sub"
                      color="#828282"
                      style={{
                        textTransform: "none",
                        fontWeight: "400",
                        fontSize: 14,
                      }}
                    >
                      {taskDetails ? taskDetails.metadata.priority : ""}
                    </IonCardSubtitle>
                  </IonCard>
                </IonCol>
                <IonCol sizeXs="12" sizeMd="4" sizeLg="4" sizeXl="4">
                  <IonCard
                    className="firstLeftNr firstLeftDesk firstLeft-mobile"
                    style={{ position: "relative", height: 90 }}
                  >
                    <IonCardHeader
                      style={{
                        color: "#fff",
                        fontWeight: "400",
                        fontSize: 18,
                        paddingInline: 0,
                      }}
                      className="m-card-header-ml-20 card-header-ml-20"
                    >
                      Email Reminder
                    </IonCardHeader>
                    <IonCardSubtitle
                      className="exp_sub"
                      color="#828282"
                      style={{
                        textTransform: "none",
                        fontWeight: "400",
                        fontSize: 14,
                      }}
                    >
                      {taskDetails
                        ? moment(taskDetails.metadata.reminders[0]).format("ddd, MMM DD, YYYY (hh:ss a)")
                        : ""}
                    </IonCardSubtitle>
                  </IonCard>
                </IonCol>
                <IonCol></IonCol>
              </IonRow>
              <IonRow style={styles.headerRow}>
                <IonCol sizeXs="12" sizeMd="4" sizeLg="4" sizeXl="4">
                  <span
                    style={{
                      color: "#fff",
                      // fontWeight: "400",
                      fontSize: 18,
                      paddingInline: 0,
                    }}
                  >
                    Task Notes
                  </span>
                </IonCol>
              </IonRow>
              <IonRow style={styles.headerRow}>
                <IonCol style={{ marginRight: 20 }}>
                  <IonText
                    style={{
                      color: "#828282",
                      textTransform: "none",
                      fontWeight: "400",
                      fontSize: 14,
                      paddingBottom: 50,
                      fontStyle: "normal",
                      lineHeight: "200%",
                    }}
                  >
                    {taskDetails ? taskDetails.metadata.body : ""}
                  </IonText>
                </IonCol>
              </IonRow>
            </React.Fragment>
          ) : null}
        </IonContent>
      ) : (
        <EditNote contactDetail={contact} note={noteDetails} task={taskDetails} />
      )}
    </IonPage>
  );
};

export default MeetingNoteDetails;
