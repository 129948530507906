import {
  SQLiteObject,
  SQLiteDatabaseConfig,
  SQLite,
} from "@ionic-native/sqlite";
import CryptoJS from 'crypto-js';
import {
  createProspTable,
  dropProspTables,
  getProspectById,
  insertProspectDetails,
  truncateProspectTable,
  updateProspect,
  deleteProspectById,
  insertProspectList,
  getAllProspects,
  getAllClients,
  createProspectQry,
  contProps
} from "src/offlineUtils/prospQueries";
import {
  ProspectProperties,
  ProspectPropertiesDetailsSQL,
  prospectInsert,
  prospectUpdate,
  prospectDetailsAPI,
  ProspectPropertiesListSQL,
  CreateProspectAPI
} from "src/types/prospects";

import { RootObject as filterApiResponse, Result as filterApiArr } from "src/types/filterApiTypes"

import { Plugins, Network, NetworkStatus } from "@capacitor/core";
import api from "src/api";
import { CreateProspectResponse, UpdateProspectResponse } from "src/types/CreateProspTypes";


const { Device, Storage } = Plugins;

const DbConfig: SQLiteDatabaseConfig = {
  name: "data.db",
  location: "default",
};

const decrypt = (decryptVlaue) => {
  let passphrase = "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9"
  const bytes = CryptoJS.AES.decrypt(decryptVlaue, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};

const getUserId = async () => {
  const ret: any = await Storage.get({ key: "userId" });
  const temp = JSON.parse(decrypt(ret.value));
  return temp && temp.id ? temp.id : null;
};

const getTeams = async () => {
  const ret: any = await Storage.get({ key: "userId" });
  const temp = JSON.parse(decrypt(ret.value));
  return temp && temp.teams ? temp.teams : null;
};

export const prospTableCreate = async () => {
  const info = await Device.getInfo();
  //check for platform - if browser then return response from api else perform dboperations
  if (info.platform === "android" || info.platform === "ios") {
    return SQLite.create(DbConfig)
      .then((db: SQLiteObject) => {
        db.executeSql(dropProspTables, []).then(() => {
          SQLite.create(DbConfig).then((db: SQLiteObject) => {
            db.executeSql(createProspTable, []).then((val) => {
              // console.log("Table created: " + JSON.stringify(val));
            }).catch((e) => {console.log("Create PRospect Table Error:"+JSON.stringify(e))})
          })
        }).catch((e) => {console.log(e)})
       
      })
      .catch((e) => {console.log(e)});
  } else {
    return null
  }
};


export const insertProspectDetailsF = (Input: ProspectPropertiesDetailsSQL) => {
  return SQLite.create(DbConfig).then(async (db: SQLiteObject) => {
    const {
     Operation,
     address_line_1,
     address_line_2,
     age_range,
     amount_status,
     arabic_last_name,
     arabic_middle_name,
     bio_profile,
     building_details,
     city,
     company,
     contact_type,
     country,
     degree,
     dirtyFlag,
     email,
     expected_closing_probability,
     expected_month_of_closing,
     expected_wealth_range,
     financial_sophistication,
     first_name_arabic,
     firstname,
     gender,
     general_remarks,
     hs_lead_status,
     hubspot_owner_id,
     industry_,
     lastname,
    //  lifecycle_stage_status,
     lead_stage,
     lifecyclestage,
     middle_name,
     mobilephone,
     nationality_1_,
     phone,
     politically_exposed_persons_pep,
     position,
     preferred_channel,
     preferred_time_of_day,
     school,
     segment,
     sharia_compliant,
     source_of_name,
     source_of_wealth,
     technology_sophistication,
     title_salutation,
     id,
     notes_last_contacted,
     onboarding_id
    } = Input;
    const val = await db.executeSql(insertProspectDetails, [
      id,
      hubspot_owner_id,
      title_salutation,
      firstname,
      middle_name,
      lastname,
      first_name_arabic,
      arabic_last_name,
      arabic_middle_name,
      gender,
      age_range,
      email,
      phone,
      mobilephone,
      address_line_1,
      address_line_2,
      city,
      country,
      nationality_1_,
      company,
      position,
      industry_,
      source_of_wealth,
      expected_wealth_range,
      bio_profile,
      general_remarks,
      preferred_channel,
      school,
      degree,
      segment,
      contact_type,
      lifecyclestage,
      // lifecycle_stage_status,
      lead_stage,
      hs_lead_status,
      financial_sophistication,
      technology_sophistication,
      building_details,
      politically_exposed_persons_pep,
      sharia_compliant,
      expected_closing_probability,
      amount_status,
      expected_month_of_closing,
      source_of_name,
      preferred_time_of_day,
      dirtyFlag,
      Operation,
      notes_last_contacted,
      onboarding_id
    ]);
    // console.log("Meeting Data Inserted: " + JSON.stringify(val));
  });
};


export const insertProspectListF = (Input: ProspectPropertiesListSQL) => {
  return SQLite.create(DbConfig).then(async (db: SQLiteObject) => {
    const {
     Operation,
     amount_status,
     arabic_last_name,
     arabic_middle_name,
     dirtyFlag,
     expected_closing_probability,
     first_name_arabic,
     firstname,
     hubspot_owner_id,
     lastname,
     middle_name,
     segment,
     id,
     createdate,
     hs_avatar_filemanager_key,
     onboarding_id,
     hs_object_id,
     lastmodifieddate,
     notes_last_contacted
    } = Input;
    const val = await db.executeSql(insertProspectList, [
      id,
      amount_status,
      arabic_last_name,
      arabic_middle_name,
      createdate,
      expected_closing_probability,
      first_name_arabic,
      firstname,
      hs_avatar_filemanager_key,
      hs_object_id,
      hubspot_owner_id,
      lastmodifieddate,
      lastname,
      middle_name,
      notes_last_contacted,
      onboarding_id,
      segment,
      dirtyFlag,
      Operation
    ]);
    // console.log("Meeting Data Inserted: " + JSON.stringify(val));
  });
};


//inserting into tables for single rec api (details api)
const insertOperDetailFunc = async (apiResp: prospectDetailsAPI[], db: SQLiteObject, dirtyFlag: 0| 1,  Operation: "LOAD" | "INSERT") => {
  const userId = await getUserId();
  let prom: Array<Promise<any>> = [];
  apiResp.forEach(async (element: prospectDetailsAPI) => {
    
    const {
      address_line_1,
      address_line_2,
      age_range,
      amount_status,
      arabic_last_name,
      arabic_middle_name,
      bio_profile,
      building_details,
      city,
      company,
      contact_type,
      country,
      degree,
      email,
      expected_closing_probability,
      expected_month_of_closing,
      expected_wealth_range,
      financial_sophistication,
      first_name_arabic,
      firstname,
      gender,
      general_remarks,
      hs_lead_status,
      hubspot_owner_id,
      industry_,
      lastname,
      // lifecycle_stage_status,
      lead_stage,
      lifecyclestage,
      middle_name,
      mobilephone,
      nationality_1_,
      phone,
      politically_exposed_persons_pep,
      position,
      preferred_channel,
      preferred_time_of_day,
      school,
      segment,
      sharia_compliant,
      source_of_name,
      source_of_wealth,
      technology_sophistication,
      title_salutation,
      notes_last_contacted,
      onboarding_id
    } = element.properties

    const delRec = await db.executeSql(deleteProspectById, [element.id])
    // console.log(delRec)
    prom.push(
      new Promise((resolve) => {
        insertProspectDetailsF({
          Operation,
          address_line_1,
          address_line_2,
          age_range,
          amount_status,
          arabic_last_name,
          arabic_middle_name,
          bio_profile,
          building_details,
          city,
          company,
          contact_type,
          country,
          degree,
          dirtyFlag,
          email,
          expected_closing_probability,
          expected_month_of_closing,
          expected_wealth_range,
          financial_sophistication,
          first_name_arabic,
          firstname,
          gender,
          general_remarks,
          hs_lead_status,
          hubspot_owner_id,
          id: element.id ,
          industry_,
          lastname,
          // lifecycle_stage_status,
          lead_stage,
          lifecyclestage,
          middle_name,
          mobilephone,
          nationality_1_,
          phone,
          politically_exposed_persons_pep,
          position,
          preferred_channel,
          preferred_time_of_day,
          school,
          segment,
          sharia_compliant,
          source_of_name,
          source_of_wealth,
          technology_sophistication,
          title_salutation,
          notes_last_contacted: String(notes_last_contacted),
          onboarding_id
        })
          .then(() => {
            resolve("success");
          })
          .catch((e) => {
            resolve(JSON.stringify(e));
          });
      })
    );   
  });

  return Promise.all(prom)
    .then(async (allval) => {
     
    })
    .catch((e) => {
      console.log(e);
      return [];
    });
}


//inserting into tables for single rec api (details api)
const insertOperCreateProspectFunc = async (CreateProspPayload: prospectInsert, db: SQLiteObject, dirtyFlag: 0| 1,  Operation: "LOAD" | "INSERT") => { 
  const {
    address_line_1,
    address_line_2,
    age_range,
    amount_status,
    arabic_last_name,
    arabic_middle_name,
    bio_profile,
    building_details,
    city,
    company,
    contact_type,
    country,
    degree,
    email,
    expected_closing_probability,
    expected_month_of_closing,
    expected_wealth_range,
    financial_sophistication,
    first_name_arabic,
    firstname,
    gender,
    general_remarks,
    hs_lead_status,
    hubspot_owner_id,
    industry_,
    lastname,
    // lifecycle_stage_status,
    lead_stage,
    lifecyclestage,
    middle_name,
    mobilephone,
    nationality_1_,
    phone,
    politically_exposed_persons_pep,
    position,
    preferred_channel,
    preferred_time_of_day,
    school,
    segment,
    sharia_compliant,
    source_of_name,
    source_of_wealth,
    technology_sophistication,
    title_salutation,
  } = CreateProspPayload.properties

  db.executeSql(createProspectQry, [
    address_line_1,
    address_line_2,
    age_range,
    amount_status,
    arabic_last_name,
    arabic_middle_name,
    bio_profile,
    building_details,
    city,
    company,
    contact_type,
    country,
    degree,
    email,
    expected_closing_probability,
    expected_month_of_closing,
    expected_wealth_range,
    financial_sophistication,
    first_name_arabic,
    firstname,
    gender,
    general_remarks,
    hs_lead_status,
    hubspot_owner_id,
    industry_,
    lastname,
    // lifecycle_stage_status,
    lead_stage,
    lifecyclestage,
    middle_name,
    mobilephone,
    nationality_1_,
    phone,
    politically_exposed_persons_pep,
    position,
    preferred_channel,
    preferred_time_of_day,
    school,
    segment,
    sharia_compliant,
    source_of_name,
    source_of_wealth,
    technology_sophistication,
    title_salutation,
    dirtyFlag,
    Operation
  ])

}

export const loadContactsforRM: () => Promise<any> = async () => {
  const userId = await getUserId();
  const teams =  await getTeams();

  let tmpSort = {};
      let fltrgrparr = [
        {
          filters: [
            {
              operator: "EQ",
              propertyName: teams?"hubspot_team_id":"hubspot_owner_id",
              value: teams?parseInt(teams[0].id):parseInt(userId),
            }
          ],
        },
      ];

      if(teams && teams.length >= 2)
      {
        fltrgrparr.push(
          {
            filters: [
              {
                operator: "EQ",
                propertyName: "hubspot_team_id",
                value: parseInt(teams[1].id),
              }
            ]
          }) 
      }
      if(teams && teams.length >= 3)
      {
        fltrgrparr.push(
          {
            filters: [
              {
                operator: "EQ",
                propertyName: "hubspot_team_id",
                value: parseInt(teams[2].id),
              }
            ]
          }) 
      };
  const sortObjDesc = {
        order: ["notes_last_contacted"],
        sorts: [
          {
            propertyName: "notes_last_contacted",
            direction: "DESCENDING",
          },
        ],
        properties: contProps,
      };
      const db: SQLiteObject = await SQLite.create(DbConfig);
      const apiReq = { ...sortObjDesc, filterGroups:fltrgrparr, limit: 100  };
      // console.log("filterApiRequest:",JSON.stringify(apiReq));
      const apiResp: filterApiResponse = await  api.filterProspectsAPI(apiReq);
      // console.log("filterApiResponse:",JSON.stringify(apiResp));
      await insertOperListFunc(apiResp, db, 0, "LOAD");//insert records retrieved from api into sqlite
      // console.log("Loading Contacts to DB");
      return apiResp;
  
}

//inserting into table for list apis
const insertOperListFunc = async (apiResp: filterApiResponse , db: SQLiteObject, dirtyFlag: 0| 1,  Operation: "LOAD" | "INSERT") => {
  // const userId = await getUserId();
  // let prom: Array<Promise<any>> = [];
  
  apiResp.results.forEach(async (element) => {
    
    const {
      hubspot_owner_id,
      title_salutation,
      firstname,
      middle_name,
      lastname,
      first_name_arabic,
      arabic_last_name,
      arabic_middle_name,
      gender,
      age_range,
      email,
      phone,
      mobilephone,
      address_line_1,
      address_line_2,
      city,
      country,
      nationality_1_,
      company,
      position,
      industry_,
      source_of_wealth,
      expected_wealth_range,
      bio_profile,
      general_remarks,
      preferred_channel,
      school,
      degree,
      segment,
      contact_type,
      lifecyclestage,
      // lifecycle_stage_status,
      lead_stage,
      hs_lead_status,
      financial_sophistication,
      technology_sophistication,
      building_details,
      politically_exposed_persons_pep,
      sharia_compliant,
      expected_closing_probability,
      amount_status,
      expected_month_of_closing,
      source_of_name,
      preferred_time_of_day,
      notes_last_contacted,
      onboarding_id,
      hs_lifecyclestage_customer_date

    } = element.properties

    db.executeSql(deleteProspectById, [element.id]).then((delRec) => {
      // console.log(JSON.stringify(delRec))
      SQLite.create(DbConfig).then((dbInstance: SQLiteObject) => {
        dbInstance.executeSql(insertProspectDetails, [
          element.id,
          hubspot_owner_id,
          title_salutation,
          firstname,
          middle_name,
          lastname,
          first_name_arabic,
          arabic_last_name,
          arabic_middle_name,
          gender,
          age_range,
          email,
          phone,
          mobilephone,
          address_line_1,
          address_line_2,
          city,
          country,
          nationality_1_,
          company,
          position,
          industry_
          ,source_of_wealth,
          expected_wealth_range,
          bio_profile,
          general_remarks,
          preferred_channel,
          school,
          degree,
          segment,
          contact_type,
          lifecyclestage,
          // lifecycle_stage_status,
          lead_stage,
          hs_lead_status,
          financial_sophistication,
          technology_sophistication,
          building_details,
          politically_exposed_persons_pep,
          sharia_compliant,
          expected_closing_probability,
          amount_status,
          expected_month_of_closing,
          source_of_name,
          preferred_time_of_day,
          dirtyFlag,
          Operation,
          notes_last_contacted,
          onboarding_id,
          hs_lifecyclestage_customer_date
        ]).then((val) => {
          // console.log("Prospect Data Inserted: " + JSON.stringify(val));
        }).catch((e) => {
          console.log(e)
        })
      }).catch((e) => console.log(JSON.stringify(e)))
    }).catch((e) => {
      console.log(JSON.stringify(e))
    })   
  });
}




export const filterProspectsDb = async (payload) => {
  const network: NetworkStatus = await Network.getStatus();
  const info = await Device.getInfo();
  //check for platform - if browser then return response from api else perform dboperations
  if (!(info.platform === "android" || info.platform === "ios")) {
    const Ppayload = {...payload}
    Ppayload.properties = contProps;
    Ppayload.limit = 10;
    return api.filterProspectsAPI(Ppayload)
  } else {
    const db: SQLiteObject = await SQLite.create(DbConfig);
    //check network connection
    if(network.connected) {//connected
      const Ppayload = {...payload}
      Ppayload.properties = contProps;
      Ppayload.limit = 10;
      const apiResp: filterApiResponse = await api.filterProspectsAPI(Ppayload);//call api
      //await insertOperListFunc(apiResp, db, 0, "LOAD");//insert records retrieved from api into sqlite
      return apiResp;
      
    } else {//network diconnected
      // console.log("filterProspectsDb payload:",JSON.stringify(payload));

      let isClient = payload.filterGroups[0].filters[1].propertyName === "hs_lifecyclestage_customer_date" && payload.filterGroups[0].filters[1].operator === "HAS_PROPERTY";
      let sqlStmt = isClient ? getAllClients : getAllProspects;
      return db.executeSql(sqlStmt).then((res) => {
        // console.log("HAHAH"+JSON.stringify(res));
        if (res.rows?.length > 0) {
          try{

            let temp: filterApiArr[] = [];
            for (let x = 0; x < res.rows.length; x++) {
              temp.push({
                id: res.rows.item(x).id,
                properties: res.rows.item(x)
              });
            }
            // console.log("TEMP ARR"+JSON.stringify(temp))
            let respObj:filterApiResponse= {
              total: res.rows.length,
              results: temp
            }
            return(respObj)
          } catch (e) {
            console.log("Try catch error"+ JSON.stringify(e))
          }
        } else {
          return {
            total: 0,
            results: []
          }
        }
      }).catch((e) => {
        console.log("HELLOOOOO"+JSON.stringify(e))
        if (e.rows?.length > 0) {
          try{

            let temp: filterApiArr[] = [];
            for (let x = 0; x < e.rows.length; x++) {
              temp.push({
                id: e.rows.item(x).id,
                properties: e.rows.item(x)
              });
            }
            // console.log("TEMP ARR"+JSON.stringify(temp))
            let respObj:filterApiResponse= {
              total: e.rows.length,
              results: temp
            }
            return respObj
          } catch (e) {
            console.log("Try catch error"+ JSON.stringify(e))
          }
        } else {
          return {
            total: 0,
            results: []
          }
        }
      }) //execute sqlite query for prospect
    }
  }
};

//

export const updateProspectDb = async (contactId,payload) => {
  const network: NetworkStatus = await Network.getStatus();
  const info = await Device.getInfo();
  if (!(info.platform === "android" || info.platform === "ios")) {
    const tempapi = api.updateProspectAPI(contactId,payload)
    
    return tempapi;
  }
  else {
    const db: SQLiteObject = await SQLite.create(DbConfig);
    //check network connection
    if(network.connected) {
      const apiResp: CreateProspectResponse = await api.updateProspectAPI(contactId,payload);//call api
      const {
        address_line_1,
        address_line_2,
        age_range,
        amount_status,
        arabic_last_name,
        arabic_middle_name,
        bio_profile,
        building_details,
        city,
        company,
        contact_type,
        country,
        degree,
        email,
        expected_closing_probability,
        expected_month_of_closing,
        expected_wealth_range,
        financial_sophistication,
        first_name_arabic,
        firstname,
        gender,
        general_remarks,
        hs_lead_status,
        hubspot_owner_id,
        industry_,
        lastname,
        // lifecycle_stage_status,
        lead_stage,
        lifecyclestage,
        middle_name,
        mobilephone,
        nationality_1_,
        phone,
        politically_exposed_persons_pep,
        position,
        preferred_channel,
        preferred_time_of_day,
        school,
        segment,
        sharia_compliant,
        source_of_name,
        source_of_wealth,
        technology_sophistication,
        title_salutation,
      } = apiResp.properties

      return db.executeSql(updateProspect, [
        contactId,
        hubspot_owner_id,
        title_salutation,
        firstname,
        middle_name,
        lastname,
        first_name_arabic,
        arabic_last_name,
        arabic_middle_name,
        gender,
        age_range,
        email,
        phone,
        mobilephone,
        address_line_1,
        address_line_2,
        city,
        country,
        nationality_1_,
        company,
        position,
        industry_,
        source_of_wealth,
        expected_wealth_range,
        bio_profile,
        general_remarks,
        preferred_channel,
        school,
        degree,
        segment,
        contact_type,
        lifecyclestage,
        // lifecycle_stage_status,
        lead_stage,
        hs_lead_status,
        financial_sophistication,
        technology_sophistication,
        building_details,
        politically_exposed_persons_pep,
        sharia_compliant,
        expected_closing_probability,
        amount_status,
        expected_month_of_closing,
        source_of_name,
        preferred_time_of_day,
        0,
        "UPDATE",
        contactId
      ]).then((val) => {
        // console.log("Network Connected - Prosp Rec Updated"+ JSON.stringify(val))
        return apiResp;
      }).catch((e) => {
        console.log("Network Connected - Create Prosp Rec Inserted - Error"+ JSON.stringify(e))
        return apiResp;
      })
    }
    else
    {
      let temp: prospectUpdate = payload
        const {
          address_line_1,
          address_line_2,
          age_range,
          amount_status,
          arabic_last_name,
          arabic_middle_name,
          bio_profile,
          building_details,
          city,
          company,
          contact_type,
          country,
          degree,
          email,
          expected_closing_probability,
          expected_month_of_closing,
          expected_wealth_range,
          financial_sophistication,
          first_name_arabic,
          firstname,
          gender,
          general_remarks,
          hs_lead_status,
          hubspot_owner_id,
          industry_,
          lastname,
          // lifecycle_stage_status,
          lead_stage,
          lifecyclestage,
          middle_name,
          mobilephone,
          nationality_1_,
          phone,
          politically_exposed_persons_pep,
          position,
          preferred_channel,
          preferred_time_of_day,
          school,
          segment,
          sharia_compliant,
          source_of_name,
          source_of_wealth,
          technology_sophistication,
          title_salutation,
        } = temp.properties
      let tempId = String(new Date().getTime())
      console.log("my id i s"+contactId);
      let custDate:any = lifecyclestage == "customer"?new Date():"";
      // if(contactId.includes("NEWPROSP"))
      // {
      //   //return db.executeSql(deleteProspectById,[contactId]).then((val) => { 
      //     return db.executeSql(createProspectQry, [
      //       address_line_1,
      //       address_line_2,
      //       age_range,
      //       amount_status,
      //       arabic_last_name,
      //       arabic_middle_name,
      //       bio_profile,
      //       building_details,
      //       city,
      //       company,
      //       contact_type,
      //       country,
      //       degree,
      //       email,
      //       expected_closing_probability,
      //       expected_month_of_closing,
      //       expected_wealth_range,
      //       financial_sophistication,
      //       first_name_arabic,
      //       firstname,
      //       gender,
      //       general_remarks,
      //       hs_lead_status,
      //       hubspot_owner_id,
      //       industry_,
      //       lastname,
      //       lifecycle_stage_status,
      //       lifecyclestage,
      //       middle_name,
      //       mobilephone,
      //       nationality_1_,
      //       phone,
      //       politically_exposed_persons_pep,
      //       position,
      //       preferred_channel,
      //       preferred_time_of_day,
      //       school,
      //       segment,
      //       sharia_compliant,
      //       source_of_name,
      //       source_of_wealth,
      //       technology_sophistication,
      //       title_salutation,
      //       1,
      //       "INSERT",
      //       tempId
      //     ]).then((val) => {
      //       console.log("Network Disconnected - Create Prosp Rec Inserted"+ JSON.stringify(val))
      //       const respObj: CreateProspectResponse = {
      //         id: String(tempId),
      //         archived: false,
      //         createdAt: new Date(),
      //         updatedAt: new Date(),
      //         properties: {
      //             ...temp.properties,
      //             hs_lifecyclestage_customer_date: "",
      //             amount_status: String(amount_status),
      //             hubspot_owner_id: String(hubspot_owner_id),
      //             sharia_compliant: String(sharia_compliant),
      //             createdate: new Date(),
      //             hs_count_is_unworked: "",
      //             hs_count_is_worked: "",
      //             hs_is_unworked: "",
      //             hubspot_owner_assigneddate: new Date(),
      //             lastmodifieddate: new Date()
      //         }
      //     }
      //     console.log(JSON.stringify(respObj))
      //       return respObj;
      //     }).catch((e) => {
      //       console.log("Network Disonnected - Prosp Rec Updated - Error"+ JSON.stringify(e))
      //       return {};
      //     })
      //   // }).catch((e) => {
      //   //   console.log("Network Disonnected - Delete by Prospect Id - Error"+ JSON.stringify(e))
      //   //   return {};
      //   // })
        
      // }
      //else
      {
        return db.executeSql(updateProspect, [
          contactId,
          hubspot_owner_id,
          title_salutation,
          firstname,
          middle_name,
          lastname,
          first_name_arabic,
          arabic_last_name,
          arabic_middle_name,
          gender,
          age_range,
          email,
          phone,
          mobilephone,
          address_line_1,
          address_line_2,
          city,
          country,
          nationality_1_,
          company,
          position,
          industry_,
          source_of_wealth,
          expected_wealth_range,
          bio_profile,
          general_remarks,
          preferred_channel,
          school,
          degree,
          segment,
          contact_type,
          lifecyclestage,
          // lifecycle_stage_status,
          lead_stage,
          hs_lead_status,
          financial_sophistication,
          technology_sophistication,
          building_details,
          politically_exposed_persons_pep,
          sharia_compliant,
          expected_closing_probability,
          amount_status,
          expected_month_of_closing,
          source_of_name,
          preferred_time_of_day,
          1,
          contactId.includes("NEWPROSP")?"INSERT":"UPDATE",
          contactId
          ]).then((val) => {
            // console.log("Network Disconnected - Prosp Rec Updated"+ JSON.stringify(val))
            const respObj: UpdateProspectResponse = {
              id: contactId ,
              archived: false,
              //createdAt: new Date(),
              updatedAt: new Date(),
              properties: {
                  ...temp.properties,
                  hs_lifecyclestage_customer_date: custDate,
                  amount_status: String(amount_status),
                  hubspot_owner_id: String(hubspot_owner_id),
                  sharia_compliant: String(sharia_compliant),
                  createdate: new Date(),
                  hs_count_is_unworked: "",
                  hs_count_is_worked: "",
                  hs_is_unworked: "",
                  hubspot_owner_assigneddate: new Date(),
                  lastmodifieddate: new Date()
              }
          }
          // console.log(JSON.stringify(respObj))
            return respObj;
          }).catch((e) => {
            console.log("Network Disonnected - Create Prosp Rec Inserted - Error"+ JSON.stringify(e))
            return {};
          })
      }
          
    }

  }
}

export const createProspectDb = async (payload) => {
  const network: NetworkStatus = await Network.getStatus();
  const info = await Device.getInfo();
  //check for platform - if browser then return response from api else perform dboperations
  if (!(info.platform === "android" || info.platform === "ios")) {
    // return api.filterProspectsAPI(payload)
    return api.createProspectAPI(payload)
  } else {
    const db: SQLiteObject = await SQLite.create(DbConfig);
    //check network connection
    if(network.connected) {//connected      
      const apiResp: CreateProspectResponse = await api.createProspectAPI(payload);//call api      
      const {
        address_line_1,
        address_line_2,
        age_range,
        amount_status,
        arabic_last_name,
        arabic_middle_name,
        bio_profile,
        building_details,
        city,
        company,
        contact_type,
        country,
        degree,
        email,
        expected_closing_probability,
        expected_month_of_closing,
        expected_wealth_range,
        financial_sophistication,
        first_name_arabic,
        firstname,
        gender,
        general_remarks,
        hs_lead_status,
        hubspot_owner_id,
        industry_,
        lastname,
        // lifecycle_stage_status,
        lead_stage,
        lifecyclestage,
        middle_name,
        mobilephone,
        nationality_1_,
        phone,
        politically_exposed_persons_pep,
        position,
        preferred_channel,
        preferred_time_of_day,
        school,
        segment,
        sharia_compliant,
        source_of_name,
        source_of_wealth,
        technology_sophistication,
        title_salutation,
      } = apiResp.properties
      
    return db.executeSql(createProspectQry, [
        address_line_1,
        address_line_2,
        age_range,
        amount_status,
        arabic_last_name,
        arabic_middle_name,
        bio_profile,
        building_details,
        city,
        company,
        contact_type,
        country,
        degree,
        email,
        expected_closing_probability,
        expected_month_of_closing,
        expected_wealth_range,
        financial_sophistication,
        first_name_arabic,
        firstname,
        gender,
        general_remarks,
        hs_lead_status,
        hubspot_owner_id,
        industry_,
        lastname,
        // lifecycle_stage_status,
        lead_stage,
        lifecyclestage,
        middle_name,
        mobilephone,
        nationality_1_,
        phone,
        politically_exposed_persons_pep,
        position,
        preferred_channel,
        preferred_time_of_day,
        school,
        segment,
        sharia_compliant,
        source_of_name,
        source_of_wealth,
        technology_sophistication,
        title_salutation,
        0,
        "LOAD",
        apiResp.id
      ]).then((val) => {
        // console.log("Network Connected - Create Prosp Rec Inserted"+ JSON.stringify(val))
        return apiResp;
      }).catch((e) => {
        console.log("Network Connected - Create Prosp Rec Inserted - Error"+ JSON.stringify(e))
        return apiResp;
      })
      
    } else {//network diconnected
        let temp: prospectInsert = payload
        const {
          address_line_1,
          address_line_2,
          age_range,
          amount_status,
          arabic_last_name,
          arabic_middle_name,
          bio_profile,
          building_details,
          city,
          company,
          contact_type,
          country,
          degree,
          email,
          expected_closing_probability,
          expected_month_of_closing,
          expected_wealth_range,
          financial_sophistication,
          first_name_arabic,
          firstname,
          gender,
          general_remarks,
          hs_lead_status,
          hubspot_owner_id,
          industry_,
          lastname,
          // lifecycle_stage_status,
          lead_stage,
          lifecyclestage,
          middle_name,
          mobilephone,
          nationality_1_,
          phone,
          politically_exposed_persons_pep,
          position,
          preferred_channel,
          preferred_time_of_day,
          school,
          segment,
          sharia_compliant,
          source_of_name,
          source_of_wealth,
          technology_sophistication,
          title_salutation,
        } = temp.properties
      let tempId = String(new Date().getTime());
      tempId = "NEWPROSP"+tempId;
      return db.executeSql(createProspectQry, [
          address_line_1,
          address_line_2,
          age_range,
          amount_status,
          arabic_last_name,
          arabic_middle_name,
          bio_profile,
          building_details,
          city,
          company,
          contact_type,
          country,
          degree,
          email,
          expected_closing_probability,
          expected_month_of_closing,
          expected_wealth_range,
          financial_sophistication,
          first_name_arabic,
          firstname,
          gender,
          general_remarks,
          hs_lead_status,
          hubspot_owner_id,
          industry_,
          lastname,
          // lifecycle_stage_status,
          lead_stage,
          lifecyclestage,
          middle_name,
          mobilephone,
          nationality_1_,
          phone,
          politically_exposed_persons_pep,
          position,
          preferred_channel,
          preferred_time_of_day,
          school,
          segment,
          sharia_compliant,
          source_of_name,
          source_of_wealth,
          technology_sophistication,
          title_salutation,
          1,
          "INSERT",
          tempId
        ]).then((val) => {
          // console.log("Network Disconnected - Create Prosp Rec Inserted"+ JSON.stringify(val))
          const respObj: CreateProspectResponse = {
            id: String(tempId),
            archived: false,
            createdAt: new Date(),
            updatedAt: new Date(),
            properties: {
                ...temp.properties,
                hs_lifecyclestage_customer_date: "",
                amount_status: String(amount_status),
                hubspot_owner_id: String(hubspot_owner_id),
                sharia_compliant: String(sharia_compliant),
                createdate: new Date(),
                hs_count_is_unworked: "",
                hs_count_is_worked: "",
                hs_is_unworked: "",
                hubspot_owner_assigneddate: new Date(),
                lastmodifieddate: new Date()
            }
        }
        // console.log(JSON.stringify(respObj))
          return respObj;
        }).catch((e) => {
          console.log("Network Disonnected - Create Prosp Rec Inserted - Error"+ JSON.stringify(e))
          return {};
        })
    }
  }
}


export const Func_getProspectById = async (contactId) => {
  const userId = await getUserId();
  
  const network: NetworkStatus = await Network.getStatus();
  const info = await Device.getInfo();
  //check for platform - if browser then return response from api else perform dboperations
  if (!(info.platform === "android" || info.platform === "ios")) {
    return api.getContactDetailsAPI({contactId:contactId})
  } else {
    const db: SQLiteObject = await SQLite.create(DbConfig);
    //check network connection
    if(network.connected) {//connected
      const apiResp = await api.getContactDetailsAPI({contactId:contactId});//call api
      await insertOperListFunc({
       results: [apiResp]
      }, db, 0, "LOAD");//insert records retrieved from api into sqlite
      return apiResp;
      
    } else {//network diconnected
      
      return db.executeSql(getProspectById,[contactId]).then((res) => {
        // console.log("HAHAH"+JSON.stringify(res));
        if (res.rows?.length > 0) {
          try{
            let temp: filterApiArr;
              temp= {
                id: res.rows.item(0).id,
                properties: res.rows.item(0)
              };
            // console.log("TEMP ARR"+JSON.stringify(temp))
            let respObj:filterApiArr = {...temp} 
            return(respObj)
          } catch (e) {
            console.log("Try catch error"+ JSON.stringify(e))
          }
        } else {
          return {}
        }
      }).catch((e) => {
        console.log("HELLOOOOO"+JSON.stringify(e))
        if (e.rows?.length > 0) {
          try{
            let temp: filterApiArr;
              temp= {
                id: e.rows.item(0).id,
                properties: e.rows.item(0)
              };
            console.log("TEMP ARR"+JSON.stringify(temp))
            let respObj:filterApiArr = {...temp} 
            return(respObj)
          } catch (e) {
            console.log("Try catch error"+ JSON.stringify(e))
          }
        } else {
          return {}
        }
      }) //execute sqlite query for prospect
    }
  }
};

