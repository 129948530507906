import React, { useState, useEffect } from "react";
import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonCheckbox,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonToolbar,
  IonCard,
  IonPopover,
} from "@ionic/react";
import { Formik } from "formik";
import CryptoJS from 'crypto-js';
import { chevronBack, chevronDown, ellipse } from "ionicons/icons";
import "./Associations.css";
import * as Yup from "yup";
import { Field } from "formik";
import {Device, Plugins } from "@capacitor/core";
import api from "src/api";
import SearchforTodos from "src/pages/ProspectSearch/ProspectSearch";
import { RouteComponentProps, useHistory } from "react-router-dom";
import useWindowDimensions from "src/components/useWindowDimensions";
import moment from "moment";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";

const { Storage } = Plugins;
const decrypt = (decryptVlaue) => {
  let passphrase = "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9"
  const bytes = CryptoJS.AES.decrypt(decryptVlaue, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};

const getUserId = async () => {
  const ret: any = await Storage.get({ key: "userId" });
  const temp = JSON.parse(decrypt(ret.value));
  return temp.id;
};

const optionsA = {
  cssClass: "my-custom-interface",
};

const relationShipOptions: any = [
  { val: "Parent"},
  { val: "Sibling"},
  { val: "Son"},
  { val: "Daughter"},
  { val: "Cousin"},
  { val: "In-Law"},
  { val: "Friend"},
  { val: "Business Partner"},
  { val: "Personal Assistant"},
  { val: "Advisor"},
  { val: "Administrator"}, 
];

const relationShipOptionsFamily: any = ["Parent","Sibling","Son","Daughter","Cousin","In-Law","Friend"];

const relationShipOptionsBusiness: any = [
  { val: "Business Partner"},
  { val: "Personal Assistant"},
  { val: "Advisor"},
  { val: "Administrator"}, 
];

const styles = {
  rootGrid: {
    marginLeft: 32,
    marginRight: 32,
  },
  toolbar: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
    height: 75,
  },
  HeaderRow: {
    marginTop: 24,
    marginBottom: 24,
  },
  Header: {
    fontSize: 28,
  },
  avatar: {
    width: 48,
    height: 48,
  },
  deftext: {
    fontSize: 14,
    marginTop: "auto",
    marginBottom: "auto",
    marginLeft: 16,
  },
  engname: {
    fontSize: 14,
    fontWeight: 700,
    marginTop: "auto",
    marginBottom: "auto",
    marginLeft: 16,
  },
  arabname: {
    fontSize: 14,
    fontFamily: "Almarai",
    marginTop: "auto",
    marginBottom: "auto",
  },
  period: {
    marginTop: "auto",
    marginBottom: "auto",
    marginLeft: 8,
    marginRight: 8,
    width: 8,
    height: 8,
  },
  addrembtn: {
    marginLeft: 64,
    "--border-radius": "2px",
    textTransform: "none",
    width: 180,
  },
  subbtn: {
    "--border-radius": "2px",
    textTransform: "none",
    width: 180,
  },
  cncl:{
    "--border-radius": "2px",
    textTransform: "none",
    width: 180,
  },
  listheadertext: {
    fontSize: 14,
    fontWeight: "normal",
    marginTop: 0,
  },
  radios: {
    fontSize: 14,
  },
  checklist: {
    "--padding-start": "0px",
  },
};

interface NewAssociationPageProps
  extends RouteComponentProps<{
    contactId: string;
    assocId:any;
    srcProsId:any;
  }> {}

  interface ShwConfState {
    open: boolean;
    event: Event | undefined;
  }

const NewAssociation: React.FC<NewAssociationPageProps> = ({
  match,
  history,
  location,
}) => {
  const [togSrch, settogSrch] = useState<boolean>(false);
  const [selcontid, setselcontid] = useState<number>(0);
  const [selcontObj, setselcontObj] = useState<any>();
  const [contIderror, setcontIderror] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [backtext, setbacktext] = useState<string>("Prospect Profile");
  const [relation, setRelation] =  useState<any>("");
  const [refer, setRefer] =  useState<any>(false);
  const [assocContactDetails, setAssocContactDetails] = useState<any>();
  const [edit, setEdit] = useState<any>(false);
  const hist = useHistory();

  const searchF = () => {
    settogSrch(true);
  };
  const [ErrorMsg, setErrorMsg] = useState<string>("Default Error Message")
  const [showError, setShowError] = useState<ShwConfState>({
    open: false,
    event: undefined,
  });
  const associationParam = history.location;
  let associationData: any = associationParam.state;

  const clearProspSelection = () => {
    setselcontid(0);
    setselcontObj({});
  };

  const handleSubmit = (values) => {
    if (selcontid !== 0 ) {
      setLoading(true);
      getUserId().then((userId) => {

        let contactPayload: any = {
          inputs: [{ id: selcontid }],
          properties: [
            "gender",
          ],
        };
        api.getTopTargetContacts(contactPayload).then((response) => {
          if (response && response.results && response.results.length > 0) {
             
              let gender = response.results[0].properties.gender;
              let corresAssociation = "";
              switch(values.relationship)
              {
                case "Business Partner": corresAssociation = "Business Partner";
                break;
                case "Sibling": corresAssociation = "Sibling";
                break;
                case "Parent": gender == "Yes"?corresAssociation = "Son":corresAssociation = "Daughter"
                break;
                case "Son": corresAssociation = "Parent";
                break;
                case "Daughter": corresAssociation = "Parent";
                break;
                case "Cousin": corresAssociation = "Cousin";
                break;
                case "In-Law": corresAssociation = "In-Law";
                break;
                case "Friend": corresAssociation = "Friend";
                break;
                case "Personal Assistant": corresAssociation = "Business Partner";
                break;
                case "Advisor": corresAssociation = "Business Partner";
                break;
                case "Administrator": corresAssociation = "Business Partner";
                break;   
                default : 
                break;       
              }
            if(edit){
              // console.log(values.relationship)
              // console.log(corresAssociation)
                api.updateAssociation(associationData.assocId,values.relationship,corresAssociation).then((resp)=>{
                  // console.log(resp)
                  setLoading(false);
                  hist.push("/contactinfo/" + associationData.contactId, {
                    to: "associations",
                  });
                })
                .catch((e) => {
                  console.log(JSON.stringify(e))
                  setLoading(false);
                })
            }
            else {
              let randomNum = moment().format("x")
              const payLoad = {
                createdBy: userId,
                updatedBy: userId,
                referral: values.referralFlg,
                sourceProspectId: selcontid,
                relationShipType: values.relationship,
                referenceId: randomNum
              };
              const payLoad2 = {
                createdBy: userId,
                updatedBy: userId,
                sourceProspectId: associationData.contactId,
                relationShipType: corresAssociation,
                referenceId: randomNum
              };
              api
                .createAssociation(associationData.contactId, payLoad)
                .then((resp) => {
                  // console.log(resp)
                  if(!resp.message)
                  {
                    api.createAssociation(selcontid, payLoad2)
                    .then((resp) => {
                      setLoading(false);
                      hist.push("/contactinfo/" +associationData.contactId, {
                        to: "associations",
                      });
                    }) 
                  }
                  else{
                      setErrorMsg(resp.message)
                      setShowError({ open: true, event: undefined}) 
                      setLoading(false);
                  }           
                })
                .catch((e) => {
                  console.log(JSON.stringify(e))
                  setLoading(false);
                })
            }	//console.log(match.params);
          } 
        })
        .catch((e) => {
          console.log(JSON.stringify(e))
          setLoading(false);
        })
        
      });
    } else {
      setcontIderror(true);
    }
  };
  const getUserEmail = async () => 
  {
    const ret: any = await Storage.get({ key: "userId" })
    const temp = JSON.parse(decrypt(ret.value))
    return temp && temp.email ? temp.email : null
  }
  useEffect(() => {    
     
    Device.getInfo().then(deviceInfo => {
		getUserEmail().then(useremail => {
			if(deviceInfo.platform == "web")
          {
            window.globalThis.firebase.analytics().setUserId(useremail);
            window.globalThis.firebase.analytics().logEvent('select_content', {
            user_name: useremail,
            content_type: 'NewAssociation',
            item_id: associationData.contactId,
            device_id:deviceInfo?deviceInfo.uuid:"",
            platform:deviceInfo?deviceInfo.platform:"",
            device_model:deviceInfo?deviceInfo.model:"",
            device_type:deviceInfo?deviceInfo.osVersion:"",
            device_os:deviceInfo?deviceInfo.operatingSystem:"",
              });
          }
         else
          {
            FirebaseAnalytics.setUserId(useremail);
            FirebaseAnalytics.logEvent("select_content", {user_name: useremail});
            FirebaseAnalytics.logEvent("select_content", {content_type: 'NewAssociation'});
            FirebaseAnalytics.logEvent("select_content", {item_id: associationData.contactId});
            FirebaseAnalytics.logEvent("select_content", {device_id: deviceInfo?deviceInfo.uuid:""});
            FirebaseAnalytics.logEvent("select_content", {platform: deviceInfo?deviceInfo.platform:""});
            FirebaseAnalytics.logEvent("select_content", {device_model: deviceInfo?deviceInfo.model:""});
            FirebaseAnalytics.logEvent("select_content", {device_type:deviceInfo?deviceInfo.osVersion:""});
            FirebaseAnalytics.logEvent("select_content", {device_os:deviceInfo?deviceInfo.operatingSystem:""});
          }
		  })
    })
    if (location.state) {
      let a: any = location.state;
      if (a.source === "Client") {
        setbacktext("Client Profile");
      }
    }
  }, [location.state]);

  useEffect(()=>{
    if(match.path === "/association/edit")
    {
      setEdit(true)
      api.getAssociations(associationData.contactId).then((assocObj)=>{
        let aObj = assocObj.filter((a) => a.sourceProspectId === associationData.srcProsId)[0]
        // console.log(aObj)
        setRelation(aObj?.relationShipType)
        setRefer(aObj?.referral)
      })

      let contactPayload: any = {
        inputs: [],
        properties: [
          "firstname,lastname,middle_name,first_name_arabic,arabic_last_name,arabic_middle_name,hs_avatar_filemanager_key",
        ],
      };
      contactPayload.inputs.push({ id: associationData.srcProsId });
      api.getTopTargetContacts(contactPayload).then((response) => {
        if (response && response.results && response.results.length > 0) {
          let results: Array<any> = response.results;
          setAssocContactDetails(
            results.map((contact) => {
              const {
                firstname,
                lastname,
                middle_name,
                first_name_arabic,
                arabic_last_name,
                arabic_middle_name,
                hs_avatar_filemanager_key,
              } = contact.properties;
              return {
                engName:
                  middle_name !== null && middle_name !== ""
                    ? `${firstname} ${middle_name} ${lastname}`
                    : `${firstname} ${lastname}`,
                arabicname:
                  arabic_middle_name !== null && arabic_middle_name !== ""
                    ? `${first_name_arabic} ${arabic_middle_name} ${arabic_last_name}`
                    : `${first_name_arabic} ${arabic_last_name}`,
                avatarpath: hs_avatar_filemanager_key,
              };
            })
          )
          setselcontid(associationData.srcProsId)
        }
      })
      .catch((e) => {
        // console.log(JSON.stringify(e))
      })


    }
  },[match.path])

  // console.log(assocContactDetails)
  // console.log(selcontid)
  // console.log(edit)
  // console.log(showError)
  return !togSrch ? (
    <IonPage>
      <IonLoading
        cssClass="my-custom-loading-class"
        isOpen={isLoading}
        message={"Please wait..."}
      />
      <IonHeader>
        <IonToolbar mode="md" style={styles.toolbar}>
          <IonButtons slot="start">
            <IonButton
              style={{ textTransform: "none" }}
              color="white"
              routerLink={"/contactinfo/" + associationData.contactId}
            >
              <IonIcon icon={chevronBack}></IonIcon>
              {backtext}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Formik
          enableReinitialize
          initialValues={{
            relationship: relation,
            referralFlg: refer,
          }}
          onSubmit={handleSubmit}
          validationSchema={Yup.object().shape({
            relationship: Yup.string()
              .max(255)
              .required("Please enter a relationship"),
          })}
        >
          {({
            values,
            errors,
            handleSubmit,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit}>
              <IonGrid class="ml-14 mr-13 mt-6" style={styles.rootGrid}>
                <ProspSelect
                  selcontid={selcontid}
                  search={searchF}
                  selcontObj={selcontObj}
                  clearProspSelection={clearProspSelection}
                  contIderror={contIderror}
                  edit={edit}
                  assocContactDetails={assocContactDetails}
                />
                <Form1
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  edit={edit}
                  relation={relation}
                />

                <IonButton
                  fill="outline"
                  style={styles.subbtn}
                  color="gold"
                  mode="md"
                  type="submit"
                  className="mobileML14"
                >
                  {edit ? "Update Association" : "Add Association"}
                </IonButton>
                {edit ? <IonButton
                  fill="outline"
                  style={styles.cncl}
                  color="gold"
                  mode="md"
                  className="mobileML14"
                  onClick={()=>{
                    hist.push("/contactinfo/" + associationData.contactId, {
                      to: "associations",
                    })
                  }}
                >
                  Cancel
                </IonButton>
                : null}
              </IonGrid>
            </form>
          )}
        </Formik>
       
      </IonContent>
         <IonPopover
                isOpen={showError.open}
                cssClass='my-custom-classA popover-class-A'
                event={undefined}
                onDidDismiss={e => setShowError({open:false, event: undefined})}
                mode="md"
            >
            <IonCard style={{ "margin":0, color:""}}>
                    <div>
                        <IonGrid class="popoverGridClass_A">
                            <IonRow style={{ color: "#828282", justifyContent: "center",fontWeight:400, fontSize: 32 }} class="ion-text-center ion-margin-top ion-margin-bottom">{ErrorMsg}</IonRow>
                            <IonRow class="ion-text-center ion-margin-bottom" style={{ color: "#B99855", cursor: "pointer", marginTop:"60px", borderTop:"solid 1px rgba(185, 152, 85,0.2)", justifyContent: "center" }}>
                                <IonCol style={{border:"solid 2px rgba(185, 152, 85,0.2)"}} onClick={() => setShowError({open:false, event: undefined})}>
                                    <IonButton class="ConfirmationButton" fill="clear">OK</IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </div>
                </IonCard>
            </IonPopover>
    </IonPage>
  ) : (
    <SearchforTodos
      assocId={associationData.contactId}
      forTodo={true}
      closeSrch={() => {
        settogSrch(false);
      }}
      setContProps={(obj) => {
        setselcontObj(obj);
        setselcontid(parseInt(obj.id));
        settogSrch(false);
        setcontIderror(false);
      }}
    />
  )
};

const ProspSelect: React.FC<{
  selcontid: number;
  search: any;
  selcontObj: any;
  clearProspSelection: any;
  contIderror: any;
  edit:any;
  assocContactDetails: any;
}> = ({ selcontid, search, selcontObj, clearProspSelection, contIderror, edit, assocContactDetails }) => {
  const { height, width } = useWindowDimensions();
  // console.log(assocContactDetails)
  return (
    <React.Fragment>
      <IonRow style={styles.HeaderRow} className="">
        <IonLabel class="fs-28 mobileML14" style={styles.Header} color="greyscale">
          {edit ? "Edit Association" : "New Association"}
        </IonLabel>
      </IonRow>
      <IonRow>
        <IonCol push="10" size="2" pushXs="8" sizeXs="4"style={{ fontSize: 14, textAlign: "right" }}>
          * = required
        </IonCol>
      </IonRow>
      <IonRow className="mobileML14">
      <IonCol size="1">
        <IonAvatar style={styles.avatar}>
          <img
            src={
              edit ? 
              assocContactDetails && assocContactDetails[0] && assocContactDetails[0].avatarpath ? `https://cdn2.hubspot.net/${assocContactDetails[0].avatarpath}` : "/assets/empty-avatar.svg"
              :
              selcontObj &&
              selcontObj.properties &&
              selcontObj.properties.hs_avatar_filemanager_key
                ? `https://cdn2.hubspot.net/${selcontObj.properties.hs_avatar_filemanager_key}`
                : "/assets/empty-avatar.svg"
            }
            alt=""
          />
        </IonAvatar>
      </IonCol> 
      <IonCol size="11" style={{margin: "auto"}}>
        {selcontid === 0 ? (
          <IonLabel color="greyscale" style={styles.deftext} className="mobileML36">
            Add prospect or client to this association
          </IonLabel>
        ) : (
          <React.Fragment>
            <IonLabel color="white" style={styles.engname} className="mobileML36">
              {edit ? assocContactDetails && assocContactDetails[0] ? assocContactDetails[0].engName : null
                :
                selcontObj.properties.middle_name !== "" &&
                selcontObj.properties.middle_name !== null
                ? `${selcontObj.properties.firstname} ${selcontObj.properties.middle_name} ${selcontObj.properties.lastname}`
                : `${selcontObj.properties.firstname} ${selcontObj.properties.lastname}`}
            </IonLabel>
            {width > 575 ?
            (
            <IonIcon
              icon={ellipse}
              size="small"
              color="greyscale"
              style={styles.period}
            ></IonIcon>
            ):<p></p>}
            <IonLabel color="greyscale" style={styles.arabname} className="mobileML36">
              {edit ? assocContactDetails && assocContactDetails[0] ? assocContactDetails[0].arabicname : null
              :
              selcontObj.properties.arabic_middle_name !== "" &&
              selcontObj.properties.arabic_middle_name !== null
                ? `${selcontObj.properties.first_name_arabic} ${selcontObj.properties.arabic_middle_name} ${selcontObj.properties.arabic_last_name}`
                : `${selcontObj.properties.first_name_arabic} ${selcontObj.properties.arabic_last_name}`}
            </IonLabel>
          </React.Fragment>
        )}
        </IonCol>
      </IonRow>
      {edit ? null :
      <IonRow>
        {selcontid === 0 ? (
          <IonButton
            fill="solid"
            style={styles.addrembtn}
            color="greyscaleBtn"
            mode="md"
            onClick={() => {
              search();
            }}
          >
            Search
          </IonButton>
        ) : (
          <IonButton
            fill="solid"
            style={styles.addrembtn}
            color="greyscaleBtn"
            mode="md"
            onClick={() => {
              clearProspSelection();
            }}
          >
            Remove
          </IonButton>
        )}
      </IonRow>
      }
      {contIderror ? (
        <IonRow style={{ marginLeft: 64 }}>
          <IonText color="danger">Please select a prospect</IonText>
        </IonRow>
      ) : null}
    </React.Fragment>
  );
};

const Form1: React.FC<{
  values: any;
  errors: any;
  handleChange: any;
  handleBlur: any;
  touched: any;
  setFieldValue: any;
  setFieldTouched: any;
  edit:any;
  relation:any;
}> = ({
  values,
  errors,
  handleChange,
  handleBlur,
  touched,
  setFieldValue,
  setFieldTouched,
  edit,
  relation
}) => {
  const { height, width } = useWindowDimensions();
  return (
    <IonRow className="form-row root-form-row mobileML14">
      <IonCol sizeLg="7" sizeSm="7">
        <IonLabel>* Relationship</IonLabel>
        {Boolean(errors.relatio && touched.taskTitle) ? (
          <IonText
            color="danger"
            style={{ marginLeft: 16 }}
          >{`(Relationship is required)`}</IonText>
        ) : null}

        <IonRow className="form-row">
          <IonCol sizeLg="9" sizeMd="9" sizeSm="9">
            <IonItem className="input-items" lines="none">
              <IonSelect
                interface={width < 576 ? "action-sheet" : "popover"}
                interfaceOptions={optionsA}
                mode="ios"
                name="relationship"
                value={values.relationship}
                onIonChange={handleChange}
                onIonBlur={handleBlur}
                placeholder="Select an option or create one"
              >
                {
                  edit && relationShipOptionsFamily.includes(relation) ? <IonSelectOption key='0'>{relation}</IonSelectOption> : null
                }
                {edit ? 
                  relationShipOptionsFamily.includes(relation)
                   ?                       
                         relationShipOptionsBusiness.map(({ val }, i) => (
                            <IonSelectOption key={i} value={val}>
                              {val}
                            </IonSelectOption>
                          ))
                    :
                    relationShipOptions.map(({ val }, i) => (
                      <IonSelectOption key={i} value={val}>
                        {val}
                      </IonSelectOption>
                    ))
                :
                relationShipOptions.map(({ val }, i) => (
                  <IonSelectOption key={i} value={val}>
                    {val}
                  </IonSelectOption>
                ))}
                
              </IonSelect>
              <IonIcon
                className="endchevron"
                color="gold"
                src={chevronDown}
              ></IonIcon>
            </IonItem>
            {edit ? null :
            <IonItem lines="none" class="margintopn20" style={styles.checklist}>
              <Field
                name="referralFlg"
                as={IonCheckbox}
                class="ion-color-actblue-v1"
                color="actblue"
                checked={values.referralFlg}
                onIonChange={(e) => {
                  setFieldValue("referralFlg", e.detail.checked);
                }}
                mode="md"
              />
              <IonLabel
                class="grayscale6 marginleftp10"
                style={{ fontSize: 12 }}
              >
                Is this association a referral?
              </IonLabel>
            </IonItem>
            }
          </IonCol>
        </IonRow>
      </IonCol>
    </IonRow>
  );
};

export default NewAssociation;
