import React, { useState, useEffect } from "react";
import {
  IonGrid,
  IonRow,
  IonItem,
  IonIcon,
  IonLabel,
  IonText,
  IonCard,
  IonMenuButton,
  IonButton,
} from "@ionic/react";
import { ellipse } from "ionicons/icons";
//import NoteData from "./NotesData";
import { ellipsisHorizontal, funnel } from "ionicons/icons";
import "./Notes.css";
import moment from "moment";
import EditNoteMenu from "./EditNotePopover";
import { useHistory } from "react-router-dom";
// import api from "src/api"

const styles = {
  grid: {
    marginLeft: 32,
    marginRight: 32,
    // marginTop: 32,
  },
  item: {
    "--background": "#222222",
    marginBottom: 2,
    borderRadius: 8,
  },
  card: {
    "--background": "#222222",
    marginTop: "unset",
    fontSize: "unset",
  },
  cards: {
    "--background": "#222222",
    marginTop: "unset",
    fontSize: "unset",
    boxShadow: "none",
    marginBottom:"0px"
  },
  desc: {
    fontWeight: 300,
    fontSize: 16,
    color: "#828282",
    marginBottom: 8,
  },
  datetime: {
    fontWeight: 300,
    fontSize: 16,
    color: "#828282",
  },
  period: {
    fontSize: 6,
    marginLeft: 4,
    marginRight: 4,
    marginTop: 8,
    color: "#828282",
  },
  threeDots: {
    color: "#828282",
  },
  label: {
    marginTop: 14.5,
    marginBottom: 14.5,
  },
  title: {
    fontWeight: "700",
    color: "#FFFFFF",
  },
  filtericon: {
    marginTop: 4,
    marginBottom: 12,
    marginRight: 0,
  },
  filtericonItem: {
    paddingRight: 0,
  },
};

interface NotesProps {
  contact: any;
  notes: any;
  filFields?: any;
  delRefreshNotes: Function;
}

interface ShwMenuState {
  open: boolean;
  event: Event | undefined;
}

interface noteObj {
  noteDesc: string;
  noteTiming: string;
  noteAccess: string;
  noteid: any;
}

export const Notes: React.FC<NotesProps> = ({
  contact,
  notes,
  filFields,
  delRefreshNotes,
}) => {
  //  const [Notes] = useState(NoteData);
  let contId = contact.id;
  const [noteId, setNoteId] = useState<any>();
  const [sNotes, setsNotes] = useState<any>([]);
  const [sStageFilterFlag, setsStageFilterFlag] = useState<any>(false);
  const history = useHistory()
  const [showMenu, setShowMenu] = useState<ShwMenuState>({
    open: false,
    event: undefined,
  });
  

  useEffect(() => {
    setsNotes([...notes]);
    
  }, [notes]);

  const delRefresh = () => {
    delRefreshNotes();
  };

  useEffect(() => {
    
    let temp: any = [];
    let temp2: any = [];
    let temp3: any = [];
    
    if (
      filFields["Meeting Topic"] &&
      filFields["Meeting Topic"].vals &&
      filFields["Meeting Topic"].vals.length > 0
    ) {
      
      for (const x of filFields["Meeting Topic"].vals) {
        
        temp = [...temp];
        if (temp2.length > 0) {
          temp2.forEach((o) => {
            if (o.noteDesc === x) {
              let len3 = temp3.length;
              let cnt3 = 0;
              if (len3 > 0) {
                for (let i = 0; i < len3; i++) {
                  if (o.noteid === temp3[i].noteid) cnt3++;
                }
                if (cnt3 === 0) temp3.push(o);
              } else temp3.push(o);
            }
          });
        } else {
          notes.forEach((o) => {
            if (o.noteDesc === x) {
              let len = temp.length;
              
              let cnt = 0;
              if (len > 0) {
                for (let i = 0; i < len; i++) {
                  if (o.noteid === temp[i].noteid) cnt++;
                }
                if (cnt === 0) temp.push(o);
              } else temp.push(o);
            }
          });
        }
      }
      // setsNotes(temp);
    }
    if (
      filFields["Prospect/ Client Type"] &&
      filFields["Prospect/ Client Type"].vals &&
      filFields["Prospect/ Client Type"].vals.length > 0
    ) {
      for (const x of filFields["Prospect/ Client Type"].vals) {
        temp = [...temp];
        
        if (temp.length > 0) {
          temp.forEach((o) => {
            if (o.clientType && o.clientType.includes(x)) {
              let len3 = temp3.length;
              let cnt3 = 0;
              if (len3 > 0) {
                for (let i = 0; i < len3; i++) {
                  if (o.noteid === temp3[i].noteid) cnt3++;
                }
                if (cnt3 === 0) temp3.push(o);
              } else temp3.push(o);
            }
          });
        } else {
          notes.forEach((o) => {
            if (o.clientType && o.clientType.includes(x)) {
              let len2 = temp2.length;
              let cnt2 = 0;
              if (len2 > 0) {
                for (let i = 0; i < len2; i++) {
                  if (o.noteid === temp2[i].noteid) cnt2++;
                }
                if (cnt2 === 0) temp2.push(o);
              } else temp2.push(o);
            }
          });
        }
      }
      //  setsNotes(temp);
    }
    let combinedarray = [];
    if (
      filFields["Prospect/ Client Type"] &&
      filFields["Prospect/ Client Type"].vals &&
      filFields["Prospect/ Client Type"].vals.length === 0 &&
      filFields["Meeting Topic"] &&
      filFields["Meeting Topic"].vals &&
      filFields["Meeting Topic"].vals.length === 0
    ) {
      
      setsNotes([...notes]);
     
    } else if (
      filFields["Meeting Topic"] &&
      filFields["Meeting Topic"].vals &&
      filFields["Meeting Topic"].vals.length > 0 &&
      filFields["Prospect/ Client Type"] &&
      filFields["Prospect/ Client Type"].vals &&
      filFields["Prospect/ Client Type"].vals.length === 0
    ) {
      
      setsNotes(temp);
    } else if (
      filFields["Meeting Topic"] &&
      filFields["Meeting Topic"].vals &&
      filFields["Meeting Topic"].vals.length === 0 &&
      filFields["Prospect/ Client Type"] &&
      filFields["Prospect/ Client Type"].vals &&
      filFields["Prospect/ Client Type"].vals.length > 0
    ) {
      
      setsNotes(temp2);
    } else if (
      filFields["Meeting Topic"] &&
      filFields["Meeting Topic"].vals &&
      filFields["Meeting Topic"].vals.length > 0 &&
      filFields["Prospect/ Client Type"] &&
      filFields["Prospect/ Client Type"].vals &&
      filFields["Prospect/ Client Type"].vals.length > 0
    ) {
      
      setsNotes(temp3);
    } else {
      
      setsNotes([...notes]);
    }


if (
    filFields["Contact Stage"] &&
    filFields["Contact Stage"].vals &&
    filFields["Contact Stage"].vals.length > 0
  ) 
{ 
  
  setsStageFilterFlag(true);
}
  }, [filFields]);


useEffect(() => {
  if(sStageFilterFlag === true)
  {    
    
    setsNotes((previous)=> {
      return previous.filter((o) => {
       
		if(o.noteTiming && o.inceptionDate )
		{
			if ((((new Date(o.inceptionDate).getTime()).toFixed(0) < o.noteTiming) && (filFields["Contact Stage"].vals.includes("Client"))) ||
			(((new Date(o.inceptionDate).getTime()).toFixed(0) > o.noteTiming) && (filFields["Contact Stage"].vals.includes("Prospect"))))
			{
				return true
			}
			else
			{
				return false
			}
		}
    //else if()
		else
			return true;
      });
    })   
    setsStageFilterFlag(false);
  }
}, [sStageFilterFlag,sNotes]);


  function iOS() {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      (navigator.userAgent.includes("Mac"))
    );
  }

  function changeformat(oldtime)
  {    
    //let newtime =  oldtime;
    // if(iOS())
    // {
    //   newtime = oldtime + (new Date(moment(oldtime).format()).getTimezoneOffset())*60*1000;
    // }    
    return moment(oldtime).format("LT")
  }

  return (
    <IonGrid class="ml-14 mt-unset mr-13" style={styles.grid}>
      <IonRow>
        <IonMenuButton style={{ marginLeft: "auto", marginBottom: "auto" }}>
          <IonButton fill="clear" size="small">
            <IonIcon
              color="gold"
              icon={funnel}
              size="small"
              // style={styles.filtericon}
            />
          </IonButton>
        </IonMenuButton>
      </IonRow>
      {sNotes.length > 0
        ? sNotes.map((note) => (
            <IonItem
              className="CardIonItem"
              style={styles.item}
              lines="none"
              key={note.noteid}
            >
              {/* <IonCard
                style={styles.cards}
                routerLink={`/meetingnotedetails/${contId}/${note.noteid}`}
              > */}
                <IonCard
                onClick={(e)=>history.push({
                  pathname: '/meetingnotedetails',
                  state: {contId:contId,noteId:note.noteid}
                })}
                style={styles.cards}                
              >
                <IonLabel style={styles.label}>
                  <IonRow>
                    <IonText style={styles.title}>{note.noteDesc}</IonText>
                    <IonIcon style={styles.period} className="period-ellipse" icon={ellipse}></IonIcon>
                    <IonText style={styles.desc}>{note.noteAccess}</IonText>
                  </IonRow>
                  <IonRow>
                    <IonText style={styles.datetime}>
                      {`${moment(note.noteTiming).format("ddd,MMM DD,YYYY")}`}
                      <IonIcon style={styles.period} className="period-ellipse" icon={ellipse}></IonIcon>
                      {
                          changeformat(note.noteTiming)
                      }
                    </IonText>
                  </IonRow>
                </IonLabel>
              </IonCard>
              <IonIcon
                style={styles.threeDots}
                size="small"
                icon={ellipsisHorizontal}
                slot="end"
                onClick={(e) => {
                  setShowMenu({ open: true, event: e.nativeEvent });
                  setNoteId(note.noteid);
                }}
              ></IonIcon>
            </IonItem>
          ))
        : null}
      <EditNoteMenu
        open={showMenu.open}
        event={showMenu.event}
        setShowPopover={() => setShowMenu({ open: false, event: undefined })}
        contactId={contId}
        noteId={noteId}
        delRefreshNotes={delRefresh}
      />
    </IonGrid>
  );
};
export default Notes;
