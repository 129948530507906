import {
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
  IonCol,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonRadioGroup,
  IonListHeader,
  IonRadio,
  IonIcon,
  IonText,
} from "@ionic/react";
import { Field } from "formik";
import { chevronDown } from "ionicons/icons";
import CryptoJS from 'crypto-js';
import React from "react";
import "./CreateProspect.css";
import useWindowDimensions from "src/components/useWindowDimensions";

import { Plugins } from "@capacitor/core";
const { Storage } = Plugins;

const decrypt = (decryptVlaue) => {
  let passphrase = "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9"
  const bytes = CryptoJS.AES.decrypt(decryptVlaue, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};

const getUserId = async () => {
  const ret: any = await Storage.get({ key: "userId" });
  const temp = JSON.parse(decrypt(ret.value));
  return temp ? temp : null;
};

const styles = {
  paddingDesk:{
    padding:"27 0 0 0",
  },
  root: {
    padding: 27,
  },
  
  selectItem: {
    "--padding-start": "0px",
    backgroundColor: "#222222",
    marginTop: 8,
  },
  selectItemJ:{
    "--padding-start": "0px",
    backgroundColor: "#222222",
    marginTop: 8,
    width: "80%",
  },
  select: {
    "--padding-start": "8px",
  },
  select_jrny: {
    "--padding-start": "8px",
  },
  label: {
    // marginBottom: 8
  },
  radiolist: {
    "--padding-start": "0px",
  },
  listHeader: {
    fontSize: 14,
    paddingInlineStart: 0,
    marginBottom: 8,
    maxHeight: 0,
    fontWeight: "normal",
  },
};

const BasicInfo: React.FC<any> = ({
  salutationlist,
  values,
  handleChange,
  agegrplist,
  jrnystglist,
  jrnystglist2,
  statuslist,
  errors,
  touched,
  hubOwners,
  setFieldValue,
  personaList,
  typelist,
  srclist,
  genderlist,
  handleBlur,
}) => {
  
  // console.log(values.jrnystg);
  // const genderlist = [
  //     { val: "Male" },
  //     { val: "Female" },
  //     { val: "N/A" }
  // ];
  // const srclist = [
  //     { val: "DFO" },
  //     { val: "TFO" }
  // ];
  // const statuslist = [
  //     { val: "In Play" },
  //     { val: "Pause for 3 Months" },
  //     { val: "Pause for 6 Months" },
  //     { val: "Maintenance" },
  //     { val: "On Hold" },
  //     { val: "Dropped" }
  // ];
  // const jrnystglist = [
  //     { val: "Not Contacted Yet" },
  //     { val: "First Contact" },
  //     { val: "First Meeting" },
  //     { val: "Second Meeting" },
  //     { val: "Relationship Building" },
  //     { val: "RPQ/Proposal Allocation Review" },
  //     { val: "Client Onboarding" },
  //     { val: "Existing Client Servicing" },
  // ];
  // const agegrplist = [
  //     { val: "21-30" },
  //     { val: "31-40" },
  //     { val: "41-50" },
  //     { val: "51-60" },
  //     { val: "61-70" },
  //     { val: "71-80" },
  //     { val: "Above 80" },
  //     { val: "N/A" },
  // ]
  // const typelist = [
  //     { val: "Prospect" },
  //     { val: "Target" }
  // ];
  // const personalist = [
  //     { val: "Inheritor" },
  //     { val: "Institution" },
  //     { val: "Merchant" },
  //     { val: "Professional" },
  //     { val: "Royal" },
  //     { val: "Real Estate" },
  //     { val: "Retiree" },
  //     { val: "Diversifier" }
  // ]
  const options = {
    cssClass: "my-custom-interface",
  };
  const optionsJourney = {
    cssClass: "my-custom-interface my-custom-interface-journey",
  };
  const { height, width } = useWindowDimensions();
  return (
    <IonGrid class="padding-unset" style={styles.root}>
      <IonRow>
        <IonCol size-xs="4" size-sm="5" size-lg="5"></IonCol>
        <IonCol size-xs="4" size-sm="5"  size-lg="5"></IonCol>
        <IonCol
          size-xs="4"
          size-sm="2"
          size-lg="2"
          style={{ fontSize: 14, textAlign: "right" }}
        >
          * = required
        </IonCol>
      </IonRow>
      <IonRow class="marginbottomp24">
        <IonCol size-xs="10" size-sm="4"  size-lg="4">
          <IonLabel class="font14" style={styles.label}>
            Salutation
          </IonLabel>
          <IonItem lines="none" class ="ion-item-height" style={styles.selectItem}>
            <Field
              as={IonSelect}
              interfaceOptions={options}
              style={styles.select}
              value={values.salutation}
              onIonChange={handleChange}
              onIonBlur={handleBlur}
              name="salutation"
              mode="ios"
              class="customIonSelect"
              placeholder="Select Salutation"
              interface={width < 576 ? "action-sheet" : "popover"}
            >
              {salutationlist.map(({ val, label }, i) => (
                <IonSelectOption key={i} value={val}>
                  {val}
                </IonSelectOption>
              ))}
            </Field>
            <IonIcon slot="end" icon={chevronDown} color="gold" />
          </IonItem>
        </IonCol>
        <IonCol size-xs="10" size-sm="4"  size-lg="4">
          <IonLabel class="font14" style={styles.label}>
            Relationship Manager
          </IonLabel>
          <IonItem style={styles.selectItem} class ="ion-item-height" lines="none">
            <Field
              as={IonSelect}
              interfaceOptions={options}
              style={styles.select}
              value={values.assigned_rm}
              // onIonChange={handleChange}
              onIonBlur={handleBlur}
              onIonChange={(e) => {
                let TempObj = hubOwners
                  .map((obj) => {
                    return {
                      val:
                        obj.firstName && obj.firstName !== ""
                          ? `${obj.firstName} ${obj.lastName}`
                          : obj.email,
                      hs_id: obj.id,
                    };
                  })
                  .filter((obj) => obj.val === e.detail.value);
                setFieldValue("assigned_rm", e.detail.value);
                getUserId().then((Obj) => {
                  setFieldValue(
                    "hubspot_owner_id",
                    e.detail.value === "" ? "" : TempObj.length > 0 ? TempObj[0].hs_id : Obj.id
                  );
                });
              }}
              name="assigned_rm"
              mode="ios"
              class="customIonSelect font14"
              placeholder="Select One"
              interface={width < 576 ? "action-sheet" : "popover"}
            >
              {hubOwners
                .map((obj) => {
                  return {
                    val:
                      obj.firstName && obj.firstName !== ""
                        ? `${obj.firstName} ${obj.lastName}`
                        : obj.email,
                    hs_id: obj.id,
                  };
                })
                .map((obj, i) => (
                  (i===0)?
                  <>
                    <IonSelectOption key="a" value={""}>
                    {""}
                  </IonSelectOption>
                  <IonSelectOption key="b" value={obj.val}>
                    {obj.val}
                  </IonSelectOption></>
                  :<IonSelectOption key={i} value={obj.val}>
                  {obj.val}
                </IonSelectOption>
                ))} 
            </Field>
            <IonIcon slot="end" icon={chevronDown} color="gold" />
          </IonItem>
        </IonCol>
        <IonCol></IonCol>
      </IonRow>

      <IonRow class="marginbottomp24">
        <IonCol size-xs="10" size-sm="4"  size-lg="4">
          <IonLabel class="font14" style={styles.label}>
            *English First Name
          </IonLabel>
          <IonItem lines="none" class ="ion-item-height" style={styles.selectItem}>
            <Field
              as={IonInput}
              class="inpClass font14"
              onIonChange={handleChange}
              onIonBlur={handleBlur}
              value={values.firstName}
              name="firstName"
              placeholder="Enter First Name"
              autocapitalize="sentences"
            />
          </IonItem>
          {Boolean(errors.firstName && touched.firstName) ? (
            <IonText color="danger">{errors.firstName}</IonText>
          ) : null}
        </IonCol>
        <IonCol size-xs="10" size-sm="4"  size-lg="4">
          <IonLabel class="font14" style={styles.label}>
            English Middle Name
          </IonLabel>
          <IonItem lines="none" class ="ion-item-height" style={styles.selectItem}>
            <Field
              as={IonInput}
              class="inpClass font14"
              onIonBlur={handleBlur}
              onIonChange={handleChange}
              value={values.middleName}
              name="middleName"
              placeholder="Enter Middle Name"
              autocapitalize="sentences"
            ></Field>
          </IonItem>
        </IonCol>
        <IonCol size-xs="10" size-sm="4"  size-lg="4">
          <IonLabel class="font14" style={styles.label}>
            *English Last Name
          </IonLabel>
          <IonItem lines="none" class ="ion-item-height" style={styles.selectItem}>
            <Field
              as={IonInput}
              class="inpClass font14"
              onIonBlur={handleBlur}
              onIonChange={handleChange}
              value={values.lastName}
              name="lastName"
              placeholder="Enter Last Name"
              autocapitalize="sentences"
            ></Field>
          </IonItem>
          {Boolean(errors.lastName && touched.lastName) ? (
            <IonText color="danger">{errors.lastName}</IonText>
          ) : null}
        </IonCol>
      </IonRow>

      <IonRow class="marginbottomp24">
        <IonCol size-xs="10" size-sm="4"  size-lg="4">
          <IonLabel class="font14" style={styles.label}>
            Arabic First Name
          </IonLabel>
          <IonItem lines="none" class ="ion-item-height" style={styles.selectItem}>
            <Field
              as={IonInput}
              lang="ar"
              class="inpClass font14"
              onIonChange={handleChange}
              onIonBlur={handleBlur}
              value={values.arbfirstName}
              name="arbfirstName"
              placeholder="Enter First Name"
            ></Field>
          </IonItem>
          {Boolean(errors.arbfirstName && touched.arbfirstName) ? (
            <IonText color="danger">{errors.arbfirstName}</IonText>
          ) : null}
        </IonCol>
        <IonCol size-xs="10" size-sm="4"  size-lg="4">
          <IonLabel class="font14" style={styles.label}>
            Arabic Middle Name
          </IonLabel>
          <IonItem lines="none" class ="ion-item-height" style={styles.selectItem}>
            <Field
              as={IonInput}
              class="inpClass font14"
              onIonChange={handleChange}
              onIonBlur={handleBlur}
              value={values.arbmiddleName}
              name="arbmiddleName"
              placeholder="Enter Middle Name"
            ></Field>
          </IonItem>
          {Boolean(errors.arbmiddleName && touched.arbmiddleName) ? (
            <IonText color="danger">{errors.arbmiddleName}</IonText>
          ) : null}
        </IonCol>
        <IonCol size-xs="10" size-sm="4"  size-lg="4">
          <IonLabel class="font14" style={styles.label}>
            Arabic Last Name
          </IonLabel>
          <IonItem lines="none" class ="ion-item-height" style={styles.selectItem}>
            <Field
              as={IonInput}
              class="inpClass font14"
              onIonChange={handleChange}
              onIonBlur={handleBlur}
              value={values.arblastName}
              name="arblastName"
              placeholder="Enter Last Name"
            ></Field>
          </IonItem>
          {Boolean(errors.arblastName && touched.arblastName) ? (
            <IonText color="danger">{errors.arblastName}</IonText>
          ) : null}
        </IonCol>
      </IonRow>
      <IonRow class="marginbottomp15">
        <IonCol size-xs="10" size-sm="4"  size-lg="4">
          <IonLabel class="font14" style={styles.label}>
            *Persona
          </IonLabel>
          <IonItem class ="ion-item-height" style={styles.selectItem} lines="none">
            <Field
              as={IonSelect}
              interfaceOptions={options}
              style={styles.select}
              mode="ios"
              onIonChange={handleChange}
              onIonBlur={handleBlur}
              value={values.persona}
              class="customIonSelect font14"
              name="persona"
              placeholder="Select an option or create one"
              interface={width < 576 ? "action-sheet" : "popover"}
            >
              {personaList.map(({ val, label }, i) => (
                <IonSelectOption key={i} value={val}>
                  {label}
                </IonSelectOption>
              ))}
            </Field>
            <IonIcon slot="end" icon={chevronDown} color="gold" />
          </IonItem>
          {Boolean(errors.persona && touched.persona) ? (
            <IonText color="danger">{errors.persona}</IonText>
          ) : null}
        </IonCol>
        <IonCol></IonCol>
        <IonCol></IonCol>
      </IonRow>
      <IonRow class="marginbottomp24">
        <IonCol size-xs="6" size-sm="2"  size-lg="3">
          <Field
            as={IonRadioGroup}
            value={values.source}
            onIonChange={handleChange}
            onIonBlur={handleBlur}
            name="source"
          >
            <IonListHeader style={styles.listHeader}>
              <IonLabel>Source</IonLabel>
            </IonListHeader>
            {srclist.map(({ val, label }, i) => (
              <IonItem
                style={styles.radiolist}
                class="grayscale6 margintopn20"
                lines="none"
                key={i}
              >
                <IonRadio mode="md" color="actblue" value={val} />
                <IonLabel style={{ fontSize: 12 }} class="marginleftp10">
                  {label}
                </IonLabel>
              </IonItem>
            ))}
          </Field>
          <Field
            as={IonRadioGroup}
            value={values.type}
            onIonChange={handleChange}
            onIonBlur={handleBlur}
            name="type"
          >
            <IonListHeader style={styles.listHeader}>
              <IonLabel>Type</IonLabel>
            </IonListHeader>
            {typelist.map(({ val, label }, i) => (
              <IonItem
                style={styles.radiolist}
                class="grayscale6 margintopn20"
                lines="none"
                
                key={i}
              >
                {/* {
                  values.type=="Client"?
                  <IonRadio disabled mode="md" color="actblue" value={val} />
                  :
                  <IonRadio mode="md" color="actblue" value={val} />
                } */}
                <IonRadio mode="md" color="actblue" value={val} />
                <IonLabel style={{ fontSize: 12 }} class="marginleftp10">
                  {label}
                </IonLabel>
              </IonItem>
            ))}
          </Field>
          {/* <IonSelect>
                            <IonSelectOption>

                            </IonSelectOption>
                        </IonSelect> */}
        </IonCol>

        <IonCol size-xs="6" size-sm="3"  size-lg="3">
          <Field
            as={IonRadioGroup}
            value={values.agegrp}
            onIonChange={handleChange}
            onIonBlur={handleBlur}
            name="agegrp"
          >
            <IonListHeader style={styles.listHeader}>
              <IonLabel>Age</IonLabel>
            </IonListHeader>
            {agegrplist.map(({ val, label }, i) => (
              <IonItem
                style={styles.radiolist}
                class="grayscale6 margintopn20"
                lines="none"
                key={i}
              >
                <IonRadio mode="md" color="actblue" value={val} />
                <IonLabel style={{ fontSize: 12 }} class="marginleftp10 font12">
                  {label}
                </IonLabel>
              </IonItem>
            ))}
          </Field>
        </IonCol>
        <IonCol size-xs="6" size-sm="4"  size-lg="3">
          {/* <Field
            as={IonRadioGroup}
            value={values.jrnystg}
            onIonChange={handleChange}
            onIonBlur={handleBlur}
            name="jrnystg"
          >
            <IonListHeader style={styles.listHeader}>
              <IonLabel>User Journey Stages</IonLabel>
            </IonListHeader>
            {jrnystglist.map(({ val, label }, i) => (
              <IonItem
                style={styles.radiolist}
                class="grayscale6 margintopn20"
                lines="none"
                key={i}
              >
                <IonRadio mode="md" color="actblue" value={val} />
                <IonLabel style={{ fontSize: 12 }} class="marginleftp10">
                  {label}
                </IonLabel>
              </IonItem>
            ))}
          </Field> */}
           <IonListHeader style={styles.listHeader}>
              <IonLabel>User Journey Stages</IonLabel>
            </IonListHeader>
          <IonItem style={styles.selectItemJ} lines="none">
            <Field
              as={IonSelect}
              interfaceOptions={optionsJourney}
              style={styles.select_jrny}
              mode="ios"
              onIonChange={handleChange}
              onIonBlur={handleBlur}
              value={values.jrnystg}
              class="customIonSelect font14"
              name="jrnystg"
              placeholder="Please Select"
              interface={width < 576 ? "action-sheet" : "popover"}
            >  
            
              {jrnystglist.map(({ val, label }, i) => (
               
                <IonSelectOption key={i} value={val} class="customionselectjrny">
                {label}
              </IonSelectOption>
              ))}
              
            </Field>
            <IonIcon slot="end" icon={chevronDown} color="gold" />
          </IonItem>
        </IonCol>
        <IonCol size-xs="6" size-sm="3"  size-lg="3">
          <Field
            as={IonRadioGroup}
            value={values.status}
            onIonChange={handleChange}
            onIonBlur={handleBlur}
            name="status"
          >
            <IonListHeader style={styles.listHeader}>
              <IonLabel>Status</IonLabel>
            </IonListHeader>
            {statuslist.map(({ val, label }, i) => (
              <IonItem
                style={styles.radiolist}
                class="grayscale6 margintopn20"
                lines="none"
                key={i}
              >
                <IonRadio mode="md" color="actblue" value={val} />
                <IonLabel style={{ fontSize: 12 }} class="marginleftp10">
                  {label}
                </IonLabel>
              </IonItem>
            ))}
          </Field>
        </IonCol>
      </IonRow>
      <IonRow class="marginbottomp24">
        <IonCol size-xs="6" size-sm="2"  size-lg="3">
          <Field
            as={IonRadioGroup}
            value={values.gender}
            onIonChange={handleChange}
            onIonBlur={handleBlur}
            name="gender"
          >
            <IonListHeader style={styles.listHeader}>
              <IonLabel>Gender</IonLabel>
            </IonListHeader>
            {genderlist.map(({ val, label }, i) => (
              <IonItem
                style={styles.radiolist}
                class="grayscale6 margintopn20"
                lines="none"
                key={i}
              >
                <IonRadio mode="md" color="actblue" value={val} />
                <IonLabel style={{ fontSize: 12 }} class="marginleftp10">
                  {label}
                </IonLabel>
              </IonItem>
            ))}
          </Field>
        </IonCol>
        <IonCol size-xs="6" size-sm="3"  size-lg="3"> 
          <Field
            as={IonRadioGroup}
            value={values.finsophis}
            onIonChange={handleChange}
            onIonBlur={handleBlur}
            name="finsophis"
          >
            <IonListHeader style={styles.listHeader}>
              <IonLabel>Financial Sophistication</IonLabel>
            </IonListHeader>
            <IonItem
              style={styles.radiolist}
              class="grayscale6 margintopn20"
              lines="none"
            >
              <IonRadio mode="md" color="actblue" value="High" />
              <IonLabel style={{ fontSize: 12 }} class="marginleftp10">
                High
              </IonLabel>
            </IonItem>
            <IonItem
              style={styles.radiolist}
              class="grayscale6 margintopn20"
              lines="none"
            >
              <IonRadio mode="md" color="actblue" value="Medium" />
              <IonLabel style={{ fontSize: 12 }} class="marginleftp10">
                Medium
              </IonLabel>
            </IonItem>
            <IonItem
              style={styles.radiolist}
              class="grayscale6 margintopn20"
              lines="none"
            >
              <IonRadio mode="md" color="actblue" value="Low" />
              <IonLabel style={{ fontSize: 12 }} class="marginleftp10">
                Low
              </IonLabel>
            </IonItem>
          </Field>
        </IonCol>
        <IonCol size-xs="6" size-sm="7"  size-lg="3">
          <Field
            as={IonRadioGroup}
            value={values.techsophis}
            onIonChange={handleChange}
            onIonBlur={handleBlur}
            name="techsophis"
          >
            <IonListHeader style={styles.listHeader}>
              <IonLabel>Technical Sophistication</IonLabel>
            </IonListHeader>
            <IonItem
              style={styles.radiolist}
              class="grayscale6 margintopn20"
              lines="none"
            >
              <IonRadio mode="md" color="actblue" value="High" />
              <IonLabel style={{ fontSize: 12 }} class="marginleftp10">
                High
              </IonLabel>
            </IonItem>
            <IonItem
              style={styles.radiolist}
              class="grayscale6 margintopn20"
              lines="none"
            >
              <IonRadio mode="md" color="actblue" value="Medium" />
              <IonLabel style={{ fontSize: 12 }} class="marginleftp10">
                Medium
              </IonLabel>
            </IonItem>
            <IonItem
              style={styles.radiolist}
              class="grayscale6 margintopn20"
              lines="none"
            >
              <IonRadio mode="md" color="actblue" value="Low" />
              <IonLabel style={{ fontSize: 12 }} class="marginleftp10">
                Low
              </IonLabel>
            </IonItem>
          </Field>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default BasicInfo;
