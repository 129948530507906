import React, { useEffect, useState } from "react";
import { IonCol, IonRow, IonGrid, IonLoading } from "@ionic/react";
import DealCard from "src/components/Deals/DealCard";
import api from "src/api";
import CryptoJS from "crypto-js";
import { Plugins } from "@capacitor/core";

export default function Deals(props) {
  const [isLoading, setLoading] = useState(false);
  const [dealsData, setDealsData] = useState<any>([]);
  const { Storage } = Plugins;
  const decrypt = (decryptVlaue) => {
    let passphrase =
      "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9";
    const bytes = CryptoJS.AES.decrypt(decryptVlaue, passphrase);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  };
  const getUserId = async () => {
    const ret: any = await Storage.get({ key: "userId" });
    return JSON.parse(decrypt(ret?.value))?.id;
  };
  useEffect(() => {
    getAllDeals();
    getClientData();
    getProspectData();
  }, []);
  const getClientData = () => {
    const properties = [
      "hubspot_owner_id",
      "title_salutation",
      "firstname",
      "middle_name",
      "lastname",
      "first_name_arabic",
      "arabic_last_name",
      "arabic_middle_name",
      "gender",
      "age_range",
      "email",
      "phone",
      "mobilephone",
      "address_line_1",
      "address_line_2",
      "city",
      "country",
      "nationality_1_",
      "company",
      "position",
      "industry_",
      "source_of_wealth",
      "expected_wealth_range",
      "bio_profile",
      "general_remarks",
      "preferred_channel",
      "school",
      "degree",
      "segment",
      "contact_type",
      "lifecyclestage",
      "lead_stage",
      "hs_lead_status",
      "financial_sophistication",
      "technology_sophistication",
      "building_details",
      "politically_exposed_persons_pep",
      "sharia_compliant",
      "expected_closing_probability",
      "amount_status",
      "expected_month_of_closing",
      "source_of_name",
      "preferred_time_of_day",
      "notes_last_contacted",
      "onboarding_id",
      "hs_avatar_filemanager_key",
      "hs_lifecyclestage_customer_date",
    ];
    getUserId().then((userId) => {
      setLoading(true);
      let payload: any = {
        sorts: [
          {
            propertyName: "notes_last_contacted",
            direction: "ASCENDING",
          },
        ],
        limit: 10,
        order: ["notes_last_contacted"],
        filterGroups: [
          {
            filters: [
              {
                operator: "EQ",
                propertyName: "hubspot_owner_id",
                value: parseInt(userId),
              },
              {
                operator: "HAS_PROPERTY",
                propertyName: "hs_lifecyclestage_customer_date",
              },
            ],
          },
        ],
        properties: properties,
      };
      api.filterProspectsAPI({ ...payload }).then((resp) => {
        if (resp) {
          const clientCount = resp.total;
          sessionStorage.setItem("clientCount", clientCount);
        }
      });
    });
  };
  const getProspectData = () => {
    const properties = [
      "hubspot_owner_id",
      "title_salutation",
      "firstname",
      "middle_name",
      "lastname",
      "first_name_arabic",
      "arabic_last_name",
      "arabic_middle_name",
      "gender",
      "age_range",
      "email",
      "phone",
      "mobilephone",
      "address_line_1",
      "address_line_2",
      "city",
      "country",
      "nationality_1_",
      "company",
      "position",
      "industry_",
      "source_of_wealth",
      "expected_wealth_range",
      "bio_profile",
      "general_remarks",
      "preferred_channel",
      "school",
      "degree",
      "segment",
      "contact_type",
      "lifecyclestage",
      "lead_stage",
      "hs_lead_status",
      "financial_sophistication",
      "technology_sophistication",
      "building_details",
      "politically_exposed_persons_pep",
      "sharia_compliant",
      "expected_closing_probability",
      "amount_status",
      "expected_month_of_closing",
      "source_of_name",
      "preferred_time_of_day",
      "notes_last_contacted",
      "onboarding_id",
      "hs_avatar_filemanager_key",
      "hs_lifecyclestage_customer_date",
    ];
    getUserId().then((userId) => {
      setLoading(true);
      let payload: any = {
        sorts: [
          {
            propertyName: "notes_last_contacted",
            direction: "ASCENDING",
          },
        ],
        limit: 10,
        order: ["notes_last_contacted"],
        filterGroups: [
          {
            filters: [
              {
                operator: "EQ",
                propertyName: "hubspot_owner_id",
                value: parseInt(userId),
              },
              {
                operator: "NOT_HAS_PROPERTY",
                propertyName: "hs_lifecyclestage_customer_date",
              },
            ],
          },
        ],
        properties: properties,
      };
      api.filterProspectsAPI({ ...payload }).then((resp) => {
        if (resp) {
          const prospectCount = resp.total;
          sessionStorage.setItem("prospectCount", prospectCount);
        }
      });
    });
  };
  const getAllDeals = () => {
    setLoading(true);

    return api
      .getAllDeals()
      .then((response) => {
        if (response.stories.length > 0) {
          setDealsData(response.stories);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  let type = props.type;
  const style = {
    dealHeader: {
      fontFamily: "Gotham Book",
      fontWeight: 400,
      fontSize: 20,
      lineHeight: "24px",
      color: "#fff",
      marginBottom: 24,
    },
  };
  return (
    <IonRow class="margin-set dashgrid-mobile" style={{ marginTop: 40 }}>
      <IonLoading
        cssClass="my-custom-loading-class"
        isOpen={isLoading}
        onDidDismiss={() => setLoading(false)}
        message={"Please wait..."}
      />
      <IonCol size="12" style={style.dealHeader}>
        Active Deals
      </IonCol>
      <IonGrid style={{ padding: 0 }}>
        <IonRow style={{ padding: 0 }}>
          {dealsData.map((item, i) => (
            <IonCol
              style={{ paddingLeft: "0px" }}
              size="12"
              size-md="6"
              size-xl="4"
            >
              <DealCard
                imgSrc={""}
                subtitle={""}
                title={item.name}
                dealType={item.content.OpportunityType}
                routerPath={`/dealAnalysis/${item.id}`}
              />
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>
    </IonRow>
  );
}
