import React, { useEffect, useState } from "react";
import copyIcon from "src/icons/copyIcon.svg";
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonPage,
  IonButton,
  IonButtons,
  IonRow,
  IonIcon,
  IonCol,
  IonText,
  IonLoading,
  IonToast,
} from "@ionic/react";
import { checkmarkCircleOutline, closeOutline } from "ionicons/icons";
import { chevronBack } from "ionicons/icons";
import { RouteComponentProps, useHistory } from "react-router";
import VideoPlayer from "src/components/Video/video";
import api from "src/api";
import { setTimeout } from "timers";
import { fireBaseLog } from "src/components/Material/FireBase";
import moment from "moment";
interface WeeklyUpdateVideoProps
  extends RouteComponentProps<{
    id: string;
  }> {}

interface WeeklyUpdateVideoType {
  name: string;
  content: {
    VideoURL: { filename: string };
    PosterImage: { filename: string };
    PublishDate: string;
    Description: string;
    Title: string;
  };
}

const styles = {
  toolbar: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
  },
};

const WeeklyUpdateVideo: React.FC<WeeklyUpdateVideoProps> = (match) => {
  const [backButtonText, setBackButtonText] = useState<string>("Material ");
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isDataLoading, setDataLoading] = useState<boolean>(false);
  const [videoURL, setVideoURL] = useState<string>("");
  const [videoType, setVideoType] = useState<string>("video/mp4");
  const [showToast, setShowToast] = useState(false);
  const getId = match.match.params.id;
  const [videoPlayedTime, setVideoPlayedTime] = useState<number>(0);
  const [weeklyUpdateData, setWeeklyUpdateData] = useState<
    WeeklyUpdateVideoType
  >({
    name: "",
    content: {
      VideoURL: { filename: "" },
      PosterImage: {
        filename: "",
      },
      PublishDate: "",
      Description: "",
      Title: "",
    },
  });
  useEffect(() => {
    getWeeklyUpdateData();
  }, []);

  const handlePlay = () => {
    const playTime = new Date().getTime();
    setVideoPlayedTime(playTime);
  };
  const handlePause = () => {
    if (videoPlayedTime > 0) {
      const pauseTime = new Date().getTime();
      fireBaseLog({
        title: weeklyUpdateData.name,
        time_spent: (pauseTime - videoPlayedTime) / 1000,
      });
    }
  };
  const handleEnd = () => {
    if (videoPlayedTime > 0) {
      const endTime = new Date().getTime();
      fireBaseLog({
        title: weeklyUpdateData.name,
        time_spent: (endTime - videoPlayedTime) / 1000,
      });
    }
  };
  const getWeeklyUpdateData = () => {
    setLoading(true);
    return api
      .getWeeklyMarketDetails(parseInt(getId))
      .then((response) => {
        if (response.story) {
          const data = response.story;
          setWeeklyUpdateData(data);
          setVideoURL(data.content.VideoURL.filename);
          setLoading(false);
          setTimeout(() => {
            setDataLoading(true);
          }, 500);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode="md" style={styles.toolbar}>
          <IonButtons slot="start">
            <IonButton
              style={{ textTransform: "none" }}
              color="white"
              routerLink="/material"
            >
              <IonIcon icon={chevronBack}></IonIcon>
              {backButtonText}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent
        style={{
          "--background": "none",
        }}
      >
        <IonLoading
          cssClass="my-custom-loading-class"
          isOpen={isLoading}
          onDidDismiss={() => setLoading(false)}
          message={"Please wait..."}
        />

        <IonRow class="mt-1 margin-set" style={{ margin: "24px 32px" }}>
          <IonCol size="12" size-md="12" size-xl="12">
            <IonRow>
              <IonCol size="6" style={{ color: "#fff" }}>
                <IonText
                  style={{ display: "block", fontSize: 24, fontWeight: 300 }}
                >
                  {weeklyUpdateData.content.Title}
                </IonText>
              </IonCol>
              <IonCol
                size="4"
                size-md="6"
                size-xl="4"
                style={{
                  textAlign: "right",
                  color: "var(--ion-color-gold-v1)",
                }}
              >
                <IonText
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      weeklyUpdateData.content.VideoURL.filename
                    );
                    setShowToast(true);
                  }}
                >
                  <u>
                    {" "}
                    <IonIcon icon={copyIcon} /> Copy link
                  </u>
                </IonText>
                <IonToast
                  isOpen={showToast}
                  onDidDismiss={() => setShowToast(false)}
                  message="Link copied to clipboard"
                  buttons={[
                    {
                      side: "start",
                      icon: checkmarkCircleOutline,
                    },
                    {
                      icon: closeOutline,
                      role: "cancel",
                    },
                  ]}
                  duration={1500}
                  position="top"
                  cssClass="custom-toaster"
                />
              </IonCol>
            </IonRow>
            <IonCol
              style={{
                display: "block",
                paddingTop: 3,
              }}
            >
              <IonText
                style={{
                  fontSize: 14,
                  color: "#c7c7c7",
                  marginTop: 8,
                  fontWeight: 300,
                }}
              >
                Date:{" "}
                {moment(
                  weeklyUpdateData.content.PublishDate.slice(0, 10)
                ).format("DD/MM/YY")}
              </IonText>
            </IonCol>
            <IonCol
              style={{
                display: "block",
                paddingTop: 3,
                paddingBottom: 21,
              }}
            >
              <IonText
                style={{
                  fontSize: 14,
                  color: "#c7c7c7",
                  marginTop: 8,
                  fontWeight: 300,
                }}
              >
                {weeklyUpdateData.content.Description}
              </IonText>
            </IonCol>
            <IonRow>
              <IonCol size="10">
                {isDataLoading && (
                  <VideoPlayer
                    url={videoURL}
                    type={videoType}
                    autoplay={false}
                    poster={weeklyUpdateData.content.PosterImage.filename}
                    onEnd={handleEnd}
                    onPlay={handlePlay}
                    onPause={handlePause}
                  />
                )}
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default WeeklyUpdateVideo;
