import React, { useEffect, useState, useRef } from "react";
import {
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonLoading,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea,
  IonToolbar,
} from "@ionic/react";
import { Formik, FormikProps } from "formik";
import CryptoJS from "crypto-js";
import { chevronBack, chevronDown, ellipse } from "ionicons/icons";
import calendargold from "src/icons/calendargold.svg";
import "./NewTodo.css";
import moment from "moment";
import * as Yup from "yup";
import CustDatePicker from "src/components/CustDatePicker/CustDatePicker";
import { Plugins } from "@capacitor/core";
import api from "src/api";
import SearchforTodos from "src/pages/ProspectSearch/ProspectSearch";
import { useHistory } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import useWindowDimensions from "src/components/useWindowDimensions";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";

const { Storage, Device } = Plugins;

const decrypt = (decryptVlaue) => {
  let passphrase =
    "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9";
  const bytes = CryptoJS.AES.decrypt(decryptVlaue, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};
const getUserId = async () => {
  const ret: any = await Storage.get({ key: "userId" });
  const temp = JSON.parse(decrypt(ret.value));
  return temp.id;
};

const getUserEmail = async () => {
  const ret: any = await Storage.get({ key: "userId" });
  const temp = JSON.parse(decrypt(ret.value));
  return temp && temp.email ? temp.email : null;
};

const optionsA = {
  cssClass: "my-custom-interface",
};

const timePickerOptions: any = [
  { val: "0:00 AM", fVal: "00:00:00" },
  { val: "0:30 AM", fVal: "00:30:00" },
  { val: "1:00 AM", fVal: "01:00:00" },
  { val: "1:30 AM", fVal: "01:30:00" },
  { val: "2:00 AM", fVal: "02:00:00" },
  { val: "2:30 AM", fVal: "02:30:00" },
  { val: "3:00 AM", fVal: "03:00:00" },
  { val: "3:30 AM", fVal: "03:30:00" },
  { val: "4:00 AM", fVal: "04:00:00" },
  { val: "4:30 AM", fVal: "04:30:00" },
  { val: "5:00 AM", fVal: "05:00:00" },
  { val: "5:30 AM", fVal: "05:30:00" },
  { val: "6:00 AM", fVal: "06:00:00" },
  { val: "6:30 AM", fVal: "06:30:00" },
  { val: "7:00 AM", fVal: "07:00:00" },
  { val: "7:30 AM", fVal: "07:30:00" },
  { val: "8:00 AM", fVal: "08:00:00" },
  { val: "8:30 AM", fVal: "08:30:00" },
  { val: "9:00 AM", fVal: "09:00:00" },
  { val: "9:30 AM", fVal: "09:30:00" },
  { val: "10:00 AM", fVal: "10:00:00" },
  { val: "10:30 AM", fVal: "10:30:00" },
  { val: "11:00 AM", fVal: "11:00:00" },
  { val: "11:30 AM", fVal: "11:30:00" },
  { val: "12:00 PM", fVal: "12:00:00" },
  { val: "12:30 PM", fVal: "12:30:00" },
  { val: "1:00 PM", fVal: "13:00:00" },
  { val: "1:30 PM", fVal: "13:30:00" },
  { val: "2:00 PM", fVal: "14:00:00" },
  { val: "2:30 PM", fVal: "14:30:00" },
  { val: "3:00 PM", fVal: "15:00:00" },
  { val: "3:30 PM", fVal: "15:30:00" },
  { val: "4:00 PM", fVal: "16:00:00" },
  { val: "4:30 PM", fVal: "16:30:00" },
  { val: "5:00 PM", fVal: "17:00:00" },
  { val: "5:30 PM", fVal: "17:30:00" },
  { val: "6:00 PM", fVal: "18:00:00" },
  { val: "6:30 PM", fVal: "18:30:00" },
  { val: "7:00 PM", fVal: "19:00:00" },
  { val: "7:30 PM", fVal: "19:30:00" },
  { val: "8:00 PM", fVal: "20:00:00" },
  { val: "8:30 PM", fVal: "20:30:00" },
  { val: "9:00 PM", fVal: "21:00:00" },
  { val: "9:30 PM", fVal: "21:30:00" },
  { val: "10:00 PM", fVal: "22:00:00" },
  { val: "10:30 PM", fVal: "22:30:00" },
  { val: "11:00 PM", fVal: "23:00:00" },
  { val: "11:30 PM", fVal: "23:30:00" },
];

function camelize(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toUpperCase() : word.toLowerCase();
    })
    .replace(/\s+/g, "");
}

const styles = {
  rootGrid: {
    marginLeft: 32,
    marginRight: 32,
  },
  toolbar: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
    height: 75,
  },
  HeaderRow: {
    marginTop: 24,
    marginBottom: 24,
  },
  Header: {
    fontSize: 28,
  },
  avatar: {
    width: 48,
    height: 48,
  },
  deftext: {
    fontSize: 14,
    marginTop: "auto",
    marginBottom: "auto",
    marginLeft: 16,
    visibility: "visible",
  },
  engname: {
    fontSize: 14,
    fontWeight: 700,
    marginTop: "auto",
    marginBottom: "auto",
    marginLeft: 16,
    visibility: "visible",
  },
  arabname: {
    fontSize: 14,
    fontFamily: "Almarai",
    marginTop: "auto",
    marginBottom: "auto",
  },
  period: {
    marginTop: "auto",
    marginBottom: "auto",
    marginLeft: 8,
    marginRight: 8,
    width: 8,
    height: 8,
  },
  addrembtn: {
    marginLeft: 64,
    "--border-radius": "2px",
    textTransform: "none",
    width: 180,
  },
  subbtn: {
    "--border-radius": "2px",
    textTransform: "none",
    width: 180,
  },
  listheadertext: {
    fontSize: 14,
    fontWeight: "normal",
    marginTop: 0,
  },
  radios: {
    fontSize: 14,
  },
};

interface NewTodoProps
  extends RouteComponentProps<{
    contactId?: string;
    selTodoObj: any;
  }> {}

const NewTodo: React.FC<NewTodoProps> = ({ match, location }) => {
  const [togSrch, settogSrch] = useState<boolean>(false);
  const [selcontid, setselcontid] = useState<number>(0);
  const [selcontObj, setselcontObj] = useState<any>();
  const [contIderror, setcontIderror] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [maintitle, setmaintitle] = useState<string>("New To-Do");
  const [buttonText, setButtonText] = useState<string>("Add To-Do");
  const [backtext, setbacktext] = useState<string>("To-Do");
  const [todoValues, setTodoValues] = useState<any>();
  const [taskTitleErr, setTaskTitleErr] = useState<boolean>(true);
  const [dueDateErr, setDueDateErr] = useState<boolean>(true);

  const hist = useHistory();
  const b: any = location.state;
  const matchContId = b?.contactId;

  // const formikRef: = useRef();
  const formikRef = React.useRef<FormikProps<any>>(null);

  useEffect(() => {
    // console.log(matchContId);
    setTaskTitleErr(false);
    setDueDateErr(false);

    Device.getInfo().then((deviceInfo) => {
      getUserEmail().then((useremail) => {
        if (deviceInfo.platform == "web") {
          window.globalThis.firebase.analytics().setUserId(useremail);
          window.globalThis.firebase.analytics().logEvent("select_content", {
            user_name: useremail,
            content_type: "NewTask",
            item_id: matchContId,
            device_id: deviceInfo ? deviceInfo.uuid : "",
            platform: deviceInfo ? deviceInfo.platform : "",
            device_model: deviceInfo ? deviceInfo.model : "",
            device_type: deviceInfo ? deviceInfo.osVersion : "",
            device_os: deviceInfo ? deviceInfo.operatingSystem : "",
          });
        } else {
          FirebaseAnalytics.setUserId(useremail);
          FirebaseAnalytics.logEvent("select_content", {
            user_name: useremail,
          });
          FirebaseAnalytics.logEvent("select_content", {
            content_type: "NewTask",
          });
          FirebaseAnalytics.logEvent("select_content", {
            item_id: matchContId,
          });
          FirebaseAnalytics.logEvent("select_content", {
            device_id: deviceInfo ? deviceInfo.uuid : "",
          });
          FirebaseAnalytics.logEvent("select_content", {
            platform: deviceInfo ? deviceInfo.platform : "",
          });
          FirebaseAnalytics.logEvent("select_content", {
            device_model: deviceInfo ? deviceInfo.model : "",
          });
          FirebaseAnalytics.logEvent("select_content", {
            device_type: deviceInfo ? deviceInfo.osVersion : "",
          });
          FirebaseAnalytics.logEvent("select_content", {
            device_os: deviceInfo ? deviceInfo.operatingSystem : "",
          });
        }
      });
    });

    if (location.state) {
      let a: any = location.state;

      if (a.selTodoObj) {
        setmaintitle("Edit To-Do");
        setButtonText("Update To-Do");

        formikRef.current?.setFieldValue(
          "taskTitle",
          a.selTodoObj.metadata.subject
        );
        formikRef.current?.setFieldValue(
          "dueDate",
          a.selTodoObj.engagement.timestamp
        );
        formikRef.current?.setFieldValue(
          "dueTime",
          //  moment(a.selTodoObj.engagement.timestamp).format("h:ss A")
          moment(a.selTodoObj.engagement.timestamp).format("HH:mm:ss")
        );
        formikRef.current?.setFieldValue(
          "taskNotes",
          a.selTodoObj.engagement.bodyPreview.replace(/(<([^>]+)>)/gi, "")
        );
        formikRef.current?.setFieldValue(
          "emailReminder",
          a.selTodoObj.metadata.reminders[0]
        );
        formikRef.current?.setFieldValue(
          "reminderTime",
          //  moment(a.selTodoObj.engagement.timestamp).format("h:ss A")
          moment(a.selTodoObj.metadata.reminders[0]).format("HH:mm:ss")
        );
        formikRef.current?.setFieldValue(
          "taskType",
          a.selTodoObj.metadata.taskType
        );
        formikRef.current?.setFieldValue(
          "priority",
          a.selTodoObj.metadata.priority ? camelize(a.selTodoObj.metadata.priority) : 'NONE'
        );
      }

      if (matchContId && a.source === "Client") {
        setbacktext("Client Profile");
      } else if (matchContId && a.source === "Prospect") {
        setbacktext("Prospect Profile");
      } else {
        setbacktext("To-Do");
      }
    }

    if (matchContId) {
      api.getContactDetails(matchContId).then((response) => {
        // console.log(response)
        if (response && response.properties) {
          setselcontObj(response);
          setselcontid(parseInt(response.id));
        } else {
          setselcontid(0);
          setselcontObj({});
        }
      });
    }

    if (todoValues && todoValues.taskTitle != "") setTaskTitleErr(false);
    if (todoValues) setDueDateErr(false);
  }, [matchContId, location.state, todoValues]);
  //}, [matchContId, location.state]);

  const searchF = () => {
    settogSrch(true);
  };

  const clearProspSelection = () => {
    setselcontid(0);
    setselcontObj({});
  };

  const handleSubmit = (values, errors) => {
    if (values.dueDate < moment().startOf("day")) {
      errors.setErrors({
        dueDateErr: " (Due date must be greater than todays)",
      });
    } else {
      if (selcontid !== 0) {
        if (maintitle === "New To-Do") {
          setLoading(true);
          getUserId().then((userId) => {
            const convertDateTime = (date, time) => {
              let dateTimeStr = date + " " + time;
              let DateTimeDt = new Date(dateTimeStr);
              let dateTimeUTC = DateTimeDt.toUTCString();
              let isounixVal = parseInt(moment(dateTimeUTC).format("x"));
              return isounixVal;
            };
            const taskPayload = {
              engagement: {
                active: true,
                type: "TASK",
                ownerId: userId,
                timestamp: convertDateTime(
                  moment(values.dueDate).format("YYYY-MM-DD"),
                  values.dueTime
                ),
                priority: values.priority,
              },
              associations: {
                contactIds: [selcontid],
                companyIds: [],
                dealIds: [],
                ownerIds: [],
                ticketIds: [],
              },
              metadata: {
                status: "NOT_STARTED",
                subject: values.taskTitle,
                body: values.taskNotes,
                taskType: values.taskType,
                forObjectType: "CONTACT",
                reminders: [
                  convertDateTime(
                    moment(values.emailReminder).format("YYYY-MM-DD"),
                    values.reminderTime
                  ),
                ],
              },
            };

            api
              .createTask(taskPayload)
              .then((resp) => {
                // console.log(resp)
                setLoading(false);
                matchContId
                  ? hist.push(`/contactinfo/${matchContId}`, {
                      to: "todos",
                    })
                  : hist.push("/todo");
              })
              .catch((e) => {
                console.log("Error calling the API: ", e);
              });
          });
        } else {
          setLoading(true);
          getUserId().then((userId) => {
            const convertDateTime = (date, time) => {
              let dateTimeStr = date + " " + time;
              let DateTimeDt = new Date(dateTimeStr);
              let dateTimeUTC = DateTimeDt.toUTCString();
              let isounixVal = parseInt(moment(dateTimeUTC).format("x"));
              return isounixVal;
            };
            const patchTaskPayload = {
              engagement: {
                active: true,
                type: "TASK",
                ownerId: userId,
                timestamp: convertDateTime(
                  moment(values.dueDate).format("YYYY-MM-DD"),
                  values.dueTime
                ),
                priority: values.priority,
              },
              associations: {
                contactIds: [selcontid],
                companyIds: [],
                dealIds: [],
                ownerIds: [],
                ticketIds: [],
              },
              metadata: {
                status: "NOT_STARTED",
                subject: values.taskTitle,
                body: values.taskNotes,
                taskType: values.taskType,
                forObjectType: "CONTACT",
                reminders: [
                  convertDateTime(
                    moment(values.emailReminder).format("YYYY-MM-DD"),
                    values.reminderTime
                  ),
                ],
              },
            };
            let a: any = location.state;
            api
              .patchTask(a.selTodoObj.engagement.id, patchTaskPayload)
              .then((resp) => {
                // console.log(resp)
                setLoading(false);
                matchContId
                  ? hist.push(`/contactinfo/${matchContId}`, {
                      to: "todos",
                    })
                  : hist.push("/todo");
              })
              .catch((e) => {
                console.log("Error calling the API: ", e);
              });
          });
        }
      } else {
        setcontIderror(true);
      }
    }
  };

  function tododata(returnval) {
    setTodoValues(returnval);
    setTaskTitleErr(false);
    setDueDateErr(false);
  }

  return !togSrch ? (
    <IonPage>
      <IonLoading
        cssClass="my-custom-loading-class"
        isOpen={isLoading}
        message={"Please wait..."}
      />
      <IonHeader>
        <IonToolbar mode="md" style={styles.toolbar}>
          <IonButtons slot="start">
            <IonButton
              style={{ textTransform: "none" }}
              color="white"
              // routerLink="/todo"
              onClick={() => {
                hist.goBack();
              }}
            >
              <IonIcon icon={chevronBack}></IonIcon>
              {backtext}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Formik
          innerRef={formikRef}
          // enableReinitialize
          initialValues={{
            taskTitle: "",
            dueDate: moment()
              .startOf("day")
              .add(3, "days"),
            dueTime: "10:00:00",
            taskNotes: "",
            emailReminder: moment()
              .startOf("day")
              .add(2, "days"),
            reminderTime: "10:00:00",
            taskType: "TODO",
            priority: "None",
          }}
          onSubmit={handleSubmit}
          validationSchema={Yup.object().shape({
            taskTitle: Yup.string()
              .max(255)
              .required("Please enter a title for the task"),
          })}
        >
          {({
            values,
            errors,
            handleSubmit,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit}>
              <IonGrid class="ml-13 mr-13 mt-6" style={styles.rootGrid}>
                <ProspSelect
                  selcontid={selcontid}
                  search={searchF}
                  selcontObj={selcontObj}
                  clearProspSelection={clearProspSelection}
                  contIderror={contIderror}
                  mainTitle={maintitle}
                  values={values}
                  tododata={tododata}
                />
                <Form1
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  todoData={todoValues}
                  taskTitleErr={taskTitleErr}
                  dueDateErr={dueDateErr}
                />
                <Form2
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  todoData={todoValues}
                />
                <IonButton
                  className="m-marginleft-12 mobileML14"
                  fill="outline"
                  style={styles.subbtn}
                  color="gold"
                  mode="md"
                  type="submit"
                >
                  {buttonText}
                </IonButton>
              </IonGrid>
            </form>
          )}
        </Formik>
      </IonContent>
    </IonPage>
  ) : (
    <SearchforTodos
      // taskTitle={formikRef?.current?.values.taskTitle}
      forTodo={true}
      closeSrch={() => {
        settogSrch(false);
      }}
      setContProps={(obj) => {
        setselcontObj(obj);
        setselcontid(parseInt(obj.id));
        settogSrch(false);
        setcontIderror(false);
      }}
      // sendDataToParent={(obj1)=> {
      //   setTodoValues(obj1);
      // }
      //  }
    />
  );
};

// const ProspSelect: React.FC<{
//   selcontid: number;
//   search: any;
//   selcontObj: any;
//   clearProspSelection: any;
//   contIderror: any;
//   mainTitle: string;
// }> = ({
//   selcontid,
//   search,
//   selcontObj,
//   clearProspSelection,
//   contIderror,
//   mainTitle,
// }) => {
const ProspSelect = (props) => {
  const { height, width } = useWindowDimensions();
  return (
    <React.Fragment>
      <IonRow style={styles.HeaderRow} className="mobileML14">
        <IonLabel class="fs-28" style={styles.Header} color="greyscale">
          {props.mainTitle}
        </IonLabel>
      </IonRow>
      <IonRow className="mobileML14">
        <IonCol size="1">
          <IonAvatar style={styles.avatar}>
            <img
              src={
                props.selcontObj &&
                props.selcontObj.properties &&
                props.selcontObj.properties.hs_avatar_filemanager_key
                  ? `https://cdn2.hubspot.net/${props.selcontObj.properties.hs_avatar_filemanager_key}`
                  : "/assets/empty-avatar.svg"
              }
            />
          </IonAvatar>
        </IonCol>
        <IonCol size="11" style={{ margin: "auto" }}>
          {props.selcontid === 0 ? (
            <IonLabel
              color="greyscale"
              style={styles.deftext}
              className="mobileML36"
            >
              Add prospect or client to this To-Do task
            </IonLabel>
          ) : (
            <React.Fragment>
              <IonLabel
                class="fs-14"
                color="white"
                style={styles.engname}
                className="mobileML36"
              >
                {props.selcontObj.properties.middle_name !== "" &&
                props.selcontObj.properties.middle_name !== null
                  ? `${props.selcontObj.properties.firstname} ${props.selcontObj.properties.middle_name} ${props.selcontObj.properties.lastname}`
                  : `${props.selcontObj.properties.firstname} ${props.selcontObj.properties.lastname}`}
              </IonLabel>
              {width > 575 ? (
                <IonIcon
                  icon={ellipse}
                  size="small"
                  color="greyscale"
                  style={styles.period}
                  className="elipse-mobile"
                ></IonIcon>
              ) : (
                <p></p>
              )}
              <IonLabel
                class="fs-14"
                color="greyscale"
                style={styles.arabname}
                className="mobileML36"
              >
                {props.selcontObj.properties.arabic_middle_name !== "" &&
                props.selcontObj.properties.arabic_middle_name !== null
                  ? `${props.selcontObj.properties.first_name_arabic} ${props.selcontObj.properties.arabic_middle_name} ${props.selcontObj.properties.arabic_last_name}`
                  : `${props.selcontObj.properties.first_name_arabic} ${props.selcontObj.properties.arabic_last_name}`}
              </IonLabel>
            </React.Fragment>
          )}
        </IonCol>
        <IonText class="ion-padding-top fs-12" style={{ marginLeft: "auto" }}>
          {"* = required"}
        </IonText>
      </IonRow>
      <IonRow>
        {props.selcontid === 0 ? (
          <IonButton
            fill="solid"
            style={styles.addrembtn}
            color="greyscaleBtn"
            mode="md"
            onClick={() => {
              props.tododata(props.values);
              props.search();
            }}
          >
            Search
          </IonButton>
        ) : (
          <IonButton
            fill="solid"
            style={styles.addrembtn}
            color="greyscaleBtn"
            mode="md"
            onClick={() => {
              props.clearProspSelection();
            }}
          >
            Remove
          </IonButton>
        )}
      </IonRow>
      {props.contIderror ? (
        <IonRow style={{ marginLeft: 64 }}>
          <IonText color="danger">Please select a Prospect</IonText>
        </IonRow>
      ) : null}
    </React.Fragment>
  );
};

const Form1: React.FC<{
  values: any;
  errors: any;
  handleChange: any;
  handleBlur: any;
  touched: any;
  setFieldValue: any;
  setFieldTouched: any;
  todoData?: any;
  taskTitleErr?: boolean;
  dueDateErr?: boolean;
}> = ({
  values,
  errors,
  handleChange,
  handleBlur,
  touched,
  setFieldValue,
  setFieldTouched,
  todoData,
  taskTitleErr,
  dueDateErr,
}) => {
  const { height, width } = useWindowDimensions();

  values.taskTitle =
    values.taskTitle != "" ? values.taskTitle : todoData?.taskTitle;
  taskTitleErr = Boolean(errors.taskTitle && touched.taskTitle) ? true : false;

  values.dueDate = todoData ? todoData.dueDate : values.dueDate;
  dueDateErr = Boolean(errors.dueDate && touched.dueDate) ? true : false;

  values.dueTime =
    values.dueTime === "10:00:00" && todoData
      ? todoData.dueTime
      : values.dueTime;

  values.taskType =
    values?.taskType === "TODO" && todoData
      ? todoData.taskType
      : values.taskType;
  values.priority =
    values?.priority === "None" && todoData
      ? todoData.priority
      : values.priority;

  return (
    <IonRow className="form-row root-form-row mobileML14">
      <IonCol size-xs="12" sizeLg="7" sizeSm="7">
        <IonLabel className="m-marginleft-8">* Task Title</IonLabel>
        {Boolean(taskTitleErr || values.taskTitle === "") ? (
          <IonText
            color="danger"
            style={{ marginLeft: 16 }}
          >{`(Task title is required)`}</IonText>
        ) : null}
        <IonItem
          className="m-marginleft-8 m-marginright-8 input-items"
          lines="none"
        >
          <IonInput
            name="taskTitle"
            value={values.taskTitle}
            onIonChange={(e) => {
              values.taskTitle = e.detail.value;
              if (todoData) todoData.taskTitle = "";
            }}
            // onIonChange={handleChange}
            onIonBlur={handleBlur}
            placeholder="Enter the title of your task"
            spellcheck={true}
            autocapitalize="sentences"
          />
        </IonItem>
        <IonLabel className="m-marginleft-8">* Due Date</IonLabel>
        {/* {Boolean(errors.dueDate && touched.dueDate) ? (
          <IonText
            color="danger"
            style={{ marginLeft: 16 }}
          >{`(Due date must be greater than today)`}</IonText>
        ) : null} */}
        {Boolean(dueDateErr) ? (
          <IonText
            color="danger"
            style={{ marginLeft: 16 }}
          >{`(Due Date must be greater than today)`}</IonText>
        ) : null}
        {Boolean(errors.dueDateErr) ? (
          <IonText
            color="danger"
            style={{ marginLeft: 16 }}
          >{`(Due Date must be greater than today)`}</IonText>
        ) : null}
        <IonRow className="form-row">
          <IonCol size-xs="12" sizeLg="9" sizeSm="8">
            <IonItem
              className="input-items m-marginleft-8 m-marginright-4"
              lines="none"
            >
              {/* <IonInput
                                    name="dueDate"
                                    value={values.dueDate}
                                    onIonChange={handleChange}
                                    onIonBlur={handleBlur}
                                /> */}
              <CustDatePicker
                // dateFormat="MMM DD, YYYY"
                dateFormat={
                  moment(values.dueDate)
                    .startOf("day")
                    .format("x") ===
                  moment()
                    .startOf("day")
                    .format("x")
                    ? `[Today (]dddd[)]`
                    : `[${moment(values.dueDate).from(
                        moment().startOf("day"),
                        false
                      )} (]dddd[)]`
                }
                date={values.dueDate}
                InputClassName="meetingdatentime tfo-calendar-picker-input tfo-input"
                onSetDateVal={(val) => {
                  setFieldValue("dueDate", moment(val).startOf("day"));
                  setFieldTouched("dueDate", true);

                  if (todoData) todoData.dueDate = moment(val).startOf("day");
                }}
              />
              <IonIcon
                src={calendargold}
                className="endchevron"
                style={{ width: 24, height: 24 }}
              ></IonIcon>
            </IonItem>
          </IonCol>
          <IonCol size-xs="12" sizeLg="3" sizeSm="4">
            <IonItem
              className="m-marginright-8 input-items m-marginL-m5"
              lines="none"
            >
              <IonSelect
                interface={width < 576 ? "action-sheet" : "popover"}
                interfaceOptions={optionsA}
                mode="ios"
                name="dueTime"
                value={values.dueTime}
                onIonChange={handleChange}
                onIonBlur={handleBlur}
              >
                {/* {timePickerOptions.map(({ val }, i) => (
                  <IonSelectOption key={i} value={val}>
                    {val}
                  </IonSelectOption>
                ))} */}
                {timePickerOptions.map((val, i) => (
                  <IonSelectOption key={i} value={val.fVal}>
                    {val.val}
                  </IonSelectOption>
                ))}
              </IonSelect>
              <IonIcon
                className="endchevron"
                color="gold"
                src={chevronDown}
              ></IonIcon>
            </IonItem>
          </IonCol>
        </IonRow>
      </IonCol>
      <IonCol pushLg="1" size-xs="12" sizeSm="5" sizeLg="4">
        <IonRow className="m-marginbottom16 m-margintopn16">
          <IonCol size="6">
            <IonList className="newtodo-radio-list">
              <IonRadioGroup
                name="taskType"
                value={values.taskType}
                //  onIonChange={handleChange}
                onIonChange={(e) => {
                  values.taskType = e.detail.value;
                  if (todoData) todoData.taskType = "TODO";
                }}
              >
                <IonListHeader>
                  <IonLabel style={styles.listheadertext}>Type</IonLabel>
                </IonListHeader>
                <IonItem className="newtodo-radio-item" lines="none">
                  <IonRadio
                    color="actblue"
                    slot="start"
                    value="CALL"
                    mode="md"
                  />
                  <IonLabel
                    color={values.taskType === "CALL" ? "white" : "greyc7"}
                    style={styles.radios}
                  >
                    Call
                  </IonLabel>
                </IonItem>
                <IonItem className="newtodo-radio-item" lines="none">
                  <IonRadio
                    color="actblue"
                    slot="start"
                    value="EMAIL"
                    mode="md"
                  />
                  <IonLabel
                    color={values.taskType === "EMAIL" ? "white" : "greyc7"}
                    style={styles.radios}
                  >
                    Email
                  </IonLabel>
                </IonItem>
                <IonItem className="newtodo-radio-item" lines="none">
                  <IonRadio
                    color="actblue"
                    slot="start"
                    value="TODO"
                    mode="md"
                  />
                  <IonLabel
                    color={values.taskType === "TODO" ? "white" : "greyc7"}
                    style={styles.radios}
                  >
                    To-do
                  </IonLabel>
                </IonItem>
              </IonRadioGroup>
            </IonList>
          </IonCol>
          <IonCol size="6">
            <IonList className="newtodo-radio-list">
              <IonRadioGroup
                name="priority"
                value={values.priority}
                //  onIonChange={handleChange}
                onIonChange={(e) => {
                  values.priority = e.detail.value;
                  if (todoData) todoData.priority = "None";
                }}
              >
                <IonListHeader>
                  <IonLabel style={styles.listheadertext}>Priority</IonLabel>
                </IonListHeader>
                <IonItem className="newtodo-radio-item" lines="none">
                  <IonRadio
                    color="actblue"
                    slot="start"
                    value="None"
                    mode="md"
                  />
                  <IonLabel
                    color={values.priority === "None" ? "white" : "greyc7"}
                    style={styles.radios}
                  >
                    None
                  </IonLabel>
                </IonItem>
                {/* <IonItem className="newtodo-radio-item" lines="none">
                  <IonRadio
                    color="actblue"
                    slot="start"
                    value="Low"
                    mode="md"
                  />
                  <IonLabel
                    color={values.priority === "Low" ? "white" : "greyc7"}
                    style={styles.radios}
                  >
                    Low
                  </IonLabel>
                </IonItem>
                <IonItem className="newtodo-radio-item" lines="none">
                  <IonRadio
                    color="actblue"
                    slot="start"
                    value="Medium"
                    mode="md"
                  />
                  <IonLabel
                    color={values.priority === "Medium" ? "white" : "greyc7"}
                    style={styles.radios}
                  >
                    Medium
                  </IonLabel>
                </IonItem> */}
                <IonItem className="newtodo-radio-item" lines="none">
                  <IonRadio
                    color="actblue"
                    slot="start"
                    value="High"
                    mode="md"
                  />
                  <IonLabel
                    color={values.priority === "High" ? "white" : "greyc7"}
                    style={styles.radios}
                  >
                    High
                  </IonLabel>
                </IonItem>
              </IonRadioGroup>
            </IonList>
          </IonCol>
        </IonRow>
      </IonCol>
    </IonRow>
  );
};

const Form2: React.FC<{
  values: any;
  errors: any;
  handleChange: any;
  handleBlur: any;
  touched: any;
  setFieldValue: any;
  setFieldTouched: any;
  todoData: any;
}> = ({
  values,
  errors,
  handleChange,
  handleBlur,
  touched,
  setFieldValue,
  setFieldTouched,
  todoData,
}) => {
  const { height, width } = useWindowDimensions();

  values.taskNotes =
    values.taskNotes != "" ? values.taskNotes : todoData?.taskNotes;

  values.emailReminder = todoData
    ? todoData.emailReminder
    : values.emailReminder;
  values.reminderTime =
    values.reminderTime === "10:00:00" && todoData
      ? todoData.reminderTime
      : values.reminderTime;

  return (
    <IonRow className="form-row mobileML14">
      <IonCol size="12">
        <IonLabel className="m-marginleft-8">Task Notes</IonLabel>
        <IonItem
          className="input-items m-marginleft-8 m-marginright-8"
          lines="none"
        >
          <IonTextarea
            name="taskNotes"
            value={values.taskNotes}
            // onIonChange={handleChange}
            onIonChange={(e) => {
              values.taskNotes = e.detail.value;
              if (todoData) todoData.taskNotes = "";
            }}
            onIonBlur={handleBlur}
            rows={5}
            spellcheck={true}
            placeholder="Enter any notes you have concerning this task"
            autocapitalize="sentences"
          />
        </IonItem>
      </IonCol>
      <IonCol size-xs="12" sizeLg="7" sizeSm="7">
        <IonLabel className="m-marginleft-8">Email Reminder</IonLabel>
        <IonRow className="form-row">
          <IonCol size-xs="12" sizeLg="9" sizeSm="8">
            {/*  <IonItem className="input-items" lines="none">
                                <IonInput
                                    name="emailReminder"
                                    value={values.emailReminder}
                                    onIonChange={handleChange}
                                    onIonBlur={handleBlur}
                                />
                            </IonItem> */}

            <IonItem
              className="m-marginleft-8 input-items m-marginright-8"
              lines="none"
            >
              <CustDatePicker
                // dateFormat="MMM DD, YYYY"
                dateFormat={
                  moment(values.emailReminder)
                    .startOf("day")
                    .format("x") ===
                  moment(values.dueDate)
                    .startOf("day")
                    .format("x")
                    ? `[On Due Date]`
                    : moment(values.emailReminder)
                        .startOf("day")
                        .format("x") >
                      moment(values.dueDate)
                        .add(1, "days")
                        .startOf("day")
                        .format("x")
                    ? `[${moment(values.emailReminder).from(
                        moment(values.dueDate).startOf("day"),
                        true
                      )} after (]dddd[)]`
                    : `[${moment(values.emailReminder).from(
                        moment(values.dueDate).startOf("day"),
                        true
                      )} before (]dddd[)]`
                }
                date={values.emailReminder}
                InputClassName="meetingdatentime tfo-calendar-picker-input tfo-input"
                onSetDateVal={(val) => {
                  setFieldValue("emailReminder", moment(val).startOf("day"));
                  setFieldTouched("emailReminder", true);

                  if (todoData)
                    todoData.emailReminder = moment(val).startOf("day");
                }}
              />
              <IonIcon
                src={calendargold}
                className="endchevron"
                style={{ width: 24, height: 24 }}
              ></IonIcon>
            </IonItem>
          </IonCol>
          <IonCol size-xs="12" sizeLg="3" sizeSm="4">
            <IonItem
              className="input-items m-marginright-12 m-marginL-m5"
              lines="none"
            >
              <IonSelect
                interface={width < 576 ? "action-sheet" : "popover"}
                interfaceOptions={optionsA}
                mode="ios"
                name="reminderTime"
                value={values.reminderTime}
                onIonChange={handleChange}
                onIonBlur={handleBlur}
              >
                {/* {timePickerOptions.map(({ val }, i) => (
                  <IonSelectOption key={i} value={val}>
                    {val}
                  </IonSelectOption>
                ))} */}
                {timePickerOptions.map((val, i) => (
                  <IonSelectOption key={i} value={val.fVal}>
                    {val.val}
                  </IonSelectOption>
                ))}
              </IonSelect>
              <IonIcon
                className="endchevron"
                color="gold"
                src={chevronDown}
              ></IonIcon>
            </IonItem>
          </IonCol>
        </IonRow>
      </IonCol>
    </IonRow>
  );
};

export default NewTodo;
