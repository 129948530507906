import React, {useState} from 'react';
import { IonPopover, IonList, IonItem } from '@ionic/react';
import "./EditAssocPopover.css"
import DeleteConfirm from "./AssocDeleteConfirm"
import { useHistory } from "react-router-dom";
const styles = {
    list: {
        padding: 0,
        
    },
    item: {
        "--background:": "#1a1a1a",
        fontSize:14
    }
}

interface MenuProps {
    open: boolean;
    event: Event | undefined;
    setShowPopover: Function
    assocId:string;
    srcProsId:string;
    contactId:string;
    delRefreshNotes: Function;
}

interface ShwConfState {
    open: boolean;
    event: Event | undefined;
  }

const EditAssocMenu: React.FC<MenuProps> = ({
    open,
    event,
    setShowPopover,
    assocId,
    contactId,
    srcProsId,
    delRefreshNotes
}) => {

    const delRefresh = () => {
        delRefreshNotes()
    }
    const history = useHistory();
    const [showConfirm, setShowConfirm] = useState<ShwConfState>({
        open: false,
        event: undefined,
      });

    return (
        <React.Fragment>
        <IonPopover
            isOpen={open}
            showBackdrop={true}
            cssClass='popover-class-A'
            event={event}
            onDidDismiss={e => setShowPopover({ open: false, event: undefined })}
            mode="ios"
        >
            <IonList style={styles.list} color="step-100">
                <IonItem detailIcon="none" lines="full" style={styles.item} 
                //</IonList>routerLink={`/association/edit/${contactId}/${srcProsId}/${assocId}`
                onClick={(e)=>history.push({
                    pathname: '/association/edit',
                    state: {contactId:contactId,srcProsId:srcProsId,assocId:assocId}
                  })
                }>Edit</IonItem>
                <IonItem style={styles.item} lines="none" onClick={(e) => {
                    setShowConfirm({ open: true, event: undefined}) 
                    setShowPopover({ open: false, event: undefined }) 
                }}>Delete</IonItem>
            </IonList>
        </IonPopover>
            <DeleteConfirm
            open={showConfirm.open}
            event={undefined}
            setShowPopover={() => setShowConfirm({ open: false, event: undefined})}
            noteId={assocId}
            delRefresh={delRefresh}
        />
        </React.Fragment>
    );
}
export default EditAssocMenu