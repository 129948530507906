import {
  IonLabel,
  IonItem,
  IonIcon,
  IonText,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonLoading,
} from "@ionic/react";

import React, { useEffect, useState } from "react";
import "./Todos.css";
import todoIcon from "src/icons/todoIcon.svg";
import todoIconIncomplete from "src/icons/todoIconIncomplete.svg";
import { ellipse } from "ionicons/icons";
import moment from "moment";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import api from "src/api";
// import TodoData from "./TodoData"

// const today = new Date();

function camelize(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toUpperCase() : word.toLowerCase();
    })
    .replace(/\s+/g, "");
}

const groupBy = (array, key) => {
  return array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    if (!result[currentValue[key]]) {
      result[currentValue[key]] = [];
    }
    return result;
  }, {});
};

const Todos: React.FC<any> = ({
  announcement,
  engagements,
  contactcall,
  contactemail,
  contProps,
  refreshEng,
}) => {
  const [monthsAll, setmonthsAll] = useState<any>([]);
  const [monthsGrouped, setmonthsGrouped] = useState<any>([]);

  /* const monthsAll = TodoData.map(todo => {
    return {
      ...todo,
      "Month": moment(todo.dueDate).format("MMMM"),
      "MonthNum": moment(todo.dueDate).format("M"),
      "DayNum": moment(todo.dueDate).format("DD")
    }
  }) */

  useEffect(() => {
    if (engagements) {
      const res = engagements.results;
      if (res.length > 0) {
        setmonthsAll(
          res
            .filter((r) => r.engagement.type === "TASK")
            .sort((a, b) => {
              return a.engagement.timestamp > b.engagement.timestamp ? 1 : -1;
            })
            .map((obj) => {
              return {
                ...obj,
                Month: moment(obj.engagement.timestamp).format("MMMM"),
                MonthNum: moment(obj.engagement.timestamp).format("M"),
                DayNum: moment(obj.engagement.timestamp).format("DD"),
              };
            })
        );
      }
    }
  }, [engagements]);

  useEffect(() => {
    if (monthsAll.length > 0) {
      setmonthsGrouped(groupBy(monthsAll, "Month"));
    }
  }, [monthsAll]);

  return monthsGrouped && monthsGrouped !== null ? (
    <React.Fragment>
      {Object.keys(monthsGrouped).map((monthName) => (
        <React.Fragment key={monthName}>
          <IonItem className="fs-16 todoMonthItem ml-13  mr-13" lines="none">
            {moment(monthsGrouped[monthName][0].engagement.timestamp).format(
              "MMMM"
            ) +
              " " +
              moment(monthsGrouped[monthName][0].engagement.timestamp).format(
                "YYYY"
              )}
          </IonItem>

          <MonthGrid
            refreshEng={refreshEng}
            contProps={contProps}
            contactemail={contactemail}
            contactcall={contactcall}
            TodosMonth={monthsGrouped[monthName]}
          />
        </React.Fragment>
      ))}
    </React.Fragment>
  ) : null;
};

const MonthGrid = ({
  TodosMonth,
  contactcall,
  contactemail,
  contProps,
  refreshEng,
}) => {
  const daysGrouped = groupBy(TodosMonth, "DayNum");
  return (
    <IonGrid class="ml-12 mr-13">
      {Object.keys(daysGrouped)
        .sort((a, b) => {
          if (a < b) return -1;
          if (a > b) return 1;
          return 0;
        })
        .map((dayCard) => (
          <IonRow
            key={dayCard}
            class="mobile-todo-margin"
            style={{ marginRight: 38 }}
          >
            <IonCol sizeLg="2" size-xs="3" size-sm="3">
              {moment(daysGrouped[dayCard][0].engagement.timestamp).format(
                "YYYY-MM-DD"
              ) === moment(new Date()).format("YYYY-MM-DD") ? (
                <React.Fragment>
                  <IonText className="todayToDo">Due Today</IonText>
                  <IonCol style={{ display: "flex", height: "100%" }}>
                    <IonLabel className="timelineVerticalLineRed"></IonLabel>
                  </IonCol>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <IonText className="todayToDoNormal">
                    Due:{" "}
                    {moment(
                      daysGrouped[dayCard][0].engagement.timestamp
                    ).format("ll")}
                  </IonText>
                  <IonCol style={{ display: "flex", height: "100%" }}>
                    <IonText className="timelineVerticalLineNormal"></IonText>
                  </IonCol>
                </React.Fragment>
              )}
            </IonCol>
            <IonCol
            sizeLg="0"
              size-xs="1"
              size-sm="1"
              style={{ display: "flex", flexDirection: "column" }}
            >
              {moment(daysGrouped[dayCard][0].engagement.timestamp).format(
                "YYYY-MM-DD"
              ) === moment(new Date()).format("YYYY-MM-DD")
                ? daysGrouped[dayCard].map((o) => (
                    <IonIcon
                      style={{
                        flexGrow: 1,
                        marginLeft: "auto",
                        width: 12,
                        marginBottom: 16,
                        
                      }}
                      icon={ellipse}
                      color="danger"
                      key={o.engagement.id}
                    />
                  ))
                : null}
            </IonCol>
            <IonCol sizeLg="9" class="mobile-todo-daygrid" size-xs="8" size-sm="8">
              <DayGrid
                contactcall={contactcall}
                contactemail={contactemail}
                contProps={contProps}
                refreshEng={refreshEng}
                TodosDay={daysGrouped[dayCard]}
              />
            </IonCol>
          </IonRow>
        ))}
    </IonGrid>
  );
};

const DayGrid = ({
  TodosDay,
  contactcall,
  contactemail,
  contProps,
  refreshEng,
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);

  const history = useHistory();

  const handleCompleteToggle = (dayData) => {
    //console.log(dayData)
    setLoading(true);
    api
      .patchTask(dayData.engagement.id, {
        metadata: {
          status:
            dayData.metadata.status === "COMPLETED"
              ? "NOT_STARTED"
              : "COMPLETED",
        },
      })
      .then((Engresp) => {
        setLoading(false);
        refreshEng(Engresp);
      });
  };

  return TodosDay.map((dayData) => (
    <IonCard class="todoCard" key={dayData.engagement.id}>
      <IonLoading
        cssClass="my-custom-loading-class"
        isOpen={isLoading}
        message={"Please wait..."}
      />
      <IonItem lines="none" className="todoItem">
        <IonGrid>
          <IonRow>
            <IonCol size-xs="1" size-sm="1">
              <IonIcon
                className="ion-padding-top"
                class="mobile-tododot"
                size="large"
                icon={
                  dayData.metadata.status === "COMPLETED"
                    ? todoIcon
                    : todoIconIncomplete
                }
                onClick={() => {
                  handleCompleteToggle(dayData);
                }}
              />
            </IonCol>
            <IonCol size-xs="11" size-sm="11">
              <IonLabel class="mobile-todo-title desk-todo-title">
                <p
                  className={clsx({
                    "boldText mobile-breakline ion-padding-top": true,
                    white: dayData.metadata.status !== "COMPLETED",
                  })}
                  style={{
                    marginBottom: 5,
                    textDecoration:
                      dayData.metadata.status === "COMPLETED"
                        ? "line-through"
                        : "none",
                  }}
                  onClick={() => {
                    history.push(
                      `/view/todo`,{
                        contId:contProps.id,
                        engId:dayData.engagement.id
                      }
                    );
                  }}
                >
                  {dayData.metadata.subject}
                </p>
                {/* <p>
                    Due:{" "}
                    {moment(dayData.engagement.timestamp).format("ddd") +
                      ", " +
                      moment(dayData.engagement.timestamp).format("ll")}
                  </p> */}
                <p>
                  {`Priority ${dayData.metadata.priority ? camelize(
                    dayData.metadata.priority
                  ) : 'NONE'} | Type: ${camelize(dayData.metadata.taskType)}`}
                </p>
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonText className="todoHorizontalLine"></IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            {dayData.metadata.status !== "COMPLETED" ? (
              <IonButton
                color="gold"
                fill="clear"
                mode="ios"
                disabled={dayData.metadata.status === "COMPLETED"}
              >
                {dayData.metadata.taskType === "EMAIL" ? (
                  <a className="todoanchor" href={"mailto:" + contactemail}>
                    Email
                  </a>
                ) : dayData.metadata.taskType === "CALL" ? (
                  <a className="todoanchor" href={"tel:" + contactcall}>
                    Call
                  </a>
                ) : (
                  <IonText
                    onClick={() => {
                      history.push(`/newevent/${contProps.id}`, {
                        contactName: `${contProps.properties.firstname} ${contProps.properties.lastname}`,
                        contId: contProps.id,
                      });
                    }}
                  >
                    Schedule
                  </IonText>
                )}
              </IonButton>
            ) : (
              <IonButton color="greyscale" fill="clear" mode="ios" disabled>
                Task Completed
              </IonButton>
            )}
          </IonRow>
        </IonGrid>
      </IonItem>
    </IonCard>
  ));
};

export default Todos;
