import React, { useState, useEffect } from "react";
import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonRow,
  IonText,
  IonInfiniteScrollContent,
  IonInfiniteScroll,
  IonPopover,
  IonFooter,
  IonToggle,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import useWindowDimensions from "src/components/useWindowDimensions";
import CryptoJS from "crypto-js";
import { useHistory } from "react-router-dom";
import wallet from "src/icons/wallet.svg";
import royal from "src/icons/pros-people.svg";
import emailicon from "src/icons/email.svg";
import phonecall from "src/icons/phonecall.svg";
import Whatsup from "src/icons/Whatsup.svg";
import smallstar from "src/icons/smallstar.svg";
import api from "src/api";
import FilterChips from "./FilterChips";
import moment from "moment";
import { Plugins } from "@capacitor/core";
import "./ListAll.css";
import { ellipse, gridOutline, listOutline, syncOutline } from "ionicons/icons";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";
import { syncMeetingNotes, synProsp } from "src/offlineUtils/networkUtil";
const imgbaseuri = "https://cdn2.hubspot.net/";

const { Storage, Device } = Plugins;

const styles = {
  card: {
    "--background": "#1A1A1A",
    marginInlineStart: 0,
    marginInlineEnd: 0,
    margin: 0,
    /*  marginTop: 27.5,
         marginRight: 32,
         marginLeft: 18 */
  },
  cntbtn: {
    "--border-radius": "16px",
    "--color": "#222222",
    marginRight: "auto",
    marginBottom: 16,
  },
  period: {
    fontSize: 6,
    marginLeft: 4,
    marginRight: 4,
    marginTop: 8,
    color: "#828282",
  },
  period_a: {
    fontSize: 6,
    marginLeft: 4,
    marginRight: 4,
    marginTop: 2,
    color: "#828282",
  },
  paddingtop8: {
    "padding-top": "8px",
  },
  postionleft6: {
    position: "relative",
    left: "6px",
  },
  positiontop10: {
    position: "relative",
    top: "10px",
    "margin-left": "-8px",
  },
  grid: {
    marginLeft: 24,
    marginRight: 24,
    // marginTop: 32,
    padding: 0,
  },
  item: {
    /* "--background": "#222222",
        marginBottom: 2,
        borderRadius: 8, */
    "--padding-start": "0px",
    height: 50,
    display: "flex",
  },
  listItem: {
    "--background": "#222222",
    width: 1500,
    marginBottom: 2,
    borderRadius: 8,
    "--min-height": "64px",
  },
  desc: {
    fontFamily: "Almarai",
    fontWeight: 300,
    fontSize: 16,
    color: "#828282",
    marginBottom: 8,
  },
  label: {
    marginTop: 14.5,
    marginBottom: 14.5,
    marginLeft: 16,
  },
  listlabel: {
    marginBottom: 10,
    cursor: "pointer",
  },
  title: {
    fontWeight: 400,
    fontSize: 18,
  },
  subText: {
    "--padding-start": "0px",
  },
  subText_a: {
    "margin-top": "-8px",
    "--padding-start": "5px",
  },
  phEmail: {
    "--padding-start": "0px",
    "margin-top": "-8px",
    "margin-bottom": "-8px",
  },
  nopad: {
    padding: 0,
  },
  cardHeader: {},
  col: {
    padding: 8,
  },
};

const decrypt = (decryptVlaue) => {
  let passphrase =
    "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9";
  const bytes = CryptoJS.AES.decrypt(decryptVlaue, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};
const getUserId = async () => {
  const ret: any = await Storage.get({ key: "userId" });
  const temp = JSON.parse(decrypt(ret.value));
  return temp && temp.id ? temp.id : null;
};

const getTeams = async () => {
  const ret: any = await Storage.get({ key: "userId" });
  const temp = JSON.parse(decrypt(ret.value));
  return temp && temp.teams ? temp.teams : null;
};

const getUserEmail = async () => {
  const ret: any = await Storage.get({ key: "userId" });
  const temp = JSON.parse(decrypt(ret.value));
  return temp && temp.email ? temp.email : null;
};

const sortObjDesc = {
  order: ["notes_last_contacted"],
  sorts: [
    {
      propertyName: "notes_last_contacted",
      direction: "DESCENDING",
    },
  ],
  properties: [
    "firstname",
    "middle_name",
    "lastname",
    "first_name_arabic",
    "arabic_last_name",
    "arabic_middle_name",
    "hs_avatar_filemanager_key",
    "amount_status",
    "expected_closing_probability",
    "segment",
    "notes_last_contacted",
    "onboarding_id",
    "hubspot_owner_id",
    "email",
    "phone",
    "mobilephone",
  ],
};
const CommitFcst = {
  order: ["amount_status"],
  sorts: [
    {
      propertyName: "amount_status",
      direction: "DESCENDING",
    },
  ],
  properties: [
    "firstname",
    "middle_name",
    "lastname",
    "first_name_arabic",
    "arabic_last_name",
    "arabic_middle_name",
    "hs_avatar_filemanager_key",
    "amount_status",
    "expected_closing_probability",
    "segment",
    "notes_last_contacted",
    "onboarding_id",
    "hubspot_owner_id",
    "email",
    "phone",
    "mobilephone",
  ],
};
const A2Z = {
  order: ["firstname"],
  sorts: [
    {
      propertyName: "firstname",
      direction: "ASCENDING",
    },
  ],
  properties: [
    "firstname",
    "middle_name",
    "lastname",
    "first_name_arabic",
    "arabic_last_name",
    "arabic_middle_name",
    "hs_avatar_filemanager_key",
    "amount_status",
    "expected_closing_probability",
    "segment",
    "notes_last_contacted",
    "onboarding_id",
    "hubspot_owner_id",
    "email",
    "phone",
    "mobilephone",
  ],
};

const ClientIdDsc = {
  order: ["onboarding_id"],
  sorts: [
    {
      propertyName: "onboarding_id",
      direction: "DESCENDING",
    },
  ],
  properties: [
    "firstname",
    "middle_name",
    "lastname",
    "first_name_arabic",
    "arabic_last_name",
    "arabic_middle_name",
    "hs_avatar_filemanager_key",
    "amount_status",
    "expected_closing_probability",
    "segment",
    "notes_last_contacted",
    "onboarding_id",
    "hubspot_owner_id",
    "email",
    "phone",
    "mobilephone",
  ],
};

const ClientIdAsc = {
  order: ["onboarding_id"],
  sorts: [
    {
      propertyName: "onboarding_id",
      direction: "ASCENDING",
    },
  ],
  properties: [
    "firstname",
    "middle_name",
    "lastname",
    "first_name_arabic",
    "arabic_last_name",
    "arabic_middle_name",
    "hs_avatar_filemanager_key",
    "amount_status",
    "expected_closing_probability",
    "segment",
    "notes_last_contacted",
    "onboarding_id",
    "hubspot_owner_id",
    "email",
    "phone",
    "mobilephone",
  ],
};

const Z2A = {
  order: ["firstname"],
  sorts: [
    {
      propertyName: "firstname",
      direction: "DESCENDING",
    },
  ],
  properties: [
    "firstname",
    "middle_name",
    "lastname",
    "first_name_arabic",
    "arabic_last_name",
    "arabic_middle_name",
    "hs_avatar_filemanager_key",
    "amount_status",
    "expected_closing_probability",
    "segment",
    "notes_last_contacted",
    "onboarding_id",
    "hubspot_owner_id",
    "email",
    "phone",
    "mobilephone",
  ],
};

interface ListAllProps {
  toptargetcnt: any;
  sendDataToParent: any;
  activeFilters: Array<string>;
  clrFilters: Function;
  filterFields: any;
  sortOpt: string;
  updateSortFilter: any;
  setToTopTarget?: any;
  selectedSeg: string;
}

const ListAll: React.FC<ListAllProps> = ({
  toptargetcnt,
  sendDataToParent,
  activeFilters,
  clrFilters,
  filterFields,
  sortOpt,
  updateSortFilter,
  setToTopTarget,
  selectedSeg,
}) => {
  const [topTarget, settopTarget] = useState<any>();
  const [allProsp, setallProsp] = useState<any>();
  const [totProsCnt, settotProsCnt] = useState(0);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [paging, setpaging] = useState<any>();
  const [ionInfiniteDisabled, setionInfiniteDisabled] = useState<boolean>(
    false
  );
  const { height, width } = useWindowDimensions();

  const ionInfiniteScrollRef: React.RefObject<HTMLIonInfiniteScrollElement> = React.createRef<
    HTMLIonInfiniteScrollElement
  >();
  const Clear = async () => {
    const uId = await getUserId();
    const teams = await getTeams();

    let filterGroups = [
      {
        filters: [
          {
            operator: "EQ",
            // propertyName: teams?"hubspot_team_id":"hubspot_owner_id",
            // value: teams?parseInt(teams[0].id):parseInt(uId),
            propertyName: teams ? "hubspot_owner_id" : "hubspot_owner_id",
            value: parseInt(uId),
          },
          {
            operator:
              selectedSeg === "allclients"
                ? "HAS_PROPERTY"
                : "NOT_HAS_PROPERTY",
            propertyName: "hs_lifecyclestage_customer_date",
          },
        ],
      },
    ];

    // if(teams && teams.length >= 2)
    // {
    //   filterGroups.push(
    //     {
    //       filters: [
    //         {
    //           operator: "EQ",
    //           propertyName: "hubspot_team_id",
    //           value: parseInt(teams[1].id),
    //         },
    //         {
    //           operator:
    //             selectedSeg === "allclients"
    //               ? "HAS_PROPERTY"
    //               : "NOT_HAS_PROPERTY",
    //           propertyName: "hs_lifecyclestage_customer_date",
    //         },
    //       ]
    //     })
    // }
    // if(teams && teams.length >= 3)
    // {
    //   filterGroups.push(
    //     {
    //       filters: [
    //         {
    //           operator: "EQ",
    //           propertyName: "hubspot_team_id",
    //           value: parseInt(teams[2].id),
    //         },
    //         {
    //           operator:
    //             selectedSeg === "allclients"
    //               ? "HAS_PROPERTY"
    //               : "NOT_HAS_PROPERTY",
    //           propertyName: "hs_lifecyclestage_customer_date",
    //         },
    //       ]
    //     })
    // }

    setLoading(true);

    // api.getAllProspects().then((response) => {
    api
      .filterProspects({ ...sortObjDesc, filterGroups })
      .then((response) => {
        setallProsp(response.results);

        settotProsCnt(response.total);
        sendDataToParent(response.total, toptargetcnt);
        setpaging(response.paging);
        !response.paging || response.paging === ""
          ? setionInfiniteDisabled(true)
          : setionInfiniteDisabled(false);
        setLoading(false);
        clrFilters();
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    // api.getAllProspects().then((response) => {
    getTeams().then((teams) => {
      getUserId().then((userId) => {
        Device.getInfo().then((deviceInfo) => {
          getUserEmail().then((useremail) => {
            let conType: string = "";
            if (selectedSeg === "allclients") {
              conType = "ViewAllClients";
            } else {
              conType = "ViewAllProspects";
            }
            if (deviceInfo.platform == "web") {
              window.globalThis.firebase.analytics().setUserId(useremail);
              window.globalThis.firebase
                .analytics()
                .logEvent("select_content", {
                  user_name: useremail,
                  content_type: conType,
                  item_id: useremail,
                  device_id: deviceInfo ? deviceInfo.uuid : "",
                  platform: deviceInfo ? deviceInfo.platform : "",
                  device_model: deviceInfo ? deviceInfo.model : "",
                  device_type: deviceInfo ? deviceInfo.osVersion : "",
                  device_os: deviceInfo ? deviceInfo.operatingSystem : "",
                });
            } else {
              FirebaseAnalytics.setUserId(useremail);
              FirebaseAnalytics.logEvent("select_content", {
                user_name: useremail,
              });
              FirebaseAnalytics.logEvent("select_content", {
                content_type: conType,
              });
              FirebaseAnalytics.logEvent("select_content", {
                item_id: useremail,
              });
              FirebaseAnalytics.logEvent("select_content", {
                device_id: deviceInfo ? deviceInfo.uuid : "",
              });
              FirebaseAnalytics.logEvent("select_content", {
                platform: deviceInfo ? deviceInfo.platform : "",
              });
              FirebaseAnalytics.logEvent("select_content", {
                device_model: deviceInfo ? deviceInfo.model : "",
              });
              FirebaseAnalytics.logEvent("select_content", {
                device_type: deviceInfo ? deviceInfo.osVersion : "",
              });
              FirebaseAnalytics.logEvent("select_content", {
                device_os: deviceInfo ? deviceInfo.operatingSystem : "",
              });
            }
          });
        });

        api
          .getTTfromStore()
          .then((resp) => {
            settopTarget(resp);
          })
          .catch((e) => {
            setLoading(false);
          });

        let filterGroups = [
          {
            filters: [
              {
                operator: "EQ",
                //propertyName: teams?"hubspot_team_id":"hubspot_owner_id",
                // value: teams?parseInt(teams[0].id):parseInt(userId),
                propertyName: teams ? "hubspot_owner_id" : "hubspot_owner_id",
                value: parseInt(userId),
              },
              {
                operator:
                  selectedSeg === "allclients"
                    ? "HAS_PROPERTY"
                    : "NOT_HAS_PROPERTY",
                propertyName: "hs_lifecyclestage_customer_date",
              },
            ],
          },
        ];
        // if(teams && teams.length >= 2)
        // {
        //   filterGroups.push(
        //     {
        //       filters: [
        //         {
        //           operator: "EQ",
        //           propertyName: "hubspot_team_id",
        //           value: parseInt(teams[1].id),
        //         },
        //       {
        //         operator:
        //           selectedSeg === "allclients"
        //             ? "HAS_PROPERTY"
        //             : "NOT_HAS_PROPERTY",
        //         propertyName: "hs_lifecyclestage_customer_date",
        //       },
        //       ]
        //     })
        // }
        // if(teams && teams.length >= 3)
        // {
        //   filterGroups.push(
        //     {
        //       filters: [
        //         {
        //           operator: "EQ",
        //           propertyName: "hubspot_team_id",
        //           value: parseInt(teams[2].id),
        //         }
        //         ,
        //         {
        //           operator:
        //             selectedSeg === "allclients"
        //               ? "HAS_PROPERTY"
        //               : "NOT_HAS_PROPERTY",
        //           propertyName: "hs_lifecyclestage_customer_date",
        //         },
        //       ]
        //     })
        // }

        api
          .filterProspects({ ...sortObjDesc, filterGroups })
          .then((response) => {
            // console.log(response)
            setallProsp(response.results);
            settotProsCnt(response.total);
            sendDataToParent(response.total, toptargetcnt);
            setpaging(response.paging);
            !response.paging || response.paging === ""
              ? setionInfiniteDisabled(true)
              : setionInfiniteDisabled(false);
            setLoading(false);
          })
          .catch((e) => {
            // console.log(JSON.stringify(e))
            setLoading(false);
          });
      });
    });
  }, []);

  const retrieveNextSet = async () => {
    //prep payload

    const uId = await getUserId();
    const teams = await getTeams();
    let tmpSort = {};
    let pag =
      paging !== "" && paging && paging.next && paging.next ? paging.next : {};

    let fltrgrparr = [
      {
        filters: [
          {
            operator: "EQ",
            //propertyName: teams?"hubspot_team_id":"hubspot_owner_id",
            // value: teams?parseInt(teams[0].id):parseInt(uId),
            propertyName: teams ? "hubspot_owner_id" : "hubspot_owner_id",
            value: parseInt(uId),
          },
          {
            operator:
              selectedSeg === "allclients"
                ? "HAS_PROPERTY"
                : "NOT_HAS_PROPERTY",
            propertyName: "hs_lifecyclestage_customer_date",
          },
        ],
      },
    ];

    // if(teams && teams.length >= 2)
    // {
    //   fltrgrparr.push(
    //     {
    //       filters: [
    //         {
    //           operator: "EQ",
    //           propertyName: "hubspot_team_id",
    //           value: parseInt(teams[1].id),
    //         },
    //       {
    //         operator:
    //           selectedSeg === "allclients"
    //             ? "HAS_PROPERTY"
    //             : "NOT_HAS_PROPERTY",
    //         propertyName: "hs_lifecyclestage_customer_date",
    //       },
    //       ]
    //     })
    // }
    // if(teams && teams.length >= 3)
    // {
    //   fltrgrparr.push(
    //     {
    //       filters: [
    //         {
    //           operator: "EQ",
    //           propertyName: "hubspot_team_id",
    //           value: parseInt(teams[2].id),
    //         },
    //       {
    //         operator:
    //           selectedSeg === "allclients"
    //             ? "HAS_PROPERTY"
    //             : "NOT_HAS_PROPERTY",
    //         propertyName: "hs_lifecyclestage_customer_date",
    //       },
    //       ]
    //     })
    // }

    switch (sortOpt) {
      case "Interaction Date (Newest to Oldest)":
        tmpSort = {
          ...sortObjDesc,
          ...pag,
          filterGroups: fltrgrparr,
          // [
          //   {
          //     filters: [
          //       {
          //         operator: "EQ",
          //         // propertyName: "hubspot_owner_id",
          //         // value: parseInt(uId),
          //         propertyName: teams?"hubspot_team_id":"hubspot_owner_id",
          //         value: teams?parseInt(teams[0].id):parseInt(uId),
          //       },
          //       {
          //         operator:
          //           selectedSeg === "allclients"
          //             ? "HAS_PROPERTY"
          //             : "NOT_HAS_PROPERTY",
          //         propertyName: "hs_lifecyclestage_customer_date",
          //       },
          //     ],
          //   },
          //   // {
          //   //   filters: [
          //   //     {
          //   //       operator: "NOT_HAS_PROPERTY",
          //   //       propertyName: "hubspot_owner_id"
          //   //     },
          //   //     {
          //   //       operator:
          //   //         selectedSeg === "allclients"
          //   //           ? "HAS_PROPERTY"
          //   //           : "NOT_HAS_PROPERTY",
          //   //       propertyName: "hs_lifecyclestage_customer_date",
          //   //     },
          //   //   ],
          //   // },
          // ],
        };
        break;
      // case "AUM":
      case "Commitment forcast (high to low)":
        tmpSort = {
          ...CommitFcst,
          ...pag,
          filterGroups: fltrgrparr,
          // filterGroups: [
          //   {
          //     filters: [
          //       {
          //         operator: "EQ",
          //         propertyName: teams?"hubspot_team_id":"hubspot_owner_id",
          //         value: teams?parseInt(teams[0].id):parseInt(uId),
          //       },
          //       {
          //         operator:
          //           selectedSeg === "allclients"
          //             ? "HAS_PROPERTY"
          //             : "NOT_HAS_PROPERTY",
          //         propertyName: "hs_lifecyclestage_customer_date",
          //       },
          //     ],
          //   },
          // {
          //   filters: [
          //     {
          //       operator: "NOT_HAS_PROPERTY",
          //       propertyName: "hubspot_owner_id"
          //     },
          //     {
          //       operator:
          //         selectedSeg === "allclients"
          //           ? "HAS_PROPERTY"
          //           : "NOT_HAS_PROPERTY",
          //       propertyName: "hs_lifecyclestage_customer_date",
          //     },
          //   ],
          // }
          //],
        };
        break;
      case "A to Z":
        tmpSort = {
          ...A2Z,
          ...pag,
          filterGroups: fltrgrparr,
          // filterGroups: [
          //   {
          //     filters: [
          //       {
          //         operator: "EQ",
          //         // propertyName: "hubspot_owner_id",
          //         // value: parseInt(uId),
          //         propertyName: teams?"hubspot_team_id":"hubspot_owner_id",
          //         value: teams?parseInt(teams[0].id):parseInt(uId),
          //       },
          //       {
          //         operator:
          //           selectedSeg === "allclients"
          //             ? "HAS_PROPERTY"
          //             : "NOT_HAS_PROPERTY",
          //         propertyName: "hs_lifecyclestage_customer_date",
          //       },
          //     ],
          //   },
          // ],
        };
        break;
      case "Z to A":
        tmpSort = {
          ...Z2A,
          ...pag,
          filterGroups: fltrgrparr,
          // filterGroups: [
          //   {
          //     filters: [
          //       {
          //         operator: "EQ",
          //         // propertyName: "hubspot_owner_id",
          //         // value: parseInt(uId),
          //         propertyName: teams?"hubspot_team_id":"hubspot_owner_id",
          //         value: teams?parseInt(teams[0].id):parseInt(uId),
          //       },
          //       {
          //         operator:
          //           selectedSeg === "allclients"
          //             ? "HAS_PROPERTY"
          //             : "NOT_HAS_PROPERTY",
          //         propertyName: "hs_lifecyclestage_customer_date",
          //       },
          //     ],
          //   },
          // ],
        };
        break;
      case "Client Id (Ascending)":
        tmpSort = {
          ...ClientIdAsc,
          ...pag,
          filterGroups: fltrgrparr,
          // filterGroups: [
          //   {
          //     filters: [
          //       {
          //         operator: "EQ",
          //         // propertyName: "hubspot_owner_id",
          //         // value: parseInt(uId),
          //         propertyName: teams?"hubspot_team_id":"hubspot_owner_id",
          //         value: teams?parseInt(teams[0].id):parseInt(uId),
          //       },
          //       {
          //         operator:
          //           selectedSeg === "allclients"
          //             ? "HAS_PROPERTY"
          //             : "NOT_HAS_PROPERTY",
          //         propertyName: "hs_lifecyclestage_customer_date",
          //       },
          //     ],
          //   },
          // ],
        };
        break;
      case "Client Id (Descending)":
        tmpSort = {
          ...ClientIdDsc,
          ...pag,
          filterGroups: fltrgrparr,
          // filterGroups: [
          //   {
          //     filters: [
          //       {
          //         operator: "EQ",
          //         // propertyName: "hubspot_owner_id",
          //         // value: parseInt(uId),
          //         propertyName: teams?"hubspot_team_id":"hubspot_owner_id",
          //         value: teams?parseInt(teams[0].id):parseInt(uId),
          //       },
          //       {
          //         operator:
          //           selectedSeg === "allclients"
          //             ? "HAS_PROPERTY"
          //             : "NOT_HAS_PROPERTY",
          //         propertyName: "hs_lifecyclestage_customer_date",
          //       },
          //     ],
          //   },
          // ],
        };
        break;

      default:
        tmpSort = {
          ...sortObjDesc,
          ...pag,
          filterGroups: fltrgrparr,
          // filterGroups: [
          //   {
          //     filters: [
          //       {
          //         operator: "EQ",
          //         // propertyName: "hubspot_owner_id",
          //         // value: parseInt(uId),
          //         propertyName: teams?"hubspot_team_id":"hubspot_owner_id",
          //         value: teams?parseInt(teams[0].id):parseInt(uId),
          //       },
          //       {
          //         operator:
          //           selectedSeg === "allclients"
          //             ? "HAS_PROPERTY"
          //             : "NOT_HAS_PROPERTY",
          //         propertyName: "hs_lifecyclestage_customer_date",
          //       },
          //     ],
          //   },
          // {
          //   filters: [
          //     {
          //       operator: "NOT_HAS_PROPERTY",
          //       propertyName: "hubspot_owner_id"
          //     },
          //     {
          //       operator:
          //         selectedSeg === "allclients"
          //           ? "HAS_PROPERTY"
          //           : "NOT_HAS_PROPERTY",
          //       propertyName: "hs_lifecyclestage_customer_date",
          //     },
          //   ],
          // }
          //  ],
        };
        break;
    }

    let payload: any = {
      ...tmpSort,
      filterGroups: [],
      // properties: [contactprops],
    };
    if (Object.keys(filterFields).length > 0) {
      Object.keys(filterFields).forEach((obj) => {
        if (
          filterFields[obj].vals.length > 0 &&
          filterFields[obj].field !== ""
        ) {
          filterFields[obj].vals.forEach((val) => {
            if (filterFields[obj].field === "hubspot_owner_id") {
              payload.filterGroups.push({
                filters: [
                  {
                    operator: "EQ",
                    propertyName: "hubspot_owner_id",
                    value: parseInt(filterFields[obj].vals[0]),
                  },
                  {
                    operator:
                      selectedSeg === "allclients"
                        ? "HAS_PROPERTY"
                        : "NOT_HAS_PROPERTY",
                    propertyName: "hs_lifecyclestage_customer_date",
                  },
                ],
              });
            } else {
              payload.filterGroups.push({
                filters: [
                  {
                    operator: "EQ",
                    //propertyName: teams?"hubspot_team_id":"hubspot_owner_id",
                    // value: teams?parseInt(teams[0].id):parseInt(uId),
                    propertyName: teams
                      ? "hubspot_owner_id"
                      : "hubspot_owner_id",
                    value: parseInt(uId),
                  },
                  {
                    operator: "CONTAINS_TOKEN",
                    propertyName: filterFields[obj].field,
                    value: val,
                  },
                  {
                    operator:
                      selectedSeg === "allclients"
                        ? "HAS_PROPERTY"
                        : "NOT_HAS_PROPERTY",
                    propertyName: "hs_lifecyclestage_customer_date",
                  },
                ],
              });
              // if(teams && teams.length >= 2)
              //   {
              //     payload.filterGroups.push({
              //       filters: [
              //         {
              //           operator: "EQ",
              //           propertyName: "hubspot_team_id",
              //           value: teams?parseInt(teams[1].id):"",
              //         },
              //         {
              //           operator: "CONTAINS_TOKEN",
              //           propertyName: filterFields[obj].field,
              //           value: val,
              //         },
              //         {
              //           operator:
              //             selectedSeg === "allclients"
              //               ? "HAS_PROPERTY"
              //               : "NOT_HAS_PROPERTY",
              //           propertyName: "hs_lifecyclestage_customer_date",
              //         },
              //       ],
              //     });
              //   }
              //   if(teams && teams.length >= 3)
              //   {
              //     payload.filterGroups.push({
              //       filters: [
              //         {
              //           operator: "EQ",
              //           propertyName: "hubspot_team_id",
              //           value: teams?parseInt(teams[2].id):"",
              //         },
              //         {
              //           operator: "CONTAINS_TOKEN",
              //           propertyName: filterFields[obj].field,
              //           value: val,
              //         },
              //         {
              //           operator:
              //             selectedSeg === "allclients"
              //               ? "HAS_PROPERTY"
              //               : "NOT_HAS_PROPERTY",
              //           propertyName: "hs_lifecyclestage_customer_date",
              //         },
              //       ],
              //     });
              //   }
            }
          });
        }
      });
    }

    let finalPayload =
      payload.filterGroups.length > 0 ? { ...payload } : tmpSort;

    return api.filterProspects({ ...finalPayload }).catch((e) => {
      // console.log(JSON.stringify(e))
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    //prep payload
    getTeams().then((teams) => {
      getUserId().then((uId) => {
        let tmpSort = {};
        let fltrgrparr = [
          {
            filters: [
              {
                operator: "EQ",
                // propertyName: teams?"hubspot_team_id":"hubspot_owner_id",
                //value: teams?parseInt(teams[0].id):parseInt(uId),
                propertyName: teams ? "hubspot_owner_id" : "hubspot_owner_id",
                value: parseInt(uId),
              },
              {
                operator:
                  selectedSeg === "allclients"
                    ? "HAS_PROPERTY"
                    : "NOT_HAS_PROPERTY",
                propertyName: "hs_lifecyclestage_customer_date",
              },
            ],
          },
        ];

        // if(teams && teams.length >= 2)
        // {
        //   fltrgrparr.push(
        //     {
        //       filters: [
        //         {
        //           operator: "EQ",
        //           propertyName: "hubspot_team_id",
        //           value: parseInt(teams[1].id),
        //         },
        //       {
        //         operator:
        //           selectedSeg === "allclients"
        //             ? "HAS_PROPERTY"
        //             : "NOT_HAS_PROPERTY",
        //         propertyName: "hs_lifecyclestage_customer_date",
        //       },
        //       ]
        //     })
        // }
        // if(teams && teams.length >= 3)
        // {
        //   fltrgrparr.push(
        //     {
        //       filters: [
        //         {
        //           operator: "EQ",
        //           propertyName: "hubspot_team_id",
        //           value: parseInt(teams[2].id),
        //         },
        //       {
        //         operator:
        //           selectedSeg === "allclients"
        //             ? "HAS_PROPERTY"
        //             : "NOT_HAS_PROPERTY",
        //         propertyName: "hs_lifecyclestage_customer_date",
        //       },
        //       ]
        //     })
        // }
        switch (updateSortFilter.sortOpt) {
          // case "AUM":
          case "Commitment forcast (high to low)":
            tmpSort = {
              ...CommitFcst,
              filterGroups: fltrgrparr,
              // filterGroups: [
              //   {
              //     filters: [
              //       {
              //         operator: "EQ",
              //         // propertyName: "hubspot_owner_id",
              //         // value: parseInt(uId),
              //         propertyName: teams?"hubspot_team_id":"hubspot_owner_id",
              //         value: teams?parseInt(teams[0].id):parseInt(uId),
              //       },
              //       {
              //         operator:
              //           selectedSeg === "allclients"
              //             ? "HAS_PROPERTY"
              //             : "NOT_HAS_PROPERTY",
              //         propertyName: "hs_lifecyclestage_customer_date",
              //       },
              //     ],
              //   },
              // ],
              // }
            };
            break;
          case "A to Z":
            tmpSort = {
              ...A2Z,
              filterGroups: fltrgrparr,
              // filterGroups: [
              //   {
              //     filters: [
              //       {
              //         operator: "EQ",
              //         // propertyName: "hubspot_owner_id",
              //         // value: parseInt(uId),
              //         propertyName: teams?"hubspot_team_id":"hubspot_owner_id",
              //         value: teams?parseInt(teams[0].id):parseInt(uId),
              //       },
              //       {
              //         operator:
              //           selectedSeg === "allclients"
              //             ? "HAS_PROPERTY"
              //             : "NOT_HAS_PROPERTY",
              //         propertyName: "hs_lifecyclestage_customer_date",
              //       },
              //     ],
              //   },
              // ],
            };
            break;
          case "Z to A":
            tmpSort = {
              ...Z2A,
              filterGroups: fltrgrparr,
              // filterGroups: [
              //   {
              //     filters: [
              //       {
              //         operator: "EQ",
              //         // propertyName: "hubspot_owner_id",
              //         // value: parseInt(uId),
              //         propertyName: teams?"hubspot_team_id":"hubspot_owner_id",
              //         value: teams?parseInt(teams[0].id):parseInt(uId),
              //       },
              //       {
              //         operator:
              //           selectedSeg === "allclients"
              //             ? "HAS_PROPERTY"
              //             : "NOT_HAS_PROPERTY",
              //         propertyName: "hs_lifecyclestage_customer_date",
              //       },
              //     ],
              //   }
              // ],
            };
            break;
          case "Client Id (Ascending)":
            tmpSort = {
              ...ClientIdAsc,
              filterGroups: fltrgrparr,
              // filterGroups: [
              //   {
              //     filters: [
              //       {
              //         operator: "EQ",
              //         // propertyName: "hubspot_owner_id",
              //         // value: parseInt(uId),
              //         propertyName: teams?"hubspot_team_id":"hubspot_owner_id",
              //         value: teams?parseInt(teams[0].id):parseInt(uId),
              //       },
              //       {
              //         operator:
              //           selectedSeg === "allclients"
              //             ? "HAS_PROPERTY"
              //             : "NOT_HAS_PROPERTY",
              //         propertyName: "hs_lifecyclestage_customer_date",
              //       },
              //     ],
              //   },
              // ],
            };
            break;
          case "Client Id (Descending)":
            tmpSort = {
              ...ClientIdDsc,
              filterGroups: fltrgrparr,
              // filterGroups: [
              //   {
              //     filters: [
              //       {
              //         operator: "EQ",
              //         // propertyName: "hubspot_owner_id",
              //         // value: parseInt(uId),
              //         propertyName: teams?"hubspot_team_id":"hubspot_owner_id",
              //         value: teams?parseInt(teams[0].id):parseInt(uId),
              //       },
              //       {
              //         operator:
              //           selectedSeg === "allclients"
              //             ? "HAS_PROPERTY"
              //             : "NOT_HAS_PROPERTY",
              //         propertyName: "hs_lifecyclestage_customer_date",
              //       },
              //     ],
              //   },
              // ],
            };
            break;
          default:
            tmpSort = {
              ...sortObjDesc,
              filterGroups: fltrgrparr,
              // filterGroups: [
              //   {
              //     filters: [
              //       {
              //         operator: "EQ",
              //         // propertyName: "hubspot_owner_id",
              //         // value: parseInt(uId),
              //         propertyName: teams?"hubspot_team_id":"hubspot_owner_id",
              //         value: teams?parseInt(teams[0].id):parseInt(uId),
              //       },
              //       {
              //         operator:
              //           selectedSeg === "allclients"
              //             ? "HAS_PROPERTY"
              //             : "NOT_HAS_PROPERTY",
              //         propertyName: "hs_lifecyclestage_customer_date",
              //       },
              //     ],
            };
            // {
            //   filters: [
            //     {
            //       operator: "NOT_HAS_PROPERTY",
            //       propertyName: "hubspot_owner_id"
            //     },
            //     {
            //       operator:
            //         selectedSeg === "allclients"
            //           ? "HAS_PROPERTY"
            //           : "NOT_HAS_PROPERTY",
            //       propertyName: "hs_lifecyclestage_customer_date",
            //     },
            //   ],
            // }
            // ],
            // };
            break;
        }
        let payload: any = {
          ...tmpSort,
          filterGroups: [],
          // properties: [contactprops],
        };
        if (updateSortFilter.field) {
          if (Object.keys(updateSortFilter.field).length > 0) {
            Object.keys(updateSortFilter.field).forEach((obj) => {
              if (
                updateSortFilter.field[obj].vals.length > 0 &&
                updateSortFilter.field[obj].field !== ""
              ) {
                updateSortFilter.field[obj].vals.forEach((val) => {
                  if (
                    updateSortFilter.field[obj].field === "hubspot_owner_id"
                  ) {
                    payload.filterGroups.push({
                      filters: [
                        {
                          operator: "EQ",
                          propertyName: "hubspot_owner_id",
                          value: parseInt(updateSortFilter.field[obj].vals[0]),
                        },
                        // {
                        //   operator: "EQ",
                        //   propertyName: "hubspot_team_id",
                        //   value: parseInt(teams[0].id),
                        // },
                        {
                          operator:
                            selectedSeg === "allclients"
                              ? "HAS_PROPERTY"
                              : "NOT_HAS_PROPERTY",
                          propertyName: "hs_lifecyclestage_customer_date",
                        },
                      ],
                    });
                    // if(teams && teams.length >= 2)
                    // {
                    //   payload.filterGroups.push({
                    //     filters: [
                    //       {
                    //         operator: "EQ",
                    //         propertyName: "hubspot_owner_id",
                    //         value: parseInt(filterFields[obj].vals[0]),
                    //       },
                    //       {
                    //         operator: "EQ",
                    //         propertyName: "hubspot_team_id",
                    //         value: parseInt(teams[1].id),
                    //       },
                    //       {
                    //         operator:
                    //           selectedSeg === "allclients"
                    //             ? "HAS_PROPERTY"
                    //             : "NOT_HAS_PROPERTY",
                    //         propertyName: "hs_lifecyclestage_customer_date",
                    //       },
                    //     ],
                    //   });
                    // }
                    // if(teams && teams.length >= 3)
                    // {
                    //   payload.filterGroups.push({
                    //     filters: [
                    //       {
                    //         operator: "EQ",
                    //         propertyName: "hubspot_owner_id",
                    //         value: parseInt(filterFields[obj].vals[0]),
                    //       },
                    //       {
                    //         operator: "EQ",
                    //         propertyName: "hubspot_team_id",
                    //         value: parseInt(teams[2].id),
                    //       },
                    //       {
                    //         operator:
                    //           selectedSeg === "allclients"
                    //             ? "HAS_PROPERTY"
                    //             : "NOT_HAS_PROPERTY",
                    //         propertyName: "hs_lifecyclestage_customer_date",
                    //       },
                    //     ],
                    //   });
                    // }
                  } else {
                    payload.filterGroups.push({
                      filters: [
                        {
                          operator: "EQ",
                          // propertyName: teams?"hubspot_team_id":"hubspot_owner_id",
                          //value: teams?parseInt(teams[0].id):parseInt(uId),
                          propertyName: teams
                            ? "hubspot_owner_id"
                            : "hubspot_owner_id",
                          value: parseInt(uId),
                        },
                        {
                          operator: "CONTAINS_TOKEN",
                          propertyName: updateSortFilter.field[obj].field,
                          value: val,
                        },
                        {
                          operator:
                            selectedSeg === "allclients"
                              ? "HAS_PROPERTY"
                              : "NOT_HAS_PROPERTY",
                          propertyName: "hs_lifecyclestage_customer_date",
                        },
                      ],
                    });
                    // if(teams && teams.length >= 2)
                    // {
                    //   payload.filterGroups.push({
                    //     filters: [
                    //       {
                    //         operator: "EQ",
                    //         propertyName: "hubspot_team_id",
                    //         value: teams?parseInt(teams[1].id):"",
                    //       },
                    //       {
                    //         operator: "CONTAINS_TOKEN",
                    //         propertyName: filterFields[obj].field,
                    //         value: val,
                    //       },
                    //       {
                    //         operator:
                    //           selectedSeg === "allclients"
                    //             ? "HAS_PROPERTY"
                    //             : "NOT_HAS_PROPERTY",
                    //         propertyName: "hs_lifecyclestage_customer_date",
                    //       },
                    //     ],
                    //   });
                    // }
                    // if(teams && teams.length >= 3)
                    // {
                    //   payload.filterGroups.push({
                    //     filters: [
                    //       {
                    //         operator: "EQ",
                    //         propertyName: "hubspot_team_id",
                    //         value: teams?parseInt(teams[2].id):"",
                    //       },
                    //       {
                    //         operator: "CONTAINS_TOKEN",
                    //         propertyName: filterFields[obj].field,
                    //         value: val,
                    //       },
                    //       {
                    //         operator:
                    //           selectedSeg === "allclients"
                    //             ? "HAS_PROPERTY"
                    //             : "NOT_HAS_PROPERTY",
                    //         propertyName: "hs_lifecyclestage_customer_date",
                    //       },
                    //     ],
                    //   });
                    // }
                  }
                });
              }
            });

            if (payload.filterGroups.length > 0) {
              api
                .filterProspects({ ...payload })
                .then((response) => {
                  setallProsp(response.results);
                  settotProsCnt(response.total);
                  sendDataToParent(response.total, toptargetcnt);
                  setpaging(response.paging);
                  setLoading(false);
                  !response.paging || response.paging === ""
                    ? setionInfiniteDisabled(true)
                    : setionInfiniteDisabled(false);
                })
                .catch((e) => {
                  // console.log(JSON.stringify(e))
                  setLoading(false);
                });
            } else {
              setLoading(true);

              api
                .filterProspects(tmpSort)
                .then((response) => {
                  // console.log(response)

                  setallProsp(response.results);
                  settotProsCnt(response.total);
                  sendDataToParent(response.total, toptargetcnt);
                  setpaging(response.paging);
                  setLoading(false);
                  !response.paging || response.paging === ""
                    ? setionInfiniteDisabled(true)
                    : setionInfiniteDisabled(false);
                })
                .catch((e) => {
                  // console.log(JSON.stringify(e))
                  setLoading(false);
                });
            }
          } else {
            setLoading(true);

            api
              .filterProspects(tmpSort)
              .then((response) => {
                setallProsp(response.results);
                settotProsCnt(response.total);
                sendDataToParent(response.total, toptargetcnt);
                setpaging(response.paging);
                setLoading(false);
                !response.paging || response.paging === ""
                  ? setionInfiniteDisabled(true)
                  : setionInfiniteDisabled(false);
              })
              .catch((e) => {
                // console.log(JSON.stringify(e))
                setLoading(false);
              });
          }
        }
      });
    });
  }, [updateSortFilter]);

  const [isChecked, setIsChecked] = useState<any>("card");

  return (
    <React.Fragment>
      <IonGrid class="ml-13 mr-13 mt-unset testing" style={styles.grid}>
        {width > 576 ? (
          <IonItem
            style={{
              width: "100%",
            }}
            lines="none"
          >
            <IonSegment
              onIonChange={(e) => setIsChecked(e.detail.value)}
              value={isChecked}
              style={{ width: "10%" }}
              slot="end"
              mode="md"
            >
              <IonSegmentButton
                value="card"
                style={{
                  "--background-checked": "#1A1A1A",
                  "--color-checked": "#B99855",
                  minWidth: 0,
                }}
              >
                <IonIcon icon={gridOutline} />
              </IonSegmentButton>
              <IonSegmentButton
                value="list"
                style={{
                  "--background-checked": "#1A1A1A",
                  "--color-checked": "#B99855",
                  minWidth: 0,
                }}
              >
                <IonIcon icon={listOutline} />
              </IonSegmentButton>
            </IonSegment>
            {/* <IonButton
            onClick={() => {
              synProsp().then(() => syncMeetingNotes())
            }}
            size="default"
            color="gold"
          >Synchronize
            <IonIcon icon={syncOutline}/>
          </IonButton> */}
          </IonItem>
        ) : null}
        <FilterChips chipArr={activeFilters} clrFilters={Clear} />
        <IonRow>
          {!isLoading && allProsp ? (
            allProsp.map((prospect) =>
              prospect.properties ? (
                <ProspCard
                  key={prospect.id}
                  prospectProps={prospect.properties}
                  id={prospect.id}
                  topTarget={topTarget}
                  setToTopTarget={setToTopTarget}
                  selectedSeg={selectedSeg}
                  isChecked={isChecked}
                />
              ) : null
            )
          ) : (
            <IonLoading
              cssClass="my-custom-loading-class"
              isOpen={true}
              message={"Please wait..."}
            />
          )}
        </IonRow>
        <IonInfiniteScroll
          ref={ionInfiniteScrollRef}
          threshold="5%"
          disabled={ionInfiniteDisabled}
          onIonInfinite={(e) => {
            retrieveNextSet().then((response) => {
              let tempArr = [...allProsp];
              if (response.results.length > 0) {
                response.results.forEach((obj) => tempArr.push(obj));
              }
              setallProsp(tempArr);

              (e.target as HTMLIonInfiniteScrollElement).complete();
              if (!response.paging || response.paging === "") {
                setionInfiniteDisabled(true);
              }
              setpaging(response.paging);
            });
          }}
        >
          <IonInfiniteScrollContent></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonGrid>
    </React.Fragment>
  );
};

const decimalFunc = (Num) => {
  let temp = Num.toString().split(".");
  let retVal = 0;
  if (temp[1]) {
    retVal = Num.toFixed(2);
  } else {
    retVal = Num;
  }
  return retVal;
};
const retExpClsAmt = (clsAmt) => {
  let closingamt_disp: number = 0;
  Number.isNaN(clsAmt) || clsAmt === null || clsAmt === ""
    ? (closingamt_disp = 0)
    : (closingamt_disp = parseInt(clsAmt));
  return Math.abs(Number(closingamt_disp)) >= 1.0e9
    ? decimalFunc(Math.abs(Number(closingamt_disp)) / 1.0e9) + "b"
    : // Six Zeroes for Millions
    Math.abs(Number(closingamt_disp)) >= 1.0e6
    ? decimalFunc(Math.abs(Number(closingamt_disp)) / 1.0e6) + "m"
    : // Three Zeroes for Thousands
    Math.abs(Number(closingamt_disp)) >= 1.0e3
    ? decimalFunc(Math.abs(Number(closingamt_disp)) / 1.0e3) + "k"
    : decimalFunc(Math.abs(Number(closingamt_disp)));
};

const ProspCard = ({
  prospectProps,
  id,
  topTarget,
  setToTopTarget,
  selectedSeg,
  isChecked,
}) => {
  const [ClientAUM, setClientAUM] = useState<any>("");
  const { height, width } = useWindowDimensions();

  const hist = useHistory();
  function routetoProfile(id) {
    hist.push("/contactinfo/" + id);
  }
  const getUserEmail = async () => {
    const ret: any = await Storage.get({ key: "userId" });
    const temp = JSON.parse(decrypt(ret.value));
    return temp && temp.email ? temp.email : null;
  };
  useEffect(() => {
    api
      .getTTfromStore()
      .then((resp) => {
        if (resp && resp !== null && resp.length > 0) {
          resp.map((contarr) => {
            if (Number(contarr.contactid) === Number(id)) {
              settoptargetflg(true);
              settoptargetrank(contarr.rank);
            }
          });
        }
      })
      .catch((e) => {
        // console.log(JSON.stringify(e))
      });

    if (selectedSeg === "allclients") {
      if (onboarding_id) {
        api
          .getClientDetails(onboarding_id)
          .then((resp) => {
            if (resp.length > 0) {
              setClientAUM(resp[0].aum);
            }
          })
          .catch((e) => {
            // console.log(JSON.stringify(e))
          });
      }
    }
  }, [id, selectedSeg]);

  const {
    firstname,
    middle_name,
    lastname,
    first_name_arabic,
    arabic_last_name,
    arabic_middle_name,
    hs_avatar_filemanager_key,
    amount_status,
    expected_closing_probability,
    segment,
    notes_last_contacted,
    onboarding_id,
    hubspot_owner_id,
    email,
    phone,
    mobilephone,
  } = prospectProps;

  const [toptargetflg, settoptargetflg] = useState<boolean>(false);
  const [toptargetrank, settoptargetrank] = useState<any>(true);

  function AddTopTarget(id) {
    let updContArray: any = [];
    Device.getInfo().then((deviceInfo) => {
      getUserId().then((userId) => {
        getUserEmail().then((useremail) => {
          api
            .getTTfromStore()
            .then((resp) => {
              if (resp.length + 1 == 26) {
                setshow25Popover({ open: true, event: undefined });
              } else {
                updContArray = resp;
                updContArray.push({
                  contactid: Number(id),
                  rank: resp.length + 1,
                });
                let payloads: any = {
                  rm_id: id,
                  prospect_list: JSON.stringify(updContArray),
                };
                api
                  .postTopTargets(payloads)
                  .then((resp) => {
                    // console.log("updContArray",updContArray)
                    // console.log("payloads",payloads)
                    let temp =
                      updContArray !== "" && updContArray !== null
                        ? updContArray
                        : [];
                    api
                      .setTopTargetsinStorage(JSON.stringify(temp))
                      .then(() => {
                        if (deviceInfo.platform == "web") {
                          window.globalThis.firebase
                            .analytics()
                            .setUserId(useremail);
                          window.globalThis.firebase
                            .analytics()
                            .logEvent("select_content", {
                              user_name: useremail,
                              content_type: "AddTopTarget",
                              item_id: Number(id),
                              device_id: deviceInfo ? deviceInfo.uuid : "",
                              platform: deviceInfo ? deviceInfo.platform : "",
                              device_model: deviceInfo ? deviceInfo.model : "",
                              device_type: deviceInfo
                                ? deviceInfo.osVersion
                                : "",
                              device_os: deviceInfo
                                ? deviceInfo.operatingSystem
                                : "",
                            });
                        } else {
                          FirebaseAnalytics.setUserId(useremail);
                          FirebaseAnalytics.logEvent("select_content", {
                            user_name: useremail,
                          });
                          FirebaseAnalytics.logEvent("select_content", {
                            content_type: "AddTopTarget",
                          });
                          FirebaseAnalytics.logEvent("select_content", {
                            item_id: Number(id),
                          });
                          FirebaseAnalytics.logEvent("select_content", {
                            device_id: deviceInfo ? deviceInfo.uuid : "",
                          });
                          FirebaseAnalytics.logEvent("select_content", {
                            platform: deviceInfo ? deviceInfo.platform : "",
                          });
                          FirebaseAnalytics.logEvent("select_content", {
                            device_model: deviceInfo ? deviceInfo.model : "",
                          });
                          FirebaseAnalytics.logEvent("select_content", {
                            device_type: deviceInfo ? deviceInfo.osVersion : "",
                          });
                          FirebaseAnalytics.logEvent("select_content", {
                            device_os: deviceInfo
                              ? deviceInfo.operatingSystem
                              : "",
                          });
                        }

                        setToTopTarget();
                      })
                      .catch((e) => {
                        // console.log(JSON.stringify(e))
                      });
                  })
                  .catch((e) => {
                    // console.log(JSON.stringify(e))
                  });
              }
            })
            .catch((e) => {
              // console.log(JSON.stringify(e))
            });
        });
      });
    });
  }

  const [showPopover, setShowPopover] = useState<{
    open: boolean;
    event: Event | undefined;
  }>({
    open: false,
    event: undefined,
  });
  const [show25Popover, setshow25Popover] = useState<{
    open: boolean;
    event: Event | undefined;
  }>({
    open: false,
    event: undefined,
  });

  function DeleteTopTarget(id) {
    let updContArray: any = [];
    let contactArr: any = [];
    Device.getInfo().then((deviceInfo) => {
      getUserId().then((userId) => {
        getUserEmail().then((useremail) => {
          api
            .getTTfromStore()
            .then((resp) => {
              contactArr = resp;
              let temprank = 0;
              let tempflag = "N";
              for (let i = 0; i < contactArr.length; i++) {
                if (parseInt(contactArr[i].contactid) === parseInt(id)) {
                  tempflag = "Y";
                } else {
                  if (tempflag === "Y") {
                    temprank = i;
                  } else {
                    temprank = i + 1;
                  }
                  updContArray.push({
                    contactid: contactArr[i].contactid,
                    rank: temprank,
                  });
                }
              }
            })
            .catch((e) => {
              // console.log(JSON.stringify(e))
            });

          let payloads: any = {
            rm_id: id,
            created_date: "2020-12-19T09:38:26",
            updated_date: "2020-12-10T11:40:08",
            //"prospect_list": "[{contactid:18651,rank:3},{contactid:18951,rank:1},{contactid:19001,rank:2}]"
            prospect_list: JSON.stringify(updContArray),
          };
          //console.log(payloads)
          //update db
          api
            .postTopTargets(payloads)
            .then((resp) => {
              // setShowPopover(
              //     { open: false, event: undefined }
              // );
              let temp =
                updContArray !== "" && updContArray !== null
                  ? updContArray
                  : [];
              api
                .setTopTargetsinStorage(JSON.stringify(temp))
                .then(() => {
                  //setaddremconf(true)
                  api
                    .getTTfromStore()
                    .then((resp) => {
                      //setdeleteTarget(0);
                      if (deviceInfo.platform == "web") {
                        window.globalThis.firebase
                          .analytics()
                          .setUserId(useremail);
                        window.globalThis.firebase
                          .analytics()
                          .logEvent("select_content", {
                            user_name: useremail,
                            content_type: "RemoveTopTarget",
                            item_id: parseInt(id),
                            device_id: deviceInfo ? deviceInfo.uuid : "",
                            platform: deviceInfo ? deviceInfo.platform : "",
                            device_model: deviceInfo ? deviceInfo.model : "",
                            device_type: deviceInfo ? deviceInfo.osVersion : "",
                            device_os: deviceInfo
                              ? deviceInfo.operatingSystem
                              : "",
                          });
                      } else {
                        FirebaseAnalytics.setUserId(useremail);
                        FirebaseAnalytics.logEvent("select_content", {
                          user_name: useremail,
                        });
                        FirebaseAnalytics.logEvent("select_content", {
                          content_type: "RemoveTopTarget",
                        });
                        FirebaseAnalytics.logEvent("select_content", {
                          item_id: parseInt(id),
                        });
                        FirebaseAnalytics.logEvent("select_content", {
                          device_id: deviceInfo ? deviceInfo.uuid : "",
                        });
                        FirebaseAnalytics.logEvent("select_content", {
                          platform: deviceInfo ? deviceInfo.platform : "",
                        });
                        FirebaseAnalytics.logEvent("select_content", {
                          device_model: deviceInfo ? deviceInfo.model : "",
                        });
                        FirebaseAnalytics.logEvent("select_content", {
                          device_type: deviceInfo ? deviceInfo.osVersion : "",
                        });
                        FirebaseAnalytics.logEvent("select_content", {
                          device_os: deviceInfo
                            ? deviceInfo.operatingSystem
                            : "",
                        });
                      }

                      setToTopTarget();
                    })
                    .catch((e) => {
                      // console.log(JSON.stringify(e))
                    });
                })
                .catch((e) => {
                  // console.log(JSON.stringify(e))
                });
            })
            .catch((e) => {
              // console.log(JSON.stringify(e))
            });
        });
      });
    });
  }

  const decimalFunc = (Num) => {
    let temp = Num.toString().split(".");
    let retVal = 0;
    if (temp[1]) {
      retVal = Num.toFixed(2);
    } else {
      retVal = Num;
    }
    return retVal;
  };
  const retExpClsAmt = (clsAmt) => {
    let closingamt_disp: number = 0;
    Number.isNaN(clsAmt) || clsAmt === null || clsAmt === ""
      ? (closingamt_disp = 0)
      : (closingamt_disp = parseInt(clsAmt));
    return Math.abs(Number(closingamt_disp)) >= 1.0e9
      ? decimalFunc(Math.abs(Number(closingamt_disp)) / 1.0e9) + "b"
      : // Six Zeroes for Millions
      Math.abs(Number(closingamt_disp)) >= 1.0e6
      ? decimalFunc(Math.abs(Number(closingamt_disp)) / 1.0e6) + "m"
      : // Three Zeroes for Thousands
      Math.abs(Number(closingamt_disp)) >= 1.0e3
      ? decimalFunc(Math.abs(Number(closingamt_disp)) / 1.0e3) + "k"
      : decimalFunc(Math.abs(Number(closingamt_disp)));
  };

  function doNothing() {
    setShowPopover({ open: false, event: undefined });
  }

  return prospectProps ? (
    isChecked === "card" ? (
      <IonCol size-xs="12" size-sm="6" sizeXl="4" style={styles.col}>
        <IonCard style={styles.card}>
          <IonCardHeader style={styles.cardHeader}>
            <IonItem style={styles.item} lines="none">
              <IonAvatar style={{ width: 48, height: 48 }}>
                <img
                  src={
                    hs_avatar_filemanager_key
                      ? `${imgbaseuri}${hs_avatar_filemanager_key}`
                      : "/assets/empty-avatar.svg"
                  }
                  alt=""
                />
                {toptargetflg && selectedSeg !== "allclients" ? (
                  <React.Fragment>
                    <IonIcon
                      class="toptargetsmallstar"
                      color="greyscale"
                      icon={smallstar}
                    />
                    {Number(toptargetrank).toString().length <= 1 ? (
                      <IonText class="toptargetsmallrank_a">
                        {toptargetrank}
                      </IonText>
                    ) : (
                      <IonText class="toptargetsmallrank_b">
                        {toptargetrank}
                      </IonText>
                    )}
                  </React.Fragment>
                ) : null}
              </IonAvatar>
              <IonLabel style={styles.label}>
                <IonRow style={{ marginBottom: 4 }}>
                  <IonText class="fs-18 mfs-14" style={styles.title}>
                    {`${firstname} ${
                      middle_name !== null && middle_name !== ""
                        ? middle_name
                        : ""
                    } ${lastname}`}
                  </IonText>
                </IonRow>
                <IonRow>
                  <IonText color="greyscale" style={styles.desc}>
                    {`${
                      first_name_arabic !== null && first_name_arabic !== ""
                        ? first_name_arabic
                        : ""
                    } ${
                      arabic_middle_name !== null && arabic_middle_name !== ""
                        ? arabic_middle_name
                        : ""
                    } ${
                      arabic_last_name !== null && arabic_last_name !== ""
                        ? arabic_last_name
                        : ""
                    }`}
                  </IonText>
                  {selectedSeg === "allclients" ? (
                    <React.Fragment>
                      <IonText></IonText>
                      <IonIcon
                        class="fs-6"
                        icon={ellipse}
                        style={styles.period}
                      />
                      <IonText color="greyscale" style={styles.desc}>
                        {" "}
                        {onboarding_id}
                      </IonText>
                    </React.Fragment>
                  ) : null}
                </IonRow>
              </IonLabel>
            </IonItem>
            <IonRow>
              <IonCol sizeLg="5" sizeSm="6" style={styles.nopad}>
                <IonItem lines="none" style={styles.subText}>
                  <IonIcon
                    icon={wallet}
                    style={{ width: 24, height: 24, marginRight: 8 }}
                  ></IonIcon>

                  {selectedSeg !== "allclients" ? (
                    <IonLabel color="greyscale">
                      ${retExpClsAmt(amount_status)}
                      {expected_closing_probability !== undefined &&
                      expected_closing_probability !== "" &&
                      expected_closing_probability !== null
                        ? "/" + expected_closing_probability
                        : ""}
                    </IonLabel>
                  ) : (
                    <IonLabel color="greyscale">
                      ${retExpClsAmt(ClientAUM)} AUM
                    </IonLabel>
                  )}
                </IonItem>
              </IonCol>
              <IonCol sizeLg="5" sizeSm="6" style={styles.nopad}>
                {segment !== null && segment !== "" ? (
                  <IonItem lines="none" style={styles.subText}>
                    <IonIcon
                      icon={royal}
                      style={{ width: 24, height: 24, marginRight: 8 }}
                    ></IonIcon>
                    <IonLabel color="greyscale">{segment}</IonLabel>
                  </IonItem>
                ) : null}
              </IonCol>
            </IonRow>
            <IonItem lines="none" style={styles.phEmail}>
              <IonButton mode="ios" className="call-buttons" fill="clear">
                {email && email !== null ? (
                  <a className="profhref" href={"mailto:" + email}>
                    <IonIcon
                      icon={emailicon}
                      style={{ width: 24, height: 24, marginRight: 8 }}
                    ></IonIcon>
                  </a>
                ) : (
                  <IonIcon
                    icon={emailicon}
                    style={{ width: 24, height: 24, marginRight: 8 }}
                  ></IonIcon>
                )}
              </IonButton>
              {email && email !== null ? (
                <IonText color="greyscale">{email}</IonText>
              ) : null}
            </IonItem>

            <IonItem lines="none" style={styles.phEmail}>
              <IonButton mode="ios" className="call-buttons" fill="clear">
                {phone && phone !== null ? (
                  <a className="profhref" href={"tel:" + phone}>
                    <IonIcon
                      icon={phonecall}
                      style={{ width: 24, height: 24, marginRight: 8 }}
                    ></IonIcon>
                  </a>
                ) : (
                  <IonIcon
                    icon={phonecall}
                    style={{ width: 24, height: 24, marginRight: 8 }}
                  ></IonIcon>
                )}
              </IonButton>
              {phone && phone !== null ? (
                <IonText color="greyscale">{phone}</IonText>
              ) : null}
            </IonItem>

            <IonItem lines="none" style={styles.phEmail}>
              <IonButton mode="ios" className="call-buttons" fill="clear">
                {/* {mobilephone && mobilephone !== null ?<a className="profhref" href={"tel:" + mobilephone}> */}
                {mobilephone && mobilephone !== null ? (
                  <a
                    className="profhref"
                    href={`https://api.whatsapp.com/send?phone=${mobilephone}`}
                  >
                    <IonIcon
                      icon={Whatsup}
                      style={{ width: 24, height: 24, marginRight: 8 }}
                    ></IonIcon>
                  </a>
                ) : (
                  <IonIcon
                    icon={Whatsup}
                    style={{ width: 24, height: 24, marginRight: 8 }}
                  ></IonIcon>
                )}
              </IonButton>
              {mobilephone && mobilephone !== null ? (
                <IonText color="greyscale">{mobilephone}</IonText>
              ) : null}
            </IonItem>
            {/* {mobilephone && mobilephone !== null  && mobilephone !== "" ?(
            <IonItem lines="none" style={styles.phEmail}>
                    <IonButton
                      mode="ios"
                      className="call-buttons"
                      fill="clear"
                    >
                 <a className="profhref" href={"tel:" + mobilephone}>
                      <IonIcon
                      icon={Whatsup}
                      style={{ width: 24, height: 24, marginRight: 8 }}
                      ></IonIcon>
                      </a>                 
                    </IonButton>
              {mobilephone && mobilephone !== null ? (
                <IonText color="greyscale">
                  {mobilephone}
                </IonText>
              ) : null}
            </IonItem> 
            ):null}
              
            </IonRow> */}

            <IonItem lines="full" style={styles.subText_a}>
              {notes_last_contacted && notes_last_contacted !== null ? (
                <IonText color="greyscale">
                  Last Contact:{" "}
                  {`${moment(notes_last_contacted).format("ddd")}, ${moment(
                    notes_last_contacted
                  ).format("ll")}`}
                </IonText>
              ) : null}
            </IonItem>
          </IonCardHeader>
          <IonCardContent>
            <IonPopover
              cssClass="delete25popover"
              isOpen={show25Popover.open}
              event={show25Popover.event}
              onDidDismiss={(e) =>
                setshow25Popover({ open: false, event: undefined })
              }
            >
              <IonGrid class="rmvpopover_a">
                <IonRow class="rmvtarget"> Reached limit of 25 targets </IonRow>
                <IonRow class="conrmvtarget">
                  Please remove some of your top targets to add another{" "}
                </IonRow>
                <IonFooter class="conrmvfooter">
                  <IonRow>
                    <IonCol>
                      <IonButton
                        onClick={() =>
                          setshow25Popover({ open: false, event: undefined })
                        }
                        class="ConfirmationButton"
                        fill="clear"
                      >
                        OK
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonFooter>
              </IonGrid>
            </IonPopover>
            <IonPopover
              isOpen={showPopover.open}
              cssClass="deletetargetpopoverclass"
              event={showPopover.event}
              onDidDismiss={(e) =>
                setShowPopover({ open: false, event: undefined })
              }
              mode="md"
            >
              <IonCard>
                {
                  <div>
                    <IonGrid class="popoverGridClass">
                      <IonRow
                        style={{
                          color: "#828282",
                          justifyContent: "center",
                          fontSize: 28,
                        }}
                        class="ion-text-center ion-margin-top ion-margin-bottom"
                      >
                        Remove top target?
                      </IonRow>
                      <IonRow
                        style={{
                          color: "#828282",
                          justifyContent: "center",
                          fontSize: 14,
                        }}
                        class="ion-text-center ion-margin-bottom"
                      >
                        Are you sure you want to remove as top target?
                      </IonRow>
                      <IonRow
                        class="ion-text-center ion-margin-bottom ion-margin-top"
                        style={{
                          color: "#B99855",
                          marginTop: "30px",
                          borderTop: "1px solid rgba(34,34,34,0.5)",
                          cursor: "pointer",
                          justifyContent: "center",
                        }}
                      >
                        <IonCol
                          style={{
                            borderRight: "1px solid rgba(34,34,34,0.5)",
                          }}
                        >
                          <IonButton
                            onClick={() => {
                              DeleteTopTarget(id);
                            }}
                            class="ConfirmationButton"
                            fill="clear"
                          >
                            Yes
                          </IonButton>
                        </IonCol>
                        <IonCol>
                          <IonButton
                            onClick={() => doNothing()}
                            class="ConfirmationButton"
                            fill="clear"
                          >
                            No
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </div>
                }
              </IonCard>
            </IonPopover>
            <IonRow>
              <IonButtons style={{ width: "100%" }}>
                <IonButton
                  color="gold"
                  size="small"
                  fill="clear"
                  routerLink={`/contactinfo/${id}`}
                  mode="ios"
                >
                  View Profile
                </IonButton>
                {selectedSeg === "allprospects" && hubspot_owner_id ? (
                  texttoDisplay(id) == "Remove as Top Target" ? (
                    <IonButton
                      color="gold"
                      size="small"
                      fill="clear"
                      style={{ marginLeft: "auto" }}
                      //onClick={setToTopTarget}
                      onClick={() =>
                        setShowPopover({ open: true, event: undefined })
                      }
                      mode="ios"
                    >
                      Remove as Top Target
                    </IonButton>
                  ) : (
                    <IonButton
                      color="gold"
                      size="small"
                      fill="clear"
                      style={{ marginLeft: "auto" }}
                      onClick={() => AddTopTarget(id)}
                      mode="ios"
                      // routerLink="/toptargets"
                    >
                      Add as Top Target
                    </IonButton>
                  )
                ) : null}
              </IonButtons>
            </IonRow>
          </IonCardContent>
        </IonCard>
      </IonCol>
    ) : (
      <React.Fragment>
        <IonItem lines="none" style={{ "--padding-start": "0px" }}>
          <IonItem
            className="TargetIonItem"
            style={styles.listItem}
            lines="none"
          >
            {width > 576 ? (
              <IonRow style={{ width: "100%" }}>
                <IonCol size="1">
                  <IonAvatar
                    className="TargetAvatar"
                    style={{ "margin-top": "10px" }}
                  >
                    <img
                      src={
                        hs_avatar_filemanager_key
                          ? `${imgbaseuri}${hs_avatar_filemanager_key}`
                          : "/assets/empty-avatar.svg"
                      }
                      alt=""
                    />
                    {toptargetflg && selectedSeg !== "allclients" ? (
                      <React.Fragment>
                        <IonIcon
                          class="toptargetsmallstarlist"
                          color="greyscale"
                          icon={smallstar}
                        />
                        {Number(toptargetrank).toString().length <= 1 ? (
                          <IonText class="listallprosprank">
                            {toptargetrank}
                          </IonText>
                        ) : (
                          <IonText class="toptargetsmallrank_b">
                            {toptargetrank}
                          </IonText>
                        )}
                      </React.Fragment>
                    ) : null}
                  </IonAvatar>
                </IonCol>
                <IonCol
                  style={{ "margin-top": "8px" }}
                  sizeXl="8"
                  sizeLg="8"
                  sizeMd="8"
                >
                  <IonRow>
                    <IonLabel style={styles.listlabel}>
                      <IonText
                        class="ion-margin-start"
                        style={styles.title}
                        onClick={() => {
                          routetoProfile(id);
                        }}
                      >
                        {`${firstname} ${
                          middle_name !== null && middle_name !== ""
                            ? middle_name
                            : ""
                        } ${lastname}`}
                      </IonText>
                      <IonIcon
                        className="period-ellipse"
                        style={styles.period}
                        icon={ellipse}
                      ></IonIcon>
                      <IonText style={styles.desc}>
                        {`${
                          first_name_arabic !== null && first_name_arabic !== ""
                            ? first_name_arabic
                            : ""
                        } ${
                          arabic_middle_name !== null &&
                          arabic_middle_name !== ""
                            ? arabic_middle_name
                            : ""
                        } ${
                          arabic_last_name !== null && arabic_last_name !== ""
                            ? arabic_last_name
                            : ""
                        }`}
                      </IonText>
                    </IonLabel>
                  </IonRow>
                  <IonRow>
                    <IonIcon
                      icon={wallet}
                      style={{
                        width: 20,
                        height: 20,
                        marginRight: 8,
                        marginLeft: 8,
                      }}
                    ></IonIcon>
                    {selectedSeg !== "allclients" ? (
                      <IonLabel color="greyscale">
                        ${retExpClsAmt(amount_status)}
                        {expected_closing_probability !== undefined &&
                        expected_closing_probability !== "" &&
                        expected_closing_probability !== null
                          ? "/" + expected_closing_probability
                          : ""}
                      </IonLabel>
                    ) : (
                      <IonLabel color="greyscale">
                        ${retExpClsAmt(ClientAUM)} AUM
                      </IonLabel>
                    )}
                    {segment !== null && segment !== "" ? (
                      <>
                        <IonIcon
                          icon={royal}
                          style={{
                            width: 20,
                            height: 20,
                            marginRight: 8,
                            marginLeft: 8,
                          }}
                        ></IonIcon>
                        <IonLabel color="greyscale">{segment}</IonLabel>
                      </>
                    ) : null}

                    {selectedSeg === "allclients" ? (
                      <>
                        <IonIcon
                          icon={ellipse}
                          className="allclientsellipse"
                        ></IonIcon>
                        <IonLabel color="greyscale">{onboarding_id}</IonLabel>
                      </>
                    ) : null}
                  </IonRow>
                  <IonRow style={styles.postionleft6}>
                    <IonItem lines="none" style={styles.phEmail}>
                      <IonButton
                        mode="ios"
                        className="call-buttons"
                        fill="clear"
                      >
                        {email && email !== null ? (
                          <a className="profhref" href={"mailto:" + email}>
                            <IonIcon
                              icon={emailicon}
                              style={{ width: 24, height: 24, marginRight: 8 }}
                            ></IonIcon>
                          </a>
                        ) : (
                          <IonIcon
                            icon={emailicon}
                            style={{ width: 24, height: 24, marginRight: 8 }}
                          ></IonIcon>
                        )}
                      </IonButton>
                      {email && email !== null ? (
                        <IonText color="greyscale">{email}</IonText>
                      ) : null}
                    </IonItem>
                    <IonButton mode="ios" className="call-buttons" fill="clear">
                      {phone && phone !== null ? (
                        <a className="profhref" href={"tel:" + phone}>
                          <IonIcon
                            icon={phonecall}
                            style={{ width: 24, height: 24, marginRight: 8 }}
                          ></IonIcon>
                        </a>
                      ) : null
                      // <IonIcon
                      // icon={phonecall}
                      // style={{ width: 24, height: 24, marginRight: 8 }}
                      // ></IonIcon>
                      }
                    </IonButton>

                    {/* <IonIcon
                      icon={phonecall}
                      style={{
                        width: 20,
                        height: 20,
                        marginRight: 8,
                        marginLeft: 8,
                      }}
                    ></IonIcon> */}
                    <IonLabel color="greyscale" style={styles.positiontop10}>
                      {phone}
                    </IonLabel>
                    {width > 1024 && mobilephone && mobilephone !== null ? (
                      <React.Fragment>
                        <IonButton
                          mode="ios"
                          className="call-buttons"
                          fill="clear"
                        >
                          <a
                            className="profhref"
                            href={`https://api.whatsapp.com/send?phone=${mobilephone}`}
                          >
                            <IonIcon
                              icon={Whatsup}
                              style={{
                                width: 24,
                                height: 24,
                                marginRight: 8,
                                paddingLeft: 20,
                              }}
                            ></IonIcon>
                          </a>
                        </IonButton>
                        <IonLabel
                          color="greyscale"
                          style={styles.positiontop10}
                        >
                          {mobilephone}
                        </IonLabel>
                      </React.Fragment>
                    ) : null}
                  </IonRow>
                  {width <= 1024 && mobilephone && mobilephone !== null ? (
                    // {mobilephone && mobilephone !== null ?(
                    <IonRow id="list-watsapp-nwln">
                      <IonButton
                        mode="ios"
                        className="call-buttons"
                        fill="clear"
                      >
                        {/* <a className="profhref" href={"tel:" + mobilephone}> */}
                        <a
                          className="profhref"
                          href={`https://api.whatsapp.com/send?phone=${mobilephone}`}
                        >
                          <IonIcon
                            icon={Whatsup}
                            style={{
                              width: 24,
                              height: 24,
                              marginRight: 8,
                              paddingLeft: 20,
                            }}
                          ></IonIcon>
                        </a>
                      </IonButton>
                      <IonLabel color="greyscale" style={styles.positiontop10}>
                        {mobilephone}
                      </IonLabel>
                    </IonRow>
                  ) : null}
                </IonCol>
                <IonCol sizeXl="3" sizeLg="3" sizeMd="3">
                  {selectedSeg === "allprospects" && hubspot_owner_id ? (
                    texttoDisplay(id) == "Remove as Top Target" ? (
                      <IonButton
                        color="gold"
                        size="small"
                        fill="clear"
                        onClick={() =>
                          setShowPopover({ open: true, event: undefined })
                        }
                        mode="ios"
                        style={{
                          "margin-top": "16px",
                          "--padding-start": "none",
                          "--padding-end": "none",
                        }}
                      >
                        Remove as Top Target
                      </IonButton>
                    ) : (
                      <IonButton
                        color="gold"
                        size="small"
                        fill="clear"
                        onClick={() => AddTopTarget(id)}
                        mode="ios"
                        style={{
                          "--padding-start": "none",
                          "--padding-end": "none",
                          "margin-top": "20px",
                        }}
                      >
                        Add as Top Target
                      </IonButton>
                    )
                  ) : null}
                </IonCol>
              </IonRow>
            ) : null}

            <IonCardContent>
              <IonPopover
                cssClass="delete25popover"
                isOpen={show25Popover.open}
                event={show25Popover.event}
                onDidDismiss={(e) =>
                  setshow25Popover({ open: false, event: undefined })
                }
              >
                <IonGrid class="rmvpopover_a">
                  <IonRow class="rmvtarget">
                    {" "}
                    Reached limit of 25 targets{" "}
                  </IonRow>
                  <IonRow class="conrmvtarget">
                    Please remove some of your top targets to add another{" "}
                  </IonRow>
                  <IonFooter class="conrmvfooter">
                    <IonRow>
                      <IonCol>
                        <IonButton
                          onClick={() =>
                            setshow25Popover({ open: false, event: undefined })
                          }
                          class="ConfirmationButton"
                          fill="clear"
                        >
                          OK
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonFooter>
                </IonGrid>
              </IonPopover>
              <IonPopover
                isOpen={showPopover.open}
                cssClass="deletetargetpopoverclass"
                event={showPopover.event}
                onDidDismiss={(e) =>
                  setShowPopover({ open: false, event: undefined })
                }
                mode="md"
              >
                <IonCard>
                  {
                    <div>
                      <IonGrid class="popoverGridClass">
                        <IonRow
                          style={{
                            color: "#828282",
                            justifyContent: "center",
                            fontSize: 28,
                          }}
                          class="ion-text-center ion-margin-top ion-margin-bottom"
                        >
                          Remove top target?
                        </IonRow>
                        <IonRow
                          style={{
                            color: "#828282",
                            justifyContent: "center",
                            fontSize: 14,
                          }}
                          class="ion-text-center ion-margin-bottom"
                        >
                          Are you sure you want to remove as top target?
                        </IonRow>
                        <IonRow
                          class="ion-text-center ion-margin-bottom ion-margin-top"
                          style={{
                            color: "#B99855",
                            marginTop: "30px",
                            borderTop: "1px solid rgba(34,34,34,0.5)",
                            cursor: "pointer",
                            justifyContent: "center",
                          }}
                        >
                          <IonCol
                            style={{
                              borderRight: "1px solid rgba(34,34,34,0.5)",
                            }}
                          >
                            <IonButton
                              onClick={() => {
                                DeleteTopTarget(id);
                              }}
                              class="ConfirmationButton"
                              fill="clear"
                            >
                              Yes
                            </IonButton>
                          </IonCol>
                          <IonCol>
                            <IonButton
                              onClick={() => doNothing()}
                              class="ConfirmationButton"
                              fill="clear"
                            >
                              No
                            </IonButton>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </div>
                  }
                </IonCard>
              </IonPopover>
            </IonCardContent>
          </IonItem>
        </IonItem>
      </React.Fragment>
    )
  ) : null;
  function texttoDisplay(contid) {
    let temptext = "Add as Top Target";
    if (topTarget !== null) {
      topTarget.forEach((obj) => {
        if (obj.contactid == contid) {
          temptext = "Remove as Top Target";
        }
      });
    }
    return temptext;
  }
};

export default ListAll;
