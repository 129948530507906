import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonGrid,
  IonRow,
  IonIcon,
  IonInput,
  IonCol,
  IonText,
  IonLoading,
} from "@ionic/react";
import CryptoJS from "crypto-js";
import Base64 from "crypto-js/enc-base64";
import familyoffice from "src/icons/FamilyOffice.svg";
import api from "src/api";
import { useHistory } from "react-router-dom";
import { Plugins } from "@capacitor/core";
import { Geolocation, Geoposition } from "@ionic-native/geolocation";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";
import {
  InAppBrowser,
  InAppBrowserEvent,
  InAppBrowserObject,
  InAppBrowserOptions,
} from "@ionic-native/in-app-browser";
// import { noteTableCreate } from "src/offlineUtils/DbOperations";
// import { prospTableCreate } from "src/offlineUtils/ProspectDbOperations";
import moment from "moment";
import { initNetworkListen } from "src/offlineUtils/networkUtil";
import {
  registerBioMetricAuth,
  showBioMetricAuth,
} from "src/pages/Auth/BioMetricAuth";
const { Storage, Device } = Plugins;
const location = window.location;

//var textname="aloapam vishnupriya";
const encrypt = (text) => {
  return Base64.stringify(CryptoJS.enc.Utf8.parse(text));
};
const decrypt = (decryptVlaue) => {
  return CryptoJS.enc.Base64.parse(decryptVlaue).toString(CryptoJS.enc.Utf8);
};

const options: InAppBrowserOptions = {
  zoom: "no",
  location: "no",
  toolbar: "no",
  hidden: "no",
  hideurlbar: "yes",
  toolbarposition: "bottom",
};

const styles = {
  inp: {
    background: "#222222",
    borderRadius: 2,
    textAlgin: "center",
    marginTop: 10,
    marginLeft: 0,
    "--padding-start": "8px",
    color: "white",
  },
  whitecolor: {
    color: "#FFFFFF",
  },
  buttonstyle: {
    width: "75%",
    border: "2px solid #B99855",
    boxSizing: "border-box",
    "--border-style": "none",
  },
};

const setObject = async (Obj) => {
  // console.log(Obj);
  await Storage.set({
    key: "userId",
    value: encrypt(
      JSON.stringify({
        id: Obj.id,
        fName: Obj.fName,
        lName: Obj.lName,
        email: Obj.email,
        teams: Obj.teams,
      })
    ),
  });
};
const setProperties = async (Obj) => {
  await Storage.set({
    key: "enumproperties",
    value: JSON.stringify({
      resultsArr: Obj,
    }),
  });
};

const setTopTargets = async (Arr) => {
  await Storage.set({
    key: "toptargets",
    value: JSON.stringify({
      result: Arr,
    }),
  });
};

const Login = () => {
  const routeHist = useHistory();
  // console.log(routeHist)
  const [username, usernameFunc] = useState("");
  const [password, passwordFunc] = useState("");
  const [isLoading, setLoading] = useState<boolean>(false);
  const [Validationerror, ValidationerrorFunc] = useState("");
  const [showpwd, showpwdFunc] = useState<any>("password");
  //const [position, setPosition] = useState<Geoposition>();

  const getUserEmail = async () => {
    const ret: any = await Storage.get({ key: "userId" });
    const temp = JSON.parse(decrypt(ret.value));
    return temp && temp.email ? temp.email : null;
  };
  const getUserId = async () => {
    const ret: any = await Storage.get({ key: "userId" });
    return JSON.parse(decrypt(ret.value)).id;
  };

  const getLocation = async () => {
    const position = await Geolocation.getCurrentPosition();
    // console.log(Geolocation);
    //setPosition(position);
    return position;
  };

  // const getfirebase = async () => {
  //     FirebaseAnalytics
  //     const firebase = await FirebaseAnalytics.analytics.logEvent("my_event", {param1: "value1"});
  //     return firebase
  // }

  const [devicePlatform, setDevicePlatform] = useState("");
  useEffect(() => {
    Device.getInfo().then((deviceInfo) => {
      setDevicePlatform(deviceInfo.platform);
    });
  }, []);

  function loginBioMetric() {
    showBioMetricAuth()
      .then((result) => {
        if (result) {
          routeHist.push("/dashboard");
        } else {
          ValidationerrorFunc("biometric authentication failed.");
        }
      })
      .catch((error) => {
        console.log("error login:", JSON.stringify(error));
      });
  }

  function loginfunc() {
    // console.log("hello");
    // calltoUpdateLocation();
    setLoading(true);
    api.getOwnerswithProps(username).then((resp) => {
      if (resp.results[0]) {
        if (parseInt(password) === resp.results[0].id) {
          let UserID = resp.results[0].id;
          //console.log(UserID);
          //calltoUpdateLocation()
          setObject({
            id: resp.results[0].id,
            fName: resp.results[0].firstName,
            lName: resp.results[0].lastName,
            email: resp.results[0].email,
            teams: resp.results[0].teams,
          }).then(() => {
            api.getContactPropertiesByPropertyBatch().then((resp) => {
              setProperties(resp.results)
                .then(() => {
                  api.getTopTargets(UserID).then((ttresp) => {
                    let temptt: any = {};
                    //console.log(ttresp.prospect_list);

                    // let payload: any = {
                    //     "inputs": [],
                    //     "properties": ["hubspot_owner_id"]
                    // };
                    // targetsArr.forEach(target => {
                    //     payload.inputs.push({id: target.contactid})
                    // })
                    // api.getTopTargetContacts(payload).then(response => {
                    //     console.log(response);
                    // })

                    ttresp && ttresp.prospect_list
                      ? (temptt = ttresp.prospect_list)
                      : (temptt = []);
                    let payload: any = {
                      inputs: [],
                      properties: ["hubspot_owner_id"],
                    };
                    //console.log(temptt);
                    let temptoptrgt = new Function("return " + temptt)();
                    // console.log(temptoptrgt);
                    if (temptoptrgt) {
                      temptoptrgt.map((target) => {
                        payload.inputs.push({ id: target.contactid });
                      });
                    }
                    api.getTopTargetContacts(payload).then((response) => {
                      // console.log(response.results);
                      let updContArray: any = [];
                      let temprank = 0;
                      let tempflag = "N";
                      let contodelarr: any = [];
                      let contodelstr = "";
                      for (let i = 0; i < response.results.length; i++) {
                        if (
                          response.results[i].properties.hubspot_owner_id !=
                          UserID
                        ) {
                          contodelarr.push(parseInt(response.results[i].id));
                        }
                      }
                      if (temptoptrgt) {
                        for (let i = 0; i < temptoptrgt.length; i++) {
                          if (contodelarr.includes(temptoptrgt[i].contactid)) {
                            tempflag = "Y";
                          } else {
                            if (tempflag === "Y") {
                              temprank = i;
                            } else {
                              temprank = i + 1;
                            }
                            updContArray.push({
                              contactid: temptoptrgt[i].contactid,
                              rank: temprank,
                            });
                          }
                        }
                      }

                      //console.log(updContArray);
                      //console.log(JSON.stringify(updContArray));
                      //console.log(temptt);
                      setTopTargets(JSON.stringify(updContArray)).then(
                        async () => {
                          // await noteTableCreate()
                          // await prospTableCreate()
                          // Device.getInfo().then((deviceInfo) => {
                          //     //console.log(deviceInfo)
                          //     if(deviceInfo.platform !== "web") {
                          await initNetworkListen();
                          //     }
                          // })

                          routeHist.push("/dashboard");
                          calltoUpdateLocation();
                          await registerBioMetricAuth();
                        }
                      );
                    });
                  });
                })
                .catch(() => {
                  passwordFunc("");
                  usernameFunc("");
                  ValidationerrorFunc("Username and password is not matching");
                });
            });
          });
        } else {
          passwordFunc("");
          usernameFunc("");
          ValidationerrorFunc("Username and password is not matching");
        }
      } else {
        passwordFunc("");
        usernameFunc("");
        ValidationerrorFunc("Username and password is not matching");
      }
      setLoading(false);
    });
  }

  function redirectO365() {
    Device.getInfo().then((deviceInfo) => {
      //console.log(deviceInfo)
      if (deviceInfo.platform !== "web") {
        // const iab: InAppBrowserObject = InAppBrowser.create('https://rm-app-ent.auth.us-east-2.amazoncognito.com/oauth2/authorize?identity_provider=RMAppProvider&redirect_uri=capacitor://localhost:8100/auth/authenticated?&response_type=TOKEN&client_id=550v59he370998gnkpromfkcvt',"_blank", options)//Internal
        const iab: InAppBrowserObject = InAppBrowser.create(
          "https://#/oauth2/authorize?identity_provider=RMappprovider&redirect_uri=capacitor://localhost:8100/auth/authenticated?&response_type=TOKEN&client_id=5bnc3gnmskh8g77jh2qhkjn78k",
          "_blank",
          options
        ); //Prod

        iab.on("loadstart").subscribe((e: InAppBrowserEvent) => {
          // console.log("LoadStart", e)
          if (e.url.indexOf("#access_token") > 0) {
            let hashpart = e.url.split("#")[1];
            iab.close();
            routeHist.push(`/auth/authenticated?#${hashpart}`);
            //calltoUpdateLocation();
          }
        });
        iab.on("loadstop").subscribe((e: InAppBrowserEvent) => {
          // console.log("Access token index: "+e.url.indexOf("#access_token"))
          // console.log("Loadstop")
          if (e.url.indexOf("#access_token") > 0) {
            //console.log("token found", e.url)
            let hashpart = e.url.split("#")[1];
            iab.close();
            routeHist.push(`/auth/authenticated?#${hashpart}`);
            // calltoUpdateLocation();
          }
        });
        iab.on("loaderror").subscribe((e: InAppBrowserEvent) => {
          // console.log("LoadError",e)
          if (e.url.indexOf("#access_token") > 0) {
            //console.log("token found", e.url)
            let hashpart = e.url.split("#")[1];
            iab.close();
            routeHist.push(`/auth/authenticated?#${hashpart}`);
            //calltoUpdateLocation();
          }
        });
      } else {
        // InAppBrowser.create(`https://rm-app-ent.auth.us-east-2.amazoncognito.com/oauth2/authorize?identity_provider=RMAppProvider&redirect_uri=${location.origin}/auth/authenticated?&response_type=TOKEN&client_id=550v59he370998gnkpromfkcvt`,"_self", options)//Internal Instance
        InAppBrowser.create(
          `https://login.microsoftonline.com/722fb109-13f1-4aa0-9a39-dd9440082369/oauth2/v2.0/authorize?identity_provider=RMappprovider&redirect_uri=${location.origin}/auth/authenticated?&response_type=token&client_id=77a8fd1d-b864-4063-9414-bbfb7375a2bd&scope=https://graph.microsoft.com/.default&state=12345`,
          "_self",
          options
        ); //Prod
      }
    });
  }

  function calltoUpdateLocation() {
    Device.getInfo().then((deviceInfo) => {
      getUserEmail().then((useremail) => {
        getUserId().then((userId) => {
          if (deviceInfo.platform == "web") {
            window.globalThis.firebase.analytics().setUserId(useremail);
            window.globalThis.firebase.analytics().logEvent("select_content", {
              user_name: useremail,
              content_type: "LoggedIn",
              item_id: useremail,
              device_id: deviceInfo ? deviceInfo.uuid : "",
              platform: deviceInfo ? deviceInfo.platform : "",
              device_model: deviceInfo ? deviceInfo.model : "",
              device_type: deviceInfo ? deviceInfo.osVersion : "",
              device_os: deviceInfo ? deviceInfo.operatingSystem : "",
            });
          } else {
            FirebaseAnalytics.setUserId(useremail);
            FirebaseAnalytics.logEvent("select_content", {
              user_name: useremail,
            });
            FirebaseAnalytics.logEvent("select_content", {
              content_type: "LoggedIn",
            });
            FirebaseAnalytics.logEvent("select_content", {
              item_id: useremail,
            });
            FirebaseAnalytics.logEvent("select_content", {
              device_id: deviceInfo ? deviceInfo.uuid : "",
            });
            FirebaseAnalytics.logEvent("select_content", {
              platform: deviceInfo ? deviceInfo.platform : "",
            });
            FirebaseAnalytics.logEvent("select_content", {
              device_model: deviceInfo ? deviceInfo.model : "",
            });
            FirebaseAnalytics.logEvent("select_content", {
              device_type: deviceInfo ? deviceInfo.osVersion : "",
            });
            FirebaseAnalytics.logEvent("select_content", {
              device_os: deviceInfo ? deviceInfo.operatingSystem : "",
            });
          }

          //console.log(useremail);
          getLocation()
            .then((loc) => {
              // console.log(loc.coords);
              let newdate = new Date();
              let currDate = moment(newdate)
                .utc()
                .format("x");
              // console.log(currDate);
              let loction: any = "";
              //if(loc && loc.coords)
              loction =
                loc && loc.coords
                  ? loc.coords.latitude + "," + loc.coords.longitude
                  : "";
              let payloads: any = {
                lastLogin: currDate,
                rmEmail: useremail,
                location: loction,
              };
              api.updatelocation(payloads).then((resp) => {
                // console.log(resp);
                // api.getlocation(useremail).then(resp => {
                //    // console.log(resp);
                // })
              });
            })
            .catch((e) => {
              //console.log(e.message);
              if (e.message == "User denied Geolocation") {
                let newdate = new Date();
                let currDate = moment(newdate)
                  .utc()
                  .format("x");
                let payloads: any = {
                  lastLogin: currDate,
                  rmEmail: useremail,
                  location: "",
                };
                api.updatelocation(payloads).then((resp) => {
                  // api.getlocation(useremail).then(resp => {
                  // // console.log(resp);
                  // })
                });
              }
            });
        });
      });
    });
  }

  return (
    <IonGrid style={{ margin: "auto" }}>
      <IonLoading
        cssClass="my-custom-loading-class"
        isOpen={isLoading}
        message={"Please wait..."}
      />
      <IonRow
        class="login-margin-top"
        style={{ justifyContent: "center", marginBottom: 40 }}
      >
        <IonCol size="4"></IonCol>
        <IonCol style={{ textAlign: "center" }} size="4">
          <IonIcon
            style={{ width: 150, height: 66 }}
            icon={familyoffice}
          ></IonIcon>
        </IonCol>
        <IonCol size="4"></IonCol>
      </IonRow>
      <IonRow style={{ marginTop: 60 }}>
        <IonCol size-xs="2" size-sm="4"></IonCol>
        <IonCol size-xs="8" size-sm="4">
          <IonText style={styles.whitecolor}>*Username</IonText>
          <IonInput
            style={styles.inp}
            onInput={(e) => {
              usernameFunc((e.target as HTMLInputElement).value);
              ValidationerrorFunc("");
            }}
            value={username}
            name="username"
            placeholder="Enter username"
          ></IonInput>
        </IonCol>
        <IonCol size-xs="2" size-sm="4"></IonCol>
      </IonRow>
      {/* <IonRow class="ion-margin-top">
                <IonCol size-xs="2" size-sm="4"></IonCol>
                <IonCol size-xs="8" size-sm="4">
                <IonText style={styles.whitecolor} >*Password</IonText>
                <div >
                    <IonInput style={styles.inp}  onInput={event => {passwordFunc((event.target as HTMLInputElement).value);ValidationerrorFunc("")}} value={password} name="password" type={showpwd} placeholder="Enter password">
                        <IonText color="gold" onClick={()=>{showpwd==="password"?showpwdFunc("text"):showpwdFunc("password")}} style={{position: "absolute",right: 8,cursor:"pointer"}} slot="end">Show</IonText>
                    </IonInput>
                </div>
                </IonCol>
                <IonCol size-xs="2" size-sm="4"></IonCol>
            </IonRow> */}
      <IonRow>
        <IonCol size="4"></IonCol>
        <IonCol style={{ fontStyle: "italic", color: "red" }} size="4">
          {Validationerror}
        </IonCol>
        <IonCol size="4"></IonCol>
      </IonRow>
      {/* <IonRow style={{ marginTop: 20 }}>
                    <IonCol size-xs="2" size-sm="4"></IonCol>
                    <IonCol style={{ textAlign: "center" }} size-xs="8" size-sm="4">
                        <IonButton type="submit" style={styles.buttonstyle} onClick={() => { loginfunc() }} fill="outline"
                            color="gold"
                        >
                            Sign In
                        </IonButton>
                    </IonCol>
                    <IonCol size-xs="2" size-sm="4"></IonCol>
                </IonRow> */}
      <IonRow style={{ marginTop: 20 }}>
        <IonCol size-xs="2" size-sm="4"></IonCol>
        <IonCol style={{ textAlign: "center" }} size-xs="8" size-sm="4">
          <IonButton
            type="submit"
            style={styles.buttonstyle}
            onClick={() => {
              redirectO365();
            }}
            fill="outline"
            color="gold"
          >
            Login with O365
          </IonButton>
        </IonCol>
        <IonCol size-xs="2" size-sm="4"></IonCol>
      </IonRow>
      {devicePlatform !== "web" ? (
        <IonRow style={{ marginTop: 20 }}>
          <IonCol size-xs="2" size-sm="4"></IonCol>
          <IonCol style={{ textAlign: "center" }} size-xs="8" size-sm="4">
            <IonButton
              type="submit"
              style={styles.buttonstyle}
              onClick={() => {
                loginBioMetric();
              }}
              fill="outline"
              color="gold"
            >
              Biometric Login
            </IonButton>
          </IonCol>
          <IonCol size-xs="2" size-sm="4"></IonCol>
        </IonRow>
      ) : (
        ""
      )}

      {/*<IonRow style={{ marginTop: 20 }}>
                <IonCol size="4"></IonCol>
                <IonCol style={{ textAlign: "center" }} size="4">
                    <IonText color="gold" > Forgot Password? </IonText>
                </IonCol>
                <IonCol size="4"></IonCol>
            </IonRow> */}
    </IonGrid>
  );
};
export default Login;
