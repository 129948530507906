import React, { useEffect, useState } from "react";
import {
  IonLoading,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButton,
  IonButtons,
  IonIcon,
  IonPage,
  IonText,
  IonRow,
  IonCol,
  IonGrid,
} from "@ionic/react";
import { chevronBack } from "ionicons/icons";
import { useHistory } from "react-router";
import VideoCard from "../../../components/Material/VideoCard";
import api from "src/api";
const styles = {
  toolbar: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
  },
};
export default function WeeklyVideoSeeMore() {
  const router = useHistory();
  const backButtonText = "Material ";
  const [isLoading, setLoading] = useState(false);
  const [weeklyUpdateData, setWeeklyUpdateData] = useState<any>([]);
  useEffect(() => {
    getWeeklyUpdateData();
  }, []);

  const getWeeklyUpdateData = () => {
    setLoading(true);
    return api
      .getWeeklyUpdateData()
      .then((response) => {
        if (response.stories.length > 0) {
          const sortedData = response.stories.sort(function(a, b) {
            return (
              new Date(a.content.PublishDate).valueOf() -
              new Date(b.content.PublishDate).valueOf()
            );
          });
          setWeeklyUpdateData(sortedData.reverse());
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode="md" style={styles.toolbar}>
          <IonButtons slot="start">
            <IonButton
              style={{ textTransform: "none" }}
              color="white"
              routerLink="/material"
            >
              <IonIcon icon={chevronBack}></IonIcon>
              {backButtonText}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent
        style={{
          "--background": "none",
        }}
      >
        <IonLoading
          cssClass="my-custom-loading-class"
          isOpen={isLoading}
          onDidDismiss={() => setLoading(false)}
          message={"Please wait..."}
        />
        <IonGrid class="" style={{ marginTop: 24 }}>
          <IonRow
            class="mt-6 margin-set mobile-top-content m-marginleft-12 m-marginright-12 dashgrid-mobile"
            style={{ margin: "24px 32px" }}
          >
            <IonRow class="" style={{ width: "100%", marginBottom: 20 }}>
              <IonText
                className="to-do"
                style={{
                  fontWeight: 300,
                  fontSize: 24,
                  lineHeight: "120%",
                  color: "#C7C7C7",
                }}
              >
                Weekly Market Updates
              </IonText>
            </IonRow>
            <IonGrid style={{ padding: "0px" }}>
              <IonRow>
                {weeklyUpdateData.map((item) => (
                  <IonCol
                    style={{ paddingLeft: "0px", cursor: "pointer" }}
                    size="12"
                    size-md="6"
                    size-xl="4"
                    onClick={() => router.push(`/weeklyUpdateVideo/${item.id}`)}
                  >
                    <VideoCard
                      imgSrc={item.content.PosterImage.filename}
                      descp={item.content.Description}
                      title={item.content.Title}
                      publishDate={item.content.PublishDate.slice(0, 10)}
                      duration={item.content.EstimatedDuration}
                    />
                  </IonCol>
                ))}
              </IonRow>
            </IonGrid>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
}
