import {
  IonItem,
  IonText,
  IonButton,
  IonRow,
  IonPopover,
  IonIcon,
} from "@ionic/react";
import React from 'react';
import "./Popup.css";


interface ShareProps {
  open: boolean;
  event: Event | undefined;
  setShowPopover: Function
}

const SharePopupData = [
  {
    label: "Risk Profile Questionnaire",
    action: "Share"
  },
  {
    label: "Recommended Portfolio Asset Allocation",
    action: "Share"
  },
  {
    label: "Opportunities",
    action: "Choose"
  },
  {
    label: "Updates",
    action: "Choose"
  },
  {
    label: "Webinars",
    action: "Choose"
  },
  {
    label: "Articles",
    action: "Choose"
  },
  {
    label: "Quarterly Presentations",
    action: "Choose"
  },  
]
const Sharepopup: React.FC<ShareProps> = ({
  open,
  event,
  setShowPopover
}) => {

  return (
    <IonPopover
      isOpen={open}
      showBackdrop={true}
      cssClass='SharePopup-custom-class'
      event={event}
      onDidDismiss={e => setShowPopover({ open: false, event: undefined })}
      mode="ios"
    >
      <IonRow style={{ marginBottom: 32 }}>
        <IonText className="ShareText">
          Share Options
        </IonText>
      </IonRow>
      {SharePopupData.map(share =>

        <IonItem key={share.label} className="ShowPopupItem" lines="none">
          <IonText>{share.label}</IonText>
          <IonButton className="sharebutton" color="gold" fill="clear" slot="end"><IonIcon className="Share" size="small" slot="end" />{share.action}</IonButton>
        </IonItem>
      )}
    </IonPopover>
  );
};

export default Sharepopup;
