import {
  IonLabel,
  IonItem,
  IonIcon,
  IonText,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonLoading,
  IonToast,
} from "@ionic/react";

import React, { useEffect, useState } from "react";
import "./Todos.css";
import todoIcon from "src/icons/todoIcon.svg";
import todoIconIncomplete from "src/icons/todoIconIncomplete.svg";
import { ellipse } from "ionicons/icons";
import moment from "moment";
import FilterChips from "./FilterChips";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import api from "src/api";
// import TodoData from "./TodoData"

function camelize(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toUpperCase() : word.toLowerCase();
    })
    .replace(/\s+/g, "");
}

// const today = new Date();

const groupBy = (array, key) => {
  return array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    if (!result[currentValue[key]]) {
      result[currentValue[key]] = [];
    }
    return result;
  }, {});
};

const Todos: React.FC<any> = ({
  Todos,
  activeFilters,
  clrFilters,
  refreshEng,
}) => {
  const [monthsAll, setmonthsAll] = useState<any>([]);
  const [monthsGrouped, setmonthsGrouped] = useState<any>([]);

  /* const monthsAll = TodoData.map(todo => {
    return {
      ...todo,
      "Month": moment(todo.dueDate).format("MMMM"),
      "MonthNum": moment(todo.dueDate).format("M"),
      "DayNum": moment(todo.dueDate).format("DD")
    }
  }) */

  useEffect(() => {
    if (Todos) {
      // if (Todos.length > 0) {
      setmonthsAll(
        Todos.map((obj) => {
          return {
            ...obj,
            Month: moment(obj.activityDate).format("MMMM"),
            MonthNum: moment(obj.activityDate).format("M"),
            DayNum: moment(obj.activityDate).format("DD"),
          };
        })
      );
      // }
    }
  }, [Todos]);

  useEffect(() => {
    if (monthsAll) {
      // if (monthsAll.length > 0) {
      setmonthsGrouped(groupBy(monthsAll, "Month"));
      // }
    }
  }, [monthsAll]);

  return monthsGrouped && monthsGrouped !== null ? (
    <React.Fragment>
      <FilterChips
        chipArr={activeFilters}
        clrFilters={clrFilters}
        // clrFilters={() => {console.log("temp")}}
      />
      {Object.keys(monthsGrouped).map((monthName) => (
        <React.Fragment key={monthName}>
          <IonItem className="todoMonthItem" lines="none">
            {moment(monthsGrouped[monthName][0].activityDate).format("MMMM") +
              " " +
              moment(monthsGrouped[monthName][0].activityDate).format("YYYY")}
          </IonItem>

          <MonthGrid
            TodosMonth={monthsGrouped[monthName]}
            refreshEng={refreshEng}
          />
        </React.Fragment>
      ))}
    </React.Fragment>
  ) : null;
};

const MonthGrid = ({ TodosMonth, refreshEng }) => {
  const daysGrouped = groupBy(TodosMonth, "DayNum");
  return (
    <IonGrid className="ml-13 mr-13">
      {Object.keys(daysGrouped)
        .sort((a, b) => {
          if (a < b) return -1;
          if (a > b) return 1;
          return 0;
        })
        .map((dayCard) => (
          <IonRow
            class="mobile-todo-margin"
            key={dayCard}
            style={{ marginRight: 38 }}
          >
            <IonCol size-xs="3" size-sm="3">
              {moment(daysGrouped[dayCard][0].activityDate).format(
                "YYYY-MM-DD"
              ) === moment(new Date()).format("YYYY-MM-DD") ? (
                <React.Fragment>
                  <IonText className="todayToDo">Due Today</IonText>
                  <IonCol style={{ display: "flex", height: "100%" }}>
                    <IonLabel className="timelineVerticalLineRed"></IonLabel>
                  </IonCol>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <IonText className="todayToDoNormal">
                    Due:{" "}
                    {moment(daysGrouped[dayCard][0].activityDate).format("ll")}
                  </IonText>
                  <IonCol style={{ display: "flex", height: "100%" }}>
                    <IonText className="timelineVerticalLineNormal"></IonText>
                  </IonCol>
                </React.Fragment>
              )}
            </IonCol>
            <IonCol
              size-xs="1"
              size-sm="1"
              style={{ display: "flex", flexDirection: "column" }}
            >
              {moment(daysGrouped[dayCard][0].activityDate).format(
                "YYYY-MM-DD"
              ) === moment(new Date()).format("YYYY-MM-DD")
                ? daysGrouped[dayCard].map((o) => (
                    <IonIcon
                      style={{
                        flexGrow: 1,
                        marginLeft: "auto",
                        width: 12,
                        marginBottom: 16,
                      }}
                      icon={ellipse}
                      color="danger"
                      key={o.engagementId}
                    />
                  ))
                : null}
            </IonCol>
            <IonCol class="mobile-todo-daygrid" size-xs="8" size-sm="8">
              <DayGrid
                TodosDay={daysGrouped[dayCard]}
                refreshEng={refreshEng}
              />
            </IonCol>
          </IonRow>
        ))}
    </IonGrid>
  );
};

const DayGrid = ({ TodosDay, refreshEng }) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const history = useHistory();

  const handleCompleteToggle = (dayData) => {
    //console.log(dayData)
    setLoading(true);
    api
      .patchTask(dayData.engagementId, {
        metadata: {
          status:
            dayData.metaDataStatus === "COMPLETED"
              ? "NOT_STARTED"
              : "COMPLETED",
        },
      })
      .then((Engresp) => {
        refreshEng(Engresp);
        setLoading(false);
        setShowToast(true);
      });
  };

  return TodosDay.map((dayData) => (
    <IonCard class="todoCard" key={dayData.engagementId}>
      <IonLoading
        cssClass="my-custom-loading-class"
        isOpen={isLoading}
        message={"Please wait..."}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message="The Task has been updated successfully."
        duration={2000}
        position="middle"
      />
      <IonItem lines="none" className="todoItem">
        <IonGrid>
          <IonRow>
            <IonCol size="1" style={{ display: "flex" }}>
              <IonIcon
                class="mobile-tododot"
                style={{ marginTop: 32, marginBottom: 16 }}
                size="large"
                icon={
                  dayData.metaDataStatus === "COMPLETED"
                    ? todoIcon
                    : todoIconIncomplete
                }
                onClick={() => {
                  handleCompleteToggle(dayData);
                }}
              />
            </IonCol>
            <IonCol size="11">
              <IonLabel class="mobile-timeline-title desk-todo-title">
                <p
                  className={clsx({
                    "boldText ion-padding-top": true,
                    white: dayData.metaDataStatus !== "COMPLETED",
                  })}
                  onClick={() => {
                    history.push(
                      `/view/todo`,{
                        contId:dayData.associationContactIds,
                        engId:dayData.engagementId
                      })                   
                  }}
                  style={{
                    marginBottom: 5,
                    textDecoration:
                      dayData.metaDataStatus === "COMPLETED"
                        ? "line-through"
                        : "none",
                  }}
                >
                  {dayData.metaDataSubject !== null &&
                  dayData.metaDataSubject !== ""
                    ? dayData.metaDataSubject
                    : "-"}
                </p>
                <p>
                  {dayData.contactMiddleName !== "" &&
                  dayData.contactMiddleName !== null
                    ? `${dayData.contactFirstName} ${dayData.contactMiddleName} ${dayData.contactLastName}`
                    : `${dayData.contactFirstName} ${dayData.contactLastName}`}
                </p>
                {/* <p>
                    Due:{" "}
                    {moment(dayData.activityDate).format("ddd") +
                      ", " +
                      moment(dayData.activityDate).format("ll")}
                  </p> */}
                <p>
                  {`Priority ${camelize(
                    dayData.metaDataPriority
                  )} | Type: ${camelize(dayData.metaDataTaskType)}`}
                </p>
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonText className="todoHorizontalLine"></IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            {dayData.metaDataStatus !== "COMPLETED" ? (
              <IonButton color="gold" fill="clear" mode="ios">
                {dayData.metaDataTaskType === "EMAIL" &&
                dayData.contProps &&
                dayData.contProps.properties ? (
                  <a
                    className="todoanchor"
                    href={"mailto:" + dayData.contProps.properties.email}
                  >
                    Email
                  </a>
                ) : dayData.metaDataTaskType === "CALL" &&
                  dayData.contProps.properties ? (
                  <a
                    className="todoanchor"
                    href={"tel:" + dayData.contProps.properties.phone}
                  >
                    Call
                  </a>
                ) : (
                  <IonText
                    onClick={() => {
                      history.push(`/newevent/${dayData.contProps.id}`, {
                        contactName: `${dayData.contProps.properties.firstname} ${dayData.contProps.properties.lastname}`,
                        from: "Todo",
                        contId: dayData.contProps.id,
                      });
                    }}
                  >
                    Schedule
                  </IonText>
                )}
              </IonButton>
            ) : (
              <IonButton color="greyscale" fill="clear" mode="ios" disabled>
                Task Completed
              </IonButton>
            )}
          </IonRow>
        </IonGrid>
      </IonItem>
    </IonCard>
  ));
};

export default Todos;
