export const createTables: string = `CREATE TABLE IF NOT EXISTS meeting_notes (meetingnoteRowId INTEGER PRIMARY KEY AUTOINCREMENT,rowId data_type VARCHAR(255),createdDate data_type VARCHAR(255),createdBy data_type VARCHAR(255),updatedDate data_type VARCHAR(255),updatedBy data_type VARCHAR(255),description data_type VARCHAR(255),prospectId data_type VARCHAR(255),clientType data_type VARCHAR(255),meetingTopic data_type VARCHAR(255),meetingDateTime data_type VARCHAR(255),meetingType data_type VARCHAR(255),notes data_type VARCHAR(255),gvc data_type VARCHAR(255),invObjective data_type VARCHAR(255),invLiquidity data_type VARCHAR(255),optLocalEconomy data_type VARCHAR(255),optIntlEconomy data_type VARCHAR(255),optCorebusiness data_type VARCHAR(255),apetiteIntlExposure data_type VARCHAR(255),liquidityEvent data_type VARCHAR(255),followupTaskId data_type VARCHAR(255),attendees data_type VARCHAR(255),meetingEndDateTime data_type VARCHAR(255),hubspotMeetingId data_type VARCHAR(255),dirtyFlag data_type INTEGER,Operation data_type VARCHAR(20));`;

export const dropTables: string = `DROP TABLE IF EXISTS meeting_notes;`;

export const insertMeetingNote: string = `INSERT INTO meeting_notes (rowId ,prospectId ,clientType ,createdBy ,createdDate ,meetingDateTime ,meetingTopic ,meetingType ,updatedBy ,updatedDate, description, meetingEndDateTime, notes, gvc, invObjective, invLiquidity, optLocalEconomy, optIntlEconomy, optCorebusiness, apetiteIntlExposure, liquidityEvent, followupTaskId, hubspotMeetingId, attendees, dirtyFlag, Operation) values (?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?);`;

export const updateMeetingNote: string = `UPDATE meeting_notes SET apetiteIntlExposure=?, optLocalEconomy=?, createdBy=?, meetingDateTime=?, updatedBy=?, clientType=?, description=?, followupTaskId=?, gvc=?, hubspotMeetingId=?, invLiquidity=?, invObjective=?, liquidityEvent=?, meetingEndDateTime=?, meetingTopic=?, meetingType=?, notes=?, optCorebusiness=?, optIntlEconomy=?, attendees=?, dirtyFlag=?, Operation=? WHERE rowId = ?;`;

export const deleteNotesForProspect: string = `DELETE FROM meeting_notes where prospectId IN (?);`;

export const deleteNoteByIdSQL: string = `DELETE FROM meeting_notes where rowId in (?);`;

export const markForDeletion: string = `UPDATE meeting_notes set Operation='DELETE' AND dirtyFlag=1;`;

export const getNotesByProspectId: string = `SELECT meetingnoteRowId, rowId, createdDate, createdBy, updatedDate, updatedBy, description, prospectId, clientType, meetingTopic, meetingDateTime, meetingType, notes, gvc, invObjective, invLiquidity, optLocalEconomy, optIntlEconomy, optCorebusiness, apetiteIntlExposure, liquidityEvent, followupTaskId, attendees, meetingEndDateTime, hubspotMeetingId, dirtyFlag, Operation FROM meeting_notes WHERE Operation NOT IN ('DELETE') AND prospectId IN (?);`;

export const getdirtyMeetingNotesQry: string = `SELECT meetingnoteRowId, rowId, createdDate, createdBy, updatedDate, updatedBy, description, prospectId, clientType, meetingTopic, meetingDateTime, meetingType, notes, gvc, invObjective, invLiquidity, optLocalEconomy, optIntlEconomy, optCorebusiness, apetiteIntlExposure, liquidityEvent, followupTaskId, attendees, meetingEndDateTime, hubspotMeetingId, dirtyFlag, Operation FROM meeting_notes WHERE Operation = ? AND dirtyFlag=1`

export const getNotesById: string = `SELECT meetingnoteRowId, rowId, createdDate, createdBy, updatedDate, updatedBy, description, prospectId, clientType, meetingTopic, meetingDateTime, meetingType, notes, gvc, invObjective, invLiquidity, optLocalEconomy, optIntlEconomy, optCorebusiness, apetiteIntlExposure, liquidityEvent, followupTaskId, attendees, meetingEndDateTime, hubspotMeetingId, dirtyFlag, Operation FROM meeting_notes WHERE rowId IN (?);`;
