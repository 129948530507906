import { IonIcon, IonItem, IonLabel, IonLoading, IonRow, IonText } from '@ionic/react'
import React, { useEffect, useState } from 'react'
import api from 'src/api';
import CryptoJS from 'crypto-js';
import personstar from 'src/icons/personstar.svg'
import "./Results.css"
import { Plugins } from "@capacitor/core";
import useWindowDimensions from "src/components/useWindowDimensions";

const { Storage } = Plugins

const styles = {
    header: {
        marginLeft: 32,
        fontWeight: 300
    },
    personlabel: {
        marginLeft: 8
    },
    item: {
        paddingRight: 16,
        paddingLeft: 16
    }

}

const decrypt = (decryptVlaue) => {
    let passphrase = "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9"
    const bytes = CryptoJS.AES.decrypt(decryptVlaue, passphrase);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  };
const getUserId = async () => {
    const ret: any = await Storage.get({ key: "userId" })
    const temp = JSON.parse(decrypt(ret.value))
    return temp.id
}

const getTeams = async () => {
    const ret: any = await Storage.get({ key: "userId" });
    const temp = JSON.parse(decrypt(ret.value));
    return temp && temp.teams ? temp.teams : null;
  };

interface InpProps {
    queryName:string,
    srchQry: string,
    clrSrch: boolean,
    resetClrSrch: Function,
    forTodo?: boolean;
    setContProps?: any;
    assocId?: any;
}

const Results: React.FC<InpProps> = ({
    queryName,
    srchQry,
    clrSrch,
    resetClrSrch,
    forTodo,
    setContProps,
    assocId
}) => {


    const [results, setResults] = useState<any>([])
    const [isLoading, setLoading] = useState<boolean>(false);

    useEffect(() => {
      
        if (srchQry !== "" && queryName == "name") {            
            // console.log(srchQry)
            let payload: any = { "query": srchQry, properties: ["gender,firstname,lastname,middle_name,id,first_name_arabic,arabic_last_name,arabic_middle_name,hs_avatar_filemanager_key"] }
            const getSearchRes = async () => {
                
                // return api.filterProspects(payload)
                setLoading(true)
                const uId = await getUserId();
                getTeams().then((teams) => {
                let filterGroups:any = [];
                // if(teams)
                // {
                //     filterGroups = [
                //         {
                //             filters: [
                //                 {
                //                     operator: "EQ",
                //                     propertyName: "hubspot_team_id",
                //                     value: teams?parseInt(teams[0].id):""
                //                 }
                //             ]
                //         }
                //     ];

                //     if(teams.length >= 2)
                //     {
                //         filterGroups.push({
                //             filters: [                    
                //               {
                //                 operator: "EQ",
                //                 propertyName: "hubspot_team_id",
                //                 value: teams?parseInt(teams[1].id):"",
                //               }
                //             ],                  
                //           });
                //     }
                //     if(teams.length >= 3)
                //     {
                //         filterGroups.push({
                //             filters: [                    
                //               {
                //                 operator: "EQ",
                //                 propertyName: "hubspot_team_id",
                //                 value: teams?parseInt(teams[2].id):"",
                //               }
                //             ],                  
                //           });                        
                //     }
                // }
                // else
                {
                    filterGroups = [
                        {
                            filters: [
                                {
                                    operator: "EQ",
                                    propertyName: "hubspot_owner_id",
                                    value: parseInt(uId)
                                }
                            ]
                        }
                    ]
                }
                


                return api.searchProspect({ ...payload, filterGroups })
                    .then((response) => {
                        //  console.log(response)
                        setResults(response.results.filter(obj => {
                            let retVal = true
                            if(parseInt(obj.id) === parseInt(assocId)) {
                                retVal = false
                            }
                            return retVal
                        }))
                        setLoading(false);
                        setTimeout(() => {
                            const htmlele: HTMLInputElement | null = document.querySelector(
                                ".searchbar-input"
                              );
                              htmlele?.focus();
                        }, 0);                        
                    })
                    .catch((error) => {
                        setLoading(false)
                        console.error("Error from API", error);
                        throw (error);
                    });
                });
            }
            getSearchRes()
        }
        else if (srchQry !== "" && queryName == "id") {
          
            let payload: any = {properties: ["gender,firstname,lastname,middle_name,id,first_name_arabic,arabic_last_name,arabic_middle_name,hs_avatar_filemanager_key"] }
            const getSearchRes = async () => {
                // return api.filterProspects(payload)
               
                setLoading(true)
                const uId = await getUserId()
                // console.log(uId);
                // console.log(srchQry);
                getTeams().then((teams) => {
                let filterGroups:any = [];
                if(teams)
                {
                    filterGroups = [
                        {
                            filters: [
                                {
                                    operator: "EQ",
                                    propertyName: "onboarding_id",
                                    value: parseInt(srchQry)
                                },
                                {
                                    operator: "EQ",
                                    propertyName: "hubspot_team_id",
                                    value: teams?parseInt(teams[0].id):""
                                }
                            ]
                        }
                    ]
                    if(teams.length >= 2)
                    {
                        filterGroups.push({
                            filters: [    
                                {
                                    operator: "EQ",
                                    propertyName: "onboarding_id",
                                    value: parseInt(srchQry)
                                },                
                                {
                                    operator: "EQ",
                                    propertyName: "hubspot_team_id",
                                    value: teams?parseInt(teams[1].id):"",
                                }
                            ],                  
                          });
                    }
                    if(teams.length >= 3)
                    {
                        filterGroups.push({
                            filters: [ 
                                {
                                    operator: "EQ",
                                    propertyName: "onboarding_id",
                                    value: parseInt(srchQry)
                                },                   
                                {
                                operator: "EQ",
                                propertyName: "hubspot_team_id",
                                value: teams?parseInt(teams[2].id):"",
                                }
                            ],                  
                          });
                    }
                }
                else
                {
                    filterGroups = [
                        {
                            filters: [
                                {
                                    operator: "EQ",
                                    propertyName: "onboarding_id",
                                    value: parseInt(srchQry)
                                },
                                {
                                    operator: "EQ",
                                    propertyName: "hubspot_owner_id",
                                    value: parseInt(uId)
                                }
                            ]
                        }
                    ]
                }
                

                return api.searchProspect({ ...payload, filterGroups })
                    .then((response) => {
                        //  console.log(response)
                        setResults(response.results.filter(obj => {
                            let retVal = true
                            if(parseInt(obj.id) === parseInt(assocId)) {
                                retVal = false
                            }
                            return retVal
                        }))
                        setLoading(false);
                        setTimeout(() => {
                            const htmlele: HTMLInputElement | null = document.querySelector(
                                ".searchbar-input"
                              );
                              htmlele?.focus();
                        }, 0);                        
                    })
                    .catch((error) => {
                        setLoading(false)
                        console.error("Error from API", error);
                        throw (error);
                    });
                })
            }
            getSearchRes()
        }
        else {
            setResults([])
        }
    }, [srchQry])

    useEffect(() => {
        if (clrSrch) {
            setResults([])
            resetClrSrch()
        }
    }, [clrSrch, resetClrSrch])

    
    return (
        <div className="ion-padding">
            <IonLoading
                isOpen={isLoading}
                onDidDismiss={() => setLoading(false)}
                message={'Please wait...'}
            />
            <IonRow class="margin-set mt-6"style={{ marginTop: 24, marginLeft: 32, marginBottom: 24 }}>
                <IonText
                class="fs-28"
                    style={{ fontWeight: 300, fontSize: 28 }}
                    color="greyscale"
                >
                    Search
                </IonText>
            </IonRow>
            {
                results && results.length > 0 ?
                    results.map(contact => (
                        !forTodo ?
                            <IonItem class="margin-set padding-unset" button detail={false} key={contact.id} routerLink={"/contactinfo/" + contact.id} style={styles.item}>
                                <IonIcon color="white" size="small" icon={personstar} style={{ marginLeft: 12 }} />
                                <IonLabel style={styles.personlabel}>
                                    {
                                        `${contact.properties.firstname}${contact.properties.middle_name ? " " + contact.properties.middle_name : ''} ${contact.properties.lastname}`
                                    }
                                </IonLabel>
                            </IonItem> :
                            <IonItem class="margin-set padding-unset" button detail={false} key={contact.id} onClick={() => { setContProps(contact) }} style={styles.item}>
                                <IonIcon color="white" size="small" icon={personstar} style={{ marginLeft: 12 }} />
                                <IonLabel style={styles.personlabel}>
                                    {
                                        `${contact.properties.firstname}${contact.properties.middle_name ? " " + contact.properties.middle_name : ''} ${contact.properties.lastname}`
                                    }
                                </IonLabel>
                            </IonItem>
                    )
                    ) : null
            }
        </div>)
}

export default Results
