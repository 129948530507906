import React, { CSSProperties, useEffect, useState } from "react";
import { IonGrid, IonIcon, IonRow, IonCol, IonLabel } from "@ionic/react";
import CryptoJS from "crypto-js";
// import LineChart from './Line'
import "./Chart.css";
import { ellipse } from "ionicons/icons";
import ChartInstance from "./ChartInstance";
import BarChartInstance from "./BarChartInstance";
//BarChartInstance
import GeneralChartInstance from "./GeneralchartInstance";
import GenOnbClientChartInstance from "./GenOnbClientChartInstance";
import personStar from "src/icons/personstar.svg";
import percent from "src/icons/percent.svg";
import api from "src/api";
import { Plugins } from "@capacitor/core";
import moment from "moment";
const { Storage } = Plugins;

const decimalFunc = (Num) => {
  let temp = Num.toString().split(".");
  let retVal = 0;
  if (temp[1]) {
    retVal = Num.toFixed(2);
  } else {
    retVal = Num;
  }
  return retVal;
};
const retTotalAmt = (clsAmt) => {
  let closingamt_disp: number = 0;
  Number.isNaN(clsAmt) || clsAmt === null || clsAmt === ""
    ? (closingamt_disp = 0)
    : (closingamt_disp = parseInt(clsAmt));
  return Math.abs(Number(closingamt_disp)) >= 1.0e9
    ? decimalFunc(Math.abs(Number(closingamt_disp)) / 1.0e9) + "b"
    : // Six Zeroes for Millions
    Math.abs(Number(closingamt_disp)) >= 1.0e6
    ? decimalFunc(Math.abs(Number(closingamt_disp)) / 1.0e6) + "m"
    : // Three Zeroes for Thousands
    Math.abs(Number(closingamt_disp)) >= 1.0e3
    ? decimalFunc(Math.abs(Number(closingamt_disp)) / 1.0e3) + "k"
    : decimalFunc(Math.abs(Number(closingamt_disp)));
};

interface StyleProps {
  rootGrid: CSSProperties;
  colbackL: CSSProperties;
  colbackR: CSSProperties;
  innerGrid: CSSProperties;
  title: CSSProperties;
  dropdownRow: CSSProperties;
  titleRow: CSSProperties;
  iconsize: CSSProperties;
}
const decrypt = (decryptVlaue) => {
  let passphrase =
    "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9";
  const bytes = CryptoJS.AES.decrypt(decryptVlaue, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};

const getUserId = async () => {
  const ret: any = await Storage.get({ key: "userId" });
  const temp = JSON.parse(decrypt(ret.value));
  return temp && temp.id ? temp.id : null;
};

const timeDropDown: any = [];
let thisdate = new Date();
let thisyear = thisdate.getFullYear();
let thismonth = thisdate.getMonth();
timeDropDown.push({ val: thisyear - 3 });
timeDropDown.push({ val: thisyear - 2 });
timeDropDown.push({ val: thisyear - 1 });
timeDropDown.push({ val: "Last 30 days" });
timeDropDown.push({ val: "Q1 " + thisyear });
if (thismonth > 2) {
  timeDropDown.push({ val: "Q2 " + thisyear });
}
if (thismonth > 5) {
  timeDropDown.push({ val: "Q3 " + thisyear });
}
if (thismonth > 8) {
  timeDropDown.push({ val: "Q4 " + thisyear });
}
timeDropDown.push({ val: "Year-to-Date" });
timeDropDown.push({ val: "Last 12 Months" });
// timeDropDown.push({ val: "All Time" });
// timeDropDown.push({ val: "Next Year" });

const timeDropDownReinvest: any = [];
timeDropDownReinvest.push({ val: thisyear - 3 });
timeDropDownReinvest.push({ val: thisyear - 2 });
timeDropDownReinvest.push({ val: thisyear - 1 });
timeDropDownReinvest.push({ val: "Year-to-Date" });

const styles: StyleProps = {
  rootGrid: {
    marginTop: 24,
  },
  colbackL: {
    height: 384,
    // background: "url(/assets/mesh.svg) no-repeat 100px 0px",
    backgroundColor: "#1A1A1A",
    paddingRight: 7.5,
  },
  colbackR: {
    height: 384,
    // background: "url(/assets/mesh.svg) no-repeat 100px 0px",
    backgroundColor: "#1A1A1A",
    paddingLeft: 7.5,
  },
  innerGrid: {
    position: "relative",
    zIndex: 2,
  },
  titleRow: {
    position: "absolute",
    top: 27,
    marginLeft: 40,
    display: "flex",
    flexDirection: "column",
  },
  dropdownRow: {
    position: "absolute",
    top: 11.5,
    marginLeft: 40,
  },
  title: {
    fontSize: 18,
    fontWeight: "normal",
  },
  iconsize: {
    height: 24,
    width: 24,
  },
};

const getUserEmail = async () => {
  const ret: any = await Storage.get({ key: "userId" });
  const temp = JSON.parse(decrypt(ret.value));
  return temp && temp.email ? temp.email : null;
};

const getTeams = async () => {
  const ret: any = await Storage.get({ key: "userId" });
  const temp = JSON.parse(decrypt(ret.value));
  return temp && temp.teams ? temp.teams : null;
};

let curquater: any = thisdate.getMonth();
let curquateryear: any = "";
let curquatstartmonth: number = 0;
if (curquater <= 2) {
  curquateryear = "Q1 " + thisyear;
  curquatstartmonth = 0;
} else if (curquater <= 5) {
  curquateryear = "Q2 " + thisyear;
  curquatstartmonth = 3;
} else if (curquater <= 8) {
  curquateryear = "Q3 " + thisyear;
  curquatstartmonth = 6;
} else {
  curquateryear = "Q4 " + thisyear;
  curquatstartmonth = 9;
}

interface ChartCompProps {}

const getStartEndDates = (timeframeString: string) => {
  // let VstartDate;
  // let VendDate;
  // api.getValutionDate().then((resp)=>{
  //  console.log("resp---",resp);
   
  //     VstartDate= `01-JAN-${moment(resp.data.valuation_date,"YYYY-MM-DD").year()}`;
      
  //     VendDate=`${moment(resp.data.valuation_date).format("DD-MMM-YYYY")}`
   
   
  //   });
 
 
  
  
  
  switch (timeframeString) {
    
    case "Next Year":
      return {
        startDate: `01-JAN-${moment(new Date())
          .add(1, "years")
          .format("YYYY")}`,
        endDate: `31-DEC-${moment(new Date())
          .add(1, "years")
          .format("YYYY")}`,
      };
    case "Last 12 Months":
      return {
        startDate: `${moment(new Date())
          .subtract(1, "years")
          .startOf("month")
          .format("DD-MMM-YYYY")}`,
        endDate: `${moment(new Date())
          .subtract(1, "month")
          .endOf("month")
          .format("DD-MMM-YYYY")}`,
      };
    case "Q1 " + moment(new Date()).format("YYYY"):
      return {
        startDate: `01-JAN-${moment(new Date()).format("YYYY")}`,
        endDate: `31-MAR-${moment(new Date()).format("YYYY")}`,
      };
    case "Q2 " + moment(new Date()).format("YYYY"):
      return {
        startDate: `01-APR-${moment(new Date()).format("YYYY")}`,
        endDate: `30-JUN-${moment(new Date()).format("YYYY")}`,
      };
    case "Q3 " + moment(new Date()).format("YYYY"):
      return {
        startDate: `01-JUL-${moment(new Date()).format("YYYY")}`,
        endDate: `30-SEP-${moment(new Date()).format("YYYY")}`,
      };
    case "Q4 " + moment(new Date()).format("YYYY"):
      return {
        startDate: `01-OCT-${moment(new Date()).format("YYYY")}`,
        endDate: `31-DEC-${moment(new Date()).format("YYYY")}`,
      };
    case "Last 30 days":
      return {
        startDate: `${moment(new Date())
          .subtract(31, "days")
          .format("DD-MMM-YYYY")}`,
        endDate: `${moment(new Date())
          .subtract(1, "days")
          .format("DD-MMM-YYYY")}`,
      };
    case "Year-to-Date":
     
      return {
        startDate: `01-JAN-${moment(new Date()).format("YYYY")}`,
        endDate: `${moment(new Date()).format("DD-MMM-YYYY")}`,
      };
    case "valuation-date":
      return {
        startDate: `01-JAN-${moment(new Date()).format("YYYY")}`,
        endDate: `valuationdate`,
      };
    case moment(new Date())
      .subtract(1, "years")
      .format("YYYY"):
      return {
        startDate: `01-JAN-${moment(new Date())
          .subtract(1, "years")
          .format("YYYY")}`,
        endDate: `31-DEC-${moment(new Date())
          .subtract(1, "years")
          .format("YYYY")}`,
      };
    case moment(new Date())
      .subtract(2, "years")
      .format("YYYY"):
      return {
        startDate: `01-JAN-${moment(new Date())
          .subtract(2, "years")
          .format("YYYY")}`,
        endDate: `31-DEC-${moment(new Date())
          .subtract(2, "years")
          .format("YYYY")}`,
      };
    case moment(new Date())
      .subtract(3, "years")
      .format("YYYY"):
      return {
        startDate: `01-JAN-${moment(new Date())
          .subtract(3, "years")
          .format("YYYY")}`,
        endDate: `31-DEC-${moment(new Date())
          .subtract(3, "years")
          .format("YYYY")}`,
      };
    default:
      return {
        startDate: `01-JAN-2015`,
        endDate: `31-DEC-${moment(new Date()).format("YYYY")}`,
      };
  }
};

const getFilterBool = (fltStr, monthNum) => {
  switch (fltStr) {
    case "Q1 " + moment(new Date()).format("YYYY"):
      return monthNum === 1 || monthNum === 2 || monthNum === 3 ? true : false;
    case "Q2 " + moment(new Date()).format("YYYY"):
      return monthNum === 4 || monthNum === 5 || monthNum === 6 ? true : false;
    case "Q3 " + moment(new Date()).format("YYYY"):
      return monthNum === 7 || monthNum === 8 || monthNum === 9 ? true : false;
    case "Q4 " + moment(new Date()).format("YYYY"):
      return monthNum === 10 || monthNum === 11 || monthNum === 12
        ? true
        : false;
    case "Last 30 days":
      return monthNum ===
        moment(new Date())
          .subtract(31, "days")
          .get("month") ||
        monthNum ===
          moment(new Date())
            .subtract(1, "days")
            .get("month")
        ? true
        : false;
    default:
      return true;
  }
};

const GeneralChart: React.FC<ChartCompProps> = () => {
  const [dProsp, setdProsp] = useState<[number, number][]>([
    [0, 0],
    [1, 0],
    [2, 0],
    [3, 0],
    [4, 0],
    [5, 0],
    [6, 0],
    [7, 0],
    [8, 0],
    [9, 0],
    [10, 0],
    [11, 0],
    [12, 0],
    [13, 0],
  ]);

  const [dTarg, setdTarg] = useState<[number, number][]>([
    [0, 0],
    [1, 0],
    [2, 0],
    [3, 0],
    [4, 0],
    [5, 0],
    [6, 0],
    [7, 0],
    [8, 0],
    [9, 0],
    [10, 0],
    [11, 0],
    [12, 0],
    [13, 0],
  ]);
  const [dAct, setdAct] = useState<[number, number][]>([
    [0, 0],
    [1, 0],
    [2, 0],
    [3, 0],
    [4, 0],
    [5, 0],
    [6, 0],
    [7, 0],
    [8, 0],
    [9, 0],
    [10, 0],
    [11, 0],
    [12, 0],
    [13, 0],
  ]);

  const [dMyAUM, setdMyAUM] = useState<[number, number][]>([
    [0, 0],
    [1, 0],
    [2, 0],
    [3, 0],
    [4, 0],
    [5, 0],
    [6, 0],
    [7, 0],
    [8, 0],
    [9, 0],
    [10, 0],
    [11, 0],
    [12, 0],
    [13, 0],
  ]);
  const [dTeamAUM, setdTeamAUM] = useState<[number, number][]>([
    [0, 0],
    [1, 0],
    [2, 0],
    [3, 0],
    [4, 0],
    [5, 0],
    [6, 0],
    [7, 0],
    [8, 0],
    [9, 0],
    [10, 0],
    [11, 0],
    [12, 0],
    [13, 0],
  ]);

  const [cmtTf, setcmtTf] = useState<string>("Year-to-Date");
  const [aumTf, setaumTf] = useState<string>("valuation-date");
  const [gencntfndTf, setgencntfndTf] = useState<string>("");
  const [targActTf, settargActTf] = useState<string>("Year-to-Date");
  const [OnbCntTf, setOnbCntTf] = useState<string>("Year-to-Date");
  const [ManAUMTf, setManAUMTf] = useState<string>("Year-to-Date");
  const [reinvestment, setreinvestment] = useState<string>("Year-to-Date");

  const [genfundraise, setgenfundraise] = useState(
    "Current AUM for " + thisyear
  );
  const [timeDrpDmGenFnds, settimeDrpDmGenFnds] = useState([]);

  const [maxCmt, setMaxCmt] = useState<number>(0);

  const [maxProsp, setmaxProsp] = useState<number>(0);
  const [prosTrans, setprosTrans] = useState<number>(0);
  const [convRatio, setconvRatio] = useState<number>(0);

  const [TotMyCnt, setTotMyCnt] = useState<number>(0);
  const [TotTeamCnt, setTotTeamCnt] = useState<number>(0);

  const [TotMyAUM, setTotMyAUM] = useState<number>(0);
  const [TotTeamAUM, setTotTeamAUM] = useState<number>(0);
  const [TotAUM, setTotAUM] = useState<number>(0);
  const [genfundraisedata, setgenfundraisedata] = useState<any>();
  const [TotClientCnt, setTotClientCnt] = useState<number>(0);
  const [TotReinvestment, setTotReinvestment] = useState<number>(0);
  const [GenTotTeamAUM, setGenTotTeamAUM] = useState<number>(0);
  const [rmCount, setrmCount] = useState<number>(0);
  const [avgAUMCnt, setavgAUMCnt] = useState<number>(0);

  const [render, setrender] = useState<boolean>(false);
  const [renderonb, setrenderonb] = useState<boolean>(false);
  const [renderfundraise, setrenderfundraise] = useState<boolean>(false);
  const [renderreinvest, setrenderreinvest] = useState<boolean>(false);
  const [vDate,setVdate]=useState<number>(0);

  // const [renderCmt, setrenderCmt] = useState<boolean>(false)
  // const [renderConv, setrenderConv] = useState<boolean>(false)

  const setCommitmentTimeframe = (str) => {
    setcmtTf(str);
  };

  const setGenClientFundTimeframe = (str) => {
    setgencntfndTf(str);
  };

  const setOnbClientTimeframe = (str) => {
    // console.log("onboarding tf");
    setOnbCntTf(str);
  };

  const setManagedAUMTimeframe = (str) => {
    setManAUMTf(str);
  };

  const setReinvestmentTimeframe = (str) => {
    // console.log("reinvestment tf");

    setreinvestment(str);
  };
  const [valuationDate, setValuationDate] = useState<string>("");
  const [valuationDateAum, setValuationDateAum] = useState<string>("");

  const [reivst, setreivst] = useState<[number, number, number, number][]>([
    [0, 0, 0, 0],
    [1, 0, 0, 0],
    [2, 0, 0, 0],
    [3, 0, 0, 0],
    [4, 0, 0, 0],
    [5, 0, 0, 0],
    [6, 0, 0, 0],
    [7, 0, 0, 0],
    [8, 0, 0, 0],
    [9, 0, 0, 0],
    [10, 0, 0, 0],
    [11, 0, 0, 0],
  ]);

  const [onbclients, setonbclients] = useState<
    [number, number, number, number][]
  >([
    [0, 0, 0, 0],
    [1, 0, 0, 0],
    [2, 0, 0, 0],
    [3, 0, 0, 0],
    [4, 0, 0, 0],
    [5, 0, 0, 0],
    [6, 0, 0, 0],
    [7, 0, 0, 0],
    [8, 0, 0, 0],
    [9, 0, 0, 0],
    [10, 0, 0, 0],
    [11, 0, 0, 0],
  ]);
  const [genclientfund, setgenclientfund] = useState<
    [number, number, number, number][]
  >([
    [1, 0, 0, 0],
    [2, 0, 0, 0],
    [3, 0, 0, 0],
    [4, 0, 0, 0],
    [5, 0, 0, 0],
    [6, 0, 0, 0],
    [7, 0, 0, 0],
    [8, 0, 0, 0],
    [9, 0, 0, 0],
    [10, 0, 0, 0],
    [11, 0, 0, 0],
    [12, 0, 0, 0],
  ]);

  //getGeneralFundRaised

  useEffect(() => {
    getTeams().then((teams) => {
      getUserEmail().then((useremail) => {
        // const { startDate, endDate } = getStartEndDates(cmtTf.toString());
        let VstartDate;
        let VendDate;
        api
        .getValutionDate().then((resp)=>{

          if( reinvestment.toString()!=="Year-to-Date"){
            const { startDate, endDate } = getStartEndDates(cmtTf.toString());
            VstartDate=startDate;
            VendDate=endDate;            
          }else{
           VstartDate= `01-JAN-${moment(resp.data.valuation_date,"YYYY-MM-DD").year()}`
            VendDate= `${moment(resp.data.valuation_date).format("DD-MMM-YYYY")}`
          }  
          
        // api.getGeneralFundRaised("s.shehadeh@tfoco.com", "01-JAN-2021", "13-May-2021","1916245").then((resp) => {
        api
          .getGeneralFundRaised(
            useremail,
            VstartDate,
            VendDate,
            teams ? parseInt(teams[0].id) : "0"
          )
          .then((resp) => {
            setValuationDateAum(resp.data.valuation_date);
            // resp.data.conversions = [
            //   {
            //     myAUM: "0.0",
            //     month: "1",
            //     year: "2021",
            //     assetName: "",
            //     teamAUM: "1.8318583569E9",
            //     teamAssetName: "",
            //   },
            //   {
            //     myAUM: "0.0",
            //     month: "2",
            //     year: "2021",
            //     assetName: "",
            //     teamAUM: "1.81674608563E9",
            //     teamAssetName: "",
            //   },
            //   {
            //     myAUM: "0.0",
            //     month: "3",
            //     year: "2021",
            //     assetName: "",
            //     teamAUM: "1.93676116588E9",
            //     teamAssetName: "",
            //   },
            //   {
            //     myAUM: "0.0",
            //     month: "4",
            //     year: "2021",
            //     assetName: "",
            //     teamAUM: "1.95101861185E9",
            //     teamAssetName: "",
            //   },
            //   {
            //     myAUM: "0.0",
            //     month: "5",
            //     year: "2021",
            //     assetName: "",
            //     teamAUM: "1.94948916132E9",
            //     teamAssetName: "",
            //   },
            //   {
            //     myAUM: "0.0",
            //     month: "6",
            //     year: "2021",
            //     assetName: "",
            //     teamAUM: "2.03262353604E9",
            //     teamAssetName: "",
            //   },
            //   {
            //     myAUM: "0.0",
            //     month: "7",
            //     year: "2021",
            //     assetName: "",
            //     teamAUM: "2.04285031229E9",
            //     teamAssetName: "",
            //   },
            //   {
            //     myAUM: "0.0",
            //     month: "8",
            //     year: "2021",
            //     assetName: "",
            //     teamAUM: "3.04693414192E9",
            //     teamAssetName: "",
            //   },
            //   {
            //     myAUM: "0.0",
            //     month: "9",
            //     year: "2021",
            //     assetName: "",
            //     teamAUM: "2.06378560378E9",
            //     teamAssetName: "",
            //   },
            //   {
            //     myAUM: "0.0",
            //     month: "10",
            //     year: "2021",
            //     assetName: "",
            //     teamAUM: "2.06749803632E9",
            //     teamAssetName: "",
            //   },
            //   {
            //     myAUM: "0.0",
            //     month: "11",
            //     year: "2021",
            //     assetName: "",
            //     teamAUM: "2.08114800938E9",
            //     teamAssetName: "",
            //   },
            // ];

             resp.data = resp.data.conversions;

            setgenfundraisedata(resp ? resp.data : []);

            setgencntfndTf(resp && resp.data[0] ? resp.data[0].assetName : "");
            const timeDropDownCurrFunds: any = [];
            if (resp && resp.data.length > 0) {
              resp.data.forEach((respobj, index) => {
                timeDropDownCurrFunds.push({ val: resp.data[index].assetName });
              });
            }

            settimeDrpDmGenFnds(timeDropDownCurrFunds);
          });
        });
      });
    });
  }, []);

  useEffect(() => {
    let tempArrGenFunds: any = [
      [1, 0, 0, 0],
      [2, 0, 0, 0],
      [3, 0, 0, 0],
      [4, 0, 0, 0],
      [5, 0, 0, 0],
      [6, 0, 0, 0],
      [7, 0, 0, 0],
      [8, 0, 0, 0],
      [9, 0, 0, 0],
      [10, 0, 0, 0],
      [11, 0, 0, 0],
      [12, 0, 0, 0],
    ];
    let tempTotMyAUM: number = 0;
    let tempTotTeamAUM: number = 0;
    let tempfinalTeamAUM: number = 0;
    if (genfundraisedata && genfundraisedata.length > 0) {
      var d = new Date(valuationDateAum);
      genfundraisedata.forEach((respobj, index) => {
        //timeDropDownReinvest.push({ val: genfundraisedata[index].assetName });
        for (const x of tempArrGenFunds) {
          if (x[0] === parseInt(respobj.month)) {
            //&& respobj.assetName == gencntfndTf) {
            x[1] = parseInt(respobj.year);
            x[2] = parseFloat(respobj.myAUM ? respobj.myAUM : 0);
            x[3] = parseFloat(respobj.teamAUM ? respobj.teamAUM : 0);
            tempTotMyAUM =
              tempTotMyAUM + parseFloat(respobj.myAUM ? respobj.myAUM : 0);
            tempTotTeamAUM =
              tempTotTeamAUM +
              parseFloat(respobj.teamAUM ? respobj.teamAUM : 0);
            // tempfinalTeamAUM = parseFloat(respobj.teamAUM?respobj.teamAUM:tempfinalTeamAUM)

            tempfinalTeamAUM =
              parseFloat(respobj.teamAUM ? respobj.teamAUM : 0) +
              parseFloat(respobj.myAUM ? respobj.myAUM : 0);
            // if (index == d.getMonth()) {
            //   tempfinalTeamAUM =
            //     parseFloat(respobj.teamAUM ? respobj.teamAUM : 0) +
            //     parseFloat(respobj.myAUM ? respobj.myAUM : 0);
            // }
          }
        }
      });
      //console.log(timeDropDownReinvest);
    }

    setrenderfundraise(false);
    //setrender(false);
    setgenclientfund(tempArrGenFunds);
    tempTotMyAUM ? setTotMyAUM(tempTotMyAUM) : setTotMyAUM(0);
    tempTotTeamAUM ? setTotTeamAUM(tempTotTeamAUM) : setTotTeamAUM(0);
    //tempTotTeamAUM || tempTotMyAUM ?setTotAUM(tempTotMyAUM + tempTotTeamAUM):setTotAUM(0);
    setTotAUM(tempfinalTeamAUM);
  }, [genfundraisedata]);

  useEffect(() => {
    // setrenderCmt(false)
    getUserId().then((userId) => {
      if (userId && userId !== null && userId !== "") {
        const { startDate, endDate } = getStartEndDates(cmtTf.toString());

        api
          .getTotalCommitmentForecast(parseInt(userId), startDate, endDate)
          .then((resp) => {
            if (resp.data && resp.data.length > 0) {
              let tempArrTarg: any = [
                [0, 0],
                [1, 0],
                [2, 0],
                [3, 0],
                [4, 0],
                [5, 0],
                [6, 0],
                [7, 0],
                [8, 0],
                [9, 0],
                [10, 0],
                [11, 0],
                [12, 0],
                [13, 0],
              ];
              let tempArrAct: any = [
                [0, 0],
                [1, 0],
                [2, 0],
                [3, 0],
                [4, 0],
                [5, 0],
                [6, 0],
                [7, 0],
                [8, 0],
                [9, 0],
                [10, 0],
                [11, 0],
                [12, 0],
                [13, 0],
              ];
              let tempTotAct: number = 0;
              resp.data.forEach((respobj) => {
                for (const x of tempArrTarg) {
                  if (x[0] === parseInt(respobj.month)) {
                    x[1] = parseFloat(respobj.targetAmount);
                  }
                }
                for (const y of tempArrAct) {
                  if (y[0] === parseInt(respobj.month)) {
                    y[1] = parseFloat(respobj.actualAmounts);
                  }
                }
                tempTotAct = tempTotAct + parseFloat(respobj.actualAmounts);
              });
              setdTarg([]);
              setdAct([]);
              setdTarg(tempArrTarg);
              setMaxCmt(tempTotAct);
              setdAct(tempArrAct);
            }
            // setrenderCmt(true)
          });
      }
    });
  }, [cmtTf]);

  useEffect(() => {
    getTeams().then((teams) => {
      getUserEmail().then((useremail) => {
        if (useremail && useremail !== null && useremail !== "") {
          // const { startDate, endDate } = getStartEndDates(ManAUMTf.toString());

          let VstartDate;
          let VendDate;
          api
          .getValutionDate().then((resp)=>{

            if( ManAUMTf.toString()!=="Year-to-Date"){
              const { startDate, endDate } = getStartEndDates(ManAUMTf.toString())
              VstartDate=startDate;
              VendDate=endDate;            
            }else{
             VstartDate= `01-JAN-${moment(resp.data.valuation_date,"YYYY-MM-DD").year()}`
              VendDate= `${moment(resp.data.valuation_date).format("DD-MMM-YYYY")}`
            }  


          api
            .getGeneralAUM(
              useremail,
              teams ? parseInt(teams[0].id) : "0",
              "generalmanagedaum",
              VstartDate,
              VendDate
            )
            .then((resp) => {
              if (resp.data && resp.data.length > 0) {
                let tempArrMyAUM: any = [
                  [0, 0],
                  [1, 0],
                  [2, 0],
                  [3, 0],
                  [4, 0],
                  [5, 0],
                  [6, 0],
                  [7, 0],
                  [8, 0],
                  [9, 0],
                  [10, 0],
                  [11, 0],
                  [12, 0],
                  [13, 0],
                ];
                let tempArrTeamAUM: any = [
                  [0, 0],
                  [1, 0],
                  [2, 0],
                  [3, 0],
                  [4, 0],
                  [5, 0],
                  [6, 0],
                  [7, 0],
                  [8, 0],
                  [9, 0],
                  [10, 0],
                  [11, 0],
                  [12, 0],
                  [13, 0],
                ];
                let tempTotAUM: number = 0;
                let tempTotRM: number = 0;
                resp.data.forEach((respobj) => {
                  for (const x of tempArrTeamAUM) {
                    if (x[0] === parseInt(respobj.month)) {
                      x[1] = parseFloat(respobj.teamAUM);
                    }
                    tempTotAUM = parseFloat(respobj.teamAUM);
                    tempTotRM = parseInt(respobj.rMCount);
                  }
                  for (const y of tempArrMyAUM) {
                    if (y[0] === parseInt(respobj.month)) {
                      y[1] =
                        parseFloat(respobj.teamAUM) / parseInt(respobj.rMCount);
                    }
                  }
                  //tempTotAct = tempTotAct + parseFloat(respobj.actualAmounts)
                });
                setdMyAUM([]);
                setdTeamAUM([]);
                setdMyAUM(tempArrMyAUM);
                setdTeamAUM(tempArrTeamAUM);
                setGenTotTeamAUM(tempTotAUM);
                setrmCount(tempTotRM);
                setavgAUMCnt(tempTotAUM / tempTotRM);
              }
              // setrenderCmt(true)
            });
          });
        }
      });
    });
  }, [ManAUMTf]);

  useEffect(() => {
    // setrenderConv(false)
    getUserId().then((userId) => {
      const { startDate, endDate } = getStartEndDates(targActTf.toString());
      api.getProspConv(userId, startDate, endDate).then((resp) => {
        if (resp.data && resp.data.length > 0) {
          let tempArrConv: any = [
            [0, 0],
            [1, 0],
            [2, 0],
            [3, 0],
            [4, 0],
            [5, 0],
            [6, 0],
            [7, 0],
            [8, 0],
            [9, 0],
            [10, 0],
            [11, 0],
            [12, 0],
            [13, 0],
          ];
          let tempTotAmt: number = 0;
          resp.data.forEach((respobj) => {
            for (const x of tempArrConv) {
              if (x[0] === parseInt(respobj.month)) {
                x[1] = parseInt(respobj.amountPerMonth);
              }
            }
            tempTotAmt = tempTotAmt + parseInt(respobj.amountPerMonth);
          });
          setdProsp([]);
          setdProsp(tempArrConv);
          setmaxProsp(tempTotAmt);

          let totalProspects = parseInt(resp.data[0].totalProspects);
          let totalTransitioned = parseInt(resp.data[0].totalTransitioned);

          setprosTrans(totalTransitioned);
          setconvRatio(
            totalProspects === 0 ? 0 : totalTransitioned / totalProspects
          );
        }
        // setrenderConv(true)
      });
    });
  }, [targActTf]);

  useEffect(() => {
    // setrenderCmt(false)
    //console.log("temp");
    getTeams().then((teams) => {
      getUserEmail().then((useremail) => {
        //getUserId().then(userId => {
        if (useremail && useremail !== null && useremail !== "") {
          const { startDate, endDate } = getStartEndDates(OnbCntTf.toString());

          // api.getOnboardedClients("s.shehadeh@tfoco.com","1844148", "01-Jan-2019", "31-Dec-2019").then((resp) => {
          api
            .getOnboardedClients(
              useremail,
              teams ? parseInt(teams[0].id) : "0",
              startDate,
              endDate
            )
            .then((resp) => {
              let tempArrOutflow: any = [
                [1, 0, 0, 0],
                [2, 0, 0, 0],
                [3, 0, 0, 0],
                [4, 0, 0, 0],
                [5, 0, 0, 0],
                [6, 0, 0, 0],
                [7, 0, 0, 0],
                [8, 0, 0, 0],
                [9, 0, 0, 0],
                [10, 0, 0, 0],
                [11, 0, 0, 0],
                [12, 0, 0, 0],
              ];
              let tempTotMyCnt: number = 0;
              let tempTotTeamCnt: number = 0;
              if (resp.data && resp.data.length > 0) {
                resp.data.forEach((respobj) => {
                  for (const x of tempArrOutflow) {
                    // console.log(respobj.myCount);
                    if (x[0] === parseInt(respobj.month)) {
                      x[1] = parseInt(respobj.year);
                      x[2] = parseInt(respobj.myCount);
                      x[3] = parseInt(respobj.teamCount);
                      tempTotMyCnt = tempTotMyCnt + parseInt(respobj.myCount);

                      tempTotTeamCnt =
                        tempTotTeamCnt +
                        parseInt(respobj.teamCount) +
                        parseInt(respobj.myCount);
                    }
                  }
                });
                // setoutflw([])
              }
              setrenderonb(false);
              setonbclients(tempArrOutflow);
              tempTotMyCnt ? setTotMyCnt(tempTotMyCnt) : setTotMyCnt(0);
              tempTotTeamCnt ? setTotTeamCnt(tempTotTeamCnt) : setTotTeamCnt(0);
              //setTotClientCnt(tempTotTeamCnt);
              //setrender(false);
            });
        }
      });
    });
  }, [OnbCntTf]);

  useEffect(() => {
    // setrenderCmt(false)
    //console.log("temp");
    getTeams().then((teams) => {
      getUserEmail().then((useremail) => {
        //getUserId().then(userId => {
        if (useremail && useremail !== null && useremail !== "") {         
          let VstartDate;
          let VendDate;
          api
          .getValutionDate().then((resp)=>{
            const AumVdate= moment(resp.data.valuation_date,"YYYY-MM-DD").year();
            setVdate(AumVdate)
            if( reinvestment.toString()!=="Year-to-Date"){
              const {startDate,endDate}= getStartEndDates(
                reinvestment.toString()
              );
             
              VstartDate=startDate;
              VendDate=endDate;            
            }else{
              
             VstartDate= `01-JAN-${moment(resp.data.valuation_date,"YYYY-MM-DD").year()}`
              VendDate= `${moment(resp.data.valuation_date).format("DD-MMM-YYYY")}`
            }          
          // api.getGeneralDashboardReinvestment("s.shehadeh@tfoco.com",startDate, endDate,1844148,"reinvestmentDetails").then((resp) => {
          api
            .getGeneralDashboardReinvestment(
              useremail,
              VstartDate,
              VendDate,
              teams ? parseInt(teams[0].id) : "0",
              "reinvestmentDetails"
            )
            .then((resp) => {
              setValuationDate(resp.data.valuation_date);

              let valuationForRein = resp.data.valuation_date;
              let tempArrReinvest: any = [
                [1, 0, 0, 0],
                [2, 0, 0, 0],
                [3, 0, 0, 0],
                [4, 0, 0, 0],
                [5, 0, 0, 0],
                [6, 0, 0, 0],
                [7, 0, 0, 0],
                [8, 0, 0, 0],
                [9, 0, 0, 0],
                [10, 0, 0, 0],
                [11, 0, 0, 0],
                [12, 0, 0, 0],
              ];
              let tempTotReinvest: number = 0;

              // resp.data.conversions = [
              //   {
              //     reinvestmentAmount: "0.0",
              //     month: "1",
              //     year: "2021",
              //     totalReinvestmentAmount: "0.0",
              //     totalIncomeDistribution: "0.0",
              //     teamReinvestmentAmount: "2.6184778E7",
              //   },
              //   {
              //     reinvestmentAmount: "0.0",
              //     month: "2",
              //     year: "2021",
              //     totalReinvestmentAmount: "0.0",
              //     totalIncomeDistribution: "0.0",
              //     teamReinvestmentAmount: "2.1911443E7",
              //   },
              //   {
              //     reinvestmentAmount: "0.0",
              //     month: "3",
              //     year: "2021",
              //     totalReinvestmentAmount: "0.0",
              //     totalIncomeDistribution: "0.0",
              //     teamReinvestmentAmount: "5.450565E7",
              //   },
              //   {
              //     reinvestmentAmount: "0.0",
              //     month: "4",
              //     year: "2021",
              //     totalReinvestmentAmount: "0.0",
              //     totalIncomeDistribution: "0.0",
              //     teamReinvestmentAmount: "4144476.0",
              //   },
              //   {
              //     reinvestmentAmount: "0.0",
              //     month: "5",
              //     year: "2021",
              //     totalReinvestmentAmount: "0.0",
              //     totalIncomeDistribution: "0.0",
              //     teamReinvestmentAmount: "3.3882974E7",
              //   },
              //   {
              //     reinvestmentAmount: "0.0",
              //     month: "6",
              //     year: "2021",
              //     totalReinvestmentAmount: "0.0",
              //     totalIncomeDistribution: "0.0",
              //     teamReinvestmentAmount: "5.2372877E7",
              //   },
              //   {
              //     reinvestmentAmount: "0.0",
              //     month: "7",
              //     year: "2021",
              //     totalReinvestmentAmount: "0.0",
              //     totalIncomeDistribution: "0.0",
              //     teamReinvestmentAmount: "3.1757463E7",
              //   },
              //   {
              //     reinvestmentAmount: "0.0",
              //     month: "8",
              //     year: "2021",
              //     totalReinvestmentAmount: "0.0",
              //     totalIncomeDistribution: "0.0",
              //     teamReinvestmentAmount: "1.7673985E7",
              //   },
              //   {
              //     reinvestmentAmount: "0.0",
              //     month: "9",
              //     year: "2021",
              //     totalReinvestmentAmount: "0.0",
              //     totalIncomeDistribution: "0.0",
              //     teamReinvestmentAmount: "1.6614368E7",
              //   },
              //   {
              //     reinvestmentAmount: "0.0",
              //     month: "10",
              //     year: "2021",
              //     totalReinvestmentAmount: "0.0",
              //     totalIncomeDistribution: "0.0",
              //     teamReinvestmentAmount: "2.2256993E7",
              //   },
              //   {
              //     reinvestmentAmount: "0.0",
              //     month: "11",
              //     year: "2021",
              //     totalReinvestmentAmount: "0.0",
              //     totalIncomeDistribution: "0.0",
              //     teamReinvestmentAmount: "2.3839443E7",
              //   },
              // ];

              resp.data = resp.data.conversions;

              if (resp.data && resp.data.length > 0) {
                resp.data.forEach((respobj) => {
                  for (const x of tempArrReinvest) {
                    if (x[0] === parseInt(respobj.month)) {
                      x[1] = parseInt(respobj.year);
                      x[2] = respobj.reinvestmentAmount
                        ? parseFloat(respobj.reinvestmentAmount)
                        : 0;
                      x[3] = respobj.teamReinvestmentAmount
                        ? parseFloat(respobj.teamReinvestmentAmount)
                        : 0;
                      tempTotReinvest =
                        parseFloat(respobj.teamReinvestmentAmount) +
                          parseFloat(respobj.reinvestmentAmount) >
                        0
                          ? parseFloat(respobj.teamReinvestmentAmount) +
                            parseFloat(respobj.reinvestmentAmount)
                          : 0;
                      //console.log(tempTotReinvest);
                    }
                  }
                });
                // setoutflw([])
              }
              setrenderreinvest(false);
              setreivst(tempArrReinvest);
              var d = new Date(valuationForRein);

              // if (reinvestment == "Year-to-Date") {
              //   let tempTotal =
              //     tempArrReinvest[d.getMonth() + 1][2] +
              //     tempArrReinvest[d.getMonth()][3];
              //   setTotReinvestment(tempTotal);
              // } else {
              //   tempTotReinvest
              //     ? setTotReinvestment(tempTotReinvest)
              //     : setTotReinvestment(0);
              // }
              tempTotReinvest
              ? setTotReinvestment(tempTotReinvest)
              : setTotReinvestment(0);
            });
          });
        }
      });
    });
  }, [reinvestment]);

  useEffect(() => {
    if (!render) {
      if (
        dProsp.length > 0 &&
        dTarg.length > 0 &&
        dAct.length > 0 &&
        dMyAUM.length > 0 &&
        dTeamAUM.length > 0
      ) {
        setrender(true);
      }
    }
  }, [dProsp, dTarg, dAct, dMyAUM, dTeamAUM]);

  useEffect(() => {
    if (!renderonb) {
      if (onbclients.length > 0) {
        setrenderonb(true);
      }
    }
  }, [onbclients]);

  useEffect(() => {
    if (!renderreinvest) {
      if (reivst[0].length > 0) {
        setrenderreinvest(true);
      }
    }
  }, [reivst]);

  useEffect(() => {
    if (!renderfundraise) {
      if (genclientfund[0].length > 0) {
        setrenderfundraise(true);
      }
    }
  }, [genclientfund]);

  return (
    <IonGrid class="mr-13 ml-13" style={styles.rootGrid}>
      <IonRow>
        {valuationDate ? (
          <GeneralChartInstance
            valuationDate={valuationDate}
            timeDropDown={timeDrpDmGenFnds}
            CardTitle={"Current AUM for "+vDate}
            Total={TotAUM}
            IconsObj={[
              {
                label: `My Clients`,
                icon: (
                  <IonIcon
                    icon={ellipse}
                    style={{ color: "#B99855", ...styles.iconsize }}
                  />
                ),
              },
              {
                label: `Team Raised`,
                icon: (
                  <IonIcon
                    icon={ellipse}
                    style={{ color: "#40412F", ...styles.iconsize }}
                  />
                ),
              },
            ]}
            chartData={
              renderfundraise && genclientfund.length > 0
                ? [genclientfund]
                : [[]]
            }
            tf={gencntfndTf}
            setTimeFrame={setGenClientFundTimeframe}
          />
        ) : null}
        {true ? (
          <GenOnbClientChartInstance
            timeDropDown={timeDropDown}
            CardTitle="Onboarded Clients"
            Total={TotTeamCnt}
            IconsObj={[
              {
                label: "My Clients",
                icon: (
                  <IonIcon
                    icon={ellipse}
                    style={{ color: "#A5BFC5", ...styles.iconsize }}
                  />
                ),
              },
              {
                label: "Team Clients",
                icon: (
                  <IonIcon
                    icon={ellipse}
                    style={{ color: "#6D8A96", ...styles.iconsize }}
                  />
                ),
              },
            ]}
            chartData={renderonb && onbclients.length > 0 ? [onbclients] : [[]]}
            tf={OnbCntTf}
            setTimeFrame={setOnbClientTimeframe}
          />
        ) : null}
      </IonRow>
      <IonRow>
        {valuationDate ? (
          <ChartInstance
            timeDropDown={timeDropDown}
            // CardTitle="Total Commitment Forecast"
            // Chartname="Total Commitment Forecast"
            CardTitle="Total Signed Commitments"
            Chartname="Total Signed Commitments"
            Total={maxCmt}
            IconsObj={[
              {
                label: "Target",
                icon: (
                  <IonIcon
                    icon={ellipse}
                    style={{ color: "#BFC5F4", ...styles.iconsize }}
                  />
                ),
              },
              {
                label: "Actual",
                icon: (
                  <IonIcon
                    icon={ellipse}
                    style={{ color: "#F7B198", ...styles.iconsize }}
                  />
                ),
              },
            ]}
            chartData={
              render && dTarg.length > 0 && dAct.length > 0
                ? [dTarg, dAct]
                : [[]]
            }
            tf={cmtTf}
            setTimeFrame={setCommitmentTimeframe}
          />
        ) : null}
        {valuationDate ? (
          <GeneralChartInstance
            valuationDate={valuationDate}
            timeDropDown={timeDropDownReinvest}
            CardTitle="Reinvestment"
            Total={TotReinvestment}
            IconsObj={[
              {
                label: `My Clients`,
                icon: (
                  <IonIcon
                    icon={ellipse}
                    style={{ color: "#5DA683", ...styles.iconsize }}
                  />
                ),
              },
              {
                label: `Team Clients`,
                icon: (
                  <IonIcon
                    icon={ellipse}
                    style={{ color: "#175E3C", ...styles.iconsize }}
                  />
                ),
              },
            ]}
            chartData={renderreinvest && reivst.length ? [reivst] : [[]]}
            tf={reinvestment}
            setTimeFrame={setReinvestmentTimeframe}
          />
        ) : null}
      </IonRow>
      <IonRow>
        <IonCol size-xs="12" size-sm="12" size-lg="12">
        {valuationDate ? (
          <ChartInstance
          valuationDate={valuationDate}
            timeDropDown={timeDropDownReinvest}
            CardTitle="Managed AUM"
            Chartname="Managed AUM"
            Total={GenTotTeamAUM}
            IconsObj={[
              {
                label: `Total for Team`,
                icon: (
                  <IonIcon
                    icon={ellipse}
                    style={{ color: "#BFC5F4", ...styles.iconsize }}
                  />
                ),
              },
              {
                //${avgAUMCnt.toFixed(1)}
                label: `${retTotalAmt(avgAUMCnt)} avg. per individual`,
                icon: (
                  <IonIcon
                    icon={ellipse}
                    style={{ color: "#5DA683", ...styles.iconsize }}
                  />
                ),
              },
            ]}
            chartData={
              render && dMyAUM.length > 0 && dTeamAUM.length > 0
                ? [dMyAUM, dTeamAUM]
                : [[]]
            }
            tf={ManAUMTf}
            setTimeFrame={setManagedAUMTimeframe}
          />):null}
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default GeneralChart;
