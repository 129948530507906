import {
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
  IonCol,
  IonInput,
  IonCheckbox,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonText,
} from "@ionic/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Field } from "formik";
import { chevronDown } from "ionicons/icons";
import "./CreateProspect.css";
// import CountryCallingCodes from "src/components/PhCountryCodes/CountryCallingCode";
import IMask from "imask";
import countryList from "src/components/PhCountryCodes/Nationality";
import useWindowDimensions from "src/components/useWindowDimensions";
// const countryList = [
//     { val: "Saudi Arabia" },
//     { val: "Bahrain" },
//     { val: "Kuwait" },
//     { val: "United Arab Emiratesa" },
//     { val: "United States" },
//     { val: "United Kingdom" },
// ]

const styles = {
  root: {
    padding: 27,
  },
  selectItem: {
    "--padding-start": "0px",
    backgroundColor: "#222222",
    marginTop: 8,
  },
  select: {
    "--padding-start": "8px",
  },
  label: {
    // marginBottom: 8
  },
  checklist: {
    "--padding-start": "0px",
  },
  listHeader: {
    fontSize: 14,
    paddingInlineStart: 0,
    marginBottom: 8,
    maxHeight: 0,
    fontWeight: "normal",
  },
};

const optionsA = {
  cssClass: "my-custom-interface",
};

const optionsB = {
  cssClass: "my-custom-interface-width",
};

const ContactInform: React.FC<any> = ({
  values,
  handleChange,
  setFieldValue,
  errors,
  touched,
  sourceOfName,
  setFieldTouched,
  handleBlur,
}) => {
  const optionsA = {
    cssClass: "my-custom-interface",
  };
  const optionsNat = {
    cssClass: "my-custom-interface my-custom-interface-nationality",
  };
  useEffect(() => {
    setFieldTouched("email", false);
    setFieldTouched("priphnum", false);
  }, [setFieldTouched]);
  const { height, width } = useWindowDimensions();
  return (
    <React.Fragment>
      <IonGrid class="padding-unset" style={styles.root}>
        <IonRow>
          <IonCol size-xs="4" size-sm="5" size-lg="5"></IonCol>
          <IonCol size-xs="4" size-sm="5" size-lg="5"></IonCol>
          <IonCol
            size-xs="4"
            size-sm="2"
            size-lg="2"
            style={{ fontSize: 14, textAlign: "right" }}
          >
            * = required
          </IonCol>
        </IonRow>
        <IonRow class="ion-margin-bottom">
          <IonCol size-xs="12" sizeSm="6" sizeLg="5" sizeXl="5">
            <IonLabel class="font14" style={styles.label}>
              Source of Prospect
            </IonLabel>
            <IonItem
              lines="none"
              class="ion-item-height"
              style={styles.selectItem}
            >
              <Field
                as={IonSelect}
                name="source_of_name"
                interfaceOptions={optionsA}
                style={styles.select}
                value={values.source_of_name}
                onIonBlur={handleBlur}
                onIonChange={handleChange}
                mode="ios"
                class="customIonSelect"
                placeholder="Select Source"
                interface={width < 576 ? "action-sheet" : "popover"}
              >
                {sourceOfName.map(({ val }, i) => (
                  <IonSelectOption key={i} value={val}>
                    {val}
                  </IonSelectOption>
                ))}
              </Field>
              <IonIcon slot="end" icon={chevronDown} color="gold" />
            </IonItem>
          </IonCol>
          <IonCol size-xs="12" sizeSm="6" sizeLg="5" sizeXl="5">
            <IonLabel class="font14" style={styles.label}>
              *Email
            </IonLabel>
            <IonItem
              lines="none"
              class="ion-item-height"
              style={styles.selectItem}
            >
              <Field
                as={IonInput}
                class="inpClass font14"
                onIonChange={handleChange}
                onIonBlur={handleBlur}
                value={values.email}
                name="email"
                placeholder="Enter Email"
              />
            </IonItem>
            {Boolean(errors.email && touched.email) ? (
              <IonText color="danger">{errors.email}</IonText>
            ) : null}
          </IonCol>
        </IonRow>
        <IonRow class="ion-margin-bottom">
          <IonCol size-xs="12" sizeSm="6" sizeLg="5" sizeXl="5">
            <IonLabel class="font14" style={styles.label}>
              *Primary Phone Number
            </IonLabel>{" "}
            <IonRow>
              {/* <IonCol size="5" size-lg="5" sizeXl="4" style={{ paddingLeft: 0 }}>
                <IonItem class ="h-70 ion-item-height" style={styles.selectItem} lines="none">
                  <Field
                    as={IonSelect}
                    interfaceOptions={optionsA}
                    style={styles.select}
                    mode="ios"
                    //onIonChange={handleChange}
                    onIonChange={(e) => {
                          setFieldValue("phCode", e.detail.value);
                          let temp =countryList.filter(d=>(d.phone == e.detail.value) ? setFieldValue("country",d.label): "")
                        }
                      }
                    onIonBlur={handleBlur}
                    value={values.phCode}
                    class="customIonSelect font14"
                    name="phCode"
                    placeholder="BH +973"
                    interface={width < 576 ? "action-sheet" : "popover"}
                  >
                    {countryList.map(({ phone, code }, i) => (
                      <IonSelectOption key={i} value={phone}>
                        {code} {phone}
                      </IonSelectOption>
                    ))}
                  </Field>
                  <IonIcon slot="end" icon={chevronDown} color="gold" />
                </IonItem>
              </IonCol> */}
              <IonCol
                size="12"
                size-lg="12"
                sizeXl="12"
                style={{ paddingRight: 0 }}
              >
                <IonItem class="h-70" lines="none" style={styles.selectItem}>
                  <Field
                    // as={
                    //     MaskedIonInput
                    // }
                    className="inpClass font14"
                    // onIonChange={handleChange}
                    // onChange={(e) => {
                    //     setFieldValue("priphnum", e)
                    //     setFieldTouched("priphnum", true)
                    // }}
                    // onIonBlur={handleBlur}
                    debounce={500}
                    value={values.priphnum}
                    name="priphnum"
                    type="tel"
                    placeholder="988998899"
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            {Boolean(errors.priphnum && touched.priphnum) ? (
              <IonText color="danger">{errors.priphnum}</IonText>
            ) : null}
          </IonCol>
          <IonCol size-xs="12" sizeSm="6" sizeLg="5" sizeXl="5">
            <IonLabel
              class="font14"
              style={{
                ...styles.label,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {/* Whatsapp(If Different from Primary Number) */}
              Mobile Phone Number
            </IonLabel>
            <IonRow>
              {/* <IonCol size="5" size-lg="5" sizeXl="4" style={{ paddingLeft: 0 }}>
                <IonItem class="h-70" style={styles.selectItem} lines="none">
                  <Field
                    as={IonSelect}
                    interfaceOptions={optionsA}
                    style={styles.select}
                    mode="ios"
                    onIonChange={handleChange}
                    onIonBlur={handleBlur}
                    value={values.phCodeWht}
                    class="customIonSelect font14"
                    name="phCodeWht"
                    placeholder="BH +973"
                    interface={width < 576 ? "action-sheet" : "popover"}
                  >
                    {countryList.map(({ phone, code }, i) => (
                      <IonSelectOption key={i} value={phone}>
                        {code} {phone}
                      </IonSelectOption>
                    ))}
                  </Field>
                  <IonIcon slot="end" icon={chevronDown} color="gold" />
                </IonItem>
              </IonCol> */}
              <IonCol
                size="12"
                size-lg="12"
                sizeXl="12"
                style={{ paddingRight: 0 }}
              >
                <IonItem class="h-70" lines="none" style={styles.selectItem}>
                  <Field
                    // as={
                    //     MaskedIonInput
                    // }
                    className="inpClass font14"
                    // onIonChange={handleChange}
                    // onChange={(e) => {
                    //     setFieldValue("whtphnum", e)
                    // }}
                    //onIonBlur={handleBlur}
                    value={values.whtphnum}
                    name="whtphnum"
                    // type="tel"
                    placeholder="988998899"
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            {Boolean(errors.whtphnum && touched.whtphnum) ? (
              <IonText color="danger">{errors.whtphnum}</IonText>
            ) : null}
          </IonCol>
        </IonRow>
        <IonRow class="ion-margin-bottom">
          <IonCol size-xs="12" sizeSm="6" sizeLg="5">
            <IonLabel class="font14" style={styles.label}>
              Address Line 1
            </IonLabel>
            <IonItem
              lines="none"
              class="ion-item-height"
              style={styles.selectItem}
            >
              <Field
                as={IonInput}
                class="inpClass font14"
                onIonChange={handleChange}
                onIonBlur={handleBlur}
                value={values.addln1}
                name="addln1"
                placeholder="Enter Address"
                autocapitalize="sentences"
              />
            </IonItem>
          </IonCol>
          <IonCol size-xs="12" sizeSm="6" sizeLg="5">
            <IonLabel class="font14" style={styles.label}>
              Address Line 2
            </IonLabel>
            <IonItem
              lines="none"
              class="ion-item-height"
              style={styles.selectItem}
            >
              <Field
                as={IonInput}
                class="inpClass font14"
                onIonChange={handleChange}
                onIonBlur={handleBlur}
                value={values.addln2}
                name="addln2"
                placeholder="Enter Address"
                autocapitalize="sentences"
              />
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow class="ion-margin-bottom">
          <IonCol size-xs="12" sizeSm="6" sizeLg="5">
            <IonLabel class="font14" style={styles.label}>
              City
            </IonLabel>
            <IonItem
              lines="none"
              class="ion-item-height"
              style={styles.selectItem}
            >
              <Field
                as={IonInput}
                class="inpClass font14"
                onIonChange={handleChange}
                onIonBlur={handleBlur}
                value={values.city}
                name="city"
                placeholder="Enter City"
                autocapitalize="sentences"
              />
            </IonItem>
          </IonCol>
          <IonCol size-xs="12" sizeSm="6" sizeLg="5">
            <IonLabel class="font14" style={styles.label}>
              Country
            </IonLabel>
            <IonItem
              lines="none"
              class="ion-item-height"
              style={styles.selectItem}
            >
              <Field
                as={IonSelect}
                interfaceOptions={optionsB}
                style={styles.select}
                value={values.country}
                onIonChange={handleChange}
                onIonBlur={handleBlur}
                name="country"
                mode="ios"
                class="customIonSelect"
                placeholder="Select Country"
                interface={width < 576 ? "action-sheet" : "popover"}
              >
                {countryList.map((o) => (
                  <IonSelectOption key={o.label} value={o.label}>
                    {o.label}
                  </IonSelectOption>
                ))}
              </Field>
              <IonIcon slot="end" icon={chevronDown} color="gold" />
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow class="ion-margin-bottom">
          <IonCol size-xs="12" sizeSm="6" sizeLg="5">
            <IonLabel class="font14" style={styles.label}>
              Building Details
            </IonLabel>
            <IonItem
              lines="none"
              class="ion-item-height"
              style={styles.selectItem}
            >
              <Field
                as={IonInput}
                class="inpClass font14"
                onIonChange={handleChange}
                onIonBlur={handleBlur}
                value={values.bldnum}
                name="bldnum"
                placeholder="Enter Building Number"
                autocapitalize="sentences"
              />
            </IonItem>
          </IonCol>
          <IonCol size-xs="12" sizeSm="6" sizeLg="5">
            <IonLabel class="font14" style={styles.label}>
              Nationality
            </IonLabel>
            <IonItem
              lines="none"
              class="ion-item-height"
              style={styles.selectItem}
            >
              <Field
                as={IonSelect}
                interfaceOptions={optionsNat}
                style={styles.select}
                value={values.nationality}
                onIonChange={handleChange}
                onIonBlur={handleBlur}
                name="nationality"
                mode="ios"
                class="customIonSelect"
                placeholder="Select Nationality"
                interface={width < 576 ? "action-sheet" : "popover"}
              >
                {countryList.map((o) => (
                  <IonSelectOption key={o.nat} value={o.nat}>
                    {o.nat}
                  </IonSelectOption>
                ))}
              </Field>
              <IonIcon slot="end" icon={chevronDown} color="gold" />
            </IonItem>
          </IonCol>
          {/* <IonCol sizeSm="6" sizeLg="5">
                        <IonLabel class="font14" style={styles.label}>Office Number</IonLabel>
                        <IonItem lines="none" style={styles.selectItem}>
                            <Field
                                as={
                                    IonInput
                                }
                                class="inpClass font14"
                                onIonChange={handleChange}
                                value={values.ofcnum}
                                name="ofcnum"
                                placeholder="Enter Office Number"
                            />
                        </IonItem>
                    </IonCol> */}
        </IonRow>
        {/* <IonRow class="ion-margin-bottom">
                    <IonCol sizeSm="6" sizeLg="5">
                        <IonLabel class="font14" style={styles.label}>Floor Number</IonLabel>
                        <IonItem lines="none" style={styles.selectItem}>
                            <Field
                                as={
                                    IonInput
                                }
                                class="inpClass font14"
                                onIonChange={handleChange}
                                value={values.flrnum}
                                name="flrnum"
                                placeholder="Enter Floor Number"
                            />
                        </IonItem>
                    </IonCol>
                </IonRow> */}
        <IonRow>
          <IonCol size-xs="12" size-sm="5">
            <IonLabel style={{ fontSize: 14 }}>
              Prefered Method(s) of Communication
            </IonLabel>
            <IonItem lines="none" style={styles.checklist}>
              <Field
                name="Prefphcall"
                as={IonCheckbox}
                class="ion-color-actblue-v1"
                color="actblue"
                checked={values.Prefphcall}
                onIonBlur={handleBlur}
                onIonChange={(e) => {
                  setFieldValue("Prefphcall", e.detail.checked);
                }}
                mode="md"
              />
              <IonLabel
                class="grayscale6 marginleftp10"
                style={{ fontSize: 12 }}
              >
                Phone Call
              </IonLabel>
            </IonItem>
            <IonItem lines="none" class="margintopn20" style={styles.checklist}>
              <Field
                name="Prefemail"
                as={IonCheckbox}
                class="ion-color-actblue-v1"
                color="actblue"
                checked={values.Prefemail}
                onIonBlur={handleBlur}
                onIonChange={(e) => {
                  setFieldValue("Prefemail", e.detail.checked);
                }}
                mode="md"
              />
              <IonLabel
                class="grayscale6 marginleftp10"
                style={{ fontSize: 12 }}
              >
                Email
              </IonLabel>
            </IonItem>
            {/* <IonItem lines="none" class="margintopn20" style={styles.checklist}  >
                            <Field name="PrefSMS" as={IonCheckbox} class="ion-color-actblue-v1" color="actblue" checked={values.PrefSMS} onIonChange={(e) => {
                                setFieldValue("PrefSMS", e.detail.checked)
                            }} mode="md" />
                            <IonLabel class="grayscale6 marginleftp10" style={{ fontSize: 12 }}>SMS</IonLabel>
                        </IonItem> */}
            <IonItem lines="none" class="margintopn20" style={styles.checklist}>
              <Field
                name="PrefWht"
                as={IonCheckbox}
                class="ion-color-actblue-v1"
                color="actblue"
                checked={values.PrefWht}
                onIonChange={(e) => {
                  setFieldValue("PrefWht", e.detail.checked);
                }}
                mode="md"
                onIonBlur={handleBlur}
              />
              <IonLabel
                class="grayscale6 marginleftp10"
                style={{ fontSize: 12 }}
              >
                Whatsapp
              </IonLabel>
            </IonItem>
          </IonCol>
          <IonCol size-xs="12" size-sm="5">
            <IonLabel>Prefered Time of Day</IonLabel>
            <IonItem lines="none" style={styles.checklist}>
              <Field
                name="PrefMor"
                as={IonCheckbox}
                class="ion-color-actblue-v1"
                color="actblue"
                checked={values.PrefMor}
                onIonChange={(e) => {
                  setFieldValue("PrefMor", e.detail.checked);
                }}
                mode="md"
                onIonBlur={handleBlur}
              />
              <IonLabel
                class="grayscale6 marginleftp10"
                style={{ fontSize: 12 }}
              >
                Morning
              </IonLabel>
            </IonItem>
            <IonItem lines="none" style={styles.checklist} class="margintopn20">
              <Field
                name="PrefNoon"
                as={IonCheckbox}
                class="ion-color-actblue-v1"
                color="actblue"
                checked={values.PrefNoon}
                onIonChange={(e) => {
                  setFieldValue("PrefNoon", e.detail.checked);
                }}
                mode="md"
                onIonBlur={handleBlur}
              />
              <IonLabel
                class="grayscale6 marginleftp10"
                style={{ fontSize: 12 }}
              >
                Afternoon
              </IonLabel>
            </IonItem>
            <IonItem lines="none" style={styles.checklist} class="margintopn20">
              <Field
                name="PrefEve"
                as={IonCheckbox}
                class="ion-color-actblue-v1"
                color="actblue"
                checked={values.PrefEve}
                onIonChange={(e) => {
                  setFieldValue("PrefEve", e.detail.checked);
                }}
                mode="md"
                onIonBlur={handleBlur}
              />
              <IonLabel
                class="grayscale6 marginleftp10"
                style={{ fontSize: 12 }}
              >
                Evening
              </IonLabel>
            </IonItem>
          </IonCol>
        </IonRow>
      </IonGrid>
    </React.Fragment>
  );
};

const MaskedIonInput = ({ value, onChange }) => {
  const maskRef = useRef<IMask.InputMask<any> | null>(null);

  const inputCallback = useCallback(async (input) => {
    if (!input) {
      return;
    }
    const nativeInput = await input.getInputElement();
    const mask = IMask(nativeInput, {
      mask: "0 00 00 00 00 0",
    })
      .on("accept", (e: any) => {
        onChange(mask.value);
      })
      .on("complete", (e: any) => {
        onChange(mask.value);
      });

    maskRef.current = mask;
  }, []);

  return (
    <IonInput
      style={{ "--padding-start": "8px" }}
      value={value}
      ref={inputCallback}
      placeholder="9 88 99 88 99"
    />
  );
};

export default ContactInform;
