import {
  IonItem,
  IonText,
  IonIcon,
  IonGrid,
  IonRow,
  IonAvatar,
  IonLabel,
  IonButton,
  IonReorderGroup,
  IonReorder,
  IonBadge,
  IonCol,
  IonList,
  IonPopover,
  IonFooter,
  IonLoading,
  IonCard,
} from "@ionic/react";
// import { ItemReorderEventDetail } from '@ionic/core';
import personstar from "src/icons/targ_empty.svg";
import { ellipse, menuOutline, addOutline } from "ionicons/icons";
import CryptoJS from 'crypto-js';
import "./TopTarget.css";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Network, Plugins } from "@capacitor/core";
import api from "src/api";
import useWindowDimensions from "src/components/useWindowDimensions";
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics';
const { Storage , Device } = Plugins;
//import Removepopup from "./Removepopup";
// import { getJSDocReadonlyTag } from "typescript";
const imgbaseuri = "https://cdn2.hubspot.net/";

const decrypt = (decryptVlaue) => {
  let passphrase = "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9"
  const bytes = CryptoJS.AES.decrypt(decryptVlaue, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};

const getUserId = async () => {
  const ret: any = await Storage.get({ key: "userId" });
  const temp = JSON.parse(decrypt(ret?.value));
  return temp?.id;
};

const styles = {
  grid: {
    marginLeft: 32,
    marginRight: 32,
    marginTop: 32,
  },

  item: {
    "--background": "#222222",
    width: 1500,
    marginBottom: 2,
    borderRadius: 8,
    "--min-height": "64px",
  },

  item2: {
    borderstyle: "dashed",
    width: 1500,
    marginBottom: 2,
    borderRadius: 8,
    color: "grey",
  },
  desc: {
    fontFamily: "Almarai",
    fontSize: 16,
    color: "#828282",
    marginBottom: 8,
  },
  period: {
    fontSize: 6,
    marginLeft: 4,
    marginRight: 4,
    marginTop: 8,
    color: "#828282",
  },
  label: {
    marginTop: 14.5,
    marginBottom: 14.5,
  },
  title: {
    fontWeight: "bold",
    cursor:"pointer"
  },
  reordericon: {
    color: "grey",
    size: "small",
    borderRadius: 8,
    marginRight: 10,
  },
  removebutton: {
    // fontWeight: "bold",
    mode: "ios",
    color: "#B99855",
    slot: "end",
  },
  addicon: {
    color: "grey",
    size: "large",
    zoom: 2,
    cursor: "pointer",
  },
  addcol: {
    textAlign: "center",
  },
};

interface ShwRmvPopup {
  open: boolean;
  event: Event | undefined;
}
//sendTargetDataToParent

const TopTargets: React.FC<any> = ({
  sendTargetDataToParent,prospectcnt}) => {
  // const [rmvMenu, setRmvMenu] = useState<ShwRmvPopup>({
  //     open: false,
  //     event: undefined
  // })
  // const [showPopover, setShowPopover] = useState(false);
  const [showPopover, setShowPopover] = useState<{
    open: boolean;
    event: Event | undefined;
  }>({
    open: false,
    event: undefined,
  });
const[totprosCount,settotprosCount]=useState<any>(prospectcnt);
  const [deleteTarget, setdeleteTarget] = useState<any>(0);

  const [TargetData, setTargetData] = useState<any>([]);
  const [contactArr, setcontactArr] = useState<any>();
  const tempval = React.useRef();

  const [renderComp, setrenderComp] = useState<boolean>(false);
  const [addremconf, setaddremconf] = useState<boolean>(true);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [show25Popover, setshow25Popover] = useState<{
    open: boolean;
    event: Event | undefined;
  }>({
    open: false,
    event: undefined,
  });
  useEffect(() => {
    return () => {
      tempval.current = TargetData;
    };
  }, [TargetData]);

  const getUserEmail = async () => {
    const ret: any = await Storage.get({ key: "userId" })
    const temp = JSON.parse(decrypt(ret.value))
    return temp && temp.email ? temp.email : null
}

  useEffect(() => {
    return () => {
      Network.getStatus().then((network) => {
        if(network.connected) {     
          setrenderComp(false);
          setLoading(true);
          getUserId().then((userId) => {
            let updArray: any = tempval.current;

            let updContArray: any = [];
            for (let i = 0; i < updArray.length; i++) {
              updContArray.push({
                contactid: Number(updArray[i].id),
                rank: updArray[i].rank,
              });
            }

            let payloads: any = {
              rm_id: userId,
              prospect_list: JSON.stringify(updContArray),
            };
            api.postTopTargets(payloads).then((resp) => {
              let temp =
                updContArray === null && updContArray !== "" ? [] : updContArray;
              api.setTopTargetsinStorage(JSON.stringify(temp)).then(() => {
                setLoading(false);
                setrenderComp(true);
              });
            });
          });
        }})
    }
  }, []);

  useEffect(() => {
   

    Network.getStatus().then((network) => {
      if(network.connected) {
    

    sendTargetDataToParent(totprosCount);
    if (addremconf) {
      setrenderComp(false);
      setLoading(true);
      getUserId().then((userId) => {
        if (userId && userId !== null && userId !== "") {
          api.getTTfromStore().then((resp) => {
            let topTargetProspects = resp;
            let ContactIdArray: any = [];

            if (
              topTargetProspects &&
              topTargetProspects !== null &&
              topTargetProspects.length > 0
            ) {
              topTargetProspects.map((prosList) => {
                ContactIdArray.push(prosList);
              });
              setcontactArr(ContactIdArray);
              let payload: any = {
                inputs: [],
                properties: [
                  "firstname,lastname,middle_name,first_name_arabic,arabic_last_name,arabic_middle_name,hs_avatar_filemanager_key",
                ],
              };
              ContactIdArray.map((conId) => {
                payload.inputs.push({ id: conId.contactid });
              });
              api.getTopTargetContacts(payload).then((resp) => {
                // console.log(resp);
                let contactset: any = [];
                if (
                  resp.results &&
                  resp.results !== null &&
                  resp.results.length > 0
                ) {
                  resp.results.map((prosList, i) => {
                    let prospRank: any = "";
                    ContactIdArray.map((conId, i) => {
                      if (
                        parseInt(conId.contactid) ===
                        parseInt(prosList.properties.hs_object_id)
                      ) {
                        prospRank = conId.rank;
                      }
                    });
                    contactset.push({
                      // FullName: prosList.properties.firstname ? prosList.properties.lastname ? prosList.properties.firstname + prosList.properties.lastname : prosList.properties.firstname : "",
                      FullName:
                        prosList.properties.middle_name !== "" &&
                        prosList.properties.middle_name !== null
                          ? `${prosList.properties.firstname} ${prosList.properties.middle_name} ${prosList.properties.lastname}`
                          : `${prosList.properties.firstname} ${prosList.properties.lastname}`,
                      // ArabicName: prosList.properties.first_name_arabic ? prosList.properties.arabic_last_name ? prosList.properties.first_name_arabic + prosList.properties.arabic_last_name : prosList.properties.first_name_arabic : "",
                      ArabicName:
                        prosList.properties.arabic_middle_name !== "" &&
                        prosList.properties.arabic_middle_name !== null
                          ? `${prosList.properties.first_name_arabic} ${prosList.properties.arabic_middle_name} ${prosList.properties.arabic_last_name}`
                          : `${prosList.properties.first_name_arabic} ${prosList.properties.arabic_last_name}`,
                      id: prosList.properties.hs_object_id,
                      avatarPath: prosList.properties.hs_avatar_filemanager_key
                        ? `${imgbaseuri}${prosList.properties.hs_avatar_filemanager_key}`
                        : "/assets/empty-avatar.svg",
                      rank: prospRank,
                    });
                  });
                } else {
                  setLoading(false);
                  setrenderComp(true);
                }

                contactset.sort(function(a, b) {
                  return a.rank - b.rank;
                });

                setTargetData(contactset);
                setLoading(false);
                setrenderComp(true);
              });
            } else if (topTargetProspects.length === 0) {
              setTargetData([]);
              setLoading(false);
              setrenderComp(true);
            } else {
              setLoading(false);
              setrenderComp(true);
            }
          });
        }
      });
      setaddremconf(false);
    }
   }})

  }, [deleteTarget, addremconf]);

  function doReorder(event: CustomEvent) {
    setTargetData(event.detail.complete(TargetData));
    
    for (let j = 0; j < TargetData.length; j++) {
      TargetData[j].rank = j + 1;
    }
    // console.log(TargetData);
  }

  const history = useHistory();

  const triggerSearch = () => {
    history.push("/prospects/toptargetsearch");
  };
  function doNothing() {
    setShowPopover({ open: false, event: undefined });
  }

  function deletetargetFunc() {
    Device.getInfo().then(deviceInfo => {
    getUserEmail().then(useremail => {
    getUserId().then((userId) => {
      let updContArray: any = [];
      let temprank = 0;
      let tempflag = "N";
      for (let i = 0; i < contactArr.length; i++) {
        if (parseInt(contactArr[i].contactid) === parseInt(deleteTarget)) {
          tempflag = "Y";
        } else {
          if (tempflag === "Y") {
            temprank = i;
          } else {
            temprank = i + 1;
          }
          updContArray.push({
            contactid: contactArr[i].contactid,
            rank: temprank,
          });
        }
      }
      //console.log(updContArray);
      let payloads: any = {
        rm_id: userId,
        created_date: "2020-12-19T09:38:26",
        updated_date: "2020-12-10T11:40:08",
        prospect_list: JSON.stringify(updContArray),
      };
      //update db
      api.postTopTargets(payloads).then((resp) => {
        setShowPopover({ open: false, event: undefined });
        let temp =
          updContArray !== "" && updContArray !== null ? updContArray : [];
        api.setTopTargetsinStorage(JSON.stringify(temp)).then(() => {
          setaddremconf(true);
          api.getTTfromStore().then((resp) => {
            if(deviceInfo.platform == "web")
            {
              window.globalThis.firebase.analytics().setUserId(useremail);
              window.globalThis.firebase.analytics().logEvent('select_content', {
              user_name: useremail,
              content_type: 'RemoveTopTarget',
              item_id: parseInt(deleteTarget),
              device_id:deviceInfo?deviceInfo.uuid:"",
              platform:deviceInfo?deviceInfo.platform:"",
              device_model:deviceInfo?deviceInfo.model:"",
              device_type:deviceInfo?deviceInfo.osVersion:"",
              device_os:deviceInfo?deviceInfo.operatingSystem:"",
            });
            }
            else{
                FirebaseAnalytics.setUserId(useremail);
                FirebaseAnalytics.logEvent("select_content", {user_name: useremail});
                FirebaseAnalytics.logEvent("select_content", {content_type: 'RemoveTopTarget'});
                FirebaseAnalytics.logEvent("select_content", {item_id: parseInt(deleteTarget)});
                FirebaseAnalytics.logEvent("select_content", {device_id: deviceInfo?deviceInfo.uuid:""});
                FirebaseAnalytics.logEvent("select_content", {platform: deviceInfo?deviceInfo.platform:""});
                FirebaseAnalytics.logEvent("select_content", {device_model: deviceInfo?deviceInfo.model:""});
                FirebaseAnalytics.logEvent("select_content", {device_type:deviceInfo?deviceInfo.osVersion:""});
                FirebaseAnalytics.logEvent("select_content", {device_os:deviceInfo?deviceInfo.operatingSystem:""});
            }

            
            setdeleteTarget(0);
          });
        });
      });
    })
  })
    })
  }
  const hist = useHistory();

  const { height, width } = useWindowDimensions();

  function routetoProfile(id)
  {
    
    hist.push('/contactinfo/'+id)
  }

  return (
    // <IonGrid style={styles.grid}>
    <IonGrid class="mobile-reorder-grid" style={styles.grid}>
      {/* <IonPopover
                      isOpen={showPopover}
                      cssClass='my-custom-class'
                      onDidDismiss={e => setShowPopover(false)}
                      mode="md"
                  > */}
      <IonPopover
        cssClass="delete25popover"
        isOpen={show25Popover.open}
        event={show25Popover.event}
        onDidDismiss={(e) =>
          setshow25Popover({ open: false, event: undefined })
        }
      >
        <IonGrid class="rmvpopover_a">
          <IonRow class="rmvtarget"> Reached limit of 25 targets </IonRow>
          <IonRow class="conrmvtarget">
            Please remove some of your top targets to add another{" "}
          </IonRow>
          <IonFooter class="conrmvfooter">
            <IonRow>
              <IonCol>
                <IonButton
                  onClick={() =>
                    setshow25Popover({ open: false, event: undefined })
                  }
                  class="ConfirmationButton"
                  fill="clear"
                >
                  OK
                </IonButton>
              </IonCol>
            </IonRow>
          </IonFooter>
        </IonGrid>
      </IonPopover>
      <IonLoading
        cssClass="my-custom-loading-class"
        isOpen={isLoading}
        message={"Please wait..."}
      />
      <IonPopover
        isOpen={showPopover.open}
        cssClass="deletetargetpopoverclass"
        event={showPopover.event}
        onDidDismiss={(e) => setShowPopover({ open: false, event: undefined })}
        mode="md"
      >
        <IonCard>
          {
            <div>
              <IonGrid class="popoverGridClass">
                <IonRow
                  style={{
                    color: "#828282",
                    justifyContent: "center",
                    fontSize: 28,
                  }}
                  class="ion-text-center ion-margin-top ion-margin-bottom"
                >
                  Remove top target?
                </IonRow>
                <IonRow
                  style={{
                    color: "#828282",
                    justifyContent: "center",
                    fontSize: 14,
                  }}
                  class="ion-text-center ion-margin-bottom"
                >
                  Are you sure you want to remove as top target?
                </IonRow>
                <IonRow
                  class="ion-text-center ion-margin-bottom ion-margin-top"
                  style={{
                    color: "#B99855",
                    marginTop: "30px",
                    borderTop: "1px solid rgba(34,34,34,0.5)",
                    cursor: "pointer",
                    justifyContent: "center",
                  }}
                >
                  <IonCol
                    style={{ borderRight: "1px solid rgba(34,34,34,0.5)" }}
                  >
                    <IonButton
                      onClick={() => {
                        deletetargetFunc();
                      }}
                      class="ConfirmationButton"
                      fill="clear"
                    >
                      Yes
                    </IonButton>
                  </IonCol>
                  <IonCol>
                    <IonButton
                      onClick={() => doNothing()}
                      class="ConfirmationButton"
                      fill="clear"
                    >
                      No
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          }
        </IonCard>
      </IonPopover>

      <IonList style={{ background: "none" }}>
        {TargetData && TargetData.length > 0 && renderComp ? (
          <IonReorderGroup disabled={false} onIonItemReorder={doReorder}>
            {TargetData.map((target, i) => (
              <IonItem
                key={i}
                lines="none"
                style={{ "--padding-start": "0px" }}
              >
                <IonBadge
                  className="TargetBadge"
                  font-weight="bolder"
                  slot="start"
                >
                  {i + 1}
                </IonBadge>
                <IonItem
                  className="TargetIonItem"
                  style={styles.item}
                  lines="none"
                >
                  <IonReorder>
                    <IonIcon
                      icon={menuOutline}
                      style={styles.reordericon}
                      slot="start"
                    ></IonIcon>
                  </IonReorder>

                  {width > 576 ? (
                    <>
                      <IonAvatar className="ion-margin-start TargetAvatar">
                        <img src={target.avatarPath} alt="" />
                        {/* <img src="https://randomuser.me/api/portraits/men/34.jpg" alt="" /> */}
                      </IonAvatar>
                      <IonLabel style={styles.label}>
                        <IonRow>
                          <IonText
                            class="ion-margin-start"
                            style={styles.title}
                            onClick={() => {
                             routetoProfile(target.id);
                           }}
                          >
                            {target.FullName}
                          </IonText>
                          <IonIcon
                            style={styles.period}
                            icon={ellipse}
                          ></IonIcon>
                          <IonText style={styles.desc}>
                            {target.ArabicName}
                          </IonText>
                        </IonRow>
                      </IonLabel>
                      <IonButton
                        custom-rank={target.rank}
                        custom-id={target.id}
                        style={styles.removebutton}
                        fill="clear"
                        mode="ios"
                        onClick={(event) => {
                          setaddremconf(false);
                          setShowPopover({ open: true, event: undefined });
                          setdeleteTarget(
                            (event.target as HTMLInputElement).getAttribute(
                              "custom-id"
                            )
                          );
                        }}
                      >
                        Remove
                      </IonButton>
                    </>
                  ) : (
                    <>
                      <IonRow>
                        <IonCol style={{ alignSelf: "center" }} size-xs="2">
                          <IonAvatar className="ion-margin-start TargetAvatar">
                            <img src={target.avatarPath} alt="" />
                            {/* <img src="https://randomuser.me/api/portraits/men/34.jpg" alt="" /> */}
                          </IonAvatar>
                        </IonCol>
                        <IonCol size-xs="10">
                          <IonLabel>
                            <IonRow>
                              <IonCol size-xs="12">
                                <IonText
                                   onClick={() => {
                                    routetoProfile(target.id);
                                  }}
                                  class="mobile-toptarget-header"
                                  style={styles.title}
                                >
                                  {target.FullName}
                                </IonText>
                              </IonCol>
                              {/* <IonIcon
                                  style={styles.period}
                                  icon={ellipse}
                                ></IonIcon> */}
                              <IonCol size-xs="12">
                                <IonText style={styles.desc}>
                                  {target.ArabicName}
                                </IonText>
                              </IonCol>
                            </IonRow>
                          </IonLabel>
                          <IonRow>
                            <IonCol size-xs="10">
                              <IonButton
                                class="mobile-toptarget-btn"
                                custom-rank={target.rank}
                                custom-id={target.id}
                                style={styles.removebutton}
                                fill="clear"
                                mode="ios"
                                onClick={(event) => {
                                  setaddremconf(false);
                                  setShowPopover({
                                    open: true,
                                    event: undefined,
                                  });
                                  setdeleteTarget(
                                    (event.target as HTMLInputElement).getAttribute(
                                      "custom-id"
                                    )
                                  );
                                }}
                              >
                                Remove
                              </IonButton>
                            </IonCol>
                          </IonRow>
                        </IonCol>
                      </IonRow>
                    </>
                  )}

                  {/* <IonButton custom-rank={target.rank}  custom-id={target.id}  style={styles.removebutton} fill="clear" mode="ios" onClick={(e) => setShowPopover({open: true, event: e.nativeEvent})}>Remove</IonButton> */}
                </IonItem>
              </IonItem>
            ))}
          </IonReorderGroup>
        ) : renderComp ? (
          <IonGrid
            onClick={() => {
              triggerSearch();
            }}
          >
            <IonRow>
              <IonIcon
                icon={personstar}
                color="white"
                size="large"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: 128,
                  height: 128,
                }}
              />
            </IonRow>
            <IonRow class="nulltargetrow2">Your Target List is empty</IonRow>
            <IonRow class="nulltargetrow3">
              <IonText color="greyscale">
                You have not added any prospects as a top target yet
              </IonText>
            </IonRow>
          </IonGrid>
        ) : null}
        {TargetData && TargetData.length > 0 && renderComp ? (
          <IonItem
            style={{ "--padding-start": "0px" }}
            lines="none"
            border-style="dotted"
          >
            <IonBadge className="AddTargetBadge" slot="start">
              {TargetData.length + 1}
            </IonBadge>
            <IonItem
              onClick={() => {
                if (TargetData.length + 1 === 26) {
                  setshow25Popover({ open: true, event: undefined });
                } else {
                  triggerSearch();
                }
              }}
              className="AddTargetIonItem"
              style={styles.item2}
              border-style="dashed"
              lines="none"
            >
              {/* <IonRow> */}
              <IonCol style={styles.addcol}>
                <IonIcon style={styles.addicon} icon={addOutline}></IonIcon>
              </IonCol>
              {/* </IonRow> */}
            </IonItem>
          </IonItem>
        ) : null}
      </IonList>
    </IonGrid>
  );
};

export default TopTargets;
