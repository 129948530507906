import React, { useEffect, useState } from "react";
import { IonRow, IonText, IonSlide, IonSlides } from "@ionic/react";
import "./FormSegments.css";
import useWindowDimensions from "src/components/useWindowDimensions";
const styles = {
  row: {
    marginTop: 24,
    marginLeft: 32,
    marginRight: 32,
    position: "relative",
    // marginBottom: 24
  },
  buttons: {
    marginRight: 24,
    paddingBottom: 12,
    cursor: "pointer",
    borderBottom: "4px solid transparent",
    transition: "border 200ms linear",
    color: "#828282",
    fontWeight: "400",
  },
};

const FormSegments: React.FC<any> = ({ actSeg }) => {
  const [selected, setSelected] = useState<string>("Basic Info");

  const retActiveClass = (button) => {
    return button === selected ? "SegActive" : "";
  };
  // const handleClick = (button) => {
  //     setSelected(button.value)
  //     selectedValue(button.value)
  // }

  useEffect(() => {
    let temp = "Basic Info";
    actSeg === 1
      ? (temp = "Basic Info")
      : actSeg === 2
      ? (temp = "Contact Info")
      : actSeg === 3
      ? (temp = "Financial Info")
      : (temp = "Other Info");
    setSelected(temp);
  }, [actSeg]);

  const buttons = [
    "Basic Info",
    "Contact Info",
    "Financial Info",
    "Other Info",
  ];
  const { height, width } = useWindowDimensions();
  const slideOpts = {
    initialSlide: 0,
    speed: 400,
    width: 105,
    freeMode: true,
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    },
    //spaceBetween: 2,
  };
  return (
    <IonRow className="roww margin-set padding-unset" style={styles.row}>
      {width > 576
        ? buttons.map((button) => (
            <IonText
              key={button}
              style={styles.buttons}
              className={retActiveClass(button)}
            >
              {button}
            </IonText>
          ))
        : null}
    </IonRow>
  );
};

export default FormSegments;
