import api from "src/api";
import { Plugins } from "@capacitor/core";
const { Storage } = Plugins;


const getTeams = async () => {
    const ret: any = await Storage.get({ key: "userId" });
    const temp = JSON.parse(ret.value);
    return temp && temp.teams ? temp.teams : null;
  };


  export const filterCheckboxes:() => Promise<any> = async() => {
  
    let teamCntNameArr:any = [];      
    let teamCntIdArr:any = [];
    let filterCheckboxes_obj:any = [
        // {
        //     id: 1,
        //     label: "Owner",
        //     propName: "owner",
        //     options: [],
        //     ownerids:[]
        // },
        {
            id: 2,
            label: "Status",
            propName: "hs_lead_status",
            options: [],
        },
        {
            id: 3,
            label: "Source",
            propName: "contact_type",
            options: [],
        },
        {
            id: 4,
            label: "Persona",
            propName: "segment",
            options: [
                // "Inheritor",
                // "Institution",
                // "Merchant",
                // "Retiree",
                // "Royal",
                // "Real Estate",
            ],
        },
        {
            id: 5,
            label: "Region",
            propName: "country",
            options: [
                "Saudi Arabia",
                "Bahrain",
                "Kuwait",
                "United Arab Emirates",
                "United States",
                "United Kingdom",
            ],
        },
        {
            id: 5,
            label: "Wealth Range",
            propName: "expected_wealth_range",
            options: [
                // "1-10m",
                // "10m-30m",
                // "30m-100m",
                // "100m-300m",
                // "300m-500m",
                // "500m-1b",
                // "1b+",
            ],
        },
    ]  

  return api.getOwnersAPI().then(resp => {
    //console.log(resp.results);
    let ownerlist = resp.results;
    // getTeams().then((rmteams) => {  
    //     if(rmteams)
    //     {
    //         let teamlength:any = rmteams.length;     
             
    //         //console.log(rmteams);
    //     // for(let i=0; i<teamlength ; i++)
    //     // {
    //     //     ownerlist.teams
    //     // }
    //         let ownertemparr = ownerlist.filter(owner => {
    //             let retval = false;
    //             for(const team of owner.teams)
    //             {
    //                 for(const rmteam of rmteams)
    //                 {
    //                     if(rmteam.id == team.id )
    //                     {
    //                         retval = true;
    //                         break;                            
    //                     }
    //                 }
    //             }
    //             return retval;                             
    //         })
    //        // console.log(ownertemparr)   


    //         ownertemparr.forEach(element => {
    //             teamCntIdArr.push(element);
    //             teamCntNameArr.push(element.firstName);
    //         });
          
    //         filterCheckboxes_obj[0].options = teamCntNameArr;
    //         filterCheckboxes_obj[0].ownerids = teamCntIdArr;
            
    //     }        
    // })
//console.log(filterCheckboxes_obj);
    return filterCheckboxes_obj
    });
}

//export const filterCheckboxes = filterCheckboxes_obj