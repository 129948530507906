import { IonIcon, IonRow, IonCol } from "@ionic/react";
import { search } from "ionicons/icons";
import React, {
  ChangeEventHandler,
  KeyboardEventHandler,
  RefObject,
} from "react";

type SearchBoxProps = {
  handleKey: KeyboardEventHandler<HTMLInputElement>;
  inpRef: RefObject<HTMLInputElement> | undefined;
  isActive: Boolean;
};
export default function SearchBox({
  handleKey,
  inpRef,
  isActive,
}: SearchBoxProps) {
  const styles = {
    container: {
      background: "#222222",
      borderRadius: 5,
    },
    searchIcon: {
      display: "inline-block",
      padding: "10px 5px 10px 10px",
    },
    searchInput: {
      padding: 10,
      background: "transparent",
      border: "none",
      width: "100%",
      outline: "none",
    },
  };
  return (
    <>
      <IonRow style={styles.container}>
        <span style={styles.searchIcon}>
          <IonIcon
            color="white"
            icon={search}
            onClick={() => {
              inpRef?.current?.focus();
            }}
            size="small"
            className="trig-srch"
          ></IonIcon>
        </span>
        <IonCol style={{ padding: 0 }}>
          <input
            style={styles.searchInput}
            ref={inpRef}
            type="text"
            onKeyUp={handleKey}
            placeholder={`Type here to search any ${
              isActive ? "client" : "prospect"
            }`}
          />
        </IonCol>
      </IonRow>
    </>
  );
}
