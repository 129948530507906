import { Plugins } from "@capacitor/core";
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics';
import { Geolocation } from '@ionic-native/geolocation';
import api from "src/api";
import moment from 'moment';
import CryptoJS from 'crypto-js';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio';

const { Storage } = Plugins
const { Device } = Plugins;

const decrypt = (decryptVlaue) => {
    let passphrase = "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9"
    const bytes = CryptoJS.AES.decrypt(decryptVlaue, passphrase);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  };

const getUserEmail = async () => {
    const ret: any = await Storage.get({ key: "userId" })
    const temp = JSON.parse(decrypt(ret.value))
    return temp && temp.email ? temp.email : null
}

const getUserId = async () => {
    const ret: any = await Storage.get({ key: "userId" });
    return JSON.parse(decrypt(ret.value)).id;
  };
const getLocation = async () => {           
    const position = await Geolocation.getCurrentPosition();
    //console.log(Geolocation);
    //setPosition(position);  
    return position      
}
function calltoUpdateLocation()
{                
    Device.getInfo().then(deviceInfo => {  
        getUserId().then(userId => {
    getUserEmail().then(useremail => {  
        //console.log(useremail); 
        if(deviceInfo.platform == "web")
            {
                window.globalThis.firebase.analytics().setUserId(useremail);
                window.globalThis.firebase.analytics().logEvent('select_content', {
                user_name: useremail,
                content_type: 'LoggedIn',
                item_id: useremail,
                device_id:deviceInfo?deviceInfo.uuid:"",
                platform:deviceInfo?deviceInfo.platform:"",
                device_model:deviceInfo?deviceInfo.model:"",
                device_type:deviceInfo?deviceInfo.osVersion:"",
                device_os:deviceInfo?deviceInfo.operatingSystem:"",
                });
            }
        else
            {
                FirebaseAnalytics.setUserId(useremail);
                FirebaseAnalytics.logEvent("select_content", {user_name: useremail});
                FirebaseAnalytics.logEvent("select_content", {content_type: 'LoggedIn'});
                FirebaseAnalytics.logEvent("select_content", {item_id: useremail});
                FirebaseAnalytics.logEvent("select_content", {device_id: deviceInfo?deviceInfo.uuid:""});
                FirebaseAnalytics.logEvent("select_content", {platform: deviceInfo?deviceInfo.platform:""});
                FirebaseAnalytics.logEvent("select_content", {device_model: deviceInfo?deviceInfo.model:""});
                FirebaseAnalytics.logEvent("select_content", {device_type:deviceInfo?deviceInfo.osVersion:""});
                FirebaseAnalytics.logEvent("select_content", {device_os:deviceInfo?deviceInfo.operatingSystem:""});
            }
        
        getLocation().then(loc=>
        {
            //console.log(loc.coords);
            let newdate = new Date();
            let currDate  = moment(newdate).utc().format("x");
            //console.log(currDate);
            let loction:any = "";
            //if(loc && loc.coords)
            loction = loc && loc.coords ? loc.coords.latitude + "," + loc.coords.longitude:"";
            let payloads: any = {
                "lastLogin": currDate,
                "rmEmail": useremail,
                "location":loction
            }
            api.updatelocation(payloads).then((resp) => {
                //console.log(resp);
                // api.getlocation(useremail).then(resp => {
                //    // console.log(resp);
                // })
            })
        }).catch((e) => {
            //console.log(e.message);
            if(e.message == "User denied Geolocation")
            {
                let newdate = new Date();
                let currDate  = moment(newdate).utc().format("x");                
                let payloads: any = {
                    "lastLogin": currDate,
                    "rmEmail": useremail,
                    "location":""
                }
                api.updatelocation(payloads).then((resp) => {                    
                    // api.getlocation(useremail).then(resp => {
                    // // console.log(resp);
                    // })
                })
            }
        })             
    })  
})
    })      
}
export const registerBioMetricAuth: () => Promise<any> = async () =>  {

return FingerprintAIO.isAvailable()
    .then((result: any) => {
        // console.log("Fingerprint Available Result:",result);

        return getUserEmail().then(
            (userEmail) =>{
                FingerprintAIO.registerBiometricSecret({
                    description: "Register biometric to use RM App offline",
                    secret: userEmail,
                    invalidateOnEnrollment: true,
                    disableBackup: false, // always disabled on Android
                    }
                )
                .then((result: any) => {
                    // console.log("Fingerprint registerBiometricSecret Result:",result)
                    
                })
                .catch((error: any) => console.log("Fingerprint registerBiometricSecret error:",error));
            }
        )
        .catch((error:any) => console.log("error retriving UserID"))
           

    })
    .catch((error: any) => console.log("Fingerprint Available error:", JSON.stringify(error)));

}


export const loginWithBioMetricAuth: () => Promise<any> = async () =>  {

    return FingerprintAIO.isAvailable()
        .then((result: any) => {
            // console.log("Fingerprint Available Result:",result);
    
            return FingerprintAIO.loadBiometricSecret({
                        description: "Validate biometric to use RM App",
                        disableBackup: false, // always disabled on Android
                        }
                    )
                    .then((result: any) => {
                        // console.log("Fingerprint loadBiometricSecret Result:",result);
                        if(result.message && (result.message === FingerprintAIO.BIOMETRIC_DISMISSED || result.message === FingerprintAIO.BIOMETRIC_AUTHENTICATION_FAILED )){
                            return false;
                        }
                        else{
                            return true;
                        }
                        
                    })
                    .catch((error: any) => console.log("Fingerprint loadBiometricSecret error:",JSON.stringify(error)));

        })
        .catch((error: any) => console.log("Fingerprint Available error:", JSON.stringify(error)));
    
    }

    export const showBioMetricAuth: () => Promise<any> = async () =>  {

        return FingerprintAIO.isAvailable()
            .then((result: any) => {
                // console.log("Fingerprint Available Result:",result);
        
                return FingerprintAIO.show({
                            description: "Authenticate to use RM App",
                            disableBackup: false, // always disabled on Android
                            }
                        )
                        .then((result: any) => {
                            // console.log("Fingerprint show Result:",result);
                            if(result.message && (result.message === FingerprintAIO.BIOMETRIC_DISMISSED || result.message === FingerprintAIO.BIOMETRIC_AUTHENTICATION_FAILED )){
                                return false;
                            }
                            else{
                                return true;
                            }
                            
                        })
                        .catch((error: any) => console.log("Fingerprint show error:",JSON.stringify(error)));
    
            })
            .catch((error: any) => console.log("Fingerprint Available error:", JSON.stringify(error)));
        
        }
