import React, { RefObject, useEffect } from 'react';
import * as d3 from 'd3';
import datas from'./datas';
const PieChart : React.FC<any> =  (
      {
      outerRadius=250,
      innerRadius=0,}) =>{

      const refA: RefObject<SVGSVGElement> = React.createRef<SVGSVGElement>()
  const margin = {
    top: 100, right: 100, bottom: 100, left: 100,
  };
  const width = 2* outerRadius + margin.left + margin.right;
  const height = 2 * outerRadius + margin.top + margin.bottom;
  const colorScale = (i: number) => {
  switch (i) {
    case 0:
      return "#5DA683"
    case 1:
      return "#6D8A96"
    case 2:
      return "#B99855"  
    default:
      return "#B99855"
  }
}

  // const colorScale = d3     
  //   .scaleThreshold()   
  //   // .interpolator(d3.interpolateCool)      
  //   .domain([0 ,1, 2])
  //   .range(["#5DA683", "#6D8A96", "#B99855"]);

// const colorScale = d3.scaleOrdinal().domain(datas).range(["#5DA683", "#6D8A96", "#B99855"])
  useEffect(() => {
    drawChart();
  }, []);

  function drawChart() {
    // Remove the old svg
    // const svg = d3.select(refA.current);
    d3.select(refA.current)
      .select('svg')
      .remove();

    // Create new svg
    const svg = d3
      .select(refA.current)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`);
      const legend = d3
      // .select("body").append("svg")
      .select(refA.current)
      // .attr("class", "legend")
      // .selectAll('text')
      .selectAll("g")
      .data(datas)
      // .enter().append("g")
      .attr("transform", function(d, i) { return "translate(-180," + (i) * 20 + ")"; });
  legend.append("circle")
      .attr("cx",200)
      .attr("cy",130)
      .attr("r", 6)
      .style("fill", function(d, i) {
  return colorScale(i);
});

  legend.append("text")
      .attr("x", 215)
      .attr("y", 130)
      .attr("dy", ".35em")
      // .attr("stroke", "#fff")
      .style('fill', '#ffffff')
      .text(function (d:any) {
        return d.label ;
    })

    const arcGenerator:any = d3
      .arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius);

    const pieGenerator = d3
      .pie()
      .padAngle(0)
      .value((d:any) => d.value );

    const arc = svg
      .selectAll()
      .data(pieGenerator(datas))
      .enter();

    // Append arcs
    arc
      .append('path')
      .attr('d', arcGenerator)
      .style('fill', (d, i) => colorScale(i))
      .style('stroke', '#ffffff')
      .style("stroke-width", "1px");
      // .style('stroke-width', 0);
      // .attr("stroke", "#fff")

    // Append text labels
    arc
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .attr("fill", "white")
      // .attr("stroke", "#fff")
      // .text((d:any) => d.data.label,)
      .text(function (d:any) {
        return d.data.label +" "+ d.value + "%";
    })
      .style('fill', '#ffffff')
      // .style('fill', (_, i) => colorScale(datas.length - i))
      .attr('transform', (d:any) => {
        const [x, y] = arcGenerator.centroid(d);
        return `translate(${x}, ${y})`;
      });
  }    
  return(
    <React.Fragment>
      <div>
      <svg
      id="chart"
      style={{"width":"400%","height":"400%"}}
       ref={refA}>
<g></g>
<g></g>
<g></g>

       </svg>
        </div>
    </React.Fragment>
  )
}

export default PieChart;