import {
  IonCard,
  IonRow,
  IonCol,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonToast,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import moment from "moment";
import React, { useState } from "react";
import { useHistory } from "react-router";

type InvestmentCardProps = {
  imgSrc: string;
  subtitle: string;
  title: string;
  dealType: string;
  routerPath: string;
};
export default function DealCard({
  imgSrc,
  subtitle,
  title,
  dealType,
  routerPath,
}: InvestmentCardProps) {
  const [showToast, setShowToast] = useState(false);
  const router = useHistory();
  const style = {
    cardRow: {
      alignItems: "center",
      justifyContent: "space-between",
      padding: "16px",
    },
    dealType: {
      fontWeight: 500,
      fontSize: 12,
      lineHeight: "12px",
      color: "#fff",
      backgroundColor: dealType == "deal" ? "#4A3D22" : "#526369",
      width: "fit-content",
      padding: "4px 8px",
      borderRadius: 4,
      textTransform: "capitalize",
    },
    ctaButton: {
      textDecoration: "none",
      fontWeight: 400,
      fontSize: 14,
      lineHeight: "120%",
      color: "#B99855",
      cursor: "pointer",
    },
  };
  return (
    <IonCard style={{ marginLeft: 0, background: "#1A1A1A" }}>
      {/* <img alt="Silhouette of mountains" src={imgSrc} className="heroImage" /> */}
      <IonRow style={style.cardRow}>
        <IonCol style={{ flex: "1 0 0%", overflow: "hidden" }}>
          <IonCardHeader style={{ padding: 0 }}>
            <IonCardTitle className="InvcardTitle">{title}</IonCardTitle>
            <IonCardSubtitle style={style.dealType}>{dealType}</IonCardSubtitle>
          </IonCardHeader>
        </IonCol>
        <IonCol size="auto">
          <span
            onClick={() => {
              router.push(routerPath);
            }}
            style={style.ctaButton}
          >
            View details
          </span>
        </IonCol>
      </IonRow>
    </IonCard>
  );
}
