import React,{useEffect,useState} from "react";
import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonPage,
  IonRow,
  IonText,
  IonToolbar,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import { Plugins } from "@capacitor/core";
import { chevronBack,chevronForward } from "ionicons/icons";
import CryptoJS from 'crypto-js';
const { Storage } = Plugins;
// import "./Popup.css"

const styles = {
  toolbar: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
    height: 75,
  },
  addrembtn: {
    marginRight: 10,
    "--border-radius": "2px",
    textTransform: "none",
    width: 120,
  },
  avatar: {
    width: 56,
    height: 56,
  },
  item: {
    "--background": "#222222",
    marginBottom: 2,
    borderRadius: 8,
  },
  avatarCol:{
    display: "flex",
    justifyContent: "center"
  }
};

const Settings: React.FC<any> = () => {

  const hist = useHistory();

  const setObject = async () => {
    await Storage.clear()
}

const decrypt = (decryptVlaue) => {
  let passphrase = "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9"
  const bytes = CryptoJS.AES.decrypt(decryptVlaue, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};

  const getUserId = async () => {
    const ret: any = await Storage.get({ key: "userId" })
    const temp = JSON.parse(decrypt(ret.value))
   return temp
}
 const [name, setName] = useState<string>("")
useEffect(() => {

getUserId().then((resp) =>{
    let fname = resp && resp.fName ? resp.fName : ""
    let lname = resp && resp.lName ? resp.lName : ""
    let fullName = fname + " " + lname
    setName(fullName)
}

)

},[])
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode="md" style={styles.toolbar}>
          <IonButtons slot="start">
            <IonButton
              style={{ textTransform: "none" }}
              color="white"
              routerLink="/dashboard"
            >
              <IonIcon icon={chevronBack}></IonIcon>
              Back
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRow class="margin-set m-marginleft-12" style={{ marginTop: 24, marginLeft: 32 }}>
          <IonCol sizeSm="2" sizeLg="2">
          <span className="to-do" style={{ fontWeight: 300, fontSize: 28, color:"GrayText", whiteSpace:"nowrap" }}>
              Settings
            </span>
          </IonCol>
          <IonCol sizeSm="8" sizeLg="8"></IonCol>
          <IonCol sizeSm="2" sizeLg="2">
            <IonButton
              fill="solid"
              style={styles.addrembtn}
              color="greyscaleBtn"
              mode="md"
              onClick={() => {
                setObject().then(o => {
                    hist.push('/login')
                })
            }}
            >
              Log Out
            </IonButton>
          </IonCol>
        </IonRow>
        <IonRow class="margin-set">
          <IonCol style={styles.avatarCol}>
            <IonAvatar style={styles.avatar}>
              <img
                src={
                  // selcontObj && selcontObj.properties && selcontObj.properties.hs_avatar_filemanager_key ?
                  //     `https://cdn2.hubspot.net/${selcontObj.properties.hs_avatar_filemanager_key}` :
                  //     "/assets/empty-avatar.svg"
                  "/assets/empty-avatar.svg"
                }
                alt=""
              />
            </IonAvatar>
          </IonCol>
        </IonRow>
        <IonRow class="margin-set">
          <IonCol class="ion-text-center">
            <IonText style={{ padding: 35, fontWeight: 400, fontSize: 14 }}>
            {name}
            </IonText>
          </IonCol>
        </IonRow>
        {/* <IonRow>
           <IonCol class="ion-text-center">
              <IonText style={{ padding: 35, fontWeight: 700, fontSize: 14 }}>
              Rajarshi Chakraboty Arabic Name
              </IonText>
          </IonCol>
        </IonRow> */}
        {/* <IonRow style={{height: 80}}></IonRow>
        <IonRow style={{height: 80}}>
          <IonItem
            style={{
              "--background": "#222222",
              "--min-height": "80px",
              marginBottom: 2,
              borderRadius: 8,
              width: "100%",
              marginRight: 20,
              marginLeft: 32,
            }}
            lines="none"
          >
            <IonText style={{ fontWeight: 400, fontSize: 18 }}
              color="greyscale">Biometrics: Enable Face ID</IonText>
            <IonToggle slot="end" style={{"--background-checked":"green" }}></IonToggle>
          </IonItem>
        </IonRow> */}
        <IonRow style={{height: 80}} class="margin-set"></IonRow>
        <IonRow style={{height: 80}} class="margin-set">
          <IonItem
            style={{
              "--background": "#222222",
              "--min-height": "80px",
              marginBottom: 2,
              borderRadius: 8,
              width: "100%",
              marginRight: 20,
              marginLeft: 32,
            }}
            lines="none"
          >
            <IonText style={{ fontWeight: 400, fontSize: 18 }}
              color="greyscale">Terms & Conditions</IonText>
            <IonButton
            fill="clear"
            mode="ios"
            slot="end"
          >
            <IonIcon color="gold" size="large" icon={chevronForward} />
          </IonButton>
          </IonItem>
        </IonRow>
        <IonRow style={{height: 80}} class="margin-set">
          <IonItem
            style={{
              "--background": "#222222",
              "--min-height": "80px",
              marginBottom: 2,
              borderRadius: 8,
              width: "100%",
              marginRight: 20,
              marginLeft: 32,
            }}
            lines="none"
          >
            <IonText style={{ fontWeight: 400, fontSize: 18 }}
              color="greyscale">Disclaimer</IonText>
            <IonButton
            fill="clear"
            mode="ios"
            slot="end"
          >
            <IonIcon color="gold" size="large" icon={chevronForward} />
          </IonButton>
          </IonItem>
        </IonRow>
        <IonRow style={{height: 80}} class="margin-set">
          <IonItem
            style={{
              "--background": "#222222",
              "--min-height": "80px",
              marginBottom: 2,
              borderRadius: 8,
              width: "100%",
              marginRight: 20,
              marginLeft: 32,
            }}
            lines="none"
          >
            <IonText style={{ fontWeight: 400, fontSize: 18 }}
              color="greyscale">Privacy Policy</IonText>
            <IonButton
            fill="clear"
            mode="ios"
            slot="end"
          >
            <IonIcon color="gold" size="large" icon={chevronForward} />
          </IonButton>
          </IonItem>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};
export default Settings;
