import { Plugins } from "@capacitor/core";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";
import CryptoJS from "crypto-js";

const { Storage, Device } = Plugins;

const decrypt = (decryptVlaue) => {
  let passphrase =
    "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9";
  const bytes = CryptoJS.AES.decrypt(decryptVlaue, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};
const getUserEmail = async () => {
  const ret: any = await Storage.get({ key: "userId" });
  const temp = JSON.parse(decrypt(ret?.value));
  return temp && temp.email ? temp.email : null;
};

type fireBaseLogProps = {
  title: string;
  time_spent: any;
};

export const fireBaseLog = ({ title, time_spent }: fireBaseLogProps) => {
  Device.getInfo().then((deviceInfo) => {
    getUserEmail().then((useremail) => {
      if (deviceInfo.platform == "web") {
        window.globalThis.firebase.analytics().setUserId(useremail);
        window.globalThis.firebase.analytics().logEvent("select_content", {
          user_name: useremail,
          content_type: title,
          item_id: time_spent,
          device_id: deviceInfo ? deviceInfo.uuid : "",
          platform: deviceInfo ? deviceInfo.platform : "",
          device_model: deviceInfo ? deviceInfo.model : "",
          device_type: deviceInfo ? deviceInfo.osVersion : "",
          device_os: deviceInfo ? deviceInfo.operatingSystem : "",
        });
      } else {
        FirebaseAnalytics.setUserId(useremail);
        FirebaseAnalytics.logEvent("select_content", {
          user_name: useremail,
        });
        FirebaseAnalytics.logEvent("select_content", {
          content_type: title,
        });
        FirebaseAnalytics.logEvent("select_content", {
          item_name: time_spent,
        });
        FirebaseAnalytics.logEvent("select_content", {
          device_id: deviceInfo ? deviceInfo.uuid : "",
        });
        FirebaseAnalytics.logEvent("select_content", {
          platform: deviceInfo ? deviceInfo.platform : "",
        });
        FirebaseAnalytics.logEvent("select_content", {
          device_model: deviceInfo ? deviceInfo.model : "",
        });
        FirebaseAnalytics.logEvent("select_content", {
          device_type: deviceInfo ? deviceInfo.osVersion : "",
        });
        FirebaseAnalytics.logEvent("select_content", {
          device_os: deviceInfo ? deviceInfo.operatingSystem : "",
        });
      }
    });
  });
};
