import React from "react";
import { IonPopover, IonList, IonItem } from "@ionic/react";
import { useHistory } from "react-router-dom";
import "./Popup.css";
const styles = {
  list: {
    padding: 0,
  },
  item: {
    "--background:": "#1a1a1a",
  },
};

interface MenuProps {
  open: boolean;
  event: Event | undefined;
  setShowPopover: Function;
  contactId: any;
  contactObj?: any;
}

const NewMenu: React.FC<MenuProps> = ({
  open,
  event,
  setShowPopover,
  contactId,
  contactObj,
}) => {
  const history = useHistory();
  
  return (
    <IonPopover
      isOpen={open}
      showBackdrop={true}
      cssClass="popover-class new-popover-class"
      event={event}
      onDidDismiss={(e) => setShowPopover({ open: false, event: undefined })}
      mode="ios"
    >
      <IonList style={styles.list}>
        <IonItem
          lines="none"
          style={styles.item}
          button
          //   routerLink={`/note/create/${contactId}`}
          onClick={() => {
            history.push(`/note/create`, {
              source: contactObj.properties.hs_lifecyclestage_customer_date ? "Client" : "Prospect",
              contactId:contactId
            });
          }}
          detail={false}
        >
          New Meeting Note
        </IonItem>
        {/* <IonItem lines="full" style={styles.item}>New To-Do</IonItem> */}
        {/* <IonItem lines="full" style={styles.item}>New Document</IonItem> */}
        <IonItem
          lines="none"
          style={styles.item}
          button
          //   routerLink={`/create/todo/${contactId}`}
          onClick={() => {
            history.push(`/create/todo`, {
              source: contactObj.properties.hs_lifecyclestage_customer_date ? "Client" : "Prospect",
              contactId:contactId
            });
          }}
          detail={false}
        >
          New To-Do
        </IonItem>
        <IonItem
          lines="none"
          style={styles.item}
          button
          //   routerLink={`/association/create/${contactId}`}
          onClick={() => {
            history.push(`/association/create`, {
              source: contactObj.properties.hs_lifecyclestage_customer_date ? "Client" : "Prospect",
              contactId:contactId
            });
          }}
          detail={false}
        >
          New Association
        </IonItem>
        <IonItem
          lines="none"
          style={styles.item}
          button
          // routerLink={`/newevent/${contactId}`}
          onClick={() => {
            history.push(`/newevent/${contactId}`, {
              contactName: `${contactObj.properties.firstname} ${contactObj.properties.lastname}`,
              contId: contactObj.id,
              contEmail: contactObj.properties.email,
              source: contactObj.properties.hs_lifecyclestage_customer_date ? "Client" : "Prospect",
            });
          }}
          detail={false}
        >
          New Scheduled Meeting
        </IonItem>
      </IonList>
    </IonPopover>
  );
};
export default NewMenu;
