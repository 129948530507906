import React, { CSSProperties, useEffect, useState } from 'react'
import { IonGrid, IonIcon, IonRow,IonCol } from '@ionic/react'
import CryptoJS from 'crypto-js';
// import LineChart from './Line'
import './Chart.css'
import { ellipse } from 'ionicons/icons'
import ChartInstance from './ChartInstance'
import personStar from 'src/icons/personstar.svg'
import percent from 'src/icons/percent.svg'
import api from "src/api";
import { Plugins } from "@capacitor/core";
import moment from 'moment'
const { Storage } = Plugins

interface StyleProps {
    rootGrid: CSSProperties;
    colbackL: CSSProperties;
    colbackR: CSSProperties;
    innerGrid: CSSProperties;
    title: CSSProperties;
    dropdownRow: CSSProperties;
    titleRow: CSSProperties;
    iconsize: CSSProperties;
}

const decrypt = (decryptVlaue) => {
    let passphrase = "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9"
   const bytes = CryptoJS.AES.decrypt(decryptVlaue, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
  };

const getUserId = async () => {
    const ret: any = await Storage.get({ key: "userId" })
    const temp = JSON.parse(decrypt(ret.value))
    return temp && temp.id ? temp.id : null
}

const timeDropDown: any = [];
let thisdate = new Date();
let thisyear = thisdate.getFullYear();
let thismonth = thisdate.getMonth();
timeDropDown.push({ val: thisyear - 3 });
timeDropDown.push({ val: thisyear - 2 });
timeDropDown.push({ val: thisyear - 1 });
timeDropDown.push({ val: "Last 30 days" });
timeDropDown.push({ val: "Q1 " + thisyear });
if (thismonth > 2) {
    timeDropDown.push({ val: "Q2 " + thisyear });
}
if (thismonth > 5) {
    timeDropDown.push({ val: "Q3 " + thisyear });
}
if (thismonth > 8) {
    timeDropDown.push({ val: "Q4 " + thisyear });
}
timeDropDown.push({ val: "Year-to-Date" });
timeDropDown.push({ val: "Last 12 Months" });

const styles: StyleProps = {
    rootGrid: {
        marginTop: 24
    },
    colbackL: {
        height: 384,
        // background: "url(/assets/mesh.svg) no-repeat 100px 0px",
        backgroundColor: "#1A1A1A",
        paddingRight: 7.5
    },
    colbackR: {
        height: 384,
        // background: "url(/assets/mesh.svg) no-repeat 100px 0px",
        backgroundColor: "#1A1A1A",
        paddingLeft: 7.5
    },
    innerGrid: {
        position: "relative",
        zIndex: 2
    },
    titleRow: {
        position: "absolute",
        top: 27,
        marginLeft: 40,
        display: "flex",
        flexDirection: "column"
    },
    dropdownRow: {
        position: "absolute",
        top: 11.5,
        marginLeft: 40
    },
    title: {
        fontSize: 18,
        fontWeight: "normal"
    },
    iconsize: {
        height: 24,
        width: 24
    },
}


interface ChartCompProps {

}

const getStartEndDates = (timeframeString: string) => {
    switch (timeframeString) {
        case "Next Year":
            return {
                startDate: `01-JAN-${moment(new Date()).add(1, "years").format("YYYY")}`,
                endDate: `31-DEC-${moment(new Date()).add(1, "years").format("YYYY")}`
            }
        case "Last 12 Months":
            return {
                startDate: `${moment(new Date()).subtract(1, "years").startOf("month").format("DD-MMM-YYYY")}`,
                endDate: `${moment(new Date()).subtract(1, "month").endOf("month").format("DD-MMM-YYYY")}`
            }
        case "Q1 " + moment(new Date()).format("YYYY"):
            return {
                startDate: `01-JAN-${moment(new Date()).format("YYYY")}`,
                endDate: `31-MAR-${moment(new Date()).format("YYYY")}`
            }
        case "Q2 " + moment(new Date()).format("YYYY"):
            return {
                startDate: `01-APR-${moment(new Date()).format("YYYY")}`,
                endDate: `30-JUN-${moment(new Date()).format("YYYY")}`
            }
        case "Q3 " + moment(new Date()).format("YYYY"):
            return {
                startDate: `01-JUL-${moment(new Date()).format("YYYY")}`,
                endDate: `30-SEP-${moment(new Date()).format("YYYY")}`
            }
        case "Q4 " + moment(new Date()).format("YYYY"):
            return {
                startDate: `01-OCT-${moment(new Date()).format("YYYY")}`,
                endDate: `31-DEC-${moment(new Date()).format("YYYY")}`
            }
        case "Last 30 days":
            return {
                startDate: `${moment(new Date()).subtract(31, "days").format("DD-MMM-YYYY")}`,
                endDate: `${moment(new Date()).subtract(1, "days").format("DD-MMM-YYYY")}`,
            }
        case "Year-to-Date":
            return {
                startDate: `01-JAN-${moment(new Date()).format("YYYY")}`,
                endDate: `${moment(new Date()).format("DD-MMM-YYYY")}`,
            }
        case moment(new Date()).subtract(1, "years").format("YYYY"):
            return {
                startDate: `01-JAN-${moment(new Date()).subtract(1, "years").format("YYYY")}`,
                endDate: `31-DEC-${moment(new Date()).subtract(1, "years").format("YYYY")}`,
            }
        case moment(new Date()).subtract(2, "years").format("YYYY"):
            return {
                startDate: `01-JAN-${moment(new Date()).subtract(2, "years").format("YYYY")}`,
                endDate: `31-DEC-${moment(new Date()).subtract(2, "years").format("YYYY")}`,
            }
        case moment(new Date()).subtract(3, "years").format("YYYY"):
            return {
                startDate: `01-JAN-${moment(new Date()).subtract(3, "years").format("YYYY")}`,
                endDate: `31-DEC-${moment(new Date()).subtract(3, "years").format("YYYY")}`,
            }
        default:
            return {
                startDate: `01-JAN-2015`,
                endDate: `31-DEC-${moment(new Date()).format("YYYY")}`,
            }
    }
}

const getFilterBool = (fltStr, monthNum) => {
    switch (fltStr) {
        case "Q1 " + moment(new Date()).format("YYYY"):
            return monthNum === 1 || monthNum === 2 || monthNum === 3 ? true : false
        case "Q2 " + moment(new Date()).format("YYYY"):
            return monthNum === 4 || monthNum === 5 || monthNum === 6 ? true : false
        case "Q3 " + moment(new Date()).format("YYYY"):
            return monthNum === 7 || monthNum === 8 || monthNum === 9 ? true : false
        case "Q4 " + moment(new Date()).format("YYYY"):
            return monthNum === 10 || monthNum === 11 || monthNum === 12 ? true : false
        case "Last 30 days":
            return monthNum === moment(new Date()).subtract(31, "days").get("month") || monthNum === moment(new Date()).subtract(1, "days").get("month") ? true : false
        default:
            return true;
    }
}

const Chart: React.FC<ChartCompProps> = () => {


    const [dProsp, setdProsp] = useState<[number, number][]>([
        [0, 0],
        [1, 0],
        [2, 0],
        [3, 0],
        [4, 0],
        [5, 0],
        [6, 0],
        [7, 0],
        [8, 0],
        [9, 0],
        [10, 0],
        [11, 0],
        [12, 0],
        [13, 0]
    ])

    const [dTarg, setdTarg] = useState<[number, number][]>([
        [0, 0],
        [1, 0],
        [2, 0],
        [3, 0],
        [4, 0],
        [5, 0],
        [6, 0],
        [7, 0],
        [8, 0],
        [9, 0],
        [10, 0],
        [11, 0],
        [12, 0],
        [13, 0]
    ])
    const [dAct, setdAct] = useState<[number, number][]>([
        [0, 0],
        [1, 0],
        [2, 0],
        [3, 0],
        [4, 0],
        [5, 0],
        [6, 0],
        [7, 0],
        [8, 0],
        [9, 0],
        [10, 0],
        [11, 0],
        [12, 0],
        [13, 0]
    ])

    const [cmtTf, setcmtTf] = useState<string>("Year-to-Date")
    const [targActTf, settargActTf] = useState<string>("Year-to-Date")

    const [maxCmt, setMaxCmt] = useState<number>(0)
    
    
    const [maxProsp, setmaxProsp] = useState<number>(0)
    const [prosTrans, setprosTrans] = useState<number>(0)
    const [convRatio, setconvRatio] = useState<number>(0)

    const [render, setrender] = useState<boolean>(false)

    // const [renderCmt, setrenderCmt] = useState<boolean>(false)
    // const [renderConv, setrenderConv] = useState<boolean>(false)

    const setCommitmentTimeframe = (str) => {
        setcmtTf(str)
    }

    const setTargActTimeframe = (str) => {
        settargActTf(str)
    }


    useEffect(() => {
        // setrenderCmt(false)
        getUserId().then(userId => {
            if (userId && userId !== null && userId !== "") {
                const { startDate, endDate } = getStartEndDates(cmtTf.toString())
                api.getCommitmentForecast(parseInt(userId), startDate, endDate).then((resp) => {
                    if (resp.data && resp.data.length > 0) {
                        let tempArrTarg: any = [
                            [0, 0],
                            [1, 0],
                            [2, 0],
                            [3, 0],
                            [4, 0],
                            [5, 0],
                            [6, 0],
                            [7, 0],
                            [8, 0],
                            [9, 0],
                            [10, 0],
                            [11, 0],
                            [12, 0],
                            [13, 0]
                        ]
                        let tempArrAct: any = [
                            [0, 0],
                            [1, 0],
                            [2, 0],
                            [3, 0],
                            [4, 0],
                            [5, 0],
                            [6, 0],
                            [7, 0],
                            [8, 0],
                            [9, 0],
                            [10, 0],
                            [11, 0],
                            [12, 0],
                            [13, 0]
                        ]
                        let tempTotAct: number = 0
                        resp.data.forEach(respobj => {
                            for(const x of tempArrTarg) {
                                if(x[0] === parseInt(respobj.month)) {
                                    x[1] = parseFloat(respobj.targetAmount)
                                }
                            }
                            for(const y of tempArrAct) {
                                if(y[0] === parseInt(respobj.month)) {
                                    y[1] = parseFloat(respobj.actualAmounts)
                                    tempTotAct = tempTotAct + parseFloat(respobj.actualAmounts)
                                }
                            }
                            
                        })
                        setdTarg([])
                        setdAct([])
                        setdTarg(tempArrTarg)
                        setMaxCmt(tempTotAct)
                        setdAct(tempArrAct)
                    }
                    // setrenderCmt(true)
                })
            }
        })
    }, [cmtTf])

    useEffect(() => {
        // setrenderConv(false)
        getUserId().then(userId => {
            const { startDate, endDate } = getStartEndDates(targActTf.toString())
            api.getProspConv(userId, startDate, endDate).then((resp) => {
                if (resp.data && resp.data.length > 0) {
                    let tempArrConv: any = [
                            [0, 0],
                            [1, 0],
                            [2, 0],
                            [3, 0],
                            [4, 0],
                            [5, 0],
                            [6, 0],
                            [7, 0],
                            [8, 0],
                            [9, 0],
                            [10, 0],
                            [11, 0],
                            [12, 0],
                            [13, 0]
                        ]
                        let tempTotAmt: number = 0
                        resp.data.forEach(respobj => {
                            for(const x of tempArrConv) {
                                if(x[0] === parseInt(respobj.month)) {
                                    x[1] = parseInt(respobj.amountPerMonth)
                                    tempTotAmt = tempTotAmt + parseInt(respobj.amountPerMonth);
                                }
                            }
                            
                        })
                        setdProsp([])
                        setdProsp(tempArrConv)
                        setmaxProsp(tempTotAmt)


                        let totalProspects = parseInt(resp.data[0].totalProspects)
                        let totalTransitioned = parseInt(resp.data[0].totalTransitioned)

                        setprosTrans(totalTransitioned)
                        setconvRatio(totalProspects === 0 ? 0 : totalTransitioned/totalProspects)

                }
                // setrenderConv(true)
            })
        })
    }, [targActTf])


    useEffect(() => {
        if(!render) {
            if(dProsp.length > 0 && dTarg.length > 0 && dAct.length > 0) {
                setrender(true)
            } 
        }
    },[dProsp,dTarg,dAct])

    return (
        <IonGrid class = "mr-13 ml-13" style={styles.rootGrid}>
            <IonRow>
                {   true
                        ?
                        <IonCol size-xs="12" size-sm="6" className="SpaceAlinChart" style={{"padding-right":"20px"}}>
                        <ChartInstance
                            timeDropDown={timeDropDown}
                            CardTitle="Prospect Conversions"
                            Chartname="Prospect Conversions"
                            Total={maxProsp}
                            IconsObj={[
                                {
                                    label: `${prosTrans} prospects transitioned`,
                                    icon: <IonIcon icon={personStar} style={{ color: "#BFC5F4", ...styles.iconsize }} />
                                },
                                {
                                    label: `${convRatio.toFixed(1)}% conversion rate`,
                                    icon: <IonIcon icon={percent} style={{ color: "#BFC5F4", ...styles.iconsize }} />
                                },
                            ]}
                            chartData={render && dProsp.length > 0 ? [dProsp]: [[]]}
                            tf={targActTf}
                            setTimeFrame={setTargActTimeframe}
                        /> </IonCol>:
                        null
                }
                {
                    true
                    ?
                    <IonCol size-xs="12" size-sm="6">
                        <ChartInstance
                            timeDropDown={timeDropDown}
                            CardTitle="Total Commitment Forecast"
                            Chartname="Total Commitment Forecast"
                            Total={maxCmt}
                            IconsObj={[
                                {
                                    label: "Target",
                                    icon: <IonIcon icon={ellipse} style={{ color: "#BFC5F4", ...styles.iconsize }} />
                                },
                                {
                                    label: "Actual",
                                    icon: <IonIcon icon={ellipse} style={{ color: "#F7B198", ...styles.iconsize }} />
                                },
                            ]}
                            chartData={
                                render && dTarg.length > 0 && dAct.length > 0 ? [dTarg, dAct] : [[]]
                            }
                            tf={cmtTf}
                            setTimeFrame={setCommitmentTimeframe}
                        /> </IonCol>:
                        null
                }
            </IonRow>
        </IonGrid>
    )
}

export default Chart