import React from "react";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
// import pdf from ""
type PdfViewverProps = {
  url: string;
};
export default function PdfViewer({ url }: PdfViewverProps) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return (
    <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.3.200/pdf.worker.js">
      <Viewer fileUrl={url} plugins={[defaultLayoutPluginInstance]} />
    </Worker>
  );
}
