import React, {useState} from 'react';
import { IonPopover, IonList, IonItem, IonButton, IonCol, IonFooter, IonGrid, IonRow, IonCard } from '@ionic/react';
import "./EditNotePopover.css"
import api from "src/api";
const styles = {
    list: {
        padding: 0
    },
    item: {
        "--background:": "#1a1a1a"
    }
}

interface MenuProps {
    open: boolean;
    event: Event | undefined;
    setShowPopover: Function;
    noteId: string;
    delRefresh: Function;
}


const DeleteConfirm: React.FC<MenuProps> = ({
    open,
    event,
    setShowPopover,
    noteId,
    delRefresh
}) => {

    function doNothing() {
        setShowPopover(
            { open: false, event: undefined }
        );
    }

    function deleteData() {

        api.GetMeetingNoteById(noteId).then((data) => {
            if (data.followupTaskId) {
              api.deleteTask(data.followupTaskId).then((taskdata) => {
                //  console.log(taskdata);
              });
            }
          });
            
          api.deleteMeetingNote(noteId).then((notedata) => {
            // console.log(notedata);
            delRefresh()
            setShowPopover({ open: false, event: undefined });
         });

        }
     return (
        <IonPopover
                isOpen={open}
                cssClass='my-custom-classA popover-class-A'
                event={event}
                onDidDismiss={e => setShowPopover({ open: false, event: undefined })}
                mode="md"
            >
                <IonCard style={{ "margin":0, color:""}}>
                    {
                        <div>
                            <IonGrid class="popoverGridClass_A">
                                <IonRow style={{ color: "#828282", justifyContent: "center",fontWeight:300, fontSize: 28 }} class="ion-text-center ion-margin-top ion-margin-bottom">Remove note?</IonRow>
                                <IonRow style={{ color: "#828282", justifyContent: "center",fontWeight:300, fontSize: 14 }} class="ion-text-center ion-margin-bottom">Are you sure you want to remove this note?</IonRow>
                                <IonRow class="ion-text-center ion-margin-bottom" style={{ color: "#B99855", cursor: "pointer", marginTop:"60px", borderTop:"solid 1px rgba(185, 152, 85,0.2)", justifyContent: "center" }}>
                                    <IonCol style={{borderRight:"solid 1px rgba(185, 152, 85,0.2)"}} >
                                        <IonButton onClick={() => doNothing()} class="ConfirmationButton" fill="clear">No</IonButton>
                                    </IonCol>
                                    <IonCol>
                                        <IonButton onClick={() => deleteData()} class="ConfirmationButton" fill="clear">Yes</IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </div>
                    }
                </IonCard>
            </IonPopover>
    );
}
export default DeleteConfirm