import React, { CSSProperties, RefObject, useEffect, useState } from 'react'
import * as d3 from 'd3'
// import './Line.css'
import './PortfolioLineChart.css'
import { max } from 'd3'

// const width = 500
// const height = 350
const margin = 20

const monthsArr = [
    "Year 1",
    "Year 2",
    "Year 3",
    "Year 4",
    "Year 5"
    
]


interface stylesI {
    root: CSSProperties;
    svg: CSSProperties;
    path: CSSProperties;
    pathA: CSSProperties;
    axis: CSSProperties;
    gridline: CSSProperties;
    axistext: CSSProperties;
    gridlineTemp: CSSProperties;
    area: CSSProperties;
}

const styles: stylesI = {
    root: {
        position: "relative",
        width: "100%",
        height: "100%",
        overflowY: "hidden",
        overflowX: "scroll"
    },
    svg: {
        fill: "#fff",
        fillOpacity: 0.5,
        zIndex: 0,
        position: "absolute",
        left: 96
    },
    path: {
        stroke: "#72758E",
        strokeWidth: 3,
        fill: "none",
        filter: "url(#shadow2)",
    },
    pathA: {
        stroke: "#F7B198",
        strokeWidth: 3,
        fill: "none",
        filter: "url(#shadow2)",
    },
    axis: {
        stroke: "#fff"
    },
    gridline: {
        stroke: "rgb(255, 255, 255, 0.05)"
    },
    gridlineTemp: {
        opacity: 1,
        stroke: "#fff"
    },
    axistext: {
        fill: "#AAAAAA",
        fillOpacity: 0.9,
        fontSize: 12,
        textAnchor: "middle",
        fontWeight: "normal"
    },
    area: {
        fill: "linear-gradient(180deg, rgba(255, 255, 255, 0.2) 25.16%, rgba(255, 255, 255, 0) 143.4%)",
        fillOpacity: 0.2
    }
}


const decimalformalFunc = (Num) => {
    let temp = Num.toString().split(".");
    let retVal = 0;
    if (temp[1]) {
      retVal = Num.toFixed(0);
    } else {
      retVal = Num;
    }
    return retVal;
  };

  const retformatamt = (clsAmt) => {
    let closingamt_disp: number = 0;
    Number.isNaN(clsAmt) || clsAmt === null || clsAmt === ""
      ? (closingamt_disp = 0)
      : (closingamt_disp = parseInt(clsAmt));
    return Math.abs(Number(closingamt_disp)) >= 1.0e9
      ? decimalformalFunc(Math.abs(Number(closingamt_disp)) / 1.0e9) + "b"
      : // Six Zeroes for Millions
      Math.abs(Number(closingamt_disp)) >= 1.0e6
      ? decimalformalFunc(Math.abs(Number(closingamt_disp)) / 1.0e6) + "m"
      : // Three Zeroes for Thousands
      Math.abs(Number(closingamt_disp)) >= 1.0e3
      ? decimalformalFunc(Math.abs(Number(closingamt_disp)) / 1.0e3) + "k"
      : decimalformalFunc(Math.abs(Number(closingamt_disp)));
  };

const retAmtforTicks = (clsAmt) => {
    let closingamt_disp: number = 0;
    (Number.isNaN(clsAmt))|| clsAmt === null || clsAmt === "" ? closingamt_disp = 0 : closingamt_disp = parseInt(clsAmt)
    return Math.abs(Number(closingamt_disp)) >= 1.0e+9

        ? Math.abs(Number(closingamt_disp)) / 1.0e+9 + "b"
        // Six Zeroes for Millions 
        : Math.abs(Number(closingamt_disp)) >= 1.0e+6

            ? Math.abs(Number(closingamt_disp)) / 1.0e+6 + "m"
            // Three Zeroes for Thousands
            : Math.abs(Number(closingamt_disp)) >= 1.0e+3

                ? Math.abs(Number(closingamt_disp)) / 1.0e+3 + "k"

                : Math.abs(Number(closingamt_disp));
}

interface LineChartProps {
  Chartname:string;
    width: number;
    height: number;
    chartData: [number, number, number, number][][][][]
}

const PortfolioBarLineChart: React.FC<LineChartProps> = ({
    Chartname,
    width,
    height,
    chartData
}) => {

    const refA: RefObject<SVGSVGElement> = React.createRef<SVGSVGElement>()


    const h = height - 2 * margin, w = width - 2 * margin

    //number formatter
    const xFormat = d3.format("0.2")
    //x scale
    const x = d3.scaleLinear()
        .domain([1, 12]) //domain: [min,max] of a
        .range([margin, w])

    //y scale

    // const tempforY: Array<number> = chartData[0].map(a => {
    //     return a[1]
    // })
    // let temp2forY: Array<number> = chartData[0].map(a => {
    //     return a[1]
    // })
    // if(chartData[1]) {
    //     temp2forY = chartData[1].map(a => {
    //         return a[1]
    //     })
    // }


    // const y = d3.scaleLinear()
    //     .domain([0, Math.max(...tempforY, ...temp2forY)* 1.2]) // domain [0,max] of b (start from 0)
    //     .range([h, margin])

    


    //line generator: each point is [x(d.a), y(d.b)] where d is a row in data
    // and x, y are scales (e.g. x(10) returns pixel value of 10 scaled by x)
    // const lineA: Function = d3.line()
    //     .x(d => x(d[0]))
    //     .y(d => y(d[1]))
    //     // .curve(d3.curveCatmullRom.alpha(0.5)) //curve line
    //     .curve(d3.curveBasis) //curve line

    //     const veticalLine: Function = d3.line()
    //     .x(d => x(d[0]))
    //     .y(d => y(d[1]))
    //     // .curve(d3.curveCatmullRom.alpha(0.5)) //curve line
    //    // .curve(d3.curveBasis) //curve line

    // const areafill: Function = d3.area()
    //     .x(d => x(d[0]))
    //     .y0(h + 100)
    //     .y1(d => y(d[1]))
    //     // .curve(d3.curveCatmullRom.alpha(0.5)) //curve line
    //     .curve(d3.curveBasis) //curve line

    // const xTicks = x.ticks(12).map((d, i) => (
    //     // x(d) > margin && x(d) < w ?
    //     <g key={i} transform={`translate(${x(d)},${h + margin})`}>
    //         <text style={styles.axistext}>{monthsArr[i]}</text>
    //         <line x1='0' x2='0' y1='0' y2='5' transform="translate(0,-20)" />
    //         {/* <line style={styles.gridline} className='gridline' y1='0' y2={margin - h} x1='0' x2='0' transform="translate(0,-25)" /> */}
    //     </g>
    //     // : null
    // ))

    // const yTicks = y.ticks(4).map((d, i) => (
    //     y(d) > 10 && y(d) < h ?
    //         <g key={i} transform={`translate(${margin + 30},${y(d)})`}>
    //             <text style={styles.axistext} x="-12" y="5">
    //                 {
    //                     // xFormat(d)
    //                     retAmtforTicks(d)
    //                 }
    //                 </text>
    //             {/* <line x1='0' x2='5' y1='0' y2='0' transform="translate(-5,0)" /> */}
    //             {/* <line style={styles.gridline} className='gridline' x1='0' x2={w - margin} y1='0' y2='0' transform="translate(-5,0)" /> */}

    //         </g>
    //         : null
    // ))
    
    // const yGridLines = yG.ticks().map((d, i) => (
    //     <g key={i} transform={`translate(${margin},${y(d)})`}>
    //         <line style={styles.gridline} className='gridline' x1={-2400} x2={2400} y1='0' y2='0' transform="translate(-5,0)" />
    //     </g>
    // ))
    const [yTicks,setyTicks] = useState<any>();
    const [xTicks,setxTicks] = useState<any>();
    const [xGridLines,setxGridLines] = useState<any>();
    const [yGridLines,setyGridLines] = useState<any>();


    useEffect(() => {
       //console.log(chartData)
        let tempArray = chartData[0][0];
        //console.log(tempArray[3] + tempArray[2]);
        const maxValueArr:any = [];
        for(let i=0 ; i<5 ; i++)
        {
            maxValueArr.push(Number(chartData[0][i][2]) + Number(chartData[0][i][3]));
        }
        
        //chartData.forEach((data, i) => {
           
   
    const ActualData = [
        {
          month: "Year 1",
          monthNum: 1,
          income: Number(chartData[0][0][2]),
          year: Number(chartData[0][0][1]),
          capital: Number(chartData[0][0][3])
        },
        {
          month: "Year 2",
          monthNum: 2,
          income: Number(chartData[0][1][2]),
          year: Number(chartData[0][1][1]),
          capital: Number(chartData[0][1][3])
        },
        {
          month: "Year 3",
          monthNum: 3,
          income: Number(chartData[0][2][2]),
          year: Number(chartData[0][2][1]),
          capital: Number(chartData[0][2][3])
        },
        {
          month: "Year 4",
          monthNum: 4,
          income: Number(chartData[0][3][2]),
          year: Number(chartData[0][3][1]),
          capital: Number(chartData[0][3][3])
        },
        {
          month: "Year 5",
          monthNum: 5,
          income: Number(chartData[0][4][2]),
          year: Number(chartData[0][4][1]),
          capital: Number(chartData[0][4][3])
        },
   
      ];
    const svg = d3.select(refA.current);
    //const svgContainer = d3.select('#container');
    
    const margin = 20;
    //const width = 760 - 2 * margin;
    //const height = 460 - 2 * margin;
    const width = 700;
    const height = 384;
    const chart = svg.append('g')
    .attr('transform', `translate(${margin}, ${margin})`);

    // const xScale = d3.scaleBand()
    //     .range([0, width])
    //    // .domain(ActualData.map((s) => s.month))
    //    .domain([0,15])
    //     .padding(0.4)
    const xScale = d3.scaleLinear()
    .domain([0, 5]) //domain: [min,max] of a
    .range([60, 360])        
    // const xScale = 
    // d3.scaleBand().range ([0, width]).padding(0.4)

    const yScale = d3.scaleLinear()
        .range([height-40, 20])
        //.domain([0, Math.max(...maxValueArr)* 1.2]); //[llowest,highest]
        .domain([0, Math.max(...maxValueArr)>0?Math.max(...maxValueArr)* 1.2:80]); //[llowest,highest]

    const xG = d3.scaleLinear()
    .domain([0, 5]) //domain: [min,max] of a
    .range([0, 700])

    const yG = d3.scaleLinear()
        .domain([0, Math.max(...maxValueArr)* 1.2]) // domain [0,max] of b (start from 0)
        .range([0, 700])

    
    setxGridLines(xG.ticks(14).map((d, i) => (
        <g key={i} transform={`translate(${xScale(d)},${h + margin})`}>
            <line style={styles.gridline} className='gridline' y1={2400} y2={-2400} x1='0' x2='0' transform="translate(0,-25)" />
        </g>
    )))

    setyGridLines(yG.ticks().map((d, i) => (
        <g key={i} transform={`translate(${margin},${yScale(d)})`}>
            <line style={styles.gridline} className='gridline' x1={-2400} x2={2400} y1='0' y2='0' transform="translate(-5,0)" />
        </g>
    )))

    setyTicks(yScale.ticks(4).map((d, i) => (
        yScale(d) > 10 && yScale(d) < h ?
            <g key={i} transform={`translate(${margin + 60},${yScale(d)})`}>
                <text style={styles.axistext} x="-12" y="5">
                    {
                        // xFormat(d)
                        retAmtforTicks(d)
                    }
                    </text>
                {/* <line x1='0' x2='5' y1='0' y2='0' transform="translate(-5,0)" /> */}
                {/* <line style={styles.gridline} className='gridline' x1='0' x2={w - margin} y1='0' y2='0' transform="translate(-5,0)" /> */}

            </g>
            : null
    )))

    
    setxTicks(xScale.ticks(5.5).map((d, i) => (
        // x(d) > margin && x(d) < w ?
        <g key={i} transform={`translate(${xScale(d)+100},${h + margin})`}>
            <text style={styles.axistext}>{monthsArr[i]}</text>
            <line x1='0' x2='0' y1='0' y2='5' transform="translate(0,-20)" />
            {/* <line style={styles.gridline} className='gridline' y1='0' y2={margin - h} x1='0' x2='0' transform="translate(0,-25)" /> */}
        </g>
        // : null
    )))


    

        chart.append('g')
        .attr('transform', `translate(0, ${height})`)
        .call(d3.axisBottom(xScale));
  
    //   chart.append('g')
    //     .call(d3.axisLeft(yScale));

        chart.append('g')
      .attr('class', 'grid')

      const barGroups = chart.selectAll()
      .data(ActualData)
      .enter()
      .append('g')

    barGroups
      .append('rect')
      .attr('class', 'bar')
      .attr('x', (g) => xScale(g.monthNum))
      .attr('y', (g) => yScale(g.capital))
      .attr('height', (g) => height - yScale(g.capital))//capital
      .attr('width', 40)
      .attr('fill','#40412F')
    //   .attr('text','text')
    
    barGroups
      .append('rect')
      .attr('class', 'bar')   
      //.attr('x', 50)
      .attr('x', (g) => xScale(g.monthNum))
      .attr('y', (g) => yScale(g.capital) - (height - yScale(g.income)))//income
      .attr('height', (g) => height - yScale(g.income))
      .attr('width', 40)
      .attr("fill", "#B99855")

    barGroups 
      .append('text')
      .attr('class', 'value')
      .attr('x', (g) => xScale(g.monthNum) + 25 / 2)
      .attr('y', (g) => yScale(g.capital + g.income) - 45)
      .attr('text-anchor', 'middle')
      .text((g) => `${retformatamt( g.capital + g.income)}`)

    //   barGroups 
    //   .append('text')
    //   .attr('class', 'value')
    //   .attr('x', (g) => xScale(g.monthNum) + 25 / 2)
    //   .attr('y', (g) => yScale(g.capital) - 45)
    //   .attr('text-anchor', 'middle')
    //   .text((g) => `${retformatamt( g.capital)}`)

      

    
        //})
    }, [])

    return (
        <div style={styles.root}>
            <svg width={width} height={height} className="axessvg" style={styles.svg} ref={refA}>
            {/* <svg width={width} height={height} className="axessvg" > */}
                 <g className="axis-labels">
                    {xTicks}
                </g>*
                <g className="axis-labels">
                     {yTicks}
                </g> 
              {/*  <defs>
                    {/* <filter id="shadow">
                        <feGaussianBlur result="coloredBlur" stdDeviation="3.5" />
                        <feMerge>
                            <feMergeNode in="coloredBlur" />
                            <feMergeNode in="SourceGraphic" />
                        </feMerge>
                    </filter>
                    <filter id="shadow2">
                        <feDropShadow dx="0" dy="10" stdDeviation="4.5"
                            floodColor="black" />
                    </filter> 
                </defs>*/}
            </svg>
            <svg width={700} height={700} className="labelssvg" style={{ position: "absolute", top: 0, left: 96 }}>
                <g className="axis-labels">
                    {xGridLines}
                </g>
                <g className="axis-labelsy">
                    {yGridLines}
                </g>
            </svg> 
        </div>
    )
}

export default PortfolioBarLineChart