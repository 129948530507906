export const batchinput = {
    "archived": "false",
    "inputs": [
        {
            "name": "lifecycle_stage_status"
        },
        {
            "name": "lead_stage"
        },
        {
            "name": "expected_closing_probability"
        },
        {
            "name": "age_range"
        },
        {
            "name": "segment"
        },
        {
            "name": "contact_type"
        },
        {
            "name": "gender"
        },
        {
            "name": "title_salutation"
        },
        {
            "name": "position"
        },
        {
            "name": "industry_"
        },
        {
            "name": "source_of_wealth"
        },
        {
            "name": "expected_wealth_range"
        },
        {
            "name": "source_of_name"
        },
        {
            "name": "politically_exposed_persons_pep"
        },
        {
            "name": "hs_lead_status"
        },
    ]
}