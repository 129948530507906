import React, { useEffect, useState } from "react";
import {
    IonGrid,
    IonCard,
    IonSlide,
    IonSlides,
    IonLabel,
    IonIcon,
    IonItem,
    CreateAnimation,
    IonButton,
} from "@ionic/react";
import calendarschedule from "src/icons/calendarschedule.svg";
import useWindowDimensions from "src/components/useWindowDimensions";
import chevronBackCircle from "src/icons/sliderprev.svg"
import chevronForwardCircle from "src/icons/slidernext.svg"
import moment from "moment";
import { Plugins } from "@capacitor/core";
import "./UpcomingSchedule.css"


const { Device } = Plugins
const styles = {
    root: {
        marginRight: 0,
        marginLeft: 32,
        marginTop: 32
    },
    label: {
        // marginLeft: 16,
        marginBottom: 32
    },
    slide: {
        marginTop: 32
    },
    row: {
        display: "flex"
    },
    cardTitle: {
        textAlign: "left",
        fontSize: 16,
    },
    cardSubtitle: {
        fontSize: 16,
        color: "#fff"
    },
    cardcontent: {
        textAlign: "left"
    },
    cardMilestone: {
        fontSize: 18,
    },
    cardMilestone1: {
        fontSize: 10,
    },
    check: {
        "--size": "20px"
    },
    card: {
        marginLeft: 0,
        marginTop: 16,
        backgroundColor: "#4B3933",
    },
    item: {
        "--padding-start": "0px",
        "--padding-end": "0px"
    },
}


interface USProps {
    engagements: any,
    contact: any;
}

interface schObj {
    MeetingTitle: string;
    contactName: string;
    meetingTiming: string;

}

interface sArr extends Array<schObj>{}



const UpcomingSchedule: React.FC<USProps> = ({
    engagements,
    contact
}) => {

    const { height, width } = useWindowDimensions();
    const [deviceInfo, setdeviceInfo] = useState<any>();
    const slideOpts = deviceInfo && deviceInfo.platform === "web" && width > 1024 ? {
        slidesPerView: 3,
        // cssMode:true,
        spaceBetween: 16,
        simulateTouch: false,
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    }
    }:{
        // initialSlide: 1,
        speed: 400,
        slidesPerView: 2.5,
        freeMode: true,
        coverflowEffect:
            { rotate: 50, stretch: 0, depth: 100, modifier: 1, slideShadows: true, },
        spaceBetween: 0,
    };
    const [shcArr, setschArr] = useState<sArr>([])

    
    const [actSlide, setactSlide] = useState<any>({
      len: 0,
      ind: 0
    });

  const slideRef = React.createRef<HTMLIonSlidesElement>();

    useEffect(() => {
        if(engagements && contact) {
            const res = engagements.results ? engagements.results : []
            if(res.length > 0) {
                setactSlide({
                    len: res.length,
                    ind: 0
                  })
                setschArr(res.filter(r => r.engagement.type==="MEETING" && moment(r.metadata.startTime).isSameOrAfter(moment().startOf("day"))).map(obj => {
                    return {
                        MeetingTitle: obj.metadata.title,
                        contactName: contact.properties.middle_name !== null && contact.properties.middle_name !== "" ? `${contact.properties.firstname} ${contact.properties.middle_name} ${contact.properties.lastname}` : `${contact.properties.firstname} ${contact.properties.lastname}`,
                        meetingTiming: `${moment(obj.metadata.startTime).format('LT')} - ${moment(obj.metadata.endTime).format('LT')} | ${moment(obj.metadata.startTime).format('ddd')} ${moment(obj.metadata.startTime).format('ll')}`
                    }
                }))           
            }
        }
    },[engagements, contact])

    useEffect(() => {
        Device.getInfo().then(info => {
            setdeviceInfo(info)
        })
    }, [])

    return (
        shcArr.length > 0 ?
        <IonGrid class="ml-13 mr-13" style={styles.root}>
            <IonItem lines="none" style={styles.item}>
                <IonLabel className="font18">
                    Upcoming Schedule
                </IonLabel>
                { <IonButton className="font14 prof-cal" fill="clear" size="small" color="gold" slot="end" mode="ios" routerLink="/calendar">
                    View Calendar
                </IonButton> }
            </IonItem>
            {   shcArr.length > 0 ?
                <CreateAnimation
                    duration={1000}
                    play={true}
                    fromTo={{
                    property: 'opacity', 
                    fromValue: '0.5', 
                    toValue: '1'
                    }}
                >
                    <div className="temp-cont">
                        <IonSlides
                            ref={slideRef}
                            className="upcomingScheduleSlide slider-upsch2"
                            options={slideOpts}
                            onIonSlideDidChange={(e) => {
                                slideRef.current?.getActiveIndex().then((ind) => {
                                  slideRef.current?.length().then(len => {
                                    setactSlide({
                                      len,
                                      ind
                                    })
                                  })
                                })
                              }}
                        >
                            {
                                shcArr.map(sh => 
                                    <IonSlide key={sh.meetingTiming}>
                                        <IonCard style={styles.card} color="alt8" class="scheduleIcon">
                                            <IonItem lines="none" color="alt8" className="ion-activated">
                                                <IonIcon icon={calendarschedule} />
                                                <IonLabel class="ion-padding" >
                                                    <p className="marginbottom5 lineheight120 white boldText">{sh.MeetingTitle}</p>
                                                    <p className="marginbottom5 lineheight120 font14 grayscale5" >{sh.contactName}</p>
                                                    <p className="lineheight120 font12 grayscale5" >{sh.meetingTiming}</p>
                                                </IonLabel>
                                            </IonItem>
                                        </IonCard>
                                    </IonSlide>
                                )
                            }
                        </IonSlides>
                        {
                            deviceInfo && deviceInfo.platform === "web" && width > 1024 ? 
                            <div className="next-prev-cont-upsch2">
                            {
                                actSlide.len > 0 && actSlide.ind === 0 ? null :
                                <IonButton
                                className="swiper-button-prev-upsch2"
                                fill="clear"
                                onClick={() => {
                                slideRef.current?.slidePrev()
                                }}
                                >
                                <IonIcon 
                                    icon={chevronBackCircle}
                                    color="gold"
                                    />
                                </IonButton>
                            }
                            {
                            actSlide.len > 0 && actSlide.ind + 3 >= actSlide.len ? null :
                            <IonButton
                                className="swiper-button-next-upsch2"
                                fill="clear"
                                onClick={() => {
                                slideRef.current?.slideNext()
                                }}
                                >
                                <IonIcon
                                color="gold"
                                icon={chevronForwardCircle}
                                />
                            </IonButton>
                            }
                            </div>
                            : null
                    }
                    </div>
                </CreateAnimation> : 
                <div style={{height: 140}}/>
            }
        </IonGrid> : null
    )
}

export default UpcomingSchedule