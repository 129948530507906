import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonButton,
  IonButtons,
  IonIcon,
  IonPage,
  IonText,
  IonRow,
  IonMenuButton,
  IonAvatar,
  IonCol,
  IonGrid,
  IonImg,
} from "@ionic/react";
import "./AllProspects.css";
import { chevronBack, search } from "ionicons/icons";
import plusadd from "src/icons/plusicon.svg";
import Segments from "src/components/Segments/Segments";
import ListAll from "./ListAllProspects/ListAll";
import { funnel } from "ionicons/icons";
import FilterMenu from "./ListAllProspects/FilterMenu";
import { filterCheckboxes } from "./ListAllProspects/filterCheckData";
import api from "src/api";
import TopTargets from "./TopTargets";
import { RouteComponentProps, useHistory } from "react-router";
import useWindowDimensions from "src/components/useWindowDimensions";
import { Plugins } from "@capacitor/core";
const { Device } = Plugins;
const styles = {
  toolbar: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
  },
};

// const segs = [
//   {
//     label: "All Prospects",
//     value: "allprospects"
//   },
//   {
//     label: "Top Targets",
//     value: "toptargets"
//   },
//   // {
//   //   label: "All Clients",
//   //   value: "allclients"
//   // },
// ]

interface AllProps extends RouteComponentProps<{}> {}

const AllProspects: React.FC<AllProps> = ({ match }) => {
  const [deviceInfo, setdeviceInfo] = useState<any>();
  const [selSeg, setselSeg] = useState<string>("");
  const [activeFilters, setactiveFilters] = useState<Array<string>>([]);
  const [clrfil, setclrfil] = useState<boolean>(false);
  const [filFields, setfilFields] = useState<Array<string>>([]);
  const [sortOpt, setSortOpt] = useState<string>(
    "Interaction Date (Newest to Oldest)"
  );
  const [sfilterCheckboxes, setsfilterCheckboxes] = useState<any>();

  const hist = useHistory();
  const [headerText, setHeaderText] = useState<string>("Prospect List");
  const [addButtonText, setAddButtonText] = useState<string>(
    "Create New Prospect"
  );
  const [backButtonText, setBackButtonText] = useState<string>(
    "Prospect Dashboard"
  );
  const [totProsp, settotProsp] = useState("");
  const [trgtcnt, settrgtcnt] = useState<any>("");
  const [segs, setSegs] = useState<Array<any>>([
    {
      label: "All Prospects(" + totProsp + ")",
      value: "allprospects",
    },
    {
      label: "Top Targets(" + trgtcnt + ")",
      value: "toptargets",
    },
  ]);
  const [updateSortFilter, setUpdateSortFilter] = useState<any>({});

  useEffect(() => {
    Device.getInfo().then((info) => {
      setdeviceInfo(info);
    });
    api.getTTfromStore().then((resp) => {
      if (resp && resp.length) {
        settrgtcnt(resp.length);

        setSegs([
          {
            label: "All Prospects(" + totProsp + ")",
            value: "allprospects",
          },
          {
            label: "Top Targets(" + resp.length + ")",
            value: "toptargets",
          },
        ]);
      }
    });
  }, []);

  useEffect(() => {
    if (match.path === "/prospects/targets") {
      setSegs([
        {
          label: "All Prospects(" + totProsp + ")",
          value: "allprospects",
        },
        {
          label: "Top Targets(" + trgtcnt + ")",
          value: "toptargets",
        },
      ]);
      setselSeg("toptargets");
    } else if (match.path === "/clients/all") {
      setSegs([
        {
          label: "All Clients",
          value: "allclients",
        },
      ]);
      setselSeg("allclients");
      setHeaderText("Client List");
      // setAddButtonText("Create New Client")
      setBackButtonText("Client Dashboard");
    } else {
      setselSeg("allprospects");
    }
  }, [match.path]);

  useEffect(() => {
    let prom: any = [];

    filterCheckboxes().then((filterCheckboxes) => {
      // console.log(filterCheckboxes);
      filterCheckboxes.forEach((fil) => {
        if (
          fil.propName === "segment" ||
          fil.propName === "expected_wealth_range" ||
          fil.propName === "contact_type" ||
          fil.propName === "hs_lead_status"
        ) {
          if (fil.propName) {
            prom.push(
              new Promise((res) => {
                api
                  .getContactPropertiesByProperty(fil.propName)
                  .then((resp) => {
                    if (resp) {
                      if (resp.type === "enumeration") {
                        fil.options = resp.options.map((o) => o.value);
                        res("success");
                      }
                    }
                  });
              })
            );
          }
        }
      });

      Promise.all(prom).then(() => {
        setsfilterCheckboxes(filterCheckboxes);
      });
    });
  }, []);

  const setFilterFields = (Obj, SortOpt) => {
    // setfilFields(Obj);
    // setSortOpt(SortOpt);
    var newObj = { field: Obj, sortOpt: SortOpt };
    setUpdateSortFilter(newObj);
  };

  const trigClr = () => {
    setFilterFields({}, "Interaction Date (Newest to Oldest)");
    setactiveFilters([]);
    setclrfil(true);
  };
  const sendDataToParent = (index, toptarget) => {
    settotProsp(index);
    settrgtcnt(toptarget);
    api.getTTfromStore().then((resp) => {
      if (resp) {
        settrgtcnt(resp.length);
        if (match.path === "/prospects/all") {
          setSegs([
            {
              label: "All Prospects(" + index + ")",
              value: "allprospects",
            },
            {
              label: "Top Targets(" + resp.length + ")",
              value: "toptargets",
            },
          ]);
        } else if (match.path === "/clients/all") {
          setSegs([
            {
              label: "All Clients(" + index + ")",
              value: "allclients",
            },
            {
              label: "",
              value: "",
            },
          ]);
        } else if (match.path === "/prospects/targets") {
          setSegs([
            {
              label: "All Prospects(" + index + ")",
              value: "allprospects",
            },
            {
              label: "Top Targets(" + resp.length + ")",
              value: "toptargets",
            },
          ]);
        }
      }
    });
  };
  const sendTargetDataToParent = (index) => {
    api.getTTfromStore().then((resp) => {
      if (resp) {
        settrgtcnt(resp.length);
        if (match.path === "/prospects/all") {
          setSegs([
            {
              label: "All Prospects(" + totProsp + ")",
              value: "allprospects",
            },
            {
              label: "Top Targets(" + resp.length + ")",
              value: "toptargets",
            },
          ]);
        } else if (match.path === "/prospects/targets") {
          if (totProsp) {
            setSegs([
              {
                label: "All Prospects(" + totProsp + ")",
                value: "allprospects",
              },
              {
                label: "Top Targets(" + resp.length + ")",
                value: "toptargets",
              },
            ]);
          } else {
            setSegs([
              {
                label: "All Prospects",
                value: "allprospects",
              },
              {
                label: "Top Targets(" + resp.length + ")",
                value: "toptargets",
              },
            ]);
          }

          setselSeg("toptargets");
        }
      }
    });
  };

  const returnComponent = (segVal, setselSeg) => {
    switch (segVal) {
      case "allprospects":
      case "allclients":
        return (
          <ListAll
            sendDataToParent={sendDataToParent}
            toptargetcnt={trgtcnt}
            activeFilters={activeFilters}
            filterFields={filFields}
            sortOpt={sortOpt}
            updateSortFilter={updateSortFilter}
            setToTopTarget={() => {
              setselSeg("toptargets");
            }}
            clrFilters={() => {
              setactiveFilters([]);
              setclrfil(true);
            }}
            selectedSeg={segVal}
          />
        );
      case "toptargets":
        return (
          <TopTargets
            sendTargetDataToParent={sendTargetDataToParent}
            prospectcnt={totProsp}
          />
        );
      default:
        return null;
    }
  };

  const { height, width } = useWindowDimensions();
  const history = useHistory();
  const triggerSearch = () => {
    history.push("/prospects/search");
  };

  function iOS() {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }

  return (
    <IonPage>
      <FilterMenu
        activeFilters={activeFilters}
        clrfil={clrfil}
        trigClr={trigClr}
        setActiveArrayFunc={(a) => {
          setactiveFilters(a);
          setclrfil(false);
        }}
        setFilterFields={setFilterFields}
        sfilterCheckboxes={sfilterCheckboxes}
        selSeg={selSeg}
      />
      <IonHeader>
        <IonToolbar mode="md" style={styles.toolbar}>
          <IonButtons slot="start">
            <IonButton
              style={{ textTransform: "none" }}
              color="white"
              // routerLink="/dashboard"
              onClick={() => {
                selSeg === "allclients"
                  ? hist.push(`/dashboard`, { to: "clients" })
                  : hist.push(`/dashboard`, { to: "prospects" });
              }}
            >
              <IonIcon icon={chevronBack}></IonIcon>
              {backButtonText}
            </IonButton>
          </IonButtons>
          {iOS() === true ? (
            <IonGrid className="rootgrid margintopn10">
              <IonRow>
                <IonCol>
                  <IonGrid className="ion-float-right">
                    <IonRow>
                      <IonIcon
                        color="white"
                        icon={search}
                        size="small"
                        onClick={triggerSearch}
                        className="trig-srch"
                      ></IonIcon>
                      <IonText
                        className="srchplaceholder ion-margin-horizontal trig-srch"
                        onClick={triggerSearch}
                      >
                        {width > 575
                          ? "Search for any prospect or client"
                          : "Search"}
                      </IonText>
                    </IonRow>
                  </IonGrid>
                </IonCol>
              </IonRow>
            </IonGrid>
          ) : (
            <IonGrid className="rootgrid" style={{ display: "flex" }}>
              {/* <IonRow> */}
              {/* <IonCol> */}
              {/* <IonGrid className="searchCss ion-float-right"> */}
              <IonRow style={{ marginLeft: "auto" }}>
                <IonIcon
                  color="white"
                  icon={search}
                  size="small"
                  onClick={triggerSearch}
                  className="trig-srch"
                ></IonIcon>
                <IonText
                  className="srchplaceholder ion-margin-horizontal trig-srch"
                  onClick={triggerSearch}
                >
                  {width > 575 ? "Search for any prospect or client" : "Search"}
                </IonText>
              </IonRow>
              {/* </IonGrid> */}
              {/* </IonCol> */}
              {/* </IonRow> */}
            </IonGrid>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent id="menu-id" style={{ "--background": "none" }}>
        <IonRow
          class="margin-set mt-6 mobile-top-content m-marginleft-12"
          style={{ marginTop: 24, marginLeft: 32, height: 32 }}
        >
          <span
            className="to-do"
            style={{
              fontWeight: 300,
              fontSize: 28,
              color: "GrayText",
              whiteSpace: "nowrap",
            }}
          >
            {headerText}
          </span>
          {deviceInfo &&
          deviceInfo.platform === "web" &&
          width > 1024 &&
          selSeg === "allprospects" ? (
            <IonButtons
              style={{
                marginLeft: "auto",
                marginBottom: "auto",
                marginTop: "auto",
              }}
              className="ion-float-right"
            >
              <IonButton
                mode="ios"
                color="gold"
                fill="clear"
                slot="end"
                routerLink="/prospect/create"
              >
                <IonIcon
                  color="white"
                  size="small"
                  icon={plusadd}
                  slot="start"
                ></IonIcon>
                {width > 575 ? addButtonText : null}
              </IonButton>
            </IonButtons>
          ) : selSeg === "allprospects" ? (
            <IonButtons
              style={{
                marginLeft: "auto",
                marginBottom: "auto",
                marginTop: "auto",
              }}
            >
              <IonButton
                mode="ios"
                color="gold"
                fill="clear"
                slot="end"
                routerLink="/prospect/create"
              >
                <IonIcon
                  color="white"
                  size="small"
                  icon={plusadd}
                  slot="start"
                ></IonIcon>
                {width > 575 ? addButtonText : null}
              </IonButton>
            </IonButtons>
          ) : null}
          {selSeg === "allprospects" ? (
            <IonMenuButton style={{ marginBottom: "auto" }}>
              <IonButton fill="clear" size="small">
                <IonIcon color="gold" icon={funnel} size="small" />
              </IonButton>
            </IonMenuButton>
          ) : selSeg === "allclients" ? (
            <IonMenuButton style={{ marginLeft: "auto", marginBottom: "auto" }}>
              <IonButton fill="clear" size="small">
                <IonIcon color="gold" icon={funnel} size="small" />
              </IonButton>
            </IonMenuButton>
          ) : null}
        </IonRow>
        <Segments
          buttons={segs}
          startbtn={selSeg}
          selectedValue={(val) => {
            setselSeg(val);
          }}
        />
        {returnComponent(selSeg, setselSeg)}
      </IonContent>
    </IonPage>
  );
};

export default AllProspects;
