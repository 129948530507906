import {
  IonGrid,
  IonRow,
  IonItem,
  IonIcon,
  IonLabel,
  IonText,
  IonAvatar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import "./Timeline.css";
import email from "src/icons/engagement-email.svg";
import timelinemeeting from "src/icons/timeline-meeting.svg";
import timelineschedule from "src/icons/timeline-schedule.svg";
import timelineanswered from "src/icons/timeline-answered.svg";
import moment from "moment";
import { ellipse } from "ionicons/icons";
import { useHistory } from "react-router-dom";
// import { dateSelectionJoinTransformer } from '@fullcalendar/react';

interface timelineObj {
  timelineTitle: string;
  timelineTiming: string;
  timelineSubject: string;
  timeCreatedDate: any;
  timemeetingDate: any;
  logbody: any;
  type: any;
}

const styles = {
  grid: {
    marginRight: 32,
    marginLeft: 32,
  },
  period: {
    fontSize: 6,
    marginLeft: 4,
    marginRight: 4,
    marginTop: 8,
    color: "#828282",
  },
};

interface tArr extends Array<timelineObj> {}

const groupBy = (array, key) => {
  return array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    if (!result[currentValue[key]]) {
      result[currentValue[key]] = [];
    }
    return result;
  }, {});
};

const Timeline: React.FC<any> = ({ announcement, engagements, contact }) => {
  // const [timlineArr, timlineFunc] = useState<tArr>([]);
  const [monthsAll, setmonthsAll] = useState<any>([]);
  const [monthsGrouped, setmonthsGrouped] = useState<any>([]);
  useEffect(() => {
    if (engagements) {
      if (engagements.results && engagements.results.length > 0) {
        setmonthsAll(
          engagements.results
            .filter(
              (r) =>
                // (r.engagement.type === "MEETING" &&
                //   moment(r.metadata.startTime).isSameOrBefore(
                //     moment(new Date())
                //   )) ||
                r.engagement.type === "MEETING" ||
                r.engagement.type === "CALL" ||
                r.engagement.type === "EMAIL" ||
                r.engagement.type === "TASK"
            )
            .map((obj) => {
              //  console.log(obj)
              return {
                ...obj,
                Month:
                  obj.engagement.type === "MEETING"
                    ? moment(obj.metadata.startTime).format("MMMM")
                    : obj.engagement.type === "CALL" ||
                      obj.engagement.type === "EMAIL"
                    ? moment(obj.engagement.timestamp).format("MMMM")
                    : moment(obj.engagement.createdAt).format("MMMM"),
                MonthNum:
                  obj.engagement.type === "MEETING"
                    ? moment(obj.metadata.startTime).format("M")
                    : obj.engagement.type === "CALL" ||
                      obj.engagement.type === "EMAIL"
                    ? moment(obj.engagement.timestamp).format("M")
                    : moment(obj.engagement.createdAt).format("M"),
                DayNum:
                  obj.engagement.type === "MEETING"
                    ? moment(obj.metadata.startTime).format("DD")
                    : obj.engagement.type === "CALL" ||
                      obj.engagement.type === "EMAIL"
                    ? moment(obj.engagement.timestamp).format("DD")
                    : moment(obj.engagement.createdAt).format("DD"),
                YearNum:
                  obj.engagement.type === "MEETING"
                    ? moment(obj.metadata.startTime).format("YYYY")
                    : obj.engagement.type === "CALL" ||
                      obj.engagement.type === "EMAIL"
                    ? moment(obj.engagement.timestamp).format("YYYY")
                    : moment(obj.engagement.createdAt).format("YYYY"),
                title: obj.metadata.title,
                timing:
                  obj.engagement.type === "MEETING"
                    ? `${moment(obj.metadata.startTime).format(
                        "ddd"
                      )}, ${moment(obj.metadata.startTime).format(
                        "ll"
                      )} | ${moment(obj.metadata.startTime).format(
                        "LT"
                      )} - ${moment(obj.metadata.endTime).format("LT")}`
                    : obj.engagement.type === "CALL" ||
                      obj.engagement.type === "EMAIL"
                    ? `${moment(obj.engagement.timestamp).format(
                        "ddd"
                      )}, ${moment(obj.engagement.timestamp).format(
                        "ll"
                      )} | ${moment(obj.engagement.timestamp).format("LT")}`
                    : `${moment(obj.engagement.createdAt).format(
                        "ddd"
                      )}, ${moment(obj.engagement.createdAt).format(
                        "ll"
                      )} | ${moment(obj.engagement.createdAt).format("LT")}`,
                timing2:
                  obj.engagement.type === "MEETING"
                    ? obj.metadata.startTime
                    : obj.engagement.type === "CALL" ||
                      obj.engagement.type === "EMAIL"
                    ? obj.engagement.timestamp
                    : moment(obj.engagement.createdAt),
                logbody:
                  obj.engagement.type === "TASK"
                    ? obj.metadata.subject
                    : obj.engagement.bodyPreview,
                type: obj.engagement.type,
                meetingtime: obj.metadata.startTime,
              };
            })
        );
      }
    }
  }, [engagements]);

  useEffect(() => {
    if (monthsAll.length > 0) {
      setmonthsGrouped(
        groupBy(
          monthsAll.sort((a, b) =>
            // a.MonthNum > b.MonthNum ? -1 : b.MonthNum > a.MonthNum ? 1 : 0
            moment(a.timing2).isAfter(moment(b.timing2))
              ? -1
              : moment(b.timing2).isAfter(moment(a.timing2))
              ? 1
              : 0
          ),
          "Month"
        )
      );
    }
  }, [monthsAll]);

  return monthsGrouped && monthsGrouped !== null ? (
    <IonGrid class="mr-13 ml-14" style={styles.grid}>
      {Object.keys(monthsGrouped).map((monthName, index) => (
        <React.Fragment key={monthName}>
          <IonItem
            className="ionitemTLMonthName"
            key={monthName}
            lines="none"
            style={{ "--padding-start": "0px", marginTop: 32 }}
          >
            {`${monthName} ${monthsGrouped[monthName][0].YearNum}`}
          </IonItem>
          <MonthList
            contact={contact}
            timelineData={monthsGrouped[monthName]}
          />
          <IonItem lines="full" />
        </React.Fragment>
      ))}
    </IonGrid>
  ) : null;
};

const MonthList = ({ timelineData, contact }) => {
  const [strtTyme, setstrtTyme] = useState<any>();
  const [endTyme, setendTyme] = useState<any>();
  const [Dte, setDte] = useState<any>();
  const [objt, setobjt] = useState<any>();
  // console.log(timelineData);
  const hist = useHistory();

  function gotoViewEvent(timeline) {
    let event = {
      title: timeline.title,
      start: timeline.metadata.startTime,
      end: timeline.metadata.endTime,
      body: timeline.metadata.body,
      name: `${contact.properties.firstname} ${contact.properties.lastname}`,
      engId: timeline.engagement.id,
      from: "Prospectprofile",
    };

    hist.push("/viewevent", event);
  }

  function gotoViewTodo(cntdid, engid) {
    console.log(cntdid);
    console.log(engid[0]);
  }
  return (
    <React.Fragment>
      {timelineData
        .sort((a, b) =>
          a.DayNum > b.DayNum ? -1 : b.DayNum > a.DayNum ? 1 : 0
        )
        .map((timeline, index) => (
          <React.Fragment key={timeline.engagement.id}>
            <IonItem className="timelineitem" lines="none" style={{ "--padding-start": "0px" }}>
              <IonAvatar slot="start">
                <IonIcon
                  style={{ width: 36, height: 36 }}
                  src={
                    timeline.type === "CALL"
                      ? timelineanswered
                      : timeline.type === "EMAIL"
                      ? email
                      : timeline.type === "TASK"
                      ? timelineschedule
                      : timelinemeeting
                  }
                />
              </IonAvatar>
              {
                // setstrtTyme(moment(timeline.metadata.startTime).format("LT"))
                // setendTyme(${moment(timeline.metadata.endTime).format("LT"))
                // setDte(moment(timeline.metadata.startTime).format("ddd"));
              }
              <IonLabel
                cstm-type={timeline.type}
                cstm-title={timeline.title}
                cstm-logbody={timeline.logbody}
                cstm-start={timeline.timing}
                cstm-end={timeline.timing}
                onClick={(event) => {
                  if (
                    (event.target as HTMLInputElement).getAttribute(
                      "cstm-type"
                    ) === "MEETING"
                  ) {
                    //gotoViewEvent((event.target as HTMLInputElement).getAttribute('cstm-title'),(event.target as HTMLInputElement).getAttribute('cstm-logbody'),(event.target as HTMLInputElement).getAttribute('cstm-start'),(event.target as HTMLInputElement).getAttribute('cstm-end'))
                    //gotoViewEvent(timeline.engagement.id,timeline.associations.contactIds)
                    gotoViewEvent(timeline);
                  } else if (
                    (event.target as HTMLInputElement).getAttribute(
                      "cstm-type"
                    ) === "TASK"
                  ) {
                    //console.log(timeline.associations.contactIds[0])
                    //gotoViewTodo(timeline.engagement.id,timeline.associations.contactIds);
                    // hist.push(`/edit/todo/${matchContId}/${matchTodoId}`,{selTodoObj,matchContId})
                    hist.push(
                      `/view/todo`,{
                        contId:timeline.associations.contactIds[0],
                        engId:timeline.engagement.id
                      }
                    );
                  }
                }}
              >
                <IonRow
                  cstm-type={timeline.type}
                  cstm-id={timeline.engagement.id}
                  cstm-title={timeline.title}
                  cstm-logbody={timeline.logbody}
                  cstm-start={timeline.metadata.startTime}
                  cstm-end={timeline.timing}
                  style={{ marginBottom: 3 }}
                >
                  <IonText
                    class="mobile-timeline-title"
                    cstm-type={timeline.type}
                    cstm-id={timeline.engagement.id}
                    cstm-title={timeline.title}
                    cstm-logbody={timeline.logbody}
                    cstm-start={timeline.metadata.startTime}
                    cstm-end={timeline.timing}
                    style={{ fontWeight: "700" }}
                  >
                    {timeline.type === "CALL"
                      ? timeline.logbody
                      : timeline.type === "MEETING"
                      ? timeline.title
                      : timeline.logbody}
                  </IonText>
                </IonRow>
                <IonRow>
                  <IonText
                    class="mobile-timeline-date"
                    cstm-type={timeline.type}
                    cstm-id={timeline.engagement.id}
                    cstm-title={timeline.title}
                    cstm-logbody={timeline.logbody}
                    cstm-start={timeline.metadata.startTime}
                    cstm-end={timeline.timing}
                    color="greyscale"
                  >
                    {timeline.type === "CALL" ? (
                      timeline.timing.split("|")[0]
                    ) : timeline.type === "MEETING" ? (
                      <RetTimeforMeeting obj={timeline} />
                    ) : (
                      timeline.timing.split("|")[0]
                    )}
                  </IonText>
                </IonRow>
              </IonLabel>
            </IonItem>
            <RetBorderComp length={timelineData.length} index={index} />
          </React.Fragment>
        ))}
    </React.Fragment>
  );
};

const RetTimeforMeeting = ({ obj }) => {
  return (
    <IonText>
      {
       obj.metadata.startTime && obj.metadata.endTime ?`${moment(obj.metadata.startTime).format("ddd")}, ${moment(
        obj.metadata.startTime
      ).format("ll")}`:''
    }
      {
        obj.metadata.startTime && obj.metadata.endTime ?<IonIcon icon={ellipse} className="timeline-period" style={styles.period} />:null
      }
      {
      obj.metadata.startTime && obj.metadata.endTime ? `${moment(obj.metadata.startTime).format("LT")} - ${moment(
        obj.metadata.endTime
      ).format("LT")}`:''
      }
    </IonText>
  );
};

const RetBorderComp = ({ length, index }) => {
  return index < length - 1 ? (
    <IonItem
      className="TLline"
      lines="none"
      style={{ height: 40, borderLeft: "1px solid #fff", marginLeft: 18 }}
    />
  ) : null;
};

export default Timeline;
