import { IonPage, IonHeader, IonToolbar, IonSearchbar, IonContent, IonGrid, IonRow, IonCol, IonButton, IonText, IonItem, IonIcon, IonLabel } from '@ionic/react'
import React, { useState, useEffect } from 'react'
import CryptoJS from 'crypto-js';
import { useHistory } from "react-router-dom";
import person from "src/icons/person.svg"
//import TopTargetSearchResults from "./TopTargetSearchResult";
import "./AttendeeSearch.css"
import { Plugins } from "@capacitor/core";
import api from 'src/api';
const { Storage } = Plugins

const styles = {
    btncol: {
        display: "flex"
    },
    btn: {
        marginTop: "auto",
        marginBottom: "auto"
    },
    header: {
        marginLeft: 32,
        fontWeight: 300
    },
    personlabel: {
        marginLeft: 8
    },
    item: {
        paddingRight: 16,
        paddingLeft: 16
    }
}

const decrypt = (decryptVlaue) => {
    let passphrase = "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9"
    const bytes = CryptoJS.AES.decrypt(decryptVlaue, passphrase);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  };

const getUserId = async () => {
    const ret: any = await Storage.get({ key: "userId" })
    const temp = JSON.parse(decrypt(ret.value))
    return temp.id
}

const getTeams = async () => {
    const ret: any = await Storage.get({ key: "userId" });
    const temp = JSON.parse(decrypt(ret.value));
    return temp && temp.teams ? temp.teams : null;
  };

interface InpProps {
    srchQry: string,
    clrSrch: boolean,
    resetClrSrch: Function,
}

//const AttendeeSearch: React.FC = (eventtitle) => {
    //const AttendeeSearch = (eventtitle, eventDesc, dueDate, dueTimeStart,dueTimeEnd ) => {
    const AttendeeSearch = (props) => {
        const datatoSend:any = [];
        datatoSend.push(props.eventtitle);
        datatoSend.push(props.eventDesc);
        datatoSend.push(props.dueDate);
        datatoSend.push(props.dueTimeStart);
        datatoSend.push(props.dueTimeEnd);
        datatoSend.push(props.reqdAttendees);
        // console.log(props);
    const history = useHistory()
    const [srchQry, setsrchQry] = useState<string>("")
    const [clrSrch, setclrSrch] = useState<boolean>(false)
    const [results, setResults] = useState<any>([])
    const resetClrSrch = () => {
        setclrSrch(false)
    }
    const handleChange = (e) => {
        // console.log(e.target.value)
        //setsrchQry(e.target.value)
        if(e.target.value.length >= 3)
        {
            setsrchQry(e.target.value);
            setTimeout(() => {
                const htmlele: HTMLInputElement | null = document.querySelector(
                    ".searchbar-input"
                  );
                  htmlele?.focus();
            }, 0);
        }
    }

    const handleClear = () => {
        setclrSrch(true)
    }

    useEffect(() => {
        if (srchQry !== "") {
            let payload: any = { };
            let isnum = /^\d+$/.test(srchQry);
            if (isnum) 
            {
                payload = {  properties: ["firstname,lastname,middle_name,id,email"] }                
            }
            else
            {                
                payload = { "query": srchQry, properties: ["firstname,lastname,middle_name,id,email"] }
            }        
                
           // let payload: any = { "query": srchQry, properties: ["firstname,lastname,middle_name,id,email"] }
            const getSearchRes = async () => {
                // return api.filterProspects(payload);                
                const uId = await getUserId();
               
                getTeams().then((teams) => {
                    
                    let filterGroups:any = [];
                    if(teams && isnum)
                    {                        
                        filterGroups = [
                            {
                                filters: [
                                    {
                                        operator: "EQ",
                                        propertyName: "hubspot_team_id",
                                        value: teams?parseInt(teams[0].id):""
                                    },
                                    {
                                        operator: "EQ",
                                        propertyName: "onboarding_id",
                                        value: parseInt(srchQry)
                                    },
                                ]
                            }
                        ];
                        if(teams.length >= 2)
                        {
                            filterGroups.push({
                                filters: [                    
                                  {
                                    operator: "EQ",
                                    propertyName: "hubspot_team_id",
                                    value: teams?parseInt(teams[1].id):"",
                                  },
                                  {
                                    operator: "EQ",
                                    propertyName: "onboarding_id",
                                    value: parseInt(srchQry)
                                  },
                                ],                  
                              });
                        }
                        if(teams.length >= 3)
                        {
                            filterGroups.push({
                                filters: [                    
                                  {
                                    operator: "EQ",
                                    propertyName: "hubspot_team_id",
                                    value: teams?parseInt(teams[2].id):"",
                                  },
                                  {
                                    operator: "EQ",
                                    propertyName: "onboarding_id",
                                    value: parseInt(srchQry)
                                  },
                                ],                  
                              });
                        }
                    }
                    else
                    {                        
                        filterGroups = [
                            {
                                filters: [
                                    {
                                        operator: "EQ",
                                        propertyName: "hubspot_owner_id",
                                        value: parseInt(uId)
                                    }
                                ]
                            }
                        ]
    
                    }
                   
                    
                return api.searchProspect({ ...payload, filterGroups })
                    .then((response) => {
                        setResults(response.results.filter(obj => {
                            let retVal = true
                            for(const c of props.cntactids) {
                                if(parseInt(c)===parseInt(obj.id)) {
                                    retVal = false
                                }
                            }
                            return retVal
                        }))
                    })
                    .catch((error) => {
                        console.error("Error from API", error);
                        throw (error);
                    });
                })
            }
            getSearchRes()
        } else {
            setResults([])
        }
    }, [srchQry])

    useEffect(() => {
        if (clrSrch) {
            setResults([])
            resetClrSrch()
        }
    }, [clrSrch, resetClrSrch])

    function retrieveProspect(contId,fname,mname,lname,email) {
       datatoSend.push(contId);
       datatoSend.push(fname);
       datatoSend.push(mname);
       datatoSend.push(lname);
       datatoSend.push(email);
       props.retrievedData(datatoSend);
    }

    
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar
                class="mobile-toolbar-top"
                    style={{ "--background": "#1b1b1b" }}
                >
                    <IonGrid
                        className="ion-margin-horizontal"
                    >
                        <IonRow class="margin-set">
                            <IonCol size-xs="9" size-sm="11">
                                <IonSearchbar
                                    placeholder="Search by Prospect Name..."
                                    mode="md"
                                    debounce={500}
                                    onIonChange={handleChange}
                                    //onKeyUp ={handleChange}
                                    onIonClear={handleClear}
                                    style={{ "--background": "#222222", "--box-shadow": "none","--padding-top":"0px" }}
                                />
                            </IonCol>
                            <IonCol class="btncol-mobile" style={styles.btncol} size-xs="3" size-sm="1" >
                                <IonButton style={styles.btn} fill="clear" color="gold" onClick={() => props.revertBack()}>
                                    Cancel
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className="search-content">
                {/* <TopTargetSearchResults srchQry={srchQry} clrSrch={clrSrch} resetClrSrch={resetClrSrch} /> */}
                <div className="ion-padding">
                    <IonRow class="margin-set" style={{ marginTop: 24, marginLeft: 32, marginBottom: 24 }}>
                        <IonText class="fs-28"
                            style={{ fontWeight: 300, fontSize: 28 }}
                            color="greyscale"
                        >
                            Search
                </IonText>
                    </IonRow>
                    {
                        results && results.length > 0 ?
                            results.map(contact => (
                                <IonItem
                                class="ml-13 mr-13"
                                    button
                                    detail={false}
                                    custom-id={contact.id}
                                    custom-fname={contact.properties.firstname}
                                    custom-mname={contact.properties.middle_name}
                                    custom-lname={contact.properties.lastname}
                                    custom-email={contact.properties.email}
                                    onClick={(event) => {
                                        retrieveProspect(
                                            (event.target as HTMLInputElement).getAttribute('custom-id'),
                                            (event.target as HTMLInputElement).getAttribute('custom-fname'),
                                            (event.target as HTMLInputElement).getAttribute('custom-mname'),
                                            (event.target as HTMLInputElement).getAttribute('custom-lname'),
                                            (event.target as HTMLInputElement).getAttribute('custom-email'),
                                            )
                                    }}
                                    key={contact.id}
                                    // routerLink={"/prospects/targets"}
                                    style={styles.item}
                                >
                                    <IonIcon icon={person} color="white" size="small" style={{ marginLeft: 12 }} />
                                    <IonLabel 
                                    custom-fname={contact.properties.firstname}
                                    custom-mname={contact.properties.middle_name}
                                    custom-lname={contact.properties.lastname}
                                    custom-id={contact.id} style={styles.personlabel}
                                    custom-email={contact.properties.email}>
                                        {
                                            `${contact.properties.firstname}${contact.properties.middle_name ? " " + contact.properties.middle_name : ''} ${contact.properties.lastname}`
                                        }
                                    </IonLabel>
                                </IonItem>
                            )
                            ) : null
                    }
                </div>
            </IonContent>
        </IonPage>
    )
}

export default AttendeeSearch
