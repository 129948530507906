import {
    SQLiteDatabaseConfig,
    SQLite,
} from "@ionic-native/sqlite";
import CryptoJS from 'crypto-js';
import api from "src/api";
import { Network, NetworkStatus, Plugins } from "@capacitor/core";

const { Storage, Device } = Plugins;

const DbConfig: SQLiteDatabaseConfig = {
    name: "data.db",
    location: "default",
};
interface SingleUser {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    userId: number;
    createdAt: any;
    updatedAt: any;
    archived: boolean;
    teams: string;
}

interface UserApiResp {
    results: SingleUser[];
    paging: string;
}

interface userTable {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    userId: string;
    createdAt: string;
    updatedAt: string;
    archived: string;
    teams: string;
}

const decrypt = (decryptVlaue) => {
    let passphrase = "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9"
    const bytes = CryptoJS.AES.decrypt(decryptVlaue, passphrase);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  };

const getUserId = async () => {
    const ret: any = await Storage.get({ key: "userId" });
    const temp = JSON.parse(decrypt(ret.value));
    return temp && temp.id ? temp.id : null;
  };

export const dropUsersables: string = "DROP TABLE IF EXISTS users;";

const createUserTable: string = `CREATE TABLE IF NOT EXISTS users (userRowId INTEGER PRIMARY KEY AUTOINCREMENT, id data_type VARCHAR(255), email data_type VARCHAR(255), firstName data_type VARCHAR(255), lastName data_type VARCHAR(255), userId data_type VARCHAR(255), createdAt data_type VARCHAR(255), updatedAt data_type VARCHAR(255), archived data_type VARCHAR(255), teams data_type VARCHAR(255))`

const insertUserRec: string = `INSERT INTO users (id, email, firstName, lastName, userId, createdAt, updatedAt, archived) values (?, ?, ?, ?, ?, ?, ?, ?)`

const getAllUsers: string = `SELECT id, email, firstName, lastName, userId, createdAt, updatedAt, archived from users`

const deleteUser: string = `DELETE FROM users WHERE id = ?`

export const userTableCreate: () => Promise<any> = async () => {
    const info = await Device.getInfo();
    //check for platform - if browser then return response from api else perform dboperations
    if (info.platform === "android" || info.platform === "ios") {
        const dbInstance = await SQLite.create(DbConfig)
        dbInstance.executeSql(createUserTable).then(val => console.log("Line 26: "+ JSON.stringify(val))).catch(e => console.log("Error line 26"+ JSON.stringify(e)))
    } else {
        return null
    }
}

export const getOwnersDb: () => Promise<UserApiResp> = async () => {
    const network: NetworkStatus = await Network.getStatus();
    const info = await Device.getInfo();
    const dbInstance = await SQLite.create(DbConfig)
    if (!(info.platform === "android" || info.platform === "ios")) {
        return api.getOwnersAPI()
    } else  {
        const respObj: UserApiResp = {
            paging: "",
            results: []
        }
        return dbInstance.executeSql(getAllUsers).then((usrResp) => {
            // console.log("LINE 77 OWNERS"+JSON.stringify(usrResp))
            if(usrResp.rows?.length > 0) {
                for(var i=0;i<usrResp.rows.length;i++) {
                    let temp = usrResp.rows.item(i)
                    const {
                        archived,
                        createdAt,
                        email,
                        firstName,
                        id,
                        lastName,
                        teams,
                        updatedAt,
                        userId
                    } = temp
                    respObj.results.push({
                        archived,
                        createdAt,
                        email,
                        firstName,
                        id: String(id),
                        lastName,
                        teams,
                        updatedAt,
                        userId
                    })
                }
            }
            return respObj
        }).catch((e) => {
            console.log("LINE 105 OWNERS"+JSON.stringify(e))
            if(e.rows?.length > 0) {
                for(var i=0;i<e.rows.length;i++) {
                    let temp = e.rows.item(i)
                    const {
                        archived,
                        createdAt,
                        email,
                        firstName,
                        id,
                        lastName,
                        teams,
                        updatedAt,
                        userId
                    } = temp
                    respObj.results.push({
                        archived,
                        createdAt,
                        email,
                        firstName,
                        id: String(id),
                        lastName,
                        teams,
                        updatedAt,
                        userId
                    })
                }
            }
            return respObj
        })
    }
}


export const userLoad: () => Promise<any> = async () => {
    api.getOwnersAPI().then(async (resp: UserApiResp )=> {
        let results = resp.results
        try {
            for (const user of results) {
                await deleteUserIfExist(String(user.id))
                await insertUser(user)
            }
        } catch (error) {
            console.log("FOR LOOP Error"+ JSON.stringify(error))
        }
    }).catch(e => console.log("Error line 57:"+JSON.stringify(e)))

}



const insertUser: (Inp:SingleUser) => Promise<any> = async (SingleUsr) => {
    const dbInstance = await SQLite.create(DbConfig)
    try {
        const { id, email, firstName, lastName, userId, createdAt, updatedAt, archived } = SingleUsr
        dbInstance.executeSql(insertUserRec, [
            String(id),
            String(email),
            String(firstName),
            String(lastName),
            String(userId),
            String(createdAt),
            String(updatedAt),
            String(archived)
        ])
    } catch (error) {
        console.log("ERROR Inserting User Rec"+ JSON.stringify(error))
    } finally {
        // dbInstance.close()
    }
}

const deleteUserIfExist: (id) => Promise<any> = async (id) => {
    const dbInstance = await SQLite.create(DbConfig)
    try {

        dbInstance.executeSql(deleteUser, [id])
    } catch (error) {
        console.log("ERROR Deleting User Rec"+ JSON.stringify(error))
    } finally {
        // dbInstance.close()
    }
}