import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonButton,
  IonButtons,
  IonIcon,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonAvatar,
  IonLabel,
  IonText,
} from "@ionic/react";
import CryptoJS from 'crypto-js';
import { chevronBack, chevronDown, ellipse } from "ionicons/icons";
import { useHistory } from "react-router";
import api from "src/api";
import moment from "moment";
import useWindowDimensions from "src/components/useWindowDimensions";
import { Plugins } from "@capacitor/core";
const { Storage } = Plugins;


const styles = {
  toolbar: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
  },
  select: {
    "--padding-start": "8px",
    color: "#B99855",
    textAlign: "right",
  },

  grid: {
    marginLeft: 32,
    marginRight: 32,
    marginTop: 32,
  },
  item: {
    "--background": "#222222",
    marginBottom: 2,
    borderRadius: 8,
  },
  desc: {
    fontFamily: "Almarai",
    fontSize: 16,
    color: "#828282",
    marginBottom: 8,
  },
  datetime: {
    fontWeight: 300,
    fontSize: 16,
    color: "#828282",
  },
  period: {
    fontSize: 6,
    marginLeft: 4,
    marginRight: 4,
    marginTop: 8,
    color: "#828282",
  },
  label: {
    marginTop: 14.5,
    marginBottom: 14.5,
  },
  title: {
    fontWeight: "700",
    marginLeft: "-4px"
  },
};
const options = {
  cssClass: "my-custom-interface",
};

const retExpClsAmt = (clsAmt) => {
  let closingamt_disp: number = 0;
  Number.isNaN(clsAmt) || clsAmt === null || clsAmt === ""
    ? (closingamt_disp = 0)
    : (closingamt_disp = parseInt(clsAmt));
  return Math.abs(Number(closingamt_disp)) >= 1.0e9
    ? decimalFunc(Math.abs(Number(closingamt_disp)) / 1.0e9) + "b"
    : // Six Zeroes for Millions
    Math.abs(Number(closingamt_disp)) >= 1.0e6
    ? decimalFunc(Math.abs(Number(closingamt_disp)) / 1.0e6) + "m"
    : // Three Zeroes for Thousands
    Math.abs(Number(closingamt_disp)) >= 1.0e3
    ? decimalFunc(Math.abs(Number(closingamt_disp)) / 1.0e3) + "k"
    : decimalFunc(Math.abs(Number(closingamt_disp)));
};

const decimalFunc = (Num) => {
  let temp = Num.toString().split(".");
  let retVal = 0;
  if (temp[1]) {
    retVal = Num.toFixed(1);
  } else {
    retVal = Num;
  }
  return retVal;
};



const ClientReinvestment: React.FC<any> = ({ }) => {
  const hist = useHistory();
  const clientreinvest = "Client Reinvestment";
  const imgbaseuri = "https://cdn2.hubspot.net/";

  const timelinedata: any = [];
  let thisdate = new Date();
  let thisyear = thisdate.getFullYear();
  let thismonth = thisdate.getMonth();
  let curquater: any = thisdate.getMonth();
  let curquateryear: any = "";
  let curquatstartmonth: number = 0;
  if (curquater <= 2) {
    curquateryear = "Q1 " + thisyear;
    curquatstartmonth = 0;
  } else if (curquater <= 5) {
    curquateryear = "Q2 " + thisyear;
    curquatstartmonth = 3;
  } else if (curquater <= 8) {
    curquateryear = "Q3 " + thisyear;
    curquatstartmonth = 6;
  } else {
    curquateryear = "Q4 " + thisyear;
    curquatstartmonth = 9;
  }

  const [curquatyear, setcurquatyear] = useState("Year-to-Date");
  timelinedata.push(thisyear - 3);
  timelinedata.push(thisyear - 2);
  timelinedata.push(thisyear - 1);
  timelinedata.push("Year-to-Date");

  let QuaterSDate = new Date();
  QuaterSDate.setDate(1);
  QuaterSDate.setMonth(0);
  QuaterSDate.setHours(0, 0, 1, 0);
  let meetSDate: any = moment(QuaterSDate);
  let meetEDate: any = moment(thisdate);

  const [clientReinvestDate, setclientReinvestDate] = useState({
    startdate: moment(QuaterSDate).format("DD-MMM-YYYY"),
    enddate: moment(thisdate).format("DD-MMM-YYYY"),
    value: curquatyear,
  });

  function timelinechange(timeline, sectionName) {
    if (timeline) {
      let startDate = new Date();
      let endDate: Date;
      if (
        timeline === thisyear - 3 ||
        timeline === thisyear - 2 ||
        timeline === thisyear - 1
      ) {
        let tempsdate = new Date();
        tempsdate.setDate(1);
        tempsdate.setMonth(0);
        tempsdate.setFullYear(timeline);
        let tempedate = new Date();
        tempedate.setDate(31);
        tempedate.setMonth(11);
        tempedate.setFullYear(timeline);
        // if(timeline === (thisyear - 1))
        // {
        //     tempedate.setFullYear(thisyear-1);
        // }
        // else if(timeline === (thisyear - 2))
        // {
        //     tempedate.setFullYear(thisyear-2);
        // }
        // else if(timeline === (thisyear - 3))
        // {
        //     tempedate.setFullYear(thisyear-3);
        // }
        meetSDate = moment(tempsdate).format("DD-MMM-YYYY");
        meetEDate = moment(tempedate).format("DD-MMM-YYYY");

        if (sectionName === "Client Reinvestment") {
          setclientReinvestDate({
            startdate: meetSDate,
            enddate: meetEDate,
            value: timeline,
          });
        }
      } else if (timeline === "Last 30 days") {
        let tempsdate: any = new Date();
        tempsdate.setDate(tempsdate.getDate() - 30);
        let tempedate = new Date();
        meetSDate = moment(tempsdate).format("DD-MMM-YYYY");
        meetEDate = moment(tempedate).format("DD-MMM-YYYY");
        if (sectionName === "Client Reinvestment") {
          setclientReinvestDate({
            startdate: meetSDate,
            enddate: meetEDate,
            value: timeline,
          });
          //setcurquatyear(timeline) ;
        }
      } else if (timeline === "Year-to-Date") {
        let tempsdate = new Date();
        tempsdate.setDate(1);
        tempsdate.setMonth(0);
        let tempedate = new Date();
        meetSDate = moment(tempsdate).format("DD-MMM-YYYY");
        meetEDate = moment(tempedate).format("DD-MMM-YYYY");
        if (sectionName === "Client Reinvestment") {
          setclientReinvestDate({
            startdate: meetSDate,
            enddate: meetEDate,
            value: timeline,
          });
        }
      } else if (timeline === "Last 12 Months") {
        let tempsdate: any = new Date();
        tempsdate.setFullYear(tempsdate.getFullYear() - 1);
        let tempedate = new Date();
        meetSDate = moment(tempsdate).format("DD-MMM-YYYY");
        meetEDate = moment(tempedate).format("DD-MMM-YYYY");
        if (sectionName === "Client Reinvestment") {
          setclientReinvestDate({
            startdate: meetSDate,
            enddate: meetEDate,
            value: timeline,
          });
          //setcurquatyear(timeline) ;
        }
      } else if (
        timeline.includes("Q1") ||
        timeline.includes("Q2") ||
        timeline.includes("Q3") ||
        timeline.includes("Q4")
      ) {
        let tempsdate = new Date();
        let tempedate = new Date();
        if (timeline.includes("Q1")) {
          if (thismonth <= 2) {
            tempsdate.setDate(1);
            tempsdate.setMonth(0);
          } else {
            tempsdate.setDate(1);
            tempsdate.setMonth(0);

            tempedate.setDate(31);
            tempedate.setMonth(2);
          }
          meetSDate = moment(tempsdate).format("DD-MMM-YYYY");
          meetEDate = moment(tempedate).format("DD-MMM-YYYY");
        } else if (timeline.includes("Q2")) {
          if (thismonth > 2 && thismonth <= 5) {
            tempsdate.setDate(1);
            tempsdate.setMonth(3);
          } else {
            tempsdate.setDate(1);
            tempsdate.setMonth(3);
            tempedate.setDate(30);
            tempedate.setMonth(5);
          }
          meetSDate = moment(tempsdate).format("DD-MMM-YYYY");
          meetEDate = moment(tempedate).format("DD-MMM-YYYY");
        } else if (timeline.includes("Q3")) {
          if (thismonth > 5 && thismonth <= 8) {
            tempsdate.setDate(1);
            tempsdate.setMonth(6);
          } else {
            tempsdate.setDate(1);
            tempsdate.setMonth(6);
            tempedate.setDate(30);
            tempedate.setMonth(8);
          }
          meetSDate = moment(tempsdate).format("DD-MMM-YYYY");
          meetEDate = moment(tempedate).format("DD-MMM-YYYY");
        } else if (timeline.includes("Q4")) {
          if (thismonth > 8 && thismonth <= 11) {
            tempsdate.setDate(1);
            tempsdate.setMonth(9);
          } else {
            tempsdate.setDate(1);
            tempsdate.setMonth(9);
            tempedate.setDate(31);
            tempedate.setMonth(11);
          }
          meetSDate = moment(tempsdate).format("DD-MMM-YYYY");
          meetEDate = moment(tempedate).format("DD-MMM-YYYY");
        }
        if (sectionName === "Client Reinvestment") {
          setclientReinvestDate({
            startdate: meetSDate,
            enddate: meetEDate,
            value: timeline,
          });
          //setcurquatyear(timeline) ;
        }
      } else if (timeline === "All Time") {
        //01-MAR-1990
        let tempsdate = new Date();
        let tempedate = new Date();
        tempsdate.setDate(1);
        tempsdate.setMonth(2);
        tempsdate.setFullYear(1990);
        meetSDate = moment(tempsdate).format("DD-MMM-YYYY");
        meetEDate = moment(tempedate).format("DD-MMM-YYYY");
        if (sectionName === "Client Reinvestment") {
          setclientReinvestDate({
            startdate: meetSDate,
            enddate: meetEDate,
            value: timeline,
          });
          //setcurquatyear(timeline) ;
        }
      } else if (timeline === "Next Year") {
        let tempsdate = new Date();
        tempsdate.setDate(1);
        tempsdate.setMonth(0);
        tempsdate.setFullYear(tempsdate.getFullYear() + 1);
        let tempedate = new Date();
        tempedate.setDate(31);
        tempedate.setMonth(11);
        tempedate.setFullYear(tempsdate.getFullYear() + 1);
        meetSDate = moment(tempsdate).format("DD-MMM-YYYY");
        meetEDate = moment(tempedate).format("DD-MMM-YYYY");
        if (sectionName === "Client Reinvestment") {
          setclientReinvestDate({
            startdate: meetSDate,
            enddate: meetEDate,
            value: timeline,
          });
          //setcurquatyear(timeline) ;
        }
      }
    }
  }
  const decrypt = (decryptVlaue) => {
    let passphrase = "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9"
    const bytes = CryptoJS.AES.decrypt(decryptVlaue, passphrase);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  };

  const getUserEmail = async () => {
    const ret: any = await Storage.get({ key: "userId" })
    const temp = JSON.parse(decrypt(ret.value))
    return temp && temp.email ? temp.email : null
  }




  const [referral,setreferralarr] = useState<any>([]);
  const [count,setcount] = useState<number>(0);
  let temprefarr:any = [];
  useEffect(() => {
    //api.getDashboardStats('47735531','01-MAR-1990','01-MAR-2021').then(resp => {
        //setLoading(true);
        getUserEmail().then((useremail) => {
            
              api.getClientDashboardReinvestmentDetail("s.shehadeh@tfoco.com",clientReinvestDate.startdate,clientReinvestDate.enddate).then(resp =>{
                //api.getClientDashboardReinvestmentDetail("s.shehadeh@tfoco.com","01-Jan-2021","01-Feb-2021").then(resp =>{
                // console.log(resp);
                if(resp.length > 0){
                  let payload: any = {
                    inputs: [],
                    properties: [
                      "first_name_arabic,arabic_last_name,arabic_middle_name,hs_avatar_filemanager_key,middle_name",
                    ],
                  };

                 resp.forEach((opt,index) => {                   
                    if(opt.contactId)  
                    {
                      payload.inputs.push({ id: opt.contactId })
                    }
                  });
                // payload.inputs.push({ id: "251" })
                // payload.inputs.push({ id: "301" })
                // payload.inputs.push({ id: "18501" })

                  // console.log(payload)
                  
                  api.getTopTargetContacts(payload).then((response) => {
                      // console.log(response.results);                      
                        if(response.results.length > 0)
                        {
                          resp.map((res, key) => {   
                          let mstcharr = response.results.filter(
                            (a) => parseInt(res.contactId)=== parseInt(a.id)
                            )[0].properties;
                        
                          let ptg = (parseInt(res.totalIncomeDistribution)-parseInt(res.totalReinvestmentAmount))/parseInt(res.totalIncomeDistribution)
                          

                          temprefarr.push({
                            engName: mstcharr.middle_name !== "" && mstcharr.middle_name !== null ? `${res.firstName} ${mstcharr.middle_name} ${res.lastName}` : `${res.firstName} ${res.lastName}`,
                             avatarpath:mstcharr.hs_avatar_filemanager_key,
                             arabicname:mstcharr.arabic_middle_name !== "" && mstcharr.arabic_middle_name !== null ? `${mstcharr.first_name_arabic} ${mstcharr.arabic_middle_name} ${mstcharr.arabic_last_name}` : `${mstcharr.first_name_arabic} ${mstcharr.arabic_last_name}`,
                             id:res.contactId,
                             amount:res.reinvestmentAmount,
                             percent:ptg.toString() === "NaN" ? "-" : ptg === 0 ? 0 : ptg.toFixed(1)
                           })
                        })
                      }
                          
                          setreferralarr(temprefarr);
                  })
                  
                 }
            })
        })
    },[clientReinvestDate])

    const history = useHistory()

    function sendToProfile(id): void {
      //console.log(id);
      history.push("/contactinfo/"+id);      
    }

  const { height, width } = useWindowDimensions();
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode="md" style={styles.toolbar}>
          <IonButtons slot="start">
            <IonButton
              style={{ textTransform: "none" }}
              color="white"
              // routerLink="/dashboard"
              onClick={() => {
                hist.push(`/dashboard`, { to: "clients" });
              }}
            >
              <IonIcon icon={chevronBack}></IonIcon>
              Client Dashboard
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent style={{ "--background": "none" }}>
        <IonGrid class="ml-13 mr-13">
          <IonRow>
            <IonCol
              class="grayscale4 mobile-reim-title"
              style={{ fontWeight: 300, fontSize: 28,  marginleft :16 , marginTop:16}}
              size-xs="8"
              size-sm="9"
             
            >
              Client Reinvestment
            </IonCol>
            <IonCol size-xs="4"
              size-sm="3" class="ion-float-right">
              <IonItem lines="none" style={{ "--padding-start": "0px" }}>
                <IonSelect
                  interfaceOptions={options}
                  onIonChange={(e) => {
                    timelinechange(e.detail.value, clientreinvest);
                  }}
                  style={styles.select}
                  name="salutation"
                  mode="ios"
                  class="customIonSelect"
                  value={clientReinvestDate.value}
                  placeholder="Select Salutation"
                  interface={width < 576 ? "action-sheet" : "popover"}
                >
                  {timelinedata.map((val) => (
                    <IonSelectOption key={val} value={val}>
                      {val}
                    </IonSelectOption>
                  ))}
                </IonSelect>
                <IonIcon color="gold" icon={chevronDown} />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              {referral.map((referral, key) => (
                <IonItem
                  className="CardIonItem"
                  style={styles.item}
                  lines="none"
                  key={key}
                >
                  <IonAvatar className="asocAvatar" slot="start">
                    <img
                      src={
                        referral.avatarpath !== "" &&
                        referral.avatarpath !== null
                          ? `${imgbaseuri}${referral.avatarpath}`
                          : "/assets/empty-avatar.svg"
                      }
                      alt=""
                    />
                  </IonAvatar>
                  <IonLabel style={styles.label}>
                    <IonRow>
                      <IonCol size-xs="12"
              size-sm="9">
                        <IonText style={styles.title}>
                          {referral.engName}{" "}
                        </IonText>
                        <IonIcon style={styles.period} icon={ellipse}></IonIcon>
                        <IonText style={styles.desc}>
                          {" "}
                          {referral.arabicname}{" "}
                        </IonText>
                        <IonIcon style={styles.period} icon={ellipse}></IonIcon>
                        <IonText style={styles.desc}> {referral.id}</IonText>
                      </IonCol>
                      <IonCol 
              size-sm="3"></IonCol>
                    </IonRow>
                    <IonRow>
                      <IonText style={styles.datetime}>
                        Reinvestment Amount: {retExpClsAmt(referral.amount)}({referral.percent}%)
                      </IonText>
                    </IonRow>
                  </IonLabel>
                  <IonText
                    style={{ cursor: "pointer" }}
                    class="font14 primarygold"
                    onClick={(event) => {
                      sendToProfile(referral.id)            
                        }}
                  >
                    View Profile
                  </IonText>
                </IonItem>
              ))}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ClientReinvestment;
