import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonBackButton,
  IonButtons,
  IonPage,
  IonText,
  IonRow,
  IonCol,
  IonLabel,
  IonIcon,
  IonButton,
  IonLoading,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonGrid,
} from "@ionic/react";
// import "./NewNote.css";
import edit from "src/icons/pen-edit.svg";
import CryptoJS from 'crypto-js';
import { chevronBack, chevronDown, add, close, person } from "ionicons/icons";
import api from "src/api";
import { RouteComponentProps } from "react-router";
import moment from "moment";
import calendargold from "src/icons/calendargold.svg";
import CustDatePicker from "src/components/CustDatePicker/CustDatePicker";
import { Plugins } from "@capacitor/core";
import ShowMoreText from 'react-show-more-text';
import "./ViewEvent.css";
const { Storage } = Plugins;


// import * as Yup from "yup";

const styles = {
  toolbar: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
  },
  headerRow: {
    marginTop: 24,
    marginLeft: 32,
  },
  arbHeaderRow: {
    marginTop: 8,
    marginLeft: 32,
  },
  engName: {
    fontWeight: 300,
    fontSize: 28,
    lineHeight: "120%",
    color: "#828282",
  },
  arbName: {
    fontWeight: 400,
    fontSize: 18,
    lineHeight: "120%",
    color: "#828282",
    fontFamily: "Almarai",
  },
  formHeader: {
    fontWeight: 400,
    fontSize: 22,
    lineHeight: "120%",
    color: "#AAAAAA",
  },
  formHeaderSmall: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "120%",
    color: "#AAAAAA",
    marginTop: 10,
    marginLeft: 5,
  },
  fieldLabel: {
    fontWeight: 400,
    fontSize: 17,
    color: "#FFFFFF",
  },
  optionLabel: {
    fontWeight: 400,
    fontSize: 14,
    color: "#C7C7C7",
  },
  optionItem: {
    marginLeft: -16,
    marginBottom: -12,
  },
  checkIcon: {
    "--background-checked": "#A5D2DC",
    "--checkmark-color": "#222222",
    "--checkmark-width": 3,
    "--border-color-checked": "#A5D2DC",
    height: 20,
    width: 20,
    marginInlineEnd: 8,
  },
  radioIcon: {
    "--color-checked": "#A5D2DC",
    marginInlineEnd: 8,
  },
  dateItem: {
    "--background": "#222222",
    marginBottom: 2,
    borderRadius: 0,
    overflow: "visible",
    "--padding-start": "8px",
  },
  inputItem: {
    "--background": "#222222",
    marginBottom: 2,
    width: "100%",
    marginTop: 8,
    // marginLeft: 35,
  },
  textAreaRow: {
    marginTop: 8,
    marginLeft: 32,
    marginRight: 32,
  },
  textArea: {
    "--background": "#222222",
    height: 102,
    paddingLeft: 10,
    color: "#828282",
    fontWeight: 400,
    fontSize: 14,
  },
  acordHeader: {
    color: "#B99855",
    fontWeight: 400,
    fontSize: 17,
    lineHeight: "120%",
  },
  addIcon: {
    color: "#B99855",
    size: 16,
    marginRight: 5,
  },
  dropdown: {
    marginTop: 5,
    width: "90%",
    "--background": "#222222",
    marginBottom: 2,
    borderRadius: 8,
  },
  textbox: {
    marginTop: 5,
    width: "90%",
    "--background": "#222222",
    marginBottom: 24,
    borderRadius: 0,
    "--padding-start": "15px",
  },
};


const optionsA = {
  cssClass: "my-custom-interface",
};

interface ViewEventPageProps extends RouteComponentProps<{
  id: string;
}> { }


const ViewEvent: React.FC<ViewEventPageProps> = (
  {
    match,
    location,
    history
  }
) => {
  const currDate: any = new Date();
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [eventInfo, setEventInfo] = useState<any>({});
  const [engInfo, setengInfo] = useState<any>({});
  const [contacts, setcontacts] = useState<Array<any>>([])
  const [backText, setBacktext] = useState<string>("Calendar")
  const [disableEdit, setdisableEdit] = React.useState<boolean>(false);
  useEffect(
    () => {
      if (location.state) {
        let a: any = location.state;
        var regex = /(<([^>]+)>)/ig;
        a.body = a.body ? a.body.replace(regex, ""): a.body;
        setEventInfo(a);
        // console.log(a);

        if(a.from) {
          setBacktext(a.from === "Prospectprofile" ? "Profile" : "Calendar")
          setdisableEdit(a.from === "Prospectprofile" ? true : false)
        }
        let contArr = a.name.split(",")
        setcontacts(contArr)

        
        // let contactPayload: any = {
        //   "inputs": [],
        //   "properties": ["firstname,lastname,middle_name,first_name_arabic,arabic_last_name,arabic_middle_name,hs_avatar_filemanager_key"]
        // };
        // // console.log("event",a);
        // if (a.title) {
        //   api.GetEngagement(a.engId).then((resp) => {
        //     setengInfo(resp)

        //     resp.associations.contactIds.forEach((contid) => {
        //       contactPayload.inputs.push({
        //         id: contid
        //       })
        //     })

        //     api.getTopTargetContacts(contactPayload).then((batchresp) => {
        //       let tempcont: Array<any> = []
        //       // console.log(batchresp)
        //       batchresp.results.forEach(c => {
        //         let tid = c.id
        //         const {
        //           firstname,
        //           lastname,
        //           middle_name
        //         } = c.properties
        //         tempcont.push({
        //           name: middle_name !== "" && middle_name !== null ? `${firstname} ${middle_name} ${lastname}` : `${firstname} ${lastname}`,
        //           id: tid
        //         })
        //       })
        //       setcontacts(tempcont)
        //     })
        //   })
        // }
      }
    }, []);

    const timePickerOptions: any = [
      {val: "0:00 AM",fVal:"0:00:00"},
      {val: "0:30 AM",fVal:"0:30:00"},
      {val: "1:00 AM",fVal:"1:00:00"},
      {val: "1:30 AM",fVal:"1:30:00"},
      {val: "2:00 AM",fVal:"2:00:00"},
      {val: "2:30 AM",fVal:"2:30:00"},
      {val: "3:00 AM",fVal:"3:00:00"},
      {val: "3:30 AM",fVal:"3:30:00"},
      {val: "4:00 AM",fVal:"4:00:00"},
      {val: "4:30 AM",fVal:"4:30:00"},
      {val: "5:00 AM",fVal:"5:00:00"},
      {val: "5:30 AM",fVal:"5:30:00"},
      {val: "6:00 AM",fVal:"6:00:00"},
      {val: "6:30 AM",fVal:"6:30:00"},
      { val: "7:00 AM",fVal:"7:00:00"},
      { val: "7:30 AM",fVal:"7:30:00"},
      { val: "8:00 AM",fVal:"8:00:00"},
      { val: "8:30 AM",fVal:"8:30:00" },
      { val: "9:00 AM",fVal:"9:00:00" },
      { val: "9:30 AM",fVal:"9:30:00" },
      { val: "10:00 AM",fVal:"10:00:00"},
      { val: "10:30 AM",fVal:"10:30:00"},
      { val: "11:00 AM",fVal:"11:00:00"},
      { val: "11:30 AM" ,fVal:"11:30:00" },
      { val: "12:00 PM",fVal:"12:00:00" },
      { val: "12:30 PM",fVal:"12:30:00" },
      { val: "1:00 PM",fVal:"13:00:00" },
      { val: "1:30 PM",fVal:"13:30:00" },
      { val: "2:00 PM",fVal:"14:00:00" },
      { val: "2:30 PM",fVal:"14:30:00" },
      { val: "3:00 PM",fVal:"15:00:00" },
      { val: "3:30 PM",fVal:"15:30:00" },
      { val: "4:00 PM",fVal:"16:00:00" },
      { val: "4:30 PM",fVal:"16:30:00" },
      { val: "5:00 PM",fVal:"17:00:00" },
      { val: "5:30 PM",fVal:"17:30:00" },
      { val: "6:00 PM",fVal:"18:00:00" },
      { val: "6:30 PM",fVal:"18:30:00" },
      { val: "7:00 PM",fVal:"19:00:00" },
      { val: "7:30 PM",fVal:"19:30:00" },
      { val: "8:00 PM",fVal:"20:00:00" },
      { val: "8:30 PM",fVal:"20:30:00" },
      { val: "9:00 PM",fVal:"21:00:00" },
      { val: "9:30 PM",fVal:"21:30:00" },
      { val: "10:00 PM",fVal:"22:00:00" },
      { val: "10:30 PM",fVal:"22:30:00" },
      { val: "11:00 PM",fVal:"23:00:00" },
      { val: "11:30 PM",fVal:"23:30:00" },
      ];
  


  const [dueDateVal, setDueDateVal] = useState<any>(currDate);

  const decrypt = (decryptVlaue) => {
    let passphrase = "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9"
    const bytes = CryptoJS.AES.decrypt(decryptVlaue, passphrase);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  };

  const getUserId = async () => {
    const ret: any = await Storage.get({ key: "userId" });
    const temp = JSON.parse(decrypt(ret.value));
    return temp.id;
  };

  return (

    <IonPage>
      <IonLoading
        cssClass="my-custom-loading-class"
        isOpen={isLoading}
        message={"Please wait..."}
      />
      <IonHeader>
        <IonToolbar mode="md" style={styles.toolbar}>
          
          <IonButtons slot="start">
            <IonButton
                type="reset"
                fill="clear"
                color="white"
                style={{}}
                onClick={() => history.goBack()}
                // style={​​​​{​​​​
                //   fontSize: 12,
                //   border: "none",
                //   marginLeft: 32,
                // }​​​​}​​​​
                //   color="#ffffff"
                >
                <IonIcon color="white" icon={chevronBack}></IonIcon>
                <IonText class="ion-text-capitalize">
                  {backText}
                </IonText>
            </IonButton>
            {/* <IonBackButton
              style={{ textTransform: "none" }}
              color="white"
              text={backText}
              icon={chevronBack}
            ></IonBackButton> */}
          </IonButtons>
          {!disableEdit ? (<IonButtons slot="end">
            <IonButton
              // routerLink={`/editevent`}
              onClick={() => {
                history.push("/editevent", { eventInfo, engInfo, contacts })
              }}
              mode="ios" color="gold" fill="clear" slot="end"><IonIcon color="white" size="small" icon={edit} slot="start" ></IonIcon>Edit</IonButton>
          </IonButtons>
          ):null}
        </IonToolbar>
      </IonHeader>
      <IonContent className="viewevent-content" >
        <IonRow style={styles.headerRow}>
          <IonCol>
            <IonText style={styles.engName}>{eventInfo.title}</IonText>
          </IonCol>
        </IonRow>
        <IonRow style={styles.headerRow}>
          <IonCol>
            <IonText style={styles.formHeader}>Event Information</IonText>
          </IonCol>
        </IonRow>
        <IonRow >
          <IonCol size-xs="12" size-sm="4" >
            <IonCard className="firstLeft_cal firstLeft_cal_desk" style={{ position: "relative" }}>
              <IonCardHeader color="white" style={{ color: "#fff", fontWeight: "300", fontSize: 14, paddingTop: 10, paddingBottom: 10, paddingLeft: 32 }}>Date</IonCardHeader>
              <IonCardSubtitle className="exp_sub" color="greyscale" style={{ paddingLeft: 32, textTransform: "none", fontWeight: "400", fontSize: 18, position: "absolute", textOverflow: "ellipsis" }}>{moment(eventInfo.start).format("ddd, MMM DD, YYYY")}</IonCardSubtitle>
            </IonCard>
          </IonCol>
          <IonCol size-xs="12" size-sm="4">
            <IonCard className="firstLeft_cal firstLeft_cal_mobile">
              <IonCardHeader color="white" style={{ color: "#fff", fontWeight: "300", fontSize: 14, paddingTop: 10, paddingBottom: 10, paddingLeft: 10 }}>Time</IonCardHeader>
              <IonCardSubtitle className="exp_sub" color="greyscale" style={{ paddingLeft: 10, textTransform: "none", fontWeight: "400", fontSize: 18, position: "absolute", textOverflow: "ellipsis" }}>{moment(eventInfo.start).format("H:mm:ss") + " - " + moment(eventInfo.end).format("H:mm:ss")}</IonCardSubtitle>
            </IonCard>
          </IonCol>
          <IonCol size-xs="12" size-sm="4">
            <IonCard className="firstLeftNr_cal firstLeft_cal_mobile">
              <IonCardHeader color="white" style={{ color: "#fff", fontWeight: "300", fontSize: 14, paddingTop: 10, paddingBottom: 0, paddingLeft: 10, whiteSpace: "nowrap" }}></IonCardHeader>
              <IonCardSubtitle className="exp_sub" color="greyscale" style={{ paddingLeft: 10, textTransform: "none", fontWeight: "400", fontSize: 18, position: "absolute", textOverflow: "ellipsis" }}>{ }</IonCardSubtitle>
            </IonCard>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size-xs="12" sizeSm="8" sizeLg="8" >
            {/* <IonRow style={styles.headerRow}>
                <IonCol sizeSm="8" sizeLg="8" style={{ paddingLeft: 0 }}>
                  <IonItem lines="none" style={styles.dateItem}>
                    <CustDatePicker
                    dateFormat="LL"
                      readonly
                      date={new Date()}
                      />
                    <IonIcon
                      style={{ width: 24, height: 24 }}
                      src={calendargold}
                      slot="end"
                    ></IonIcon>
                    </IonItem>
                </IonCol>
                <IonCol sizeSm="4" sizeLg="4" className="nxttimepickerCol">
                <IonItem lines="none" style={styles.dateItem}>
                    <IonSelect
                      interface="popover"
                      name="dueTimeStart"
                      mode="ios"
                    >
                    {timePickerOptions.map(({ val }, i) => (
                        <IonSelectOption key={val} value={val}>
                          {val}
                        </IonSelectOption>
                        ))}
                        </IonSelect>
                        <IonIcon slot="end" color="gold" src={chevronDown}></IonIcon>
                        </IonItem>
                        </IonCol>
                      </IonRow> */}

            {
              engInfo.metadata && engInfo.metadata.reminders && engInfo.metadata.reminders.length > 0 ?
                <IonRow className="view-event-row-desktop" style={styles.headerRow}>
                  <IonLabel style={styles.fieldLabel}>* Email Reminder</IonLabel>
                </IonRow> : null
            }

            <IonCol size-xs="12" size-sm="4">
              {engInfo.metadata && engInfo.metadata.reminders && engInfo.metadata.reminders.length > 0 ?
                <IonCard className="firstLeft_cal firstLeft_cal_desk" style={{ position: "relative" }}>
                  <IonCardHeader color="white" style={{ color: "#fff", fontWeight: "300", fontSize: 14, paddingTop: 10, paddingBottom: 10, paddingLeft: 32 }}>Email Reminder</IonCardHeader>
                  <IonCardSubtitle className="exp_sub" color="greyscale" style={{ paddingLeft: 32, textTransform: "none", fontWeight: "400", fontSize: 18, position: "absolute", textOverflow: "ellipsis" }}>{moment(engInfo.metadata.reminders[0]).format("ddd, MMM DD, YYYY")}</IonCardSubtitle>
                </IonCard> : null
              }
            </IonCol>
            <IonRow className="view-event-row-desktop" style={styles.headerRow}>
              <IonLabel style={styles.fieldLabel}>Description</IonLabel>
            </IonRow>
            <IonRow className="view-event-row-desktop" style={styles.textAreaRow}>
              <IonText class="bioDesc ion-text-start">
                <ShowMoreText
                  /* Default options */
                  lines={1}
                  more='Read more...'
                  less='Read less'
                  className='content-css showmore'
                  anchorClass='my-anchor-css-class primarygold'
                  expanded={false}
                //width={100%}
                >
                  {eventInfo.body ? eventInfo.body : "-"}
                </ShowMoreText>
              </IonText>
            </IonRow>

          </IonCol>
          <IonCol sizeSm="4" sizeLg="4" >
            <IonRow style={styles.headerRow}>
              <IonLabel style={styles.fieldLabel}>Attendees</IonLabel>
            </IonRow>
            <IonRow style={styles.headerRow}>
              <IonText color="greyscale">Required Attendees</IonText>
            </IonRow>
            <IonGrid>
              {/* {contacts && contacts.length > 0 ?
                contacts.map((obj, i) =>
                  <IonRow key={i}>
                    <IonCol size="1" style={{"display": "grid","align-content": "center"}}>
                      <IonIcon color="gold" style={{ width: 24, height: 24 }} className="toptargeticon" icon={person}></IonIcon>
                      </IonCol>
                      <IonCol size="11">
                      <IonText class="addastoptrgt" mode="ios">{obj.name}</IonText>
                      </IonCol>   
                  </IonRow>
                ): null
              } */}
              {contacts && contacts.length > 0 ?
                contacts.map((obj, i) =>
                  <IonRow key={i} className="firstLeft_cal_mobile">
                    <IonCol size="1" style={{"display": "grid","alignContent": "center"}}>
                      <IonIcon color="gold" style={{ width: 20, height: 20 }} className="toptargeticon" icon={person}></IonIcon>
                      </IonCol>
                      <IonCol size="11">
                      <IonText class="addastoptrgt" mode="ios" style={{marginLeft:10 }}>{obj}</IonText>
                      </IonCol>   
                  </IonRow>
                ): null
              }
            </IonGrid>
          </IonCol>
        </IonRow>


       {/*  <IonRow>
          <IonCol sizeSm="8" sizeLg="8" sizeMd="8" className="nxttimepickerCol">
            <IonRow style={styles.headerRow}>
              <IonLabel style={styles.fieldLabel}>Description</IonLabel>
            </IonRow>
            <IonRow style={styles.textAreaRow}>
              <IonText class="bioDesc ion-text-start">
                <ShowMoreText
                  lines={3}
                  more='Read more...'
                  less='Read less'
                  className='content-css showmore'
                  anchorClass='my-anchor-css-class primarygold'
                  expanded={false}
                //width={100%}
                >
                  {eventInfo.body ? eventInfo.body : "-"}
                </ShowMoreText>
              </IonText>
            </IonRow>
          </IonCol>
        </IonRow> */}
      </IonContent>
    </IonPage>
  );
};

export default ViewEvent;
