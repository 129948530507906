import React, { useEffect } from "react";
import MainLayout from "src/layouts/MainLayout";
// import LoginLayout from "src/layouts/LoginLayout/LoginLayout";
import Dashboard from "src/pages/Dashboard/Dashboard";
import CryptoJS from "crypto-js";
import Login from "src/pages/Login/Login";
import AllProspects from "src/pages/Prospects/AllProspects";
import CreateProspect from "src/pages/Prospects/CreateProspect/CreateProspect";
import NewNote from "src/pages/MeetingNote/NewNote";
import Calendar from "src/pages/Calendar/Calendar";
import Portfolio from "src/pages/Portfolio/Portfolio";
import ToDo from "src/pages/ToDo/ToDo";
import NewTodo from "src/pages/ToDo/NewToDo/NewTodo";
import ProspectSearch from "src/pages/ProspectSearch/ProspectSearch";
import TopTargetSearch from "src/pages/Prospects/TopTargetSearch";
import ContactInfo from "src/pages/ContactInfo/ContactInfo";
import Geographies from "src/pages/Dashboard/Geographies/Geographies";
import CheckRedirect from "src/pages/Auth/CheckRedirect";
import TopTargets from "src/pages/Prospects/TopTargets";
import MeetingNoteDetails from "src/pages/ContactInfo/MeetingNoteDetails/MeetingNoteDetails";
import NewEvent from "src/pages/Calendar/NewEvent/NewEvent";
//import AttendeeSearch from "src/pages/Calendar/NewEvent/AttendeeSearch"
import ClientReinvestment from "src/pages/Dashboard/Prospects/ClientReinvestment";
import { Route, Redirect, useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import NewAssociation from "src/pages/ContactInfo/Associations/NewAssociation";
import Associations from "src/pages/ContactInfo/Associations/NewAssociation";
import ViewEvent from "src/pages/Calendar/ViewEvent";
import Settings from "src/pages/Settings";
import ViewTodo from "src/pages/ToDo/ViewTodo/ViewTodo";
import ProspectReferral from "src/pages/Dashboard/Prospects/ProspectReferral";
import WeeklyVideoSeeMore from "src/pages/Material/WeeklyVideoSeeMore/WeeklyVideoSeeMore";
import Material from "src/pages/Material/Material";
import WeeklyUpdateVideo from "src/pages/Material/WeeklyVideoSeeMore/WeeklyUpdateVideo";
import InvestmentUpdate from "src/pages/Material/InvestmentUpdate/InvestmentUpdate";
import InvestmentProductDetails from "src/pages/Material/InvestmentUpdate/InvestmentProductDetails";
import DigitalProductUpdate from "src/pages/Material/DigitalProductUpdate/DigitalProductUpdate";
import DigitalProductDetails from "src/pages/Material/DigitalProductUpdate/DigitalProductDetails";
import { useIdleTimer } from "react-idle-timer";
import { captureFirebaseEvent } from "./firebaseEvents";
import DealAnalysis from "src/pages/Dashboard/Deals/DealAnalysis/DealAnalysis";
import { Plugins } from "@capacitor/core";
const { Storage } = Plugins;
const AuthenticatedRoutes: React.FC = ({ children }) => {
  const decrypt = (decryptVlaue) => {
    let passphrase =
      "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9";
    const bytes = CryptoJS.AES.decrypt(decryptVlaue, passphrase);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  };

  const getUserId = async () => {
    const ret: any = await Storage.get({ key: "userId" });
    const temp = JSON.parse(ret?.value ? decrypt(ret?.value) : ret?.value);
    return temp && temp.id ? temp.id : null;
  };

  const getCognitoId = async () => {
    const ret: any = await Storage.get({ key: "cognito" });
    const temp = JSON.parse(ret?.value);
    return temp && temp.id_token ? temp.id_token : null;
  };

  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      getCognitoId().then((cognitoToken) => {
        if (!(cognitoToken && cognitoToken !== null && cognitoToken !== "")) {
          history.push("/login");
        } else {
          getUserId().then((userId) => {
            if (!(userId && userId !== null && userId !== "")) {
              history.push("/login");
            }
          });
        }
      });
    }, 2000);
  }, [history]);
  return <>{children}</>;
};

const CustomRoutes = ({ getPath }) => {
  const loc = useLocation();
  const hist = useHistory();
  const captureScreenshotEvent = (event) => {
    // console.log("loc",loc);
    captureFirebaseEvent(event, loc);
  };

  useEffect(() => {
    getPath(loc.pathname);
    // loc.pathname === "/login/authenticated" ?  hist.push("/dashboard") : hist.push("/login")
    document.addEventListener("screenshot", captureScreenshotEvent, false);
    return () => {
      document.removeEventListener("screenshot", captureScreenshotEvent, true);
      document.removeEventListener("screenshot", captureScreenshotEvent, false);
    };
  }, [loc.pathname]);

  const setObject = async () => {
    await Storage.clear();
  };

  const handleOnIdle = (event) => {
    // console.log("user is idle", event);
    // console.log("last active", getLastActiveTime());
    setObject().then((o) => {
      hist.push("/login");
    });
    //   hist.push("/login");
  };

  const handleOnActive = (event) => {
    // console.log("user is active", event);
    // console.log("time remaining", getRemainingTime());
  };

  const handleOnAction = (event) => {
    // console.log('user did something', event)
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 30, // time in milliseconds
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });

  return (
    <React.Fragment>
      <Route path="/" component={MainLayout}>
        {/* <Route path="/app/authenticated*" component={Dashboard} /> */}
        {/* <Route path="/" render={() => (<Redirect to="/dashboard" />)} /> */}
        {/* <Route path="/" component={Dashboard} /> */}

        <AuthenticatedRoutes>
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/prospects/all" component={AllProspects} />
          <Route path="/prospects/targets" component={AllProspects} />
          <Route path="/prospect/create" component={CreateProspect} />
          <Route path="/prospect/edit" edit={true} component={CreateProspect} />
          <Route path="/note/create" component={NewNote} />
          <Route path="/calendar" component={Calendar} />
          <Route path="/portfolio" component={Portfolio} />
          <Route path="/todo" component={ToDo} />
          <Route path="/create/todo" component={NewTodo} />
          {/* <Route path="/create/todo/:contactId" component={NewTodo} /> */}
          <Route path="/prospects/search" component={ProspectSearch} />
          <Route
            path="/prospects/toptargetsearch"
            component={TopTargetSearch}
          />
          {/* <Route path="/newevent/attendeesearch" component={AttendeeSearch} /> */}
          <Route path="/contactinfo/:id" component={ContactInfo} />
          <Route path="/geographies" component={Geographies} />
          <Route path="/toptargets" component={TopTargets} />
          <Route path="/login" component={Login} />
          <Route path="/newevent" component={NewEvent} />
          <Route path="/editevent" component={NewEvent} />
          <Route path="/weeklyVideoSeeMore" component={WeeklyVideoSeeMore} />
          <Route
            path="/digitalProductDetails/:id"
            component={DigitalProductDetails}
          />
          <Route
            path="/investmentProductDetails/:id"
            component={InvestmentProductDetails}
          />
          <Route path="/material" component={Material} />
          <Route path="/weeklyUpdateVideo/:id" component={WeeklyUpdateVideo} />
          <Route path="/investmentUpdate" component={InvestmentUpdate} />
          <Route
            path="/digitalProductUpdate"
            component={DigitalProductUpdate}
          />
          <Route path="/dealAnalysis/:id" component={DealAnalysis} />
          <Route
            path="/"
            render={() => {
              return loc.pathname === "/" ? <Redirect to={"/login"} /> : null;
            }}
          />
          {/* <Route
            path="/meetingnotedetails/:contactId/:meetingnoteId"
            component={MeetingNoteDetails}
          /> */}
          <Route path="/meetingnotedetails" component={MeetingNoteDetails} />
          <Route
            exact
            path="/meetingnotedetailsedit"
            component={MeetingNoteDetails}
          />
          <Route path="/association/create" component={NewAssociation} />
          <Route path="/viewevent" component={ViewEvent} />
          <Route path="/settings" component={Settings} />
          <Route path="/view/todo" component={ViewTodo} />
          <Route path="/edit/todo" component={NewTodo} />
          <Route path="/clients/all" component={AllProspects} />
          <Route path="/clientreinvestment" component={ClientReinvestment} />
          <Route path="/ProspectReferral" component={ProspectReferral} />
          <Route path="/client/edit" edit={true} component={CreateProspect} />
          <Route exact path="/association/edit" component={Associations} />
        </AuthenticatedRoutes>
      </Route>
      <Route path="/auth">
        <Route path="/" component={CheckRedirect} />
      </Route>
    </React.Fragment>
  );
};
export default CustomRoutes;
