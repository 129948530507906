import React from 'react';
import { IonPopover, IonList, IonItem } from '@ionic/react';
import { useHistory } from "react-router-dom";
import { Plugins } from "@capacitor/core";
const { Storage } = Plugins
// import "./Popup.css"
const styles = {
    list: {
        padding: 0,
    },
    item: {
        "--background:": "#1a1a1a"
    }
}

interface MenuProps {
    open: boolean;
    event: Event | undefined;
    setShowPopover: Function
}
const setObject = async () => {
    // await Storage.set({
    //     key: "userId",
    //     value: JSON.stringify({})
    // })
    await Storage.clear()
}

const ProfPopover: React.FC<MenuProps> = ({
    open,
    event,
    setShowPopover,
}) => {

    const hist = useHistory()

    return (
        <IonPopover
            isOpen={open}
            showBackdrop={true}
            cssClass='popover-class new-popover-class'
            event={event}
            onDidDismiss={e => setShowPopover({ open: false, event: undefined })}
            mode="ios"
        >
            <IonList style={styles.list}>
            <IonItem lines="none" style={styles.item} button detail={false} routerLink="/settings"
                >Settings</IonItem>
                <IonItem lines="none" style={styles.item} button detail={false}
                    onClick={() => {
                        setObject().then(o => {
                            hist.push('/login')
                        })
                    }}
                >Logout</IonItem>
                {/* <IonItem lines="full" style={styles.item}>New To-Do</IonItem> */}
                {/* <IonItem lines="full" style={styles.item}>New Document</IonItem> */}
                {/* <IonItem lines="none" style={styles.item}>New Association</IonItem> */}
            </IonList>
        </IonPopover>
    );
}
export default ProfPopover