
import React, { useEffect, useState } from 'react';
import {
  IonAvatar,
  // IonButton,
  // IonCard,
  IonCol,
  IonContent,IonGrid,IonHeader,IonIcon,IonImg,IonItem,IonLabel,IonPage,IonRange,IonRow,IonText, IonToolbar,
} from '@ionic/react';
import './Portfolio.css';
import PieChart from './PieChart';
import Segments from 'src/components/Segments/Segments';
import useWindowDimensions from "src/components/useWindowDimensions";
import { search } from "ionicons/icons";
import { useHistory } from 'react-router';
import { Plugins } from "@capacitor/core";
// import ProtofolioSlider from './ProtofolioSlider';
import { style } from 'd3';
import PortfolioChart from './PortfolioChart';
import ProfPopover from 'src/components/ProfileModal/ProfPopover';
const {   Device } = Plugins;

const styles = {
  headertile: {
    fontWeight: 300,
     fontSize: 28 ,
     marginTop:30,
     marginLeft:32,
     
},
text: {
  fontsize:12,
  color: "#AAAAAA",
  marginLeft:32,
  marginRight:50,
  marginTop:20,
},
valamt:{
fontsize:53,
fontweight:300,
},
toprowmargin:{
  marginBottom:4,
  fontsize:14,
},
rowmargin:{
  marginBottom:50,
  fontsize:18,
  color:"#AAAAAA"
},
segtextrow: {
  marginRight:100,
  marginLeft:150,
  marginTop:20,
},
};

const segs = [
  {
    label: "Liquid Credit",
    value: "Liquidcredit",
  },
  {
    label: "Semi Liquid Credit",
    value: "Semiliquidcredit",
  },
  {
    label: "Balanced",
    value: "Balanced",
  },
  {
    label: "Growth",
    value: "Growth",
  },
];
const retComp = (segVal) => {
  switch (segVal) {
    case "Liquidcredit":
      return (
        <IonText
        class="segseltext"
        style ={styles.text}>
        The liquid credit portfolio offers a completely liquid, low to moderate risk, income-generating solution for investors. It is suitable for very low-risk investors who seek stable income, minimal exposure to volatility, and optionality to redeem the full investment amount. The liquid credit portfolio is fit for investors with any investment horizon.
        </IonText>
      );
    case "Semiliquidcredit":
      return(
        <IonText
        class="segseltext"
        style ={styles.text}>
          The semi-liquid credit portfolio offers a semi-liquid solution through a diversified portfolio of liquid and illiquid investments. The portfolio aims to provide investors with enhanced yield and returns by complementing the income-generating liquid opportunities with illiquid yield generating investment opportunities. Suitable for investors who seek enhanced yield, moderate exposure to volatility, and optionality to redeem half of the investment amount.
        </IonText>
      );
    case "Balanced":
      return(
        <IonText
        class="segseltext"
        style ={styles.text}>
          The balanced portfolio offers a truly diversified investment opportunity through a wide array of investment programs and funds The Family Office provides. The portfolio aims to generate superior total returns and moderate yield while still offering partial liquidity. It is suitable for investors who seek capital gains, moderate to high-risk appetite, and with longer time horizons.
        </IonText>
      );
    case "Growth":
      return(
        <IonText
        class="segseltext"
        style ={styles.text}>
          The growth portfolio offers a unique opportunity to invest in a diverse selection of capital growth programs and funds The Family Office provides. The portfolio aims to generate superior capital gains in the medium to long term. It is suitable for investors with a high-risk appetite and who seek to achieve high growth for their investment portfolio.
        </IonText>
      );
    }
  }

  // const [deviceInfo, setdeviceInfo] = useState<any>();
  const decimalFunc = (Num) => {
    let temp = Num.toString().split(".");
    let retVal = 0;
    if (temp[1]) {
      retVal = Num.toFixed(1);
    } else {
      retVal = Num;
    }
    return retVal;
  };
  const retExpClsAmt = (clsAmt) => {
    let closingamt_disp: number = 0;
    Number.isNaN(clsAmt) || clsAmt === null || clsAmt === ""
      ? (closingamt_disp = 0)
      : (closingamt_disp = parseInt(clsAmt));
    return Math.abs(Number(closingamt_disp)) >= 1.0e9
      ? decimalFunc(Math.abs(Number(closingamt_disp)) / 1.0e9) + "b"
      : // Six Zeroes for Millions
      Math.abs(Number(closingamt_disp)) >= 1.0e6
      ? decimalFunc(Math.abs(Number(closingamt_disp)) / 1.0e6) + "m"
      : // Three Zeroes for Thousands
      Math.abs(Number(closingamt_disp)) >= 1.0e3
      ? decimalFunc(Math.abs(Number(closingamt_disp)) / 1.0e3) + "k"
      : decimalFunc(Math.abs(Number(closingamt_disp)));
  };

  interface ProfPopup {
    open: boolean;
    event: Event | undefined;
  }

  interface ProjReturnsIntf {
    id?:number,
    year?: number;
    actReturn?: number;
    yield?:number;
  }
  interface ReinvestImptInt {
    id?:number;
    year?: number;
    retNonInvested?: number;
    retInvested?:number;
    retInvestedDiff?:number;
  }

const Portfolio: React.FC = ({}) => {
  const [selSeg, setselSeg] = useState<any>("Liquidcredit");
  // const { height, width } = useWindowDimensions();
  const { height, width } = useWindowDimensions();
  const [deviceInfo, setdeviceInfo] = useState<any>();
  const [principleAmt, setPrincipleAmt] = useState(500000);
  const [income,setIncome] = useState<Number>(5.5);
  const [expTotAnnRev,setExpTotAnnRev] = useState<Number>(8.5);
  const [qtrLiquidity,setQtrLiquidity] = useState<Number>(0);
  const [avgHoldYrs,setAvgHoldYrs] = useState<Number>(4);

  const [projectedReturns,setProjectedReturns] = useState<ProjReturnsIntf[]>();
  const [impOfReinvestment, setImpOfReinvestment ] = useState<ReinvestImptInt[]>();

  const history = useHistory();
  const triggerSearch = () => {
    history.push("/prospects/search");
  };
  const [ProfPopovermenu, setProfPopovermenu] = useState<ProfPopup>({
    open: false,
    event: undefined,
  });
  function iOS() {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
    }

    useEffect(() => {
      Device.getInfo().then(info => {
          setdeviceInfo(info)
          //console.log(info);
      })
    }, [])

    useEffect(() =>{

      switch(selSeg){
        case 'Liquidcredit':
          setIncome(3);
          setExpTotAnnRev(4.7);
          setQtrLiquidity(100);
          setAvgHoldYrs(0);
          break;

        case 'Semiliquidcredit':
          setIncome(5.5);
          setExpTotAnnRev(8.5);
          setQtrLiquidity(0);
          setAvgHoldYrs(4);
          break;

        case 'Balanced':
          setIncome(3.6);
          setExpTotAnnRev(7.4);
          setQtrLiquidity(30);
          setAvgHoldYrs(3.5);
          break;

        case 'Growth':
          setIncome(0);
          setExpTotAnnRev(14.3);
          setQtrLiquidity(0);
          setAvgHoldYrs(4.4);
          break;

        default:
          break;
      };

      setPrincipleAmt(500000);
      
    },[selSeg])
    
    const calcProjectedReturns = ( projYears:number, pAmt:number ) => {
      const currentYear = new Date().getFullYear() as number;
      var projReturns: ProjReturnsIntf[] = [];

      for(var i = 0; i <= projYears ; i++){
        if(i === 0){
          projReturns.push({
            id:i,
            year: currentYear+i,
            actReturn: Number(pAmt),
            yield: 0
          });
        }else{
          let actReturnAmt = projReturns[i-1]?.actReturn ? (Number(projReturns[i-1].actReturn)) * (1 + Number(( Number(expTotAnnRev) - Number(income)) * 0.01 ))  : 0;
          let yieldAmt = projReturns[i-1]?.actReturn ? Number(projReturns[i-1]?.actReturn) * Number(income) * Number(0.01) : 0;

          projReturns.push({
            id:i,
            year: currentYear+i,
            actReturn: actReturnAmt,
            yield: yieldAmt
          });
        }
      }
      return projReturns;
    }
  const calcReinvestmentImpact = ( projYears:number, pAmt:number) => {

    const currentYear = new Date().getFullYear() as number;
    var impReinvest: ReinvestImptInt[] = [];

    for(var i = 0; i <= projYears ; i++){
      if(i === 0){
        impReinvest.push({
          id:i,
          year: currentYear+i,
          retNonInvested: Number(pAmt),
          retInvested: Number(pAmt),
          retInvestedDiff: 0
        })

      }else{
        let retNonInvested =  impReinvest[i-1]?.retNonInvested ? (Number(impReinvest[i-1].retNonInvested)) * (1 + Number(( Number(expTotAnnRev) - Number(income)) * 0.01 ))  : 0;
        let retInvested = impReinvest[i-1]?.retInvested ? Number(impReinvest[i-1]?.retInvested) * (1+ Number(Number(expTotAnnRev) * 0.01) )  : 0 ;
        
        impReinvest.push({
          id:i,
          year: currentYear+i,
          retNonInvested: retNonInvested,
          retInvested: retInvested,
          retInvestedDiff: (Number(retInvested) - Number(retNonInvested))
        });
      }
    }
    return impReinvest;
  }

  const calcInvestment = (pAmt) => {
    const projReturns = calcProjectedReturns(5,pAmt);
    const reinvImpact = calcReinvestmentImpact(5,pAmt);

    // console.log("principleAmt:",pAmt);
    // console.log("income:",income);
    // console.log("expTotAnnRev:",expTotAnnRev);
    // console.log("qtrLiquidity:",qtrLiquidity);
    // console.log("avgHoldYrs:",avgHoldYrs);
    // console.log("projReturns",projReturns);
    // console.log("reinvImpact",reinvImpact);
    setProjectedReturns(projReturns);
    setImpOfReinvestment(reinvImpact);

  }
      
  return (
    <IonPage>
     <IonHeader>
        <IonToolbar>
          {iOS() === true ? (
            <IonGrid className="rootgrid margintopn10">
              <IonRow>
                <IonCol>
                  <IonImg
                    className="ion-text-start"
                    src="/assets/brand_logo.svg"
                    style={{ width: 75 }}
                  />
                </IonCol>
                <IonCol>
                  <IonGrid className="ion-float-right">
                    <IonRow>
                      <IonIcon
                        color="white"
                        icon={search}
                        size="small"
                        onClick={triggerSearch}
                        className="trig-srch"
                      ></IonIcon>
                      <IonText
                        className="srchplaceholder ion-margin-horizontal trig-srch"
                        onClick={triggerSearch}
                      >
                        {width > 575
                          ? "Search for any prospect or client"
                          : "Search"}
                      </IonText>
                      <IonAvatar
                        onClick={(e) => {
                          setProfPopovermenu({
                            open: true,
                            event: e.nativeEvent,
                          });
                        }}
                        style={{ width: 24, height: 24 }}
                      >
                        <img src="/assets/empty-avatar.svg" alt="" />
                      </IonAvatar>
                    </IonRow>
                  </IonGrid>
                </IonCol>
              </IonRow>
            </IonGrid>
          ) : (
            <IonGrid className="rootgrid">
              <IonRow>
                <IonCol>
                  <IonImg
                    className="ion-text-start"
                    src="/assets/brand_logo.svg"
                    style={{ width: 75 }}
                  />
                </IonCol>
                <IonCol>
                  <IonGrid className="searchCss ion-float-right">
                    <IonRow>
                      <IonIcon
                        color="white"
                        icon={search}
                        size="small"
                        onClick={triggerSearch}
                        className="trig-srch"
                      ></IonIcon>
                      <IonText
                        className="srchplaceholder ion-margin-horizontal trig-srch"
                        onClick={triggerSearch}
                      >
                        {width > 575
                          ? "Search for any prospect or client"
                          : "Search"}
                      </IonText>

                      <IonAvatar
                        onClick={(e) => {
                          setProfPopovermenu({
                            open: true,
                            event: e.nativeEvent,
                          });
                        }}
                        style={{ width: 24, height: 24 }}
                      >
                        <img src="/assets/empty-avatar.svg" alt="" />
                      </IonAvatar>
                    </IonRow>
                  </IonGrid>
                </IonCol>
              </IonRow>
            </IonGrid>
          )}
        </IonToolbar>
      </IonHeader>

    <IonContent 
    style={{ "--background": "none" }}> 
      <IonRow 
            class="grayscale4 mobile-reim-title fs-28 ml-13 mr-13"
            style={styles.headertile}
            size-xs="8"
            size-sm="9"
          >
            Portfolio Simulator
        
        </IonRow>
        <IonRow>
            <Segments 
                  buttons={segs}
                  startbtn={selSeg}
                  selectedValue={setselSeg}
                />
          </IonRow>
          <IonRow
          style ={styles.segtextrow}
          >
          {retComp(selSeg)}
          </IonRow>
          <IonGrid class="ml-13 mr-13"
          style={{"margin-left": "32px"}} >
          <IonRow>
            <PieChart/>
          </IonRow>
        
    

{deviceInfo && deviceInfo.platform === "web" && width > 1024 ?
(
      <IonGrid 
          style={{"margin-left":"71%","margin-top":"65px"}}>
            <IonRow style={styles.toprowmargin}> Income </IonRow>
            <IonRow style={styles.rowmargin}>{income} %</IonRow>
            <IonRow style={styles.toprowmargin}>Excepted Total Return</IonRow>
            <IonRow style={styles.rowmargin}>{expTotAnnRev}%</IonRow>
            <IonRow style={styles.toprowmargin}>Quaterly Liquidity</IonRow>
            <IonRow style={styles.rowmargin}>{qtrLiquidity}%</IonRow>
            <IonRow style={styles.toprowmargin}>Avg. Holding Period</IonRow>
            <IonRow style={styles.rowmargin}>{avgHoldYrs === 0 ? "Open" :  avgHoldYrs +" Years"}</IonRow>
          </IonGrid>
): (
          <IonGrid  style={{"margin-top":"481px","margin-bottom":"-127px"}}>
        <IonRow>
        <IonCol size='3'>
          <IonRow style={styles.toprowmargin}>Income</IonRow>
          <IonRow style={styles.rowmargin}>{income} %</IonRow>
        </IonCol>
        <IonCol size='3'>
          <IonRow style={styles.toprowmargin}>Excepted Total Return</IonRow>
          <IonRow style={styles.rowmargin}>{expTotAnnRev}%</IonRow>
        </IonCol>
        <IonCol size='3'>
          <IonRow style={styles.toprowmargin}>Quaterly Liquidity</IonRow>
          <IonRow style={styles.rowmargin}>{qtrLiquidity}% </IonRow>
        </IonCol>
        <IonCol size='3'>
          <IonRow style={styles.toprowmargin}>Avg. Holding Period</IonRow>
          <IonRow style={styles.rowmargin}>{avgHoldYrs === 0 ? "Open" :  avgHoldYrs +" Years"}</IonRow>
        </IonCol>
        </IonRow>
      </IonGrid> 
)
}


       <IonItem style={{"margin-top":"5%"}}>
         </IonItem>   

      <IonGrid
      style={{"margin-top":"3%"}}
      >
      <IonRow 
      class="fs-18"
      style={{"font-weight":"400","font-size":"18px"}}>
         Principle Investment
     </IonRow>
     <IonRow>
         <IonCol 
         className="colValue">
           <IonLabel class ="fs-53"
           style={{"font-weight":"300","font-size":"53px"}}>${retExpClsAmt(principleAmt)}</IonLabel></IonCol>
         <IonCol
         className ="sliderposition">
         <IonRange
            className={"rangeval"}
             min={500000} 
             max={10000000} color="greyscale" 
             step={500000}
             value={principleAmt}
             onIonChange={ e => {
               setPrincipleAmt(e.detail.value as number);
               calcInvestment(e.detail.value as number);
              }}
             >
           </IonRange>  
           <IonRow>
             <IonCol 
             style={{"margin-top":"-3.7%"}}
             >
              <IonLabel  style={{"font-size":"18px","color":"#828282","font-weight":"400","position":"relative","left":"4%"}}>$500K</IonLabel>
              </IonCol>
              <IonCol class="sildervalleft"
              style={{"margin-top":"-3.7%"}}
              >
              <IonLabel style={{"font-size":"18px","color":"#828282","font-weight":"400","position":"relative","left":"185%"}}>$10m</IonLabel>
              </IonCol >
            
            </IonRow>
         </IonCol>
        </IonRow>
        </IonGrid>
        </IonGrid>
      <IonRow>
        {/* <IonCol 
        size-xs="12"
        size-sm="6"
        size-lg="6"
        > */}

        {/* </IonCol>
        <IonCol
        size-xs="12"
        size-sm="6"
        size-lg="6"
        ></IonCol> */}

      <PortfolioChart 
        projReturns={projectedReturns} 
        impReinvestment={impOfReinvestment}
        
      />
      </IonRow>
    </IonContent>
    <ProfPopover
          open={ProfPopovermenu.open}
          event={ProfPopovermenu.event}
          setShowPopover={() =>
            setProfPopovermenu({ open: false, event: undefined })
          }
        />
    </IonPage>
  
);
};
export default Portfolio;