import React, { CSSProperties, RefObject, useEffect } from 'react'
import * as d3 from 'd3'
import './Line.css'
import { max } from 'd3'
import moment from 'moment'

// const width = 500
// const height = 350
const margin = 20
// const data = [
//     { a: 1, b: 3 },
//     { a: 2, b: 6 },
//     { a: 3, b: 2 },
//     { a: 4, b: 12 },
//     { a: 5, b: 8 }
// ]
// const data: [number, number][] = [
//     [0, 3],
//     [1, 3],
//     [2, 6],
//     [3, 6],
//     [4, 7],
//     [5, 7],
//     [6, 10],
//     [7, 10],
//     [8, 8],
//     [9, 8],
//     [10, 7],
//     [11, 6],
//     [12, 4],
//     [13, 3]
// ];




interface stylesI {
    root: CSSProperties;
    svg: CSSProperties;
    path: CSSProperties;
    pathA: CSSProperties;
    axis: CSSProperties;
    gridline: CSSProperties;
    axistext: CSSProperties;
    gridlineTemp: CSSProperties;
    area: CSSProperties;
}

const styles: stylesI = {
    root: {
        position: "relative",
        width: "100%",
        height: "100%",
        overflowY: "hidden",
        overflowX: "scroll"
    },
    svg: {
        fill: "#fff",
        fillOpacity: 0.5,
        zIndex: 0,
        position: "absolute",
        left: 96,
        bottom: 0,
    },
    path: {
        stroke: "#72758E",
        strokeWidth: 3,
        fill: "none",
        filter: "url(#shadow2)",
    },
    pathA: {
        stroke: "#F7B198",
        strokeWidth: 3,
        fill: "none",
        filter: "url(#shadow2)",
    },
    axis: {
        stroke: "#fff"
    },
    gridline: {
        stroke: "rgb(255, 255, 255, 0.05)"
    },
    gridlineTemp: {
        opacity: 1,
        stroke: "#fff"
    },
    axistext: {
        fill: "#AAAAAA",
        fillOpacity: 0.9,
        fontSize: 12,
        textAnchor: "middle",
        fontWeight: "normal"
    },
    area: {
        fill: "linear-gradient(180deg, rgba(255, 255, 255, 0.2) 25.16%, rgba(255, 255, 255, 0) 143.4%)",
        fillOpacity: 0.2
    }
}

const retAmtforTicks = (clsAmt) => {
    let closingamt_disp: number = 0;
    (Number.isNaN(clsAmt))|| clsAmt === null || clsAmt === "" ? closingamt_disp = 0 : closingamt_disp = parseInt(clsAmt)
    return Math.abs(Number(closingamt_disp)) >= 1.0e+9

        ? Math.abs(Number(closingamt_disp)) / 1.0e+9 + "b"
        // Six Zeroes for Millions 
        : Math.abs(Number(closingamt_disp)) >= 1.0e+6

            ? Math.abs(Number(closingamt_disp)) / 1.0e+6 + "m"
            // Three Zeroes for Thousands
            : Math.abs(Number(closingamt_disp)) >= 1.0e+3

                ? Math.abs(Number(closingamt_disp)) / 1.0e+3 + "k"

                : Math.abs(Number(closingamt_disp));
}

interface LineChartProps {
    tf:string,
    Chartname:string,
    width: number;
    height: number;
    chartData: [number, number][][]
}

const GeneralManagedAUM: React.FC<LineChartProps> = ({
    tf,
    Chartname,
    width,
    height,
    chartData,
    
}) => {
    //console.log(Chartname);
    const refA: RefObject<SVGSVGElement> = React.createRef<SVGSVGElement>()

    
    const h = height - 2 * margin, w = width - 2 * margin;
    let monthsArr:any= [];/*"Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"];  */
    //console.log(moment(new Date()).subtract(1, "years").startOf("month").format("mm"));
   // console.log(moment(new Date()).subtract(1, "month").endOf("month").format("DD-MMM-YYYY"));
   let x:any;
                
    
              
        

        x = d3.scaleLinear()
        .domain([1, 12]) //domain: [min,max] of a
        .range([margin, w])

        var dateVar = new Date();
        var monthVar = dateVar.getMonth(); 
        if(tf.toString().includes("Year-to-Date"))
        {
          monthsArr = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
        ]
        //   monthsArr.splice(monthVar+1,12-monthVar);
          monthsArr.splice(monthVar+1,13-monthVar);
          chartData[0].splice(monthVar+1,13-monthVar);
            if(chartData[1])
            {
                chartData[1].splice(monthVar+1,13-monthVar);
            }
          //console.log(ActualData);
        }
        else
        {
            monthsArr = [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec"
                ]
        }
    //console.log(monthsArr);
    //number formatter
    const xFormat = d3.format("0.2")
    //x scale
    

    //    console.log(chartData);

    //y scale
    let tempforY: Array<number> 
    let temp2forY: Array<number>;
    tempforY = chartData[0].map(a => {
        return a[1]
    })
    temp2forY = chartData[0].map(a => {
        return a[1]
    })
    if(chartData[1]) {
        temp2forY = chartData[1].map(a => {
            return a[1]
        })
    }

   


    const y = d3.scaleLinear()
        .domain([0, Math.max(...tempforY, ...temp2forY)* 1.2]) // domain [0,max] of b (start from 0)
        .range([h, margin])

    const xG = d3.scaleLinear()
        .domain([0, 15]) //domain: [min,max] of a
        .range([0, 1200])

    const yG = d3.scaleLinear()
        .domain([0, Math.max(...tempforY, ...temp2forY)* 1.2]) // domain [0,max] of b (start from 0)
        .range([0, 1200])


    //line generator: each point is [x(d.a), y(d.b)] where d is a row in data
    // and x, y are scales (e.g. x(10) returns pixel value of 10 scaled by x)
    const lineA: Function = d3.line()
        .x(d => x(d[0]))
        .y(d => y(d[1]))
        // .curve(d3.curveCatmullRom.alpha(0.5)) //curve line
        .curve(d3.curveBasis) //curve line

    const areafill: Function = d3.area()
        .x(d => x(d[0]))
        .y0(h + 100)
        .y1(d => y(d[1]))
        // .curve(d3.curveCatmullRom.alpha(0.5)) //curve line
        .curve(d3.curveBasis) //curve line

    const xTicks = x.ticks(12).map((d, i) => (
        // x(d) > margin && x(d) < w ?
        <g key={i} transform={`translate(${x(d)},${h + margin})`}>
            <text style={styles.axistext}>{monthsArr[i]}</text>
            <line x1='0' x2='0' y1='0' y2='5' transform="translate(0,-20)" />
            {/* <line style={styles.gridline} className='gridline' y1='0' y2={margin - h} x1='0' x2='0' transform="translate(0,-25)" /> */}
        </g>
        // : null
    ))

    const yTicks = y.ticks(4).map((d, i) => (
        y(d) > 10 && y(d) < h ?
            <g key={i} transform={`translate(${margin + 30},${y(d)})`}>
                <text style={styles.axistext} x="-12" y="5">
                    {
                        // xFormat(d)
                        retAmtforTicks(d)
                    }
                    </text>
                {/* <line x1='0' x2='5' y1='0' y2='0' transform="translate(-5,0)" /> */}
                {/* <line style={styles.gridline} className='gridline' x1='0' x2={w - margin} y1='0' y2='0' transform="translate(-5,0)" /> */}

            </g>
            : null
    ))

    const xGridLines = xG.ticks(14).map((d, i) => (
        <g key={i} transform={`translate(${x(d)},${h + margin})`}>
            <line style={styles.gridline} className='gridline' y1={2400} y2={-2400} x1='0' x2='0' transform="translate(0,-25)" />
        </g>
    ))
    const yGridLines = yG.ticks().map((d, i) => (
        <g key={i} transform={`translate(${margin},${y(d)})`}>
            <line style={styles.gridline} className='gridline' x1={-2400} x2={2400} y1='0' y2='0' transform="translate(-5,0)" />
        </g>
    ))



    useEffect(() => {
        
            chartData.forEach((data, i) => {
                //area
                //  let p1:any = d3.select(refA.current).append('path')
                 d3.select(refA.current).append('path')
                     .attr('fill', 'linear-gradient(180deg, rgba(255, 255, 255, 0.2) 25.16%, rgba(255, 255, 255, 0) 143.4%)')
                     .attr('fill-opacity', '0.2')
                    .attr('d', areafill(data))
                
                    if(Chartname == "Managed AUM")
                    {
                        d3.select(refA.current).append('path')
                        //.attr('filter', 'url(./#shadow2)')
                        //.attr('filter','<feDropShadow dx="0" dy="10" stdDeviation="4.5" floodColor="black" />')
                        .attr('fill', 'none')
                        .attr('stroke', i > 0 ? '#BFC5F4' : '#5DA683')
                        .attr('stroke-width', 3)
                        //.attr("class","filterpath")                 
                        .attr('d', lineA(data))
                    }
                    else
                    {
                        d3.select(refA.current).append('path')
                        //.attr('filter', 'url(./#shadow2)')
                        //.attr('filter','<feDropShadow dx="0" dy="10" stdDeviation="4.5" floodColor="black" />')
                        .attr('fill', 'none')
                        .attr('stroke', i > 0 ? '#F7B198' : '#72758E')
                        .attr('stroke-width', 3)
                        //.attr("class","filterpath")                 
                        .attr('d', lineA(data))
                    }
                    //line
                //  let p2:any = d3.select(refA.current).append('path')
                    
                    
               // d3.select(p1._groups[0][0]).transition()
               // d3.select(p2._groups[0][0]).transition()
            })
        
    }, [])

    return (
        <div style={styles.root}>
            <svg width={width} height={height} className="axessvg" style={styles.svg} ref={refA}>
                <g className="axis-labels">
                    {xTicks}
                </g>
                <g className="axis-labels">
                    {yTicks}
                </g>
                <defs>
                    <filter id="shadow">
                        <feGaussianBlur result="coloredBlur" stdDeviation="3.5" />
                        <feMerge>
                            <feMergeNode in="coloredBlur" />
                            <feMergeNode in="SourceGraphic" />
                        </feMerge>
                    </filter>
                    <filter id="shadow2">
                        <feDropShadow dx="0" dy="10" stdDeviation="4.5"
                            floodColor="black" />
                    </filter>
                </defs>
                {/* {
                    chartData.map((data, i) =>
                        <React.Fragment>
                            <path d={areafill(data)} style={styles.area} />
                            <path d={lineA(data)} style={i > 0 ? styles.pathA : styles.path} />
                        </React.Fragment>
                    )
                } */}
            </svg>
            <svg width={width} height={height} className="labelssvg" style={{ position: "absolute", bottom: 0, left: 96 }}>
                <g className="axis-labels">
                    {xGridLines}
                </g>
                <g className="axis-labelsy">
                    {yGridLines}
                </g>
            </svg>
        </div>
    )
}

export default GeneralManagedAUM