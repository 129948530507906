
import {
  IonItem,
  IonText,
  IonIcon,
  IonGrid,
  IonRow,
  IonAvatar,
  IonLabel,
} from "@ionic/react";
import { ellipse, ellipsisHorizontal } from "ionicons/icons";
import api from "src/api";
// import file from "src/icons/file.svg";
import React, { useEffect, useState } from "react";
import "./Associations.css";
import { useHistory } from "react-router";
import EditAssocMenu from "./EditAssocPopover";

const imgbaseuri = "https://cdn2.hubspot.net/";

const styles = {
  grid: {
    marginLeft: 32,
    marginRight: 32,
    marginTop: 32,
  },
  item: {
    "--background": "#222222",
    marginBottom: 2,
    borderRadius: 8,
  },
  desc: {
    fontFamily: "Almarai",
    fontSize: 16,
    color: "#828282",
    marginBottom: 8,
  },
  datetime: {
    fontWeight: 300,
    fontSize: 16,
    color: "#828282",
  },
  period: {
    fontSize: 6,
    marginLeft: 4,
    marginRight: 4,
    marginTop: 8,
    color: "#828282",
  },
  label: {
    marginTop: 14.5,
    marginBottom: 14.5,
  },
  title: {
    fontWeight: "700",
  },
  filtericon: {
    marginTop: 4,
    marginBottom: 12,
    marginRight: 0,
  },
  filtericonItem: {
    paddingRight: 0,
  },
};

interface ShwMenuState {
  open: boolean;
  event: Event | undefined;
}

const Associations: React.FC<any> = (props) => {
  const [associationList, setAssociationList] = React.useState<any>([]);
  
  const hist = useHistory()
  function routetoProfile(contactId)
  {
    hist.push(`/contactinfo/${contactId}`, { to : "details" })
  }

  const [assocId, setAssocId] = useState<any>()
  const [srcProsId, setSrcProsId] = useState<any>()
  const [assocRef, setAssocRef] = useState<boolean>(false)

  const [showMenu, setShowMenu] = useState<ShwMenuState>({
    open: false,
    event: undefined,
  });

  const delRefresh = () => {
    setAssocRef(true)
  };

  useEffect(() => {
    api
      .getAssociations(props.contactId)
      .then((data) => {
        if (data.length > 0) {
          // console.log(data)
          let contactPayload: any = {
            inputs: [],
            properties: [
              "firstname,lastname,middle_name,first_name_arabic,arabic_last_name,arabic_middle_name,hs_avatar_filemanager_key",
            ],
          };
          data.forEach((referral) => {
            contactPayload.inputs.push({ id: referral.sourceProspectId });
          });
          // console.log(contactPayload)
          api.getTopTargetContacts(contactPayload).then((response) => {
            if (response && response.results && response.results.length > 0) {
              let results: Array<any> = response.results;
              // console.log(results)
              setAssociationList(
                data.map((dataIns) =>{
                    return {
                      engName: results.filter((a) => a.id === dataIns.sourceProspectId)[0]
                              ? results.filter((a) => a.id === dataIns.sourceProspectId)[0].properties.middle_name !== null 
                                && results.filter((a) => a.id === dataIns.sourceProspectId)[0].middle_name !== ""
                                      ? `${results.filter((a) => a.id === dataIns.sourceProspectId)[0].properties.firstname} ${results.filter((a) => a.id === dataIns.sourceProspectId)[0].properties.middle_name} ${results.filter((a) => a.id === dataIns.sourceProspectId)[0].properties.lastname}`
                                      : `${results.filter((a) => a.id === dataIns.sourceProspectId)[0].properties.firstname} ${results.filter((a) => a.id === dataIns.sourceProspectId)[0].properties.lastname}`
                              : "",

                      arabicname: results.filter((a) => a.id === dataIns.sourceProspectId)[0]
                                  ? results.filter((a) => a.id === dataIns.sourceProspectId)[0].properties.arabic_middle_name !== null 
                                    && results.filter((a) => a.id === dataIns.sourceProspectId)[0].arabic_middle_name !== ""
                                          ? `${results.filter((a) => a.id === dataIns.sourceProspectId)[0].properties.first_name_arabic} ${results.filter((a) => a.id === dataIns.sourceProspectId)[0].properties.arabic_middle_name} ${results.filter((a) => a.id === dataIns.sourceProspectId)[0].properties.arabic_last_name}`
                                          : `${results.filter((a) => a.id === dataIns.sourceProspectId)[0].properties.first_name_arabic} ${results.filter((a) => a.id === dataIns.sourceProspectId)[0].properties.arabic_last_name}`
                                  : "",
                      avatarpath: results.filter((a) => a.id === dataIns.sourceProspectId)[0]
                                  ? results.filter((a) => a.id === dataIns.sourceProspectId)[0].properties.hs_avatar_filemanager_key
                                  : "",
                      sourceProspectId: dataIns.sourceProspectId,
                      referralFlg: dataIns.referral,
                      relationShipType: dataIns.relationShipType,
                      rowId: dataIns.rowId
                    }
                })
              )
            }
          })
          .catch((e) => {
            console.log(JSON.stringify(e))
          })
        } else {
          setAssociationList(data);
        }
      })
      .catch((error) => {
        console.error("error in get association API", error);
      });
  }, [props.contactId, assocRef]);


  return (
    <IonGrid class="ml-13 mr-13" style={styles.grid}>
      {associationList.map((referral, key) => (
        <IonItem
          className="CardIonItem"
          style={styles.item}
          lines="none"
          key={key}
        >
          <IonAvatar className="asocAvatar" slot="start">
            <img
              src={
                referral.avatarpath !== "" && referral.avatarpath !== null
                  ? `${imgbaseuri}${referral.avatarpath}`
                  : "/assets/empty-avatar.svg"
              }
              alt=""
            />
          </IonAvatar>
          <IonLabel className="assocdata" style={styles.label}>
            <IonRow 
             onClick={() => {
              routetoProfile(referral.sourceProspectId);
           }}
           style={{cursor:"pointer"}}>
              <IonText class="fs-16 mfs-14" style={styles.title}>{referral.engName}</IonText>
              <IonIcon class="fs-6" style={styles.period} icon={ellipse}></IonIcon>
              <IonText class="fs-16 mfs-14" style={styles.desc}>{referral.arabicname}</IonText>
            </IonRow>
            <IonRow>
              <IonText class="mobile-breakline" style={styles.datetime}>
                Association: {referral.relationShipType}{" "}
                {referral.referralFlg ? "- Client referral" : null} -{" "}
                {referral.sourceProspectId}
              </IonText>
            </IonRow>
          </IonLabel>
          <IonIcon
                    color="white"
                    size="small"
                    icon={ellipsisHorizontal}
                    slot="end"
                    onClick={(e) => {
                      setShowMenu({ open: true, event: e.nativeEvent });
                      setAssocId(referral.rowId)
                      setSrcProsId(referral.sourceProspectId)
                    }}
                  ></IonIcon>
        </IonItem>
      ))}
      <EditAssocMenu
        open={showMenu.open}
        event={showMenu.event}
        setShowPopover={() => setShowMenu({ open: false, event: undefined })}
        assocId={assocId}
        contactId={props.contactId}
        srcProsId={srcProsId}
        delRefreshNotes={delRefresh}
      />
    </IonGrid>
  );
};

export default Associations;
