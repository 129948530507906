interface JSObj {
    id: number;
    active: boolean;
    completed: boolean;
    milestoneTitle: string;
    milestoneMsg: string;
    milestoneCompleteMsg: string;
    milestoneDetail: string
}

interface JSArr extends Array<JSObj> { }


export const retStagesArr = (HubStages: Array<any>) => {

    let retArr: JSArr = HubStages.map((stage, i) => {
        return {
            id: i + 1,
            completed: false,
            active: false,
            milestoneTitle: stage.label,
            milestoneDetail: returnSubtitle(stage.label),
            milestoneCompleteMsg: "Milestone achieved",
            milestoneMsg: i === 0 ? "Current milestone" : i === 1 ? "Next upcoming milestone" : "Future Milestone"
        }
    })

    return retArr


}

// const returnSubtitle = (label) => {
//     return label === "Lead" ?
//         "Book Meeting" : label === "MQL" ?
//             "Book Meeting" : label === "SQL" ?
//                 "Book Meeting" : label === "DFO Relevant Lead" ?
//                     "Book Meeting" : label === "Book Meeting" ?
//                         "Connect with Prospect" : label === "Reacted to RM" ?
//                             "Build rapport & trust" : label === "Warm Lead" ?
//                                 "Build rapport & trust" : label === "Hot Lead" ?
//                                     "Discuss offerings & services" : label === "RPQ" ?
//                                         "Answer questionnaire" : label === "Offer Sent" ?
//                                             "Read offer" : label === "Signed" ?
//                                                 "Sign offer" : "Existing Client Servicing"
// }

const returnSubtitle = (label) => {
    return label === "New Lead" ?
        "Book Meeting" : label === "Cleared Lead" ?
            "Book Meeting" : label === "SQL" ?
                "Book Meeting" : label === "Booked Meeting" ?
                    "Book Meeting" : label === "Meeting face 2 face" ?
                        "Connect with Prospect" : label === "RISK QUESTIONNAIRE ANSWERED" ?
                            "Build rapport & trust" : label === "OFFER SENT" ?
                                "Build rapport & trust" : label === "15 DAYS TO CLOSE" ?
                                    "Discuss offerings & services" : label === "30 DAYS TO CLOSE" ?
                                        "Answer questionnaire" : label === "60+ DAYS TO CLOSE" ?
                                            // "Read offer" : label === "COMMITTED CAPITAL" ?
                                                "Sign offer" : "COMMITTED CAPITAL"
}

export const getStageIndex = (Currstage:string, StagesArr:JSArr) => {
    let temp = StagesArr.filter(obj => Currstage === obj.milestoneTitle)
    return temp.length > 0 ?  temp[0].id : 0

}