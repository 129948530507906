const Nationality = [
    {
        "label": "Bahrain",
        "nat": "Bahraini",
        "code": "BH",
        "phone": "+973"
    },
    {
        "label": "Saudi Arabia",
        "nat": "Saudi Arabian",
        "code": "SA",
        "phone": "+966"
    },
    {
        "label": "United Arab Emirates",
        "nat": "Emirati",
        "code": "AE",
        "phone": "+971"
    },
    {
        "label": "Kuwait",
        "nat": "Kuwaiti",
        "code": "KW",
        "phone": "+965"
    },
    {
        "label": "United Kingdom",
        "nat": "British",
        "code": "GB",
        "phone": "+44"
    },
    {
        "label": "Qatar",
        "nat": "Qatari",
        "code": "QA",
        "phone": "+974"
    },
    {
        "label": "Oman",
        "nat": "Omani",
        "code": "OM",
        "phone": "+968"
    },
    {
        "label": "Afghanistan",
        "nat": "Afghan",
        "code": "AF",
        "phone": "+93"
    },
    {
        "label": "Albania",
        "nat": "Albanian",
        "code": "AL",
        "phone": "+355"
    },
    
    {
        "label": "Algeria",
        "nat": "Algerian",
        "code": "DZ",
        "phone": "+213"
    },
    {
        "label": "United States",
        "nat": "American",
        "code": "US",
        "phone": "+1"
    },
    {
        "label": "Andorra",
        "nat": "Andorran",
        "code": "AD",
        "phone": "+376"
    },
    {
        "label": "Angola",
        "nat": "Angolan",
        "code": "AO",
        "phone": "+244"
    },
    {
        "label": "Anguilla",
        "nat": "Anguillan",
        "code": "AI",
        "phone": "+1-264"
    },
    {
        "label": "Argentina",
        "nat": "Argentine",
        "code": "AR",
        "phone": "+54"
    },
    {
        "label": "Armenia",
        "nat": "Armenian",
        "code": "AM",
        "phone": "+374"
    },
    {
        "label": "Australia",
        "nat": "Australian",
        "code": "AU",
        "phone": "+61"
    },
    {
        "label": "Austria",
        "nat": "Austrian",
        "code": "AT",
        "phone": "+43"
    },
    {
        "label": "Azerbaijan",
        "nat": "Azerbaijani",
        "code": "AZ",
        "phone": "+994"
    },
    {
        "label": "Bahamas",
        "nat": "Bahamian",
        "code": "BS",
        "phone": "+1-242"
    },
    {
        "label": "Bangladesh",
        "nat": "Bangladeshi",
        "code": "BD",
        "phone": "+880"
    },
    {
        "label": "Barbados",
        "nat": "Barbadian",
        "code": "BB",
        "phone": "+1-246"
    },
    {
        "label": "Belarus",
        "nat": "Belarusian",
        "code": "BY",
        "phone": "+375"
    },
    {
        "label": "Belgium",
        "nat": "Belgian",
        "code": "BE",
        "phone": "+32"
    },
    {
        "label": "Belize",
        "nat": "Belizean",
        "code": "BZ",
        "phone": "+501"
    },
    {
        "label": "Benin",
        "nat": "Beninese",
        "code": "BJ",
        "phone": "+229"
    },
    {
        "label": "Bermuda",
        "nat": "Bermudian",
        "code": "BM",
        "phone": "+1-441"
    },
    {
        "label": "Bhutan",
        "nat": "Bhutanese",
        "code": "BT",
        "phone": "+975"
    },
    {
        "label": "Bolivia",
        "nat": "Bolivian",
        "code": "BO",
        "phone": "+591"
    },
    {
        "label": "Botswana",
        "nat": "Botswanan",
        "code": "BW",
        "phone": "+267"
    },
    {
        "label": "Brazil",
        "nat": "Brazilian",
        "code": "BR",
        "phone": "+55"
    },
    {
        "label": "British Virgin Islands",
        "nat": "British Virgin Islander",
        "code": "VG",
        "phone": "+1-284"
    },
    {
        "label": "Brunei Darussalam",
        "nat": "Bruneian",
        "code": "BN",
        "phone": "+673"
    },
    {
        "label": "Bulgaria",
        "nat": "Bulgarian",
        "code": "BG",
        "phone": "+359"
    },
    {
        "label": "Burkina Faso",
        "nat": "Burkinan",
        "code": "BF",
        "phone": "+226"
    },
    {
        "label": "Myanmar",
        "nat": "Burmese",
        "code": "MM",
        "phone": "+95"
    },
    {
        "label": "Burundi",
        "nat": "Burundian",
        "code": "BI",
        "phone": "+257"
    },
    {
        "label": "Cambodia",
        "nat": "Cambodian",
        "code": "KH",
        "phone": "+855"
    },
    {
        "label": "Cameroon",
        "nat": "Cameroonian",
        "code": "CM",
        "phone": "+237"
    },
    {
        "label": "Canada",
        "nat": "Canadian",
        "code": "CA",
        "phone": "+1"
    },
    {
        "label": "Cape Verde",
        "nat": "Cape Verdean",
        "code": "CV",
        "phone": "+238"
    },
    {
        "label": "Cayman Islands",
        "nat": "Cayman Islander",
        "code": "KY",
        "phone": "+1-345"
    },
    {
        "label": "Central African Republic",
        "nat": "Central African",
        "code": "CF",
        "phone": "+236"
    },
    {
        "label": "Chad",
        "nat": "Chadian",
        "code": "TD",
        "phone": "+235"
    },
    {
        "label": "Chile",
        "nat": "Chilean",
        "code": "CL",
        "phone": "+56"
    },
    {
        "label": "China",
        "nat": "Chinese",
        "code": "CN",
        "phone": "+86"
    },
    {
        "label": "Antigua and Barbuda",
        "nat": "Citizen of Antigua and Barbuda",
        "code": "AG",
        "phone": "+1-268"
    },
    {
        "label": "Bosnia and Herzegovina",
        "nat": "Citizen of Bosnia and Herzegovina",
        "code": "BA",
        "phone": "+387"
    },
    {
        "label": "Guinea-Bissau",
        "nat": "Citizen of Guinea-Bissau",
        "code": "GW",
        "phone": "+245"
    },
    {
        "label": "Kiribati",
        "nat": "Citizen of Kiribati",
        "code": "KI",
        "phone": "+686"
    },
    {
        "label": "Seychelles",
        "nat": "Citizen of Seychelles",
        "code": "SC",
        "phone": "+248"
    },
    {
        "label": "Dominican Republic",
        "nat": "Citizen of the Dominican Republic",
        "code": "DO",
        "phone": "+1-809"
    },
    {
        "label": "Vanuatu",
        "nat": "Citizen of Vanuatu",
        "code": "VU",
        "phone": "+678"
    },
    {
        "label": "Colombia",
        "nat": "Colombian",
        "code": "CO",
        "phone": "+57"
    },
    {
        "label": "Comoros",
        "nat": "Comoran",
        "code": "KM",
        "phone": "+269"
    },
    {
        "label": "Congo, Republic of the",
        "nat": "Congolese (Congo)",
        "code": "CG",
        "phone": "+242"
    },
    {
        "label": "Cook Islands",
        "nat": "Cook Islander",
        "code": "CK",
        "phone": "+682"
    },
    {
        "label": "Costa Rica",
        "nat": "Costa Rican",
        "code": "CR",
        "phone": "+506"
    },
    {
        "label": "Croatia",
        "nat": "Croatian",
        "code": "HR",
        "phone": "+385"
    },
    {
        "label": "Cuba",
        "nat": "Cuban",
        "code": "CU",
        "phone": "+53"
    },
    {
        "label": "Cyprus",
        "nat": "Cypriot",
        "code": "CY",
        "phone": "+357"
    },
    {
        "label": "Czech Republic",
        "nat": "Czech",
        "code": "CZ",
        "phone": "+420"
    },
    {
        "label": "Denmark",
        "nat": "Danish",
        "code": "DK",
        "phone": "+45"
    },
    {
        "label": "Djibouti",
        "nat": "Djiboutian",
        "code": "DJ",
        "phone": "+253"
    },
    {
        "label": "Dominica",
        "nat": "Dominican",
        "code": "DM",
        "phone": "+1-776"
    },
    {
        "label": "Netherlands",
        "nat": "Dutch",
        "code": "NL",
        "phone": "+31"
    },
    {
        "label": "Ecuador",
        "nat": "Ecuadorean",
        "code": "EC",
        "phone": "+593"
    },
    {
        "label": "Egypt",
        "nat": "Egyptian",
        "code": "EG",
        "phone": "+20"
    },
    {
        "label": "Equatorial Guinea",
        "nat": "Equatorial Guinean",
        "code": "GQ",
        "phone": "+240"
    },
    {
        "label": "Eritrea",
        "nat": "Eritrean",
        "code": "ER",
        "phone": "+291"
    },
    {
        "label": "Estonia",
        "nat": "Estonian",
        "code": "EE",
        "phone": "+372"
    },
    {
        "label": "Ethiopia",
        "nat": "Ethiopian",
        "code": "ET",
        "phone": "+251"
    },
    {
        "label": "Faroe Islands",
        "nat": "Faroese",
        "code": "FO",
        "phone": "+298"
    },
    {
        "label": "Fiji",
        "nat": "Fijian",
        "code": "FJ",
        "phone": "+679"
    },
    {
        "label": "Philippines",
        "nat": "Filipino",
        "code": "PH",
        "phone": "+63"
    },
    {
        "label": "Finland",
        "nat": "Finnish",
        "code": "FI",
        "phone": "+358"
    },
    {
        "label": "France",
        "nat": "French",
        "code": "FR",
        "phone": "+33"
    },
    {
        "label": "Gabon",
        "nat": "Gabonese",
        "code": "GA",
        "phone": "+241"
    },
    {
        "label": "Gambia",
        "nat": "Gambian",
        "code": "GM",
        "phone": "+220"
    },
    {
        "label": "Georgia",
        "nat": "Georgian",
        "code": "GE",
        "phone": "+995"
    },
    {
        "label": "Germany",
        "nat": "German",
        "code": "DE",
        "phone": "+49"
    },
    {
        "label": "Ghana",
        "nat": "Ghanaian",
        "code": "GH",
        "phone": "+233"
    },
    {
        "label": "Gibraltar",
        "nat": "Gibraltarian",
        "code": "GI",
        "phone": "+350"
    },
    {
        "label": "Greece",
        "nat": "Greek",
        "code": "GR",
        "phone": "+30"
    },
    {
        "label": "Greenland",
        "nat": "Greenlandic",
        "code": "GL",
        "phone": "+299"
    },
    {
        "label": "Grenada",
        "nat": "Grenadian",
        "code": "GD",
        "phone": "+1-473"
    },
    {
        "label": "Guam",
        "nat": "Guamanian",
        "code": "GU",
        "phone": "+1-671"
    },
    {
        "label": "Guatemala",
        "nat": "Guatemalan",
        "code": "GT",
        "phone": "+502"
    },
    {
        "label": "Guinea",
        "nat": "Guinean",
        "code": "GN",
        "phone": "+224"
    },
    {
        "label": "Guyana",
        "nat": "Guyanese",
        "code": "GY",
        "phone": "+592"
    },
    {
        "label": "Haiti",
        "nat": "Haitian",
        "code": "HT",
        "phone": "+509"
    },
    {
        "label": "Honduras",
        "nat": "Honduran",
        "code": "HN",
        "phone": "+504"
    },
    {
        "label": "Hong Kong",
        "nat": "Hong Konger",
        "code": "HK",
        "phone": "+852"
    },
    {
        "label": "Hungary",
        "nat": "Hungarian",
        "code": "HU",
        "phone": "+36"
    },
    {
        "label": "Iceland",
        "nat": "Icelandic",
        "code": "IS",
        "phone": "+354"
    },
    {
        "label": "India",
        "nat": "Indian",
        "code": "IN",
        "phone": "+91"
    },
    {
        "label": "Indonesia",
        "nat": "Indonesian",
        "code": "ID",
        "phone": "+62"
    },
    {
        "label": "Iran, Islamic Republic of",
        "nat": "Iranian",
        "code": "IR",
        "phone": "+98"
    },
    {
        "label": "Iraq",
        "nat": "Iraqi",
        "code": "IQ",
        "phone": "+964"
    },
    {
        "label": "Ireland",
        "nat": "Irish",
        "code": "IE",
        "phone": "+353"
    },
    {
        "label": "Israel",
        "nat": "Israeli",
        "code": "IL",
        "phone": "+972"
    },
    {
        "label": "Italy",
        "nat": "Italian",
        "code": "IT",
        "phone": "+39"
    },
    {
        "label": "Jamaica",
        "nat": "Jamaican",
        "code": "JM",
        "phone": "+1-876"
    },
    {
        "label": "Japan",
        "nat": "Japanese",
        "code": "JP",
        "phone": "+81"
    },
    {
        "label": "Jordan",
        "nat": "Jordanian",
        "code": "JO",
        "phone": "+962"
    },
    {
        "label": "Kazakhstan",
        "nat": "Kazakh",
        "code": "KZ",
        "phone": "+7"
    },
    {
        "label": "Kenya",
        "nat": "Kenyan",
        "code": "KE",
        "phone": "+254"
    },
    {
        "label": "Kosovo",
        "nat": "Kosovan",
        "code": "XK",
        "phone": "+383"
    },
    {
        "label": "Kyrgyzstan",
        "nat": "Kyrgyz",
        "code": "KG",
        "phone": "+996"
    },
    {
        "label": "Lao People's Democratic Republic",
        "nat": "Lao",
        "code": "LA",
        "phone": "+856"
    },
    {
        "label": "Latvia",
        "nat": "Latvian",
        "code": "LV",
        "phone": "+371"
    },
    {
        "label": "Lebanon",
        "nat": "Lebanese",
        "code": "LB",
        "phone": "+961"
    },
    {
        "label": "Liberia",
        "nat": "Liberian",
        "code": "LR",
        "phone": "+231"
    },
    {
        "label": "Libya",
        "nat": "Libyan",
        "code": "LY",
        "phone": "+218"
    },
    {
        "label": "Liechtenstein",
        "nat": "Liechtenstein citizen",
        "code": "LI",
        "phone": "+423"
    },
    {
        "label": "Lithuania",
        "nat": "Lithuanian",
        "code": "LT",
        "phone": "+370"
    },
    {
        "label": "Luxembourg",
        "nat": "Luxembourger",
        "code": "LU",
        "phone": "+352"
    },
    {
        "label": "Macao",
        "nat": "Macanese",
        "code": "MO",
        "phone": "+853"
    },
    {
        "label": "Madagascar",
        "nat": "Malagasy",
        "code": "MG",
        "phone": "+261"
    },
    {
        "label": "Malawi",
        "nat": "Malawian",
        "code": "MW",
        "phone": "+265"
    },
    {
        "label": "Malaysia",
        "nat": "Malaysian",
        "code": "MY",
        "phone": "+60"
    },
    {
        "label": "Maldives",
        "nat": "Maldivian",
        "code": "MV",
        "phone": "+960"
    },
    {
        "label": "Mali",
        "nat": "Malian",
        "code": "ML",
        "phone": "+223"
    },
    {
        "label": "Malta",
        "nat": "Maltese",
        "code": "MT",
        "phone": "+356"
    },
    {
        "label": "Marshall Islands",
        "nat": "Marshallese",
        "code": "MH",
        "phone": "+692"
    },
    {
        "label": "Martinique",
        "nat": "Martiniquais",
        "code": "MQ",
        "phone": "+596"
    },
    {
        "label": "Mauritania",
        "nat": "Mauritanian",
        "code": "MR",
        "phone": "+222"
    },
    {
        "label": "Mauritius",
        "nat": "Mauritian",
        "code": "MU",
        "phone": "+230"
    },
    {
        "label": "Mexico",
        "nat": "Mexican",
        "code": "MX",
        "phone": "+52"
    },
    {
        "label": "Micronesia, Federated States of",
        "nat": "Micronesian",
        "code": "FM",
        "phone": "+691"
    },
    {
        "label": "Moldova, Republic of",
        "nat": "Moldovan",
        "code": "MD",
        "phone": "+373"
    },
    {
        "label": "Monaco",
        "nat": "Monegasque",
        "code": "MC",
        "phone": "+377"
    },
    {
        "label": "Mongolia",
        "nat": "Mongolian",
        "code": "MN",
        "phone": "+976"
    },
    {
        "label": "Montenegro",
        "nat": "Montenegrin",
        "code": "ME",
        "phone": "+382"
    },
    {
        "label": "Montserrat",
        "nat": "Montserratian",
        "code": "MS",
        "phone": "+1-664"
    },
    {
        "label": "Morocco",
        "nat": "Moroccan",
        "code": "MA",
        "phone": "+212"
    },
    {
        "label": "Mozambique",
        "nat": "Mozambican",
        "code": "MZ",
        "phone": "+258"
    },
    {
        "label": "Namibia",
        "nat": "Namibian",
        "code": "NA",
        "phone": "+264"
    },
    {
        "label": "Nauru",
        "nat": "Nauruan",
        "code": "NR",
        "phone": "+674"
    },
    {
        "label": "Nepal",
        "nat": "Nepalese",
        "code": "NP",
        "phone": "+977"
    },
    {
        "label": "New Zealand",
        "nat": "New Zealander",
        "code": "NZ",
        "phone": "+64"
    },
    {
        "label": "Nicaragua",
        "nat": "Nicaraguan",
        "code": "NI",
        "phone": "+505"
    },
    {
        "label": "Nigeria",
        "nat": "Nigerian",
        "code": "NG",
        "phone": "+234"
    },
    {
        "label": "Niger",
        "nat": "Nigerien",
        "code": "NE",
        "phone": "+227"
    },
    {
        "label": "Niue",
        "nat": "Niuean",
        "code": "NU",
        "phone": "+683"
    },
    {
        "label": "Norway",
        "nat": "Norwegian",
        "code": "NO",
        "phone": "+47"
    },
    {
        "label": "Pakistan",
        "nat": "Pakistani",
        "code": "PK",
        "phone": "+92"
    },
    {
        "label": "Palau",
        "nat": "Palauan",
        "code": "PW",
        "phone": "+680"
    },
    {
        "label": "Palestine, State of",
        "nat": "Palestinian",
        "code": "PS",
        "phone": "+970"
    },
    {
        "label": "Panama",
        "nat": "Panamanian",
        "code": "PA",
        "phone": "+507"
    },
    {
        "label": "Papua New Guinea",
        "nat": "Papua New Guinean",
        "code": "PG",
        "phone": "+675"
    },
    {
        "label": "Paraguay",
        "nat": "Paraguayan",
        "code": "PY",
        "phone": "+595"
    },
    {
        "label": "Peru",
        "nat": "Peruvian",
        "code": "PE",
        "phone": "+51"
    },
    {
        "label": "Pitcairn",
        "nat": "Pitcairn Islander",
        "code": "PN",
        "phone": "+870"
    },
    {
        "label": "Poland",
        "nat": "Polish",
        "code": "PL",
        "phone": "+48"
    },
    {
        "label": "Portugal",
        "nat": "Portuguese",
        "code": "PT",
        "phone": "+351"
    },
    {
        "label": "Puerto Rico",
        "nat": "Puerto Rican",
        "code": "PR",
        "phone": "+1"
    },
    {
        "label": "Romania",
        "nat": "Romanian",
        "code": "RO",
        "phone": "+40"
    },
    {
        "label": "Russian Federation",
        "nat": "Russian",
        "code": "RU",
        "phone": "+7"
    },
    {
        "label": "Rwanda",
        "nat": "Rwandan",
        "code": "RW",
        "phone": "+250"
    },
    {
        "label": "San Marino",
        "nat": "Sammarinese",
        "code": "SM",
        "phone": "+378"
    },
    {
        "label": "Samoa",
        "nat": "Samoan",
        "code": "WS",
        "phone": "+685"
    },
    {
        "label": "Sao Tome and Principe",
        "nat": "Sao Tomean",
        "code": "ST",
        "phone": "+239"
    },
    {
        "label": "Senegal",
        "nat": "Senegalese",
        "code": "SN",
        "phone": "+221"
    },
    {
        "label": "Serbia",
        "nat": "Serbian",
        "code": "RS",
        "phone": "+381"
    },
    {
        "label": "Sierra Leone",
        "nat": "Sierra Leonean",
        "code": "SL",
        "phone": "+232"
    },
    {
        "label": "Singapore",
        "nat": "Singaporean",
        "code": "SG",
        "phone": "+65"
    },
    {
        "label": "Slovakia",
        "nat": "Slovak",
        "code": "SK",
        "phone": "+421"
    },
    {
        "label": "Slovenia",
        "nat": "Slovenian",
        "code": "SI",
        "phone": "+386"
    },
    {
        "label": "Solomon Islands",
        "nat": "Solomon Islander",
        "code": "SB",
        "phone": "+677"
    },
    {
        "label": "Somalia",
        "nat": "Somali",
        "code": "SO",
        "phone": "+252"
    },
    {
        "label": "South Africa",
        "nat": "South African",
        "code": "ZA",
        "phone": "+27"
    },
    {
        "label": "Republic of Korea",
        "nat": "South Korean",
        "code": "KR",
        "phone": "+82"
    },
    {
        "label": "South Sudan",
        "nat": "South Sudanese",
        "code": "SS",
        "phone": "+211"
    },
    {
        "label": "Spain",
        "nat": "Spanish",
        "code": "ES",
        "phone": "+34"
    },
    {
        "label": "Sri Lanka",
        "nat": "Sri Lankan",
        "code": "LK",
        "phone": "+94"
    },
    {
        "label": "Saint Helena",
        "nat": "St Helenian",
        "code": "SH",
        "phone": "+290"
    },
    {
        "label": "Saint Lucia",
        "nat": "St Lucian",
        "code": "LC",
        "phone": "+1-758"
    },
    {
        "label": "Sudan",
        "nat": "Sudanese",
        "code": "SD",
        "phone": "+249"
    },
    {
        "label": "Suriname",
        "nat": "Surinamese",
        "code": "SR",
        "phone": "+597"
    },
    {
        "label": "Swaziland",
        "nat": "Swazi",
        "code": "SZ",
        "phone": "+268"
    },
    {
        "label": "Sweden",
        "nat": "Swedish",
        "code": "SE",
        "phone": "+46"
    },
    {
        "label": "Switzerland",
        "nat": "Swiss",
        "code": "CH",
        "phone": "+41"
    },
    {
        "label": "Syrian Arab Republic",
        "nat": "Syrian",
        "code": "SY",
        "phone": "+963"
    },
    {
        "label": "Taiwan, Province of China",
        "nat": "Taiwanese",
        "code": "TW",
        "phone": "+886"
    },
    {
        "label": "Tajikistan",
        "nat": "Tajik",
        "code": "TJ",
        "phone": "+992"
    },
    {
        "label": "United Republic of Tanzania",
        "nat": "Tanzanian",
        "code": "TZ",
        "phone": "+255"
    },
    {
        "label": "Thailand",
        "nat": "Thai",
        "code": "TH",
        "phone": "+66"
    },
    {
        "label": "Togo",
        "nat": "Togolese",
        "code": "TG",
        "phone": "+228"
    },
    {
        "label": "Tonga",
        "nat": "Tongan",
        "code": "TO",
        "phone": "+676"
    },
    {
        "label": "Trinidad and Tobago",
        "nat": "Trinidadian",
        "code": "TT",
        "phone": "+1-868"
    },
    {
        "label": "Tunisia",
        "nat": "Tunisian",
        "code": "TN",
        "phone": "+216"
    },
    {
        "label": "Turkey",
        "nat": "Turkish",
        "code": "TR",
        "phone": "+90"
    },
    {
        "label": "Turkmenistan",
        "nat": "Turkmen",
        "code": "TM",
        "phone": "+993"
    },
    {
        "label": "Turks and Caicos Islands",
        "nat": "Turks and Caicos Islander",
        "code": "TC",
        "phone": "+1-649"
    },
    {
        "label": "Tuvalu",
        "nat": "Tuvaluan",
        "code": "TV",
        "phone": "+688"
    },
    {
        "label": "Uganda",
        "nat": "Ugandan",
        "code": "UG",
        "phone": "+256"
    },
    {
        "label": "Ukraine",
        "nat": "Ukrainian",
        "code": "UA",
        "phone": "+380"
    },
    {
        "label": "Uruguay",
        "nat": "Uruguayan",
        "code": "UY",
        "phone": "+598"
    },
    {
        "label": "Uzbekistan",
        "nat": "Uzbek",
        "code": "UZ",
        "phone": "+998"
    },
    {
        "label": "Holy See (Vatican City State)",
        "nat": "Vatican citizen",
        "code": "VA",
        "phone": "+379"
    },
    {
        "label": "Venezuela",
        "nat": "Venezuelan",
        "code": "VE",
        "phone": "+58"
    },
    {
        "label": "Vietnam",
        "nat": "Vietnamese",
        "code": "VN",
        "phone": "+84"
    },
    {
        "label": "Wallis and Futuna",
        "nat": "Wallisian",
        "code": "WF",
        "phone": "+681"
    },
    {
        "label": "Yemen",
        "nat": "Yemeni",
        "code": "YE",
        "phone": "+967"
    },
    {
        "label": "Zambia",
        "nat": "Zambian",
        "code": "ZM",
        "phone": "+260"
    },
    {
        "label": "Zimbabwe",
        "nat": "Zimbabwean",
        "code": "ZW",
        "phone": "+263"
    },
 ];
 
 export default Nationality;
 