import React from "react";
import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
} from "@ionic/react";

import "./Card.css";
import moment from "moment";

type VideoCardProps = {
  imgSrc: string;
  descp: string;
  title: string;
  publishDate: string;
  duration: string;
};

function VideoCard({
  imgSrc,
  descp,
  title,
  publishDate,
  duration,
}: VideoCardProps) {
  return (
    <IonCard
      style={{
        marginLeft: 0,
        background: "transparent",
        boxShadow: "none",
      }}
    >
      <img
        alt="Card-media"
        src={
          imgSrc
            ? imgSrc
            : "https://ionicframework.com/docs/img/demos/card-media.png"
        }
      />
      <IonCardHeader style={{ paddingLeft: 0, paddingTop: 22 }}>
        <IonCardTitle className="cardTitles">{title} </IonCardTitle>
        <IonCardSubtitle className="cardSubTitles">{descp}</IonCardSubtitle>
        <IonCardSubtitle className="InvcardSubTitle">
          {moment(publishDate).format("DD/MM/YY")}, {duration} min
        </IonCardSubtitle>
      </IonCardHeader>
    </IonCard>
  );
}
export default VideoCard;
