import React, { CSSProperties, RefObject, useEffect, useState } from 'react'
import * as d3 from 'd3'
import './Line.css'
import { max } from 'd3'

// const width = 500
// const height = 350
const margin = 20
// const data = [
//     { a: 1, b: 3 },
//     { a: 2, b: 6 },
//     { a: 3, b: 2 },
//     { a: 4, b: 12 },
//     { a: 5, b: 8 }
// ]
// const data: [number, number][] = [
//     [0, 3],
//     [1, 3],
//     [2, 6],
//     [3, 6],
//     [4, 7],
//     [5, 7],
//     [6, 10],
//     [7, 10],
//     [8, 8],
//     [9, 8],
//     [10, 7],
//     [11, 6],
//     [12, 4],
//     [13, 3]
// ];

var monthsArr = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
]


interface stylesI {
    root: CSSProperties;
    svg: CSSProperties;
    path: CSSProperties;
    pathA: CSSProperties;
    axis: CSSProperties;
    gridline: CSSProperties;
    axistext: CSSProperties;
    gridlineTemp: CSSProperties;
    area: CSSProperties;
}

const styles: stylesI = {
    root: {
        position: "relative",
        width: "100%",
        height: "100%",
        overflowY: "hidden",
        overflowX: "scroll"
    },
    svg: {
        fill: "#fff",
        fillOpacity: 1.5,
        zIndex: 0,
        position: "absolute",
        left: 96,
        bottom: 0,
        filter: "drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7))"
    },
    path: {
        stroke: "#72758E",
        strokeWidth: 3,
        fill: "none",
        filter: "url(#shadow2)",
    },
    pathA: {
        stroke: "#F7B198",
        strokeWidth: 3,
        fill: "none",
        filter: "url(#shadow2)",
    },
    axis: {
        stroke: "#fff"
    },
    gridline: {
        stroke: "rgb(255, 255, 255, 0.05)"
    },
    gridlineTemp: {
        opacity: 1,
        stroke: "#fff"
    },
    axistext: {
        fill: "#AAAAAA",
        fillOpacity: 0.9,
        fontSize: 12,
        textAnchor: "middle",
        fontWeight: "normal"
    },
    area: {
        fill: "linear-gradient(180deg, rgba(255, 255, 255, 0.2) 25.16%, rgba(255, 255, 255, 0) 143.4%)",
        fillOpacity: 0.2
    }
}


const decimalformalFunc = (Num) => {
    let temp = Num.toString().split(".");
    let retVal = 0;
    if (temp[1]) {
      retVal = Num.toFixed(0);
    } else {
      retVal = Num;
    }
    return retVal;
  };

  const retformatamt = (clsAmt) => {
    let closingamt_disp: number = 0;
    Number.isNaN(clsAmt) || clsAmt === null || clsAmt === ""
      ? (closingamt_disp = 0)
      : (closingamt_disp = parseInt(clsAmt));
    return Math.abs(Number(closingamt_disp)) >= 1.0e9
      ? decimalformalFunc(Math.abs(Number(closingamt_disp)) / 1.0e9) + "b"
      : // Six Zeroes for Millions
      Math.abs(Number(closingamt_disp)) >= 1.0e6
      ? decimalformalFunc(Math.abs(Number(closingamt_disp)) / 1.0e6) + "m"
      : // Three Zeroes for Thousands
      Math.abs(Number(closingamt_disp)) >= 1.0e3
      ? decimalformalFunc(Math.abs(Number(closingamt_disp)) / 1.0e3) + "k"
      : decimalformalFunc(Math.abs(Number(closingamt_disp)));
  };

const retAmtforTicks = (clsAmt) => {
    let closingamt_disp: number = 0;
    (Number.isNaN(clsAmt))|| clsAmt === null || clsAmt === "" ? closingamt_disp = 0 : closingamt_disp = parseInt(clsAmt)
    return Math.abs(Number(closingamt_disp)) >= 1.0e+9

        ? Math.abs(Number(closingamt_disp)) / 1.0e+9 + "b"
        // Six Zeroes for Millions 
        : Math.abs(Number(closingamt_disp)) >= 1.0e+6

            ? Math.abs(Number(closingamt_disp)) / 1.0e+6 + "m"
            // Three Zeroes for Thousands
            : Math.abs(Number(closingamt_disp)) >= 1.0e+3

                ? Math.abs(Number(closingamt_disp)) / 1.0e+3 + "k"

                : Math.abs(Number(closingamt_disp));
}

interface LineChartProps {
  CardTitle:string;
    width: number;
    height: number;
    chartData: [number, number, number, number][][][][];
    tf:string
}

const BarLineReinvestmentChart: React.FC<LineChartProps> = ({
    CardTitle,
    width,
    height,
    chartData,
    tf
}) => {

    const refA: RefObject<SVGSVGElement> = React.createRef<SVGSVGElement>()


    const h = height - 2 * margin, w = width - 2 * margin

    //number formatter
    const xFormat = d3.format("0.2")
    //x scale
    const x = d3.scaleLinear()
        .domain([1, 12]) //domain: [min,max] of a
        .range([margin, w])

    
    const [yTicks,setyTicks] = useState<any>();
    const [xTicks,setxTicks] = useState<any>();
    const [xGridLines,setxGridLines] = useState<any>();
    const [yGridLines,setyGridLines] = useState<any>();


    useEffect(() => {
       if(chartData[0].length > 0) {      
        let tempArray = chartData[0][0];
        //console.log(tempArray[3] + tempArray[2]);
        const maxValueArr:any = [];
        for(let i=0 ; i<12 ; i++)
        {
            maxValueArr.push(Number(chartData[0][i][2]));
        }
        
        //chartData.forEach((data, i) => {
           
   
        const ActualData = [
            {
                month: "JAN",
                monthNum: 1,
                reinvest: Number(chartData[0][0][2]),
                year: Number(chartData[0][0][1]),
            },
            {
                month: "FEB",
                monthNum: 2,
                reinvest: Number(chartData[0][1][2]),
                year: Number(chartData[0][1][1]),
            },
            {
                month: "MAR",
                monthNum: 3,
                reinvest: Number(chartData[0][2][2]),
                year: Number(chartData[0][2][1]),
            },
            {
                month: "APR",
                monthNum: 4,
                reinvest: Number(chartData[0][3][2]),
                year: Number(chartData[0][3][1]),
            },
            {
                month: "MAY",
                monthNum: 5,
                reinvest: Number(chartData[0][4][2]),
                year: Number(chartData[0][4][1]),
            },
            {
                month: "JUN",
                monthNum: 6,
                reinvest: Number(chartData[0][5][2]),
                year: Number(chartData[0][5][1]),
            },
            {
                month: "JUL",
                monthNum: 7,
                reinvest: Number(chartData[0][6][2]),
                year: Number(chartData[0][6][1]),
            },
            {
                month: "AUG",
                monthNum: 8,
                reinvest: Number(chartData[0][7][2]),
                year: Number(chartData[0][7][1]),
            },
            {
                month: "SEP",
                monthNum: 9,
                reinvest: Number(chartData[0][8][2]),
                year: Number(chartData[0][8][1]),
            },
            {
                month: "OCT",
                monthNum: 10,
                reinvest: Number(chartData[0][9][2]),
                year: Number(chartData[0][9][1]),
            },
            {
                month: "NOV",
                monthNum: 11,
                reinvest: Number(chartData[0][10][2]),
                year: Number(chartData[0][10][1]),
            },
            {
                month: "DEC",
                monthNum: 12,
                reinvest: Number(chartData[0][11][2]),
                year: Number(chartData[0][11][1]),
            }
            ];

    
            var dateVar = new Date();
            var monthVar = dateVar.getMonth();
            if(tf.toString().includes("Q1"))
    {      
     
      monthsArr = [];
      monthsArr.push("Jan");
      monthsArr.push("");
      monthsArr.push("Feb");
      monthsArr.push("");
      monthsArr.push("Mar");
      ActualData.splice(3,9);
      ActualData[0].monthNum = 1;
      ActualData[1].monthNum = 3;
      ActualData[2].monthNum = 5;
    }
    else if(tf.toString().includes("Q2"))
    {
      ActualData.splice(0,3);
      ActualData.splice(3,8);
      monthsArr = [];
      monthsArr.push("Apr");
      monthsArr.push("");
      monthsArr.push("May");
      monthsArr.push("");
      monthsArr.push("Jun");
      ActualData[0].monthNum = 1;
      ActualData[1].monthNum = 3;
      ActualData[2].monthNum = 5;
    }
    else if(tf.toString().includes("Q3"))
    {
      ActualData.splice(0,3);
      ActualData.splice(3,8);
      monthsArr = [];
      monthsArr.push("Jul");
      monthsArr.push("");
      monthsArr.push("Aug");
      monthsArr.push("");
      monthsArr.push("Sep");
      ActualData[0].monthNum = 1;
      ActualData[1].monthNum = 3;
      ActualData[2].monthNum = 5;
    }
    else if(tf.toString().includes("Q4"))
    {
      ActualData.splice(0,6);
      ActualData.splice(0,3);
      monthsArr = [];
      monthsArr.push("Oct");
      monthsArr.push("");
      monthsArr.push("Nov");
      monthsArr.push("");
      monthsArr.push("Dec");
      ActualData[0].monthNum = 1;
      ActualData[1].monthNum = 3;
      ActualData[2].monthNum = 5;
    }
        else if(tf.toString().includes("Year-to-Date"))
            {      
              monthsArr.splice(monthVar+1,12-monthVar);
            //   console.log(ActualData);
            }
            else
            {
                monthsArr = [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec"
                ]
            }

    const svg = d3.select(refA.current);
    //const svgContainer = d3.select('#container');    
    const margin = 20;
    //const width = 760 - 2 * margin;
    //const height = 460 - 2 * margin;
    // const width = 700;
    // const height = 384;
    const chart = svg.append('g')
    .attr('transform', `translate(${margin}, ${margin})`);

    // const xScale = d3.scaleBand()
    //     .range([0, width])
    //    // .domain(ActualData.map((s) => s.month))
    //    .domain([0,15])
    //     .padding(0.4)
    const xScale = d3.scaleLinear()
    .domain([0, 13]) //domain: [min,max] of a
    .range([20, 660])        
    
    const yScale = d3.scaleLinear()
        .range([height-40, 20])
        //.domain([0, Math.max(...maxValueArr)* 1.2]); //[llowest,highest]
        .domain([0, Math.max(...maxValueArr)>0?Math.max(...maxValueArr)* 1.2:80]); //[llowest,highest]

    const xG = d3.scaleLinear()
    .domain([0, 15]) //domain: [min,max] of a
    .range([0, 700])

    const yG = d3.scaleLinear()
        .domain([0, Math.max(...maxValueArr)* 1.2]) // domain [0,max] of b (start from 0)
        .range([0, 700])

    
    setxGridLines(xG.ticks(14).map((d, i) => (
        <g key={i} transform={`translate(${xScale(d)},${h + margin})`}>
            <line style={styles.gridline} className='gridline' y1={2400} y2={-2400} x1='0' x2='0' transform="translate(0,-25)" />
        </g>
    )))

    setyGridLines(yG.ticks().map((d, i) => (
        <g key={i} transform={`translate(${margin},${yScale(d)})`}>
            <line style={styles.gridline} className='gridline' x1={-2400} x2={2400} y1='0' y2='0' transform="translate(-5,0)" />
        </g>
    )))

    setyTicks(yScale.ticks(4).map((d, i) => (
        yScale(d) > 10 && yScale(d) < h ?
            <g key={i} transform={`translate(${margin + 30},${yScale(d)})`}>
                <text style={styles.axistext} x="-12" y="5">
                    {
                        // xFormat(d)
                        retAmtforTicks(d)
                    }
                    </text>
                {/* <line x1='0' x2='5' y1='0' y2='0' transform="translate(-5,0)" /> */}
                {/* <line style={styles.gridline} className='gridline' x1='0' x2={w - margin} y1='0' y2='0' transform="translate(-5,0)" /> */}

            </g>
            : null
    )))

    
    setxTicks(xScale.ticks(12).map((d, i) => (
        // x(d) > margin && x(d) < w ?
        <g key={i} transform={`translate(${xScale(d)+84},${h + margin})`}>
            <text style={styles.axistext}>{monthsArr[i]}</text>
            <line x1='0' x2='0' y1='0' y2='5' transform="translate(0,-20)" />
            {/* <line style={styles.gridline} className='gridline' y1='0' y2={margin - h} x1='0' x2='0' transform="translate(0,-25)" /> */}
        </g>
        // : null
    )))

        chart.append('g')
        .attr('transform', `translate(0, ${height})`)
        .call(d3.axisBottom(xScale));
  
    //   chart.append('g')
    //     .call(d3.axisLeft(yScale));

        chart.append('g')
      .attr('class', 'grid')

      const barGroups = chart.selectAll()
      .data(ActualData)
      .enter()
      .append('g')

    barGroups
      .append('rect')
      .attr('class', 'bar')
      .attr('x', (g) => xScale(g.monthNum))
      .attr('y', (g) => yScale(g.reinvest) - margin)
      //g.teamclient ==0 ? 0 : height - yScale(g.teamclient) - (2*margin))
      //.attr('height', (g) => height - yScale(g.reinvest))
      .attr('height', (g) => g.reinvest == 0 ? 0 : height - yScale(g.reinvest) - (2*margin)) 
      .attr('width', 30)
      .attr('fill','#B99855')
    
    // barGroups
    //   .append('rect')
    //   .attr('class', 'bar')   
    //   //.attr('x', 50)
    //   .attr('x', (g) => xScale(g.monthNum))
    //   .attr('y', (g) => yScale(g.capital) - (height - yScale(g.income)))//income
    //   .attr('height', (g) => height - yScale(g.income))
    //   .attr('width', 30)
    //   .attr("fill", "#B99855")

    // barGroups 
    //   .append('text')
    //   .attr('class', 'value')
    //   .attr('x', (g) => xScale(g.monthNum) + 25 / 2)
    //   .attr('y', (g) => yScale(g.capital + g.income) - 45)
    //   .attr('text-anchor', 'middle')
    //   .text((g) => `${retformatamt( g.capital + g.income)}`)

      barGroups 
      .append('text')
      .attr('class', 'value')
      .attr('class','font12')
      .attr('x', (g) => xScale(g.monthNum) + 30 / 2)
      .attr('y', (g) => yScale(g.reinvest) - 30)
      .attr('text-anchor', 'middle')
      //.text((g) => `${retformatamt( g.reinvest)}`)
      .text(function(g){         
        return g.reinvest?retformatamt( g.reinvest):""})      
    }
    
        //})
    }, [])

    return (
        <div style={styles.root}>
            <svg width={width} height={height} className="labelssvg" style={{ position: "absolute", bottom: 0, left: 96 }}>
                <g className="axis-labels">
                    {xGridLines}
                </g>
                <g className="axis-labelsy">
                    {yGridLines}
                </g>
            </svg> 
            <svg width={width} height={height} className="axessvg" style={styles.svg} ref={refA}>
            {/* <svg width={width} height={height} className="axessvg" > */}
                 <g className="axis-labels">
                    {xTicks}
                </g>*
                <g className="axis-labels">
                     {yTicks}
                </g> 
              {/*  <defs>
                    {/* <filter id="shadow">
                        <feGaussianBlur result="coloredBlur" stdDeviation="3.5" />
                        <feMerge>
                            <feMergeNode in="coloredBlur" />
                            <feMergeNode in="SourceGraphic" />
                        </feMerge>
                    </filter>
                    <filter id="shadow2">
                        <feDropShadow dx="0" dy="10" stdDeviation="4.5"
                            floodColor="black" />
                    </filter> 
                </defs>*/}
            </svg>
        </div>
    )
}

export default BarLineReinvestmentChart