import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonText,
  IonToolbar,
} from "@ionic/react";
import { chevronBack } from "ionicons/icons";
import moment from "moment";
import api from "src/api";
import { useHistory } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import edit from "src/icons/pen-edit.svg"


interface ViewTodoPageProps
  extends RouteComponentProps<{
    engId: string;
    contId: string
  }> {}

const styles = {
  rootGrid: {
    marginLeft: 32,
    marginRight: 32,
  },
  toolbar: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
    height: 75,
  },
  HeaderRow: {
    marginTop: 24,
    marginBottom: 24,
  },
  Header: {
    fontSize: 28,
  },
  avatar: {
    width: 48,
    height: 48,
  },
  deftext: {
    fontSize: 14,
    marginTop: "auto",
    marginBottom: "auto",
    marginLeft: 16,
  },
  engname: {
    fontSize: 14,
    fontWeight: 700,
    marginTop: "auto",
    marginBottom: "auto",
    marginLeft: 16,
  },
  arabname: {
    fontSize: 14,
    fontFamily: "Almarai",
    marginTop: "auto",
    marginBottom: "auto",
  },
  period: {
    marginTop: "auto",
    marginBottom: "auto",
    marginLeft: 8,
    marginRight: 8,
    width: 8,
    height: 8,
  },
  addrembtn: {
    marginLeft: 64,
    "--border-radius": "2px",
    textTransform: "none",
    width: 180,
  },
  subbtn: {
    "--border-radius": "2px",
    textTransform: "none",
    width: 180,
  },
  listheadertext: {
    fontSize: 14,
    fontWeight: "normal",
    marginTop: 0,
  },
  radios: {
    fontSize: 14,
  },
};


const ViewTodo: React.FC<ViewTodoPageProps> = ({ match }) => {
  const [selTodoObj, setseltodoObj] = useState<any>();
  const [isLoading, setLoading] = useState<boolean>(false);

  const hist = useHistory();
  const ViewState: any = hist.location.state
  const matchTodoId = ViewState.engId;
  const matchContId = ViewState.contId;

  useEffect(() => {
    setLoading(true)
    if (matchTodoId) {
      api.GetEngagement(matchTodoId).then((response) => {
        setseltodoObj(response);
        setLoading(false)
      });
    }
  }, [matchTodoId]);

   return (
    <IonPage>
      <IonLoading
        cssClass="my-custom-loading-class"
        isOpen={isLoading}
        message={"Please wait..."}
      />
      <IonHeader>
        <IonToolbar mode="md" style={styles.toolbar}>
          <IonButtons slot="start">
            <IonButton
              style={{ textTransform: "none" }}
              color="white"
              // routerLink="/todo"
              onClick={() => {
                hist.push(`/contactinfo/${matchContId}`,{
                    to: "todos"
                });
              }}
            >
              <IonIcon icon={chevronBack}></IonIcon>
              {ViewState && ViewState.engId 
                ? "Profile"
                : "To-Do"}
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton
              // routerLink={`/editevent`}
              onClick={() => {
                hist.push(`/edit/todo`,{selTodoObj:selTodoObj,contactId:matchContId})
              }}
              mode="ios" color="gold" fill="clear" slot="end"><IonIcon color="white" size="small" icon={edit} slot="start" ></IonIcon>Edit</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid style={styles.rootGrid} className="ml-13 mr-13 mt-6">
          <IonRow style={styles.HeaderRow} className="mobileML14">
            {/* <IonLabel className="to-do" style={styles.Header} color="greyscale">
              View To-Do
            </IonLabel> */}
          <span className="to-do" style={{ fontWeight: 300, fontSize: 28, color:"GrayText", whiteSpace:"nowrap" }}>
            View To-Do
          </span>
          </IonRow>
          <IonRow className="form-row root-form-row mobileML14">
            <IonCol sizeLg="5" sizeSm="5">
              <IonRow>
                <IonCol>
                  <IonLabel>Task Title</IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonText
                    style={{
                      textTransform: "none",
                      fontWeight: "300",
                      fontSize: 14,
                      color: "#828282",
                    }}
                  >
                    {selTodoObj ? selTodoObj.metadata.subject : ""}
                  </IonText>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonLabel>Due Date</IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonText
                    style={{
                      textTransform: "none",
                      fontWeight: "300",
                      fontSize: 14,
                      color: "#828282",
                    }}
                  >
                    {selTodoObj
                      ? moment(selTodoObj.engagement.timestamp).format(
                         // "dddd, MMMM DD, YYYY (h:ss A)"
                         "dddd, MMMM DD, YYYY (h:mm A)"
                        )
                      : ""}
                  </IonText>
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol pushLg="1" sizeSm="7" sizeLg="6">
              <IonRow>
                <IonCol size="6">Type</IonCol>
                <IonCol size="6">Priority</IonCol>
              </IonRow>
              <IonRow>
                <IonCol
                  size="6"
                  style={{
                    textTransform: "none",
                    fontWeight: "300",
                    fontSize: 14,
                    color: "#828282",
                  }}
                >
                  {selTodoObj ? selTodoObj.metadata.taskType : ""}
                </IonCol>
                <IonCol
                  size="6"
                  style={{
                    textTransform: "none",
                    fontWeight: "300",
                    fontSize: 14,
                    color: "#828282",
                  }}
                >
                  {selTodoObj ? selTodoObj.metadata.priority : ""}
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
          <IonRow className="form-row mobileML14">
            <IonCol size="12">
              <IonRow>
                <IonCol>
                  <IonLabel>Task Notes</IonLabel>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonText
                    style={{
                      textTransform: "none",
                      fontWeight: "300",
                      fontSize: 14,
                      color: "#828282",
                    }}
                  >
                    {selTodoObj ? selTodoObj.engagement.bodyPreview.replace(/(<([^>]+)>)/ig,"") : ""}
                  </IonText>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
          <IonRow className="form-row mobileML14">
            <IonCol>
              <IonLabel>Email Reminder</IonLabel>
            </IonCol>
          </IonRow>
          <IonRow className="form-row mobileML14">
            <IonCol>
              <IonText
                style={{
                  textTransform: "none",
                  fontWeight: "300",
                  fontSize: 14,
                  color: "#828282",
                }}
              >
                {selTodoObj && selTodoObj.metadata.reminders[0]
                  ? moment(selTodoObj.metadata.reminders[0]).format(
                    //  "dddd, MMMM DD, YYYY (h:ss A)"
                    "dddd, MMMM DD, YYYY (h:mm A)"
                    )
                  : ""}
              </IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ViewTodo;
