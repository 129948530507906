import {
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
  IonCol,
  IonInput,
  IonTextarea,
  IonCheckbox,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonText,
} from "@ionic/react";
import React, { useCallback, useRef, useEffect } from "react";
import { Field } from "formik";
import { chevronDown } from "ionicons/icons";
import moment from "moment";
import IMask from "imask";
import dollar from "src/icons/dollar.svg";
import useWindowDimensions from "src/components/useWindowDimensions";

const styles = {
  root: {
    padding: 27,
  },
  selectItem: {
    "--padding-start": "0px",
    backgroundColor: "#222222",
    marginTop: 8,
  },
  select: {
    "--padding-start": "8px",
  },
  label: {
    // marginBottom: 8
  },
  checklist: {
    "--padding-start": "0px",
  },
  listHeader: {
    fontSize: 14,
    paddingInlineStart: 0,
    marginBottom: 8,
    maxHeight: 0,
    fontWeight: "normal",
  },
};

const retExpMonths = () => {
  let retObj: any = [];
  for (var i = 0; i < 12; i++) {
    retObj.push({
      val: `${moment(new Date())
        .add(i, "M")
        .format("MMM")} ${moment(new Date())
        .add(i, "M")
        .format("YYYY")}`,
      mom: moment(new Date())
        .add(i, "M")
        .startOf("month")
        .format("YYYY-MM-DD"),
    });
  }
  return retObj;
};

const optionsSec = {
  cssClass: "my-custom-interfacesec",
};
const optionalert = {
  cssClass: "my-custom-interfacealert",
};
const optionsA = {
  cssClass: "my-custom-interface",
};
const FinancialInfo: React.FC<any> = ({
  contact,
  handleChange,
  values,
  setFieldValue,
  comppostn,
  sector,
  sow,
  wealthRange,
  errors,
  touched,
  clsProb,
  polexpP,
  handleBlur,
}) => {
  // useEffect(() => {
  //   setFieldTouched("exp_closing_prob", false);
  //   setFieldTouched("exp_mnth_close", false);
  //   setFieldTouched("exp_close_amt", false);
  // }, [setFieldTouched]);
  const { height, width } = useWindowDimensions();
  return (
    <React.Fragment>
      <IonGrid class="padding-unset" style={styles.root}>
        <IonRow>
          <IonCol size-xs="4" size-sm="5" size-lg="5"></IonCol>
          <IonCol size-xs="4" size-sm="5" size-lg="5"></IonCol>
          <IonCol
            size-xs="4"
            size-sm="2"
            size-lg="2"
            style={{ fontSize: 14, textAlign: "right" }}
          >
            * = required
          </IonCol>
        </IonRow>
        <IonRow class="ion-margin-bottom">
          <IonCol size-lg="4" size-xs="12" size-sm="4">
            <IonLabel class="font14" style={styles.label}>
              Company
            </IonLabel>
            <IonItem
              lines="none"
              class="ion-item-height"
              style={styles.selectItem}
            >
              <Field
                as={IonInput}
                class="inpClass font14"
                onIonChange={handleChange}
                onIonBlur={handleBlur}
                value={values.company}
                name="company"
                placeholder="Enter company name"
                autocapitalize="sentences"
              />
            </IonItem>
          </IonCol>
          <IonCol size-lg="4" size-xs="12" size-sm="4">
            <IonLabel class="font14" style={styles.label}>
              Position at Company
            </IonLabel>
            <IonItem
              lines="none"
              class="ion-item-height"
              style={styles.selectItem}
            >
              <Field
                as={IonSelect}
                interfaceOptions={optionsA}
                style={styles.select}
                value={values.position}
                onIonChange={handleChange}
                onIonBlur={handleBlur}
                name="position"
                mode="ios"
                class="customIonSelect"
                placeholder="Select position"
                interface={width < 576 ? "action-sheet" : "popover"}
              >
                {comppostn.map((obj, i) => (
                  <IonSelectOption key={i} value={obj.val}>
                    {obj.val}
                  </IonSelectOption>
                ))}
              </Field>
              <IonIcon slot="end" icon={chevronDown} color="gold" />
            </IonItem>
          </IonCol>
          <IonCol size-lg="4" size-xs="12" size-sm="4">
            <IonLabel class="font14" style={styles.label}>
              Sector
            </IonLabel>
            <IonItem
              lines="none"
              class="ion-item-height"
              style={styles.selectItem}
            >
              <Field
                as={IonSelect}
                interfaceOptions={optionsSec}
                style={styles.select}
                value={values.sector}
                onIonChange={handleChange}
                onIonBlur={handleBlur}
                name="sector"
                mode="ios"
                class="customIonSelect"
                placeholder="Select Sector"
                interface={width < 576 ? "action-sheet" : "popover"}
              >
                {sector.map((obj, i) => (
                  <IonSelectOption key={i} value={obj.val}>
                    {obj.val}
                  </IonSelectOption>
                ))}
              </Field>
              <IonIcon slot="end" icon={chevronDown} color="gold" />
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow class="ion-margin-bottom">
          <IonCol size-lg="4" size-xs="12" size-sm="4">
            <IonLabel class="font14" style={styles.label}>
              Source of Wealth
            </IonLabel>
            <IonItem
              lines="none"
              class="ion-item-height"
              style={styles.selectItem}
            >
              <Field
                as={IonSelect}
                interfaceOptions={optionalert}
                style={styles.select}
                value={values.sow}
                // onIonChange={handleChange}
                onIonChange={(e) => {
                  let data = e.detail.value;
                  // console.log(data)
                  setFieldValue("sow", data);
                }}
                onIonBlur={handleBlur}
                name="sow"
                mode="md"
                // mode="ios"
                class="customIonSelect"
                placeholder="Select source of wealth"
                interface={width < 576 ? "action-sheet" : "popover"}
                multiple="true"
              >
                {sow.map((obj, i) => (
                  <IonSelectOption key={i} value={obj.val}>
                    {obj.val}
                  </IonSelectOption>
                ))}
              </Field>
              <IonIcon slot="end" icon={chevronDown} color="gold" />
            </IonItem>
          </IonCol>
          <IonCol size-lg="4" size-xs="12" size-sm="4">
            <IonLabel class="font14" style={styles.label}>
              Expected Wealth Range
            </IonLabel>
            <IonItem
              lines="none"
              class="ion-item-height"
              style={styles.selectItem}
            >
              <Field
                as={IonSelect}
                interfaceOptions={optionsA}
                style={styles.select}
                value={values.exp_wealth_range}
                onIonChange={handleChange}
                onIonBlur={handleBlur}
                name="exp_wealth_range"
                mode="ios"
                class="customIonSelect"
                placeholder="Select wealth range"
                interface={width < 576 ? "action-sheet" : "popover"}
              >
                {wealthRange.map((obj, i) => (
                  <IonSelectOption key={i} value={obj.val}>
                    {obj.val}
                  </IonSelectOption>
                ))}
              </Field>
              <IonIcon slot="end" icon={chevronDown} color="gold" />
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow class="ion-margin-bottom">
          <IonCol size-lg="9">
            <IonLabel class="font14" style={styles.label}>
              Bio
            </IonLabel>
            <IonItem lines="none" style={styles.selectItem}>
              <Field
                as={IonTextarea}
                rows={6}
                class="inpClass font14"
                onIonChange={handleChange}
                onIonBlur={handleBlur}
                value={values.bio}
                name="bio"
                spellcheck={true}
                placeholder="Enter biography or profile on the prospect"
                autocapitalize="sentences"
              />
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow class="ion-margin-bottom">
          <IonCol size-lg="4" size-xs="12" size-sm="4">
            {/* <IonItem lines="none" style={styles.checklist} >
                                <Field name="pol_exp" as={IonCheckbox} class="ion-color-actblue-v1" color="actblue" checked={values.pol_exp} onIonChange={(e) => {
                                    setFieldValue("pol_exp", e.detail.checked)
                                }} mode="md" />
                                <IonLabel class="grayscale6 ion-margin-start" style={{ fontSize: 12 }}>Politically Exposed Person(PEP)</IonLabel>
                        </IonItem> */}
            <IonLabel class="font14" style={styles.label}>
              Politically Exposed Person(PEP)
            </IonLabel>
            <IonItem
              lines="none"
              class="ion-item-height"
              style={styles.selectItem}
            >
              <Field
                as={IonSelect}
                interfaceOptions={optionsA}
                style={styles.select}
                value={values.pol_exp}
                onIonChange={handleChange}
                onIonBlur={handleBlur}
                name="pol_exp"
                mode="ios"
                class="customIonSelect"
                placeholder="Select Political Exposure"
                interface={width < 576 ? "action-sheet" : "popover"}
              >
                {polexpP.map((obj, i) => (
                  <IonSelectOption key={i} value={obj.val}>
                    {obj.label}
                  </IonSelectOption>
                ))}
              </Field>
              <IonIcon slot="end" icon={chevronDown} color="gold" />
            </IonItem>
          </IonCol>
          <IonCol
            size-lg="4"
            size-xs="12"
            size-sm="4"
            style={{ display: "flex" }}
          >
            <IonItem
              lines="none"
              class="ion-item-height ion-margin-top-6"
              style={{ ...styles.checklist, marginTop: "auto" }}
            >
              <Field
                name="sharia_comp"
                as={IonCheckbox}
                class="ion-color-actblue-v1"
                color="actblue"
                checked={values.sharia_comp}
                onIonBlur={handleBlur}
                onIonChange={(e) => {
                  setFieldValue("sharia_comp", e.detail.checked);
                }}
                mode="md"
              />
              <IonLabel
                class="grayscale6 ion-margin-start"
                style={{ fontSize: 12 }}
              >
                Sharia Compliant
              </IonLabel>
            </IonItem>
          </IonCol>
        </IonRow>

        <IonRow class="ion-margin-bottom">
          <IonCol size-lg="4" size-xs="12" size-sm="4">
            <IonLabel class="font14" style={styles.label}>
              * Expected Closing Probability
            </IonLabel>
            <IonItem
              lines="none"
              class="ion-item-height"
              style={styles.selectItem}
            >
              <Field
                as={IonSelect}
                interfaceOptions={optionsA}
                style={styles.select}
                onIonChange={handleChange}
                onIonBlur={handleBlur}
                value={values.exp_closing_prob}
                name="exp_closing_prob"
                mode="ios"
                class="customIonSelect"
                placeholder="Select Expected Closing Probability"
                interface={width < 576 ? "action-sheet" : "popover"}
              >
                {clsProb.map((obj, i) => (
                  <IonSelectOption key={i} value={obj.val}>
                    {obj.val}
                  </IonSelectOption>
                ))}
              </Field>
              <IonIcon slot="end" icon={chevronDown} color="gold" />
            </IonItem>

            {Boolean(errors.exp_closing_prob && touched.exp_closing_prob) ? (
              <IonText color="danger">{errors.exp_closing_prob}</IonText>
            ) : null}
          </IonCol>
          <IonCol size-lg="4" size-xs="12" size-sm="4">
            <IonLabel class="font14" style={styles.label}>
              * Expected Month of Closing
            </IonLabel>
            <IonItem
              lines="none"
              class="ion-item-height"
              style={styles.selectItem}
            >
              <Field
                as={IonSelect}
                interfaceOptions={optionsA}
                style={styles.select}
                onIonChange={(e) => {
                  setFieldValue("exp_mnth_close", e.detail.value);
                  // setFieldValue("exp_date_close", retExpMonths().filter(o => o.val === e.detail.value)[0].mom)
                }}
                onIonBlur={handleBlur}
                value={values.exp_mnth_close}
                name="exp_mnth_close"
                mode="ios"
                class="customIonSelect"
                placeholder="Select Expected Month of Closing"
                interface={width < 576 ? "action-sheet" : "popover"}
              >
                {retExpMonths().map((obj, i) => (
                  <IonSelectOption key={i} value={obj.mom}>
                    {obj.val}
                  </IonSelectOption>
                ))}
              </Field>
              <IonIcon slot="end" icon={chevronDown} color="gold" />
            </IonItem>
            {Boolean(errors.exp_mnth_close && touched.exp_mnth_close) ? (
              <IonText color="danger">{errors.exp_mnth_close}</IonText>
            ) : null}
          </IonCol>
          <IonCol size-lg="4" size-xs="12" size-sm="4">
            <IonLabel class="font14" style={styles.label}>
              * Expected Closing Amount
            </IonLabel>
            <IonItem
              lines="none"
              class="ion-item-height"
              style={styles.selectItem}
            >
              <IonIcon
                class="ion-padding-bottom-6"
                style={{ height: 20 }}
                color="gold"
                icon={dollar}
              />
              <Field
                as={MaskedIonInput}
                type="number"
                class="inpClass font14"
                // onIonChange={handleChange}
                onChange={(e) => {
                  setFieldValue("exp_close_amt", e);
                }}
                onIonBlur={handleBlur}
                value={values.exp_close_amt}
                name="exp_close_amt"
                style={{ paddingLeft: 8 }}
                placeholder="Enter Expected Closing Amount"
              />
            </IonItem>
            {Boolean(errors.exp_close_amt && touched.exp_close_amt) ? (
              <IonText color="danger">{errors.exp_close_amt}</IonText>
            ) : null}
          </IonCol>
        </IonRow>
        {/* <IonRow class="ion-margin-bottom">
                    <IonCol size="3">
                        <Field as={IonRadioGroup} name="inv_obj" onIonChange={handleChange} value={values.inv_obj}>
                            <IonListHeader style={styles.listHeader}>
                                <IonLabel>Investment Objectives</IonLabel>
                            </IonListHeader>
                            <IonItem style={styles.checklist} class="grayscale6 margintopn20" lines="none">
                                <IonRadio mode="md" color="actblue" value="Growth" />
                                <IonLabel style={{ fontSize: 12 }} class="marginleftp10">Growth</IonLabel>
                            </IonItem>
                            <IonItem style={styles.checklist} class="grayscale6 margintopn20" lines="none">
                                <IonRadio mode="md" color="actblue" value="Yielding" />
                                <IonLabel style={{ fontSize: 12 }} class="marginleftp10">Yielding</IonLabel>
                            </IonItem>
                            <IonItem style={styles.checklist} class="grayscale6 margintopn20" lines="none">
                                <IonRadio mode="md" color="actblue" value="Both" />
                                <IonLabel style={{ fontSize: 12 }} class="marginleftp10">Both</IonLabel>
                            </IonItem>
                            <IonItem style={styles.checklist} class="grayscale6 margintopn20" lines="none">
                                <IonRadio mode="md" color="actblue" value="Opportunistic" />
                                <IonLabel style={{ fontSize: 12 }} class="marginleftp10">Opportunistic</IonLabel>
                            </IonItem>
                        </Field>
                    </IonCol>
                </IonRow> */}
      </IonGrid>
    </React.Fragment>
  );
};

const MaskedIonInput = ({ value, onChange }) => {
  const maskRef = useRef<IMask.InputMask<any> | null>(null);

  const inputCallback = useCallback(async (input) => {
    if (!input) {
      return;
    }
    const nativeInput = await input.getInputElement();
    const mask = IMask(nativeInput, {
      mask: Number,
      thousandsSeparator: ",",
    })
      .on("accept", (e: any) => {
        onChange(mask.value);
      })
      .on("complete", (e: any) => {
        onChange(mask.value);
      });

    maskRef.current = mask;
  }, []);

  return (
    <IonInput
      style={{ "--padding-start": "8px" }}
      value={value}
      ref={inputCallback}
    />
  );
};

export default FinancialInfo;
