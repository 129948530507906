import React, { useEffect, useState } from "react";
import {
  IonCol,
  IonGrid,
  IonRow,
  IonLabel,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonIcon,
} from "@ionic/react";
import { chevronDown } from "ionicons/icons";
// import BarLineChart from "./BarLine";
import BarLineReinvestmentChart from "./BarLineReinvestment";

import dollar from "src/icons/dollar.svg";
import useWindowDimensions from "src/components/useWindowDimensions";
import GroupBarChart from "./GroupBarChart";

const styles = {
  rootGrid: {
    marginTop: 24,
  },
  colbackL: {
    height: 384,
    // background: "url(/assets/mesh.svg) no-repeat 100px 0px",
    backgroundColor: "#1A1A1A",
    paddingRight: 7.5,
  },
  colbackR: {
    height: 384,
    // background: "url(/assets/mesh.svg) no-repeat 100px 0px",
    backgroundColor: "#1A1A1A",
    paddingLeft: 7.5,
  },
  innerGrid: {
    position: "relative",
    zIndex: 2,
  },
  titleRow: {
    position: "absolute",
    top: 27,
    marginLeft: 40,
    display: "flex",
    flexDirection: "column",
  },
  dropdownRow: {
    position: "absolute",
    top: 11.5,
    marginLeft: 40,
  },
  title: {
    fontSize: 18,
    fontWeight: "normal",
  },
  datedwItem: {
    marginTop: 11.5,
    "--padding-start": 0,
    color: "#b99855",
    fontSize: 14,
    "--color-activated": "none",
    "--background-activated": "none",
  },
  moneyRow: {
    position: "relative",
  },
  moneyIcon: {
    position: "absolute",
    height: 22,
    left: -20,
    top: 10,
  },
  moneyLabel: {
    fontWeight: 300,
    fontSize: 43,
    marginBottom: 12,
  },
  iconrow: {
    display: "flex",
    marginBottom: 8,
  },
  iconlabel: {
    color: "#AAAAAA",
    marginLeft: 8,
    fontSize: 14,
    marginTop: "auto",
    marginBottom: "auto",
  },
};

const decimalFunc = (Num) => {
  let temp = Num.toString().split(".");
  let retVal = 0;
  if (temp[1]) {
    retVal = Num.toFixed(2);
  } else {
    retVal = Num;
  }
  return retVal;
};
const retTotalAmt = (clsAmt) => {
  let closingamt_disp: number = 0;
  Number.isNaN(clsAmt) || clsAmt === null || clsAmt === ""
    ? (closingamt_disp = 0)
    : (closingamt_disp = parseInt(clsAmt));
  return Math.abs(Number(closingamt_disp)) >= 1.0e9
    ? decimalFunc(Math.abs(Number(closingamt_disp)) / 1.0e9) + "b"
    : // Six Zeroes for Millions
    Math.abs(Number(closingamt_disp)) >= 1.0e6
    ? decimalFunc(Math.abs(Number(closingamt_disp)) / 1.0e6) + "m"
    : // Three Zeroes for Thousands
    Math.abs(Number(closingamt_disp)) >= 1.0e3
    ? decimalFunc(Math.abs(Number(closingamt_disp)) / 1.0e3) + "k"
    : decimalFunc(Math.abs(Number(closingamt_disp)));
};

const optionsA = {
  cssClass: "my-custom-interface",
};

const BarChartInstance: React.FC<any> = ({
  timeDropDown,
  CardTitle,
  Total,
  IconsObj,
  chartData,
  setTimeFrame,
  tf,
  valuationDate,
}) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Octr",
    "Nov",
    "Dec",
  ];
  const date = new Date();

  //let  yearToDateText;
  // if (CardTitle === "Distribution"){
  //  yearToDateText = "Jan" +" "+ " - " + " "+monthNames[date.getMonth()] +" '"+date.getFullYear().toString().substr(-2);
  // }
  // else{
  //     yearToDateText = "Jan" +" "+ " - " + " "+monthNames[date.getMonth()-1] +" '"+date.getFullYear().toString().substr(-2);
  // }
  const [yearToDateVal, setyearToDateVal] = useState<string>("");
  useEffect(() => {
    var yearToDateText = "";
    var TotalNew;

    if (CardTitle === "Distribution") {
      yearToDateText =
        "Jan" +
        " " +
        " - " +
        " " +
        monthNames[date.getMonth()] +
        " '" +
        date
          .getFullYear()
          .toString()
          .substr(-2);
    } else {
      var d = new Date(valuationDate);
      yearToDateText =
        "Jan" +
        " " +
        " - " +
        " " +
        d.toLocaleString("en-us", { month: "short" }) +
        " '" +
        d
          .getFullYear()
          .toString()
          .substr(-2);
    }

    setyearToDateVal(yearToDateText);
  }, [valuationDate, CardTitle]);

  const [renderLine, setrenderLine] = useState<boolean>(false);

  useEffect(() => {
    chartData && chartData[0] && chartData[0].length > 0
      ? setrenderLine(true)
      : setrenderLine(false);
  }, [chartData]);

  const [isHovered, setIsHovered] = useState(false);
  const ToggleChartText = (e) => {
    isHovered ? setIsHovered(false) : setIsHovered(true);
  };

  const { height, width } = useWindowDimensions();
  return (
    <IonCol size-xs="12" size-sm="6" sizeLg="6">
      {/* <Axis /> */}
      <IonGrid style={styles.innerGrid}>
        <IonRow
          style={styles.titleRow}
          className={isHovered ? "text-hide" : ""}
        >
          <IonLabel class="fs-18" style={styles.title}>
            {CardTitle}
          </IonLabel>
          <IonRow>
            <IonItem lines="none" style={styles.datedwItem}>
              <IonSelect
                interface={width < 576 ? "action-sheet" : "popover"}
                interfaceOptions={optionsA}
                mode="ios"
                value={tf}
                onIonChange={(e) => {
                  // setselTime(e.detail.value)
                  setTimeFrame(e.detail.value);
                }}
                name="time"
              >
                {/* {timeDropDown.map(({ val }, i) => (
                                <IonSelectOption key={i} value={val}>
                                    {val}
                                </IonSelectOption>
                            ))} */}

                {timeDropDown.map(({ val }, i) =>
                  val == "Year-to-Date" ? (
                    <IonSelectOption key={i} value={val}>
                      {yearToDateVal}
                    </IonSelectOption>
                  ) : (
                    <IonSelectOption key={i} value={val}>
                      {val}
                    </IonSelectOption>
                  )
                )}
              </IonSelect>
              <IonIcon slot="end" color="gold" src={chevronDown}></IonIcon>
            </IonItem>
          </IonRow>
          <IonRow style={styles.moneyRow}>
            <IonIcon
              class="mobile-dollar"
              icon={dollar}
              color="white"
              style={styles.moneyIcon}
            />
            <IonLabel class="mobile-moneylabel" style={styles.moneyLabel}>
              {retTotalAmt(Total)}
            </IonLabel>
          </IonRow>
          {IconsObj.map((obj) => (
            <IonRow key={obj.label} style={styles.iconrow}>
              {obj.icon}
              <IonLabel style={styles.iconlabel}>{obj.label}</IonLabel>
            </IonRow>
          ))}
          <IonRow></IonRow>
        </IonRow>
      </IonGrid>
      <div style={styles.colbackL} onClick={(e) => ToggleChartText(e)}>
        {CardTitle === "Distribution" && renderLine ? (
          <GroupBarChart
            CardTitle={CardTitle}
            width={1400}
            height={200}
            chartData={chartData}
            tf={tf}
          />
        ) : CardTitle === "Reinvestment" && renderLine ? (
          <BarLineReinvestmentChart
            CardTitle={CardTitle}
            width={700}
            height={200}
            chartData={chartData}
            tf={tf}
          />
        ) : null}
        <div className="chartFade"></div>
        <div className="chartOverlay"></div>
      </div>
    </IonCol>
  );
};

export default BarChartInstance;
