import React, { CSSProperties, useEffect, useState } from "react";
import { IonGrid, IonIcon, IonRow, IonText } from "@ionic/react";
// import LineChart from './Line'
import "./Chart.css";
import { ellipse } from "ionicons/icons";
import ChartInstance from "./ChartInstance";
import BarChartInstance from "./BarChartInstance";
import personStar from "src/icons/personstar.svg";
import percent from "src/icons/percent.svg";
import api from "src/api";
import { Plugins } from "@capacitor/core";
import moment from "moment";
import CryptoJS from "crypto-js";
const { Storage } = Plugins;

interface StyleProps {
  rootGrid: CSSProperties;
  colbackL: CSSProperties;
  colbackR: CSSProperties;
  innerGrid: CSSProperties;
  title: CSSProperties;
  dropdownRow: CSSProperties;
  titleRow: CSSProperties;
  iconsize: CSSProperties;
}
const decrypt = (decryptVlaue) => {
  let passphrase =
    "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9";
  const bytes = CryptoJS.AES.decrypt(decryptVlaue, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};

const getUserId = async () => {
  const ret: any = await Storage.get({ key: "userId" });
  const temp = JSON.parse(decrypt(ret.value));
  return temp && temp.id ? temp.id : null;
};

const getUserEmail = async () => {
  const ret: any = await Storage.get({ key: "userId" });
  const temp = JSON.parse(decrypt(ret.value));
  return temp && temp.email ? temp.email : null;
};

const timeDropDown: any = [];
let thisdate = new Date();
let thisyear = thisdate.getFullYear();
let thismonth = thisdate.getMonth();
timeDropDown.push({ val: thisyear - 3 });
timeDropDown.push({ val: thisyear - 2 });
timeDropDown.push({ val: thisyear - 1 });
timeDropDown.push({ val: "Last 30 days" });
timeDropDown.push({ val: "Q1 " + thisyear });
if (thismonth > 2) {
  timeDropDown.push({ val: "Q2 " + thisyear });
}
if (thismonth > 5) {
  timeDropDown.push({ val: "Q3 " + thisyear });
}
if (thismonth > 8) {
  timeDropDown.push({ val: "Q4 " + thisyear });
}
timeDropDown.push({ val: "Year-to-Date" });
timeDropDown.push({ val: "Last 12 Months" });

const timeDropDownClientAUM: any = [];
timeDropDownClientAUM.push({ val: thisyear - 3 });
timeDropDownClientAUM.push({ val: thisyear - 2 });
timeDropDownClientAUM.push({ val: thisyear - 1 });
timeDropDownClientAUM.push({ val: "Q1 " + thisyear });
if (thismonth > 2) {
  timeDropDownClientAUM.push({ val: "Q2 " + thisyear });
}
if (thismonth > 5) {
  timeDropDownClientAUM.push({ val: "Q3 " + thisyear });
}
if (thismonth > 8) {
  timeDropDownClientAUM.push({ val: "Q4 " + thisyear });
}
timeDropDownClientAUM.push({ val: "Year-to-Date" });
timeDropDownClientAUM.push({ val: "Last 12 Months" });

const timeDropDownReinvest: any = [];
timeDropDownReinvest.push({ val: thisyear - 3 });
timeDropDownReinvest.push({ val: thisyear - 2 });
timeDropDownReinvest.push({ val: thisyear - 1 });
timeDropDownReinvest.push({ val: "Year-to-Date" });
timeDropDownReinvest.push({ val: "Q1 " + thisyear });
if (thismonth > 2) {
  timeDropDownReinvest.push({ val: "Q2 " + thisyear });
}
if (thismonth > 5) {
  timeDropDownReinvest.push({ val: "Q3 " + thisyear });
}
if (thismonth > 8) {
  timeDropDownReinvest.push({ val: "Q4 " + thisyear });
}

const styles: StyleProps = {
  rootGrid: {
    marginTop: 24,
  },
  colbackL: {
    height: 384,
    // background: "url(/assets/mesh.svg) no-repeat 100px 0px",
    backgroundColor: "#1A1A1A",
    paddingRight: 7.5,
  },
  colbackR: {
    height: 384,
    // background: "url(/assets/mesh.svg) no-repeat 100px 0px",
    backgroundColor: "#1A1A1A",
    paddingLeft: 7.5,
  },
  innerGrid: {
    position: "relative",
    zIndex: 2,
  },
  titleRow: {
    position: "absolute",
    top: 27,
    marginLeft: 40,
    display: "flex",
    flexDirection: "column",
  },
  dropdownRow: {
    position: "absolute",
    top: 11.5,
    marginLeft: 40,
  },
  title: {
    fontSize: 18,
    fontWeight: "normal",
  },
  iconsize: {
    height: 24,
    width: 24,
  },
};

interface ChartCompProps {}

const decimalFunc = (Num) => {
  let temp = Num.toString().split(".");
  let retVal = 0;
  if (temp[1]) {
    retVal = Num.toFixed(2);
  } else {
    retVal = Num;
  }
  return retVal;
};

const retExpClsAmt = (clsAmt) => {
  let closingamt_disp: number = 0;
  Number.isNaN(clsAmt) || clsAmt === null || clsAmt === ""
    ? (closingamt_disp = 0)
    : (closingamt_disp = parseInt(clsAmt));
  return Math.abs(Number(closingamt_disp)) >= 1.0e9
    ? decimalFunc(Math.abs(Number(closingamt_disp)) / 1.0e9) + "b"
    : // Six Zeroes for Millions
    Math.abs(Number(closingamt_disp)) >= 1.0e6
    ? decimalFunc(Math.abs(Number(closingamt_disp)) / 1.0e6) + "m"
    : // Three Zeroes for Thousands
    Math.abs(Number(closingamt_disp)) >= 1.0e3
    ? decimalFunc(Math.abs(Number(closingamt_disp)) / 1.0e3) + "k"
    : decimalFunc(Math.abs(Number(closingamt_disp)));
};
const getStartEndDates = (timeframeString: string) => {
  switch (timeframeString) {
    case "Next Year":
      return {
        startDate: `01-JAN-${moment(new Date())
          .add(1, "years")
          .format("YYYY")}`,
        endDate: `31-DEC-${moment(new Date())
          .add(1, "years")
          .format("YYYY")}`,
      };
    case "Last 12 Months":
      return {
        startDate: `${moment(new Date())
          .subtract(1, "years")
          .startOf("month")
          .format("DD-MMM-YYYY")}`,
        endDate: `${moment(new Date())
          .subtract(1, "month")
          .endOf("month")
          .format("DD-MMM-YYYY")}`,
      };
    case "Q1 " + moment(new Date()).format("YYYY"):
      return {
        startDate: `01-JAN-${moment(new Date()).format("YYYY")}`,
        endDate: `31-MAR-${moment(new Date()).format("YYYY")}`,
      };
    case "Q2 " + moment(new Date()).format("YYYY"):
      return {
        startDate: `01-APR-${moment(new Date()).format("YYYY")}`,
        endDate: `30-JUN-${moment(new Date()).format("YYYY")}`,
      };
    case "Q3 " + moment(new Date()).format("YYYY"):
      return {
        startDate: `01-JUL-${moment(new Date()).format("YYYY")}`,
        endDate: `30-SEP-${moment(new Date()).format("YYYY")}`,
      };
    case "Q4 " + moment(new Date()).format("YYYY"):
      return {
        startDate: `01-OCT-${moment(new Date()).format("YYYY")}`,
        endDate: `31-DEC-${moment(new Date()).format("YYYY")}`,
      };
    case "Last 30 days":
      return {
        startDate: `${moment(new Date())
          .subtract(31, "days")
          .format("DD-MMM-YYYY")}`,
        endDate: `${moment(new Date())
          .subtract(1, "days")
          .format("DD-MMM-YYYY")}`,
      };
    case "Year-to-Date":
      return {
        startDate: `01-JAN-${moment(new Date()).format("YYYY")}`,
        endDate: `${moment(new Date()).format("DD-MMM-YYYY")}`,
      };
    case moment(new Date())
      .subtract(1, "years")
      .format("YYYY"):
      return {
        startDate: `01-JAN-${moment(new Date())
          .subtract(1, "years")
          .format("YYYY")}`,
        endDate: `31-DEC-${moment(new Date())
          .subtract(1, "years")
          .format("YYYY")}`,
      };
    case moment(new Date())
      .subtract(2, "years")
      .format("YYYY"):
      return {
        startDate: `01-JAN-${moment(new Date())
          .subtract(2, "years")
          .format("YYYY")}`,
        endDate: `31-DEC-${moment(new Date())
          .subtract(2, "years")
          .format("YYYY")}`,
      };
    case moment(new Date())
      .subtract(3, "years")
      .format("YYYY"):
      return {
        startDate: `01-JAN-${moment(new Date())
          .subtract(3, "years")
          .format("YYYY")}`,
        endDate: `31-DEC-${moment(new Date())
          .subtract(3, "years")
          .format("YYYY")}`,
      };
    default:
      return {
        startDate: `01-JAN-2004`,
        endDate: `31-DEC-${moment(new Date()).format("YYYY")}`,
      };
  }
};

const getFilterBool = (fltStr, monthNum) => {
  switch (fltStr) {
    case "Q1 " + moment(new Date()).format("YYYY"):
      return monthNum === 1 || monthNum === 2 || monthNum === 3 ? true : false;
    case "Q2 " + moment(new Date()).format("YYYY"):
      return monthNum === 4 || monthNum === 5 || monthNum === 6 ? true : false;
    case "Q3 " + moment(new Date()).format("YYYY"):
      return monthNum === 7 || monthNum === 8 || monthNum === 9 ? true : false;
    case "Q4 " + moment(new Date()).format("YYYY"):
      return monthNum === 10 || monthNum === 11 || monthNum === 12
        ? true
        : false;
    case "Last 30 days":
      return monthNum ===
        moment(new Date())
          .subtract(31, "days")
          .get("month") ||
        monthNum ===
          moment(new Date())
            .subtract(1, "days")
            .get("month")
        ? true
        : false;
    default:
      return true;
  }
};

const BarChart: React.FC<ChartCompProps> = () => {
  const [dProsp, setdProsp] = useState<[number, number][]>([
    [0, 0],
    [1, 0],
    [2, 0],
    [3, 0],
    [4, 0],
    [5, 0],
    [6, 0],
    [7, 0],
    [8, 0],
    [9, 0],
    [10, 0],
    [11, 0],
    [12, 0],
    [13, 0],
  ]);

  const [dFund, setdFund] = useState<[number, number][]>([
    [0, 0],
    [1, 0],
    [2, 0],
    [3, 0],
    [4, 0],
    [5, 0],
    [6, 0],
    [7, 0],
    [8, 0],
    [9, 0],
    [10, 0],
    [11, 0],
    [12, 0],
    [13, 0],
  ]);
  const [dFundAllTime, setdFundAllTime] = useState<[number, number][]>([]);

  const [dTarg, setdTarg] = useState<[number, number][]>([
    [0, 0],
    [1, 0],
    [2, 0],
    [3, 0],
    [4, 0],
    [5, 0],
    [6, 0],
    [7, 0],
    [8, 0],
    [9, 0],
    [10, 0],
    [11, 0],
    [12, 0],
    [13, 0],
  ]);
  const [dAct, setdAct] = useState<[number, number][]>([
    [0, 0],
    [1, 0],
    [2, 0],
    [3, 0],
    [4, 0],
    [5, 0],
    [6, 0],
    [7, 0],
    [8, 0],
    [9, 0],
    [10, 0],
    [11, 0],
    [12, 0],
    [13, 0],
  ]);

  const [outflw, setoutflw] = useState<[number, number, number, number][]>([
    [0, 0, 0, 0],
    [1, 0, 0, 0],
    [2, 0, 0, 0],
    [3, 0, 0, 0],
    [4, 0, 0, 0],
    [5, 0, 0, 0],
    [6, 0, 0, 0],
    [7, 0, 0, 0],
    [8, 0, 0, 0],
    [9, 0, 0, 0],
    [10, 0, 0, 0],
    [11, 0, 0, 0],
  ]);

  const [reivst, setreivst] = useState<[number, number, number, number][]>([
    [0, 0, 0, 0],
    [1, 0, 0, 0],
    [2, 0, 0, 0],
    [3, 0, 0, 0],
    [4, 0, 0, 0],
    [5, 0, 0, 0],
    [6, 0, 0, 0],
    [7, 0, 0, 0],
    [8, 0, 0, 0],
    [9, 0, 0, 0],
    [10, 0, 0, 0],
    [11, 0, 0, 0],
  ]);

  const [cmtTf, setcmtTf] = useState<string>("Year-to-Date");
  const [targActTf, settargActTf] = useState<string>("Year-to-Date");

  const [outflow, setoutflow] = useState<string>("Year-to-Date");
  const [reinvestment, setreinvestment] = useState<string>("Year-to-Date");

  const [maxCmt, setMaxCmt] = useState<number>(0);

  const [maxProsp, setmaxProsp] = useState<number>(0);
  const [prosTrans, setprosTrans] = useState<number>(0);
  const [convRatio, setconvRatio] = useState<number>(0);

  const [TotCapital, setTotCapital] = useState<number>(0);
  const [TotIncome, setTotIncome] = useState<number>(0);
  const [TotCapInc, setTotCapInc] = useState<number>(0);
  const [TotCapGain, setTotCapGain] = useState<number>(0);
  const [TotReinvestment, setTotReinvestment] = useState<number>(0);
  //

  const [maxFund, setmaxFund] = useState<number>(0);
  const [clientTrans, setClientTrans] = useState<number>(0);
  const [clientConvRatio, setClientConvRatio] = useState<number>(0);

  const [render, setrender] = useState<boolean>(false);
  const [renderreinvest, setrenderreinvest] = useState<boolean>(false);
  const [renderoutflw, setrenderoutflw] = useState<boolean>(false);
  const [valuationDate, setValuationDate] = useState<string>("");

  // const [renderCmt, setrenderCmt] = useState<boolean>(false)
  // const [renderConv, setrenderConv] = useState<boolean>(false)

  const setCommitmentTimeframe = (str) => {
    setcmtTf(str);
  };

  const setTargActTimeframe = (str) => {
    settargActTf(str);
  };

  const setOutflowTimeframe = (str) => {
    setoutflow(str);
  };
  const setReinvestmentTimeframe = (str) => {
    setreinvestment(str);
  };

  useEffect(() => {
    getUserEmail().then((useremail) => {
      if (useremail && useremail !== null && useremail !== "") {
        const { startDate, endDate } = getStartEndDates(outflow.toString());
        api.getClientOutflow(useremail, startDate, endDate).then((resp) => {
          let tempArrOutflow: any = [
            [1, 0, 0, 0, 0],
            [2, 0, 0, 0, 0],
            [3, 0, 0, 0, 0],
            [4, 0, 0, 0, 0],
            [5, 0, 0, 0, 0],
            [6, 0, 0, 0, 0],
            [7, 0, 0, 0, 0],
            [8, 0, 0, 0, 0],
            [9, 0, 0, 0, 0],
            [10, 0, 0, 0, 0],
            [11, 0, 0, 0, 0],
            [12, 0, 0, 0, 0],
          ];
          let tempTotCap: number = 0;
          let tempTotInc: number = 0;
          let tempCapGain: number = 0;
          if (resp.data && resp.data.length > 0) {
            resp.data.forEach((respobj) => {
              for (const x of tempArrOutflow) {
                if (x[0] === parseInt(respobj.month)) {
                  x[1] = parseInt(respobj.year);
                  x[2] = parseFloat(respobj.incomeDistribution);
                  x[3] = parseFloat(respobj.capitalReturn);
                  x[4] = parseFloat(respobj.capitalGain);
                }
              }
              tempTotCap = tempTotCap + parseFloat(respobj.capitalReturn);
              tempTotInc = tempTotInc + parseFloat(respobj.incomeDistribution);
              tempCapGain = tempCapGain + parseFloat(respobj.capitalGain);
            });
          }

          setrenderoutflw(false);
          setoutflw(tempArrOutflow);
          tempTotCap ? setTotCapital(tempTotCap) : setTotCapital(0);
          tempTotInc ? setTotIncome(tempTotInc) : setTotIncome(0);
          tempCapGain ? setTotCapGain(tempCapGain) : setTotCapGain(0);
          setTotCapInc(tempTotCap + tempTotInc + tempCapGain);
        });
      }
    });
  }, [outflow]);

  useEffect(() => {
    getUserEmail().then((useremail) => {
      if (useremail && useremail !== null && useremail !== "") {
        let VstartDate;
        let VendDate;
        api.getValutionDate().then((resp) => {
          if (reinvestment.toString() !== "Year-to-Date") {
            const { startDate, endDate } = getStartEndDates(
              reinvestment.toString()
            );
            VstartDate = startDate;
            VendDate = endDate;
          } else {
            VstartDate = `01-JAN-${moment(
              resp.data.valuation_date,
              "YYYY-MM-DD"
            ).year()}`;
            VendDate = `${moment(resp.data.valuation_date).format(
              "DD-MMM-YYYY"
            )}`;
          }

          //api.getClientDashboardReinvestment("s.shehadeh@tfoco.com", startDate, endDate).then((resp) => {
          api
            .getClientDashboardReinvestment(useremail, VstartDate, VendDate)
            .then((resp) => {
              //setoutflw
              setValuationDate(resp.data.valuation_date);
              let tempArrReinvest: any = [
                [1, 0, 0, 0],
                [2, 0, 0, 0],
                [3, 0, 0, 0],
                [4, 0, 0, 0],
                [5, 0, 0, 0],
                [6, 0, 0, 0],
                [7, 0, 0, 0],
                [8, 0, 0, 0],
                [9, 0, 0, 0],
                [10, 0, 0, 0],
                [11, 0, 0, 0],
                [12, 0, 0, 0],
              ];
              let tempTotReinvest: number = 0;
              resp.data = resp.data.conversions;
              if (resp.data && resp.data.length > 0) {
                resp.data.forEach((respobj) => {
                  for (const x of tempArrReinvest) {
                    if (x[0] === parseInt(respobj.month)) {
                      x[1] = parseInt(respobj.year);
                      x[2] = parseFloat(
                        respobj.reinvestmentAmount
                          ? respobj.reinvestmentAmount
                          : 0
                      );
                      x[3] = parseFloat(
                        respobj.totalReinvestmentAmount
                          ? respobj.totalReinvestmentAmount
                          : 0
                      );
                    }
                    tempTotReinvest =
                      parseFloat(respobj.totalReinvestmentAmount) +
                      parseFloat(respobj.reinvestmentAmount);
                  }
                });
              }
              setrenderreinvest(false);
              setreivst(tempArrReinvest);
              tempTotReinvest
                ? setTotReinvestment(tempTotReinvest)
                : setTotReinvestment(0);
            });
        });
      }
    });
  }, [reinvestment]);

  useEffect(() => {
    // setrenderCmt(false)
    getUserId().then((userId) => {
      if (userId && userId !== null && userId !== "") {
        const { startDate, endDate } = getStartEndDates(cmtTf.toString());
        api
          .getClientCommitmentForecast(parseInt(userId), startDate, endDate)
          .then((resp) => {
            if (resp.data && resp.data.length > 0) {
              let tempArrTarg: any = [
                [0, 0],
                [1, 0],
                [2, 0],
                [3, 0],
                [4, 0],
                [5, 0],
                [6, 0],
                [7, 0],
                [8, 0],
                [9, 0],
                [10, 0],
                [11, 0],
                [12, 0],
                [13, 0],
              ];
              let tempArrAct: any = [
                [0, 0],
                [1, 0],
                [2, 0],
                [3, 0],
                [4, 0],
                [5, 0],
                [6, 0],
                [7, 0],
                [8, 0],
                [9, 0],
                [10, 0],
                [11, 0],
                [12, 0],
                [13, 0],
              ];
              let tempTotAct: number = 0;
              resp.data.forEach((respobj) => {
                for (const x of tempArrTarg) {
                  if (x[0] === parseInt(respobj.month)) {
                    x[1] = parseFloat(respobj.targetAmount);
                  }
                }
                for (const y of tempArrAct) {
                  if (y[0] === parseInt(respobj.month)) {
                    y[1] = parseFloat(respobj.actualAmounts);
                  }
                }
                tempTotAct = tempTotAct + parseFloat(respobj.actualAmounts);
              });
              setdTarg([]);
              setdAct([]);
              setdTarg(tempArrTarg);
              setMaxCmt(tempTotAct);
              setdAct(tempArrAct);
            }
            // setrenderCmt(true)
          });
      }
    });
  }, [cmtTf]);

  useEffect(() => {
    // setrenderConv(false)
    getUserId().then((userId) => {
      const { startDate, endDate } = getStartEndDates(targActTf.toString());
      api.getProspConv(userId, startDate, endDate).then((resp) => {
        if (resp.data && resp.data.length > 0) {
          let tempArrConv: any = [
            [0, 0],
            [1, 0],
            [2, 0],
            [3, 0],
            [4, 0],
            [5, 0],
            [6, 0],
            [7, 0],
            [8, 0],
            [9, 0],
            [10, 0],
            [11, 0],
            [12, 0],
            [13, 0],
          ];
          let tempTotAmt: number = 0;
          resp.data.forEach((respobj) => {
            for (const x of tempArrConv) {
              if (x[0] === parseInt(respobj.month)) {
                x[1] = parseInt(respobj.amountPerMonth);
              }
            }
            tempTotAmt = tempTotAmt + parseInt(respobj.amountPerMonth);
          });
          setdProsp([]);
          setdProsp(tempArrConv);
          setmaxProsp(tempTotAmt);

          let totalProspects = parseInt(resp.data[0].totalProspects);
          let totalTransitioned = parseInt(resp.data[0].totalTransitioned);

          setprosTrans(totalTransitioned);
          setconvRatio(
            totalProspects === 0 ? 0 : totalTransitioned / totalProspects
          );
        }
        // setrenderConv(true)
      });
    });

    getUserEmail().then((useremail) => {
      //ALOAPMA Changes for client
      let VstartDate;
      let VendDate;
      api.getValutionDate().then((resp) => {
        if (targActTf.toString() !== "Year-to-Date") {
          const { startDate, endDate } = getStartEndDates(targActTf.toString());
          VstartDate = startDate;
          VendDate = endDate;
        } else {
          VstartDate = `01-JAN-${moment(
            resp.data.valuation_date,
            "YYYY-MM-DD"
          ).year()}`;
          VendDate = `${moment(resp.data.valuation_date).format(
            "DD-MMM-YYYY"
          )}`;
        }

        // const { startDate, endDate } = getStartEndDates(targActTf.toString())
        api
          .getClientFundRaised(useremail, VstartDate, VendDate)
          .then((resp) => {
            //api.getClientFundRaised("s.shehadeh@tfoco.com", startDate, endDate).then((resp) => {
            let dataflg: boolean = false;
            let tempTotFund: number = 0;
            let tempArrConv: any = [
              [0, 0],
              [1, 0],
              [2, 0],
              [3, 0],
              [4, 0],
              [5, 0],
              [6, 0],
              [7, 0],
              [8, 0],
              [9, 0],
              [10, 0],
              [11, 0],
              [12, 0],
              [13, 0],
            ];

            let totalClients = 0;
            let totalTransitionedClients = 0;
            let tempArrayClientFunds: any = [];
            resp.data = resp.data.conversions;
            if (targActTf == "All Time") {
              if (resp.data && resp.data.length > 0) {
                var newSortedArray: any = [];
                tempArrayClientFunds.push([0, 0]);
                let k: number;
                for (k = 0; k < Number(new Date().getFullYear()) - 2004; k++) {
                  let temporaryarr: any = [];
                  temporaryarr = resp.data.filter(function(el) {
                    return (
                      el.year == k + 2004 &&
                      (el.month == 12 ? el.month == 12 : el.month == 11)
                    );
                  });
                  tempArrayClientFunds.push([
                    k,
                    temporaryarr[0] ? temporaryarr[0].aum : 0,
                  ]);
                }
                tempArrayClientFunds.push([k, 0]);
                resp.data.forEach((respobj, i) => {});
                totalClients = parseInt(resp.data[0].totalClients);
                totalTransitionedClients = parseInt(resp.data[0].numClients);
                tempTotFund = parseInt(resp.data[0].totalAUM);
              }

              setrender(false);
              setdFund([]);
              setdFund(tempArrayClientFunds);
              setdFundAllTime([]);
              setdFundAllTime(tempArrayClientFunds);
              setmaxFund(tempTotFund);
              setClientTrans(totalTransitionedClients);
              setClientConvRatio(
                totalClients === 0
                  ? 0
                  : (totalTransitionedClients / totalClients) * 100
              );
            } else if (
              targActTf == "Year-to-Date" &&
              (resp.data.length == 0 || resp.data == null)
            ) {
              const { startDate, endDate } = getStartEndDates(
                moment(new Date())
                  .subtract(1, "years")
                  .format("YYYY")
              );
              api
                .getClientFundRaised(useremail, startDate, endDate)
                .then((resp) => {
                  resp.data = resp.data.conversions;
                  if (resp.data && resp.data?.length > 0) {
                    totalClients = parseInt(resp.data[0].totalClients);
                    totalTransitionedClients = parseInt(
                      resp.data[0].numClients
                    );
                    tempTotFund = parseInt(resp.data[0].totalAUM);
                  }

                  setdFund([]);
                  setdFund(tempArrConv);
                  setdFundAllTime([]);
                  setdFundAllTime(tempArrayClientFunds);
                  setmaxFund(tempTotFund);
                  setClientTrans(totalTransitionedClients);
                  setClientConvRatio(
                    totalClients === 0
                      ? 0
                      : (totalTransitionedClients / totalClients) * 100
                  );
                });
            } else {
              if (resp.data && resp.data.length > 0) {
                resp.data.forEach((respobj) => {
                  for (const x of tempArrConv) {
                    if (x[0] === parseInt(respobj.month)) {
                      dataflg = true;
                      x[1] = parseInt(respobj.aum);
                      if (targActTf != "Last 12 Months") {
                        tempTotFund = parseInt(respobj.aum);
                      }
                    }
                  }
                });
                totalClients = parseInt(resp.data[0].totalClients);
                totalTransitionedClients = parseInt(resp.data[0].numClients);
                if (targActTf == "Last 12 Months") {
                  tempTotFund = parseInt(resp.data[0].totalAUM);
                }
              }
              setdFund([]);
              setdFund(tempArrConv);
              setdFundAllTime([]);
              setdFundAllTime(tempArrayClientFunds);
              setmaxFund(tempTotFund);
              setClientTrans(totalTransitionedClients);
              setClientConvRatio(
                totalClients === 0
                  ? 0
                  : (totalTransitionedClients / totalClients) * 100
              );
            }
          });
      });
    });
  }, [targActTf]);

  useEffect(() => {
    if (!render) {
      if (
        dProsp.length > 0 &&
        dTarg.length > 0 &&
        dAct.length > 0 &&
        dFund.length > 0
      ) {
        setrender(true);
      }
    }
  }, [dProsp, dTarg, dAct, dFund]);

  useEffect(() => {
    if (!renderoutflw) {
      if (outflw) {
        setrenderoutflw(true);
      }
    }
  }, [outflw]);

  useEffect(() => {
    if (!renderreinvest) {
      if (reivst) {
        setrenderreinvest(true);
      }
    }
  }, [reivst]);

  return (
    <IonGrid class="ml-13 mr-13" style={styles.rootGrid}>
      <IonRow>
        {valuationDate ? (
          <ChartInstance
            valuationDate={valuationDate}
            timeDropDown={timeDropDownClientAUM}
            CardTitle="AUM"
            Total={maxFund}
            Chartname="AUM"
            IconsObj={[
              {
                label: `${clientTrans} Clients`,
                icon: (
                  <IonIcon
                    icon={personStar}
                    style={{ color: "#BFC5F4", ...styles.iconsize }}
                  />
                ),
              },
              {
                label: `${clientConvRatio.toFixed(1)}% of Total Clients`,
                icon: (
                  <IonIcon
                    icon={percent}
                    style={{ color: "#BFC5F4", ...styles.iconsize }}
                  />
                ),
              },
            ]}
            chartData={render && dFund.length > 0 ? [dFund] : [[]]}
            tf={targActTf}
            setTimeFrame={setTargActTimeframe}
          />
        ) : null}
        {valuationDate ? (
          // true
          <BarChartInstance
            timeDropDown={timeDropDown}
            CardTitle="Distribution"
            Total={TotCapInc}
            IconsObj={[
              {
                label: retExpClsAmt(TotIncome) + " Income Distributions",
                icon: (
                  <IonIcon
                    icon={ellipse}
                    style={{ color: "#B99855", ...styles.iconsize }}
                  />
                ),
              },
              {
                label: retExpClsAmt(TotCapital) + " Capital Returns",
                icon: (
                  <IonIcon
                    icon={ellipse}
                    style={{ color: "#40412F", ...styles.iconsize }}
                  />
                ),
              },
              {
                label: retExpClsAmt(TotCapGain) + " Capital Gains",
                icon: (
                  <IonIcon
                    icon={ellipse}
                    style={{ color: "#44344C", ...styles.iconsize }}
                  />
                ),
              },
            ]}
            chartData={
              renderoutflw && outflw.length ? [outflw] : [[]]
              //render && dFund.length > 0 ? [dFund]: [[]]
            }
            tf={outflow}
            setTimeFrame={setOutflowTimeframe}
          />
        ) : null}
      </IonRow>
      <IonRow>
        {valuationDate ? (
          <ChartInstance
            timeDropDown={timeDropDown}
            // CardTitle="Total Commitment Forecast"
            // CardTitle="New Money"
            // Chartname="New Money"
            CardTitle="Signed Commitments"
            Chartname="Signed Commitments"
            Total={maxCmt}
            IconsObj={[
              {
                label: "Target",
                icon: (
                  <IonIcon
                    icon={ellipse}
                    style={{ color: "#BFC5F4", ...styles.iconsize }}
                  />
                ),
              },
              {
                label: "Actual",
                icon: (
                  <IonIcon
                    icon={ellipse}
                    style={{ color: "#F7B198", ...styles.iconsize }}
                  />
                ),
              },
            ]}
            chartData={
              render && dTarg.length > 0 && dAct.length > 0
                ? [dTarg, dAct]
                : [[]]
            }
            tf={cmtTf}
            setTimeFrame={setCommitmentTimeframe}
          />
        ) : null}
        {valuationDate ? (
          <BarChartInstance
            valuationDate={valuationDate}
            timeDropDown={timeDropDownReinvest}
            CardTitle="Reinvestment"
            Total={TotReinvestment}
            IconsObj={[
              {
                label: "My Clients",
                icon: (
                  <IonIcon
                    icon={ellipse}
                    style={{ color: "#B99855", ...styles.iconsize }}
                  />
                ),
              },
            ]}
            chartData={renderreinvest && reivst.length ? [reivst] : [[]]}
            tf={reinvestment}
            setTimeFrame={setReinvestmentTimeframe}
          />
        ) : null}
      </IonRow>
    </IonGrid>
  );
};

export default BarChart;
