import React, { useEffect, useState } from "react";
import './ClientData.css';
import moment from 'moment';
import CryptoJS from 'crypto-js';
import {
    IonAvatar,
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCol,
    IonGrid,
    IonItem,
    IonLabel,
    IonRow,
    IonList,
    IonInfiniteScroll,    
} from "@ionic/react";
import { style } from "d3";
import api from 'src/api';
import { Plugins } from "@capacitor/core";
const { Storage } = Plugins

const imgbaseuri = "https://cdn2.hubspot.net/";

const styles = {
    card: {
        "--background": "#1A1A1A",
        borderRadius: 8,
    },
    grid: {
        marginLeft: -24,
        marginRight: 0,
        marginTop: 24,
        padding: 0
    },
    item: {
        height: 80,
    },
    scroll: {
        maxHeight:260,
        overflowX: "hidden",
        overflowY: "scroll",
        height: 296,
        left: 24,
        right: 24,
        top: 24
    
    },
    col: {
        padding: 8,
    },
    row:{
        // left:70,
    }

}

// const clientinfo = [
//     {
//         id: 1,
//         text: "AbdulRahman Jassim Ahmed",
//         amount:"$100 AUM"
//     },
//     {
//         id: 2,
//         text: "Rashed Khaled Musaed",
//         amount:"$70 AUM"
//     },
//     {
//         id: 3,
//         text: "AbdulAziz Hamad Mousa",
//         amount:"$40 AUM"
//     },
//     {
//         id: 4,
//         text: "AbdulRahman Jassim Ahmed",
//         amount:"$100 AUM"
//     },
//     {
//         id: 5,
//         text: "Rashed Khaled Musaed",
//         amount:"$70 AUM"
//     },
//     {
//         id: 6,
//         text: "AbdulAziz Hamad Mousa",
//         amount:"$40 AUM"
//     }
// ]



// const clientLastCt= [
//     {
//         id: 1,
//         text: "Hashem Abdullah Qassim",
//         date:"Oct 19, 2020"
//     },
//     {
//         id: 2,
//         text: "Nasser Mohammed Ali",
//         date:"Oct 18, 2020"
//     },
//     {
//         id: 3,
//         text: "Salman Osama Saad",
//         date:"Oct 15, 2020"
//     },
//     {
//         id: 4,
//         text: "Hashem Abdullah Qassim",
//         date:"Oct 19, 2020"
//     },
//     {
//         id: 5,
//         text: "Nasser Mohammed Ali",
//         date:"Oct 18, 2020"
//     },
//     {
//         id: 6,
//         text: "Salman Osama Saad",
//         date:"Oct 15, 2020"
//     }
// ]

const decimalFunc = (Num) => {
    let temp = Num.toString().split(".");
    let retVal = 0;
    if (temp[1]) {
      retVal = Num.toFixed(2);
    } else {
      retVal = Num;
    }
    return retVal;
  };
  const retExpClsAmt = (clsAmt) => {
    let closingamt_disp: number = 0;
    Number.isNaN(clsAmt) || clsAmt === null || clsAmt === ""
      ? (closingamt_disp = 0)
      : (closingamt_disp = parseInt(clsAmt));
    return Math.abs(Number(closingamt_disp)) >= 1.0e9
      ? decimalFunc(Math.abs(Number(closingamt_disp)) / 1.0e9) + "b"
      : // Six Zeroes for Millions
      Math.abs(Number(closingamt_disp)) >= 1.0e6
      ? decimalFunc(Math.abs(Number(closingamt_disp)) / 1.0e6) + "m"
      : // Three Zeroes for Thousands
      Math.abs(Number(closingamt_disp)) >= 1.0e3
      ? decimalFunc(Math.abs(Number(closingamt_disp)) / 1.0e3) + "k"
      : decimalFunc(Math.abs(Number(closingamt_disp)));
  };
 
  const decrypt = (decryptVlaue) => {
    let passphrase = "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9"
    const bytes = CryptoJS.AES.decrypt(decryptVlaue, passphrase);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  };
const getUserId = async () => {
    const ret:any = await Storage.get({ key: "userId"})
    return JSON.parse(decrypt(ret.value)).id
}

const getUserEmail = async () => {
    const ret: any = await Storage.get({ key: "userId" })
    const temp = JSON.parse(decrypt(ret.value))
    return temp && temp.email ? temp.email : null
}

const ClientData = () => {

    const [clientLastCt, setclientLastCt] = useState<any>([]);
    // const [clientAUMList, setClientAUMList] = useState<any>([]);
    const [clientinfo, setclientinfo] = useState<any>([]);
    const [clientinfoflg, setclientinfoflg] = useState<any>([]);
    let temporaryClientArray = [];
    useEffect(() => {        
            // setLoading(true);
            getUserId().then((userId) => {    
        let payload: any = { "query": "", properties: ["hs_avatar_filemanager_key","hubspot_owner_id","notes_last_contacted","firstname","lastname"] }
        let filterGroups = [
                {
                    filters: [
                        {
                            operator: "EQ",
                            propertyName: "hubspot_owner_id",
                            value: parseInt(userId)
                        },
                        {
                            operator:"HAS_PROPERTY",
                            propertyName: "hs_lifecyclestage_customer_date"
                        },
                        {
                            operator: "HAS_PROPERTY",
                            propertyName: "notes_last_contacted"
                        }
                    ]
                   
                }
            ]
        api.searchProspect({ ...payload, filterGroups }).then(resp => {
            if(resp && resp.results && resp.results.length > 0)
            {
                let tmpArr = resp.results
                tmpArr.sort((a, b) => {
                      return b.properties.notes_last_contacted > a.properties.notes_last_contacted ? 1 : -1;
                    })
                tmpArr = tmpArr.filter((obj, i) => i < 6)
                setclientLastCt(tmpArr)
                // console.log(tmpArr)
            }
         })
    }) 

    getUserEmail().then((userEmail) => {
            let tempClientArr: any = []
            api.getClientAUM(userEmail).then((resp)=>{
                // console.log(resp);
                let filterResp = resp.filter((obj, i) => i < 6)
                filterResp.map((obj,i) =>{
                    // console.log(obj);
                    // let payload: any = { "query": "", properties: ["hs_avatar_filemanager_key,firstname,lastname,onboarding_id"] }
                    // let filterGroups = [
                    //     {
                    //         filters: [
                    //             {
                    //                 operator: "EQ",
                    //                 propertyName: "onboarding_id",
                    //                 value:obj.clientId
                    //             },
                    //         ]
                           
                    //     }
                    // ]
                    let tempList = {}
                  //  api.searchProspect({ ...payload, filterGroups }).then((contresp) =>{
                         tempList = {
                             id: i,
                             text: obj.firstName  && obj.lastName ? obj.firstName +" "+obj.lastName : obj.firstName?obj.firstName:obj.lastName?obj.lastName:"-",
                             amount: retExpClsAmt(obj.aum),
                             rawamt: obj.aum,
                             avatar: ""
                         }  
                        tempClientArr.push(tempList);
                        
                    //})
                                     
                })
                tempClientArr.sort(function(a, b) {
                    return parseInt(a.id) - parseInt(b.id);
                });
                // console.log(tempClientArr);
                setclientinfo(tempClientArr) ;
                //console.log("before sorting",tempClientArr)
                   // let temporaryClientArray = tempClientArr;

                    // console.log("new ay---",temporaryClientArray);
                    // if(temporaryClientArray)
                    // {
                    //     console.log(temporaryClientArray);
                        
                    // }                    
                
               // console.log("after sorting",temporaryClientArray);
        })
    })
        
    }, [])

    // useEffect(() => { 
    //     console.log(clientinfoflg);
    //     var temporaryClientArray = clientinfoflg;
    //     temporaryClientArray.sort((a, b) => parseFloat(b.rawamt) - parseFloat(a.rawamt));
    //     console.log(temporaryClientArray);
    //     setclientinfo(temporaryClientArray)
    //     // if( clientinfoflg.length > 0)
    //     // {
    //     //     console.log(clientinfoflg);
    //     // }
    // }, [clientinfoflg])

    // function sortSetClientInfo(tempArr)
    // {
    //     console.log("inside",tempArr);
    //     tempArr.sort(function(a, b) {
    //         console.log("hello");
    //         return parseFloat(b.id) - parseFloat(a.id);
    //     });
    //     let arraytemp:any = [];
    //     tempArr.filter(obj => {
    //         let retVal = true
    //         for(var i=0;i<6;i++) {
    //             if(obj.id === i) {
    //                 arraytemp.push(obj);
    //             }
    //         }
            
    //     })
    //     console.log(arraytemp);

    //     console.log("outside",tempArr);
        
    // }

    
    return (
       < React.Fragment>
       <IonGrid style={styles.grid}>
             <IonRow>
             <IonCol size-xs="12" size-lg="6" size-sm="6" style={styles.col}>   
             <IonRow className="RowHeading font18 m-card-header-ml-20" style={{ fontWeight: 400 }}>Clients by AUM 
                 {/* <IonText style={{ paddingLeft:"20px"}} >Clients by AUM </IonText> */}
                 </IonRow>
             {/* <IonInfiniteScroll style={styles.scroll}> */}
        <IonCard className="ClientDataAUMCard"style={styles.card}>
        <IonInfiniteScroll style={styles.scroll}>
            <IonCardHeader>
            <IonList className="ClientDataAUMList">
       { clientinfo.map((item)=>
       <IonItem lines="full" style={styles.item} key={item.id}>
           <IonAvatar slot="start">
           <img
                  src={
                    item.avatar
                        ? `${imgbaseuri}${item.avatar}`
                        : "/assets/empty-avatar.svg"
                  }
                  alt=""
                />
          </IonAvatar>
          <IonLabel>
           <IonRow className="Row1">{item.text}</IonRow>
           <IonRow className="Row2">${item.amount} AUM</IonRow> 
           </IonLabel>
       </IonItem>)}
       </IonList>
       </IonCardHeader>
       </IonInfiniteScroll>
       <IonCardContent>
       <IonRow>
       {clientinfo.length > 0 ? 
           <IonButton
                            color="gold"
                            size="small"
                            fill="clear"
                            style={{ marginLeft: "auto" ,marginRight:"auto"}}
                            routerLink="/clients/all"
                            mode="ios"
                        >
                            View Full list
                </IonButton>
            : null}
           </IonRow>
       </IonCardContent>
       </IonCard>
       {/* </IonInfiniteScroll> */}
       </IonCol>
       <IonCol size-xs="12"  size-lg="6" size-sm="6" style={styles.col}>
       <IonRow className="RowHeading font18 m-card-header-ml-20" style={{ fontWeight: 400 }}>Clients Last Contacted</IonRow>
       {/* <IonInfiniteScroll style={styles.scroll}> */}
        <IonCard className="ClientDatLastContactCard"style={styles.card}>
        <IonInfiniteScroll style={styles.scroll}>
            <IonCardHeader>
        <IonList className="ClientDataLastContactList">
         { clientLastCt.map(items =>
          <IonItem lines="full" style={styles.item} key={items.id}>
           <IonAvatar slot="start">
           <img
                  src={
                    items.properties.hs_avatar_filemanager_key
                        ? `${imgbaseuri}${items.properties.hs_avatar_filemanager_key}`
                        : "/assets/empty-avatar.svg"
                  }
                  alt=""
                />
          </IonAvatar>
          <IonLabel>
           <IonRow className="Row1">{items.properties.firstname && items.properties.lastname ? items.properties.firstname+" "+items.properties.lastname: ""}</IonRow>
           <IonRow className="Row2">{items.properties.notes_last_contacted ? moment(items.properties.notes_last_contacted).format("MMM DD, YYYY"): ""}</IonRow> 
           </IonLabel>
           </IonItem>
       )}
       </IonList>
       </IonCardHeader>
       </IonInfiniteScroll>
       <IonCardContent>
           <IonRow>
               {clientLastCt.length > 0 ? 
           <IonButton
                color="gold"
                size="small"
                fill="clear"
                style={{ marginLeft: "auto" ,marginRight:"auto"}}
                routerLink="/clients/all"
                mode="ios"
            >
                View Full list
            </IonButton>
            :null}
           </IonRow>
       </IonCardContent>
       
       </IonCard>
       </IonCol>
       </IonRow>
       </IonGrid>
       </React.Fragment>
    );
};
export default ClientData;