import React, { RefObject, useCallback, useEffect, useState } from "react";
import {
  IonGrid,
  IonRow,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonSlide,
  IonSlides,
  IonLabel,
  IonCheckbox,
  IonText,
  IonBadge,
  IonPopover,
  IonCol, //, IonPopover, IonFooter, IonTitle, IonItem
} from "@ionic/react";
// import { JourneyStagesArr } from "./Stages";
import "./JourneyStages.css";
import { retStagesArr, getStageIndex } from "./StageUtils";
import api from "src/api";

const styles = {
  root: {
    marginRight: 32,
    marginLeft: 32,
    position: "relative",
  },
  label: {
    // marginLeft: 16,
    marginBottom: 32,
    fontSize: 18,
  },
  card: {
    backgroundColor: "#263134",
    borderRadius: 0,
    padding: 0,
    margin: 0,
    height: 276,
    width: "100%",
    boxShadow: "rgba(0, 0, 0, 0.7) 0px -16px 16px ",
  },
  slide: {
    marginTop: 32,
    marginRight: -32,
    marginLeft: -32,
  },
  slideBackdrop: {
    marginTop: 32,
    marginRight: -32,
    marginLeft: -32,
    height: 276,
  },
  row: {
    display: "flex",
  },
  cardTitle: {
    textAlign: "left",
    fontSize: 16,
  },
  cardSubtitle: {
    fontSize: 14,
    color: "#fff",
  },
  cardcontent: {
    textAlign: "left",
  },
  cardMilestone: {
    fontSize: 18,
  },
  cardMilestone1: {
    fontSize: 10,
  },
  check: {
    "--size": "20px",
  },
};

interface InProps {
  contact: any;
  journeystage: any;
  JourneyStagesHub: any;
}

const JourneyStages: React.FC<InProps> = ({
  contact,
  journeystage,
  JourneyStagesHub,
}) => {
  const [actSlide, setactSlide] = useState<number>(0);
  // const [actSlideID, setactSlideID] = useState<number>(0);
  const [showSlides, setshowSlides] = useState<boolean>(false);
  const [nextStage, nextStageFunc] = useState<any>("true");
  const [nextStageName, nextStageNameFunc] = useState<any>("true");
  const [prevStage, prevStageFunc] = useState<any>("false");
  // const [prospstg, prospstgFunc] = useState<any>();
  const [StagesArr, setStagesArr] = useState<any>([]);

  const slideRef: RefObject<HTMLIonSlidesElement> = React.createRef<
    HTMLIonSlidesElement
  >();

  const slideOpts = {
    initialSlide: actSlide,
    speed: 400,
    // slidesPerView: 2,
    // slidesPerGroup: 3,
    updateOnWindowResize: true,
    centeredSlides: true,
    centeredSlidesBounds: true,
    width: 300,
    freeMode: true,
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    },
    spaceBetween: 0,
  };

  const retActClass = (stage) => {
    return stage.active ? "mobile-activeStage" : "inactiveStage";
  };
  const retActClassTitle = (stage) => {
    return stage.active ? "" : "inactiveStageTitle";
  };
  const retActClassLine = (stage) => {
    return stage.active ? "mobile-for-num-line" : "for-num-line";
  };

  // useEffect(() => {
  //     slideRef.current?.slideTo(actSlideID)
  // },[actSlideID])

  /* const onSlideChange = async (e:any) => {
        let actIndex: number = 0;
        await e.target.getActiveIndex().then((value: any) => actIndex = value)
        // console.log(actIndex)
    } */

  const indexcheck = useCallback(
    (val) => {
      return val.milestoneTitle === nextStage;
    },
    [nextStage]
  );

  // let StagesArr = [...JourneyStagesHub]//API Based
  // loop to set Active

  // let StagesArr = [...JourneyStagesArr]
  // let StagesArr: any = []

  useEffect(() => {
    const getLabelVal = (output, str) => {
      let retVal = "";
      if (output === "value") {
        for (const stage of JourneyStagesHub) {
          if (str === stage.label || str === stage.val) {
            retVal = stage.val;
            break;
          }
        }
      } else if (output === "label") {
        for (const stage of JourneyStagesHub) {
          if (str === stage.label || str === stage.val) {
            retVal = stage.label;
            break;
          }
        }
      }
      return retVal;
    };

    // const prospstg = contact.properties.lifecycle_stage_status;
    const prospstg = contact.properties.lead_stage;
    
    if (
      JourneyStagesHub &&
      JourneyStagesHub.length > 0 &&
      !(prospstg === "" || prospstg === null)
    ) {
      //     setStagesArr(retStagesArr(JourneyStagesHub).forEach(s => {
      //     if(s.milestoneTitle === prospstg) {
      //         s.completed = false
      //         s.active = true
      //         s.milestoneMsg = "Current milestone"
      //         setactSlide(s.id - 2 + 0.1)
      //         // setactSlideID(s.id - 1.5)
      //         slideRef.current?.slideTo(s.id - 1.2)
      //     } else {

      //         if(s.id === getStageIndex(prospstg, StagesArr)+1) {
      //             s.completed = false
      //             s.active = false
      //             s.milestoneMsg = "Next upcoming milestone"
      //         } else if (s.id > getStageIndex(prospstg, StagesArr)+1) {
      //             s.completed = false
      //             s.active = false
      //             s.milestoneMsg = "Future milestone"
      //         } else {
      //             s.completed = true
      //             s.active = false
      //             s.milestoneMsg = "Milestone achieved"
      //         }
      //     }
      // }))

      setStagesArr(
        retStagesArr(JourneyStagesHub).map((s) => {
          if (s.milestoneTitle === getLabelVal("label", prospstg)) {
            setactSlide(s.id - 2 + 0.1);
            // setactSlideID(s.id - 1.5)
            slideRef.current?.slideTo(s.id - 1.2);
            return {
              ...s,
              completed: false,
              active: true,
              milestoneMsg: "Current milestone",
            };
          } else {
            if (
              s.id ===
              getStageIndex(
                getLabelVal("label", prospstg),
                retStagesArr(JourneyStagesHub)
              ) +
                1
            ) {
              return {
                ...s,
                completed: false,
                active: false,
                milestoneMsg: "Next upcoming milestone",
              };
            } else if (
              s.id >
              getStageIndex(
                getLabelVal("label", prospstg),
                retStagesArr(JourneyStagesHub)
              ) +
                1
            ) {
              return {
                ...s,
                completed: false,
                active: false,
                milestoneMsg: "Future milestone",
              };
            } else {
              return {
                ...s,
                completed: true,
                active: false,
                milestoneMsg: "Milestone achieved",
              };
            }
          }
        })
      );

      // setStagesArr(tempArr)
    }
    // else {
    //     setStagesArr([])
    // }
    setshowSlides(true);
  }, [JourneyStagesHub, contact]);

  useEffect(() => {
    const getLabelVal = (output, str) => {
      let retVal = "";
      if (output === "value") {
        for (const stage of JourneyStagesHub) {
          if (str === stage.label || str === stage.val) {
            retVal = stage.val;
            break;
          }
        }
      } else if (output === "label") {
        for (const stage of JourneyStagesHub) {
          if (str === stage.label || str === stage.val) {
            retVal = stage.label;
            break;
          }
        }
      }
      return retVal;
    };

    if (contact && contact.properties && StagesArr) {
      // const prospstg = contact.properties.lifecycle_stage_status;
      const prospstg = contact.properties.lead_stage;
      
      nextStageFunc(getLabelVal("label", prospstg));
      const nextIndex = StagesArr.findIndex(indexcheck);
      nextStageNameFunc(
        StagesArr[nextIndex + 1] ? StagesArr[nextIndex + 1].milestoneTitle : ""
      );
      prevStageFunc(
        StagesArr[nextIndex - 1] ? StagesArr[nextIndex - 1].milestoneTitle : ""
      );
      // if(!(prospstg === "" || prospstg === null)) {

      //     StagesArr.forEach(s => {
      //         if(s.milestoneTitle === prospstg) {
      //             s.completed = false
      //             s.active = true
      //             s.milestoneMsg = "Current milestone"
      //             setactSlide(s.id - 2 + 0.1)
      //             // setactSlideID(s.id - 1.5)
      //             slideRef.current?.slideTo(s.id - 1.2)
      //         } else {

      //             if(s.id === getStageIndex(prospstg, StagesArr)+1) {
      //                 s.completed = false
      //                 s.active = false
      //                 s.milestoneMsg = "Next upcoming milestone"
      //             } else if (s.id > getStageIndex(prospstg, StagesArr)+1) {
      //                 s.completed = false
      //                 s.active = false
      //                 s.milestoneMsg = "Future milestone"
      //             } else {
      //                 s.completed = true
      //                 s.active = false
      //                 s.milestoneMsg = "Milestone achieved"
      //             }
      //         }
      //     })
      // }
    }
  }, [contact, StagesArr, indexcheck]);

  const [showPopover, setShowPopover] = useState(false);
  const [showPrevPopover, setPrevShowPopover] = useState(false);
  function updateStage(stageToUpd) {
    const getLabelVal = (output, str) => {
      let retVal = "";
      if (output === "value") {
        for (const stage of JourneyStagesHub) {
          if (str === stage.label || str === stage.val) {
            retVal = stage.val;
            break;
          }
        }
      } else if (output === "label") {
        for (const stage of JourneyStagesHub) {
          if (str === stage.label || str === stage.val) {
            retVal = stage.label;
            break;
          }
        }
      }
      return retVal;
      
    };
    
    setPrevShowPopover(false);
    setShowPopover(false);
    // let payload: any = {
    //   properties: {
    //     // "prospect_stages":stageToUpd
    //     // lifecycle_stage_status: getLabelVal("value", stageToUpd),
    //     lead_stage: getLabelVal("value", stageToUpd),
    //   },
    // };
    let payload: any 
    // if (stageToUpd === "COMMITTED CAPITAL")
    if (stageToUpd.toLowerCase() === "committed capital")
    {
      
       payload = { 
      properties: {
        lead_stage: getLabelVal("value", stageToUpd),
        lifecyclestage : "customer"
      }, 
    };
    }else{
      
     payload = {
    properties: {
      lead_stage: getLabelVal("value", stageToUpd),
    },
  };
}
  

    api.updateProspect(contact.id, payload).then((resp) => {
      //setengData(resp)
      journeystage();
    })
    .catch((e) => {
      console.log(JSON.stringify(e))
    })
  }
  return (
    // <IonGrid style={styles.root}>
    //   <IonLabel style={styles.label}>User Journey Stages</IonLabel>
      <IonGrid  class="ml-13 mr-13" style={styles.root} >
            <IonLabel class="fs-18" style={styles.label} >User Journey Stages</IonLabel>
      {
        <IonPopover
          isOpen={showPopover}
          cssClass="my-custom-class"
          onDidDismiss={(e) => setShowPopover(false)}
          mode="md"
        >
          <IonCard>
            {
              <div>
                <IonGrid class="popoverGridClass">
                  <IonRow
                    style={{
                      color: "#828282",
                      justifyContent: "center",
                      fontSize: 28,
                    }}
                    class="ion-text-center ion-margin-top ion-margin-bottom"
                  >
                    Great Work!
                  </IonRow>
                  <IonRow
                    style={{
                      color: "#828282",
                      justifyContent: "center",
                      fontSize: 14,
                    }}
                    class="ion-text-center ion-margin-bottom"
                  >
                    Your prospect has been moved to the next stage:
                  </IonRow>
                  <IonRow
                    style={{
                      color: "#FFFFFF",
                      justifyContent: "center",
                      fontSize: 18,
                    }}
                    class="ion-text-center ion-margin-bottom"
                  >
                    {nextStageName}
                  </IonRow>
                  <IonRow
                    class="ion-text-center ion-margin-bottom"
                    style={{
                      color: "#B99855",
                      cursor: "pointer",
                      justifyContent: "center",
                    }}
                    onClick={() => updateStage(nextStageName)}
                  >
                    OK
                  </IonRow>
                </IonGrid>
              </div>
            }
          </IonCard>
        </IonPopover>
      }
      {
        <IonPopover
          isOpen={showPrevPopover}
          cssClass="my-custom-class"
          onDidDismiss={(e) => setPrevShowPopover(false)}
          mode="md"
        >
          <IonCard style={{ boxShadow: "none" }}>
            {
              <div>
                <IonGrid class="popoverGridClass">
                  <IonRow
                    style={{
                      color: "#828282",
                      justifyContent: "center",
                      fontSize: 28,
                    }}
                    class="ion-text-center ion-margin-top ion-margin-bottom"
                  >
                    Are you sure?
                  </IonRow>
                  <IonRow
                    style={{
                      color: "#828282",
                      justifyContent: "center",
                      fontSize: 14,
                    }}
                    class="ion-text-center ion-margin-bottom"
                  >
                    Do you wish to move your prospect back to stage:
                  </IonRow>
                  <IonRow
                    style={{
                      color: "#FFFFFF",
                      justifyContent: "center",
                      fontSize: 18,
                    }}
                    class="ion-text-center ion-margin-bottom"
                  >
                    {prevStage}
                  </IonRow>
                  <IonRow
                    class="ion-text-center ion-margin-bottom"
                    style={{ color: "#B99855" }}
                  >
                    <IonCol
                      style={{ cursor: "pointer" }}
                      onClick={() => setPrevShowPopover(false)}
                    >
                      No
                    </IonCol>
                    <IonCol
                      style={{ cursor: "pointer" }}
                      onClick={() => updateStage(prevStage)}
                    >
                      Yes
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            }
          </IonCard>
        </IonPopover>
      }
      {showSlides ? (
        <IonSlides
          options={slideOpts}
          style={styles.slide}
          className="js-slides"
          ref={slideRef}
          // onIonSlideDidChange={(e: any) => {onSlideChange(e)}}
        >
          {StagesArr && StagesArr.length > 0
            ? StagesArr.map((stage) => (
                <IonSlide key={stage.id} className="vert-slide-margins">
                  <IonCard
                    onClick={() => {
                      stage.active
                        ? setShowPopover(true)
                        : actSlide + 1.9 - stage.id === 1
                        ? setPrevShowPopover(true)
                        : setPrevShowPopover(false);
                    }}
                    style={styles.card}
                    className={retActClass(stage)}
                  >
                    <IonCardHeader>
                      <IonCardTitle style={styles.cardTitle}>
                        <IonRow>
                          <IonCheckbox
                            disabled
                            style={styles.check}
                            color="gold"
                            // class="ion-color-gold-v1"
                            mode="md"
                            checked={
                              stage.milestoneMsg === "Milestone achieved"
                            }
                            // onIonChange={e => console.log(e.detail.checked)}
                          />
                          <IonText
                            style={{
                              marginLeft: 8,
                              marginRight: 8,
                              fontsize: 14,
                              fontweight: 500,
                            }}
                            className={retActClassTitle(stage)}
                          >
                            {stage.milestoneTitle}
                          </IonText>
                        </IonRow>
                      </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent style={styles.cardcontent}>
                      <IonText color="white" style={styles.cardSubtitle}>
                        {stage.milestoneMsg}
                      </IonText>
                      <br />
                      <IonText style={styles.cardMilestone}>
                        {/* {stage.milestoneDetail} */}
                      </IonText>
                      <IonGrid
                        style={{ marginTop: 82 }}
                        className={retActClassLine(stage)}
                      >
                        <IonRow className="for-num-line-row">
                          <IonBadge
                            style={{
                              "--background": "var(--ion-color-step-250)",
                            }}
                          >
                            {stage.id}
                          </IonBadge>
                        </IonRow>
                      </IonGrid>
                    </IonCardContent>
                  </IonCard>
                </IonSlide>
              ))
            : null}
        </IonSlides>
      ) : (
        <div style={styles.slideBackdrop} className="js-slides-back">
          {/* <IonLoading
                        isOpen={!showSlides}
                        // onDidDismiss={() => setLoading(false)}
                        message={'Please wait...'}
                        duration={5000}
                    /> */}
        </div>
      )}
      <div className="slideShadow"></div>
      {/* {contact.properties.lifecycle_stage_status === "Client 2" && ( */}
         {/* {contact.properties.lead_stage === "Client 2" && ( */}
          {contact.properties.lead_stage === "COMMITTED CAPITAL" && (          
        <div className="blur-j-stage">
          <IonRow style={{ fontSize: 28 }}>
            <IonText
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: 100,
              }}
            >
              All milestones achieved!
            </IonText>
          </IonRow>
          <IonRow style={{ fontSize: 14 }}>
            <IonText
              style={{ marginLeft: "auto", marginRight: "auto", marginTop: 8 }}
            >
              Your prospect is ready to become a client.
            </IonText>
          </IonRow>
        </div>
      )}
    </IonGrid>
  );
};

export default JourneyStages;
