import React, { useEffect, useState } from "react";
import {
  IonMenu,
  IonHeader,
  IonToolbar,
  IonContent,
  IonButton,
  IonIcon,
  IonTitle,
  IonButtons,
  IonLabel,
  IonSelect,
  IonCheckbox,
  IonRow,
  IonText,
  IonFooter,
  IonMenuButton,
  IonSelectOption,
  IonItem,
  IonAlert,
} from "@ionic/react";
import { close, chevronDown } from "ionicons/icons";
import "./FilterMenu.css";
import CryptoJS from 'crypto-js';
import useWindowDimensions from "src/components/useWindowDimensions";
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics';
import { Plugins  } from "@capacitor/core";
const { Storage, Device } = Plugins
const styles = {
  sortby: {
    marginTop: 6,
    backgroundColor: "#222222",
    paddingLeft: 8,
    paddingRight: 16,
  },
  applybtn: {
    borderRadius: 0,
    textTransform: "none",
    marginRight: "2",
  },
  footer: {
    backgroundColor: "#1a1a1a",
    padding: 24,
  },
  selectItem: {
    "--padding-start": "0px",
    backgroundColor: "#222222",
    marginTop: 8,
  },
  root: {
    padding: 27,
  },
  select: {
    "--padding-start": "8px",
  },
  label: {
    // marginBottom: 8
  },
  radiolist: {
    "--padding-start": "0px",
  },
  listHeader: {
    fontSize: 14,
    paddingInlineStart: 0,
    marginBottom: 8,
    maxHeight: 0,
    fontWeight: "normal",
  },
};

const SortArrProsp: any = [
  {
    val: "Interaction Date (Newest to Oldest)",
  },
  {
    val: "Closing Date (Soonest to Furthest)",
  },
  {
    val: "Probability of Closing",
  },
  {
    val: "Commitment forcast (high to low)",
  },
  {
    val: "A to Z",
  },
  {
    val: "Z to A",
  },
];

const SortArrClient: any = [
  {
    val: "Interaction Date (Newest to Oldest)",
  },
  {
    val: "Closing Date (Soonest to Furthest)",
  },
  {
    val: "Probability of Closing",
  },
  {
    val: "Commitment forcast (high to low)",
  },
  {
    val: "A to Z",
  },
  {
    val: "Z to A",
  },
  {
    val: "Client Id (Ascending)",
  },
  {
    val: "Client Id (Descending)",
  },
];

const optionsA = {
  cssClass: "my-custom-interface",
};

interface FilProps {
  activeFilters: Array<string>;
  setActiveArrayFunc: Function;
  clrfil: boolean;
  setFilterFields: Function;
  trigClr: Function;
  sfilterCheckboxes: any;
  selSeg: string;
}

const FilterMenu: React.FC<FilProps> = ({
  activeFilters,
  setActiveArrayFunc,
  clrfil,
  setFilterFields,
  trigClr,
  sfilterCheckboxes,
  selSeg
}) => {
  const [actArr, setactArr] = useState<Array<string>>([]);
  const [isMenuOpen, setisMenuOpen] = useState<boolean>(false);
  const [selSort, setselSort] = useState<string>(
    "Interaction Date (Newest to Oldest)"
  );
  // const [sfilterCheckboxes, setsfilterCheckboxes] = useState<any>(filterCheckboxes)
  const { height, width } = useWindowDimensions();

  const [filterAlert, setfilterAlert] = useState<boolean>(false);

  useEffect(() => {
    if (!isMenuOpen && clrfil) {
      setactArr([]);
      setselSort("Interaction Date (Newest to Oldest)");
    }
  }, [clrfil, isMenuOpen]);

  // useEffect(() => {
  //     let prom: any = []
  //     filterCheckboxes.forEach(fil => {
  //         if(fil.propName) {
  //             prom.push(new Promise((res) => {
  //                 api.getContactPropertiesByProperty(fil.propName).then(resp => {
  //                     if(resp.type === "enumeration") {
  //                         fil.options = resp.options.map(o => o.value)
  //                         res()
  //                     }
  //                 })
  //             }))
  //         }
  //     })
  //     Promise.all(prom).then(() => {
  //         setsfilterCheckboxes(filterCheckboxes)
  //     })
  // },[])
  const decrypt = (decryptVlaue) => {
    let passphrase = "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9"
    const bytes = CryptoJS.AES.decrypt(decryptVlaue, passphrase);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  };
  
  const addToActArray = (str: string) => {
    if (isMenuOpen) {
      setactArr([...actArr, str]);
    }
  };
  const remFromActArray = (str: string) => {
    if (isMenuOpen) {
      setactArr(actArr.filter((a) => a !== str));
    }
  };
   const getUserEmail = async () => {
    const ret: any = await Storage.get({ key: "userId" })
    const temp = JSON.parse(decrypt(ret.value))
    return temp && temp.email ? temp.email : null
}
  const getUserId = async () => {
    const ret: any = await Storage.get({ key: "userId" });
    const temp = JSON.parse(decrypt(ret.value));
    return temp && temp.id ? temp.id : null;
  };

  const ApplyChanges = () => {
    getUserId().then((userId) => {
 Device.getInfo().then(deviceInfo => {
    getUserEmail().then(useremail => {
      if(deviceInfo.platform == "web")
      {
        //useremail
        window.globalThis.firebase.analytics().setUserId(useremail);
        window.globalThis.firebase.analytics().logEvent('select_content', {
        user_name: useremail,
        content_type: 'ApplyFilter',
        item_id: actArr.toString(),
        device_id:deviceInfo?deviceInfo.uuid:"",
        platform:deviceInfo?deviceInfo.platform:"",
        device_model:deviceInfo?deviceInfo.model:"",
        device_type:deviceInfo?deviceInfo.osVersion:"",
        device_os:deviceInfo?deviceInfo.operatingSystem:"",
          });
      }
      else
      {
        //useremail
            FirebaseAnalytics.setUserId(useremail);
            FirebaseAnalytics.logEvent("select_content", {user_name: useremail});
            FirebaseAnalytics.logEvent("select_content", {content_type: 'ApplyFilter'});
            FirebaseAnalytics.logEvent("select_content", {item_id: actArr.toString()});
            FirebaseAnalytics.logEvent("select_content", {device_id: deviceInfo?deviceInfo.uuid:""});
            FirebaseAnalytics.logEvent("select_content", {platform: deviceInfo?deviceInfo.platform:""});
            FirebaseAnalytics.logEvent("select_content", {device_model: deviceInfo?deviceInfo.model:""});
            FirebaseAnalytics.logEvent("select_content", {device_type:deviceInfo?deviceInfo.osVersion:""});
            FirebaseAnalytics.logEvent("select_content", {device_os:deviceInfo?deviceInfo.operatingSystem:""});

      }
    
    })
 })

    if (actArr.length > 1) {
      setfilterAlert(true);
    } else {
      let tempObj = {
        "Wealth Range": {
          field: "expected_wealth_range",
          vals: [],
        },
        Persona: {
          field: "segment",
          vals: [],
        },
        Region: {
          field: "country",
          vals: [],
        },
        Owner: {
          field: "hubspot_owner_id",
          vals: [],
        },
        Source: {
            field: "contact_type",
            vals: []
        },
        Status: {
          field: "hs_lead_status",
          vals: []
      },
      };
      actArr.forEach((sel) => {
        let currVal = "";
        let currKey = "";
        sfilterCheckboxes.forEach((par) => {
          par.options.forEach((o) => {
            if (o === sel) {
             
              if(par.label === "Owner")
              {
                let abc = par.ownerids.filter(a=>{
                  return sel == a.firstName;
                });                
                sel = abc[0].id;
              }
              currVal = sel;
              currKey = par.label;
              tempObj[currKey].vals.push(currVal);
            }
          });
        });
      });
      
      setFilterFields(tempObj, selSort);
      setActiveArrayFunc(actArr);
    }
  })
  };

  return (
    <React.Fragment>
      <IonAlert
        isOpen={filterAlert}
        onDidDismiss={() => setfilterAlert(false)}
        cssClass="my-custom-filter-class"
        subHeader={"Please select a maximum of 1 filter."}
        // subHeader={'Subtitle'}
        // message={'Please select a maximum of 3 filters.'}
        buttons={[
          {
            text: "OK",
            role: "cancel",
            cssClass: "filter-alert-ok",
          },
        ]}
      />
      <IonMenu
        side="end"
        menuId="first"
        contentId="menu-id"
        style={{ "--width": "327px" }}
        onIonDidOpen={() => {
          setisMenuOpen(true);
        }}
        onIonDidClose={() => {
          setisMenuOpen(false);
        }}
      >
        <IonHeader>
          <IonToolbar className="filtermenu-toolbar">
            <IonButtons slot="start">
              <IonMenuButton>
                <IonButton fill="clear">
                  <IonIcon icon={close} style={{ color: "#fff" }}></IonIcon>
                </IonButton>
              </IonMenuButton>
            </IonButtons>
            <IonTitle>Filters</IonTitle>
            <IonButtons slot="end">
              <IonMenuButton>
                <IonButton
                  fill="clear"
                  mode="ios"
                  size="small"
                  color="gold"
                  onClick={() => {
                    trigClr();
                    setselSort("Interaction Date (Newest to Oldest)");
                  }}
                >
                  Clear
                </IonButton>
              </IonMenuButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        
        <IonContent id="filter-menu">
          {/* <IonLabel>
                        Sort By
                    </IonLabel>
                    <IonItem lines="none" style={styles.selectItem}>
                        <IonSelect
                            placeholder="Interaction Date (Newest to Oldest)"
                            style={styles.sortby}
                            interfaceOptions={optionsA}
                            value={selSort}
                            onIonChange={(e) => {
                                setselSort(e.detail.value)
                            }}
                            mode="ios"
                            class="customIonSelect"
                        >
                            {
                                SortArr
                                .map(({ val }, i) =>
                                    <IonSelectOption key={i} value={val}>{val}</IonSelectOption>
                                )
                            }
                            <IonIcon slot="end" icon={chevronDown} color="gold" />
                        </IonSelect>
                    </IonItem> */}

          <IonLabel class="font14" style={styles.label}>
            Sort By
          </IonLabel>
          <IonItem lines="none" style={styles.selectItem}>
            <IonSelect
              interfaceOptions={optionsA}
              style={styles.select}
              value={selSort}
              onIonChange={(e) => {
                setselSort(e.detail.value);
              }}
              name="salutation"
              mode="ios"
              class="customIonSelect"
              placeholder="Interaction Date (Newest to Oldest)"
              interface={width < 576 ? "action-sheet" : "popover"}
            >
              {
              selSeg=="allclients"?SortArrClient.map(({ val }, i) => (
                <IonSelectOption key={i} value={val}>
                  {val}
                </IonSelectOption>
              )):SortArrProsp.map(({ val }, i) => (
                <IonSelectOption key={i} value={val}>
                  {val}
                </IonSelectOption>
              ))
              }
            </IonSelect>
            <IonIcon slot="end" icon={chevronDown} color="gold" />
          </IonItem>
          <IonItem lines="none" className="filter-title-msg">
            <IonText  >Choose only one Filter</IonText>
          </IonItem>
          {sfilterCheckboxes && sfilterCheckboxes.map((fil) => (
            <CheckBoxOptions
              key={fil.id}
              label={fil.label}
              optionsArr={fil.options}
              actArr={actArr}
              addToActArr={(str) => {
                addToActArray(str);
              }}
              remFromActArr={(str) => {
                remFromActArray(str);
              }}
            />
          ))}
        </IonContent>
        <IonFooter className="ion-no-borders" style={styles.footer}>
          <IonMenuButton>
            <IonButton
              fill="outline"
              mode="md"
              color="gold"
              style={styles.applybtn}
              onClick={() => {
                ApplyChanges();
              }}
            >
              Apply Changes
            </IonButton>
          </IonMenuButton>
        </IonFooter>
      </IonMenu>
    </React.Fragment>
  );
};

const CheckBoxOptions = ({
  label,
  optionsArr,
  actArr,
  addToActArr,
  remFromActArr,
}) => {
  return (
    <div style={{ marginTop: 32 }}>
      <IonLabel style={{ fontWeight: 400, marginBottom: 2 }}>{label}</IonLabel>
      {optionsArr.map((val, i) => (
        <IonRow key={i} style={{ marginTop: 8 }}>
          <IonCheckbox
            color="actblue"
            mode="md"
            checked={actArr.includes(val)}
            class="ion-color-actblue-v1"
            value={val}
            style={{ width: 20, height: 20, "--background": "#1A1A1A" }}
            onIonChange={(e) => {
              const detChecked = e.detail.checked;
              if (detChecked) {
                addToActArr(val);
              } else {
                remFromActArr(val);
              }
            }}
          />
          <IonText
            style={{
              marginLeft: 8,
              marginRight: 8,
              fontsize: 14,
              fontweight: 500,
              color: "#c7c7c7",
            }}
          >
            {label === "Wealth Range" ? `$${val}` : val}
          </IonText>
        </IonRow>
      ))}
    </div>
  );
};

export default FilterMenu;
