import React from "react";
import { useHistory } from "react-router-dom";
import {
    IonHeader,
    IonPage,
    IonToolbar,
    IonImg,
    IonRow,
    IonCol,
    IonAvatar,
    IonIcon,
    IonText,
    IonGrid } from "@ionic/react";
import { search } from "ionicons/icons";
import './MainLayout.css'
const MainLayout: React.FC = ({
    children
}) => {

    const history = useHistory()

    const triggerSearch = () => {
        // console.log("Search Triggered")
        history.push('/prospects/search')
    }

    // const [searchText, setSearchText] = useState<string>("")

    
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonGrid className="rootgrid">
                        <IonRow>
                            <IonCol>
                                <IonImg
                                    className="ion-text-start"
                                    src='/assets/brand_logo.svg'
                                    style={{ width: 75 }}
                                />
                            </IonCol>
                            <IonCol>
                                <IonGrid className="ion-float-right">
                                    <IonRow>
                                        <IonIcon color="white" icon={search} size="small" onClick={triggerSearch} className="trig-srch" ></IonIcon>
                                        <IonText className="srchplaceholder ion-margin-horizontal trig-srch" onClick={triggerSearch} >
                                                Search for any prospect or client
                                        </IonText>
                                        <IonAvatar style={{ width: 24, height: 24 }}>
                                            <img src="https://randomuser.me/api/portraits/men/49.jpg" alt="" />
                                        </IonAvatar>
                                    </IonRow>
                                </IonGrid>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonToolbar>
            </IonHeader>
            {children}
        </IonPage>
    )
}

export default MainLayout