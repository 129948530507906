import React, { useCallback, useEffect, useRef, useState } from "react";
import { Field, useFormik } from "formik";
import { useHistory } from "react-router-dom";
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonBackButton,
  IonButtons,
  IonPage,
  IonText,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonIcon,
  IonCheckbox,
  IonInput,
  IonRadioGroup,
  IonRadio,
  IonTextarea,
  IonButton,
  IonSelect,
  IonSelectOption,
  IonLoading,
  IonChip,
  IonDatetime,
} from "@ionic/react";
import "./NewNote.css";
import CryptoJS from 'crypto-js';
import { chevronBack, chevronDown, add, close } from "ionicons/icons";
import api from "src/api";
import { RouteComponentProps } from "react-router";
import moment from "moment";
import calendargold from "src/icons/calendargold.svg";
import CustDatePicker from "src/components/CustDatePicker/CustDatePicker";
import AttendeeSearch from "src/pages/Calendar/NewEvent/AttendeeSearch";
import IMask from "imask";
import dollar from "src/icons/dollar.svg";
import { Plugins, Network } from "@capacitor/core";
import useWindowDimensions from "src/components/useWindowDimensions";
import { meetingNotePayload } from "src/types";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";
const { Storage, Device } = Plugins;

// import * as Yup from "yup";


const styles = {
  toolbar: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
  },
  label:{},  
  selectItem: {
    "--padding-start": "0px",
    backgroundColor: "#222222",
    marginTop: 8,
  },
  select: {
    "--padding-start": "8px",
  },
  headerRow: {
    marginTop: 24,
    marginLeft: 32,
  },
  arbHeaderRow: {
    marginTop: 8,
    marginLeft: 32,
  },
  engName: {
    fontWeight: 300,
    fontSize: 28,
    lineHeight: "120%",
    color: "#828282",
  },
  arbName: {
    fontWeight: 400,
    fontSize: 18,
    lineHeight: "120%",
    color: "#828282",
    fontFamily: "Almarai",
  },
  reqText: {
    fontWeight: 700,
    fontSize: 14,
    color: "#FFFFFF",
  },
  formHeader: {
    fontWeight: 400,
    fontSize: 22,
    lineHeight: "120%",
    color: "#AAAAAA",
  },
  formHeaderSmall: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "120%",
    color: "#AAAAAA",
    marginTop: 10,
    marginLeft: 5,
  },
  fieldLabel: {
    fontWeight: 400,
    fontSize: 17,
    color: "#FFFFFF",
  },
  optionLabel: {
    fontWeight: 400,
    fontSize: 14,
    color: "#C7C7C7",
  },
  optionItem: {
    marginLeft: -16,
    marginBottom: -12,

  },
  optionItemI1: {
    marginLeft: -16,
    height: 40,
    // marginBottom: 26,
    // marginTop:5,
  },
  optionItemIncreased: {
    marginLeft: -16,
    height:"3%"

  },
  checkIcon: {
    "--background-checked": "#A5D2DC",
    "--checkmark-color": "#222222",
    "--checkmark-width": 3,
    "--border-color-checked": "#A5D2DC",
    height: 20,
    width: 20,
    marginInlineEnd: 8,
  },
  radioIcon: {
    "--color-checked": "#A5D2DC",
    marginInlineEnd: 8,
  },
  dateItem: {
    "--background": "#222222",
    marginBottom: 2,
    borderRadius: 0,
    overflow: "visible",
    "--padding-start": "8px",
  },
  inputItem: {
    "--background": "#222222",
    marginBottom: 2,
    // width: "100%",
    marginTop: 8,
    // marginLeft: 35,
  },
  inputItemI1: {
    "--background": "#222222",
    marginBottom: 2,
    // width: "100%",
    marginTop: "-1px!important",
    // marginLeft: 35,
  },
  textAreaRow: {
    marginTop: 8,
    marginLeft: 32,
    marginRight: 32,
  },
  textArea: {
    "--background": "#222222",
    // height: 102,
    // "--padding-start": "10px",
    padding: 10,
    // color: "#828282",
    fontWeight: 400,
    fontSize: 14,
  },
  acordHeader: {
    color: "#B99855",
    fontWeight: 400,
    fontSize: 17,
    lineHeight: "120%",
  },
  addIcon: {
    color: "#B99855",
    size: 16,
    marginRight: 5,
  },
  dropdown: {
    marginTop: 5,
    width: "90%",
    "--background": "#222222",
    marginBottom: 2,
    borderRadius: 8,
  },
  textbox: {
    marginTop: 5,
    width: "90%",
    "--background": "#222222",
    marginBottom: 24,
    borderRadius: 0,
    "--padding-start": "15px",
  },
};

interface NewMeetingPageProps
  extends RouteComponentProps<{
    contactId: string;
  }> {}

const optionsA = {
  cssClass: "my-custom-interface",
};

const optionsAttendee = {
  cssClass: "my-custom-alert"
}

const NewNote: React.FC<NewMeetingPageProps> = ({ match, history }) => {
  const [contact, setContact] = React.useState<any>();
  const currDate: any = new Date();
  const [openSentiment, setOpenSentiment] = React.useState(true);
  const [openFinancial, setOpenFinancial] = React.useState(false);
  const [openLiquidity, setOpenLiquidity] = React.useState(false);
  const [openNextStep, setOpenNextStep] = React.useState(false);
  const [lstmeetdate, setlstmeetdate] = React.useState(moment(new Date()));
  const [ro, setRO] = React.useState(true);
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const toggleSentiment = () => {
    setOpenFinancial(false);
    setOpenLiquidity(false);
    setOpenNextStep(false);
    setOpenSentiment(!openSentiment);
  };
  const toggleFinancial = () => {
    setOpenSentiment(false);
    setOpenLiquidity(false);
    setOpenNextStep(false);
    setOpenFinancial(!openFinancial);
  };
  const toggleLiquidity = () => {
    setOpenSentiment(false);
    setOpenFinancial(false);
    setOpenNextStep(false);
    setOpenLiquidity(!openLiquidity);
  };
  const toggleNextStep = () => {
    setOpenSentiment(false);
    setOpenLiquidity(false);
    setOpenFinancial(false);
    setOpenNextStep(!openNextStep);
  };

  const timePickerOptions: any = [
    {val: "0:00 AM",fVal:"00:00:00"},
    {val: "0:30 AM",fVal:"00:30:00"},
    {val: "1:00 AM",fVal:"01:00:00"},
    {val: "1:30 AM",fVal:"01:30:00"},
    {val: "2:00 AM",fVal:"02:00:00"},
    {val: "2:30 AM",fVal:"02:30:00"},
    {val: "3:00 AM",fVal:"03:00:00"},
    {val: "3:30 AM",fVal:"03:30:00"},
    {val: "4:00 AM",fVal:"04:00:00"},
    {val: "4:30 AM",fVal:"04:30:00"},
    {val: "5:00 AM",fVal:"05:00:00"},
    {val: "5:30 AM",fVal:"05:30:00"},
    {val: "6:00 AM",fVal:"06:00:00"},
    {val: "6:30 AM",fVal:"06:30:00"},
    { val: "7:00 AM",fVal:"07:00:00"},
    { val: "7:30 AM",fVal:"07:30:00"},
    { val: "8:00 AM",fVal:"08:00:00"},
    { val: "8:30 AM",fVal:"08:30:00" },
    { val: "9:00 AM",fVal:"09:00:00" },
    { val: "9:30 AM",fVal:"09:30:00" },
    { val: "10:00 AM",fVal:"10:00:00"},
    { val: "10:30 AM",fVal:"10:30:00"},
    { val: "11:00 AM",fVal:"11:00:00"},
    { val: "11:30 AM" ,fVal:"11:30:00" },
    { val: "12:00 PM",fVal:"12:00:00" },
    { val: "12:30 PM",fVal:"12:30:00" },
    { val: "1:00 PM",fVal:"13:00:00" },
    { val: "1:30 PM",fVal:"13:30:00" },
    { val: "2:00 PM",fVal:"14:00:00" },
    { val: "2:30 PM",fVal:"14:30:00" },
    { val: "3:00 PM",fVal:"15:00:00" },
    { val: "3:30 PM",fVal:"15:30:00" },
    { val: "4:00 PM",fVal:"16:00:00" },
    { val: "4:30 PM",fVal:"16:30:00" },
    { val: "5:00 PM",fVal:"17:00:00" },
    { val: "5:30 PM",fVal:"17:30:00" },
    { val: "6:00 PM",fVal:"18:00:00" },
    { val: "6:30 PM",fVal:"18:30:00" },
    { val: "7:00 PM",fVal:"19:00:00" },
    { val: "7:30 PM",fVal:"19:30:00" },
    { val: "8:00 PM",fVal:"20:00:00" },
    { val: "8:30 PM",fVal:"20:30:00" },
    { val: "9:00 PM",fVal:"21:00:00" },
    { val: "9:30 PM",fVal:"21:30:00" },
    { val: "10:00 PM",fVal:"22:00:00" },
    { val: "10:30 PM",fVal:"22:30:00" },
    { val: "11:00 PM",fVal:"23:00:00" },
    { val: "11:30 PM",fVal:"23:30:00" },
    { val: "12:00 PM",fVal:"24:00:00" },
    ];
  const [closingProbabilityOptions, setclsProb] = useState<any>([]);
  const closingMonthOptions = () => {
    let retObj: any = [];
    for (var i = 0; i < 12; i++) {
      retObj.push({
        val: `${moment(new Date())
          .add(i, "M")
          .format("MMM")} ${moment(new Date())
          .add(i, "M")
          .format("YYYY")}`,
        mom: moment(new Date())
          .add(i, "M")
          .startOf("month")
          .format("YYYY-MM-DD"),
      });
    }
    return retObj;
  };
  const checkboxList = [
    { val: "Principle", name: "principle" },
    { val: "Advisor", name: "advisor" },
    { val: "Family member", name: "familymember" },
    { val: "Other", name: "other" },
  ];
  const meetingTopicList = [
    { val: "1st Meeting" },
    { val: "2nd Meeting" },
    { val: "3rd Meeting" },
    { val: "Meeting with CEO" },
    { val: "Meeting with Portfolio Manager" },
    { val: "Meeting with External Consultant"},
    { val: "Proposal Review" },
    { val: "Onboarding Portfolio Review" },
    { val: "Deal/Program Discussion" },
    { val: "Quarterly Report Review" },
    { val: "Other" },
  ];
  const meetingTypeList = [
    { val: "Pre-qualification" },
    { val: "First outreach" },
    { val: "Demo" },
    { val: "Physical meeting" },
    { val: "Video Call Meeting" },
    { val: "Phone Call Meeting" },
  
  ];
  const VOC = [{ val: "Low" }, { val: "Medium" }, { val: "High" }];
  const IO = [
    { val: "Growth" },
    { val: "Yielding" },
    { val: "Both Growth and Yielding" },
    { val: "Opportunistic" },
  ];
  const IL = [{ val: "Low" }, { val: "Medium" }, { val: "High" }];
  const OLE = [{ val: "Low" }, { val: "Medium" }, { val: "High" }];
  const OIM = [{ val: "Low" }, { val: "Medium" }, { val: "High" }];
  const OCB = [{ val: "Low" }, { val: "Medium" }, { val: "High" }];
  const AIE = [{ val: "Low" }, { val: "Medium" }, { val: "High" }];
  const type = [
    { name: "Call", val: "CALL" },
    { name: "Email", val: "EMAIL" },
    { name: "To-do", val: "TODO" },
  ];
  const priority = [
    { name: "None", val: "NONE" },
    // { name: "Low", val: "NONE" },
    // { name: "Medium", val: "NONE" },
    { name: "High", val: "HIGH" },
  ];
  const [dueDateVal, setDueDateVal] = useState<any>(currDate);
  // const emailReminderOption = () => {
  //   let remOpt: any = [];
  //   for (var i = 1; i < 5; i++) {
  //     remOpt.push({
  //       val:
  //         i === 1
  //           ? `The day of (${moment(dueDateVal).format("dddd")})`
  //           : i === 2
  //           ? `1 day before (${moment(dueDateVal)
  //               .subtract(1, "d")
  //               .format("dddd")})`
  //           : i === 3
  //           ? `1 week before (${moment(dueDateVal)
  //               .subtract(1, "w")
  //               .format("dddd")})`
  //           : `No reminder`,

  //       dt:
  //         i === 1
  //           ? `${moment(dueDateVal).format("YYYY-MM-DD")}`
  //           : i === 2
  //           ? `${moment(dueDateVal)
  //               .subtract(1, "d")
  //               .format("YYYY-MM-DD")}`
  //           : i === 3
  //           ? `${moment(dueDateVal)
  //               .subtract(1, "w")
  //               .format("YYYY-MM-DD")}`
  //           : `No reminder`,
  //     });
  //   }
  //   return remOpt;
  // };
  const hist= useHistory();

  const newNoteParam = hist.location;
  let newNoteData: any = newNoteParam.state;
  //const locInfo:any = history.location;

  let contId = newNoteData.contactId;
  
 // const contId = match.params.contactId;
 const decrypt = (decryptVlaue) => {
  let passphrase = "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9"
  const bytes = CryptoJS.AES.decrypt(decryptVlaue, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};
  const getUserId = async () => {
    const ret: any = await Storage.get({ key: "userId" });
    const temp = JSON.parse(decrypt(ret?.value));
    return temp?.id;
  };
  const getUserEmail = async () => {
    const ret: any = await Storage.get({ key: "userId" })
    const temp = JSON.parse(decrypt(ret?.value))
    return temp && temp?.email ? temp.email : null
}

  // const getUser = async () => {
  //   const ret: any = await Storage.get({ key: "userId" });
  //   const temp = JSON.parse(ret.value);
  //   return temp ? temp : null;
  // };
  const [togglesearch, settogglesearch] = useState<any>(false);
  const [isOpt, setisOpt] = useState<boolean>(false);
  const [cntactids, setcontactids] = useState<any>([]);
  const [cntactnames, setcntactnames] = useState<any>([]);
  const [cntactemail, setcntactemail] = useState<any>([]);
  const [optcntactids, setoptcontactids] = useState<any>([]);
  const [optcntactnames, setoptcntactnames] = useState<any>([]);
  const [optcntactemail, setoptcntactemail] = useState<any>([]);
  const [stateStartDate,setstateStartDate] = useState<any>(moment().format("ddd, DD MMM YYYY") + " 04:30:00 GMT")  
  const [stateEndDate,setstateEndDate] = useState<any>(moment().add(30,"minute").format("ddd, DD MMM YYYY") + " 05:00:00 GMT");

  const triggerSearch = () => {
    //history.push('/newevent/attendeesearch')
    settogglesearch(true);
    // console.log(formik.values.eventDesc);
  };
  const opttriggerSearch = () => {
    //history.push('/newevent/attendeesearch')
    setisOpt(true);
    settogglesearch(true);
    // console.log(formik.values.eventDesc);
  };

  
  function retrievedData(returnval) {
    // console.log(returnval);
    settogglesearch(false);
    // setfromdueDate(convertDateTime(returnval[2], returnval[3]));
    // settodueDate(convertDateTime(returnval[2], returnval[4]));
    let tempcontid = cntactids;
    let opttempcontid = optcntactids;
    let tempcontnames = cntactnames;
    let opttempcontnames = optcntactnames;
    let tempcontemail = cntactemail;
    let opttempcontemail = optcntactemail;
    
    if (returnval[6]) {
      isOpt ? opttempcontid.push(returnval[6]) : tempcontid.push(returnval[6]);
    }
    if (returnval[7]) {
      if (returnval[9]) {
        isOpt
          ? opttempcontnames.push(returnval[7] + " " + returnval[9])
          : tempcontnames.push(returnval[7] + " " + returnval[9]);
      } else
        isOpt
          ? opttempcontnames.push(returnval[7])
          : tempcontnames.push(returnval[7]);
    }
    if (returnval[10]) {
      isOpt ? opttempcontemail.push(returnval[10]) : tempcontemail.push(returnval[10]);
    }
    //setcontactids(returnval[6]?tempcontid?tempcontid+','+returnval[6]:'':'');
    //setcontactids(tempcontid?tempcontid+','+returnval[6]:returnval[6]?returnval[6]:'');
    setcontactids(tempcontid);
    setoptcontactids(opttempcontid);
    setcntactnames(tempcontnames);
    setcntactemail(tempcontemail);
    setoptcntactnames(opttempcontnames);
    setoptcntactemail(opttempcontemail);
    
    formik.values.reqdAttendees =
      returnval[5] + returnval[7]
        ? returnval[9]
          ? returnval[7] + " " + returnval[9]
          : returnval[7]
        : "-" + ",";
    if (isOpt) {
      setisOpt(false);
    }
  }

  function deletechip(val) {
    let indexval = cntactids?.indexOf(val.toString());


    // console.log(val.toString())
    let temp = [...cntactids];
    let temp1 = [...cntactnames];
    temp.splice(indexval, 1);
    temp1.splice(indexval, 1);
    setcontactids(temp);
    setcntactnames(temp1);
    // cntactids.pop(indexval);
    // cntactnames.pop(indexval);
  }

  function revertBack() {
    settogglesearch(false);
  }

  const [user, setUser] = React.useState<any>();
  const [hubOwners, setOwners] = useState<any>([]);
  const [ownerIds,setownerIds] = useState<any>([]);
  const [onboardingId,setOnboardingId] = useState('');
  
  useEffect(() => {
    
    setLoading(true);
    getUserId().then((userId) => {
      setUser(parseInt(userId));
    });
    Device.getInfo().then(deviceInfo => {
    getUserEmail().then(useremail => {
      if(deviceInfo.platform == "web")
          {
            window.globalThis.firebase.analytics().setUserId(useremail);
            window.globalThis.firebase.analytics().logEvent('select_content', {
            user_name: useremail,
            content_type: 'NewMeetingNote',
            item_id: contId,
            device_id:deviceInfo?deviceInfo.uuid:"",
            platform:deviceInfo?deviceInfo.platform:"",
            device_model:deviceInfo?deviceInfo.model:"",
            device_type:deviceInfo?deviceInfo.osVersion:"",
            device_os:deviceInfo?deviceInfo.operatingSystem:"",
              });

          }
         else
          {
            FirebaseAnalytics.setUserId(useremail);
            FirebaseAnalytics.logEvent("select_content", {user_name: useremail});
            FirebaseAnalytics.logEvent("select_content", {content_type: 'NewMeetingNote'});
            FirebaseAnalytics.logEvent("select_content", {item_id: contId});
            FirebaseAnalytics.logEvent("select_content", {device_id: deviceInfo?deviceInfo.uuid:""});
            FirebaseAnalytics.logEvent("select_content", {platform: deviceInfo?deviceInfo.platform:""});
            FirebaseAnalytics.logEvent("select_content", {device_model: deviceInfo?deviceInfo.model:""});
            FirebaseAnalytics.logEvent("select_content", {device_type:deviceInfo?deviceInfo.osVersion:""});
            FirebaseAnalytics.logEvent("select_content", {device_os:deviceInfo?deviceInfo.operatingSystem:""});
          }
    })
  })

    api.getContactDetails(contId).then((data) => {
      
      setContact(data);
    
      api.getOwners().then((resp) => {
        setOwners(resp?.results);
      });
      if(data && data?.properties){
        //Set Exp Details
        const {
          amount_status,
          expected_closing_probability,
          expected_month_of_closing,
          onboarding_id
        } = data?.properties;
        // console.log(
        //   amount_status,
        //   expected_closing_probability,
        //   expected_month_of_closing
        // );
        formik.setFieldValue("closingAmt", amount_status);
        formik.setFieldValue("closingProb", expected_closing_probability);
        formik.setFieldValue("closingMonth", expected_month_of_closing);
        setOnboardingId(onboarding_id);
      }  
      setLoading(false);
    });

    api
      .getContactPropertiesByProperty("expected_closing_probability")
      .then((resp) => {
        if (resp?.type === "enumeration") {
          setclsProb(
            resp.options.map((o) => {
              return { val: o.value };
            })
          );
        }
      })
      .catch((error) => {
        console.error("Error from Conacts API: ", error);
      });

    api.GetNoteByProspectId(contId,onboardingId).then((data) => {
      let notesArr = data
        .sort((a, b) => {
          return b.updatedDate > a.updatedDate ? 1 : -1;
        })
        .map((obj) => {
          return {
            noteTiming: obj.updatedDate,
            noteid: obj.rowId,
            noteDesc: obj.meetingTopic,
            noteAccess: obj.meetingType,
          };
        });
      if (notesArr.length > 0) {
        api.GetNotesInsights(notesArr[0].noteid).then((data) => {
          formik.setFieldValue(
            "coreBusiness",
            data.optCorebusiness &&
              data.optCorebusiness !== "" &&
              data.optCorebusiness !== null
              ? data.optCorebusiness
              : ""
          );
          formik.setFieldValue(
            "viewOnComp",
            data.gvc && data.gvc !== "" && data.gvc !== null ? data.gvc : ""
          );
          formik.setFieldValue(
            "investObj",
            data.invObjective &&
              data.invObjective !== "" &&
              data.invObjective !== null
              ? data.invObjective
              : ""
          );
          formik.setFieldValue(
            "investLiq",
            data.invLiquidity &&
              data.invLiquidity !== "" &&
              data.invLiquidity !== null
              ? data.invLiquidity
              : ""
          );
          formik.setFieldValue(
            "intlMkt",
            data.optIntlEconomy &&
              data.optIntlEconomy !== "" &&
              data.optIntlEconomy !== null
              ? data.optIntlEconomy
              : ""
          );
          formik.setFieldValue(
            "intlExpo",
            data.apetiteIntlExposure &&
              data.apetiteIntlExposure !== "" &&
              data.apetiteIntlExposure !== null
              ? data.apetiteIntlExposure
              : ""
          );
          formik.setFieldValue(
            "localECO",
            data.optLocalEconomy &&
              data.optLocalEconomy !== "" &&
              data.optLocalEconomy !== null
              ? data.optLocalEconomy
              : ""
          );
          setlstmeetdate(moment(data.updatedDate));
        });
      }
    });
  }, [contId])

  const validate = (values) => {
    const errors: any = {};
    if (!values.coreBusiness) {
      errors.coreBusiness = "Please select option";
    }
    if (!values.intlMkt) {
      errors.intlMkt = "Please select option";
    }
    if (!values.intlExpo) {
      errors.intlExpo = "Please select option";
    }
    if (!values.localECO) {
      errors.localECO = "Please select option";
    }
    if (!values.investObj) {
      errors.investObj = "Please select option";
    }
    if (!values.viewOnComp) {
      errors.viewOnComp = "Please select option";
    }
    if (!values.investLiq) {
      errors.investLiq = "Please select option";
    }
    if (!values.meetingNote) {
      errors.meetingNote = "(Required)";
    }

    // if (!values.intlMkt) {
    //   errors.intlMkt = "Please select option";
    // }
    // if (!values.intlExpo) {
    //   errors.intlExpo = "Please select option";
    // }
    // if (!values.localECO) {
    //   errors.localECO = "Please select option";
    // }
    // if (!values.investObj) {
    //   errors.investObj = "Please select option";
    // }
    // if (!values.viewOnComp) {
    //   errors.viewOnComp = "Please select option";
    // }
    // if (!values.investLiq) {
    //   errors.investLiq = "Please select option";
    // }
    // if (!values.meetingNote) {
    //   errors.meetingNote = "(Required)";
    // }
    if(new Date(stateEndDate) <= new Date(stateStartDate)) {     
      errors.endtime = "End Time should be greater than Start Time";
    }
    return errors;
  };

 

  const formik = useFormik({
    validate,
    initialValues: {
      date: moment(currDate).format("YYYY-MM-DD"),
      //time: "10:00:00",
      inputstarttime:"10:00:00",
      starttime: moment().format("ddd, DD MMM YYYY") + " 04:30:00 GMT",
      endtime: moment().add(30,"minute").format("ddd, DD MMM YYYY") + " 05:00:00 GMT",
      // starttime: "Mon, 01 Mar 2021 04:30:00 GMT",
      // endtime: "Mon, 01 Mar 2021 05:00:00 GMT",
      principle: true,
      advisor: false,
      familymember: false,
      other: false,
      otherTypeValue: "",
      meetingTopic: "1st Meeting",
      otherTopicValue: "",
      meetingType: "Pre-qualification",
      meetingNote: "",
      viewOnComp: "",
      investObj: "",
      investLiq: "",
      localECO: "",
      intlMkt: "",
      coreBusiness: "",
      intlExpo: "",
      closingProb: "75%",
      closingMonth: "April 2021",
      closingAmt: "$ 2 Million",
      liqEvent: "",
      taskTitle: "",
      dueDate: moment(currDate).format("YYYY-MM-DD"),
      dueTime: "10:00:00",
      taskType: "TODO",
      priority: "NONE",
      taskNotes: "",
      emailReminder: moment(currDate).format("YYYY-MM-DD"),
      reminderTime: "10:00:00",
      attendeesName:"",
      reqdAttendees:{cntactnames}
    },
    onSubmit: (values) => {
      
      setLoading(true);
      function iOS() {
        return (
          [
            "iPad Simulator",
            "iPhone Simulator",
            "iPod Simulator",
            "iPad",
            "iPhone",
            "iPod",
          ].includes(navigator.platform) ||
          (navigator.userAgent.includes("Mac"))
        );
      }
      const convertDateTime = (date, time) => {
        let dateTimeStr = date + "T" + time;
        let dateTimeStr_new = new Date(new Date(dateTimeStr));
        let dateTimeStr_ios = dateTimeStr_new.setMinutes(dateTimeStr_new.getMinutes() + dateTimeStr_new.getTimezoneOffset());
        // console.log(dateTimeStr);
        let DateTimeDt = new Date(dateTimeStr);
        // console.log(DateTimeDt);
        let dateTimeUTC = DateTimeDt.toUTCString();
        //console.log(dateTimeUTC);
        let isounixVal = parseInt(moment(dateTimeUTC).format("x"));
        //console.log(isounixVal);
        if(iOS())
        {
          //return dateTimeStr_ios;
          return isounixVal;
        }
        else
        {
          return isounixVal;
        }
      };

      const taskPayload = {
        engagement: {
          active: true,
          type: "TASK",
          ownerId: user,
          timestamp: convertDateTime(
            moment(values.dueDate).format("YYYY-MM-DD"),
            values.dueTime
          ),
          priority: values.priority,
        },
        associations: {
          contactIds: [parseInt(contact.id)],
          companyIds: [],
          dealIds: [],
          ownerIds: [],
          ticketIds: [],
        },
        metadata: {
          status: "NOT_STARTED",
          subject: values.taskTitle,
          body: values.taskNotes,
          taskType: values.taskType,
          forObjectType: "CONTACT",
          reminders: [
            convertDateTime(
              moment(values.emailReminder).format("YYYY-MM-DD"),
              values.reminderTime
            ),
          ],
          priority: values.priority,
        },
      };

      let clType = "";
      clType = values.principle ? clType + ";" + "Principle" : clType;
      clType = values.advisor ? clType + ";" + "Advisor" : clType;
      clType = values.familymember ? clType + ";" + "Family member" : clType;
      clType = values.other
        ? (clType = clType + ";" + "Other" + ";" + values.otherTypeValue)
        : clType;
        

      Network.getStatus().then((network) => {
      if(network.connected) {
      if(values.taskTitle)
      {
      api.createTask(taskPayload).then((resp) => {         
        let taskId = resp.engagement.id;
         //create a payload - call api to create meeeting -

         /*Start of payload*/
         const notemeetingPayload = {
          engagement: {
            ownerId: user,
            type: "MEETING",
            active: true,
            activityType:values.meetingType,
          },
          associations: {
            contactIds: [parseInt(contact.id)],
            ownerIds: ownerIds,
          },
          metadata: {
            body: values.meetingNote,
            startTime: convertDateTime(
              moment(values.date).format("YYYY-MM-DD"),
              //values.starttime
              moment(values.starttime).format("HH:mm:00")
            ),
            endTime: convertDateTime(
              moment(values.date).format("YYYY-MM-DD"),
             // values.endtime
             moment(values.endtime).format("HH:mm:00")
            ),
            title: "",
            // meetingOutcome:"SCHEDULED",
            meetingOutcome:"COMPLETED"
          },
        };
      /*End of payload*/
        api.createTask(notemeetingPayload).then((resp) => {
      //      let createNoteData;
           let contactName;
      //     let externalAttendeesName;
      //     let createNoteDataAttendees;
      //      if(cntactids.indexOf(contact.id) === -1){
      //  createNoteDataAttendees = [...cntactids,contact.id] //commented as we dnt need meeting for multiple prsopect
      // createNoteData = contact.id.toString()
       contactName= contact.properties.firstname+" "+contact.properties.lastname;
      //       // externalAttendeesName=  cntactnames.toString()===""?contactName:cntactnames.toString()+", "+contactName
      //       externalAttendeesName=  cntactnames.toString()===""?contactName:cntactnames.toString()
      //     }else{
      //       createNoteDataAttendees = [...cntactids];
      //    createNoteData = contact.id.toString()
      //     externalAttendeesName=  cntactnames.toString();
      //     }          
          // let createNoteData = [...cntactids,contact.id]
          // let createNotePayload = createNoteDataAttendees?.join(",")+","
          // console.log(resp.engagement.id);
            //console.log(resp.associations);
            const meetingPayload: meetingNotePayload = {
              createdBy: user,
              updatedBy: user,
              description: "",
              meetingDateTime: convertDateTime(
                moment(values.date).format("YYYY-MM-DD"),
                //values.starttime
                moment(values.starttime).format("HH:mm:00")
              ),
              meetingEndDateTime :convertDateTime(
                moment(values.date).format("YYYY-MM-DD"),
               // values.endtime
               moment(values.endtime).format("HH:mm:00")
              ),
              clientType: clType,
              meetingTopic: values.meetingTopic === "Other" ? values.otherTopicValue : values.meetingTopic,
              meetingType: values.meetingType,
              notes: values.meetingNote,
              gvc: values.viewOnComp,
              invObjective: values.investObj,
              invLiquidity: values.investLiq,
              optLocalEconomy: values.localECO,
              optIntlEconomy: values.intlMkt,
              optCorebusiness: values.coreBusiness,
              apetiteIntlExposure: values.intlExpo,
              liquidityEvent: values.liqEvent,
              followupTaskId: taskId,
              hubspotMeetingId:resp && resp.engagement && resp.engagement.id ? resp.engagement.id : "",
              attendees: values.attendeesName?values.attendeesName.toString():"",
             externalAttendees: cntactnames.toString(),
              // externalAttendees: externalAttendeesName,
              //  prospectId:createNoteData,
              // externalAttendeesId:createNotePayload,  
            };
          
          
            api.createNote(contact.id, meetingPayload).then((noteResp) => {
               //api to trigger mail for meetings notes create and update alopama
               emailTrigger(contactName,cntactnames,values);
               
              
              const updContactPayload = {
                properties: {
                  expected_closing_probability: values.closingProb,
                  amount_status: Number(
                    values.closingAmt.replace(/[^0-9.-]+/g, "")
                  ),
                  expected_month_of_closing: values.closingMonth,
                },
              };
    
              if (
                values.closingProb !==
                  contact.properties.expected_closing_probability ||
                Number(values.closingAmt.replace(/[^0-9.-]+/g, "")) !==
                  Number(contact.properties.amount_status) ||
                values.closingMonth !== contact.properties.expected_month_of_closing
              ) {
                  
                api
                  .updateProspect(parseInt(contact.id), updContactPayload)
                  .then((resp) => {
                    
                    setLoading(false);
                    history.push(`/contactinfo/${contact.id}`, {
                      to: "notes",
                    });
                  });
              } else {
                setLoading(false);
                history.push(`/contactinfo/${contact.id}`, {
                  to: "notes",
                });
              }
            });
        })    
      })
    }
    else
    {
     // api.createTask(taskPayload).then((resp) => {         
       // let taskId = resp.engagement.id;
         //create a payload - call api to create meeeting -

         /*Start of payload*/
         const notemeetingPayload = {
          engagement: {
            ownerId: user,
            type: "MEETING",
            active: true,
            activityType:values.meetingType,
          },
          associations: {
           // contactIds: [parseInt(contact.id)],
           contactIds: [...cntactids,contact.id].map(Number), //MA-110 fix by alopama
            ownerIds: ownerIds,
          },
          metadata: {
            body: values.meetingNote,
            startTime: convertDateTime(
              moment(values.date).format("YYYY-MM-DD"),
              //values.starttime
              moment(values.starttime).format("HH:mm:00")
            ),
            endTime: convertDateTime(
              moment(values.date).format("YYYY-MM-DD"),
             // values.endtime
             moment(values.endtime).format("HH:mm:00")
            ),
            title: "",
            // meetingOutcome:"SCHEDULED",
            meetingOutcome:"COMPLETED"
          },
        };
        
      /*End of payload*/
        api.createTask(notemeetingPayload).then((resp) => {
       
          //  let createNoteData;
          let contactName;
          // let externalAttendeesName;
          // let createNoteDataAttendees;
          //  if(cntactids.indexOf(contact.id) === -1){
          //    createNoteDataAttendees = [...cntactids,contact.id] //commented as we dnt need meeting for multiple prsopect
          //     createNoteData = contact.id.toString()
           contactName= contact.properties.firstname+" "+contact.properties.lastname;
          //   // externalAttendeesName=  cntactnames.toString()===""?contactName:cntactnames.toString()+", "+contactName
          //   externalAttendeesName=  cntactnames.toString()===""?contactName:cntactnames.toString()
          //  }else{
          //   createNoteDataAttendees = [...cntactids];
          //  createNoteData = contact.id.toString()
          // externalAttendeesName=  cntactnames.toString();
          // }          
          // // let createNoteData = [...cntactids,contact.id]
          // let createNotePayload = createNoteDataAttendees?.join(",")+","
          // console.log(resp.engagement.id);
            //console.log(resp.associations);
            const meetingPayload: meetingNotePayload = {
              createdBy: user,
              updatedBy: user,
              description: "",
              meetingDateTime: convertDateTime(
                moment(values.date).format("YYYY-MM-DD"),
                //values.starttime
                moment(values.starttime).format("HH:mm:00")
              ),
              meetingEndDateTime :convertDateTime(
                moment(values.date).format("YYYY-MM-DD"),
               // values.endtime
               moment(values.endtime).format("HH:mm:00")
              ),
              clientType: clType,
              meetingTopic: values.meetingTopic === "Other" ? values.otherTopicValue : values.meetingTopic,
              meetingType: values.meetingType,
              notes: values.meetingNote,
              gvc: values.viewOnComp,
              invObjective: values.investObj,
              invLiquidity: values.investLiq,
              optLocalEconomy: values.localECO,
              optIntlEconomy: values.intlMkt,
              optCorebusiness: values.coreBusiness,
              apetiteIntlExposure: values.intlExpo,
              liquidityEvent: values.liqEvent,
              followupTaskId: 0,
              hubspotMeetingId:resp && resp.engagement && resp.engagement.id ? resp.engagement.id : "",
              attendees: values.attendeesName?values.attendeesName.toString():"",
              externalAttendees: cntactnames.toString()
              // externalAttendees: externalAttendeesName,
              //  prospectId:createNoteData,
              // externalAttendeesId:createNotePayload,
            };
            
          //MA-113, to create meeting notes for external attendees , Author: Alopama
           
            //reverted back the changes as edit is not working edit
            api.createNote(contact.id, meetingPayload).then((noteResp) => {
                 //api to trigger mail for meetings notes create and update alopama


                 emailTrigger(contactName,cntactnames,values);
             
              const updContactPayload = {
                properties: {
                  expected_closing_probability: values.closingProb,
                  amount_status: Number(
                    values.closingAmt.replace(/[^0-9.-]+/g, "")
                  ),
                  expected_month_of_closing: values.closingMonth,
                },
              };
    
              if (
                values.closingProb !==
                  contact.properties.expected_closing_probability ||
                Number(values.closingAmt.replace(/[^0-9.-]+/g, "")) !==
                  Number(contact.properties.amount_status) ||
                values.closingMonth !== contact.properties.expected_month_of_closing
              ) {
                  
                api
                  .updateProspect(parseInt(contact.id), updContactPayload)
                  .then((resp) => {
                    
                    setLoading(false);
                    history.push(`/contactinfo/${contact.id}`, {
                      to: "notes",
                    });
                  });
              } else {
                setLoading(false);
                history.push(`/contactinfo/${contact.id}`, {
                  to: "notes",
                });
              }
            
            });
        })    
     // })
    }
    
    } else {
       let createNoteData;
           let contactName;
          let externalAttendeesName;
          let createNoteDataAttendees;
           if(cntactids.indexOf(contact.id) === -1){
            createNoteDataAttendees = [...cntactids,contact.id] //commented as we dnt need meeting for multiple prsopect
            createNoteData = contact.id.toString()
           // contactName= contact.properties.firstname+" "+contact.properties.lastname;
            // externalAttendeesName=  cntactnames.toString()===""?contactName:cntactnames.toString()+", "+contactName
            externalAttendeesName=  cntactnames.toString()===""?contactName:cntactnames.toString() 
          }else{
            createNoteDataAttendees = [...cntactids]; //commented as we dnt need meeting for multiple prsopect
         createNoteData = contact.id.toString()
          externalAttendeesName=  cntactnames.toString();
          }          
          // let createNoteData = [...cntactids,contact.id]
          let createNotePayload = createNoteDataAttendees?.join(",")+","
        const meetingPayload: meetingNotePayload = {
          createdBy: user,
          updatedBy: user,
          description: "",
          meetingDateTime: convertDateTime(
            moment(values.date).format("YYYY-MM-DD"),
            //values.starttime
            moment(values.starttime).format("HH:mm:00")
          ),
          meetingEndDateTime :convertDateTime(
            moment(values.date).format("YYYY-MM-DD"),
            //values.endtime
            moment(values.endtime).format("HH:mm:00")
          ),
          clientType: clType,
          meetingTopic: values.meetingTopic === "Other" ? values.otherTopicValue : values.meetingTopic,
          meetingType: values.meetingType,
          notes: values.meetingNote,
          gvc: values.viewOnComp,
          invObjective: values.investObj,
          invLiquidity: values.investLiq,
          optLocalEconomy: values.localECO,
          optIntlEconomy: values.intlMkt,
          optCorebusiness: values.coreBusiness,
          apetiteIntlExposure: values.intlExpo,
          liquidityEvent: values.liqEvent,
          followupTaskId: 0,
          hubspotMeetingId: 0,
          attendees: ownerIds.toString(),
          externalAttendees: "",
          //  prospectId:createNoteData,
          // externalAttendeesId:createNotePayload,
         
        };
        api.createNote(contact.id, meetingPayload).then((noteResp) => {   
           //api to trigger mail for meetings notes create and update alopama
           emailTrigger(contactName,cntactnames,values);
           
          setLoading(false);
            history.push(`/contactinfo/${contact.id}`, {
              to: "notes",
          });
        });
      }

       
      });
    },
  });

   
  // console.log("form value", formik.values);
  const { height, width } = useWindowDimensions();


  const emailTrigger = (contactName,cntactnames,values)=>{
    
    let emailList:any=[]
    let tfoattendees:any=[]
    hubOwners
      .map((obj) => {
        ownerIds.map((ownerObj)=>{
          if( obj.id == ownerObj){
            emailList.push(obj.email)
            if(obj.firstName===""){
              tfoattendees.push(obj.email)
            }else{
            tfoattendees.push(obj.firstName+" "+obj.lastName)
            }
          }
        })             
      })
      let emailToList= emailList.concat(cntactemail);
     let meetingDetails={
       "contactName":contactName,
       "TypeOfMeeting":values.meetingType,
       "MeetingDate": moment(values.date).format("YYYY-MM-DD"),
       "MeetingTime": moment(values.starttime).format("HH:mm:00"),
       "TFOGroupattendees":tfoattendees,
       "DetailedMeetingnotes":values.meetingNote,
       "Otherattendees":cntactnames,

      }

      if(formik.values.meetingNote.length>50){
        api
          .SendOTPEmail({
            "subject": "Meetings Notes",
            "from": "RMAPP",
            "tolist":emailList,
            otp: meetingDetails,
          }) .then((emailresp) => {
         })
      }else{
        // console.log("email did not sent")
      }
    
  }

  function tempfunct(index)
  {
    let acttime = index.substr(0, index.length - 3);
    let tme = "PM";
    if(parseInt(index.substr(0, 3))<=9)
    {
      acttime = (acttime.slice(1)) ;
    }  
    parseInt(index.substr(0, 3))<=11? tme="AM":acttime = (parseInt(acttime.substr(0, 3))%12) + acttime.slice(2) ;

    return acttime +" "+ tme
  }
  var now1000  = new Date();
  now1000.setHours(10);
  now1000.setMinutes(0);
  now1000.setSeconds(0);
  // console.log(now1000.toUTCString());
  var now1030  = new Date();
  now1030.setHours(10);
  now1030.setMinutes(30);
  now1030.setSeconds(0);
  //console.log(now1000.toUTCString());
  //const [SelectedStartDate, setSelectedStartDate] = useState<any>(now1000.toUTCString());
  //const [SelectedEndDate, setSelectedEndDate] = useState<any>(now1030.toUTCString());
// function tempfunction(val)
// {
//   setSelectedEndDate(val);
// }
  return togglesearch ? (
    <AttendeeSearch
      isOpt={isOpt}
      retrievedData={retrievedData}
      revertBack={revertBack}
       cntactids={cntactids}
      // eventtitle={formik.values.eventTitle}
      // eventDesc={formik.values.eventDesc}
      // dueDate={formik.values.dueDate}
      // dueTimeStart={formik.values.dueTimeStart}
      // dueTimeEnd={formik.values.dueTimeEnd}
      // reqdAttendees={formik.values.reqdAttendees}
    />
  ) : (
    <IonPage>
      <IonLoading
        cssClass="my-custom-loading-class"
        isOpen={isLoading}
        message={"Please wait..."}
      />
      <IonHeader>
        <IonToolbar mode="md" style={styles.toolbar}>
          <IonButtons slot="start">
            {/* <IonBackButton
              style={{ textTransform: "none" }}
              color="white"
              text={
                contact && contact.properties.hs_lifecyclestage_customer_date
                  ? "Client Profile"
                  : "Prospect Profile"
              }
              icon={chevronBack}
            ></IonBackButton> */}
            <IonButton
              style={{ textTransform: "none" }}
              color="white"
              // routerLink="/dashboard"
              onClick={() => {
                history.push(`/contactinfo/${contact.id}`, {
                  to: "details",
              })}}
            >
              <IonIcon icon={chevronBack}></IonIcon>
              {contact && contact.properties.hs_lifecyclestage_customer_date
                  ? "Client Profile"
                  : "Prospect Profile"}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form onSubmit={formik.handleSubmit}>
          <IonRow class="margin-set mt-6" style={styles.headerRow} className="headerRow-desk">
            <IonCol size-xs="9" sizeLg="10" size-sm="10">
              <IonText class="fs-28" style={styles.engName}>
                {contact
                  ? contact.properties.title_salutation
                    ? contact.properties.title_salutation +
                      " " +
                      contact.properties.firstname +
                      " " +
                      contact.properties.lastname
                    : contact.properties.firstname +
                      " " +
                      contact.properties.lastname
                  : ""}
              </IonText>
            </IonCol>
            <IonCol class="ion-padding-top" size-xs="3" sizeLg="2" size-sm="4">
              <IonText class="fw-unset fs-14" style={styles.reqText}>* = required</IonText>
            </IonCol>
          </IonRow>
          <IonRow class="margin-set" style={styles.arbHeaderRow}>
            <IonCol>
              <IonText class="fs-18" style={styles.arbName}>
                {contact
                  ? contact.properties.first_name_arabic +
                    " " +
                    contact.properties.arabic_middle_name +
                    " " +
                    contact.properties.arabic_last_name
                  : ""}
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow class="margin-set" style={styles.headerRow} className="headerRow-desk">
            <IonCol>
              <IonText class="fs-22" style={styles.formHeader}>Meeting Information</IonText>
            </IonCol>
          </IonRow>
          <IonRow class="margin-set" style={styles.headerRow} className="headerRow-desk">
            <IonCol>
              <IonText class="fs-17" style={styles.fieldLabel}>*Date & Time</IonText>
            </IonCol>
          </IonRow>
          <IonRow class="margin-set" style={{ marginTop: 6, marginLeft: 32 }}>
            <IonCol size-xs="12" sizeSm="6" sizeLg="4">
              <IonItem className="m-margin-rght16" lines="none" style={styles.dateItem}>
                <CustDatePicker
                  dateFormat="dddd, MMMM DD, YYYY"
                  date={formik.values.date}
                  InputClassName="meetingdatentime tfo-calendar-picker-input tfo-input"
                  onSetDateVal={(val) => {
                    formik.setFieldValue("date", val);
                  }}
                />
                <IonIcon
                  src={calendargold}
                  slot="end"
                  style={{ width: 24, height: 24 }}
                ></IonIcon>
              </IonItem>
            </IonCol>
            <IonCol
              size-xs="1"
              sizeSm="1"
              sizeLg="1"
              style={{ display: "flex" }}
            >
              <IonText style={{ margin: "auto" }}>From</IonText>
            </IonCol>
            <IonCol size-xs="12" sizeSm="2" sizeLg="2">
              <IonItem className="m-margin-rght16" lines="none" style={styles.dateItem}>
                {/* <IonInput
                value={
                  tempfunct(formik.values.inputstarttime)
                  }
                  onIonBlur={(val) => {
                  
                  let inpVal = (val.target as HTMLInputElement).value ;
                  let numlength =  inpVal.replace(/[^0-9]/g,"").length;
                  let setVal = "10:00:00";
                  if(inpVal.length >=4 && inpVal.length <=8)
                  {
                    console.log("sdff");
                    let colPos = inpVal.indexOf(":");
                    if((numlength == 3  || numlength == 4) && inpVal.includes(":") && inpVal.replace(/[^:]/g,"").length == 1 && colPos < 3)
                    {
                      console.log(colPos);
                      console.log(inpVal[0].replace(/[^0-9]/g,"").length);                      
                      if(colPos == 1 && inpVal[0].replace(/[^0-9]/g,"").length)
                      {
                        console.log("hi");
                        setVal = "0"+inpVal[0]+":00:00";
                      }
                      else
                      {
                        setVal = "10:00:00";
                      }
                    }
                    else
                    {
                      setVal = "10:00:00";
                    }
                  }
                  else if(inpVal.length >=1 && inpVal.length <=2 && numlength >=1 && numlength <=2 )
                  {
                    console.log("hiiiret");
                    if(inpVal.length ==2 && numlength ==1 )
                    {
                        setVal = "10:00:00";
                    }
                    else
                    {                      
                      if(numlength ==1 && inpVal.length ==1)
                      {
                        setVal = "0"+inpVal+":00:00";
                      }
                      else if(numlength ==2 && inpVal.length ==2 && parseInt(inpVal)<=24)
                      {
                        setVal = inpVal+":00:00";
                      }
                      else
                      {
                        setVal = "10:00:00";
                      }
                      
                    }                      
                  }
                  else
                  {
                    console.log("hiii");
                    //formik.setFieldValue("inputstarttime", "10:00:00");
                    setVal = "10:00:00";
                  }
                  formik.setFieldValue("inputstarttime", setVal);
                  console.log(formik.values.inputstarttime);
                  let endVal :  any = null;
                    for(var i in timePickerOptions){
                      if(timePickerOptions[parseInt(i)].fVal === setVal){  
                        endVal = timePickerOptions[parseInt(i)+1].fVal
                      }  
                    }
                    console.log(endVal);
                    formik.setFieldValue("endtime",endVal);
                }}  
                >           
                </IonInput> */}
                <IonDatetime
                  displayFormat="h:mm A" 
                  value={formik.values.starttime}
                  mode="ios"
                  onIonChange={event => {     
                    let valValue:any =   event.detail.value;
                    formik.setFieldValue("starttime",new Date(valValue).toUTCString());
                    var d1 = new Date (valValue);
                    var d2 = new Date ( d1 );
                    d2.setMinutes ( d1.getMinutes() + 30 );
                    formik.setFieldValue("endtime",new Date(d2).toUTCString());
                    setstateStartDate(new Date(valValue).toUTCString());
                  } }
                  name="starttime"
                  >
                </IonDatetime>
                {/* <IonSelect
                  interface={width < 576 ? "action-sheet" : "popo8ver"}
                  interfaceOptions={optionsA}
                  mode="ios"
                  value={""}
                  // onIonChange={formik.handleChange}
                  onIonChange={(val) => {
                    formik.setFieldValue("starttime", val.detail.value);
                    formik.setFieldValue("inputstarttime", val.detail.value);
                    let endVal :  any = null;
                    for(var i in timePickerOptions){
                      if(timePickerOptions[parseInt(i)].fVal === val.detail.value){  
                        endVal = timePickerOptions[parseInt(i)+1].fVal
                      }  
                    }
                    formik.setFieldValue("endtime",endVal);
                  }}
                  name="starttime"
                >
                  {timePickerOptions.map((val , i) => (
                    <IonSelectOption key={i} value={val.fVal}>
                      {val.val}
                    </IonSelectOption>
                  ))}
                </IonSelect> */}
                <IonIcon slot="end" style={{marginLeft: "auto"}}  color="gold" src={chevronDown}></IonIcon>
              </IonItem>
              
            </IonCol>
            <IonCol
              size-xs="1"
              sizeSm="1"
              sizeLg="1"
              style={{ display: "flex" }}
            >
              <IonText style={{ margin: "auto" }}>To</IonText>
            </IonCol>
            <IonCol size-xs="12" sizeSm="2" sizeLg="2">
            <IonItem className="m-margin-rght16" lines="none" style={styles.dateItem}>
            <IonDatetime
                  displayFormat="h:mm A"  
                  value={formik.values.endtime}
                  mode="ios"
                  name="endtime"
                  onIonChange={event => { 
                    let endValue:any =   event.detail.value;
                    formik.setFieldValue("endtime",new Date(endValue).toUTCString());
                    setstateEndDate(new Date(endValue).toUTCString());  
                   }
                  }
                 >
            </IonDatetime>               
                <IonIcon slot="end" style={{marginLeft: "auto"}} color="gold" src={chevronDown}></IonIcon>
              </IonItem> 
            </IonCol>
          </IonRow>
          {formik.errors.endtime  ? (
            <IonRow class="margin-set" style={{marginLeft: 32}} className="headerRow-desk">
              <IonText color="danger">
                {formik.errors.endtime}
              </IonText>
              </IonRow>
            ) : null}
          <IonRow class="margin-set" style={styles.headerRow} className="headerRow1-desk">
            <IonCol size-xs="12" size-sm="4">
              <IonRow>
                <IonLabel style={styles.fieldLabel}>
                  Prospect/Client Type
                </IonLabel>
              </IonRow>
              {checkboxList.map(({ val, name }, i) => (
                <IonItem lines="none" key={i} style={styles.optionItemI1}>
                  <IonLabel style={styles.optionLabel}>{val}</IonLabel>
                  <IonCheckbox
                    mode="md"
                    style={styles.checkIcon}
                    slot="start"
                    value={val}
                    name={name}
                    onIonChange={(val) => {
                      val.detail.value === "Other"
                        ? val.detail.checked
                          ? setRO(false)
                          : setRO(true)
                        : setRO(true);
                      formik.setFieldValue(name, val.detail.checked);
                    }}
                    checked={formik.values[name]}
                  />
                </IonItem>
              ))}
              <IonItem
                style={styles.inputItemI1}
                lines="none"
                className="NoteTypeinput1 m-margin-rght16"
              >
                <IonInput
                  placeholder="Enter a type"
                  name="otherTypeValue"
                  readonly={ro}
                  value={formik.values.otherTypeValue}
                  onIonChange={formik.handleChange}
                  autocapitalize="sentences"
                  spellcheck={true}
                ></IonInput>
              </IonItem>
            </IonCol>
            
            {/* <IonCol size-xs="12" size-sm="4">
              <IonRow>
                <IonLabel style={styles.fieldLabel}>Meeting Topic</IonLabel>
              </IonRow>
              <IonRadioGroup
                name="meetingTopic"
                value={formik.values.meetingTopic}
                // onIonChange={formik.handleChange}
                onIonChange={(val) => {
                  val.detail.value === "Other"
                    ? setRO(false)
                      : setRO(true)
                  formik.setFieldValue("meetingTopic", val.detail.value);
                }}
              >
                {meetingTopicList.map(({ val }, i) => (
                  <IonItem lines="none" key={i} style={styles.optionItem}>
                    <IonRadio
                      slot="start"
                      style={styles.radioIcon}
                      mode="md"
                      value={val}
                    />
                    <IonLabel style={styles.optionLabel}>{val}</IonLabel>
                  </IonItem>
                ))}
              </IonRadioGroup>
              <IonItem
                style={styles.inputItem}
                lines="none"
                className="NoteTypeinput m-margin-rght16"
              >
                <IonInput
                  placeholder="Enter a topic"
                  name="otherTopicValue"
                  readonly={ro}
                  value={formik.values.otherTopicValue}
                  onIonChange={formik.handleChange}
                  autocapitalize="sentences"
                  spellcheck={true}
                ></IonInput>
              </IonItem>
            </IonCol> */}
            <IonCol size-xs="12" size-sm="4">
            <IonLabel class="font14" style={styles.label}>
            Meeting Topic
          </IonLabel>
          <IonItem  style={styles.selectItem} lines="none" class="ion-item-height1">
            <IonSelect
              // as={IonSelect}
              interfaceOptions={optionsA}
              style={styles.select}
              mode="ios"
              onIonChange={formik.handleChange}
              onIonBlur={formik.handleBlur}
              value={formik.values.meetingTopic}
              class="customIonSelect font14"
              name="meetingTopic"
              placeholder="Select an option or create one"
              interface={width < 576 ? "action-sheet" : "popover"}
            >
             {meetingTopicList.map((o) => (
                        <IonSelectOption key={o.val} value={o.val}>
                          {o.val}
                </IonSelectOption>
              ))}
            </IonSelect>
            <IonIcon slot="end" icon={chevronDown} color="gold" />
          </IonItem>

          {formik.values.meetingTopic === "Other" ? (
		   <IonRow >
                      <IonCol>
                        <IonLabel class="font14" style={styles.label}>
                        Please Enter Topic
                        </IonLabel>
                        <IonItem lines="none" style={styles.selectItem} class="ion-item-height">
                          <IonInput
                            class="inpClass font14"
                            onIonChange={formik.handleChange}
                            value={formik.values.otherTopicValue}
                            name="otherTopicValue"
                            placeholder="Enter a Topic"
                            spellcheck={true}
                            autocapitalize="sentences"
                          />
                        </IonItem>
                       
                      </IonCol>
                    </IonRow>
                    ) : (
                    ""
                  )}


            </IonCol>
            {/* <IonCol size-xs="12" size-sm="4">
              <IonRow>
                <IonLabel style={styles.fieldLabel}>Meeting Type</IonLabel>
              </IonRow>
              <IonRadioGroup
                name="meetingType"
                value={formik.values.meetingType}
                onIonChange={formik.handleChange}
              >
                {meetingTypeList.map(({ val }, i) => (
                  <IonItem lines="none" key={i} style={styles.optionItem}>
                    <IonLabel style={styles.optionLabel}>{val}</IonLabel>
                    <IonRadio
                      style={styles.radioIcon}
                      slot="start"
                      mode="md"
                      value={val}
                    />
                  </IonItem>
                ))}
              </IonRadioGroup>
            </IonCol>
          </IonRow> */}
           <IonCol size-xs="12" size-sm="4">
          <IonLabel class="font14" style={styles.label}>
            Meeting Type
          </IonLabel>
          <IonItem  style={styles.selectItem} lines="none" class="ion-item-height1">
            <IonSelect
              // as={IonSelect}
              interfaceOptions={optionsA}
              style={styles.select}
              mode="ios"
              onIonChange={formik.handleChange}
              onIonBlur={formik.handleBlur}
              value={formik.values.meetingType}
              class="customIonSelect font14"
              name="meetingType"
              placeholder="Select an option or create one"
              interface={width < 576 ? "action-sheet" : "popover"}
            >
             {meetingTypeList.map((o) => (
                        <IonSelectOption key={o.val} value={o.val}>
                          {o.val}
                </IonSelectOption>
              ))}
            </IonSelect>
            <IonIcon slot="end" icon={chevronDown} color="gold" />
          </IonItem>
         
        </IonCol>
</IonRow>
          {/* <IonRow class="margin-set" style={styles.headerRow} className="headerRow-desk">
            <IonLabel style={styles.fieldLabel}>*Meeting Notes</IonLabel>
            {formik.errors.meetingNote ? (
              <IonText style={{ color: "red", marginLeft: 10 }}>
                {formik.errors.meetingNote}
              </IonText>
            ) : null}
          </IonRow> */}


          <IonRow class="margin-set" style={styles.headerRow} className="headerRow-desk">
          <IonCol size-xs="12" size-sm="4"  size-lg="4">
            <IonLabel style={styles.fieldLabel}>
              Invite Required Attendees
            </IonLabel>      
            {/* {formik.errors.attendeesName ? (
              <IonText style={{ color: "red", marginLeft: 10 }}>
                {formik.errors.attendeesName}
              </IonText>
            ) : null}         */}
         
          <IonItem style={styles.selectItem} class ="ion-item-height m-margin-rght16" lines="none">
          <IonSelect 
          mode="md"
          className="attendeesSelect"
          interfaceOptions={optionsAttendee}
          multiple
          name="attendeesName"
          value={formik.values.attendeesName}
          onIonChange={(event) => {setownerIds(event.detail.value);formik.setFieldValue("attendeesName",event.detail.value)}}
          >
            {hubOwners
                .map((obj) => {
                  return {
                    val:
                      obj.firstName && obj.firstName !== ""
                        ? `${obj.firstName} ${obj.lastName}`
                        : obj.email,
                    hs_id: obj.id,
                    hs_email:obj.email
                  };
                  
                })
                .map((obj, i) => (
                  <IonSelectOption key={i} custom-rmid={obj.hs_id} value={obj.hs_id} custom-ei={obj.hs_email} custom-id={obj.hs_id}   
                   
                >
                    {obj.val}
                  </IonSelectOption>
                ))}
          </IonSelect>
            <IonIcon slot="end" icon={chevronDown} color="gold" />
          </IonItem>
        </IonCol>
        <IonCol size-xs="12" size-sm="4"  size-lg="4">
            <IonLabel style={styles.fieldLabel}>
              Invite External Attendees
            </IonLabel>      
          <IonItem style={styles.selectItem} class ="ion-item-height m-margin-rght16" lines="none" onClick={triggerSearch}/>          
        </IonCol>
        <IonCol size-xs="10" size-sm="4"  size-lg="4">
        {cntactnames
              ? cntactnames.map((val, i) => (
                  <IonChip key={i}>
                    <IonLabel custom-id={cntactids[i]}>{val}</IonLabel>
                    <IonIcon
                      onClick={(event) => {
                        deletechip(
                          (event.target as HTMLInputElement).getAttribute(
                            "custom-id"
                          )
                        );
                      }}
                      custom-id={cntactids[i]}
                      icon={close}
                    />
                  </IonChip>
                ))
              : null}
        </IonCol>
          </IonRow>
          <IonRow class="desktop-margin margin-set" style={styles.textAreaRow}>
          </IonRow>
          
          <IonRow class="margin-set" style={styles.headerRow} className="headerRow-desk">
            <IonLabel style={styles.fieldLabel}>*Meeting Notes</IonLabel>
            {formik.errors.meetingNote ? (
              <IonText style={{ color: "red", marginLeft: 10 }}>
                {formik.errors.meetingNote}
              </IonText>
            ) : null}
          </IonRow>
          <IonRow class="margin-set m-margin-rght16" style={styles.textAreaRow}>
            <IonTextarea
              
              style={styles.textArea}
              placeholder="Enter any notes from the meeting here"
              name="meetingNote"
              rows={6}
              value={formik.values.meetingNote}
              onIonChange={formik.handleChange}
              spellcheck={true}
              autocapitalize="sentences"
            ></IonTextarea>
          </IonRow>
          <IonRow class="margin-set">
            <IonCol>
              <IonItem style={{ marginRight: 32 }} className="new-note-ion-item" />
            </IonCol>
          </IonRow>
          <IonRow class="margin-set">
            <IonCol>
              {openSentiment ? (
                <React.Fragment>
                  <IonItem
                    onClick={toggleSentiment}
                    style={{ marginRight: 32 }} className="new-note-ion-item"
                    lines="none"
                  >
                    <IonIcon src={close} style={styles.addIcon}></IonIcon>
                    <IonText style={styles.acordHeader}>
                      Close Sentiments
                    </IonText>
                  </IonItem>
                  <IonRow style={styles.headerRow} className="headerRow-desk">
                    <IonLabel class="fs-22" style={styles.formHeader}>
                      Sentiments From Last Meeting{" "}
                    </IonLabel>{" "}
                    <IonLabel style={styles.formHeaderSmall}>
                      {" "}
                      {moment(lstmeetdate).format("LL")} (update as necessary)
                    </IonLabel>
                  </IonRow>
                  <IonRow style={styles.headerRow} className="headerRow-desk">
                    <IonCol size-xs="12" size-sm="4">
                      <IonRow className="sentiTitleRow">
                        <IonLabel style={styles.fieldLabel}>
                          *General View On Company
                        </IonLabel>
                      </IonRow>
                      <IonRadioGroup
                        name="viewOnComp"
                        value={formik.values.viewOnComp}
                        onIonChange={formik.handleChange}
                      >
                        {VOC.map(({ val }, i) => (
                          <IonItem
                            lines="none"
                            key={i}
                            style={styles.optionItem}
                          >
                            <IonLabel style={styles.optionLabel}>
                              {val}
                            </IonLabel>
                            <IonRadio
                              style={styles.radioIcon}
                              slot="start"
                              mode="md"
                              value={val}
                            />
                          </IonItem>
                        ))}
                      </IonRadioGroup>
                      {formik.errors.viewOnComp ? (
                    <IonText className="errormsg" >
                      {formik.errors.viewOnComp}
                    </IonText>
                  ) : null}
                    </IonCol>
                    <IonCol size-xs="12" size-sm="4">
                      <IonRow className="sentiTitleRow">
                        <IonLabel style={styles.fieldLabel}>
                          *Investment Objectives
                        </IonLabel>
                      </IonRow>
                      <IonRadioGroup
                        name="investObj"
                        value={formik.values.investObj}
                        onIonChange={formik.handleChange}
                      >
                        {IO.map(({ val }, i) => (
                          <IonItem
                            lines="none"
                            key={i}
                            style={styles.optionItem}
                          >
                            <IonLabel style={styles.optionLabel}>
                              {val}
                            </IonLabel>
                            <IonRadio
                              style={styles.radioIcon}
                              slot="start"
                              mode="md"
                              value={val}
                            />
                          </IonItem>
                        ))}
                      </IonRadioGroup>
                      {formik.errors.investObj ? (
                    <IonText className="errormsg">
                      {formik.errors.investObj}
                    </IonText>
                  ) : null}
                    </IonCol>
                    <IonCol size-xs="12" size-sm="4">
                      <IonRow className="sentiTitleRow">
                        <IonLabel style={styles.fieldLabel}>
                          *Investment Liquidity
                        </IonLabel>
                      </IonRow>
                      <IonRadioGroup
                        name="investLiq"
                        value={formik.values.investLiq}
                        onIonChange={formik.handleChange}
                      >
                        {IL.map(({ val }, i) => (
                          <IonItem
                            lines="none"
                            key={i}
                            style={styles.optionItem}
                          >
                            <IonLabel style={styles.optionLabel}>
                              {val}
                            </IonLabel>
                            <IonRadio
                              style={styles.radioIcon}
                              slot="start"
                              mode="md"
                              value={val}
                            />
                          </IonItem>
                        ))}
                      </IonRadioGroup>
                      {formik.errors.investLiq ? (
                    <IonText className="errormsg" >
                      {formik.errors.investLiq}
                    </IonText>
                  ) : null}
                    </IonCol>
                  </IonRow>

                  <IonRow style={styles.headerRow} className="headerRow-desk">
                    <IonCol size-xs="12" size-sm="4">
                      <IonRow className="sentiTitleRow">
                        <IonLabel style={styles.fieldLabel}>
                          *Optimism on local economy
                        </IonLabel>
                      </IonRow>
                      <IonRadioGroup
                        name="localECO"
                        value={formik.values.localECO}
                        onIonChange={formik.handleChange}
                      >
                        {OLE.map(({ val }, i) => (
                          <IonItem
                            lines="none"
                            key={i}
                            style={styles.optionItem}
                          >
                            <IonLabel style={styles.optionLabel}>
                              {val}
                            </IonLabel>
                            <IonRadio
                              style={styles.radioIcon}
                              slot="start"
                              mode="md"
                              value={val}
                            />
                          </IonItem>
                        ))}
                      </IonRadioGroup>
                      {formik.errors.localECO ? (
                    <IonText className="errormsg">
                      {formik.errors.localECO}
                    </IonText>
                  ) : null}
                    </IonCol>
                    <IonCol size-xs="12" size-sm="4">
                      <IonRow className="sentiTitleRow">
                        <IonLabel style={styles.fieldLabel}>
                          *Optimism on intl. Market
                        </IonLabel>
                      </IonRow>
                      <IonRadioGroup
                        name="intlMkt"
                        value={formik.values.intlMkt}
                        onIonChange={formik.handleChange}
                      >
                        {OIM.map(({ val }, i) => (
                          <IonItem
                            lines="none"
                            key={i}
                            style={styles.optionItem}
                          >
                            <IonLabel style={styles.optionLabel}>
                              {val}
                            </IonLabel>
                            <IonRadio
                              style={styles.radioIcon}
                              slot="start"
                              mode="md"
                              value={val}
                            />
                          </IonItem>
                        ))}
                      </IonRadioGroup>
                      {formik.errors.intlMkt ? (
                    <IonText className="errormsg">
                      {formik.errors.intlMkt}
                    </IonText>
                  ) : null}
                    </IonCol>
                    <IonCol size-xs="12" size-sm="4">
                      <IonRow className="sentiTitleRow">
                        <IonLabel style={styles.fieldLabel}>
                          *Optimism on core business
                        </IonLabel>
                      </IonRow>
                      <IonRadioGroup
                        name="coreBusiness"
                        value={formik.values.coreBusiness}
                        onIonChange={formik.handleChange}
                      >
                        {OCB.map(({ val }, i) => (
                          <IonItem
                            lines="none"
                            key={i}
                            style={styles.optionItem}
                          >
                            <IonLabel style={styles.optionLabel}>
                              {val}
                            </IonLabel>
                            <IonRadio
                              style={styles.radioIcon}
                              slot="start"
                              mode="md"
                              value={val}
                            />
                          </IonItem>
                        ))}
                      </IonRadioGroup>
                      {formik.errors.coreBusiness ? (
                    <IonText className="errormsg">
                      {formik.errors.coreBusiness}
                    </IonText>
                  ) : null}
                    </IonCol>
                   
                  </IonRow>
                  <IonRow style={styles.headerRow} className="headerRow-desk">
                    <IonCol>
                      <IonRow className="sentiTitleRow">
                        <IonLabel style={styles.fieldLabel}>
                          *Appetite for intl. exposure
                        </IonLabel>
                      </IonRow>
                      <IonRadioGroup
                        name="intlExpo"
                        value={formik.values.intlExpo}
                        onIonChange={formik.handleChange}
                      >
                        {AIE.map(({ val }, i) => (
                          <IonItem
                            lines="none"
                            key={i}
                            style={styles.optionItem}
                          >
                            <IonLabel style={styles.optionLabel}>
                              {val}
                            </IonLabel>
                            <IonRadio
                              style={styles.radioIcon}
                              slot="start"
                              mode="md"
                              value={val}
                            />
                          </IonItem>
                        ))}
                      </IonRadioGroup>
                      {formik.errors.intlExpo ? (
                    <IonText className="errormsg">
                      {formik.errors.intlExpo}
                    </IonText>
                  ) : null}
                    </IonCol>
                  </IonRow>
                  <IonItem lines="full" className="notes-line-nn notes-line-desk"></IonItem>
                </React.Fragment>
              ) : (
                <IonItem onClick={toggleSentiment} style={{ marginRight: 32 }} className="new-note-ion-item">
                  <IonIcon src={add} style={styles.addIcon}></IonIcon>
                  <IonText style={styles.acordHeader}>Add Sentiments</IonText>
                  {
                  //  formik.errors.intlMkt
                  (formik.errors.intlMkt || formik.errors.investLiq || formik.errors.viewOnComp || formik.errors.investObj || formik.errors.localECO || formik.errors.intlExpo || formik.errors.coreBusiness) 
                   ? (
                    <IonText style={{ color: "red", marginLeft: 10 }}>
                      (Required)
                    </IonText>
                  ) : null}
                </IonItem>
              )}
            </IonCol>
          </IonRow>
          <IonRow class="margin-set">
            <IonCol>
              {openFinancial ? (
                <React.Fragment>
                  <IonItem
                    onClick={toggleFinancial}
                    style={{ marginRight: 32 }} className="new-note-ion-item"
                    lines="none"
                  >
                    <IonIcon src={close} style={styles.addIcon}></IonIcon>
                    <IonText style={styles.acordHeader}>
                      Close Financial Information
                    </IonText>
                  </IonItem>
                  <IonRow style={styles.headerRow} className="headerRow-desk">
                    <IonLabel class="fs-22" style={styles.formHeader}>
                      Financial Information
                    </IonLabel>
                  </IonRow>
                  <IonRow style={styles.headerRow} className="headerRow-desk">
                    <IonCol size-xs="12" sizeSm="6" sizeLg="4">
                      <IonRow>
                        <IonLabel style={styles.fieldLabel}>
                          Expected Closing Probability
                        </IonLabel>
                      </IonRow>
                      <IonRow>
                        <IonItem lines="none" style={styles.dropdown}>
                          <IonSelect
                            value={formik.values.closingProb}
                            onIonChange={formik.handleChange}
                            name="closingProb"
                            interface={width < 576 ? "action-sheet" : "popover"}
                            interfaceOptions={optionsA}
                            mode="ios"
                          >
                            {closingProbabilityOptions.map(({ val }, i) => (
                              <IonSelectOption key={i} value={val}>
                                {val}
                              </IonSelectOption>
                            ))}
                          </IonSelect>
                          <IonIcon
                            slot="end"
                            color="gold"
                            src={chevronDown}
                          ></IonIcon>
                        </IonItem>
                      </IonRow>
                    </IonCol>
                    <IonCol size-xs="12" sizeSm="6" sizeLg="4">
                      <IonRow>
                        <IonLabel style={styles.fieldLabel}>
                          Expected Month of Closing
                        </IonLabel>
                      </IonRow>
                      <IonRow>
                        <IonItem lines="none" style={styles.dropdown}>
                          <IonSelect
                            value={formik.values.closingMonth}
                            onIonChange={formik.handleChange}
                            name="closingMonth"
                            interface={width < 576 ? "action-sheet" : "popover"}
                            interfaceOptions={optionsA}
                            mode="ios"
                          >
                            {closingMonthOptions().map(({ val, mom }, i) => (
                              <IonSelectOption key={i} value={mom}>
                                {val}
                              </IonSelectOption>
                            ))}
                          </IonSelect>
                          <IonIcon
                            slot="end"
                            color="gold"
                            src={chevronDown}
                          ></IonIcon>
                        </IonItem>
                      </IonRow>
                    </IonCol>
                    <IonCol size-xs="12" sizeSm="6" sizeLg="4">
                      <IonRow>
                        <IonLabel style={styles.fieldLabel}>
                          Expected Closing Amount
                        </IonLabel>
                      </IonRow>
                      <IonRow>
                        <IonItem lines="none" style={styles.dropdown}>
                          <IonIcon
                            color="gold"
                            style={{ height: 20 }}
                            icon={dollar}
                          />
                          <MaskedIonInput
                            value={formik.values.closingAmt}
                            // onIonChange={formik.handleChange}
                            // name="closingAmt"
                            onChange={(e) => {
                              formik.setFieldValue("closingAmt", e);
                            }}
                          ></MaskedIonInput>
                        </IonItem>
                      </IonRow>
                    </IonCol>
                  </IonRow>
                  <IonItem lines="full" className="notes-line-nn notes-line-desk"></IonItem>
                </React.Fragment>
              ) : (
                <IonItem onClick={toggleFinancial} style={{ marginRight: 32 }} className="new-note-ion-item">
                  <IonIcon src={add} style={styles.addIcon}></IonIcon>
                  <IonText style={styles.acordHeader}>
                    Add Financial Information
                  </IonText>
                </IonItem>
              )}
            </IonCol>
          </IonRow>
          <IonRow class="margin-set">
            <IonCol>
              {openLiquidity ? (
                <React.Fragment>
                  <IonItem
                    onClick={toggleLiquidity}
                    style={{ marginRight: 32 }} className="new-note-ion-item"
                    lines="none"
                  >
                    <IonIcon src={close} style={styles.addIcon}></IonIcon>
                    <IonText style={styles.acordHeader}>
                      Close Liquidity Event
                    </IonText>
                  </IonItem>
                  <IonRow style={styles.headerRow} className="headerRow-desk">
                    <IonLabel class="fs-17" color="white" style={styles.fieldLabel}>
                      Liquidity Events
                    </IonLabel>
                  </IonRow>
                  <IonRow style={styles.textAreaRow} className="txtAreaRowDesk">
                    <IonTextarea
                      style={styles.textArea}
                      placeholder="Inheritance in the spring of 2020"
                      name="liqEvent"
                      rows={6}
                      value={formik.values.liqEvent}
                      onIonChange={formik.handleChange}
                      spellcheck={true}
                      autocapitalize="sentences"
                    ></IonTextarea>
                  </IonRow>
                  <IonItem lines="full" className="notes-line-nn notes-line-desk"></IonItem>
                </React.Fragment>
              ) : (
                <IonItem onClick={toggleLiquidity} style={{ marginRight: 32 }} className="new-note-ion-item">
                  <IonIcon src={add} style={styles.addIcon}></IonIcon>
                  <IonText style={styles.acordHeader}>
                    Add Liquidity Event
                  </IonText>
                </IonItem>
              )}
            </IonCol>
          </IonRow>
          <IonRow class="margin-set">
            <IonCol>
              {openNextStep ? (
                <React.Fragment>
                  <IonItem onClick={toggleNextStep} style={{ marginRight: 32 }} className="new-note-ion-item">
                    <IonIcon src={close} style={styles.addIcon}></IonIcon>
                    <IonText style={styles.acordHeader}>Next Steps</IonText>
                  </IonItem>
                  <IonRow style={styles.headerRow} className="headerRow-desk">
                    <IonCol size-xs="12" size-sm="6" style={{ padding: 0 }}>
                      <IonRow style={{ marginBottom: 6 }}>
                        <IonLabel style={styles.fieldLabel}>
                          * Task Title
                        </IonLabel>
                        {formik.errors.taskTitle ? (
                          <IonText style={{ color: "red", marginLeft: 10 }}>
                            {formik.errors.taskTitle}
                          </IonText>
                        ) : null}
                      </IonRow>
                      <IonRow>
                        <IonInput
                          className = "m-margin-rght16"
                          style={styles.textbox}
                          placeholder="Enter the title of your task"
                          name="taskTitle"
                          mode="md"
                          value={formik.values.taskTitle}
                          onIonChange={formik.handleChange}
                          autocapitalize="sentences"
                          spellcheck={true}
                        ></IonInput>
                      </IonRow>
                      <IonRow style={{ marginBottom: 6 }}>
                        <IonLabel style={styles.fieldLabel}>
                          * Due Date
                        </IonLabel>
                      </IonRow>
                      <IonRow>
                        <IonCol
                          sizeSm="12"
                          sizeLg="8"
                          style={{ paddingLeft: 0 }}
                        >
                          <IonItem className = "m-margin-rght16" lines="none" style={styles.dateItem}>
                            <CustDatePicker
                              dateFormat="LL"
                              date={formik.values.dueDate}
                              onSetDateVal={(val) => {
                                setDueDateVal(val);
                                formik.setFieldValue(
                                  "dueDate",
                                  moment(val).format("YYYY-MM-DD")
                                );
                              }}
                            />
                            <IonIcon
                              style={{ width: 24, height: 24 }}
                              src={calendargold}
                              slot="end"
                            ></IonIcon>
                          </IonItem>
                        </IonCol>
                        <IonCol
                          sizeSm="6"
                          sizeLg="4"
                          className="nxttimepickerCol"
                        >
                          <IonItem  className = "m-margin-rght16 m-margin-leftn5" lines="none" style={styles.dateItem}>
                            <IonSelect
                              interface={
                                width < 576 ? "action-sheet" : "popover"
                              }
                              value={formik.values.dueTime}
                              onIonChange={formik.handleChange}
                              name="dueTime"
                              interfaceOptions={optionsA}
                              mode="ios"
                            >
                              {timePickerOptions.map(( val , i) => (
                                <IonSelectOption key={i} value={val.fVal}>
                                  {val.val}
                                </IonSelectOption>
                              ))}
                            </IonSelect>
                            <IonIcon
                              slot="end"
                              color="gold"
                              src={chevronDown}
                            ></IonIcon>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                    </IonCol>
                    <IonCol size-xs="12" size-sm="1"></IonCol>
                    <IonCol size-xs="6" size-sm="2">
                      <IonRow>
                        <IonLabel style={styles.fieldLabel}>Type</IonLabel>
                      </IonRow>
                      <IonRadioGroup
                        value={formik.values.taskType}
                        onIonChange={formik.handleChange}
                        name="taskType"
                      >
                        {type.map(({ val, name }, i) => (
                          <IonItem
                            lines="none"
                            key={i}
                            style={styles.optionItem}
                          >
                            <IonLabel style={styles.optionLabel}>
                              {name}
                            </IonLabel>
                            <IonRadio
                              style={styles.radioIcon}
                              slot="start"
                              mode="md"
                              value={val}
                            />
                          </IonItem>
                        ))}
                      </IonRadioGroup>
                    </IonCol>
                    <IonCol size-xs="6" size-sm="3">
                      <IonRow>
                        <IonLabel style={styles.fieldLabel}>Priority</IonLabel>
                      </IonRow>
                      <IonRadioGroup
                        value={formik.values.priority}
                        onIonChange={formik.handleChange}
                        name="priority"
                      >
                        {priority.map(({ val, name }, i) => (
                          <IonItem
                            lines="none"
                            key={i}
                            style={styles.optionItem}
                          >
                            <IonLabel style={styles.optionLabel}>
                              {name}
                            </IonLabel>
                            <IonRadio
                              style={styles.radioIcon}
                              slot="start"
                              mode="md"
                              value={val}
                            />
                          </IonItem>
                        ))}
                      </IonRadioGroup>
                    </IonCol>
                  </IonRow>
                  <IonRow style={styles.headerRow} className="headerRow-desk">
                    <IonLabel style={styles.fieldLabel}>Task Notes</IonLabel>
                  </IonRow>
                  <IonRow style={styles.textAreaRow} className="txtAreaRowDesk m-margin-rght16">
                    <IonTextarea
                      
                      style={styles.textArea}
                      placeholder="Enter any notes you have concerning this task"
                      value={formik.values.taskNotes}
                      onIonChange={formik.handleChange}
                      rows={6}
                      name="taskNotes"
                      spellcheck={true}
                      autocapitalize="sentences"
                    ></IonTextarea>
                  </IonRow>
                  <IonRow style={styles.headerRow} className="headerRow-desk">
                    <IonLabel style={styles.fieldLabel}>
                      Email Reminder
                    </IonLabel>
                  </IonRow>
                  <IonRow className="new-note-ion-item" style={{ marginTop: 8, marginLeft: 32 }}>
                    <IonCol size-xs="12" sizeSm="6" sizeLg="4">
                      {/* <IonItem lines="none" style={styles.dateItem}>
                        <IonSelect
                          value={formik.values.emailReminder}
                          onIonChange={formik.handleChange}
                          name="emailReminder"
                          interface={width < 576 ? "action-sheet" : "popover"}
                          interfaceOptions={optionsA}
                          mode="ios"
                        >
                          {emailReminderOption().map(({ val, dt }, i) => (
                            <IonSelectOption key={i} value={dt}>{val}</IonSelectOption>
                          ))}
                        </IonSelect>
                        <IonIcon
                          slot="end"
                          color="gold"
                          src={chevronDown}
                        ></IonIcon>
                      </IonItem> */}
                      <IonItem
                        className="input-items m-margin-rght16"
                        lines="none"
                        style={styles.dateItem}
                      >
                        <CustDatePicker
                          // dateFormat="MMM DD, YYYY"
                          dateFormat={
                            moment(formik.values.emailReminder)
                              .startOf("day")
                              .format("x") ===
                            moment(formik.values.dueDate)
                              .startOf("day")
                              .format("x")
                              ? `[On Due Date]`
                              : moment(formik.values.emailReminder)
                                  .startOf("day")
                                  .format("x") >
                                moment(formik.values.dueDate)
                                  .add(1, "days")
                                  .startOf("day")
                                  .format("x")
                              ? `[${moment(formik.values.emailReminder).from(
                                  moment(formik.values.dueDate).startOf("day"),
                                  true
                                )} after (]dddd[)]`
                              : `[${moment(formik.values.emailReminder).from(
                                  moment(formik.values.dueDate).startOf("day"),
                                  true
                                )} before (]dddd[)]`
                          }
                          date={formik.values.emailReminder}
                          InputClassName="meetingdatentime tfo-calendar-picker-input tfo-input"
                          onSetDateVal={(val) => {
                            formik.setFieldValue(
                              "emailReminder",
                              moment(val).startOf("day")
                            );
                            formik.setFieldTouched("emailReminder", true);
                          }}
                        />
                        <IonIcon
                          src={calendargold}
                          className="endchevron"
                          style={{ width: 24, height: 24 }}
                        ></IonIcon>
                      </IonItem>
                    </IonCol>
                    <IonCol size-xs="12" sizeSm="4" sizeLg="2">
                      <IonItem className="m-margin-rght16" lines="none" style={styles.dateItem}>
                        <IonSelect
                          interface={width < 576 ? "action-sheet" : "popover"}
                          value={formik.values.reminderTime}
                          onIonChange={formik.handleChange}
                          name="reminderTime"
                          interfaceOptions={optionsA}
                          mode="ios"
                        >
                          {timePickerOptions.map(( val , i) => (
                            <IonSelectOption key={i} value={val.fVal}>
                              {val.val}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                        <IonIcon
                          slot="end"
                          color="gold"
                          src={chevronDown}
                        ></IonIcon>
                      </IonItem>
                    </IonCol>
                    <IonCol size="6"></IonCol>
                  </IonRow>
                </React.Fragment>
              ) : (
                <IonItem onClick={toggleNextStep} style={{ marginRight: 32 }} className="new-note-ion-item">
                  <IonIcon src={add} style={styles.addIcon}></IonIcon>
                  <IonText style={styles.acordHeader}>Add Next Step</IonText>
                  {formik.errors.taskTitle ? (
                    <IonText style={{ color: "red", marginLeft: 10 }}>
                      {formik.errors.taskTitle}
                    </IonText>
                  ) : null}
                </IonItem>
              )}
            </IonCol>
          </IonRow>
          <IonRow class="margin-set">
            <IonCol>
              <IonItem lines="none"></IonItem>
            </IonCol>
          </IonRow>
          <IonRow class="margin-set">
            <IonCol size="3" className="mobile-btn">
              <IonButton
                type="submit"
                class="ion-float-left customformbtn"
                style={{
                  fontSize: 12,
                  borderColor: "#B99855",
                  marginLeft: 32,
                }}
                color="#ffffff"
              >
                <IonText className="ion-text-capitalize" color="gold" style={{whiteSpace:"nowrap", margin:"auto"}}>
                  Save Meeting Notes
                </IonText>
              </IonButton>
            </IonCol>
            <IonCol size="3"></IonCol>
            <IonCol size="3"></IonCol>
            <IonCol size="3"></IonCol>
          </IonRow>
        </form>
      </IonContent>
    </IonPage>
  );
};

const MaskedIonInput = ({ value, onChange }) => {
  const maskRef = useRef<IMask.InputMask<any> | null>(null);

  const inputCallback = useCallback(async (input) => {
    if (!input) {
      return;
    }
    const nativeInput = await input.getInputElement();
    const mask = IMask(nativeInput, {
      mask: Number,
      thousandsSeparator: ",",
    })
      .on("accept", (e: any) => {
        onChange(mask.value);
      })
      .on("complete", (e: any) => {
        onChange(mask.value);
      });

    maskRef.current = mask;
  }, []);

  return (
    <IonInput
      style={{ "--padding-start": "8px" }}
      value={value}
      ref={inputCallback}
    />
  );
};

export default NewNote;
