import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonLoading,
} from "@ionic/react";
import { chevronBack } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import api from "src/api";
import PdfViewer from "src/components/PdfViewer/PdfViewer";
import { fireBaseLog } from "src/components/Material/FireBase";
const styles = {
  toolbar: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
  },
};
export default function DigitalProductDetails(match) {
  const [digitalUpdateData, setDigitalUpdateData] = useState<any>([]);
  const [backButtonText, setBackButtonText] = useState<string>("Material");
  const [isLoading, setLoading] = useState(true);
  const getId = match.match.params.id;
  useEffect(() => {
    setLoading(true);
    const getDigitalUpdates = () => {
      return api
        .getDigitaltDetails(parseInt(getId))
        .then((response) => {
          if (response.story) {
            const data = response.story;
            setDigitalUpdateData(data);
            setLoading(false);
            fireBaseLog({
              title: "Digital Product Updates",
              time_spent: data.name,
            });
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    };
    getDigitalUpdates();
  }, []);
  return (
    <IonPage>
      <IonLoading
        cssClass="my-custom-loading-class"
        isOpen={isLoading}
        onDidDismiss={() => setLoading(false)}
        message={"Please wait..."}
      />
      <IonHeader>
        <IonToolbar mode="md" style={styles.toolbar}>
          <IonButtons slot="start">
            <IonButton
              style={{ textTransform: "none" }}
              color="white"
              routerLink="/material"
            >
              <IonIcon icon={chevronBack}></IonIcon>
              {backButtonText}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      {isLoading == false && (
        <PdfViewer url={`${digitalUpdateData.content.Document.filename}`} />
      )}
    </IonPage>
  );
}
