import React from 'react'
import { 
    IonButton,
    IonChip,
    IonRow
} from "@ionic/react";

const styles = {
    row: {
        display: "flex",
        height: 40,
        paddingLeft: 8,
        paddingRight: 8,
        marginBottom: 24,
        marginLeft: 27,
        marginTop: 24
    },
    chip: {
        backgroundColor: "#A5D2DC",
        color: "#222222",
        fontWeight: "400",
        fontSize: 12,
        marginTop: "auto",
        marginBottom: "auto"
    },
    clearbtn: {
        marginTop: "auto",
        marginBottom: "auto"
    }
}

interface ChipProps {
    chipArr: Array<string>;
    clrFilters: Function;
}

const FilterChips: React.FC<ChipProps> = ({
    chipArr,
    clrFilters
}) => {
    return(
        chipArr.length > 0 ? 
        <IonRow style={styles.row} className="todo-filchips">
            {
                chipArr.map(chip => 
                    <IonChip key={chip} style={styles.chip}>{chip}</IonChip>
                )
            }
            <IonButton
                fill="clear"
                mode="ios"
                color="gold"
                size="small"
                style={styles.clearbtn}
                onClick={() => {clrFilters()}}
            >
                Clear All Filters
            </IonButton>
        </IonRow> :
        <IonRow style={styles.row} className="todo-filchips"/>
    )
}

export default FilterChips