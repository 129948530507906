import React, { useEffect, useState } from "react";
import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
  IonSlide,
  IonSlides,
  IonText,
} from "@ionic/react";
import { wallet } from "ionicons/icons";
import royal from "src/icons/pros-people.svg";
import api from "src/api";
import "./TopTargets.css";
import moment from "moment";
import smallstar from "src/icons/smallstar.svg";
import emailicon from "src/icons/email.svg";
import phonecall from "src/icons/phonecall.svg";
import Whatsup from "src/icons/Whatsup.svg";
import useWindowDimensions from "src/components/useWindowDimensions";
import chevronBackCircle from "src/icons/sliderprev.svg"
import chevronForwardCircle from "src/icons/slidernext.svg"
import { Plugins } from "@capacitor/core";
const { Device } = Plugins
const imgbaseuri = "https://cdn2.hubspot.net/";


const styles = {
  selectItem: {
    "--padding-start": "0px",
    backgroundColor: "#222222",
  },
  select: {
    "--padding-start": "8px",
    color: "#B99855",
    textAlign: "right",
  },
  phEmail:{
    "--padding-start": "0px",
    "margin-top":"-8px",
    "margin-bottom":"-8px"
  },
  prosstats: {
    borderRadius: 25,
    background: "#263134",
    "--padding-start": "11px",
    "--inner-padding-end": "13px",
    "--inner-padding-start": "2px",
  },
  propsmeet: {
    borderRadius: 25,
    background: "#4B3933",
    "--padding-start": "11px",
    "--inner-padding-end": "13px",
    "--inner-padding-start": "2px",
  },
  schedulecard: {
    marginLeft: 0,
    marginTop: 16,
    backgroundColor: "#1A1A1A",
  },
  card: {
    "--background": "#1A1A1A",
    marginInlineStart: 0,
    marginInlineEnd: 0,
    margin: 0,
  },
  upcomingSchdulecard: {
    background: "#4B3933",
    marginInlineStart: 0,
    marginInlineEnd: 0,
    margin: 0,
  },
  next5todocard: {
    background: "#1A1A1A",
    marginInlineStart: 0,
    marginInlineEnd: 0,
    marginLeft: 16,
  },
  cardHeader: {},
  grid: {
    marginLeft: 24,
    marginRight: 24,
    marginTop: 32,
    padding: 0,
  },
  dashgrid: {
    marginLeft: 32,
    marginRight: 32,
  },
  item: {
    /* "--background": "#222222",
        marginBottom: 2,
        borderRadius: 8, */
    "--padding-start": "0px",
    height: 50,
    display: "flex",
  },
  desc: {
    fontFamily: "Almarai",
    fontWeight: 300,
    fontSize: 16,
    color: "#828282",
    marginBottom: 8,
  },
  label: {
    marginTop: 14.5,
    marginBottom: 14.5,
    marginLeft: 16,
  },
  title: {
    fontWeight: 400,
    fontSize: 18,
  },
  subText: {
    "--padding-start": "0px",
  },
  subText_a: {
    "--padding-start": "0px",
    marginTop: -8,
  },
  nopad: {
    padding: 0,
  },
  slide: {
    marginTop: 16,
    marginRight: -32,
    marginLeft: -32,
  },
};

// let expected_closing_amount = ""

const decimalFunc = (Num) => {
  let temp = Num.toString().split(".");
  let retVal = 0;
  if (temp[1]) {
    retVal = Num.toFixed(2);
  } else {
    retVal = Num;
  }
  return retVal;
};
const retExpClsAmt = (clsAmt) => {
  let closingamt_disp: number = 0;
  Number.isNaN(clsAmt) || clsAmt === null || clsAmt === ""
    ? (closingamt_disp = 0)
    : (closingamt_disp = parseInt(clsAmt));
  return Math.abs(Number(closingamt_disp)) >= 1.0e9
    ? decimalFunc(Math.abs(Number(closingamt_disp)) / 1.0e9) + "b"
    : // Six Zeroes for Millions
    Math.abs(Number(closingamt_disp)) >= 1.0e6
    ? decimalFunc(Math.abs(Number(closingamt_disp)) / 1.0e6) + "m"
    : // Three Zeroes for Thousands
    Math.abs(Number(closingamt_disp)) >= 1.0e3
    ? decimalFunc(Math.abs(Number(closingamt_disp)) / 1.0e3) + "k"
    : decimalFunc(Math.abs(Number(closingamt_disp)));
};

interface ttcard {
  engName: string;
  arabicname: string;
  expamnt: string | number;
  expclspct: string;
  avatarpath: string;
  persona: string;
  lstcontacted: string;
  contId: string;
  rank: string;
  email:string,
  phone:string,
  mobilephone:string,
}

const TopTargets = () => {
  const [Tcontacts, setTcontacts] = useState<Array<ttcard>>([]);
  const [deviceInfo, setdeviceInfo] = useState<any>();
  const [actSlide, setactSlide] = useState<any>({
    len: 0,
    ind: 0
  });

  const slideRef = React.createRef<HTMLIonSlidesElement>();

  const { height, width } = useWindowDimensions();
  let slidewidth = 400;
  if (width < 576) slidewidth = 380;

  const slideOpts = deviceInfo && deviceInfo.platform === "web" && width > 1024 ? {
    slidesPerView: 3,
    // cssMode:true,
    spaceBetween: 10,
    simulateTouch: false,
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev',
    navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  }
}:{
    initialSlide: 0.5,
    speed: 400,
    // slidesPerView: 2,
    // slidesPerGroup: 3,
    // centeredSlides: true,
    // centeredSlidesBounds: true,
    width: slidewidth,
    freeMode: true,
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    },
    spaceBetween: 16,
  };

  useEffect(() => {

    Device.getInfo().then(info => {
      setdeviceInfo(info)
    })

    api.getTTfromStore().then((targetsArr: Array<any>) => {
      if (targetsArr && targetsArr !== null && targetsArr.length > 0) {
        let payload: any = {
          inputs: [],
          properties: [
            "firstname,lastname,middle_name,first_name_arabic,arabic_last_name,arabic_middle_name,hs_avatar_filemanager_key,notes_last_contacted,amount_status,expected_closing_probability,segment,email,phone,mobilephone",
          ],
        };
        targetsArr.forEach((target) => {
          payload.inputs.push({ id: target.contactid });
        });

        api.getTopTargetContacts(payload).then((response) => {
          // console.log(response)
          if (response && response.results && response.results.length > 0) {
            setactSlide({
              len: response.results.length,
              ind: 0
            })
            let results: Array<any> = response.results;
            results = results.map((obj) => {
              return {
                ...obj,
                rank: targetsArr.filter(
                  (a) => a.contactid === parseInt(obj.id)
                )[0].rank,
              };
            });

            setTcontacts(
              results
                .sort((a, b) => (a.rank > b.rank ? 1 : -1))
                .map((contact) => {
                  const {
                    firstname,
                    lastname,
                    middle_name,
                    first_name_arabic,
                    arabic_last_name,
                    arabic_middle_name,
                    hs_avatar_filemanager_key,
                    notes_last_contacted,
                    amount_status,
                    expected_closing_probability,
                    segment,
                    email,
                    phone,
                    mobilephone,
                  } = contact.properties;
                  return {
                    engName:
                      middle_name !== null && middle_name !== ""
                        ? `${firstname} ${lastname}`
                        : `${firstname} ${middle_name} ${lastname}`,
                    arabicname:
                      arabic_middle_name !== null && arabic_middle_name !== ""
                        ? `${first_name_arabic} ${arabic_last_name}`
                        : `${first_name_arabic} ${arabic_middle_name} ${arabic_last_name}`,
                    expamnt: retExpClsAmt(amount_status),
                    expclspct: expected_closing_probability,
                    persona: segment,
                    avatarpath: hs_avatar_filemanager_key,
                    lstcontacted: notes_last_contacted,
                    contId: contact.id,
                    rank: contact.rank,
                    email:email,
                    phone:phone,
                    mobilephone:mobilephone
                  };
                })
            );
          }
        });
      }
    });
  }, []);

  return Tcontacts.length > 0 ? (
    <div className="temp-cont">
      <IonSlides ref={slideRef} options={slideOpts} style={styles.slide} class="slides-mobile" className="slider-tt"
        onIonSlideDidChange={(e) => {
          slideRef.current?.getActiveIndex().then((ind) => {
            slideRef.current?.length().then(len => {
              setactSlide({
                len,
                ind
              })
            })
          })
        }}
      >
        {Tcontacts.map((tcontact, i) => (
          <IonSlide key={i}>
            <TargetCard tcontact={tcontact} />
          </IonSlide>
        ))}
        
      {/* <IonIcon name="chevron-forward-circle-sharp"></IonIcon> */}
      </IonSlides>
      {
          deviceInfo && deviceInfo.platform === "web" && width > 1024 ? 
          <div className="next-prev-cont">
          {
            actSlide.len > 0 && actSlide.ind === 0 ? null :
            <IonButton
            className="swiper-button-prev-cust"
            fill="clear"
            onClick={() => {
              slideRef.current?.slidePrev()
            }}
            >
              <IonIcon 
                icon={chevronBackCircle}
                color="gold"
                />
            </IonButton>
          }
          {
          actSlide.len > 0 && actSlide.ind + 3 >= actSlide.len ? null :
          <IonButton
            className="swiper-button-next-cust"
            fill="clear"
            onClick={() => {
              slideRef.current?.slideNext()
            }}
            >
            <IonIcon
              color="gold"
              icon={chevronForwardCircle}
              />
          </IonButton>
          }
        </div>
        : null
      }
    </div>
  ) : (
    <IonRow>
      <IonLabel color="greyscale">No data found</IonLabel>
    </IonRow>
  );
};

interface TCard {
  tcontact: ttcard;
}

const TargetCard: React.FC<TCard> = ({ tcontact }) => {
  return (
    <IonCard style={styles.card} className="topTargCard">
      <IonCardHeader style={styles.cardHeader}>
        <IonItem style={styles.item} lines="none">
          <IonAvatar style={{ width: 48, height: 48 }}>
            <img
              src={
                tcontact.avatarpath !== "" && tcontact.avatarpath !== null
                  ? `${imgbaseuri}${tcontact.avatarpath}`
                  : "/assets/empty-avatar.svg"
              }
              alt=""
            />
            <IonIcon
              class="toptargetstardashboard"
              color="greyscale"
              icon={smallstar}
            />
            <IonText class="toptargetsmallrank_a">{tcontact.rank}</IonText>
          </IonAvatar>
          <IonLabel style={styles.label}>
            <IonRow style={{ marginBottom: 4 }}>
              <IonText style={styles.title}>{tcontact.engName}</IonText>
            </IonRow>
            <IonRow>
              <IonText color="greyscale" style={styles.desc}>
                {tcontact.arabicname}
              </IonText>
            </IonRow>
          </IonLabel>
        </IonItem>
        <IonRow>
          <IonCol size-xs="6" size-sm="5" style={styles.nopad}>
            <IonItem lines="none" style={styles.subText}>
              <IonIcon
                color="gold"
                icon={wallet}
                style={{ width: 24, height: 24, marginRight: 8 }}
              ></IonIcon>
              <IonLabel color="greyscale">
                ${tcontact.expamnt}/{tcontact.expclspct}
              </IonLabel>
            </IonItem>
          </IonCol>
          <IonCol size-xs="6" size-sm="5" style={styles.nopad}>
            <IonItem lines="none" style={styles.subText}>
              <IonIcon
                icon={royal}
                style={{ width: 24, height: 24, marginRight: 8 }}
              ></IonIcon>
              <IonLabel color="greyscale">{tcontact.persona}</IonLabel>
            </IonItem>
          </IonCol>
        </IonRow>
{/* 
        <IonItem lines="none" style={styles.phEmail}>
            <IonIcon
                      icon={emailicon}
                      style={{ width: 24, height: 24, marginRight: 8 }}
            ></IonIcon>
              {tcontact.email && tcontact.email !== null ? (
                <IonText color="greyscale">
                  {tcontact.email}
                </IonText>
              ) : null}
            </IonItem> */}

            <IonItem lines="none" style={styles.phEmail}>
              <IonButton
                      mode="ios"
                      className="call-buttons"
                      fill="clear"
                    >
                      {tcontact.email && tcontact.email !== null ?<a className="profhref" href={"mailto:" + tcontact.email}>
                      <IonIcon
                      icon={emailicon}
                      style={{ width: 24, height: 24, marginRight: 8 }}
                      ></IonIcon>
                      </a>:
                      <IonIcon
                      icon={emailicon}
                      style={{ width: 24, height: 24, marginRight: 8 }}
                      ></IonIcon>
                      }                 
                    </IonButton>
              {tcontact.email && tcontact.email !== null ? (
                <IonText color="greyscale">
                  {tcontact.email}
                </IonText>
              ) : null}
            </IonItem>
            {/* <IonRow> */}

            <IonItem lines="none" style={styles.phEmail}>
            <IonButton
            mode="ios"
            className="call-buttons"
            fill="clear"
            >
            {tcontact.phone && tcontact.phone !== null ?<a className="profhref" href={"tel:" + tcontact.phone}>
            <IonIcon
            icon={phonecall}
            style={{ width: 24, height: 24, marginRight: 8 }}
            ></IonIcon>
            </a>:
            <IonIcon
            icon={phonecall}
            style={{ width: 24, height: 24, marginRight: 8 }}
            ></IonIcon>
            }                 
          </IonButton>
            {/* <IonIcon
                      icon={phonecall}
                      style={{ width: 24, height: 24, marginRight: 8 }}
            ></IonIcon> */}
              {tcontact.phone &&tcontact. phone !== null ? (
                <IonText color="greyscale">
                  {tcontact.phone}
                </IonText>
              ) : null}
            </IonItem> 
            <IonItem lines="none" style={styles.phEmail}>
            <IonButton
            mode="ios"
            className="call-buttons"
            fill="clear"
            >
            {tcontact.mobilephone && tcontact.mobilephone !== null ?<a className="profhref" href={"tel:" + tcontact.mobilephone}>
            <IonIcon
            icon={Whatsup}
            style={{ width: 24, height: 24, marginRight: 8 }}
            ></IonIcon>
            </a>:
            <IonIcon
            icon={Whatsup}
            style={{ width: 24, height: 24, marginRight: 8 }}
            ></IonIcon>
            }                 
          </IonButton>
            {/* <IonIcon
                      icon={phonecall}
                      style={{ width: 24, height: 24, marginRight: 8 }}
            ></IonIcon> */}
              {tcontact.mobilephone &&tcontact. mobilephone !== null ? (
                <IonText color="greyscale">
                  {tcontact.mobilephone}
                </IonText>
              ) : null}
            </IonItem> 


            {/* </IonRow> */}
        <IonItem lines="full" style={styles.subText_a}>
          <IonText color="greyscale">
            {tcontact.lstcontacted !== "" && tcontact.lstcontacted !== null
              ? `Last Contact: ${moment(tcontact.lstcontacted).format(
                  "ddd, MMM DD, YYYY"
                )}`
              : ""}
          </IonText>
        </IonItem>
      </IonCardHeader>
      <IonCardContent>
        <IonRow>
          <IonButtons style={{ width: "100%" }}>
            <IonButton
              color="gold"
              size="small"
              fill="clear"
              routerLink={`/contactinfo/${tcontact.contId}`}
              mode="ios"
              class="button-text-mobile"
            >
              View Profile
            </IonButton>
            <IonButton
              color="gold"
              size="small"
              fill="clear"
              style={{ marginLeft: "auto" }}
              routerLink="/prospects/targets"
              mode="ios"
              class="button-text-mobile"
            >
              Remove as Top Target
            </IonButton>
          </IonButtons>
        </IonRow>
      </IonCardContent>
    </IonCard>
  );
};

export default TopTargets;
