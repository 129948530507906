import { IonButton, IonCol, IonGrid, IonIcon, IonRow, IonText, IonFooter } from "@ionic/react";
import { chevronBack, chevronForward } from "ionicons/icons";
import React from "react";

const styles = {
  grid: {
    paddingRight: 27,
    paddingLeft: 27
  }
}

export const StepButton = props => {
  const { step, goback, isEdit, path } = props;

  switch (step) {
    case 1:
      return (
        <IonFooter>
          <IonGrid style={styles.grid} className="grid-desktop">
            <IonRow class="verticalline"></IonRow>
            <IonRow>
              <IonCol size="6"></IonCol>
              {/* <IonCol size="2"></IonCol>
              <IonCol size="2"></IonCol> */}
              <IonCol size="6">
                <IonButton mode="ios" fill="outline" type="submit" class="ion-float-right customformbtn" style={{ fontSize: 12, borderColor: "#B99855" }} color="dark">
                  <IonText class="mobilenext ion-text-capitalize" color="gold">Next Step</IonText>
                  <IonIcon icon={chevronForward} size="small" color="gold" slot="end" />
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>

        </IonFooter>
      );
    case 2:
      return (
        <IonFooter>
          <IonGrid style={styles.grid} className="grid-desktop">
          <IonRow class="verticalline"></IonRow>
            <IonRow>
              <IonCol size="6">
                <IonButton mode="ios" fill="outline" onClick={() => {goback()}} class="ion-float-left customformbtn" style={{borderRadius:0, fontSize: 12, borderColor: "#B99855" }} color="dark">
                  <IonIcon icon={chevronBack} size="small" color="gold" slot="start" />
                  <IonText color="gold">Previous Step</IonText>
                </IonButton>
              </IonCol>
              {/* <IonCol size="2"></IonCol>
              <IonCol size="2"></IonCol> */}
              <IonCol size="6">
                <IonButton mode="ios" fill="outline" type="submit" class="ion-float-right customformbtn" style={{fontSize: 12, borderColor: "#B99855" }} color="dark">
                  <IonText class="mobilenext ion-text-capitalize" mode="ios" color="gold">Next Step</IonText>
                  <IonIcon icon={chevronForward} size="small" color="gold" slot="end" />
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>

        </IonFooter>
      );
    case 3:
      return (
        <IonFooter>
          <IonGrid style={styles.grid} className="grid-desktop">
          <IonRow class="verticalline"></IonRow>
            <IonRow>
              <IonCol size="6">
                <IonButton mode="ios" fill="outline" onClick={() => {goback()}} class="ion-float-left customformbtn" style={{ fontSize: 12, borderColor: "#B99855" }} color="dark">
                  <IonIcon icon={chevronBack} size="small" color="gold" slot="start" />
                  <IonText color="gold">Previous Step</IonText>
                </IonButton>
              </IonCol>
              {/* <IonCol size="2"></IonCol>
              <IonCol size="2"></IonCol> */}
              <IonCol size="6">
                <IonButton mode="ios" fill="outline" type="submit" class="ion-float-right customformbtn" style={{ fontSize: 12, borderColor: "#B99855" }} color="dark">
                  <IonText class="mobilenext ion-text-capitalize" mode="ios" color="gold">Next Step</IonText>
                  <IonIcon icon={chevronForward} size="small" color="gold" slot="end" />
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonFooter>
      );
    case 4:
      return (
        <IonFooter>
          <IonGrid style={styles.grid} className="grid-desktop">
          <IonRow class="verticalline"></IonRow>
            <IonRow>
              <IonCol size="6">
                <IonButton mode="ios" fill="outline" onClick={() => {goback()}} class="ion-float-left customformbtn" style={{ borderRadius:0,fontSize: 12, borderColor: "#B99855" }} color="dark">
                  <IonIcon icon={chevronBack} size="small" color="gold" slot="start" />
                  <IonText color="gold">Previous Step</IonText>
                </IonButton>
              </IonCol>
              {/* <IonCol size="2"></IonCol>
              <IonCol size="2"></IonCol> */}
              <IonCol size="6">
                <IonButton mode="ios" fill="outline" type="submit" class="ion-float-right customformbtn" style={{ fontSize: 12, borderColor: "#B99855" }} color="dark">
                  <IonText class="mobilenext ion-text-capitalize" color="gold">
                  {
                    isEdit && path==="/prospect/edit" ? 'Update Prospect' : isEdit && path==="/client/edit" ? 'Update Client' : 'Create Prospect'
                  }
                  </IonText>
                  {/* <IonIcon icon={chevronForward} size="small" color="gold" slot="end" /> */}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonFooter>
      );
    default:
      return <></>;
  }
};