import React, { useCallback, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import {
  IonContent,
  IonText,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonIcon,
  IonCheckbox,
  IonInput,
  IonRadioGroup,
  IonRadio,
  IonTextarea,
  IonButton,
  IonSelect,
  IonSelectOption,
  IonLoading,
  IonChip,
  IonDatetime
} from "@ionic/react";
import "./EditNote.css";
import CryptoJS from 'crypto-js';
import { chevronDown, add, close } from "ionicons/icons";
import api from "src/api";
import { RouteComponentProps } from "react-router";
import moment from "moment";
import calendargold from "src/icons/calendargold.svg";
import CustDatePicker from "src/components/CustDatePicker/CustDatePicker";
import AttendeeSearch from "src/pages/Calendar/NewEvent/AttendeeSearch";
import IMask from "imask";
import dollar from "src/icons/dollar.svg";
import { Plugins } from "@capacitor/core";
import { useHistory } from "react-router-dom";
import useWindowDimensions from "src/components/useWindowDimensions";
import { meetingNotePayload } from "src/types";
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics';
const { Storage, Device } = Plugins;
// import * as Yup from "yup";

const styles = {
  toolbar: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
  },
  headerRow: {
    marginTop: 24,
    marginLeft: 32,
  },  
  label:{},  
  selectItem: {
    "--padding-start": "0px",
    backgroundColor: "#222222",
    marginTop: 8,
  },
  arbHeaderRow: {
    marginTop: 8,
    marginLeft: 32,
  },
  engName: {
    fontWeight: 300,
    fontSize: 28,
    lineHeight: "120%",
    color: "#828282",
  },
  arbName: {
    fontWeight: 400,
    fontSize: 18,
    lineHeight: "120%",
    color: "#828282",
    fontFamily: "Almarai",
  },
  reqText: {
    fontWeight: 700,
    fontSize: 14,
    color: "#FFFFFF",
  },
  formHeader: {
    fontWeight: 400,
    fontSize: 22,
    lineHeight: "120%",
    color: "#AAAAAA",
  },
  formHeaderSmall: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "120%",
    color: "#AAAAAA",
    marginTop: 10,
    marginLeft: 5,
  },
  fieldLabel: {
    fontWeight: 400,
    fontSize: 17,
    color: "#FFFFFF",
  },
  optionLabel: {
    fontWeight: 400,
    fontSize: 14,
    color: "#C7C7C7",
  },
  optionItem: {
    marginLeft: -16,
    marginBottom: -12,
  },
  optionItemI1: {
    marginLeft: -16,
    height:40,
    // marginBottom: 26,
    // marginTop:5,
  },
  checkIcon: {
    "--background-checked": "#A5D2DC",
    "--checkmark-color": "#222222",
    "--checkmark-width": 3,
    "--border-color-checked": "#A5D2DC",
    height: 20,
    width: 20,
    marginInlineEnd: 8,
  },
  radioIcon: {
    "--color-checked": "#A5D2DC",
    marginInlineEnd: 8,
  },
  select: {
    "--padding-start": "8px",
  },
  dateItem: {
    "--background": "#222222",
    marginBottom: 2,
    borderRadius: 0,
    overflow: "visible",
    "--padding-start": "8px",
  },
  inputItem: {
    "--background": "#222222",
    marginBottom: 2,
    // width: "100%",
    marginTop: 8,
    // marginLeft: 35,
  },
  inputItemI1: {
    "--background": "#222222",
    marginBottom: 2,
    // width: "100%",
    marginTop: "-1px!important",
    // marginLeft: 35,
  },
  textAreaRow: {
    marginTop: 8,
    marginLeft: 32,
    marginRight: 32,
  },
  textArea: {
    "--background": "#222222",
    // height: "100px !important",
    padding: 10,
    // color: "#828282",
    fontWeight: 400,
    fontSize: 14,
  },
  acordHeader: {
    color: "#B99855",
    fontWeight: 400,
    fontSize: 17,
    lineHeight: "120%",
  },
  addIcon: {
    color: "#B99855",
    size: 16,
    marginRight: 5,
  },
  dropdown: {
    marginTop: 5,
    width: "90%",
    "--background": "#222222",
    marginBottom: 2,
    borderRadius: 8,
  },
  textbox: {
    marginTop: 5,
    width: "90%",
    "--background": "#222222",
    marginBottom: 24,
    borderRadius: 0,
    "--padding-start": "15px",
  },
};

interface EditMeetingPageProps
  extends RouteComponentProps<{
    contactId: string;
  }> {}

const optionsA = {
  cssClass: "my-custom-interface",
};
const EditNote: React.FC<any> = ({ contactDetail, note, task, match }) => {
  
  //  console.log(moment(note.meetingDateTime).format("H:mm:ss"))
  //  console.log(moment(task.metadata.reminders[0]).format("H:mm:ss"))
   //console.log(note.attendees.split(','));
   //console.log(note);
   const [hubOwners, setOwners] = useState<any>([]);
    const [ownerIds,setownerIds] = useState<any>([]);
    const [initialOwner,setinitialOwner] = useState<any>([]);
   
   //int[] myIntArray = Arrays.stream(myStringArray).mapToInt(Integer::parseInt).toArray();

  const currDate: any = new Date();
  const [openSentiment, setOpenSentiment] = React.useState(true);
  const [openFinancial, setOpenFinancial] = React.useState(false);
  const [openLiquidity, setOpenLiquidity] = React.useState(false);
  const [openNextStep, setOpenNextStep] = React.useState(false);
  const [lstmeetdate, setlstmeetdate] = React.useState(moment(new Date()));
  const [ro, setRO] = React.useState(true);
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const history = useHistory();

  const toggleSentiment = () => {
    setOpenFinancial(false);
    setOpenLiquidity(false);
    setOpenNextStep(false);
    setOpenSentiment(!openSentiment);
  };
  const toggleFinancial = () => {
    setOpenSentiment(false);
    setOpenLiquidity(false);
    setOpenNextStep(false);
    setOpenFinancial(!openFinancial);
  };
  const toggleLiquidity = () => {
    setOpenSentiment(false);
    setOpenFinancial(false);
    setOpenNextStep(false);
    setOpenLiquidity(!openLiquidity);
  };
  const toggleNextStep = () => {
    setOpenSentiment(false);
    setOpenLiquidity(false);
    setOpenFinancial(false);
    setOpenNextStep(!openNextStep);
  };

  const timePickerOptions: any = [
    {val: "0:00 AM",fVal:"00:00:00"},
    {val: "0:30 AM",fVal:"00:30:00"},
    {val: "1:00 AM",fVal:"01:00:00"},
    {val: "1:30 AM",fVal:"01:30:00"},
    {val: "2:00 AM",fVal:"02:00:00"},
    {val: "2:30 AM",fVal:"02:30:00"},
    {val: "3:00 AM",fVal:"03:00:00"},
    {val: "3:30 AM",fVal:"03:30:00"},
    {val: "4:00 AM",fVal:"04:00:00"},
    {val: "4:30 AM",fVal:"04:30:00"},
    {val: "5:00 AM",fVal:"05:00:00"},
    {val: "5:30 AM",fVal:"05:30:00"},
    {val: "6:00 AM",fVal:"06:00:00"},
    {val: "6:30 AM",fVal:"06:30:00"},
    { val: "7:00 AM",fVal:"07:00:00"},
    { val: "7:30 AM",fVal:"07:30:00"},
    { val: "8:00 AM",fVal:"08:00:00"},
    { val: "8:30 AM",fVal:"08:30:00" },
    { val: "9:00 AM",fVal:"09:00:00" },
    { val: "9:30 AM",fVal:"09:30:00" },
    { val: "10:00 AM",fVal:"10:00:00"},
    { val: "10:30 AM",fVal:"10:30:00"},
    { val: "11:00 AM",fVal:"11:00:00"},
    { val: "11:30 AM" ,fVal:"11:30:00" },
    { val: "12:00 PM",fVal:"12:00:00" },
    { val: "12:30 PM",fVal:"12:30:00" },
    { val: "1:00 PM",fVal:"13:00:00" },
    { val: "1:30 PM",fVal:"13:30:00" },
    { val: "2:00 PM",fVal:"14:00:00" },
    { val: "2:30 PM",fVal:"14:30:00" },
    { val: "3:00 PM",fVal:"15:00:00" },
    { val: "3:30 PM",fVal:"15:30:00" },
    { val: "4:00 PM",fVal:"16:00:00" },
    { val: "4:30 PM",fVal:"16:30:00" },
    { val: "5:00 PM",fVal:"17:00:00" },
    { val: "5:30 PM",fVal:"17:30:00" },
    { val: "6:00 PM",fVal:"18:00:00" },
    { val: "6:30 PM",fVal:"18:30:00" },
    { val: "7:00 PM",fVal:"19:00:00" },
    { val: "7:30 PM",fVal:"19:30:00" },
    { val: "8:00 PM",fVal:"20:00:00" },
    { val: "8:30 PM",fVal:"20:30:00" },
    { val: "9:00 PM",fVal:"21:00:00" },
    { val: "9:30 PM",fVal:"21:30:00" },
    { val: "10:00 PM",fVal:"22:00:00" },
    { val: "10:30 PM",fVal:"22:30:00" },
    { val: "11:00 PM",fVal:"23:00:00" },
    { val: "11:30 PM",fVal:"23:30:00" },
    { val: "12:00 PM",fVal:"24:00:00" },
    ];
  const [closingProbabilityOptions, setclsProb] = useState<any>([]);
  const closingMonthOptions = () => {
    let retObj: any = [];
    for (var i = 0; i < 12; i++) {
      retObj.push({
        val: `${moment(new Date())
          .add(i, "M")
          .format("MMM")} ${moment(new Date())
          .add(i, "M")
          .format("YYYY")}`,
        mom: moment(new Date())
          .add(i, "M")
          .startOf("month")
          .format("YYYY-MM-DD"),
      });
    }
    return retObj;
  };
  const checkboxList = [
    { val: "Principle", name: "principle" },
    { val: "Advisor", name: "advisor" },
    { val: "Family member", name: "familymember" },
    { val: "Other", name: "other" },
  ];
  const meetingTopicList = [
    { val: "1st Meeting" },
    { val: "2nd Meeting" },
    { val: "3rd Meeting" },
    { val: "Meeting with CEO" },
    { val: "Meeting with Portfolio Manager" },
    { val: "Meeting with External Consultant"},
    { val: "Proposal Review" },
    { val: "Onboarding Portfolio Review" },
   { val: "Deal/Program Discussion" },
    { val: "Quarterly Report Review" },
    { val: "Other" },
  ];
  const meetingTypeList = [
    { val: "Pre-qualification" },
    { val: "First outreach" },
    { val: "Demo" },
    { val: "Physical meeting" },
    { val: "Video Call Meeting" },
    { val: "Phone Call Meeting" },
  ];
  const VOC = [{ val: "Low" }, { val: "Medium" }, { val: "High" }];
  const IO = [
    { val: "Growth" },
    { val: "Yielding" },
    { val: "Both Growth and Yielding" },
    { val: "Opportunistic" },
  ];
  const IL = [{ val: "Low" }, { val: "Medium" }, { val: "High" }];
  const OLE = [{ val: "Low" }, { val: "Medium" }, { val: "High" }];
  const OIM = [{ val: "Low" }, { val: "Medium" }, { val: "High" }];
  const OCB = [{ val: "Low" }, { val: "Medium" }, { val: "High" }];
  const AIE = [{ val: "Low" }, { val: "Medium" }, { val: "High" }];
  const type = [
    { name: "Call", val: "CALL" },
    { name: "Email", val: "EMAIL" },
    { name: "To-do", val: "TODO" },
  ];
  const priority = [
    { name: "None", val: "NONE" },
    // { name: "Low", val: "NONE" },
    // { name: "Medium", val: "NONE" },
    { name: "High", val: "HIGH" },
  ];
  const [dueDateVal, setDueDateVal] = useState<any>(currDate);
  

  // const emailReminderOption = () => {
  //   let remOpt: any = [];
  //   for (var i = 1; i < 5; i++) {
  //     remOpt.push({
  //       val:
  //         i === 1
  //           ? `The day of (${moment(dueDateVal).format("dddd")})`
  //           : i === 2
  //           ? `1 day before (${moment(dueDateVal)
  //               .subtract(1, "d")
  //               .format("dddd")})`
  //           : i === 3
  //           ? `1 week before (${moment(dueDateVal)
  //               .subtract(1, "w")
  //               .format("dddd")})`
  //           : `No reminder`,

  //       dt:
  //         i === 1
  //           ? `${moment(dueDateVal).format("YYYY-MM-DD")}`
  //           : i === 2
  //           ? `${moment(dueDateVal)
  //               .subtract(1, "d")
  //               .format("YYYY-MM-DD")}`
  //           : i === 3
  //           ? `${moment(dueDateVal)
  //               .subtract(1, "w")
  //               .format("YYYY-MM-DD")}`
  //           : `No reminder`,
  //     });
  //   }
  //   return remOpt;
  // };

  // console.log(contactDetail)
  // console.log(note)
  // console.log(task)

  const contId = contactDetail ? contactDetail.properties.id : "";
  
  const decrypt = (decryptVlaue) => {
    let passphrase = "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9"
    const bytes = CryptoJS.AES.decrypt(decryptVlaue, passphrase);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  };

  const getUserId = async () => {
    const ret: any = await Storage.get({ key: "userId" });
    const temp = JSON.parse(decrypt(ret.value));
    return temp.id;
  };

  const [togglesearch, settogglesearch] = useState<any>(false);
  const [noteData, setNoteData] = useState<any>();
  const [isOpt, setisOpt] = useState<boolean>(false);
  const [cntactids, setcontactids] = useState<any>([]);
  const [cntactnames, setcntactnames] = useState<any>([]);
  const [cntactemail, setcntactemail] = useState<any>([]);
  const [optcntactids, setoptcontactids] = useState<any>([]);
  const [optcntactnames, setoptcntactnames] = useState<any>([]);
  const [optcntactemail, setoptcntactemail] = useState<any>([]);
  const [stateStartDate,setstateStartDate] = useState<any>(moment().format("ddd, DD MMM YYYY") + " 04:30:00 GMT")  
  const [stateEndDate,setstateEndDate] = useState<any>(moment().add(30,"minute").format("ddd, DD MMM YYYY") + " 05:00:00 GMT");

  const triggerSearch = () => {
    //history.push('/newevent/attendeesearch')
    settogglesearch(true);
    // console.log(formik.values.eventDesc);
  };
  const opttriggerSearch = () => {
    //history.push('/newevent/attendeesearch')
    setisOpt(true);
    settogglesearch(true);
    // console.log(formik.values.eventDesc);
  };

  
  function retrievedData(returnval) {
    
    
    // console.log(returnval);
    settogglesearch(false);
    // setfromdueDate(convertDateTime(returnval[2], returnval[3]));
    // settodueDate(convertDateTime(returnval[2], returnval[4]));
    
    let tempcontid = cntactids;
    let opttempcontid = optcntactids;
    let tempcontnames = cntactnames;
    let opttempcontnames = optcntactnames;
    let tempcontemail = cntactemail;
    let opttempcontemail = optcntactemail;
    if (returnval[6]) {
      isOpt ? opttempcontid.push(returnval[6]) : tempcontid.push(returnval[6]);
    }
    if (returnval[7]) {
      if (returnval[9]) {
        isOpt
          ? opttempcontnames.push(returnval[7] + " " + returnval[9])
          : tempcontnames.push(returnval[7] + " " + returnval[9]);
      } else
        isOpt
          ? opttempcontnames.push(returnval[7])
          : tempcontnames.push(returnval[7]);
    }
    if (returnval[10]) {
      isOpt ? opttempcontemail.push(returnval[10]) : tempcontemail.push(returnval[10]);
    }
    //setcontactids(returnval[6]?tempcontid?tempcontid+','+returnval[6]:'':'');
    //setcontactids(tempcontid?tempcontid+','+returnval[6]:returnval[6]?returnval[6]:'');
    
    setcontactids(tempcontid);
    setoptcontactids(opttempcontid);
    setcntactnames(tempcontnames);
    setoptcntactnames(opttempcontnames);
    setcntactemail(tempcontemail);
    setoptcntactemail(opttempcontemail);
    formik.values.reqdAttendees =
      returnval[5] + returnval[7]
        ? returnval[9]
          ? returnval[7] + " " + returnval[9]
          : returnval[7]
        : "-" + ",";
    if (isOpt) {
      setisOpt(false);
    }
  }

  function deletechip(val) {    
    
    let indexval = cntactids?.indexOf(val?.toString());
    
    // console.log(val.toString())
    let temp = [...cntactids];    
    let temp1 = [...cntactnames];
    temp.splice(indexval, 1);
    temp1.splice(indexval, 1);    
    setcontactids(temp);
    setcntactnames(temp1);
    // cntactids.pop(indexval);
    // cntactnames.pop(indexval);
  }

  function revertBack() {
    settogglesearch(false);
  }

  useEffect(() => {   
    setNoteData(note);  
      
    // note.externalAttendeesId = note?.externalAttendeesId=== undefined || note?.externalAttendeesId!== null || note?.externalAttendeesId==="," ?note.prospectId.replace(/,\s*$/, ""):note?.externalAttendeesId.replace(/,\s*$/, "");
   
    let conName = note && note.externalAttendees ? note.externalAttendees.split(",") : ""
  //   let conId = note && note.externalAttendeesId ? note.externalAttendeesId.split(",") : ""
     
    // if(conName ||conId )
    if(conName)
    {
      
      setcntactnames(conName)
    //  setcontactids(conId)
    }
    api.getOwners().then((resp) => {
      setOwners(resp.results);
      
        let temparrattendees:any = [];
        let temparrattendeesnames:any = [];
        for(const i of note?.attendees.split(','))
        {
          resp?.results.forEach(owner => {
                if(parseInt(i) === parseInt(owner.id)) {
                    temparrattendeesnames.push(`${owner.firstName} ${owner.lastName}`)
                }
            })
        }
      for(let i=0; i<note?.attendees?.split(',').length;i++)
      {
        // if(note.attendees==="NaN" ||note.attendees===""){
        //   temparrattendees=[];
        // }else{
        
       temparrattendees.push(parseInt(note.attendees.split(',')[i]))
      //   }
      }
      setinitialOwner(temparrattendeesnames);  
     setownerIds(temparrattendees);
      formik.setFieldValue("attendeesName",temparrattendees)
    })
    .catch((e) => {
      console.log(JSON.stringify(e))
      setLoading(false);
    })
  },[note])

  const [user, setUser] = React.useState<any>();

  useEffect(() => {
    setLoading(true);
    getUserId().then((userId) => {
      setUser(parseInt(userId));
    });
    
    
    api
      .getContactPropertiesByProperty("expected_closing_probability")
      .then((resp) => {
        if (resp.type === "enumeration") {
          setclsProb(
            resp.options.map((o) => {
              return { val: o.value };
            })
          );
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error from Conacts API: ", error);
      });

    //   api.GetNoteByProspectId(contId).then((data) => {
    //     let notesArr = data
    //       .sort((a, b) => {
    //         return b.updatedDate > a.updatedDate ? 1 : -1;
    //       })
    //       .map((obj) => {
    //         return {
    //           noteTiming: obj.updatedDate,
    //           noteid: obj.rowId,
    //           noteDesc: obj.meetingTopic,
    //           noteAccess: obj.meetingType,
    //         };
    //       });
    //     if (notesArr.length > 0) {
    //       api.GetNotesInsights(notesArr[0].noteid).then((data) => {
    //         formik.setFieldValue(
    //           "coreBusiness",
    //           data.optCorebusiness &&
    //             data.optCorebusiness !== "" &&
    //             data.optCorebusiness !== null
    //             ? data.optCorebusiness
    //             : "Medium"
    //         );
    //         formik.setFieldValue(
    //           "viewOnComp",
    //           data.gvc && data.gvc !== "" && data.gvc !== null ? data.gvc : "High"
    //         );
    //         formik.setFieldValue(
    //           "investObj",
    //           data.invObjective &&
    //             data.invObjective !== "" &&
    //             data.invObjective !== null
    //             ? data.invObjective
    //             : "Yielding"
    //         );
    //         formik.setFieldValue(
    //           "investLiq",
    //           data.invLiquidity &&
    //             data.invLiquidity !== "" &&
    //             data.invLiquidity !== null
    //             ? data.invLiquidity
    //             : "Medium"
    //         );
    //         formik.setFieldValue(
    //           "intlMkt",
    //           data.optIntlEconomy &&
    //             data.optIntlEconomy !== "" &&
    //             data.optIntlEconomy !== null
    //             ? data.optIntlEconomy
    //             : "Medium"
    //         );
    //         formik.setFieldValue(
    //           "intlExpo",
    //           data.apetiteIntlExposure &&
    //             data.apetiteIntlExposure !== "" &&
    //             data.apetiteIntlExposure !== null
    //             ? data.apetiteIntlExposure
    //             : "Medium"
    //         );
    //         formik.setFieldValue(
    //           "localECO",
    //           data.optLocalEconomy &&
    //             data.optLocalEconomy !== "" &&
    //             data.optLocalEconomy !== null
    //             ? data.optLocalEconomy
    //             : "Medium"
    //         );
    //         setlstmeetdate(data.updatedDate);
    //       });
    //     }
    //   });
  }, [contId]);

  const validate = (values) => {
    const errors: any = {};
    // if (!values.taskTitle) {
    //   errors.taskTitle = "(Required)";
    // }
    if (!values.meetingNote) {
      errors.meetingNote = "(Required)";
    }
    if(new Date(stateEndDate) <= new Date(stateStartDate)) {     
      errors.endtime = "End Time should be greater than Start Time";
    }
    return errors;
  };


  function iOS() {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      (navigator.userAgent.includes("Mac"))
    );
  }

  function newdateDisplay(oldtime)
  {
    let newtime =  oldtime;
    return newtime
  }  const getUserEmail = async () => {


    const ret: any = await Storage.get({ key: "userId" })
    const temp = JSON.parse(decrypt(ret.value))
    return temp && temp.email ? temp.email : null
}

// console.log(note.meetingDateTime);
// console.log(note.meetingEndDateTime);
//1614747600000
//"Fri, 19 Feb 2021 04:30:00 GMT"

//console.log(new Date(1614747600000));
  const formik = useFormik({
    validate,
    initialValues: {
      date: note ? moment(note.meetingDateTime).format("YYYY-MM-DD") : "",
     //starttime: note ? moment(note.meetingDateTime).format("HH:mm:ss") : "",
     starttime: note ? new Date(note.meetingDateTime).toUTCString() : "",
     endtime: note ? new Date(note.meetingEndDateTime).toUTCString() : "",
      principle: note
        ? note.clientType.includes("Principle")
          ? true
          : false
        : false,
      advisor: note
        ? note.clientType.includes("Advisor")
          ? true
          : false
        : false,
      familymember: note
        ? note.clientType.includes("Family member")
          ? true
          : false
        : false,
      other: note ? (note.clientType.includes("Other") ? true : false) : false,
      otherTypeValue: note
        ? note.clientType.includes("Other")
          ? note.clientType.substring(note.clientType.lastIndexOf(";") + 1)
          : ""
        : "",
      meetingTopic: note &&
                    note.meetingTopic.includes("1st Meeting") ||  
                    note.meetingTopic.includes("2nd Meeting") || 
                    note.meetingTopic.includes("Proposal Review") ||
                    note.meetingTopic.includes("Onboarding Portfolio Review") ||
                    note.meetingTopic.includes("Deal/Program Discussion") ||
                    note.meetingTopic.includes("Quarterly Report Review") ? note.meetingTopic : "Other",
      otherTopicValue: note &&
                    note.meetingTopic.includes("1st Meeting") ||  
                    note.meetingTopic.includes("2nd Meeting") || 
                    note.meetingTopic.includes("Proposal Review") ||
                    note.meetingTopic.includes("Onboarding Portfolio Review") ||
                    note.meetingTopic.includes("Deal/Program Discussion") ||
                    note.meetingTopic.includes("Quarterly Report Review") ? "" : note.meetingTopic,
      meetingType: note ? note.meetingType : "",
      meetingNote: note && note.notes ? note.notes.replace(/(<([^>]+)>)/ig,"")  : "",
      viewOnComp: note ? note.gvc : "",
      investObj: note ? note.invObjective : "",
      investLiq: note ? note.invLiquidity : "",
      localECO: note ? note.optLocalEconomy : "",
      intlMkt: note ? note.optIntlEconomy : "",
      coreBusiness: note ? note.optCorebusiness : "",
      intlExpo: note ? note.apetiteIntlExposure : "",
      closingProb: contactDetail
        ? contactDetail.properties.expected_closing_probability
        : "",
      closingMonth: contactDetail
        ? contactDetail.properties.expected_month_of_closing
        : "",
      closingAmt: contactDetail ? contactDetail.properties.amount_status : "",
      liqEvent: note ? note.liquidityEvent : "",
      taskTitle: task ? task.metadata.subject : "",
      dueDate: task
        ? moment(task.engagement.timestamp).format("YYYY-MM-DD")
        : "",
      dueTime: task ? moment(task.engagement.timestamp).format("HH:mm:ss") : "",
      taskType: task ? task.metadata.taskType : "",
      priority: task ? task.metadata.priority : "",
      taskNotes: task ? task.metadata.body : "",
      emailReminder:
        task && task.metadata.reminders && task.metadata.reminders.length > 0
          ? moment(task.metadata.reminders[0]).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
      reminderTime:
        task && task.metadata.reminders && task.metadata.reminders.length > 0
          ? moment(task.metadata.reminders[0]).format("HH:mm:ss")
          : "10:00:00",
      //attendeesName:note ? note.attendees : "",
      attendeesName:note ?ownerIds:null,
      hubspotMeetingId:note?note.hubspotMeetingId:"",
      reqdAttendees:note?note.externalAttendees:""
    },
    onSubmit: (values) => {
      //console.log("sumit");
      setLoading(true);
      const convertDateTime = (date, time) => {
        let dateTimeStr = date + "T" + time;
        let dateTimeStr_new = new Date(new Date(dateTimeStr));
        let dateTimeStr_ios = dateTimeStr_new.setMinutes(dateTimeStr_new.getMinutes() + dateTimeStr_new.getTimezoneOffset());
        // console.log(dateTimeStr);
        let DateTimeDt = new Date(dateTimeStr);
        // console.log(DateTimeDt);
        let dateTimeUTC = DateTimeDt.toUTCString();
        //console.log(dateTimeUTC);
        let isounixVal = parseInt(moment(dateTimeUTC).format("x"));
        //console.log(isounixVal);
        if(iOS())
        {
          //return dateTimeStr_ios;
          return isounixVal;
        }
        else
        {
          return isounixVal;
        }
      };
      //function
      const emailTrigger = (cntactnames,values)=>{
        
          let editedDateTime= convertDateTime(
            moment(values.date).format("YYYY-MM-DD"),
           // values.starttime
           moment(values.starttime).format("HH:mm:00")
          )
        let emailList:any=[]
        let tfoattendees:any=[]
        let tfoattendeesID:any=[]
        hubOwners
          .map((obj) => {
            
            ownerIds.map((ownerObj)=>{
              if( obj.id == ownerObj){
                emailList.push(obj.email)
                if(obj.firstName===""){
                  tfoattendees.push(obj.email);
                  tfoattendeesID.push(obj.id)
                }else{
                tfoattendees.push(obj.firstName+" "+obj.lastName)
                tfoattendeesID.push(obj.id)
                }
              }
            })             
          })
          let emailToList= emailList.concat(cntactemail);
         let meetingDetails={
           "contactName":contactDetail.properties.firstname+" "+contactDetail.properties.lastname,
           "TypeOfMeeting":values.meetingType,
           "MeetingDate": moment(values.date).format("YYYY-MM-DD"),
           "MeetingTime": moment(values.starttime).format("HH:mm:00"),
           "TFOGroupattendees":tfoattendees,
           "DetailedMeetingnotes":values.meetingNote,
           "Otherattendees":cntactnames,
    
          }
          let emailMeetingTopic;
          if(values.meetingTopic==="Other"){
            emailMeetingTopic=values.otherTopicValue
          }else{
            emailMeetingTopic=values.meetingTopic;
          }
          
          if(formik.values.meetingNote.length>50 &&(noteData.notes!==values.meetingNote || editedDateTime !==noteData.meetingDateTime || noteData.meetingType!==values.meetingType || noteData.externalAttendees !== cntactnames.toString(",") || noteData.attendees !== tfoattendeesID.toString(",")|| noteData.meetingTopic!==emailMeetingTopic)){
            
            api.SendOTPEmail({
                "subject": "Meetings Notes",
                "from": "RMAPP",
                "tolist":emailList,
                otp: meetingDetails,
              }) .then((emailresp) => {
             })
          }else{
            // console.log("email did not sent")
          }
        
      }
     
      const taskPayload = {
        engagement: {
          active: true,
          type: "TASK",
          ownerId: user,
          timestamp: convertDateTime(
            moment(values.dueDate).format("YYYY-MM-DD"),
            values.dueTime
          ),
          priority: values.priority,
        },
        associations: {
         // contactIds: [parseInt(contactDetail.id)],
          contactIds:  [...cntactids,contactDetail.id].map(Number),
          companyIds: [],
          dealIds: [],
          ownerIds: [],
          ticketIds: [],
        },
        metadata: {
          status: "NOT_STARTED",
          subject: values.taskTitle,
          body: values.taskNotes,
          taskType: values.taskType,
          forObjectType: "CONTACT",
          reminders: [
            convertDateTime(
              moment(values.emailReminder).format("YYYY-MM-DD"),
              values.reminderTime
            ),
          ],
          priority: values.priority,
        },
      };
      
       /*Start of payload*/
       const notemeetingPayload = {
        engagement: {
          ownerId: user,
          type: "MEETING",
          active: true,
          activityType:values.meetingType,
        },
        associations: {
          //contactIds: [parseInt(contactDetail.id)],
          contactIds:  [...cntactids,contactDetail.id].map(Number),
          ownerIds: ownerIds,
        },
        metadata: {
          body: values.meetingNote,
          startTime: convertDateTime(
            moment(values.date).format("YYYY-MM-DD"),
            //values.starttime,
            moment(values.starttime).format("HH:mm:00")
          ),
          endTime: convertDateTime(
            moment(values.date).format("YYYY-MM-DD"),
            //values.endtime
            moment(values.endtime).format("HH:mm:00")
          ),
          title: "",
          // meetingOutcome:"SCHEDULED",
          meetingOutcome:"COMPLETED"
        },
      };
    /*End of payload*/
     
      let clType = "";
      clType = values.principle ? clType + ";" + "Principle" : clType;
      clType = values.advisor ? clType + ";" + "Advisor" : clType;
      clType = values.familymember ? clType + ";" + "Family member" : clType;
      clType = values.other
        ? (clType = clType + ";" + "Other" + ";" + values.otherTypeValue)
        : clType;

        // console.log(note)
      Device.getInfo().then(deviceInfo => {
        // console.log(deviceInfo);
      getUserEmail().then(useremail => {  
        // console.log(useremail);
        // console.log(note.followupTaskId);
        // console.log(taskPayload);
        if(note.followupTaskId && note.followupTaskId != 0)
        {
          
      api.patchTask(note.followupTaskId, taskPayload).then((resp) => {
        // console.log(resp.associations.ownerIds.toString());
       
        let taskId = note.followupTaskId;
      //  let createNotePayload = cntactids?.join(",")+","
        const meetingPayload: meetingNotePayload = {
          createdBy: user,
          updatedBy: user,
          description: "",
          meetingDateTime: convertDateTime(
            moment(values.date).format("YYYY-MM-DD"),
           // values.starttime
           moment(values.starttime).format("HH:mm:00")
          ),
          meetingEndDateTime: convertDateTime(
            moment(values.date).format("YYYY-MM-DD"),
            //values.endtime
            moment(values.endtime).format("HH:mm:00")
          ),
          clientType: clType,
          meetingTopic: values.meetingTopic === "Other" ? values.otherTopicValue : values.meetingTopic,
          meetingType: values.meetingType,
          notes: values.meetingNote,
          gvc: values.viewOnComp,
          invObjective: values.investObj,
          invLiquidity: values.investLiq,
          optLocalEconomy: values.localECO,
          optIntlEconomy: values.intlMkt,
          optCorebusiness: values.coreBusiness,
          apetiteIntlExposure: values.intlExpo,
          liquidityEvent: values.liqEvent,
          followupTaskId: taskId,
          hubspotMeetingId:resp.engagement.id,
          attendees:values.attendeesName.toString(),
          externalAttendees: cntactnames.toString(),
         //  prospectId:note.prospectId,
         // externalAttendeesId:createNotePayload
        };
      
        if(note.hubspotMeetingId){
        api.patchTask(note.hubspotMeetingId, notemeetingPayload).then((resp) => {
          
          api
          .UpdateMeetingNoteById(note.rowId, meetingPayload)
          .then((noteResp) => {

            // console.log(noteResp);
             //api to trigger mail for meetings notes create and update alopama
             emailTrigger(cntactnames,values);
           

            const updContactPayload = {
              properties: {
                expected_closing_probability: values.closingProb,
                amount_status: Number(
                  values.closingAmt.replace(/[^0-9.-]+/g, "")
                ),
                expected_month_of_closing: values.closingMonth,
              },
            };

            if (
              values.closingProb !==
                contactDetail.properties.expected_closing_probability ||
              Number(values.closingAmt.replace(/[^0-9.-]+/g, "")) !==
                Number(contactDetail.properties.amount_status) ||
              values.closingMonth !==
                contactDetail.properties.expected_month_of_closing
            ) {
              //  console.log(updContactPayload)
              api
                .updateProspect(parseInt(contactDetail.id), updContactPayload)
                .then((resp) => {
                  // console.log(resp);
                  setLoading(false);
                  history.push(`/contactinfo/${contactDetail.id}`, {
                    to: "notes",
                  });
                  if(deviceInfo.platform == "web")
                  {
                    //user
                        window.globalThis.firebase.analytics().setUserId(useremail);
                        window.globalThis.firebase.analytics().logEvent('select_content', {
                        user_name: useremail,
                        content_type: 'EditMeetingNote',
                        item_id: contactDetail.id,
                        device_id:deviceInfo?deviceInfo.uuid:"",
                        platform:deviceInfo?deviceInfo.platform:"",
                        device_model:deviceInfo?deviceInfo.model:"",
                        device_type:deviceInfo?deviceInfo.osVersion:"",
                        device_os:deviceInfo?deviceInfo.operatingSystem:"",
                      });
                  }
                  else
                  {
                        FirebaseAnalytics.setUserId(useremail);
                        FirebaseAnalytics.logEvent("select_content", {user_name: useremail});
                        FirebaseAnalytics.logEvent("select_content", {content_type: 'EditMeetingNote'});
                        FirebaseAnalytics.logEvent("select_content", {item_id: contactDetail.id});
                        FirebaseAnalytics.logEvent("select_content", {device_id: deviceInfo?deviceInfo.uuid:""});
                        FirebaseAnalytics.logEvent("select_content", {platform: deviceInfo?deviceInfo.platform:""});
                        FirebaseAnalytics.logEvent("select_content", {device_model: deviceInfo?deviceInfo.model:""});
                        FirebaseAnalytics.logEvent("select_content", {device_type:deviceInfo?deviceInfo.osVersion:""});
                        FirebaseAnalytics.logEvent("select_content", {device_os:deviceInfo?deviceInfo.operatingSystem:""});
                        

                  }
                 
                })
                .catch((error) => {
                  setLoading(false)
                  console.error(error);
                });
            } else {
              setLoading(false);
              history.push(`/contactinfo/${contactDetail.id}`, {
                to: "notes",
              });
              if(deviceInfo.platform == "web")
                  {
                    window.globalThis.firebase.analytics().setUserId(useremail);
                        window.globalThis.firebase.analytics().logEvent('select_content', {
                        user_name: useremail,
                        content_type: 'EditMeetingNote',
                        item_id: contactDetail.id,
                        device_id:deviceInfo?deviceInfo.uuid:"",
                        platform:deviceInfo?deviceInfo.platform:"",
                        device_model:deviceInfo?deviceInfo.model:"",
                        device_type:deviceInfo?deviceInfo.osVersion:"",
                        device_os:deviceInfo?deviceInfo.operatingSystem:"",
                      });
                  }
                  else
                  {
                    FirebaseAnalytics.setUserId(useremail);
                        FirebaseAnalytics.logEvent("select_content", {user_name: useremail});
                        FirebaseAnalytics.logEvent("select_content", {content_type: 'EditMeetingNote'});
                        FirebaseAnalytics.logEvent("select_content", {item_id: contactDetail.id});
                        FirebaseAnalytics.logEvent("select_content", {device_id: deviceInfo?deviceInfo.uuid:""});
                        FirebaseAnalytics.logEvent("select_content", {platform: deviceInfo?deviceInfo.platform:""});
                        FirebaseAnalytics.logEvent("select_content", {device_model: deviceInfo?deviceInfo.model:""});
                        FirebaseAnalytics.logEvent("select_content", {device_type:deviceInfo?deviceInfo.osVersion:""});
                        FirebaseAnalytics.logEvent("select_content", {device_os:deviceInfo?deviceInfo.operatingSystem:""});

                  }
            }
          })
          .catch((error) => {
            setLoading(false)
            console.error(error);
          });
        })
        .catch((error) => {
          setLoading(false)
          console.error(error);
        });

      }
      else
      {
        api
        .UpdateMeetingNoteById(note.rowId, meetingPayload)
        .then((noteResp) => {
          //email trigger Author:alopama
          emailTrigger(cntactnames,values);  

          const updContactPayload = {
            properties: {
              expected_closing_probability: values.closingProb,
              amount_status: Number(
                values.closingAmt.replace(/[^0-9.-]+/g, "")
              ),
              expected_month_of_closing: values.closingMonth,
            },
          };

          if (
            values.closingProb !==
              contactDetail.properties.expected_closing_probability ||
            Number(values.closingAmt.replace(/[^0-9.-]+/g, "")) !==
              Number(contactDetail.properties.amount_status) ||
            values.closingMonth !==
              contactDetail.properties.expected_month_of_closing
          ) {
            //  console.log(updContactPayload)
            api
              .updateProspect(parseInt(contactDetail.id), updContactPayload)
              .then((resp) => {
                // console.log(resp);
                setLoading(false);
                history.push(`/contactinfo/${contactDetail.id}`, {
                  to: "notes",
                });
                if(deviceInfo.platform == "web")
                {
                  //user
                      window.globalThis.firebase.analytics().setUserId(useremail);
                      window.globalThis.firebase.analytics().logEvent('select_content', {
                      user_name: useremail,
                      content_type: 'EditMeetingNote',
                      item_id: contactDetail.id,
                      device_id:deviceInfo?deviceInfo.uuid:"",
                      platform:deviceInfo?deviceInfo.platform:"",
                      device_model:deviceInfo?deviceInfo.model:"",
                      device_type:deviceInfo?deviceInfo.osVersion:"",
                      device_os:deviceInfo?deviceInfo.operatingSystem:"",
                    });
                }
                else
                {
                      FirebaseAnalytics.setUserId(useremail);
                      FirebaseAnalytics.logEvent("select_content", {user_name: useremail});
                      FirebaseAnalytics.logEvent("select_content", {content_type: 'EditMeetingNote'});
                      FirebaseAnalytics.logEvent("select_content", {item_id: contactDetail.id});
                      FirebaseAnalytics.logEvent("select_content", {device_id: deviceInfo?deviceInfo.uuid:""});
                      FirebaseAnalytics.logEvent("select_content", {platform: deviceInfo?deviceInfo.platform:""});
                      FirebaseAnalytics.logEvent("select_content", {device_model: deviceInfo?deviceInfo.model:""});
                      FirebaseAnalytics.logEvent("select_content", {device_type:deviceInfo?deviceInfo.osVersion:""});
                      FirebaseAnalytics.logEvent("select_content", {device_os:deviceInfo?deviceInfo.operatingSystem:""});
                      

                }
               
              })
              .catch((error) => {
                setLoading(false)
                console.error(error);
              });
          } else {
            setLoading(false);
            history.push(`/contactinfo/${contactDetail.id}`, {
              to: "notes",
            });
            if(deviceInfo.platform == "web")
                {
                  window.globalThis.firebase.analytics().setUserId(useremail);
                      window.globalThis.firebase.analytics().logEvent('select_content', {
                      user_name: useremail,
                      content_type: 'EditMeetingNote',
                      item_id: contactDetail.id,
                      device_id:deviceInfo?deviceInfo.uuid:"",
                      platform:deviceInfo?deviceInfo.platform:"",
                      device_model:deviceInfo?deviceInfo.model:"",
                      device_type:deviceInfo?deviceInfo.osVersion:"",
                      device_os:deviceInfo?deviceInfo.operatingSystem:"",
                    });
                }
                else
                {
                  FirebaseAnalytics.setUserId(useremail);
                      FirebaseAnalytics.logEvent("select_content", {user_name: useremail});
                      FirebaseAnalytics.logEvent("select_content", {content_type: 'EditMeetingNote'});
                      FirebaseAnalytics.logEvent("select_content", {item_id: contactDetail.id});
                      FirebaseAnalytics.logEvent("select_content", {device_id: deviceInfo?deviceInfo.uuid:""});
                      FirebaseAnalytics.logEvent("select_content", {platform: deviceInfo?deviceInfo.platform:""});
                      FirebaseAnalytics.logEvent("select_content", {device_model: deviceInfo?deviceInfo.model:""});
                      FirebaseAnalytics.logEvent("select_content", {device_type:deviceInfo?deviceInfo.osVersion:""});
                      FirebaseAnalytics.logEvent("select_content", {device_os:deviceInfo?deviceInfo.operatingSystem:""});

                }
          }
        })
        .catch((error) => {
          setLoading(false)
          console.error(error);
        });

      }        
      })
      .catch((error) => {
        setLoading(false)
        console.error(error);
      });
    }
    else
    {
      //  const contactName= contactDetail.properties.firstname+" "+contactDetail.properties.lastname;
      
      let taskId = note.followupTaskId;
      
      let createNotePayload = cntactids?.join(",")+",";
      
        const meetingPayload: meetingNotePayload = {
          createdBy: user,
          updatedBy: user,
          description: "",
          meetingDateTime: convertDateTime(
            moment(values.date).format("YYYY-MM-DD"),
           // values.starttime
           moment(values.starttime).format("HH:mm:00")
          ),
          meetingEndDateTime: convertDateTime(
            moment(values.date).format("YYYY-MM-DD"),
            //values.endtime
            moment(values.endtime).format("HH:mm:00")
          ),
          clientType: clType,
          meetingTopic: values.meetingTopic === "Other" ? values.otherTopicValue : values.meetingTopic,
          meetingType: values.meetingType,
          notes: values.meetingNote,
          gvc: values.viewOnComp,
          invObjective: values.investObj,
          invLiquidity: values.investLiq,
          optLocalEconomy: values.localECO,
          optIntlEconomy: values.intlMkt,
          optCorebusiness: values.coreBusiness,
          apetiteIntlExposure: values.intlExpo,
          liquidityEvent: values.liqEvent,
          followupTaskId: taskId,
          hubspotMeetingId:parseInt(""),// resp.engagement.id, //- Not sending 
          attendees:values.attendeesName.toString(),
          externalAttendees: cntactnames.toString(),
         //  prospectId:note.prospectId,
          // externalAttendeesId:createNotePayload
        };
     
        if(note.hubspotMeetingId){          
        api.patchTask(note.hubspotMeetingId, notemeetingPayload).then((resp) => {
          
          api
          .UpdateMeetingNoteById(note.rowId, meetingPayload)
          .then((noteResp) => {
             //api to trigger mail for meetings notes create and update alopama
             emailTrigger(cntactnames,values);
            const updContactPayload = {
              properties: {
                expected_closing_probability: values.closingProb,
                amount_status: Number(
                  values.closingAmt.replace(/[^0-9.-]+/g, "")
                ),
                expected_month_of_closing: values.closingMonth,
              },
            };

            if (
              values.closingProb !==
                contactDetail.properties.expected_closing_probability ||
              Number(values.closingAmt.replace(/[^0-9.-]+/g, "")) !==
                Number(contactDetail.properties.amount_status) ||
              values.closingMonth !==
                contactDetail.properties.expected_month_of_closing
            ) {
              //  console.log(updContactPayload)
              api
                .updateProspect(parseInt(contactDetail.id), updContactPayload)
                .then((resp) => {
                  // console.log(resp);
                  setLoading(false);
                  history.push(`/contactinfo/${contactDetail.id}`, {
                    to: "notes",
                  });
                  if(deviceInfo.platform == "web")
                  {
                    //user
                        window.globalThis.firebase.analytics().setUserId(useremail);
                        window.globalThis.firebase.analytics().logEvent('select_content', {
                        user_name: useremail,
                        content_type: 'EditMeetingNote',
                        item_id: contactDetail.id,
                        device_id:deviceInfo?deviceInfo.uuid:"",
                        platform:deviceInfo?deviceInfo.platform:"",
                        device_model:deviceInfo?deviceInfo.model:"",
                        device_type:deviceInfo?deviceInfo.osVersion:"",
                        device_os:deviceInfo?deviceInfo.operatingSystem:"",
                      });
                  }
                  else
                  {
                        FirebaseAnalytics.setUserId(useremail);
                        FirebaseAnalytics.logEvent("select_content", {user_name: useremail});
                        FirebaseAnalytics.logEvent("select_content", {content_type: 'EditMeetingNote'});
                        FirebaseAnalytics.logEvent("select_content", {item_id: contactDetail.id});
                        FirebaseAnalytics.logEvent("select_content", {device_id: deviceInfo?deviceInfo.uuid:""});
                        FirebaseAnalytics.logEvent("select_content", {platform: deviceInfo?deviceInfo.platform:""});
                        FirebaseAnalytics.logEvent("select_content", {device_model: deviceInfo?deviceInfo.model:""});
                        FirebaseAnalytics.logEvent("select_content", {device_type:deviceInfo?deviceInfo.osVersion:""});
                        FirebaseAnalytics.logEvent("select_content", {device_os:deviceInfo?deviceInfo.operatingSystem:""});
                        

                  }
                 
                })
                .catch((error) => {
                  setLoading(false)
                  console.error(error);
                });
            } else {
              setLoading(false);
              history.push(`/contactinfo/${contactDetail.id}`, {
                to: "notes",
              });
              if(deviceInfo.platform == "web")
                  {
                    window.globalThis.firebase.analytics().setUserId(useremail);
                        window.globalThis.firebase.analytics().logEvent('select_content', {
                        user_name: useremail,
                        content_type: 'EditMeetingNote',
                        item_id: contactDetail.id,
                        device_id:deviceInfo?deviceInfo.uuid:"",
                        platform:deviceInfo?deviceInfo.platform:"",
                        device_model:deviceInfo?deviceInfo.model:"",
                        device_type:deviceInfo?deviceInfo.osVersion:"",
                        device_os:deviceInfo?deviceInfo.operatingSystem:"",
                      });
                  }
                  else
                  {
                    FirebaseAnalytics.setUserId(useremail);
                        FirebaseAnalytics.logEvent("select_content", {user_name: useremail});
                        FirebaseAnalytics.logEvent("select_content", {content_type: 'EditMeetingNote'});
                        FirebaseAnalytics.logEvent("select_content", {item_id: contactDetail.id});
                        FirebaseAnalytics.logEvent("select_content", {device_id: deviceInfo?deviceInfo.uuid:""});
                        FirebaseAnalytics.logEvent("select_content", {platform: deviceInfo?deviceInfo.platform:""});
                        FirebaseAnalytics.logEvent("select_content", {device_model: deviceInfo?deviceInfo.model:""});
                        FirebaseAnalytics.logEvent("select_content", {device_type:deviceInfo?deviceInfo.osVersion:""});
                        FirebaseAnalytics.logEvent("select_content", {device_os:deviceInfo?deviceInfo.operatingSystem:""});

                  }
            }
          })
          .catch((error) => {
            setLoading(false)
            console.error(error);
          });
        })
        .catch((error) => {
          setLoading(false)
          console.error(error);
        });

      }
      else
      {  
              
        api
        .UpdateMeetingNoteById(note.rowId, meetingPayload)
        .then((noteResp) => {
          // console.log(noteResp);
          //api to trigger mail for meetings notes create and update alopama
          emailTrigger(cntactnames,values);   

          const updContactPayload = {
            properties: {
              expected_closing_probability: values.closingProb,
              amount_status: Number(
                values.closingAmt.replace(/[^0-9.-]+/g, "")
              ),
              expected_month_of_closing: values.closingMonth,
            },
          };

          if (
            values.closingProb !==
              contactDetail.properties.expected_closing_probability ||
            Number(values.closingAmt.replace(/[^0-9.-]+/g, "")) !==
              Number(contactDetail.properties.amount_status) ||
            values.closingMonth !==
              contactDetail.properties.expected_month_of_closing
          ) {
            //  console.log(updContactPayload)
            api
              .updateProspect(parseInt(contactDetail.id), updContactPayload)
              .then((resp) => {
                // console.log(resp);
                setLoading(false);
                history.push(`/contactinfo/${contactDetail.id}`, {
                  to: "notes",
                });
                if(deviceInfo.platform == "web")
                {
                  //user
                      window.globalThis.firebase.analytics().setUserId(useremail);
                      window.globalThis.firebase.analytics().logEvent('select_content', {
                      user_name: useremail,
                      content_type: 'EditMeetingNote',
                      item_id: contactDetail.id,
                      device_id:deviceInfo?deviceInfo.uuid:"",
                      platform:deviceInfo?deviceInfo.platform:"",
                      device_model:deviceInfo?deviceInfo.model:"",
                      device_type:deviceInfo?deviceInfo.osVersion:"",
                      device_os:deviceInfo?deviceInfo.operatingSystem:"",
                    });
                }
                else
                {
                      FirebaseAnalytics.setUserId(useremail);
                      FirebaseAnalytics.logEvent("select_content", {user_name: useremail});
                      FirebaseAnalytics.logEvent("select_content", {content_type: 'EditMeetingNote'});
                      FirebaseAnalytics.logEvent("select_content", {item_id: contactDetail.id});
                      FirebaseAnalytics.logEvent("select_content", {device_id: deviceInfo?deviceInfo.uuid:""});
                      FirebaseAnalytics.logEvent("select_content", {platform: deviceInfo?deviceInfo.platform:""});
                      FirebaseAnalytics.logEvent("select_content", {device_model: deviceInfo?deviceInfo.model:""});
                      FirebaseAnalytics.logEvent("select_content", {device_type:deviceInfo?deviceInfo.osVersion:""});
                      FirebaseAnalytics.logEvent("select_content", {device_os:deviceInfo?deviceInfo.operatingSystem:""});
                      

                }
               
              })
              .catch((error) => {
                setLoading(false)
                console.error(error);
              });
          } else {
            setLoading(false);
            history.push(`/contactinfo/${contactDetail.id}`, {
              to: "notes",
            });
            if(deviceInfo.platform == "web")
                {
                  window.globalThis.firebase.analytics().setUserId(useremail);
                      window.globalThis.firebase.analytics().logEvent('select_content', {
                      user_name: useremail,
                      content_type: 'EditMeetingNote',
                      item_id: contactDetail.id,
                      device_id:deviceInfo?deviceInfo.uuid:"",
                      platform:deviceInfo?deviceInfo.platform:"",
                      device_model:deviceInfo?deviceInfo.model:"",
                      device_type:deviceInfo?deviceInfo.osVersion:"",
                      device_os:deviceInfo?deviceInfo.operatingSystem:"",
                    });
                }
                else
                {
                  FirebaseAnalytics.setUserId(useremail);
                      FirebaseAnalytics.logEvent("select_content", {user_name: useremail});
                      FirebaseAnalytics.logEvent("select_content", {content_type: 'EditMeetingNote'});
                      FirebaseAnalytics.logEvent("select_content", {item_id: contactDetail.id});
                      FirebaseAnalytics.logEvent("select_content", {device_id: deviceInfo?deviceInfo.uuid:""});
                      FirebaseAnalytics.logEvent("select_content", {platform: deviceInfo?deviceInfo.platform:""});
                      FirebaseAnalytics.logEvent("select_content", {device_model: deviceInfo?deviceInfo.model:""});
                      FirebaseAnalytics.logEvent("select_content", {device_type:deviceInfo?deviceInfo.osVersion:""});
                      FirebaseAnalytics.logEvent("select_content", {device_os:deviceInfo?deviceInfo.operatingSystem:""});

                }
          }
        })
        .catch((error) => {
          setLoading(false)
          console.error(error);
        });

      }
    }
    })
      })
    },
  });
     
  // console.log("form value", formik.values);
  const { height, width } = useWindowDimensions();

  return togglesearch ? (
    <AttendeeSearch
      isOpt={isOpt}
      retrievedData={retrievedData}
      revertBack={revertBack}
       cntactids={cntactids}
      // eventtitle={formik.values.eventTitle}
      // eventDesc={formik.values.eventDesc}
      // dueDate={formik.values.dueDate}
      // dueTimeStart={formik.values.dueTimeStart}
      // dueTimeEnd={formik.values.dueTimeEnd}
      // reqdAttendees={formik.values.reqdAttendees}
    />
  ) : (
    <React.Fragment>
      <IonLoading
        cssClass="my-custom-loading-class"
        isOpen={isLoading}
        message={"Please wait..."}
      />
      {/* <IonHeader>
        <IonToolbar mode="md" style={styles.toolbar}>
          <IonButtons slot="start">
            <IonBackButton
              style={{ textTransform: "none" }}
              color="white"
              text="Prospect Profile"
              icon={chevronBack}
            ></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader> */}
      <IonContent>
        <form onSubmit={formik.handleSubmit}>
          <IonRow class="margin-set mt-6" style={styles.headerRow} className="headerRow-desk">
            <IonCol size-xs="9" size-sm="10">
              <IonText class="fs-28" style={styles.engName}>
                {contactDetail
                  ? contactDetail.properties.title_salutation
                    ? contactDetail.properties.title_salutation +
                      " " +
                      contactDetail.properties.firstname +
                      " " +
                      contactDetail.properties.lastname
                    : contactDetail.properties.firstname +
                      " " +
                      contactDetail.properties.lastname
                  : ""}
              </IonText>
            </IonCol>
            <IonCol size-xs="3" size-sm="2">
              <IonText class="fw-unset fs-14" style={styles.reqText}>* = required</IonText>
            </IonCol>
          </IonRow>
          <IonRow class="margin-set" style={styles.arbHeaderRow}>
            <IonCol>
              <IonText class="fs-18" style={styles.arbName}>
                {contactDetail
                  ? contactDetail.properties.first_name_arabic +
                    " " +
                    contactDetail.properties.arabic_middle_name +
                    " " +
                    contactDetail.properties.arabic_last_name
                  : ""}
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow class="margin-set" style={styles.headerRow} className="headerRow-desk">
            <IonCol>
              <IonText class="fs-22" style={styles.formHeader}>Meeting Information</IonText>
            </IonCol>
          </IonRow>
          <IonRow class="margin-set" style={styles.headerRow} className="headerRow-desk">
            <IonCol>
              <IonText class="fs-17" style={styles.fieldLabel}>*Date & Time</IonText>
            </IonCol>
          </IonRow>
          <IonRow class="margin-set" style={{ marginTop: 6, marginLeft: 32 }}>
            <IonCol size-xs="12" sizeSm="6" sizeLg="4">
              <IonItem lines="none" style={styles.dateItem}>
                <CustDatePicker
                  dateFormat="dddd, MMMM DD, YYYY"
                  date={formik.values.date}
                  InputClassName="meetingdatentime tfo-calendar-picker-input tfo-input"
                  onSetDateVal={(val) => {
                    formik.setFieldValue("date", val);
                  }}
                />
                <IonIcon
                  src={calendargold}
                  slot="end"
                  style={{ width: 24, height: 24 }}
                ></IonIcon>
              </IonItem>
            </IonCol>
            <IonCol size-xs="12" sizeSm="4" sizeLg="2">
              <IonItem lines="none" style={styles.dateItem}>
              <IonDatetime
                  displayFormat="h:mm A"
                  value={formik.values.starttime}
                  mode="ios"
                  onIonChange={event => {      
                    let valValue:any =   event.detail.value 
                    formik.setFieldValue("starttime",new Date(valValue).toUTCString());
                    var d1 = new Date (valValue);
                    var d2 = new Date ( d1 );
                    d2.setMinutes ( d1.getMinutes() + 30 );
                    formik.setFieldValue("endtime",new Date(d2).toUTCString());
                    setstateStartDate(new Date(valValue).toUTCString());
                  } }
                  name="starttime"
                  >
                </IonDatetime>
                {/* <IonSelect
                  interface={width < 576 ? "action-sheet" : "popover"}
                  interfaceOptions={optionsA}
                  mode="ios"
                  value={formik.values.starttime}
                  // onIonChange={formik.handleChange}
                  onIonChange={(val) => {
                    formik.setFieldValue("starttime", val.detail.value);
                    let endVal :  any = null;
                    for(var i in timePickerOptions){
                      if(timePickerOptions[parseInt(i)].fVal === val.detail.value){  
                        endVal = timePickerOptions[parseInt(i)+1].fVal
                      }  
                    }
                    formik.setFieldValue("endtime",endVal);
                  }}
                  name="starttime"
                >
                  
                  {timePickerOptions.map((val , i) => (
                    <IonSelectOption key={i} value={val.fVal}>
                      {val.val}
                    </IonSelectOption>
                  ))}
                </IonSelect> */}
                <IonIcon slot="end" color="gold" src={chevronDown}></IonIcon>
              </IonItem>
            </IonCol>
            <IonCol size-xs="12" sizeSm="4" sizeLg="2">
            <IonItem lines="none" style={styles.dateItem}>
            <IonDatetime
                  displayFormat="h:mm A"  
                  value={formik.values.endtime}
                  mode="ios"
                  name="endtime"
                  onIonChange={event => { 
                    let endValue:any =   event.detail.value;
                    formik.setFieldValue("endtime",new Date(endValue).toUTCString());  
                    setstateEndDate(new Date(endValue).toUTCString());  
                   }}
                 >
            </IonDatetime> 
                {/* <IonSelect
                  interface={width < 576 ? "action-sheet" : "popover"}
                  interfaceOptions={optionsA}
                  mode="ios"
                  value={formik.values.endtime}
                  onIonChange={formik.handleChange}
                  name="endtime"
                >
                  {timePickerOptions.filter((v)=>v.fVal > formik.values.starttime).map((val , i) => (
                    <IonSelectOption key={i} value={val.fVal}>
                      {val.val}
                    </IonSelectOption>
                  ))}
                </IonSelect>*/}
                <IonIcon slot="end" color="gold" src={chevronDown}></IonIcon> 
              </IonItem>
            </IonCol>
          </IonRow>
          {formik.errors.endtime  ? (
            <IonRow class="margin-set" style={{marginLeft: 32}} className="headerRow-desk">
              <IonText color="danger">
                {formik.errors.endtime}
              </IonText>
              </IonRow>
            ) : null}
          <IonRow  class="margin-set" style={styles.headerRow} className="headerRow-desk">
            <IonCol size-xs="12" size-sm="4">
              <IonRow>
                <IonLabel style={styles.fieldLabel}>
                  Prospect/Client Type
                </IonLabel>
              </IonRow>
              {checkboxList.map(({ val, name }, i) => (
                <IonItem lines="none" key={i} style={styles.optionItemI1}>
                  <IonLabel style={styles.optionLabel}>{val}</IonLabel>
                  <IonCheckbox
                    mode="md"
                    style={styles.checkIcon}
                    slot="start"
                    value={val}
                    name={name}
                    onIonChange={(val) => {
                      val.detail.value === "Other"
                        ? val.detail.checked
                          ? setRO(false)
                          : setRO(true)
                        : setRO(true);
                      formik.setFieldValue(name, val.detail.checked);
                    }}
                    checked={formik.values[name]}
                  />
                </IonItem>
              ))}
              <IonItem
                style={styles.inputItemI1}
                lines="none"
                className="NoteTypeinput1"
              >
                <IonInput
                  placeholder="Enter a type"
                  name="otherTypeValue"
                  readonly={ro}
                  value={formik.values.otherTypeValue}
                  onIonChange={formik.handleChange}
                  autocapitalize="sentences"
                  spellcheck={true}
                ></IonInput>
              </IonItem>
            </IonCol>
            {/* <IonCol size-xs="12" size-sm="4">
             <IonRow>
                <IonLabel style={styles.fieldLabel}>Meeting Topic</IonLabel>
              </IonRow>
              <IonRadioGroup
                name="meetingTopic"
                value={formik.values.meetingTopic}
                // onIonChange={formik.handleChange}
                onIonChange={(val) => {
                  val.detail.value === "Other"
                    ? setRO(false)
                      : setRO(true)
                  formik.setFieldValue("meetingTopic", val.detail.value);
                }}
              >
                {meetingTopicList.map(({ val }, i) => (
                  <IonItem lines="none" key={i} style={styles.optionItem}>
                    <IonRadio
                      slot="start"
                      style={styles.radioIcon}
                      mode="md"
                      value={val}
                    />
                    <IonLabel style={styles.optionLabel}>{val}</IonLabel>
                  </IonItem>
                ))}
              </IonRadioGroup>
              <IonItem
                style={styles.inputItem}
                lines="none"
                className="NoteTypeinput"
              >
                <IonInput
                  placeholder="Enter a topic"
                  name="otherTopicValue"
                  readonly={ro}
                  value={formik.values.otherTopicValue}
                  onIonChange={formik.handleChange}
                  autocapitalize="sentences"
                  spellcheck={true}
                ></IonInput>
              </IonItem>
            </IonCol> */}
             <IonCol size-xs="12" size-sm="4">
            <IonLabel class="font14" style={styles.label}>
            Meeting Topic
          </IonLabel>
          <IonItem  style={styles.selectItem} lines="none" class="ion-item-height1">
            <IonSelect
              // as={IonSelect}
              interfaceOptions={optionsA}
              style={styles.select}
              mode="ios"
              onIonChange={formik.handleChange}
              onIonBlur={formik.handleBlur}
              value={formik.values.meetingTopic}
              class="customIonSelect font14"
              name="meetingTopic"
              placeholder="Select an option or create one"
              interface={width < 576 ? "action-sheet" : "popover"}
            >
             {meetingTopicList.map((o) => (
                        <IonSelectOption key={o.val} value={o.val}>
                          {o.val}
                </IonSelectOption>
              ))}
            </IonSelect>
            <IonIcon slot="end" icon={chevronDown} color="gold" />
          </IonItem>

          {formik.values.meetingTopic === "Other" ? (
		   <IonRow >
                      <IonCol>
                        <IonLabel class="font14" style={styles.label}>
                        Please Enter Topic
                        </IonLabel>
                        <IonItem lines="none" style={styles.selectItem} class="ion-item-height">
                          <IonInput
                            class="inpClass font14"
                            onIonChange={formik.handleChange}
                            value={formik.values.otherTopicValue}
                            name="otherTopicValue"
                            placeholder="Enter a Topic"
                            spellcheck={true}
                            autocapitalize="sentences"
                          />
                        </IonItem>
                       
                      </IonCol>
                    </IonRow>
                    ) : (
                    ""
                  )}
            </IonCol>
            {/* <IonCol size-xs="12" size-sm="4">
              <IonRow>
                <IonLabel style={styles.fieldLabel}>Meeting Type</IonLabel>
              </IonRow>
              <IonRadioGroup
                name="meetingType"
                value={formik.values.meetingType}
                onIonChange={formik.handleChange}
              >
                {meetingTypeList.map(({ val }, i) => (
                  <IonItem lines="none" key={i} style={styles.optionItem}>
                    <IonLabel style={styles.optionLabel}>{val}</IonLabel>
                    <IonRadio
                      style={styles.radioIcon}
                      slot="start"
                      mode="md"
                      value={val}
                    />
                  </IonItem>
                ))}
              </IonRadioGroup>
            </IonCol> */}
             <IonCol size-xs="12" size-sm="4">
          <IonLabel class="font14" style={styles.label}>
            Meeting Type
          </IonLabel>
          <IonItem  style={styles.selectItem} lines="none" class="ion-item-height1">
            <IonSelect
              // as={IonSelect}
              interfaceOptions={optionsA}
              style={styles.select}
              mode="ios"
              onIonChange={formik.handleChange}
              onIonBlur={formik.handleBlur}
              value={formik.values.meetingType}
              class="customIonSelect font14"
              name="meetingType"
              placeholder="Select an option or create one"
              interface={width < 576 ? "action-sheet" : "popover"}
            >
             {meetingTypeList.map((o) => (
                        <IonSelectOption key={o.val} value={o.val}>
                          {o.val}
                </IonSelectOption>
              ))}
            </IonSelect>
            <IonIcon slot="end" icon={chevronDown} color="gold" />
          </IonItem>
         
        </IonCol>
          </IonRow>

          <IonRow class="margin-set" style={styles.headerRow}>
          <IonCol size-xs="10" size-sm="4"  size-lg="4">
            <IonLabel style={styles.fieldLabel}>
              Invite Required Attendees
            </IonLabel>   
            {/* {formik.errors.attendeesName ? (
              <IonText style={{ color: "red", marginLeft: 10 }}>
                {formik.errors.attendeesName}
              </IonText>
            ) : null}        */}
         
          <IonItem style={styles.selectItem} class ="ion-item-height" lines="none">
          <IonSelect 
          className="attendeesSelect"
          multiple
           value={formik.values.attendeesName}
           name="attendeesName"
           mode="md"
          //value={initialOwner.join(',')}
          onIonChange={(event) => {setownerIds(event.detail.value);formik.setFieldValue("attendeesName",event.detail.value) }}
          >
            {hubOwners
                .map((obj) => {
                  return {
                    val:
                      obj.firstName && obj.firstName !== ""
                        ? `${obj.firstName} ${obj.lastName}`
                        : obj.email,
                    hs_id: obj.id,
                  };
                })
                .map((obj, i) => (
                  <IonSelectOption key={i} custom-rmid={obj.hs_id} value={obj.hs_id}> 
                    {obj.val}
                  </IonSelectOption>
                ))}
          </IonSelect>
            <IonIcon slot="end" icon={chevronDown} color="gold" />
          </IonItem>
        </IonCol>
        <IonCol size-xs="10" size-sm="4"  size-lg="4">
            <IonLabel style={styles.fieldLabel}>
              Invite External Attendees
            </IonLabel>      
          <IonItem style={styles.selectItem} class ="ion-item-height" lines="none" onClick={triggerSearch}/>          
        </IonCol>
        <IonCol size-xs="10" size-sm="4"  size-lg="4">
        {cntactnames
              ? cntactnames.map((val, i) => (
                  <IonChip key={i}>
                    <IonLabel custom-id={cntactids[i]}>{val}</IonLabel>
                    {/* {cntactids.length===1?<IonIcon                    
                      onClick={(event) => {                        
                        deletechip(
                          (event.target as HTMLInputElement).getAttribute(
                            "custom-id"
                          )
                        );
                      }}
                      custom-id={cntactids[i]}
                      icon={close}
                      className="disabled"
                    />: */}
                    <IonIcon                    
                    onClick={(event) => {                        
                      deletechip(
                        (event.target as HTMLInputElement).getAttribute(
                          "custom-id"
                        )
                      );
                    }}
                    custom-id={cntactids[i]}
                    icon={close}
                  />
                  {/* } */}
                    
                  </IonChip>
                ))
              : null}
        </IonCol>
          </IonRow>

          <IonRow class="margin-set" style={styles.headerRow} className="headerRow-desk">
            <IonLabel style={styles.fieldLabel}>*Meeting Notes</IonLabel>
            {formik.errors.meetingNote ? (
              <IonText style={{ color: "red", marginLeft: 10 }}>
                {formik.errors.meetingNote}
              </IonText>
            ) : null}
          </IonRow>
          <IonRow class="margin-set" style={styles.textAreaRow}>
            <IonTextarea
              style={styles.textArea}
              placeholder="Enter any notes from the meeting here"
              name="meetingNote"
              rows={6}
              value={formik.values.meetingNote}
              onIonChange={formik.handleChange}
              spellcheck={true}
              autocapitalize="sentences"
            ></IonTextarea>
          </IonRow>
          <IonRow class="margin-set">
            <IonCol>
              <IonItem style={{ marginRight: 32 }} className="new-note-ion-item" />
            </IonCol>
          </IonRow>
          <IonRow class="margin-set">
            <IonCol>
              {openSentiment ? (
                <React.Fragment>
                  <IonItem
                    onClick={toggleSentiment}
                    style={{ marginRight: 32 }}
                    className="new-note-ion-item"
                    lines="none"
                  >
                    <IonIcon src={close} style={styles.addIcon}></IonIcon>
                    <IonText style={styles.acordHeader}>
                      Close Sentiments
                    </IonText>
                  </IonItem>
                  <IonRow style={styles.headerRow}  className="headerRow-desk">
                    <IonLabel class="fs-22" style={styles.formHeader}>
                      Sentiments From Last Meeting{" "}
                    </IonLabel>{" "}
                    <IonLabel style={styles.formHeaderSmall}>
                      {" "}
                      {moment(lstmeetdate).format("LL")} (update as necessary)
                    </IonLabel>
                  </IonRow>
                  <IonRow style={styles.headerRow}  className="headerRow-desk">
                    <IonCol size-xs="12" size-sm="4">
                      <IonRow className="sentiTitleRow">
                        <IonLabel style={styles.fieldLabel}>
                          General View On Company
                        </IonLabel>
                      </IonRow>
                      <IonRadioGroup
                        name="viewOnComp"
                        value={formik.values.viewOnComp}
                        onIonChange={formik.handleChange}
                      >
                        {VOC.map(({ val }, i) => (
                          <IonItem
                            lines="none"
                            key={i}
                            style={styles.optionItem}
                          >
                            <IonLabel style={styles.optionLabel}>
                              {val}
                            </IonLabel>
                            <IonRadio
                              style={styles.radioIcon}
                              slot="start"
                              mode="md"
                              value={val}
                            />
                          </IonItem>
                        ))}
                      </IonRadioGroup>
                    </IonCol>
                    <IonCol size-xs="12" size-sm="4">
                      <IonRow className="sentiTitleRow">
                        <IonLabel style={styles.fieldLabel}>
                          Investment Objectives
                        </IonLabel>
                      </IonRow>
                      <IonRadioGroup
                        name="investObj"
                        value={formik.values.investObj}
                        onIonChange={formik.handleChange}
                      >
                        {IO.map(({ val }, i) => (
                          <IonItem
                            lines="none"
                            key={i}
                            style={styles.optionItem}
                          >
                            <IonLabel style={styles.optionLabel}>
                              {val}
                            </IonLabel>
                            <IonRadio
                              style={styles.radioIcon}
                              slot="start"
                              mode="md"
                              value={val}
                            />
                          </IonItem>
                        ))}
                      </IonRadioGroup>
                    </IonCol>
                    <IonCol size-xs="12" size-sm="4">
                      <IonRow className="sentiTitleRow">
                        <IonLabel style={styles.fieldLabel}>
                          Investment Liquidity
                        </IonLabel>
                      </IonRow>
                      <IonRadioGroup
                        name="investLiq"
                        value={formik.values.investLiq}
                        onIonChange={formik.handleChange}
                      >
                        {IL.map(({ val }, i) => (
                          <IonItem
                            lines="none"
                            key={i}
                            style={styles.optionItem}
                          >
                            <IonLabel style={styles.optionLabel}>
                              {val}
                            </IonLabel>
                            <IonRadio
                              style={styles.radioIcon}
                              slot="start"
                              mode="md"
                              value={val}
                            />
                          </IonItem>
                        ))}
                      </IonRadioGroup>
                    </IonCol>
                  </IonRow>

                  <IonRow style={styles.headerRow}  className="headerRow-desk">
                    <IonCol size-xs="12" size-sm="4">
                      <IonRow className="sentiTitleRow">
                        <IonLabel style={styles.fieldLabel}>
                          Optimism on local economy
                        </IonLabel>
                      </IonRow>
                      <IonRadioGroup
                        name="localECO"
                        value={formik.values.localECO}
                        onIonChange={formik.handleChange}
                      >
                        {OLE.map(({ val }, i) => (
                          <IonItem
                            lines="none"
                            key={i}
                            style={styles.optionItem}
                          >
                            <IonLabel style={styles.optionLabel}>
                              {val}
                            </IonLabel>
                            <IonRadio
                              style={styles.radioIcon}
                              slot="start"
                              mode="md"
                              value={val}
                            />
                          </IonItem>
                        ))}
                      </IonRadioGroup>
                    </IonCol>
                    <IonCol size-xs="12" size-sm="4">
                      <IonRow className="sentiTitleRow">
                        <IonLabel style={styles.fieldLabel}>
                          Optimism on intl. Market
                        </IonLabel>
                      </IonRow>
                      <IonRadioGroup
                        name="intlMkt"
                        value={formik.values.intlMkt}
                        onIonChange={formik.handleChange}
                      >
                        {OIM.map(({ val }, i) => (
                          <IonItem
                            lines="none"
                            key={i}
                            style={styles.optionItem}
                          >
                            <IonLabel style={styles.optionLabel}>
                              {val}
                            </IonLabel>
                            <IonRadio
                              style={styles.radioIcon}
                              slot="start"
                              mode="md"
                              value={val}
                            />
                          </IonItem>
                        ))}
                      </IonRadioGroup>
                    </IonCol>
                    <IonCol size-xs="12" size-sm="4">
                      <IonRow className="sentiTitleRow">
                        <IonLabel style={styles.fieldLabel}>
                          Optimism on core business
                        </IonLabel>
                      </IonRow>
                      <IonRadioGroup
                        name="coreBusiness"
                        value={formik.values.coreBusiness}
                        onIonChange={formik.handleChange}
                      >
                        {OCB.map(({ val }, i) => (
                          <IonItem
                            lines="none"
                            key={i}
                            style={styles.optionItem}
                          >
                            <IonLabel style={styles.optionLabel}>
                              {val}
                            </IonLabel>
                            <IonRadio
                              style={styles.radioIcon}
                              slot="start"
                              mode="md"
                              value={val}
                            />
                          </IonItem>
                        ))}
                      </IonRadioGroup>
                    </IonCol>
                  </IonRow>
                  <IonRow style={styles.headerRow}  className="headerRow-desk">
                    <IonCol>
                      <IonRow className="sentiTitleRow">
                        <IonLabel style={styles.fieldLabel}>
                          Appetite for intl. exposure
                        </IonLabel>
                      </IonRow>
                      <IonRadioGroup
                        name="intlExpo"
                        value={formik.values.intlExpo}
                        onIonChange={formik.handleChange}
                      >
                        {AIE.map(({ val }, i) => (
                          <IonItem
                            lines="none"
                            key={i}
                            style={styles.optionItem}
                          >
                            <IonLabel style={styles.optionLabel}>
                              {val}
                            </IonLabel>
                            <IonRadio
                              style={styles.radioIcon}
                              slot="start"
                              mode="md"
                              value={val}
                            />
                          </IonItem>
                        ))}
                      </IonRadioGroup>
                    </IonCol>
                  </IonRow>
                  <IonItem lines="full" className="notes-line-nn notes-line-desk"></IonItem>
                </React.Fragment>
              ) : (
                <IonItem onClick={toggleSentiment} style={{ marginRight: 32 }} className="new-note-ion-item">
                  <IonIcon src={add} style={styles.addIcon}></IonIcon>
                  <IonText style={styles.acordHeader}>Add Sentiments</IonText>
                </IonItem>
              )}
            </IonCol>
          </IonRow>
          <IonRow class="margin-set">
            <IonCol>
              {openFinancial ? (
                <React.Fragment>
                  <IonItem
                    onClick={toggleFinancial}
                    style={{ marginRight: 32 }}
                    lines="none"
                    className="new-note-ion-item"
                  >
                    <IonIcon src={close} style={styles.addIcon}></IonIcon>
                    <IonText style={styles.acordHeader}>
                      Close Financial Information
                    </IonText>
                  </IonItem>
                  <IonRow style={styles.headerRow}  className="headerRow-desk">
                    <IonLabel class="fs-22" style={styles.formHeader}>
                      Financial Information
                    </IonLabel>
                  </IonRow>
                  <IonRow style={styles.headerRow}  className="headerRow-desk">
                    <IonCol size-xs="12" sizeSm="6" sizeLg="4">
                      <IonRow>
                        <IonLabel style={styles.fieldLabel}>
                          Expected Closing Probability
                        </IonLabel>
                      </IonRow>
                      <IonRow>
                        <IonItem lines="none" style={styles.dropdown}>
                          <IonSelect
                            value={formik.values.closingProb}
                            onIonChange={formik.handleChange}
                            name="closingProb"
                            interface={width < 576 ? "action-sheet" : "popover"}
                            interfaceOptions={optionsA}
                            mode="ios"
                          >
                            {closingProbabilityOptions.map(({ val }, i) => (
                              <IonSelectOption key={i} value={val}>
                                {val}
                              </IonSelectOption>
                            ))}
                          </IonSelect>
                          <IonIcon
                            slot="end"
                            color="gold"
                            src={chevronDown}
                          ></IonIcon>
                        </IonItem>
                      </IonRow>
                    </IonCol>
                    <IonCol size-xs="12" sizeSm="6" sizeLg="4">
                      <IonRow>
                        <IonLabel style={styles.fieldLabel}>
                          Expected Month of Closing
                        </IonLabel>
                      </IonRow>
                      <IonRow>
                        <IonItem lines="none" style={styles.dropdown}>
                          <IonSelect
                            value={formik.values.closingMonth}
                            onIonChange={formik.handleChange}
                            name="closingMonth"
                            interface={width < 576 ? "action-sheet" : "popover"}
                            interfaceOptions={optionsA}
                            mode="ios"
                          >
                            {closingMonthOptions().map(({ val, mom }, i) => (
                              <IonSelectOption key={i} value={mom}>
                                {val}
                              </IonSelectOption>
                            ))}
                          </IonSelect>
                          <IonIcon
                            slot="end"
                            color="gold"
                            src={chevronDown}
                          ></IonIcon>
                        </IonItem>
                      </IonRow>
                    </IonCol>
                    <IonCol size-xs="12" sizeSm="6" sizeLg="4">
                      <IonRow>
                        <IonLabel style={styles.fieldLabel}>
                          Expected Closing Amount
                        </IonLabel>
                      </IonRow>
                      <IonRow>
                        <IonItem lines="none" style={styles.dropdown}>
                          <IonIcon
                            color="gold"
                            style={{ height: 20 }}
                            icon={dollar}
                          />
                          <MaskedIonInput
                            value={formik.values.closingAmt}
                            // onIonChange={formik.handleChange}
                            // name="closingAmt"
                            onChange={(e) => {
                              formik.setFieldValue("closingAmt", e);
                            }}
                          ></MaskedIonInput>
                        </IonItem>
                      </IonRow>
                    </IonCol>
                  </IonRow>
                  <IonItem lines="full" className="notes-line-nn notes-line-desk"></IonItem>
                </React.Fragment>
              ) : (
                <IonItem onClick={toggleFinancial} style={{ marginRight: 32 }} className="new-note-ion-item">
                  <IonIcon src={add} style={styles.addIcon}></IonIcon>
                  <IonText style={styles.acordHeader}>
                    Add Financial Information
                  </IonText>
                </IonItem>
              )}
            </IonCol>
          </IonRow>
          <IonRow class="margin-set">
            <IonCol>
              {openLiquidity ? (
                <React.Fragment>
                  <IonItem
                    onClick={toggleLiquidity}
                    style={{ marginRight: 32 }}
                    className="new-note-ion-item"
                    lines="none"
                  >
                    <IonIcon src={close} style={styles.addIcon}></IonIcon>
                    <IonText style={styles.acordHeader}>
                      Close Liquidity Event
                    </IonText>
                  </IonItem>
                  <IonRow style={styles.headerRow}  className="headerRow-desk">
                    <IonLabel class="fs-17" color="white" style={styles.fieldLabel}>
                      Liquidity Events
                    </IonLabel>
                  </IonRow>
                  <IonRow style={styles.textAreaRow} className="txtAreaRowDesk">
                    <IonTextarea
                      style={styles.textArea}
                      placeholder="Inheritance in the spring of 2020"
                      name="liqEvent"
                      rows={6}
                      value={formik.values.liqEvent}
                      onIonChange={formik.handleChange}
                      spellcheck={true}
                      autocapitalize="sentences"
                    ></IonTextarea>
                  </IonRow>
                  <IonItem lines="full" className="notes-line-nn notes-line-desk"></IonItem>
                </React.Fragment>
              ) : (
                <IonItem onClick={toggleLiquidity} style={{ marginRight: 32 }} className="new-note-ion-item">
                  <IonIcon src={add} style={styles.addIcon}></IonIcon>
                  <IonText style={styles.acordHeader}>
                    Add Liquidity Event
                  </IonText>
                </IonItem>
              )}
            </IonCol>
          </IonRow>
          <IonRow class="margin-set">
            <IonCol>
              {openNextStep ? (
                <React.Fragment>
                  <IonItem onClick={toggleNextStep} style={{ marginRight: 32 }} className="new-note-ion-item">
                    <IonIcon src={close} style={styles.addIcon}></IonIcon>
                    <IonText style={styles.acordHeader}>Next Steps</IonText>
                  </IonItem>
                  <IonRow style={styles.headerRow}  className="headerRow-desk">
                    <IonCol size-xs="12" size-sm="6" style={{ padding: 0 }}>
                      <IonRow style={{ marginBottom: 6 }}>
                        <IonLabel style={styles.fieldLabel}>
                          * Task Title
                        </IonLabel>
                        {formik.errors.taskTitle ? (
                          <IonText style={{ color: "red", marginLeft: 10 }}>
                            {formik.errors.taskTitle}
                          </IonText>
                        ) : null}
                      </IonRow>
                      <IonRow>
                        <IonInput
                          style={styles.textbox}
                          placeholder="Enter the title of your task"
                          name="taskTitle"
                          mode="md"
                          value={formik.values.taskTitle}
                          onIonChange={formik.handleChange}
                          spellcheck={true}
                          autocapitalize="sentences"
                        ></IonInput>
                      </IonRow>
                      <IonRow style={{ marginBottom: 6 }}>
                        <IonLabel style={styles.fieldLabel}>
                          * Due Date
                        </IonLabel>
                      </IonRow>
                      <IonRow>
                        <IonCol
                          sizeSm="12"
                          sizeLg="8"
                          style={{ paddingLeft: 0 }}
                        >
                          <IonItem lines="none" style={styles.dateItem}>
                            <CustDatePicker
                              dateFormat="LL"
                              date={formik.values.dueDate}
                              onSetDateVal={(val) => {
                                setDueDateVal(val);
                                formik.setFieldValue(
                                  "dueDate",
                                  moment(val).format("YYYY-MM-DD")
                                );
                              }}
                            />
                            <IonIcon
                              style={{ width: 24, height: 24 }}
                              src={calendargold}
                              slot="end"
                            ></IonIcon>
                          </IonItem>
                        </IonCol>
                        <IonCol
                          sizeSm="6"
                          sizeLg="4"
                          className="nxttimepickerCol"
                        >
                          <IonItem lines="none" style={styles.dateItem}>
                            <IonSelect
                              interface={
                                width < 576 ? "action-sheet" : "popover"
                              }
                              value={formik.values.dueTime}
                              onIonChange={formik.handleChange}
                              name="dueTime"
                              interfaceOptions={optionsA}
                              mode="ios"
                            >
                              {/* {timePickerOptions.map(({ val }, i) => (
                                <IonSelectOption key={val} value={val}>
                                  {val}
                                </IonSelectOption>
                              ))} */}
                              {timePickerOptions.map((val , i) => (
                                <IonSelectOption key={i} value={val.fVal}>
                                  {val.val}
                                </IonSelectOption>
                              ))}
                            </IonSelect>
                            <IonIcon
                              slot="end"
                              color="gold"
                              src={chevronDown}
                            ></IonIcon>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                    </IonCol>
                    <IonCol size-xs="12" size-sm="1"></IonCol>
                    <IonCol size-xs="6" size-sm="2">
                      <IonRow>
                        <IonLabel style={styles.fieldLabel}>Type</IonLabel>
                      </IonRow>
                      <IonRadioGroup
                        value={formik.values.taskType}
                        onIonChange={formik.handleChange}
                        name="taskType"
                      >
                        {type.map(({ val, name }, i) => (
                          <IonItem
                            lines="none"
                            key={i}
                            style={styles.optionItem}
                          >
                            <IonLabel style={styles.optionLabel}>
                              {name}
                            </IonLabel>
                            <IonRadio
                              style={styles.radioIcon}
                              slot="start"
                              mode="md"
                              value={val}
                            />
                          </IonItem>
                        ))}
                      </IonRadioGroup>
                    </IonCol>
                    <IonCol size-xs="6" size-sm="3">
                      <IonRow>
                        <IonLabel style={styles.fieldLabel}>Priority</IonLabel>
                      </IonRow>
                      <IonRadioGroup
                        value={formik.values.priority}
                        onIonChange={formik.handleChange}
                       name="priority"
                      >
                        {priority.map(({ val, name }, i) => (
                          <IonItem
                            lines="none"
                            key={i}
                            style={styles.optionItem}
                          >
                            <IonLabel style={styles.optionLabel}>
                              {name}
                            </IonLabel>
                            <IonRadio
                              style={styles.radioIcon}
                              slot="start"
                              mode="md"
                              value={val}
                            />
                          </IonItem>
                        ))}
                      </IonRadioGroup>
                    </IonCol>
                  </IonRow>
                  <IonRow style={styles.headerRow}  className="headerRow-desk">
                    <IonLabel style={styles.fieldLabel}>Task Notes</IonLabel>
                  </IonRow>
                  <IonRow style={styles.textAreaRow} className="txtAreaRowDesk">
                    <IonTextarea
                      style={styles.textArea}
                      placeholder="Enter any notes you have concerning this task"
                      rows={6}
                      value={formik.values.taskNotes}
                      onIonChange={formik.handleChange}
                      name="taskNotes"
                      spellcheck={true}
                      autocapitalize="sentences"
                    ></IonTextarea>
                  </IonRow>
                  <IonRow style={styles.headerRow}  className="headerRow-desk">
                    <IonLabel style={styles.fieldLabel}>
                      Email Reminder
                    </IonLabel>
                  </IonRow>
                  <IonRow className="new-note-ion-item" style={{ marginTop: 8, marginLeft: 32 }}>
                    <IonCol size-xs="12" sizeSm="6" sizeLg="4">
                      {/* <IonItem lines="none" style={styles.dateItem}>
                        <IonSelect
                          value={formik.values.emailReminder}
                          onIonChange={formik.handleChange}
                          name="emailReminder"
                          interface={width < 576 ? "action-sheet" : "popover"}
                          interfaceOptions={optionsA}
                          mode="ios"
                        >
                          {emailReminderOption().map(({ val, dt }, i) => (
                            <IonSelectOption key={i} value={dt}>{val}</IonSelectOption>
                          ))}
                        </IonSelect>
                        <IonIcon
                          slot="end"
                          color="gold"
                          src={chevronDown}
                        ></IonIcon>
                      </IonItem> */}
                      <IonItem
                        className="input-items"
                        lines="none"
                        style={styles.dateItem}
                      >
                        <CustDatePicker
                          // dateFormat="MMM DD, YYYY"
                          dateFormat={
                            moment(formik.values.emailReminder)
                              .startOf("day")
                              .format("x") ===
                            moment(formik.values.dueDate)
                              .startOf("day")
                              .format("x")
                              ? `[On Due Date]`
                              : moment(formik.values.emailReminder)
                                  .startOf("day")
                                  .format("x") >
                                moment(formik.values.dueDate)
                                  .add(1, "days")
                                  .startOf("day")
                                  .format("x")
                              ? `[${moment(formik.values.emailReminder).from(
                                  moment(formik.values.dueDate).startOf("day"),
                                  true
                                )} after (]dddd[)]`
                              : `[${moment(formik.values.emailReminder).from(
                                  moment(formik.values.dueDate).startOf("day"),
                                  true
                                )} before (]dddd[)]`
                          }
                          date={formik.values.emailReminder}
                          InputClassName="meetingdatentime tfo-calendar-picker-input tfo-input"
                          onSetDateVal={(val) => {
                            formik.setFieldValue(
                              "emailReminder",
                              moment(val).startOf("day")
                            );
                            formik.setFieldTouched("emailReminder", true);
                          }}
                        />
                        <IonIcon
                          src={calendargold}
                          className="endchevron"
                          style={{ width: 24, height: 24 }}
                        ></IonIcon>
                      </IonItem>
                    </IonCol>
                    <IonCol size-xs="12" sizeSm="4" sizeLg="2">
                      <IonItem lines="none" style={styles.dateItem}>
                        <IonSelect
                          interface={width < 576 ? "action-sheet" : "popover"}
                          value={formik.values.reminderTime}
                          onIonChange={formik.handleChange}
                          name="reminderTime"
                          interfaceOptions={optionsA}
                          mode="ios"
                        >
                          {/* {timePickerOptions.map(({ val }, i) => (
                            <IonSelectOption key={i} value={val}>
                              {val}
                            </IonSelectOption>
                          ))} */}
                          {timePickerOptions.map((val , i) => (
                            <IonSelectOption key={i} value={val.fVal}>
                              {val.val}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                        <IonIcon
                          slot="end"
                          color="gold"
                          src={chevronDown}
                        ></IonIcon>
                      </IonItem>
                    </IonCol>
                    <IonCol size="6"></IonCol>
                  </IonRow>
                </React.Fragment>
              ) : (
                <IonItem onClick={toggleNextStep} style={{ marginRight: 32 }} className="new-note-ion-item">
                  <IonIcon src={add} style={styles.addIcon}></IonIcon>
                  <IonText style={styles.acordHeader}>Add Next Step</IonText>
                  {formik.errors.taskTitle ? (
                    <IonText style={{ color: "red", marginLeft: 10 }}>
                      {formik.errors.taskTitle}
                    </IonText>
                  ) : null}
                </IonItem>
              )}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem lines="none"></IonItem>
            </IonCol>
          </IonRow>
          <IonRow class="margin-set">
            <IonCol size="3">
              <IonButton
                type="submit"
                class="ion-float-left customformbtn"
                style={{
                  fontSize: 12,
                  borderColor: "#B99855",
                  marginLeft: 32,
                }}
                color="#ffffff"
              >
                <IonText className="ion-text-capitalize" color="gold" style={{whiteSpace:"nowrap", margin:"auto"}}> 
                  Save Meeting Notes
                </IonText>
              </IonButton>
            </IonCol>
            <IonCol size="3"></IonCol>
            <IonCol size="3"></IonCol>
            <IonCol size="3"></IonCol>
          </IonRow>
        </form>
      </IonContent>
    </React.Fragment>
  );
};

const MaskedIonInput = ({ value, onChange }) => {
  const maskRef = useRef<IMask.InputMask<any> | null>(null);

  const inputCallback = useCallback(async (input) => {
    if (!input) {
      return;
    }
    const nativeInput = await input.getInputElement();
    const mask = IMask(nativeInput, {
      mask: Number,
      thousandsSeparator: ",",
    })
      .on("accept", (e: any) => {
        onChange(mask.value);
      })
      .on("complete", (e: any) => {
        onChange(mask.value);
      });

    maskRef.current = mask;
  }, []);

  return (
    <IonInput
      style={{ "--padding-start": "8px" }}
      value={value}
      ref={inputCallback}
    />
  );
};

export default EditNote;