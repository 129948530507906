import React, { useEffect, useRef, useState } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "./videoPlayer.css";
import { fireBaseLog } from "../Material/FireBase";
type onPlay = () => void;
type onPause = () => void;
type onEnd = () => void;

type VideoPlayerPropsType = {
  url?: string;
  type?: string;
  poster?: string;
  showPlayBtn?: boolean;
  autoplay?: boolean;
  onPlay?: onPlay;
  onPause?: onPause;
  onEnd?: onEnd;
};

export default function VideoPlayer(props: VideoPlayerPropsType) {
  const {
    url,
    poster,
    autoplay,
    showPlayBtn,
    type,
    onPlay,
    onPause,
    onEnd,
  } = props;
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current) {
      if (onPlay && onPause && onEnd) {
        videojs(
          videoRef.current,
          {
            preload: "auto",
            onPlay: onPlay(),
            onPause: onPause(),
            onEnd: onEnd(),
            autoplay: autoplay || false,
            nativeControlsForTouch: true,
            fluid: true,
            controls: true,
            responsive: true,
            poster: poster,
            height: "300px",
            width: "300px",
            bigPlayButton: showPlayBtn || false,
            controlBar: {
              pictureInPictureToggle: false,
            },
            html5: {
              nativeVideoTracks: true,
            },
            sources: [
              {
                src: url || "",
                type: type || "video/mp4",
              },
            ],
          },
          function onPlayerReady() {
            /* this.on("ended", function() {
              if (onEnd) {
                onEnd();
              }
            }); */
          }
        );
      }
    }
  }, []);

  return (
    <div dir="ltr" className={`video-player-container`}>
      <video
        controls
        ref={videoRef}
        className="video-js"
        onPlay={onPlay}
        onPause={onPause}
      />
    </div>
  );
}
