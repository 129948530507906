import React, { CSSProperties, useEffect, useState } from 'react'
import { IonGrid, IonIcon, IonRow,IonCol } from '@ionic/react'
// import LineChart from './Line'
// import'./PorfolioChart.css'
import { ellipse } from 'ionicons/icons'
import PortfolioChartInstance from "./PortfolioChartInstance";
import personStar from 'src/icons/personstar.svg'
import percent from 'src/icons/percent.svg'
import api from "src/api";
import { Plugins } from "@capacitor/core";
import moment from 'moment'
import'./PortfolioChart.css'
const { Storage } = Plugins

interface StyleProps {
    rootGrid: CSSProperties;
    colbackL: CSSProperties;
    colbackR: CSSProperties;
    innerGrid: CSSProperties;
    title: CSSProperties;
    dropdownRow: CSSProperties;
    titleRow: CSSProperties;
    iconsize: CSSProperties;
}

const timeDropDown: any = [];
timeDropDown.push({ val: "Money Over 5 Years" });
timeDropDown.push({ val: "Money Over 10 Years" });
timeDropDown.push({ val: "Money Over 15 Years" });


const styles: StyleProps = {
    rootGrid: {
        marginTop: 24
    },
    colbackL: {
        height: 384,
        // background: "url(/assets/mesh.svg) no-repeat 100px 0px",
        backgroundColor: "#1A1A1A",
        paddingRight: 7.5
    },
    colbackR: {
        height: 384,
        // background: "url(/assets/mesh.svg) no-repeat 100px 0px",
        backgroundColor: "#1A1A1A",
        paddingLeft: 7.5
    },
    innerGrid: {
        position: "relative",
        zIndex: 2
    },
    titleRow: {
        position: "absolute",
        top: 27,
        marginLeft: 40,
        display: "flex",
        flexDirection: "column"
    },
    dropdownRow: {
        position: "absolute",
        top: 11.5,
        marginLeft: 40
    },
    title: {
        fontSize: 18,
        fontWeight: "normal"
    },
    iconsize: {
        height: 24,
        width: 24
    }
}


interface ChartCompProps {
    projReturns,
    impReinvestment
}


const PortfolioChart: React.FC<ChartCompProps> = ({
    projReturns,
    impReinvestment,
 }) => {

    const [outflw, setoutflw] = useState<[number, number, number, number][]>([
        [0, 0, 0 , 0],
        [1, 0, 0 , 0],
        [2, 0, 0 , 0],
        [3, 0, 0 , 0],
        [4, 0, 0 , 0],
        [5, 0, 0 , 0],
        
    ])
    const [dTarg, setdTarg] = useState<[number, number][]>([
        [0, 0],
        [1, 430],
        [2, 450],
        [3, 450],
        [4, 460],
        [5, 470],
        [6, 0],
      
    ])
    const [dAct, setdAct] = useState<[number, number][]>([
        [0, 0],
        [1, 150],
        [2, 250],
        [3, 230],
        [4, 260],
        [5, 260],
        [6, 0],
      
    ])

    const [cmtTf, setcmtTf] = useState<string>("Money Over 5 Years")
    
    const [render, setrender] = useState<boolean>(false)
    const [Projectret, setProjectret] = useState<string>("Money Over 5 Years")
    // const setCommitmentTimeframe = (str) => {
    //     setcmtTf(str)
    // }
    // const setOutflowTimeframe = (str) => {
    //     setoutflow(str)
    // }

    useEffect(() => { 
     
        let tempArrOutflow: any = [                            
            [0, 0,430 ,150],
            [1, 0, 450 , 250],
            [2, 0, 450 , 230],
            [3, 0, 460 , 260],
            [4, 0, 470 , 260],
            [5, 0, 0 , 0],
            
        ]
            let tempTotCap: number = 0;
            let tempTotInc: number = 0;
            if (projReturns && projReturns.length > 0) {
                projReturns.forEach(respobj => {
                for(const x of tempArrOutflow) {
                    if(x[0] === parseInt(respobj.id)) {
                        x[1] = parseInt(respobj.year);
                        x[3] = parseFloat(respobj.actReturn.toFixed(0));
                        x[2] = parseFloat(respobj.yield.toFixed(0));
                    }
                }
                tempTotCap = tempTotCap + parseFloat(respobj.capitalReturn)
                tempTotInc = tempTotInc + parseFloat(respobj.incomeDistribution)
            })   
           // setoutflw([]) 
        }     
                   
        setrender(false);               
        setoutflw(tempArrOutflow);
        // console.log("tempArrOutflow",tempArrOutflow);
                    
},[Projectret,projReturns])


    useEffect(() => {

        let tempArrTarg: any = [
            [0, 0],
            [1, 150],
            [2, 250],
            [3, 230],
            [4, 260],
            [5, 260],
            [6, 0],
            
        ]
        let tempArrAct: any = [
            [0, 0],
            [1, 430],
            [2, 450],
            [3, 450],
            [4, 460],
            [5, 470],
            [6, 0],
            
        ]
        let tempTotAct: number = 0
        if(impReinvestment && impReinvestment.length >0){
            impReinvestment.forEach(respobj => {
                for(const x of tempArrTarg) {
                    if(x[0] === parseInt(respobj.id)) {
                        x[1] = parseFloat(respobj.retInvested)
                    }
                }
                for(const y of tempArrAct) {
                    if(y[0] === parseInt(respobj.id)) {
                        y[1] = parseFloat(respobj.retNonInvested)
                    }
                }
                tempTotAct = tempTotAct + parseFloat(respobj.retNonInvested)
            })
        }
        setdTarg(tempArrTarg);
        setdAct(tempArrAct);

    }, [cmtTf,impReinvestment])

    // useEffect(() => {
    //     if(!render) {
    //         if(dProsp.length > 0 && dTarg.length > 0 && dAct.length > 0) {
    //             setrender(true)
    //         } 
    //     }
    // },[dProsp,dTarg,dAct])

    useEffect(() => {
        if(!render) {
            if( dTarg.length > 0 && dAct.length > 0) {
                setrender(true)
            } 
        }
    },[dTarg,dAct,render])

    return (
        <IonGrid class = "mr-13 ml-13" style={styles.rootGrid}>
            <IonRow>
                <IonCol size-xs="12" size-sm="6">
                    <PortfolioChartInstance
                        timeDropDown={timeDropDown}
                        CardTitle="Project Returns"
                        IconsObj={[
                            {
                                label:" Yield",
                                icon: <IonIcon icon={ellipse} style={{ color: "#B99855", ...styles.iconsize }} />
                            },
                            {
                                label: " Return",
                                icon: <IonIcon icon={ellipse} style={{ color: "#40412F", ...styles.iconsize }} />
                            },
                        ]}
                        chartData={
                            render && outflw.length ? [outflw]:[[]]
                        }
                        tf={Projectret}
                        setTimeFrame={setProjectret}
                    /> 
                </IonCol>

                <IonCol size-xs="12" size-sm="6">
                    <PortfolioChartInstance
                        timeDropDown={timeDropDown}
                        CardTitle="Impact of Reinvesting"
                        // Total={maxCmt}
                        IconsObj={[
                            {
                                label: "Return Invested",
                                icon: <IonIcon icon={ellipse} style={{ color: "#BFC5F4", ...styles.iconsize }} />
                            },
                            {
                                label: "Return Non-invested",
                                icon: <IonIcon icon={ellipse} style={{ color: "#F7B198", ...styles.iconsize }} />
                            },
                        ]}
                        chartData={
                            render && dTarg.length > 0 && dAct.length > 0 ? [dTarg, dAct] : [[]]
                        }
                        tf={cmtTf}
                        setTimeFrame={setcmtTf}
                    /> 
                </IonCol>
            </IonRow>
        </IonGrid>
    )
}

export default PortfolioChart