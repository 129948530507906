import {
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonButton,
  IonIcon,
  IonLabel,
  IonText,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonSelect,
  IonSelectOption,
  IonImg,
} from "@ionic/react";
import phonecall from "src/icons/phonecall.svg";
import emailicon from "src/icons/email.svg";
import Whatsup from "src/icons/Whatsup.svg";
import CryptoJS from "crypto-js";
import { chevronForward } from "ionicons/icons";
import ShowMoreText from "react-show-more-text";
import api from "src/api";
// import saudiarabia from "src/icons/saudiarabia.svg";
import world from "src/icons/world.svg";
import flagEU from "src/icons/flag-of-Europe.png";
import flagArgentina from "src/icons/flag-of-Argentina.png";
import flagAustralia from "src/icons/flag-of-Australia.png";
import flagBahrain from "src/icons/flag-of-Bahrain.png";
import flagBrazil from "src/icons/flag-of-Brazil.png";
import flagCanada from "src/icons/flag-of-Canada.png";
import flagChina from "src/icons/flag-of-China.png";
import flagFrance from "src/icons/flag-of-France.png";
import flagGermany from "src/icons/flag-of-Germany.png";
import flagIndia from "src/icons/flag-of-India.png";
import flagIndonesia from "src/icons/flag-of-Indonesia.png";
import flagItaly from "src/icons/flag-of-Italy.png";
import flagJapan from "src/icons/flag-of-Japan.png";
import flagKoreaSouth from "src/icons/flag-of-Korea-South.png";
import flagKuwait from "src/icons/flag-of-Kuwait.png";
import flagMexico from "src/icons/flag-of-Mexico.png";
import flagOman from "src/icons/flag-of-Oman.png";
import flagQatar from "src/icons/flag-of-Qatar.png";
import flagRussia from "src/icons/flag-of-Russia.png";
import flagSaudi from "src/icons/flag-of-Saudi-Arabia.png";
import flagTurkey from "src/icons/flag-of-Turkey.png";
import flagUAE from "src/icons/flag-of-United-Arab-Emirates.png";
import flagSA from "src/icons/flag-of-South-Africa.png";
import flagUK from "src/icons/flag-of-United-Kingdom.png";
import flagUSA from "src/icons/flag-of-United-States-of-America.png";

import diploma from "src/icons/diploma.svg";
import { Plugins } from "@capacitor/core";
import JourneyStages from "./JourneyStages";
import React, { useEffect, useState } from "react";
import moment from "moment";
import dollar from "src/icons/dollar.svg";
import "./Details.css";
import UpcomingSchedule from "./UpcomingSchedule";
import useWindowDimensions from "src/components/useWindowDimensions";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";
// import props from 'src/api/contactprops';

const { Device, Storage } = Plugins;

let companyName,
  companyPos,
  companysector,
  srcofwealth,
  expwealthrange,
  bioprof = "-";
let gender,
  age,
  custdate,
  type,
  techsophis,
  finsophis,
  segment,
  preferredchannel = "-";
let expclosingmnt,
  expclosingpbl,
  expclosingamt,
  acainst,
  degree,
  /*school,*/ address1,
  address2,
  country,
  emailid,
  phonenum,
  whtsappnum,
  prospectsrc,
  ObjectId,
  prospectstatus,
  last_contacted,
  leadstage,
  onboardingid = "-";

const styles = {
  card: {
    "--color": "yellow!important",
  },
  baseInfo: {
    marginBottom: 32,
    marginLeft: 32,
    marginRight: 32,
  },
  cntbtn: {
    "--border-radius": "16px",
    "--color": "#222222",
    marginRight: "auto",
    marginBottom: 16,
  },
  cntMethods: {
    fontWeight: "400",
    fontSize: 18,
    width: "100%",
  },
  cntInteraction: {
    fontWeight: "400",
    fontSize: 12,
  },
  phEmail: {
    "--padding-start": "0px",
    "margin-top": "-8px",
    "margin-bottom": "-8px",
  },
};

const selectCountryImage = (country) => {
  switch (country) {
    case "Saudi Arabia":
      return flagSaudi;
    case "Kuwait":
      return flagKuwait;
    case "United Arab Emirates":
      return flagUAE;
    case "Qatar":
      return flagQatar;
    case "Bahrain":
      return flagBahrain;
    case "Oman":
      return flagOman;
    case "Argentina":
      return flagArgentina;
    case "Australia":
      return flagAustralia;
    case "Brazil":
      return flagBrazil;
    case "Canada":
      return flagCanada;
    case "China":
      return flagChina;
    case "France":
      return flagFrance;
    case "Germany":
      return flagGermany;
    case "India":
      return flagIndia;
    case "Indonesia":
      return flagIndonesia;
    case "Italy":
      return flagItaly;
    case "Japan":
      return flagJapan;
    case "Republic of Korea":
      return flagKoreaSouth;
    case "Mexico":
      return flagMexico;
    case "Russia":
      return flagRussia;
    case "South Africa":
      return flagSA;
    case "Turkey":
      return flagTurkey;
    case "United Kingdom":
      return flagUK;
    case "United States":
      return flagUSA;
    default:
      return world;
  }
};
const decimalFunc = (Num) => {
  let temp = Num.toString().split(".");
  let retVal = 0;
  if (temp[1]) {
    retVal = Num.toFixed(1);
  } else {
    retVal = Num;
  }
  return retVal;
};
const retExpClsAmt = (clsAmt) => {
  let closingamt_disp: number = 0;
  Number.isNaN(clsAmt) || clsAmt === null || clsAmt === ""
    ? (closingamt_disp = 0)
    : (closingamt_disp = parseInt(clsAmt));
  return Math.abs(Number(closingamt_disp)) >= 1.0e9
    ? decimalFunc(Math.abs(Number(closingamt_disp)) / 1.0e9) + "b"
    : // Six Zeroes for Millions
    Math.abs(Number(closingamt_disp)) >= 1.0e6
    ? decimalFunc(Math.abs(Number(closingamt_disp)) / 1.0e6) + "m"
    : // Three Zeroes for Thousands
    Math.abs(Number(closingamt_disp)) >= 1.0e3
    ? decimalFunc(Math.abs(Number(closingamt_disp)) / 1.0e3) + "k"
    : decimalFunc(Math.abs(Number(closingamt_disp)));
};

const getFontSize = (text: string) => {
  return text !== null && text !== "" && text.length > 10 ? 20 : 34;
};
const getFontSizeExpClosingAmt = (text: any) => {
  return text !== null && text !== "" && text.length > 5 ? 34 : 53;
};

const Details: React.FC<any> = ({
  contact,
  noteArr,
  engagements,
  journeystage,
  genViewComp,
  AppIntExp,
  OptLocEco,
  OptCoreBus,
  InvObj,
  InvLiq,
  OptIntMkt,
  ShowInsights,
  JourneyStagesArray,
  // LeadStagesArray,
  associationId,
  setAsscSeg,
  clientAUM,
}) => {
  function detjourneystage() {
    journeystage();
  }

  if (contact) {
    // console.log(contact.properties);
    // let EduObj = JSON.parse(contact.properties.additional_qualification_degree ? contact.properties.additional_qualification_degree : "{}")
    last_contacted = contact.properties.notes_last_contacted;
    companyName = contact.properties.company;
    companyPos = contact.properties.position;
    companysector = contact.properties.industry_;
    srcofwealth = contact.properties.source_of_wealth;
    expwealthrange = contact.properties.expected_wealth_range;
    expclosingamt = contact.properties.amount_status;
    expclosingpbl = contact.properties.expected_closing_probability;
    expclosingmnt = contact.properties.expected_month_of_closing;
    bioprof = contact.properties.bio_profile;
    gender = contact.properties.gender;
    age = contact.properties.age_range;
    custdate = contact.properties.hs_lifecyclestage_customer_date;
    // type = contact.properties.type;
    type = contact.properties.hs_lifecyclestage_customer_date
      ? "Client"
      : "Prospect";
    techsophis = contact.properties.technology_sophistication;
    finsophis = contact.properties.financial_sophistication;
    segment = contact.properties.segment;
    acainst = contact.properties.school;
    // EduObj !== null && EduObj.edu && EduObj.edu[0] ? acainst = EduObj.edu[0].institution : acainst = '';
    degree = contact.properties.degree;
    // EduObj !== null && EduObj.edu && EduObj.edu[0] ? degree = EduObj.edu[0].degree : degree = ''
    address1 = contact.properties.address_line_1;
    address2 = contact.properties.address_line_2;
    prospectstatus = contact.properties.hs_lead_status;
    prospectsrc = contact.properties.contact_type;
    preferredchannel = contact.properties.preferred_channel;
    ObjectId = contact.properties.hs_object_id;
    country = contact.properties.country;
    emailid = contact.properties.email;
    phonenum = contact.properties.phone;
    whtsappnum = contact.properties.mobilephone;
    onboardingid = contact.properties.onboarding_id;
    leadstage = contact.properties.lead_stage;
  }
  let currDate = new Date();
  const [currYear, setcurrYear] = useState<any>(currDate.getFullYear());
  const optionsA = {
    cssClass: "my-custom-reinvest-select",
  };
  const { height, width } = useWindowDimensions();
  const [reinvestmentAmount, setReinvestmentAmount] = useState<any>(0);
  const [reinvestmentPecentage, setReinvestmentPecentage] = useState<any>(0);
  const [startDate, setstartDate] = useState<any>("01-JAN-" + currYear);
  const [endDate, setendDate] = useState<any>(
    moment(currDate).format("DD-MMM-YYYY")
  );
  const setYTD = (val) => {
    setstartDate("01-JAN-" + val);
    setendDate("31-DEC-" + val);
    setcurrYear(val);
  };
  const decrypt = (decryptVlaue) => {
    let passphrase =
      "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9";
    const bytes = CryptoJS.AES.decrypt(decryptVlaue, passphrase);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  };
  const getUserEmail = async () => {
    const ret: any = await Storage.get({ key: "userId" });
    const temp = JSON.parse(decrypt(ret.value));
    return temp && temp.email ? temp.email : null;
  };
  useEffect(() => {
    Device.getInfo().then((deviceInfo) => {
      getUserEmail().then((useremail) => {
        let conType: string = "";
        let contId: any = "";
        if (onboardingid) {
          conType = "ViewClient";
          contId = onboardingid;
        } else {
          conType = "ViewProspect";
          contId = ObjectId;
        }

        if (deviceInfo.platform == "web") {
          window.globalThis.firebase.analytics().setUserId(useremail);
          window.globalThis.firebase.analytics().logEvent("select_content", {
            user_name: useremail,
            content_type: conType,
            item_id: contId,
            device_id: deviceInfo ? deviceInfo.uuid : "",
            platform: deviceInfo ? deviceInfo.platform : "",
            device_model: deviceInfo ? deviceInfo.model : "",
            device_type: deviceInfo ? deviceInfo.osVersion : "",
            device_os: deviceInfo ? deviceInfo.operatingSystem : "",
          });
        } else {
          FirebaseAnalytics.setUserId(useremail);
          FirebaseAnalytics.logEvent("select_content", {
            user_name: useremail,
          });
          FirebaseAnalytics.logEvent("select_content", {
            content_type: conType,
          });
          FirebaseAnalytics.logEvent("select_content", { item_id: contId });
          FirebaseAnalytics.logEvent("select_content", {
            device_id: deviceInfo ? deviceInfo.uuid : "",
          });
          FirebaseAnalytics.logEvent("select_content", {
            platform: deviceInfo ? deviceInfo.platform : "",
          });
          FirebaseAnalytics.logEvent("select_content", {
            device_model: deviceInfo ? deviceInfo.model : "",
          });
          FirebaseAnalytics.logEvent("select_content", {
            device_type: deviceInfo ? deviceInfo.osVersion : "",
          });
          FirebaseAnalytics.logEvent("select_content", {
            device_os: deviceInfo ? deviceInfo.operatingSystem : "",
          });
        }
      });
    });
    if (onboardingid) {
      api
        .getClientDetailReinvestment(onboardingid, startDate, endDate)
        .then((resp) => {
          setReinvestmentAmount(
            retExpClsAmt(resp.data[0].totalReinvestmentAmount)
          );
          let ptg =
            (parseInt(resp.data[0].totalIncomeDistribution) -
              parseInt(resp.data[0].totalReinvestmentAmount)) /
            parseInt(resp.data[0].totalIncomeDistribution);
          ptg.toString() === "NaN"
            ? setReinvestmentPecentage("-")
            : setReinvestmentPecentage(ptg.toFixed(1));
        })
        .catch((error) => {
          console.error("Error from Conacts API: ", error);
        });
    }
  }, [onboardingid, startDate, endDate]);

  const newleadstageval = [
    "INACTIVE",
    "LOST",
    "Competition",
    "Disqualified",
    "Not interested SQL",
    "Unresponsive SQL",
  ];

  let leadstagevalues = newleadstageval.includes(leadstage);

  return (
    <React.Fragment>
      {!custdate ? (
        <IonGrid class="ml-13 mr-13">
          {expclosingamt !== null &&
          expclosingamt !== "" &&
          expclosingpbl !== null &&
          expclosingpbl !== "" ? (
            <IonRow
              class="mobile-padding0"
              style={{ paddingLeft: 24, paddingRight: 24 }}
            >
              <IonCol size-xs="6" size-sm="4">
                <IonCard className="firstLeft" style={{ position: "relative" }}>
                  <IonIcon
                    class="mobile-dollar"
                    icon={dollar}
                    color="white"
                    style={{
                      position: "absolute",
                      height: 34,
                      left: 24,
                      top: 36,
                    }}
                  ></IonIcon>
                  <IonCardHeader
                    color="white"
                    style={{
                      color: "#fff",
                      fontWeight: "300",
                      fontSize: getFontSizeExpClosingAmt(
                        retExpClsAmt(expclosingamt)
                      ),
                      paddingTop: 32,
                      paddingBottom: 10,
                      paddingLeft: 40,
                    }}
                  >
                    {retExpClsAmt(expclosingamt)}
                  </IonCardHeader>
                  <IonCardSubtitle
                    className="exp_sub"
                    color="greyscale"
                    style={{
                      paddingLeft: 40,
                      textTransform: "none",
                      fontWeight: "400",
                      fontSize: 14,
                      position: "absolute",
                      bottom: 24,
                    }}
                  >
                    Expected Closing Amount
                  </IonCardSubtitle>
                </IonCard>
              </IonCol>
              <IonCol size-xs="6" size-sm="4">
                <IonCard className="firstLeft firstLeft-mobile">
                  <IonCardHeader
                    color="white"
                    style={{
                      color: "#fff",
                      fontWeight: "300",
                      fontSize: 43,
                      paddingTop: 26,
                      paddingBottom: 10,
                      paddingLeft: 40,
                    }}
                  >
                    {expclosingpbl}
                    <div
                      style={{
                        position: "relative",
                        marginTop: 6,
                        marginBottom: 6,
                        borderBottom: "4px solid #B99855",
                        width: expclosingpbl,
                      }}
                    />
                    <div className="percGrey" />
                  </IonCardHeader>
                  <IonCardSubtitle
                    className="exp_sub"
                    color="greyscale"
                    style={{
                      paddingLeft: 40,
                      textTransform: "none",
                      fontWeight: "400",
                      fontSize: 14,
                      position: "absolute",
                      bottom: 24,
                    }}
                  >
                    Expected Probability
                  </IonCardSubtitle>
                </IonCard>
              </IonCol>
              <IonCol size-xs="12" size-sm="4">
                <IonCard className="firstLeftNr">
                  <IonCardHeader
                    color="white"
                    style={{
                      color: "#fff",
                      fontWeight: "300",
                      fontSize: 43,
                      paddingTop: 43,
                      paddingBottom: 10,
                      paddingLeft: 40,
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {expclosingmnt !== null && expclosingmnt !== ""
                      ? moment(expclosingmnt).format("MMM YYYY")
                      : "-"}
                  </IonCardHeader>
                  <IonCardSubtitle
                    className="exp_sub"
                    color="greyscale"
                    style={{
                      paddingLeft: 40,
                      textTransform: "none",
                      fontWeight: "400",
                      fontSize: 14,
                      position: "absolute",
                      bottom: 24,
                    }}
                  >
                    Expected Month of Closing
                  </IonCardSubtitle>
                </IonCard>
              </IonCol>
            </IonRow>
          ) : null}

          <IonRow
            class="mobile-padding0"
            style={{ paddingLeft: 24, paddingRight: 24 }}
          >
            <IonCol size-xs="6" size-sm="4">
              <IonCard className="firstLeft">
                <IonCardHeader
                  color="white"
                  style={{
                    color: "#fff",
                    fontWeight: "300",
                    fontSize: 53,
                    paddingTop: 32,
                    paddingBottom: 10,
                    paddingLeft: 40,
                  }}
                >
                  {segment !== null && segment !== "" ? segment.charAt(0) : "-"}
                </IonCardHeader>
                <IonCardSubtitle
                  color="greyscale"
                  style={{
                    paddingLeft: 40,
                    textTransform: "none",
                    fontWeight: "400",
                    fontSize: 14,
                    position: "absolute",
                    bottom: 24,
                  }}
                >{`${
                  segment !== null && segment !== "" ? segment : ""
                } Persona`}</IonCardSubtitle>
              </IonCard>
              <IonCard className="firstLeftNb">
                <IonCardHeader
                  color="white"
                  style={{
                    color: "#fff",
                    fontWeight: "300",
                    fontSize: getFontSize(prospectstatus),
                    paddingTop: 55,
                    paddingBottom: 10,
                    paddingLeft: 40,
                  }}
                >
                  {prospectstatus ? prospectstatus : "-"}
                </IonCardHeader>
                <IonCardSubtitle
                  color="greyscale"
                  style={{
                    paddingLeft: 40,
                    textTransform: "none",
                    fontWeight: "400",
                    fontSize: 14,
                    position: "absolute",
                    bottom: 24,
                  }}
                >
                  Status
                </IonCardSubtitle>
              </IonCard>
            </IonCol>
            <IonCol size-xs="6" size-sm="4">
              <IonCard className="firstLeft firstLeft-mobile">
                <IonCardHeader
                  color="white"
                  style={{
                    color: "#fff",
                    fontWeight: "300",
                    fontSize: 34,
                    paddingTop: 55,
                    paddingBottom: 10,
                    paddingLeft: 40,
                  }}
                >
                  {prospectsrc ? prospectsrc : "-"}
                </IonCardHeader>
                <IonCardSubtitle
                  color="greyscale"
                  style={{
                    paddingLeft: 40,
                    textTransform: "none",
                    fontWeight: "400",
                    fontSize: 14,
                    position: "absolute",
                    bottom: 24,
                  }}
                >
                  Source
                </IonCardSubtitle>
              </IonCard>
              {associationId &&
              associationId !== "-" &&
              associationId !== " " ? (
                <IonCard
                  className="firstLeftNb"
                  onClick={(e) => {
                    setAsscSeg("associations");
                  }}
                >
                  <IonCardHeader
                    color="white"
                    style={{
                      color: "#fff",
                      fontWeight: "300",
                      fontSize: 34,
                      paddingTop: 55,
                      paddingBottom: 10,
                      paddingLeft: 40,
                    }}
                  >
                    {" "}
                    <sup style={{ fontSize: 18 }}>#</sup>
                    {associationId}{" "}
                  </IonCardHeader>
                  <IonCardSubtitle
                    color="gold"
                    style={{
                      paddingLeft: 40,
                      textTransform: "none",
                      fontWeight: "400",
                      fontSize: 14,
                      position: "absolute",
                      bottom: 24,
                    }}
                  >
                    Referral from Client
                  </IonCardSubtitle>
                </IonCard>
              ) : null}
            </IonCol>
            <IonCol size-xs="12" size-sm="4">
              <IonRow
                style={{
                  marginLeft: 32,
                  marginTop: 48,
                  display: "flex",
                  flexDirection: "column",
                }}
                class="mobile-flex-align"
              >
                <IonText class="fs-18" style={styles.cntMethods} color="white">
                  Contact Methods
                </IonText>

                <IonText
                  class="fs-12"
                  color="greyscale"
                  style={styles.cntInteraction}
                >
                  <p>
                    {last_contacted &&
                    last_contacted !== null &&
                    last_contacted !== ""
                      ? `Last Interaction: ${moment(last_contacted).format(
                          "ll"
                        )}`
                      : null}
                  </p>
                </IonText>
                <IonRow>
                  <IonItem lines="none" style={styles.phEmail}>
                    <IonButton mode="ios" className="call-buttons" fill="clear">
                      {emailid && emailid !== null ? (
                        <a className="profhref" href={"mailto:" + emailid}>
                          <IonIcon
                            icon={emailicon}
                            style={{ width: 24, height: 24, marginRight: 8 }}
                          ></IonIcon>
                        </a>
                      ) : (
                        <IonIcon
                          icon={emailicon}
                          style={{ width: 24, height: 24, marginRight: 8 }}
                        ></IonIcon>
                      )}
                    </IonButton>
                    {emailid && emailid !== null ? (
                      <IonText
                        color="greyscale"
                        style={{
                          paddingRight: "10px",
                          textDecoration: "underline",
                          "word-break": "break-all",
                        }}
                      >
                        {emailid}
                      </IonText>
                    ) : null}
                    {emailid && emailid !== null ? (
                      <a className="profhref" href={"mailto:" + emailid}>
                        <IonIcon
                          icon={chevronForward}
                          size="small"
                          color="gold"
                          slot="end"
                        />{" "}
                      </a>
                    ) : (
                      <IonIcon
                        icon={chevronForward}
                        size="small"
                        color="gold"
                        slot="end"
                      />
                    )}
                  </IonItem>
                  <IonItem lines="none" style={styles.phEmail}>
                    <IonButton mode="ios" className="call-buttons" fill="clear">
                      {phonenum && phonenum !== null ? (
                        <a className="profhref" href={"tel:" + phonenum}>
                          <IonIcon
                            icon={phonecall}
                            style={{ width: 24, height: 24, marginRight: 8 }}
                          ></IonIcon>
                        </a>
                      ) : (
                        <IonIcon
                          icon={phonecall}
                          style={{ width: 24, height: 24, marginRight: 8 }}
                        ></IonIcon>
                      )}
                    </IonButton>
                    {phonenum && phonenum !== null ? (
                      <IonText
                        color="greyscale"
                        style={{
                          paddingRight: "10px",
                          textDecoration: "underline",
                        }}
                      >
                        {phonenum}
                      </IonText>
                    ) : null}
                    {phonenum && phonenum !== null ? (
                      <a className="profhref" href={"tel:" + phonenum}>
                        <IonIcon
                          icon={chevronForward}
                          size="small"
                          color="gold"
                          slot="end"
                        />{" "}
                      </a>
                    ) : (
                      <IonIcon
                        icon={chevronForward}
                        size="small"
                        color="gold"
                        slot="end"
                      />
                    )}
                  </IonItem>
                  {whtsappnum && whtsappnum !== null && whtsappnum !== "" ? (
                    <IonItem lines="none" style={styles.phEmail}>
                      <IonButton
                        mode="ios"
                        className="call-buttons"
                        fill="clear"
                      >
                        {" "}
                        <a
                          className="profhref"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`https://api.whatsapp.com/send?phone=${whtsappnum}`}
                        >
                          <IonIcon
                            icon={Whatsup}
                            style={{ width: 24, height: 24, marginRight: 8 }}
                          ></IonIcon>
                        </a>
                      </IonButton>
                      {whtsappnum && whtsappnum !== null ? (
                        <IonText
                          color="greyscale"
                          style={{
                            paddingRight: "10px",
                            textDecoration: "underline",
                          }}
                        >
                          {whtsappnum}
                        </IonText>
                      ) : null}
                      <a
                        className="profhref"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://api.whatsapp.com/send?phone=${whtsappnum}`}
                      >
                        <IonIcon
                          icon={chevronForward}
                          size="small"
                          color="gold"
                          slot="end"
                        />{" "}
                      </a>
                    </IonItem>
                  ) : (
                    ""
                  )}{" "}
                </IonRow>

                {/* <IonRow
                  class="mobile-flex-row"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  {preferredchannel &&
                  preferredchannel.includes("Call") === true ? (
                    <IonButton
                      mode="ios"
                      style={styles.cntbtn}
                      className="cnt-buttons"
                      color="actblue"
                      size="small"
                    >
                      <a className="profhref" href={"tel:" + phonenum}>
                        Call
                      </a>
                      <IonIcon
                        icon={chevronForward}
                        size="small"
                        color="greyscale2"
                        slot="end"
                      />
                    </IonButton>
                  ) : (
                    ""
                  )}
                  {preferredchannel &&
                  preferredchannel.includes("Whatsapp") === true &&
                  whtsappnum &&
                  whtsappnum !== null &&
                  whtsappnum !== "" ? (
                  //  { console.log.(preferredchannel)}
                    <IonButton
                      mode="ios"
                      style={styles.cntbtn}
                      className="cnt-buttons"
                      color="actblue"
                      size="small"
                    >
                      {/* <IonText color="greyscale2">Whatsapp</IonText> */}
                {/* <a className="profhref" href={"tel:" + phonenum}>Call</a> 
                      <a
                        className="profhref"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://api.whatsapp.com/send?phone=${whtsappnum}`}
                      >
                        WhatsApp
                      </a>
                      {/* <a
                                                className="profhref"
                                                target="_blank"
                                                href={`https://wa.me/${whtsappnum}?text=Hello%20World`}>WhatsApp</a> 
                      <IonIcon
                        icon={chevronForward}
                        size="small"
                        color="greyscale2"
                        slot="end"
                      />
                    </IonButton>
                  ) : (
                    ""
                  )}
                  {preferredchannel &&
                  preferredchannel.includes("Email") === true ? (
                    <IonButton
                      mode="ios"
                      style={styles.cntbtn}
                      className="cnt-buttons"
                      color="actblue"
                      size="small"
                    >
                      <a className="profhref" href={"mailto:" + emailid}>
                        Email
                      </a>
                      <IonIcon
                        icon={chevronForward}
                        size="small"
                        color="greyscale2"
                        slot="end"
                      />
                    </IonButton>
                  ) : (
                    ""
                  )}
                </IonRow> */}
              </IonRow>
            </IonCol>
          </IonRow>
          <IonItem
            class="margin-unset"
            lines="full"
            style={{ marginLeft: 32, marginRight: 32, marginBottom: 32 }}
          />
        </IonGrid>
      ) : (
        <IonGrid class="ml-13 mr-13">
          <IonRow
            class="mobile-padding0"
            style={{ paddingLeft: 24, paddingRight: 24 }}
          >
            <IonCol size-xs="6" size-sm="4">
              <IonCard className="firstLeft" style={{ position: "relative" }}>
                <IonIcon
                  class="mobile-dollar"
                  icon={dollar}
                  color="white"
                  style={{
                    position: "absolute",
                    height: 34,
                    left: 24,
                    top: 36,
                  }}
                ></IonIcon>
                <IonCardHeader
                  color="white"
                  style={{
                    color: "#fff",
                    fontWeight: "300",
                    fontSize: 53,
                    paddingTop: 32,
                    paddingBottom: 10,
                    paddingLeft: 40,
                  }}
                >
                  {retExpClsAmt(expclosingamt)}
                </IonCardHeader>

                <IonCardSubtitle
                  className="exp_sub"
                  color="greyscale"
                  style={{
                    paddingLeft: 40,
                    textTransform: "none",
                    fontWeight: "400",
                    fontSize: 14,
                    position: "absolute",
                    bottom: 24,
                  }}
                >
                  Expected Wallet Increase
                </IonCardSubtitle>
              </IonCard>
            </IonCol>
            <IonCol size-xs="6" size-sm="4">
              <IonCard className="firstLeft firstLeft-mobile">
                <IonCardHeader
                  color="white"
                  style={{
                    color: "#fff",
                    fontWeight: "300",
                    fontSize: 43,
                    paddingTop: 26,
                    paddingBottom: 10,
                    paddingLeft: 40,
                  }}
                >
                  {expclosingpbl}
                  <div
                    style={{
                      position: "relative",
                      marginTop: 6,
                      marginBottom: 6,
                      borderBottom: "4px solid #B99855",
                      width: expclosingpbl,
                    }}
                  />
                  <div className="percGrey" />
                </IonCardHeader>
                <IonCardSubtitle
                  className="exp_sub"
                  color="greyscale"
                  style={{
                    paddingLeft: 40,
                    textTransform: "none",
                    fontWeight: "400",
                    fontSize: 14,
                    position: "absolute",
                    bottom: 24,
                  }}
                >
                  Expected Probability
                </IonCardSubtitle>
              </IonCard>
            </IonCol>
            <IonCol size-xs="12" size-sm="4">
              <IonCard className="firstLeftNr">
                <IonCardHeader
                  class="fs-43"
                  color="white"
                  style={{
                    color: "#fff",
                    fontWeight: "300",
                    fontSize: 43,
                    paddingTop: 43,
                    paddingBottom: 10,
                    paddingLeft: 40,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {expclosingmnt !== null && expclosingmnt !== ""
                    ? moment(expclosingmnt).format("MMM YYYY")
                    : "-"}
                </IonCardHeader>
                <IonCardSubtitle
                  className="exp_sub"
                  color="greyscale"
                  style={{
                    paddingLeft: 40,
                    textTransform: "none",
                    fontWeight: "400",
                    fontSize: 14,
                    position: "absolute",
                    bottom: 24,
                  }}
                >
                  Expected Month of Increase
                </IonCardSubtitle>
              </IonCard>
            </IonCol>
          </IonRow>
          <IonRow
            class="mobile-padding0"
            style={{ paddingLeft: 24, paddingRight: 24 }}
          >
            <IonCol size-xs="6" size-sm="4">
              <IonCard className="firstLeft" style={{ position: "relative" }}>
                <IonIcon
                  class="mobile-dollar"
                  icon={dollar}
                  color="white"
                  style={{
                    position: "absolute",
                    height: 34,
                    left: 24,
                    top: 36,
                  }}
                ></IonIcon>
                <IonCardHeader
                  color="white"
                  style={{
                    color: "#fff",
                    fontWeight: "300",
                    fontSize: 53,
                    paddingTop: 32,
                    paddingBottom: 10,
                    paddingLeft: 40,
                  }}
                >
                  {retExpClsAmt(clientAUM)}
                </IonCardHeader>
                <IonCardSubtitle
                  className="exp_sub"
                  color="greyscale"
                  style={{
                    paddingLeft: 40,
                    textTransform: "none",
                    fontWeight: "400",
                    fontSize: 14,
                    position: "absolute",
                    bottom: 24,
                  }}
                >
                  Current AUM
                </IonCardSubtitle>
              </IonCard>
            </IonCol>
            <IonCol size-xs="6" size-sm="4">
              <IonCard className="firstLeft firstLeft-mobile">
                <IonIcon
                  class="mobile-dollar"
                  icon={dollar}
                  color="white"
                  style={{
                    position: "absolute",
                    height: 34,
                    left: 24,
                    top: 36,
                  }}
                ></IonIcon>
                <IonCardHeader
                  color="white"
                  style={{
                    color: "#fff",
                    fontWeight: "300",
                    fontSize: 53,
                    paddingTop: 32,
                    paddingBottom: 10,
                    paddingLeft: 40,
                  }}
                >
                  {reinvestmentAmount}
                  <IonText class="font14">({reinvestmentPecentage}%)</IonText>
                </IonCardHeader>
                <IonCardSubtitle
                  className="exp_sub"
                  color="greyscale"
                  style={{
                    paddingLeft: 40,
                    textTransform: "none",
                    fontWeight: "400",
                    fontSize: 14,
                    position: "absolute",
                    bottom: 24,
                  }}
                >
                  Reinvested in{" "}
                </IonCardSubtitle>
                <IonSelect
                  value={currYear}
                  onIonChange={(e) => {
                    setYTD(e.detail.value);
                  }}
                  name="reinvestYear"
                  class="my-custom-reinvest-select"
                  interface={width < 576 ? "action-sheet" : "popover"}
                  interfaceOptions={optionsA}
                  mode="ios"
                >
                  {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((val, i) => (
                    <IonSelectOption
                      key={i}
                      value={currDate.getFullYear() - val}
                    >
                      {currDate.getFullYear() - val}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonCard>
            </IonCol>
            <IonCol size-xs="12" size-sm="4">
              <IonCard className="firstLeft firstLeft-mobile">
                <IonCardHeader
                  color="white"
                  style={{
                    color: "#fff",
                    fontWeight: "300",
                    fontSize: getFontSize(prospectstatus),
                    paddingTop: 55,
                    paddingBottom: 10,
                    paddingLeft: 40,
                  }}
                >
                  {prospectstatus ? prospectstatus : "-"}
                </IonCardHeader>
                <IonCardSubtitle
                  color="greyscale"
                  style={{
                    paddingLeft: 40,
                    textTransform: "none",
                    fontWeight: "400",
                    fontSize: 14,
                    position: "absolute",
                    bottom: 24,
                  }}
                >
                  Status
                </IonCardSubtitle>
              </IonCard>
            </IonCol>
          </IonRow>
          <IonRow
            class="mobile-padding0"
            style={{ paddingLeft: 24, paddingRight: 24 }}
          >
            <IonCol size-xs="6" size-sm="4">
              <IonCard className="firstLeftNb">
                <IonCardHeader
                  color="white"
                  className="persona-det"
                  style={{
                    color: "#fff",
                    fontWeight: "300",
                    fontSize: 53,
                    paddingTop: 32,
                    paddingBottom: 10,
                    paddingLeft: 40,
                  }}
                >
                  {segment !== null && segment !== "" ? segment.charAt(0) : "-"}
                </IonCardHeader>
                <IonCardSubtitle
                  color="greyscale"
                  style={{
                    paddingLeft: 40,
                    textTransform: "none",
                    fontWeight: "400",
                    fontSize: 14,
                    position: "absolute",
                    bottom: 24,
                  }}
                >{`${
                  segment !== null && segment !== "" ? segment : ""
                } Persona`}</IonCardSubtitle>
              </IonCard>
              {/* <IonCard className="firstLeftNb">
                            <IonCardHeader color="white" style={{ color: "#fff", fontWeight: "300", fontSize: getFontSize(prospectstatus), paddingTop: 55, paddingBottom: 10, paddingLeft: 40 }}>{prospectstatus ? prospectstatus : "-"}</IonCardHeader>
                            <IonCardSubtitle color="greyscale" style={{ paddingLeft: 40, textTransform: "none", fontWeight: "400", fontSize: 14, position: "absolute", bottom: 24 }}>Status</IonCardSubtitle>
                        </IonCard> */}
            </IonCol>
            <IonCol size-xs="6" size-sm="4">
              <IonCard className="firstLeftNb firstLeft-mobile">
                <IonCardHeader
                  color="white"
                  style={{
                    color: "#fff",
                    fontWeight: "300",
                    fontSize: 34,
                    paddingTop: 55,
                    paddingBottom: 10,
                    paddingLeft: 40,
                  }}
                >
                  {prospectsrc ? prospectsrc : "-"}
                </IonCardHeader>
                <IonCardSubtitle
                  color="greyscale"
                  style={{
                    paddingLeft: 40,
                    textTransform: "none",
                    fontWeight: "400",
                    fontSize: 14,
                    position: "absolute",
                    bottom: 24,
                  }}
                >
                  Source
                </IonCardSubtitle>
              </IonCard>
              {/* {
                            associationId && associationId !== "-" && associationId !== " " ?
                            <IonCard className="firstLeftNb" onClick={(e)=>{setAsscSeg('associations')}}>
                                <IonCardHeader color="white" style={{ color: "#fff", fontWeight: "300", fontSize: 34, paddingTop: 55, paddingBottom: 10, paddingLeft: 40 }}> <sup style={{fontSize: 18}}>#</sup>{associationId} </IonCardHeader>
                                <IonCardSubtitle color="gold" style={{ paddingLeft: 40, textTransform: "none", fontWeight: "400", fontSize: 14, position: "absolute", bottom: 24 }} >Referral from Client</IonCardSubtitle>
                            </IonCard> : null
                        } */}
            </IonCol>
            <IonCol size-xs="12" size-sm="4">
              <IonRow
                style={{
                  marginLeft: 32,
                  marginTop: 48,
                  display: "flex",
                  flexDirection: "column",
                }}
                class="mobile-flex-align"
              >
                <IonText style={styles.cntMethods} color="white">
                  Contact Methods
                </IonText>

                <IonText color="greyscale" style={styles.cntInteraction}>
                  <p>
                    {last_contacted &&
                    last_contacted !== null &&
                    last_contacted !== ""
                      ? `Last Interaction: ${moment(last_contacted).format(
                          "ll"
                        )}`
                      : null}
                  </p>
                </IonText>
                <IonRow>
                  <IonItem lines="none" style={styles.phEmail}>
                    <IonButton mode="ios" className="call-buttons" fill="clear">
                      {emailid && emailid !== null ? (
                        <a className="profhref" href={"mailto:" + emailid}>
                          <IonIcon
                            icon={emailicon}
                            style={{ width: 24, height: 24, marginRight: 8 }}
                          ></IonIcon>
                        </a>
                      ) : (
                        <IonIcon
                          icon={emailicon}
                          style={{ width: 24, height: 24, marginRight: 8 }}
                        ></IonIcon>
                      )}
                    </IonButton>
                    {emailid && emailid !== null ? (
                      <IonText
                        color="greyscale"
                        style={{
                          paddingRight: "10px",
                          textDecoration: "underline",
                        }}
                      >
                        {emailid}
                      </IonText>
                    ) : null}
                    {emailid && emailid !== null ? (
                      <a className="profhref" href={"mailto:" + emailid}>
                        <IonIcon
                          icon={chevronForward}
                          size="small"
                          color="gold"
                          slot="end"
                        />{" "}
                      </a>
                    ) : (
                      <IonIcon
                        icon={chevronForward}
                        size="small"
                        color="gold"
                        slot="end"
                      />
                    )}
                  </IonItem>
                  <IonItem lines="none" style={styles.phEmail}>
                    <IonButton mode="ios" className="call-buttons" fill="clear">
                      {phonenum && phonenum !== null ? (
                        <a className="profhref" href={"tel:" + phonenum}>
                          <IonIcon
                            icon={phonecall}
                            style={{ width: 24, height: 24, marginRight: 8 }}
                          ></IonIcon>
                        </a>
                      ) : (
                        <IonIcon
                          icon={phonecall}
                          style={{ width: 24, height: 24, marginRight: 8 }}
                        ></IonIcon>
                      )}
                    </IonButton>
                    {phonenum && phonenum !== null ? (
                      <IonText
                        color="greyscale"
                        style={{
                          paddingRight: "10px",
                          textDecoration: "underline",
                        }}
                      >
                        {phonenum}
                      </IonText>
                    ) : null}
                    {phonenum && phonenum !== null ? (
                      <a className="profhref" href={"tel:" + phonenum}>
                        <IonIcon
                          icon={chevronForward}
                          size="small"
                          color="gold"
                          slot="end"
                        />{" "}
                      </a>
                    ) : (
                      <IonIcon
                        icon={chevronForward}
                        size="small"
                        color="gold"
                        slot="end"
                      />
                    )}
                  </IonItem>
                  {whtsappnum && whtsappnum !== null && whtsappnum !== "" ? (
                    <IonItem lines="none" style={styles.phEmail}>
                      <IonButton
                        mode="ios"
                        className="call-buttons"
                        fill="clear"
                      >
                        <a
                          className="profhref"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`https://api.whatsapp.com/send?phone=${whtsappnum}`}
                        >
                          <IonIcon
                            icon={Whatsup}
                            style={{ width: 24, height: 24, marginRight: 8 }}
                          ></IonIcon>
                        </a>
                      </IonButton>
                      {whtsappnum && whtsappnum !== null ? (
                        <IonText
                          color="greyscale"
                          style={{
                            paddingRight: "10px",
                            textDecoration: "underline",
                          }}
                        >
                          {whtsappnum}
                        </IonText>
                      ) : null}
                      <a
                        className="profhref"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://api.whatsapp.com/send?phone=${whtsappnum}`}
                      >
                        <IonIcon
                          icon={chevronForward}
                          size="small"
                          color="gold"
                          slot="end"
                        />{" "}
                      </a>
                    </IonItem>
                  ) : (
                    ""
                  )}{" "}
                </IonRow>

                {/* <IonRow
                  class="mobile-flex-row"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  {preferredchannel &&
                  preferredchannel.includes("Call") === true ? (
                    <IonButton
                      mode="ios"
                      style={styles.cntbtn}
                      className="cnt-buttons"
                      color="actblue"
                      size="small"
                    >
                      <a className="profhref" href={"tel:" + phonenum}>
                        Call
                      </a>
                      <IonIcon
                        icon={chevronForward}
                        size="small"
                        color="greyscale2"
                        slot="end"
                      />
                    </IonButton>
                  ) : (
                    ""
                  )}
                  {preferredchannel &&
                  preferredchannel.includes("Whatsapp") === true &&
                  whtsappnum &&
                  whtsappnum !== null &&
                  whtsappnum !== "" ? (
                    <IonButton
                      mode="ios"
                      style={styles.cntbtn}
                      className="cnt-buttons"
                      color="actblue"
                      size="small"
                    >
                      {/* <IonText color="greyscale2">Whatsapp</IonText> */}
                {/* <a className="profhref" href={"tel:" + phonenum}>Call</a> 
                      <a
                        className="profhref"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://api.whatsapp.com/send?phone=${whtsappnum}`}
                      >
                        WhatsApp
                      </a>
                      {/* <a
                                                className="profhref"
                                                target="_blank"
                                                href={`https://wa.me/${whtsappnum}?text=Hello%20World`}>WhatsApp</a> 
                      <IonIcon
                        icon={chevronForward}
                        size="small"
                        color="greyscale2"
                        slot="end"
                      />
                    </IonButton>
                  ) : (
                    ""
                  )}
                  {preferredchannel &&
                  preferredchannel.includes("Email") === true ? (
                    <IonButton
                      mode="ios"
                      style={styles.cntbtn}
                      className="cnt-buttons"
                      color="actblue"
                      size="small"
                    >
                      <a className="profhref" href={"mailto:" + emailid}>
                        Email
                      </a>
                      <IonIcon
                        icon={chevronForward}
                        size="small"
                        color="greyscale2"
                        slot="end"
                      />
                    </IonButton>
                  ) : (
                    ""
                  )}
                </IonRow> */}
              </IonRow>
            </IonCol>
          </IonRow>
        </IonGrid>
      )}

      {/* User Journey Stages */}

      {/* {!custdate && JourneyStagesArray && JourneyStagesArray.length > 0  && LeadStagesArray.length <= 0 ? ( */}

      {!custdate &&
      JourneyStagesArray &&
      JourneyStagesArray.length > 0 &&
      !leadstagevalues &&
      type === "Prospect" ? (
        <React.Fragment>
          <JourneyStages
            journeystage={detjourneystage}
            JourneyStagesHub={JourneyStagesArray}
            contact={contact}
          />
          <IonItem
            class="ml-13 mr-13"
            style={{ marginLeft: 32, marginRight: 32 }}
            lines="full"
          />
        </React.Fragment>
      ) : !custdate ? (
        width <= 1024 ? (
          <IonItem
            class="ml-13 mr-13"
            style={{ "margin-top": "-26px", marginLeft: 32, marginRight: 32 }}
            lines="full"
          >
            <IonLabel style={{ "margin-bottom": "15px" }} className="font18">
              Lead Stage
              <IonText
                color="greyscale"
                style={{ fontSize: 12, marginLeft: "30px" }}
              >
                {" "}
                {leadstage}
              </IonText>
            </IonLabel>
          </IonItem>
        ) : (
          <IonItem
            class="ml-13 mr-13"
            style={{ marginLeft: 32, marginRight: 32 }}
            lines="full"
          >
            <IonLabel style={{ "margin-bottom": "15px" }} className="font18">
              Lead Stage
              <IonText
                color="greyscale"
                style={{ fontSize: 12, marginLeft: "30px" }}
              >
                {" "}
                {leadstage}
              </IonText>
            </IonLabel>
          </IonItem>
        )
      ) : (
        <IonItem
          class="ml-13 mr-13"
          style={{ marginLeft: 32, marginRight: 32 }}
          lines="full"
        ></IonItem>
      )}

      {ShowInsights ? (
        <React.Fragment>
          <Insights
            genViewComp={genViewComp}
            AppIntExp={AppIntExp}
            OptLocEco={OptLocEco}
            OptCoreBus={OptCoreBus}
            InvObj={InvObj}
            InvLiq={InvLiq}
            OptIntMkt={OptIntMkt}
            contact={contact}
          />
          <IonItem
            class="ml-13 mr-13"
            style={{ marginLeft: 32, marginRight: 32 }}
            lines="full"
          />
        </React.Fragment>
      ) : null}

      {/* Upcoming Schedule */}
      <UpcomingSchedule engagements={engagements} contact={contact} />

      <IonGrid
        style={{ marginLeft: 32, marginRight: 32, marginTop: 32 }}
        className="fi-grid ml-13 mr-13 insight-grid"
      >
        <IonRow style={{ marginBottom: 16 }}>
          <IonLabel className="font18">Financial Information</IonLabel>
        </IonRow>
        <IonRow>
          <IonCol size-xs="12" size-sm="4">
            <p className="font14 white">Company</p>
            <p className="font18 grayscale reducetopmargin">
              {companyName ? companyName : "-"}
            </p>
            <div className="bottomBorder95"></div>
          </IonCol>
          <IonCol size-xs="12" size-sm="4">
            <p className="font14 white">Position</p>
            <p className="font18 grayscale reducetopmargin">
              {companyPos ? companyPos : "-"}
            </p>
            <div className="bottomBorder95"></div>
            <div className="rightBorder95"></div>
          </IonCol>
          <IonCol size-xs="12" size-sm="4">
            <p className="font14 white">Sector</p>
            <p className="font18 grayscale reducetopmargin">
              {companysector ? companysector : "-"}
            </p>
            <div className="bottomBorder95"></div>
            <div className="rightBorder95"></div>
          </IonCol>
        </IonRow>
        <IonRow class="finlastrow">
          <IonCol size-xs="12" size-sm="4">
            <p className="font14  white">Source of Wealth</p>
            <p className="font18 grayscale reducetopmargin">
              {srcofwealth ? srcofwealth : "-"}
            </p>
          </IonCol>
          <IonCol size-xs="12" size-sm="4">
            <p className="font14 white">Expected Wealth Range</p>
            <p className="font18 primarygold reducetopmargin">
              ${expwealthrange ? expwealthrange : "-"}
            </p>
            <div className="rightBorder95"></div>
          </IonCol>
          <IonCol size-xs="12" size-sm="4">
            <p className="font14 white">Expected Closing Amount</p>
            <p className="font18 primarygold reducetopmargin">
              ${expclosingamt ? expclosingamt : "-"}
            </p>
            <div className="rightBorder95"></div>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonGrid className="ml-13 mr-13 insight-grid" style={{ margin: 32 }}>
        <IonRow style={{ marginBottom: 16 }}>
          <IonLabel className="font14">Bio/Profile</IonLabel>
        </IonRow>
        <div className="bioDiv">
          <IonText class="bioDesc ion-text-start">
            <ShowMoreText
              /* Default options */
              lines={3}
              more="Read more..."
              less="Read less"
              className="content-css showmore"
              anchorClass="my-anchor-css-class primarygold"
              expanded={false}
              //width={100%}
            >
              {bioprof ? bioprof : "-"}
            </ShowMoreText>
          </IonText>
        </div>
      </IonGrid>

      {/* Basic Information Section */}
      <IonGrid
        style={{ marginLeft: 32, marginRight: 32 }}
        className="ml-13 mr-13 bi-grid insight-grid"
      >
        <IonRow style={{ marginBottom: 16 }}>
          <IonLabel className="font18">Basic Information</IonLabel>
        </IonRow>
        <IonRow>
          <IonCol size-xs="12" size-sm="4">
            <p className="font14 white">Gender</p>
            <p className="font18 grayscale reducetopmargin">
              {gender
                ? gender === "Yes"
                  ? "Male"
                  : gender === "No"
                  ? "Female"
                  : gender
                : "-"}
            </p>
            <div className="bottomBorder95"></div>
          </IonCol>
          <IonCol size-xs="12" size-sm="4">
            <p className="font14 white">Age</p>
            <p className="font18 grayscale reducetopmargin">
              {age ? age : "-"}
            </p>
            <div className="bottomBorder95"></div>
            <div className="rightBorder95"></div>
          </IonCol>
          <IonCol size-xs="12" size-sm="4">
            <p className="font14 white">Type</p>
            <p className="font18 grayscale reducetopmargin">
              {type ? type : "-"}
            </p>
            <div className="bottomBorder95"></div>
            <div className="rightBorder95"></div>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size-xs="12" size-sm="4">
            <div>
              <p className="font14 white">Technological Sophistication</p>
              <p
                color={
                  techsophis === "High"
                    ? "sophisgreen"
                    : techsophis === "Medium"
                    ? "sophispale"
                    : "sophisred"
                }
                className={
                  finsophis === "High"
                    ? "font18 reducetopmargin"
                    : finsophis === "Medium"
                    ? "font18 reducetopmargin"
                    : "font18 reducetopmargin"
                }
              >
                {techsophis ? techsophis : "-"}
              </p>
            </div>
          </IonCol>
          <IonCol size-xs="12" size-sm="4">
            <p className="font14 white ">Financial Sophistication</p>
            <p
              color={
                finsophis === "High"
                  ? "sophisgreen"
                  : finsophis === "Medium"
                  ? "sophispale"
                  : "sophisred"
              }
              className={
                finsophis === "High"
                  ? "font18 reducetopmargin"
                  : finsophis === "Medium"
                  ? "font18 reducetopmargin"
                  : "font18 reducetopmargin"
              }
            >
              {finsophis ? finsophis : "-"}
            </p>
            <div className="rightBorder95"></div>
          </IonCol>
          <IonCol size-xs="12" size-sm="4">
            <p className="font14 white">Segment/Persona</p>
            <p className="font18 grayscale reducetopmargin">
              {segment ? segment : "-"}
            </p>
            <div className="rightBorder95"></div>
          </IonCol>
        </IonRow>
      </IonGrid>

      {/*<IonLoading
       isOpen={isLoading}
       onDidDismiss={() => setLoading(false)}
       message={'Please wait...'}
       duration={5000}
     />
     {
         contact?
         <div>
        <IonCard>
        <IonCardHeader>
        <IonAvatar>
         <IonIcon icon={person} size="large"/>
       </IonAvatar>
         <IonCardSubtitle>{contact.id}</IonCardSubtitle>
         <IonCardTitle>{contact.properties.firstname+" "+contact.properties.lastname}</IonCardTitle>
       </IonCardHeader>
         <IonItem className="ion-activated">
             <IonIcon icon={mail} slot="start" />
             <IonLabel>{contact.properties.email}</IonLabel>
         </IonItem>
         </IonCard>
         <C3Chart data={data} />
         </div>
         :""
     }
   */}
      {/* <div className="preAddInfoBorder"></div> */}
      <IonItem
        class="ml-13 mr-13"
        style={{ marginLeft: 32, marginRight: 32 }}
        lines="full"
      />
      <IonGrid
        className="ml-13 mr-13 insight-grid"
        style={{ marginLeft: 32, marginRight: 32, marginTop: 32 }}
      >
        <IonRow style={{ marginBottom: 16 }}>
          <IonLabel className="font18">Additional Info</IonLabel>
        </IonRow>
        <IonRow>
          <IonCol size-xs="12" size-sm="6">
            <IonItem lines="none" style={{ "--padding-start": "0px" }}>
              <IonIcon icon={diploma} style={{ width: 48, height: 48 }} />
              <IonLabel class="ion-padding" color="white">
                <p>{acainst}</p>
                <p>{degree ? degree : "-"}</p>
              </IonLabel>
            </IonItem>
          </IonCol>
          <IonCol size-xs="12" size-sm="6">
            <IonItem lines="none" style={{ "--padding-start": "0px" }}>
              {/* <IonIcon icon={saudiarabia} style={{ width: 48, height: 48 }} /> */}
              <IonImg
                src={selectCountryImage(country)}
                style={{ width: 48, height: 48 }}
              />
              <IonLabel class="ion-padding" color="white">
                <p>
                  {" "}
                  {address1
                    ? country
                      ? address1 + ", " + address2 + ", " + country
                      : address1 + ", " + address2
                    : address2
                    ? country
                      ? address2 + ", " + country
                      : address2
                    : country
                    ? country
                    : "-"}
                </p>
              </IonLabel>
            </IonItem>
          </IonCol>
        </IonRow>
      </IonGrid>
    </React.Fragment>
  );
};
interface noteObj {
  noteTiming: string;
  noteid: any;
}

interface sArr extends Array<noteObj> {}

const Insights = ({
  contact,
  genViewComp,
  AppIntExp,
  OptLocEco,
  OptCoreBus,
  InvObj,
  InvLiq,
  OptIntMkt,
}) => {
  const { notes_last_contacted } = contact.properties;
  const { height, width } = useWindowDimensions();
  return contact && contact.properties ? (
    <IonGrid
      style={{ marginRight: 32, marginLeft: 32, marginTop: 32 }}
      className="fi-grid insight-grid"
    >
      <IonRow style={{ marginBottom: 16 }}>
        <IonLabel className="font18">Insights from last meeting</IonLabel>
        <IonLabel
          color="greyscale"
          style={{ fontSize: 12, marginLeft: 12, marginTop: 5 }}
          class="mobile-date-postion"
        >
          {notes_last_contacted &&
          notes_last_contacted !== null &&
          notes_last_contacted !== ""
            ? moment(notes_last_contacted).format("ll")
            : null}
        </IonLabel>
        <IonLabel class="insightsnewmeeting font14 primarygold">
          <IonButton color="gold" fill="clear">
            {/* routerLink="/newevent" */}
            {width > 576 ? "Start New Meeting" : "New Meeting"}
          </IonButton>
        </IonLabel>
      </IonRow>
      <IonRow>
        <IonCol size-xs="12" size-sm="4">
          <p className="font14 white">General View of Company</p>
          <p
            className="font18 reducetopmargin"
            color={
              genViewComp === "High"
                ? "sophisgreen"
                : genViewComp === "Medium"
                ? "sophispale"
                : "sophisred"
            }
          >
            {genViewComp}
          </p>
          <div className="bottomBorder95"></div>
        </IonCol>
        <IonCol size-xs="12" size-sm="4">
          <p className="font14 white">Investment Objectives</p>
          <p className="font18 reducetopmargin">{InvObj}</p>
          <div className="bottomBorder95"></div>
          <div className="rightBorder95"></div>
        </IonCol>
        <IonCol size-xs="12" size-sm="4">
          <p className="font14 white">Investment Liquidity</p>
          <p
            className="font18 reducetopmargin"
            color={
              InvLiq === "High"
                ? "sophisgreen"
                : InvLiq === "Medium"
                ? "sophispale"
                : "sophisred"
            }
          >
            {InvLiq}
          </p>
          <div className="bottomBorder95"></div>
          <div className="rightBorder95"></div>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size-xs="12" size-sm="4">
          <p className="font14  white">Optimism on Intl. Market</p>
          <p
            className="font18 reducetopmargin"
            color={
              OptIntMkt === "High"
                ? "sophisgreen"
                : OptIntMkt === "Medium"
                ? "sophispale"
                : "sophisred"
            }
          >
            {OptIntMkt}
          </p>
          <div className="bottomBorder95"></div>
        </IonCol>
        <IonCol size-xs="12" size-sm="4">
          <p className="font14 white">Appetite for Intl. Exposure</p>
          <p
            className="font18 primarygold reducetopmargin"
            color={
              AppIntExp === "High"
                ? "sophisgreen"
                : AppIntExp === "Medium"
                ? "sophispale"
                : "sophisred"
            }
          >
            {AppIntExp}
          </p>
          <div className="bottomBorder95"></div>
          <div className="rightBorder95"></div>
        </IonCol>
        <IonCol size-xs="12" size-sm="4">
          <p className="font14 white">Optimism on Local Economy</p>
          <p
            className="font18 primarygold reducetopmargin"
            color={
              OptLocEco === "High"
                ? "sophisgreen"
                : OptLocEco === "Medium"
                ? "sophispale"
                : "sophisred"
            }
          >
            {OptLocEco}
          </p>
          <div className="bottomBorder95"></div>
          <div className="rightBorder95"></div>
        </IonCol>
      </IonRow>
      <IonRow class="finlastrow">
        <IonCol size-xs="12" size-sm="4">
          <p className="font14  white">Optimism on Core Business</p>
          <p
            className="font18 reducetopmargin"
            color={
              OptCoreBus === "High"
                ? "sophisgreen"
                : OptCoreBus === "Medium"
                ? "sophispale"
                : "sophisred"
            }
          >
            {OptCoreBus}
          </p>
        </IonCol>
        <IonCol size-xs="12" size-sm="4">
          <div className="rightBorder95"></div>
        </IonCol>
      </IonRow>
    </IonGrid>
  ) : null;
};

export default Details;
