import { Plugins } from "@capacitor/core";
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics';
import CryptoJS from 'crypto-js';

const { Storage } = Plugins;
const { Device } = Plugins;

const decrypt = (decryptVlaue) => {
    let passphrase = "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9"
    const bytes = CryptoJS.AES.decrypt(decryptVlaue, passphrase);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  };

const getUserEmail = async () => {
  const ret: any = await Storage.get({ key: "userId" })
  const temp = JSON.parse(decrypt(ret.value))
  return temp && temp.email ? temp.email : null
}
const getUserId = async () => {
  const ret: any = await Storage.get({ key: "userId" });
  return JSON.parse(decrypt(ret.value)).id;
};

const getScreenName = (message) =>{
    

    switch(message.pathname){
        case "/todo":
            return {screenName:'To Do', itemId: ''};
        case "/dashboard":
            return {screenName:'Dashboard', itemId: ''};
        case "/prospects/all":
            return {screenName:'All Prospects', itemId: ''};
        case "/prospects/targets":
            return {screenName:'All Targets', itemId: ''};
        case "/prospect/create":
            return {screenName:'Create Prospect', itemId: ''};
        case "/calendar":
            return {screenName:'Calendar', itemId: ''};
        case "/portfolio":
            return {screenName:'Portfolio', itemId: ''};
        case "/create/todo":
            return {screenName:'Create To Do', itemId: ''};
        case "/viewevent":
            return {screenName:'View Event', itemId: ''};
        case "/clients/all":
            return {screenName:'All Clients', itemId: ''};
            case "/WeeklyVideoSeeMore":
            return {screenName:'Weekly Video See More', itemId: ''};
            case "/WeeklyUpdateVideo":
            return {screenName:'Weekly Update Video', itemId: ''};
            case "/Material":
                return {screenName:'Material Dashboard', itemId: ''};
            case "/DigitalProductUpdate":
                return {screenName:'Digital Product Update', itemId: ''};
            case "/InvestmentUpdate":
            return {screenName:'Investment Update', itemId: ''};
        default:
            if(message.pathname.includes("/contactinfo/")){
                return { screenName:'Profile Screen', itemId:message.pathname.split("/")[2] }
            }
            else if(message.pathname.includes("/prospect/edit/")){
                return { screenName:'Edit Prospect', itemId:message.pathname.split("/")[3] }
            }
            else if(message.pathname.includes("/client/edit/")){
                return { screenName:'Edit Client', itemId:message.pathname.split("/")[3] }
            }
            return{ screenName:'', itemId: '' };
    }

}

export const captureFirebaseEvent = (event, message) =>  {

    // console.log("screenshot event",message, event);
    const { screenName, itemId} = getScreenName(message);

        Device.getInfo().then(deviceInfo => {  
          getUserId().then(userId => {
          getUserEmail().then(useremail => {  
          if(deviceInfo.platform === "web")
              {
                  window.globalThis.firebase.analytics().setUserId(useremail);
                  window.globalThis.firebase.analytics().logEvent('select_content', {
                  user_name: useremail,
                  content_type: 'snapshotDetected',
                  item_id: itemId,
                  screen: screenName,
                  device_id:deviceInfo?deviceInfo.uuid:"",
                  platform:deviceInfo?deviceInfo.platform:"",
                  device_model:deviceInfo?deviceInfo.model:"",
                  device_type:deviceInfo?deviceInfo.osVersion:"",
                  device_os:deviceInfo?deviceInfo.operatingSystem:"",
                  });
              }
          else
              {
                  FirebaseAnalytics.setUserId(useremail);
                  FirebaseAnalytics.logEvent("select_content", {user_name: useremail});
                  FirebaseAnalytics.logEvent("select_content", {content_type: 'snapshotDetected'});
                  FirebaseAnalytics.logEvent("select_content", {item_id: itemId});
                  FirebaseAnalytics.logEvent("select_content", {screen: screenName});
                  FirebaseAnalytics.logEvent("select_content", {device_id: deviceInfo?deviceInfo.uuid:""});
                  FirebaseAnalytics.logEvent("select_content", {platform: deviceInfo?deviceInfo.platform:""});
                  FirebaseAnalytics.logEvent("select_content", {device_model: deviceInfo?deviceInfo.model:""});
                  FirebaseAnalytics.logEvent("select_content", {device_type:deviceInfo?deviceInfo.osVersion:""});
                  FirebaseAnalytics.logEvent("select_content", {device_os:deviceInfo?deviceInfo.operatingSystem:""});
              }
            
            })
          })
        })
    
    }