import React from "react";
import {
  IonCard,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
  IonSlide,
  IonSlides,
  IonText,
} from "@ionic/react";
import useWindowDimensions from "src/components/useWindowDimensions";

const styles = {};

const slideOpts = {
  initialSlide: 0.5,
  speed: 400,
  // slidesPerView: 2,
  //slidesPerGroup: 4,
  // centeredSlides: true,
  // centeredSlidesBounds: true,
  width: 300,
  freeMode: true,
  coverflowEffect: {
    rotate: 50,
    stretch: 0,
    depth: 100,
    modifier: 1,
    slideShadows: true,
  },
  // spaceBetween: 16,
};

const PersonaBreakdown = (props) => {
  // const [totPersona,settotPersona] = useState<any>(0);

  const { height, width } = useWindowDimensions();

  let perscnt: number = 0;
  if (props.personaData) {
    for (let i: number = 0; i < props.personaData.length; i++) {
      perscnt = perscnt + parseInt(props.personaData[i].count);
    }
    //settotPersona(perscnt);
  }
  //settotPersona(perscnt);
  // useEffect(() => {

  // }, [perscnt])
  // props.personaData && props.personaData.map((val, ind) =>
  // {
  //     perscnt =  perscnt+ parseInt(val.count);
  //     settotPersona(perscnt);
  // }
  // );
  return props.personaData ? (
    props.personaData.length > 0 ? (
      width > 575 ? (
        <IonSlides options={slideOpts}>
          {props.personaData.map((val, ind) => (
            <IonSlide
              key={ind}
              className="spaceAlingRight"
              style={{ width: "300px" }}
            >
              <IonCard className="schCard CardPersonas">
                <IonItem lines="none" style={{ "--padding-start": "10px" }}>
                  <IonGrid style={{ textAlign: "center" }}>
                    <IonRow className="titleRowPersona">
                      <IonText
                        className="cardTitleGeo"
                        style={{ fontSize: 43, fontWeight: 300 }}
                      >
                        {((parseInt(val.count) * 100) / perscnt).toFixed(1)}%
                      </IonText>
                      <IonText class="cardSubTitleGeo grayscale4">
                        ({val.count})
                      </IonText>
                    </IonRow>
                    <IonRow>
                      <IonText class="cardCountryGeo grayscale4">
                        {val.persona ? val.persona : "-"}
                      </IonText>
                    </IonRow>
                  </IonGrid>
                </IonItem>
              </IonCard>
            </IonSlide>
          ))}
        </IonSlides>
      ) : (
        <React.Fragment>
          <IonRow class="margintopn20">
            {props.personaData.map((val, ind) => (
              <IonCol
                size-xs="12"
                size-sm="3"
                style={{
                  visibility: "visible",
                  margin: "auto",
                }}
                className="spaceAlingRight"
              >
                <IonCard className="schCard CardGeo CardGeoMobile">
                  <IonItem lines="none">
                    <IonGrid style={{ textAlign: "center" }}>
                      <IonRow className="titleRowPersona">
                        <IonText
                          className="cardTitleGeo"
                          style={{ fontSize: 43, fontWeight: 300 }}
                        >
                          {((parseInt(val.count) * 100) / perscnt).toFixed(1)}%
                        </IonText>
                        <IonText class="cardSubTitleGeo grayscale4">
                          ({val.count})
                        </IonText>
                      </IonRow>
                      <IonRow>
                        <IonText class="cardCountryGeo grayscale4">
                          {val.persona ? val.persona : "-"}
                        </IonText>
                      </IonRow>
                    </IonGrid>
                  </IonItem>
                </IonCard>
              </IonCol>
            ))}
          </IonRow>
        </React.Fragment>
      )
    ) : (
      <IonRow style={{ marginTop: 32 }}>
        <IonLabel color="greyscale">No data found</IonLabel>
      </IonRow>
    )
  ) : (
    <IonRow style={{ marginTop: 32 }}>
      <IonLabel color="greyscale">No data found</IonLabel>
    </IonRow>
  );
};

export default PersonaBreakdown;
