import React, { useEffect, useState } from "react";
import {
  IonLoading,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButton,
  IonButtons,
  IonIcon,
  IonPage,
  IonText,
  IonRow,
  IonCol,
  IonGrid,
} from "@ionic/react";
import { chevronBack } from "ionicons/icons";
import InvestmentCard from "../../../components/Material/InvestmentCard";
import api from "src/api";
const styles = {
  toolbar: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
  },
};
export default function InvestmentUpdate() {
  const backButtonText = "Material ";
  const [digitalUpdateData, setDigitalUpdateData] = useState<any>([]);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    const getDigitalUpdates = () => {
      return api
        .getDigitalUpdate()
        .then((response) => {
          if (response.stories.length > 0) {
            const data = response.stories;
            const sortedData = data.sort(function(a, b) {
              return (
                new Date(a.content.PublishDate).valueOf() -
                new Date(b.content.PublishDate).valueOf()
              );
            });
            setDigitalUpdateData(sortedData.reverse());
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    };
    getDigitalUpdates();
  }, []);
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode="md" style={styles.toolbar}>
          <IonButtons slot="start">
            <IonButton
              style={{ textTransform: "none" }}
              color="white"
              // routerLink="/dashboard"
              routerLink="/material"
            >
              <IonIcon icon={chevronBack}></IonIcon>
              {backButtonText}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent
        style={{
          "--background": "none",
        }}
      >
        <IonLoading
          cssClass="my-custom-loading-class"
          isOpen={isLoading}
          onDidDismiss={() => setLoading(false)}
          message={"Please wait..."}
        />
        <IonGrid class="" style={{ marginTop: 24 }}>
          <IonRow
            class="mt-6 margin-set mobile-top-content m-marginleft-12 m-marginright-12 dashgrid-mobile"
            style={{ margin: "24px 32px" }}
          >
            <IonRow
              class="mt-6 mobile-top-content m-marginleft-12"
              style={{ width: "100%", marginBottom: 20 }}
            >
              <IonText
                style={{
                  fontWeight: 300,
                  fontSize: 24,
                  lineHeight: "120%",
                  color: "#C7C7C7",
                }}
              >
                Digital Product Updates
              </IonText>
            </IonRow>
            <IonGrid style={{ padding: "0px" }}>
              <IonRow>
                {digitalUpdateData.map((item) => (
                  <IonCol
                    style={{ paddingLeft: "0px" }}
                    size="12"
                    size-md="6"
                    size-xl="4"
                  >
                    <InvestmentCard
                      imgSrc={item.content.BannerImage.filename}
                      subtitle={"title"}
                      title={item.content.Title}
                      publishDate={item.content.PublishDate.substr(0, 10)}
                      routerPath={
                        item.content.Document
                          ? `/investmentProductDetails/${item.id}`
                          : "No Pdf file found"
                      }
                      filename={
                        item.content.Document
                          ? item.content.Document.filename
                          : ""
                      }
                      section={"Digital"}
                    />
                  </IonCol>
                ))}
              </IonRow>
            </IonGrid>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
}
