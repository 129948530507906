import {
  IonGrid,
  IonItem,
  IonIcon,
  IonLabel,
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCol,
  IonRow,
  IonText,
  IonCardContent,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import "./Engagement.css";
import engagementapp from "src/icons/engagement-app.svg";
import engagementemail from "src/icons/engagement-email.svg";
import engagementform from "src/icons/engagement-form.svg";
import engagementwebvisit from "src/icons/engagement-webvisit.svg";
import { ellipse } from "ionicons/icons";
import moment from "moment";
import api from "src/api";
import useWindowDimensions from "src/components/useWindowDimensions";

const styles = {
  period: {
    fontSize: 6,
    marginLeft: 4,
    marginRight: 4,
    marginTop: 5,
    color: "#828282",
  }
};

function camelize(str) {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
        return index === 0 ? word.toUpperCase() : word.toLowerCase();
      })
      .replace(/\s+/g, "");
  }

const Timeline: React.FC<any> = (props) => {

    const [productsVisited, setProductsVisited] = useState<any>([])
    const [apiResp, setApiResp] = useState<any>()
    const { height, width } = useWindowDimensions();

useEffect(()=>{
    api.getCDPEngagement(props.contactId).then((resp)=>{
      // api.getCDPEngagement("viz_5f6afe1a55b0d").then((resp)=>{
        // console.log(resp)
        setApiResp(resp.data)
        setProductsVisited(resp.data.products_visited)
    })
    .catch((e) => {
      // console.log(JSON.stringify(e))
    })
},[props.contactId])

  return (
    <React.Fragment>
      <IonItem class="ion-margin-start ion-margin-end ml-13 mr-13" lines="none">
        Overview
      </IonItem>
      <IonGrid class="ion-margin-start ion-margin-end ml-13 mr-13">
        <IonRow class="mobile-padding0">
          <IonCol size-xs="12" size-sm="4">
            <IonCard className="firstLeft firstLeftDesk firstLeft-mobile firstLeftEng" style={{ position: "relative" }}>
              <IonCardHeader
                color="white"
                style={{
                  color: "#fff",
                  fontWeight: "300",
                  paddingTop: 32,
                }}
              >
                First Website Visit Date
              </IonCardHeader>
              <IonCardContent
                color="greyscale"
                style={{
                  textTransform: "none",
                  fontWeight: "400",
                  fontSize: 14,
                }}
              >
                {apiResp && apiResp.first_seen_ts ? moment(apiResp.first_seen_ts * 1000).format("ll") : "-"}
              </IonCardContent>
            </IonCard>
          </IonCol>
          <IonCol size-xs="12" size-sm="4">
            <IonCard className="firstLeft firstLeft-mobile firstLeftDesk firstLeftEng">
              <IonCardHeader
                color="white"
                style={{
                  color: "#fff",
                  fontWeight: "300",
                  paddingTop: 32,
                }}
              >
                City/Country
              </IonCardHeader>
              <IonCardContent
                color="greyscale"
                style={{
                  textTransform: "none",
                  fontWeight: "400",
                  fontSize: 14,
                }}
              >
                {apiResp && apiResp.city ? apiResp.city : "-"}
              </IonCardContent>
            </IonCard>
          </IonCol>
          <IonCol size-xs="12" size-sm="4">
            <IonCard className="firstLeftNr firstLeftDesk firstLeftEng">
              <IonCardHeader
                color="white"
                style={{
                  color: "#fff",
                  fontWeight: "300",
                  paddingTop: 32,
                }}
              >
                Web Browser
              </IonCardHeader>
              <IonCardContent
                color="greyscale"
                style={{
                  textTransform: "none",
                  fontWeight: "400",
                  fontSize: 14,
                }}
              >
                {apiResp && apiResp.browser ? apiResp.browser : "-"}
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
        <IonRow class="mobile-padding0">
          <IonCol size-xs="12" size-sm="4">
            <IonCard className="firstLeft firstLeftDesk firstLeft-mobile firstLeftEng" style={{ position: "relative" }}>
              <IonCardHeader
                color="white"
                style={{
                  color: "#fff",
                  fontWeight: "300",
                  paddingTop: 32,
                }}
              >
                Last URL Visited
              </IonCardHeader>
              <IonCardContent
                color="greyscale"
                style={{
                  textTransform: "none",
                  fontWeight: "400",
                  fontSize: 14,
                }}
              >
                {apiResp && apiResp.LastURL ? apiResp.LastURL : "-"}
              </IonCardContent>
            </IonCard>
          </IonCol>
          <IonCol size-xs="12" size-sm="4">
            <IonCard className="firstLeft firstLeft-mobile firstLeftDesk firstLeftEng">
              <IonCardHeader
                color="white"
                style={{
                  color: "#fff",
                  fontWeight: "300",
                  paddingTop: 32,
                }}
              >
                Visits in Last 15 Days
              </IonCardHeader>
              <IonCardContent
                color="greyscale"
                style={{
                  textTransform: "none",
                  fontWeight: "400",
                  fontSize: 14,
                }}
              >
                {apiResp && apiResp.sessions_15days ? apiResp.sessions_15days : "-"} sessions
              </IonCardContent>
            </IonCard>
          </IonCol>
          <IonCol size-xs="12" size-sm="4">
            <IonCard className="firstLeftNr firstLeftDesk firstLeftEng">
              <IonCardHeader
                color="white"
                style={{
                  color: "#fff",
                  fontWeight: "300",
                  paddingTop: 32,
                }}
              >
                Pages Visited in Last 15 Days
              </IonCardHeader>
              <IonCardContent
                color="greyscale"
                style={{
                  textTransform: "none",
                  fontWeight: "400",
                  fontSize: 14,
                }}
              >
                {apiResp && apiResp.page_visits_15days ? apiResp.page_visits_15days : "-"} pages
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
        <IonRow class="mobile-padding0">
          <IonCol size-xs="12" size-sm="4">
            <IonCard className="firstLeft firstLeftDesk firstLeft-mobile firstLeftEng" style={{ position: "relative" }}>
              <IonCardHeader
                color="white"
                style={{
                  color: "#fff",
                  fontWeight: "300",
                  paddingTop: 32,
                }}
              >
                Last Website Visited
              </IonCardHeader>
              <IonCardContent
                color="greyscale"
                style={{
                  textTransform: "none",
                  fontWeight: "400",
                  fontSize: 14,
                }}
              >
                ({apiResp && apiResp.last_seen_ts ? moment(apiResp.last_seen_ts * 1000).format("ll") : "-"})
              </IonCardContent>
            </IonCard>
          </IonCol>
          <IonCol size-xs="12" size-sm="4">
            <IonCard className="firstLeft firstLeft-mobile firstLeftDesk firstLeftEng">
              <IonCardHeader
                color="white"
                style={{
                  color: "#fff",
                  fontWeight: "300",
                  paddingTop: 32,
                }}
              >
                Total Visits
              </IonCardHeader>
              <IonCardContent
                color="greyscale"
                style={{
                  textTransform: "none",
                  fontWeight: "400",
                  fontSize: 14,
                }}
              >
                {apiResp && apiResp.sessions_total ? apiResp.sessions_total : "-"} sessions
              </IonCardContent>
            </IonCard>
          </IonCol>
          <IonCol size-xs="12" size-sm="4">
            <IonCard className="firstLeftNr firstLeftDesk firstLeftEng">
              <IonCardHeader
                color="white"
                style={{
                  color: "#fff",
                  fontWeight: "300",
                  paddingTop: 32,
                }}
              >
                Total Page Visits
              </IonCardHeader>
              <IonCardContent
                color="greyscale"
                style={{
                  textTransform: "none",
                  fontWeight: "400",
                  fontSize: 14,
                }}
              >
                {apiResp && apiResp.page_visits_total ? apiResp.page_visits_total : "-"} pages
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonItem class="ion-margin-start ion-margin-end ml-13 mr-13" lines="none">
        List of Pages
      </IonItem>
      {width > 576 ?
      (<IonGrid class="ion-margin-start ion-margin-end ml-13 mr-13">
        <IonItem
          class="ion-margin-start ion-margin-end"
          lines="none"
          style={{ "--background": "#2a2a2a" }}
        >
          <IonRow style={{ width: "100%" }}>
            <IonCol size="2">
              <IonText color="greyscale" style={{ fontWeight: "400" }}>
                #
              </IonText>
            </IonCol>
            <IonCol size="4">
              <IonText color="greyscale" style={{ fontWeight: "400" }}>
                Pages Visited
              </IonText>
            </IonCol>
            <IonCol size="3">
              <IonText color="greyscale" style={{ fontWeight: "400" }}>
                Frequency of Visits
              </IonText>
            </IonCol>
            <IonCol size="3">
              <IonText color="greyscale" style={{ fontWeight: "400" }}>
                Last Visited Date
              </IonText>
            </IonCol>
          </IonRow>
        </IonItem>
        {productsVisited ? (productsVisited.map((obj , i) =>
        <IonItem key={i}
          class="ion-margin-start ion-margin-end"
          lines="none"
          style={{ "--background": "#222222", marginTop:"2px" }}
        >
          <IonRow style={{ width: "100%" }}>
            <IonCol size="2">
            <IonText color="white" style={{ fontWeight: "300" }}>
                {i+1}.
              </IonText>
            </IonCol>
            <IonCol size="4">
              <IonText color="white" style={{ fontWeight: "300" }}>
                {camelize(obj.name).replace(/_/g, ' ')}
              </IonText>
            </IonCol>
            <IonCol size="3">
              <IonText color="greyscale" style={{ fontWeight: "300" }}>
                {obj.count} visits
              </IonText>
            </IonCol>
            <IonCol size="3">
              <IonText color="greyscale" style={{ fontWeight: "300" }}>
                {moment(new Date(obj.ts * 1000)).format("ll")}
              </IonText>
            </IonCol>
          </IonRow>
        </IonItem>
        )):null}
      </IonGrid>
      ):
      (
        <IonGrid class="ion-grid-margin-mobile">
        <IonItem
          lines="none"
          style={{ "--background": "#2a2a2a" }}
        >
          <IonRow style={{ width: "100%" }}>
            <IonCol size="2">
              <IonText color="greyscale" style={{ fontWeight: "400" }}>
                #
              </IonText>
            </IonCol>
            <IonCol size="10">
              <IonText color="greyscale" style={{ fontWeight: "400" }}>
                Pages Visited
              </IonText>
            </IonCol>
          </IonRow>
        </IonItem>
        {productsVisited ? (productsVisited.map((obj , i) =>
        <IonItem key={i}
          lines="none"
          style={{ "--background": "#222222", marginTop:"2px" }}
        >
          <IonRow style={{ width: "100%" }}>
            <IonCol size="2">
            <IonText color="white" style={{ fontWeight: "300", margin:"auto" }}>
                {i+1}.
              </IonText>
            </IonCol>
            <IonCol size="10">
              <IonRow>
                  <IonText color="white" style={{ fontWeight: "300" }}>
                  {camelize(obj.name).replace(/_/g, ' ')}
                </IonText>
              </IonRow>
              <IonRow>
                  <IonText color="greyscale" style={{ fontWeight: "300" }}>
                    {obj.count} visits
                  </IonText>
                  <IonIcon icon={ellipse} style={styles.period} class="elipse-mobile"/>
                  <IonText color="greyscale" style={{ fontWeight: "300" }}>
                    {moment(obj.ts * 1000).format("ll")}
                  </IonText>
              </IonRow> 
            </IonCol>
          </IonRow>
        </IonItem>
        )):null}
      </IonGrid>
      )}
      <IonItem class="ion-margin-start ion-margin-end ml-13 mr-13" lines="none"></IonItem>

      {/* <IonItem class="ion-margin-start ion-margin-end ml-13 mr-13">November 2020</IonItem>
      <IonGrid class="ion-margin-start ion-margin-end ml-13 mr-13">
        <IonItem lines="none">
          <IonIcon
            style={{ width: 36, height: 36 }}
            icon={engagementemail}
            slot="start"
          />
          <IonLabel>
            <p className="white boldText">Opened Email from TFO</p>
            <p className="grayscale">Tue, Nov 22, 2020 . 1:15 pm</p>
          </IonLabel>
        </IonItem>
      </IonGrid>
      <IonItem
        lines="none"
        style={{ marginLeft: 57, borderLeft: "1px solid #fff" }}
      ></IonItem>

      <IonGrid class="ion-margin-start ion-margin-end">
        <IonItem lines="none">
          <IonIcon
            style={{ width: 36, height: 36 }}
            color="red"
            icon={engagementapp}
            slot="start"
          />
          <IonLabel>
            <p className="white boldText">Opened Client App</p>
            <p className="grayscale">Tue, Nov 15, 2020 . 3:15 pm</p>
          </IonLabel>
        </IonItem>
      </IonGrid>
      <IonGrid class="ion-margin-end">
        <IonItem class="ion-margin-start">October 2020</IonItem>
        <IonGrid class="ion-margin-start ion-margin-end">
          <IonItem lines="none">
            <IonIcon
              style={{ width: 36, height: 36 }}
              icon={engagementwebvisit}
              slot="start"
            />
            <IonLabel>
              <p className="white boldText">Visited TFO Website</p>
              <p className="grayscale">Tue, Oct 12, 2020 . 6:15 pm</p>
            </IonLabel>
          </IonItem>
        </IonGrid>
        <IonItem
          lines="none"
          style={{ marginLeft: 57, borderLeft: "1px solid #fff" }}
        ></IonItem>

        <IonGrid class="ion-margin-start ion-margin-end">
          <IonItem lines="none">
            <IonIcon
              style={{ width: 36, height: 36 }}
              icon={engagementform}
              slot="start"
            />
            <IonLabel>
              <p className="white boldText">Filled out "Request a call" form</p>
              <p className="grayscale">Tue, Oct 12, 2020 . 6:15 pm</p>
            </IonLabel>
          </IonItem>
        </IonGrid>
        <IonItem
          lines="none"
          style={{ marginLeft: 57, borderLeft: "1px solid #fff" }}
        ></IonItem>

        <IonGrid class="ion-margin-start ion-margin-end">
          <IonItem lines="none">
            <IonIcon
              style={{ width: 36, height: 36 }}
              icon={engagementemail}
              slot="start"
            />
            <IonLabel>
              <p className="white boldText">Opened Email from TFO</p>
              <p className="grayscale">Tue, Nov 22, 2020 . 1:15 pm</p>
            </IonLabel>
          </IonItem>
        </IonGrid>
        <IonItem
          lines="none"
          style={{ marginLeft: 57, borderLeft: "1px solid #fff" }}
        ></IonItem>

        <IonGrid class="ion-margin-start ion-margin-end">
          <IonItem lines="none">
            <IonIcon
              style={{ width: 36, height: 36 }}
              color="red"
              icon={engagementapp}
              slot="start"
            />
            <IonLabel>
              <p className="white boldText">Opened Client App</p>
              <p className="grayscale">Tue, Nov 15, 2020 . 3:15 pm</p>
            </IonLabel>
          </IonItem>
        </IonGrid>
      </IonGrid> */}
    </React.Fragment>
  );
};

export default Timeline;
