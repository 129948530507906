import React, { RefObject, useEffect, useState } from "react";
import {
  IonCol,
  IonGrid,
  IonRow,
  IonLabel,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonIcon,
} from "@ionic/react";
import { chevronDown } from "ionicons/icons";
import PortfolioLineChart from "./PortfolioLineChart"
import useWindowDimensions from "src/components/useWindowDimensions";
import PortfolioBarLineChart from "./PortfolioBarLineChart";

const styles = {
  rootGrid: {
    marginTop: 24,
  },
  colbackL: {
    height: 384,
    // background: "url(/assets/mesh.svg) no-repeat 100px 0px",
    backgroundColor: "#1A1A1A",
    paddingRight: 7.5,
  },
  colbackR: {
    height: 384,
    // background: "url(/assets/mesh.svg) no-repeat 100px 0px",
    backgroundColor: "#1A1A1A",
    paddingLeft: 7.5,
  },
  innerGrid: {
    position: "relative",
    zIndex: 2,
  },
  titleRow: {
    position: "absolute",
    top: 27,
    marginLeft: 40,
    display: "flex",
    flexDirection: "column",
  },
  dropdownRow: {
    position: "absolute",
    top: 11.5,
    marginLeft: 40,
  },
  title: {
    fontSize: 18,
    fontWeight: "normal",
  },
  datedwItem: {
    marginTop: 11.5,
    "--padding-start": 0,
    color: "#b99855",
    fontSize: 14,
    "--color-activated": "none",
    "--background-activated": "none",
  },
  moneyRow: {
    position: "relative",
  },
  moneyIcon: {
    position: "absolute",
    height: 22,
    left: -20,
    top: 10,
  },
  moneyLabel: {
    fontWeight: 300,
    fontSize: 43,
    marginBottom: 12,
  },
  iconrow: {
    display: "flex",
    marginBottom: 8,
  },
  iconlabel: {
    color: "#AAAAAA",
    marginLeft: 8,
    fontSize: 14,
    marginTop: "auto",
    marginBottom: "auto",
  },
};

const decimalFunc = (Num) => {
    let temp = Num.toString().split(".")
    let retVal = 0
    if(temp[1]){
        retVal = Num.toFixed(2)
    } else {
        retVal = Num
    }
    return retVal
}
// const retTotalAmt = (clsAmt) => {
//     let closingamt_disp: number = 0;
//     (Number.isNaN(clsAmt))|| clsAmt === null || clsAmt === "" ? closingamt_disp = 0 : closingamt_disp = parseInt(clsAmt)
//     return Math.abs(Number(closingamt_disp)) >= 1.0e+9

//         ? decimalFunc(Math.abs(Number(closingamt_disp)) / 1.0e+9) + "b"
//         // Six Zeroes for Millions 
//         : Math.abs(Number(closingamt_disp)) >= 1.0e+6

//             ? decimalFunc(Math.abs(Number(closingamt_disp)) / 1.0e+6) + "m"
//             // Three Zeroes for Thousands
//             : Math.abs(Number(closingamt_disp)) >= 1.0e+3

//                 ? decimalFunc(Math.abs(Number(closingamt_disp)) / 1.0e+3) + "k"

//                 : decimalFunc(Math.abs(Number(closingamt_disp)));
// }
// const retTotalAmt = (Total) => {
//   let total_disp: number = 0;
//   Total === null || Total === ""
//     ? (total_disp = 0)
//     : (total_disp = parseInt(Total));
//   return Math.abs(Number(total_disp)) >= 1.0e9
//     ? Math.abs(Number(total_disp)) / 1.0e9 + "b"
//     : // Six Zeroes for Millions
//     Math.abs(Number(total_disp)) >= 1.0e6
//     ? Math.abs(Number(total_disp)) / 1.0e6 + "m"
//     : // Three Zeroes for Thousands
//     Math.abs(Number(total_disp)) >= 1.0e3
//     ? Math.abs(Number(total_disp)) / 1.0e3 + "k"
//     : Math.abs(Number(total_disp));
// };


// const retTotalAmt = (Total) => {
//     let total_disp: number = 0
//     Total === null || Total === "" ? total_disp = 0 : total_disp = parseInt(Total)
//     return Math.abs(Number(total_disp)) >= 1.0e+9

//         ? Math.abs(Number(total_disp)) / 1.0e+9 + "b"
//         // Six Zeroes for Millions 
//         : Math.abs(Number(total_disp)) >= 1.0e+6

//             ? Math.abs(Number(total_disp)) / 1.0e+6 + "m"
//             // Three Zeroes for Thousands
//             : Math.abs(Number(total_disp)) >= 1.0e+3

//                 ? Math.abs(Number(total_disp)) / 1.0e+3 + "k"

//                 : Math.abs(Number(total_disp));
// }

const optionsA = {
  cssClass: "my-custom-interface",
};

const PortfolioChartInstance: React.FC<any> = ({
  timeDropDown,
  CardTitle,
  Total,
  Chartname,
  IconsObj,
  chartData,
  setTimeFrame,
  tf,

}) => {
  //console.log(setTimeFrame);
  //console.log(tf);
  const [renderLine, setrenderLine] = useState<boolean>(false);
  const { height, width } = useWindowDimensions();
//console.log(tempprop);
  useEffect(() => {
    chartData && chartData[0] && chartData[0].length > 0
      ? setrenderLine(true)
      : setrenderLine(false);
  }, [chartData]);

    
  const contentRef: RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
  useEffect(() => {
      const styles = document.createElement('style')
      styles.textContent = `
      ::-webkit-scrollbar {
        width: 10px !important;
        height: 10px !important;
      }
      ::-webkit-scrollbar-track {
        background: transparent !important;
      }
      ::-webkit-scrollbar-thumb {
        background: transparent !important;
        border-radius: 10px !important;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: #555 !important;
      }
    `;
    
    // contentRef.current?.shadowRoot?.appendChild(styles)
    contentRef.current?.appendChild(styles);
  }, [])

  return (
    <IonCol  size-xs="12" sizeLg="6" size-sm="6">
      {/* <Axis /> */}
      <IonGrid style={styles.innerGrid}>
        <IonRow style={styles.titleRow}>
          <IonLabel class="fs-18" style={styles.title}>{CardTitle}</IonLabel>
          <IonItem lines="none" style={styles.datedwItem}>
            <IonSelect
              interface={width < 576 ? "action-sheet" : "popover"}
              interfaceOptions={optionsA}
              mode="ios"
              value={tf}
              onIonChange={(e) => {
                // setselTime(e.detail.value)
                setTimeFrame(e.detail.value);
              }}
              name="time"
            >
              {timeDropDown.map(({ val }, i) => (
                <IonSelectOption key={i} value={val}>
                  {val}
                </IonSelectOption>
              ))}
            </IonSelect>
            <IonIcon slot="end" color="gold" src={chevronDown}></IonIcon>
          </IonItem>
          {/* <IonRow style={styles.moneyRow}>
            <IonIcon  class="mobile-dollar" icon={dollar} color="white" style={styles.moneyIcon} />
            <IonLabel class="mobile-moneylabel" style={styles.moneyLabel}>{retTotalAmt(Total)}</IonLabel>
          </IonRow> */}
          {IconsObj.map((obj) => (
            <IonRow key={obj.label} style={styles.iconrow}>
              {obj.icon}
              <IonLabel style={styles.iconlabel}>{obj.label}</IonLabel>
            </IonRow>
          ))}
          <IonRow></IonRow>
        </IonRow>
      </IonGrid>
      <div 
      ref={contentRef} 
      style={styles.colbackL}>
     { CardTitle == "Project Returns" && renderLine ?                        
                        <PortfolioBarLineChart
                        Chartname = {Chartname}
                            width={700}
                            height={384}
                            chartData={chartData}
                        />: 

      CardTitle=="Impact of Reinvesting" &&  renderLine ? 
          <PortfolioLineChart 
          Chartname = {Chartname}
          tf={tf}
          width={700} 
          height={384}
          chartData={chartData} />:null
        }
        <div className="chartFade"></div>
        <div className="chartOverlay"></div>
      </div>
    </IonCol>
  );
};

export default PortfolioChartInstance;
