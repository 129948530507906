import React, { useEffect, useState } from 'react';
import {
    IonMenu,
    IonHeader,
    IonToolbar,
    IonContent,
    IonButton,
    IonIcon,
    IonTitle,
    IonButtons,
    IonLabel,
    IonCheckbox,
    IonRow,
    IonText,
    IonFooter,
    IonMenuButton,
} from '@ionic/react';
import { close } from "ionicons/icons"
import "./FilterMenu.css"
const styles = {
    sortby: {
        marginTop: 6,
        backgroundColor: "#222222",
        paddingLeft: 8,
        paddingRight: 16
    },
    applybtn: {
        borderRadius: 0,
        textTransform: "none",
        marginRight: "2"
    },
    footer: {
        backgroundColor: "#1a1a1a",
        padding: 24
    },
    selectItem: {
        "--padding-start": "0px",
        backgroundColor: "#222222",
        marginTop: 8
    },
    root: {
        padding: 27
    },
    select: {
        "--padding-start": "8px",
    },
    label: {
        // marginBottom: 8
    },
    radiolist: {
        "--padding-start": "0px"
    },
    listHeader: {
        fontSize: 14,
        paddingInlineStart: 0,
        marginBottom: 8,
        maxHeight: 0,
        fontWeight: "normal"
    }
}

// const SortArr: any =   [
//     {
//         val: "Interaction Date (Newest to Oldest)",
//     },
//     {
//         val: "Closing Date (Soonest to Furthest)",
//     },
//     {
//         val: "Probability of Closing",
//     },
//     {
//         val: "Commitment forcast (high to low)",
//     },
// ]

// const optionsA = {
//     cssClass: 'my-custom-interface'
// };

interface FilProps {
    activeFilters: Array<string>;
    setActiveArrayFunc: Function;
    clrfil: boolean;
    setFilterFields: Function;
    trigClr: Function;
    sfilterCheckboxes: any;
    trigClrfromChips: any;
}

const FilterMenu: React.FC<any> = ({
    activeFilters,
    setActiveArrayFunc,
    clrfil,
    setFilterFields,
    trigClr,
    sfilterCheckboxes,
    trigClrfromChips
}) => {
    const [actArr, setactArr] = useState<Array<string>>([])
    const [isMenuOpen, setisMenuOpen] = useState<boolean>(false)
    const [selSort, setselSort] = useState<string>("Interaction Date (Newest to Oldest)")
    // const [sfilterCheckboxes, setsfilterCheckboxes] = useState<any>(filterCheckboxes)


    useEffect(()=>{
        if(trigClrfromChips !== 0) {
            trigClr()
            setactArr([])
            setselSort("Interaction Date (Newest to Oldest)")
        }
    },[trigClrfromChips])

    const Clear = () => {
        trigClr()
        setactArr([])
        setselSort("Interaction Date (Newest to Oldest)")
    }

    useEffect(() => {
        if(!isMenuOpen && clrfil) {
            setactArr([])
            setselSort("Interaction Date (Newest to Oldest)")
        }
    }, [clrfil,isMenuOpen])
    
    const addToActArray = (str: string) => {
        if(isMenuOpen) {
            setactArr([
                ...actArr,
                str
            ])
        }
    }
    const remFromActArray = (str: string) => {
        if(isMenuOpen) {
            setactArr(actArr.filter(a => a !== str))
        }
    }

    const ApplyChanges = () => {
        let tempObj = 
            { 
                Type : {
                    vals: []
                },
                Priority : {
                    vals: []
                }
            }   
        actArr.forEach(sel => {
            let currVal=""
            let currKey=""
            sfilterCheckboxes.forEach(par => {
                par.options.forEach(o => {
                    if(o === sel) {
                        currVal = sel
                        currKey = par.label
                        tempObj[currKey].vals.push(currVal)
                    }
                })
            })
        })
        setFilterFields(tempObj, selSort)
        setActiveArrayFunc(actArr)
    }

    return (
        <React.Fragment>
            <IonMenu side="end" menuId="first" contentId="todo-menu-id" style={{"--width": "327px"}}
                onIonDidOpen={() => {setisMenuOpen(true)}}
                onIonDidClose={() => {setisMenuOpen(false)}}
            >
                <IonHeader>
                    <IonToolbar className="filtermenu-toolbar">
                        <IonButtons
                            slot="start"
                        >
                            <IonMenuButton>
                                <IonButton
                                    fill="clear"
                                >
                                    <IonIcon
                                        icon={close}
                                        style={{ color: "#fff" }}
                                    ></IonIcon>
                                </IonButton>
                            </IonMenuButton>
                        </IonButtons>
                        <IonTitle>
                            Filters
                        </IonTitle>
                        <IonButtons
                            slot="end"
                            >
                            <IonMenuButton>
                                <IonButton
                                    fill="clear"
                                    mode="ios"
                                    size="small"
                                    color="gold"
                                    onClick={Clear}
                                    >
                                    Clear
                                </IonButton>
                            </IonMenuButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent id="filter-menu">
                    {/* <IonLabel class="font14" style={styles.label}>Sort By</IonLabel>
                        <IonItem lines="none" style={styles.selectItem}>
                            <IonSelect 
                                interfaceOptions={optionsA}
                                style={styles.select}
                                value={selSort}
                                onIonChange={(e) => {
                                    setselSort(e.detail.value)
                                }}
                                name="salutation"
                                mode="ios"
                                class="customIonSelect"
                                placeholder="Interaction Date (Newest to Oldest)"
                                interface="popover"
                            >
                                {SortArr
                                .map(({ val }, i) =>
                                    <IonSelectOption key={i} value={val}>{val}</IonSelectOption>
                                )}
                            </IonSelect>
                            <IonIcon slot="end" icon={chevronDown} color="gold" />
                        </IonItem> */}
                    {
                        sfilterCheckboxes.map(fil =>
                            <CheckBoxOptions
                                key={fil.id}
                                label={fil.label}
                                optionsArr={fil.options}
                                actArr={actArr}
                                addToActArr={(str) => {addToActArray(str)}}
                                remFromActArr={(str) => {remFromActArray(str)}}
                            />
                        )
                    }
                </IonContent>
                <IonFooter className="ion-no-borders" style={styles.footer}>
                    <IonMenuButton>
                        <IonButton
                            fill="outline"
                            mode="md"
                            color="gold"
                            style={styles.applybtn}
                            onClick={() => { ApplyChanges() }}
                        >
                            Apply Changes
                        </IonButton>
                    </IonMenuButton>
                </IonFooter>
            </IonMenu>
        </React.Fragment>

    )


};

const CheckBoxOptions = ({
    label,
    optionsArr,
    actArr,
    addToActArr,
    remFromActArr,
}) => {
    return (
        <div style={{ marginTop: 32 }}>
            <IonLabel style={{ fontWeight: 400, marginBottom: 2 }}>{label}</IonLabel>
            {
                optionsArr.map((val , i) =>
                    <IonRow key={i} style={{ marginTop: 8 }}>
                        <IonCheckbox
                            color="actblue"
                            mode="md"
                            checked={actArr.includes(val)}
                            class="ion-color-actblue-v1"
                            value={val}
                            style={{ width: 20, height: 20, "--background": "#1A1A1A" }}
                            onIonChange={(e) => {
                                const detChecked = e.detail.checked;
                                if(detChecked) {
                                    addToActArr(val)
                                } else {
                                    remFromActArr(val)
                                }

                            }}
                        />
                        <IonText
                            style={{ marginLeft: 8, marginRight: 8, fontsize: 14, fontweight: 500, color: "#c7c7c7" }}
                        >
                            {label === "Wealth Range" ?  `$${val}`: val}
                        </IonText>
                    </IonRow>
                )
            }
        </div>
    )
}

export default FilterMenu