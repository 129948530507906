import axios from "axios";
import contactprops from "./contactprops";
import { batchinput } from "./batchproperties";
import { Plugins } from "@capacitor/core";
import { meetingNoteApiRespSingle, meetingNotePayload } from "src/types";
import {
  getNotesByProspId,
  createNewMeetingNote,
  getNoteById,
  updateNoteById,
  deleteNoteById,
} from "src/offlineUtils/DbOperations";
import {
  updateProspectDb,
  createProspectDb,
  filterProspectsDb,
  Func_getProspectById,
} from "src/offlineUtils/ProspectDbOperations";
import { getOwnersDb } from "src/offlineUtils/UserOperations";
import history from "./history";

//let baseURI = 'https://apigw-weu-tfo-capp-dev.azure-api.net/rmapp/uat/api/v1';//new prod
let baseURI =
  "https://apigw-weu-tfo-capp-dev.azure-api.net/rmapp/production/api/v1";
// let baseURI = "https://app-rmapp-weu-dev.azurewebsites.net"; //latest 2023
//let baseURI = 'http://localhost:8080/api/v1';//new prod
process.env.REACT_APP_BACKEND_BASEURI
  ? (baseURI = process.env.REACT_APP_BACKEND_BASEURI)
  : //baseURI = 'https://qgvbps29l0.execute-api.eu-west-1.amazonaws.com/staging/api/v1'; //new staging
    //baseURI = 'https://apigw-weu-tfo-capp-dev.azure-api.net/rmapp/uat/api/v1';//new prod
    (baseURI =
      "https://apigw-weu-tfo-capp-dev.azure-api.net/rmapp/production/api/v1");
// baseURI = "https://app-rmapp-weu-dev.azurewebsites.net"; //latest 2023
axios.defaults.baseURL = baseURI;

// let token = 'CPTMpdb6LhICAQEYh6_PAyD52I0FKJmDDjIZAGfyQctYIboOQTdHbpxfzgPE4BdGOv33lDoaAAoCQQAADIADAAgAAAABAAAAAAAAABjAABNCGQBn8kHLI0Obh55TN7S4YhznTWgEIKSe6W8'
let token = "pat-na1-6d8b229f-78c6-4e52-91a6-d50c008b403c";

const { Storage } = Plugins;

const getPropertiesfromStorage = async (propName) => {
  const ret: any = await Storage.get({ key: "enumproperties" });
  const temp = JSON.parse(ret.value);
  let temp1: any = "";
  temp && temp.resultsArr
    ? (temp1 = temp.resultsArr.filter((obj) => obj.name === propName))
    : (temp1 = []);
  return temp1 && temp1.length > 0 ? temp1[0] : null;
};

const getTopTargetsfromStorage = async () => {
  const ret: any = await Storage.get({ key: "toptargets" });
  const temp = JSON.parse(ret?.value);
  let temp1: any = temp?.result;
  return temp && temp !== null && temp?.result && temp1.length > 0
    ? JSON.parse(temp?.result)
    : [];
};

const setTopTargets = async (Obj) => {
  await Storage.set({
    key: "toptargets",
    value: JSON.stringify({
      result: Obj,
    }),
  });
};
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";

axios.interceptors.request.use(async (config) => {
  const getCongnitoIdToken = async () => {
    const ret: any = await Storage.get({ key: "cognito" });
    const temp = JSON.parse(ret?.value);
    return temp && temp.id_token ? temp.id_token : null;
  };
  const cognitoToken = await getCongnitoIdToken();
  if (cognitoToken) {
    config.headers["Authorization"] = `Bearer ${cognitoToken?.replace(
      "#access_token=",
      ""
    )}`;
  }
  return config;
});

axios.interceptors.response.use(async (response) => {
  const { data } = response;
  if (data) {
    const { code } = data;
    const lMsg = code && code.toLowerCase();
    if (lMsg) {
      if (lMsg.includes("gene-")) {
        await Storage.clear();
        history.push("/login");
      }
    }
  }
  return response;
});

export default {
  SendOTPEmail(requestPayload) {
    return axios
      .post(`absher-auth/sendotp`, requestPayload)
      .then((response) => response.data)
      .catch((error) => error.response.status);
  },
  getContactDetails(contactId) {
    return Func_getProspectById(contactId).then((resp) => {
      return resp;
    });
  },
  getContactDetailsAPI({ contactId: contactId }) {
    return axios
      .post(`/prospects/${contactId}?archived=false`, contactprops, {
        headers: {
          "Content-Type": "text/plain",
        },
      })
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        console.log(error);
      });
  },
  getEngagementsByContactId(contactId) {
    // return axios.get(`/engagements/v1/engagements/associated/contact/${contactId}/paged?`)
    // .then(resp => resp.data)
    return axios
      .get(`/engagements/prospect/${contactId}?limit=80`)
      .then((resp) => resp.data);
  },
  getDocumentsById(documentId) {
    // return axios.get(`/filemanager/api/v2/files/${documentId}?`)
    // .then(resp => resp.data)
    return axios
      .get(`/engagements/attachments/${documentId}`)
      .then((resp) => resp.data);
  },
  getAllProspects() {
    // return axios.get(`/crm/v3/objects/contacts?limit=100&&properties=${contactprops}`).then(resp => resp.data)
    return axios
      .get(`/prospects/?properties=${contactprops}`)
      .then((resp) => resp.data);
  },
  getNextSetProspects(after) {
    // return axios.get(`/crm/v3/objects/contacts?limit=100&after=${after}&properties=${contactprops}`).then(resp => resp.data)
    return axios
      .get(`/prospects/?after=${after}&properties=${contactprops}`)
      .then((resp) => resp.data);
  },
  searchProspect(payload) {
    // return axios.get(`/contacts/v1/search/query?q=${srchQry}&`).then(resp => resp.data)
    // return axios.post(`/crm/v3/objects/contacts/search?`, payload).then(resp => resp.data)
    return axios
      .post(`/prospects/search?`, { ...payload, limit: 20 })
      .then((resp) => resp.data);
  },
  getContactPropertiesByProperty(propname) {
    // return axios.get(`/crm/v3/properties/contacts/${propname}?`).then(resp => resp.data)
    // return axios.get(`/prospects/properties/${propname}`).then(resp => resp.data)
    return getPropertiesfromStorage(propname);
  },
  getContactPropertiesByPropertyBatch() {
    // return axios.get(`/crm/v3/properties/contacts/${propname}?`).then(resp => resp.data)
    return axios
      .post(`/prospects/properties/objectproperties/contacts`, batchinput)
      .then((resp) => resp.data);
  },
  filterProspects(payload) {
    // return axios.post(`/prospects/search?`, { ...payload, limit: 10 }).then(resp => resp.data)
    return filterProspectsDb(payload).then((resp) => resp);
  },
  filterProspectsAPI(payload) {
    return axios
      .post(`/prospects/search?`, { ...payload })
      .then((resp) => resp.data);
  },
  createProspect(paylaod) {
    // return axios.post(`crm/v3/objects/contacts?`,paylaod).then(resp => resp.data)
    // return axios.post(`/prospects`, paylaod).then(resp => resp.data)
    return createProspectDb(paylaod).then((resp) => resp);
  },
  createProspectAPI(paylaod) {
    return axios.post(`/prospects`, paylaod).then((resp) => resp.data);
  },
  updateProspect(contactId, paylaod) {
    return updateProspectDb(contactId, paylaod).then((resp) => resp);
  },
  updateProspectAPI(contactId, paylaod) {
    return axios
      .patch(`/prospects/${contactId}`, paylaod)
      .then((resp) => resp.data);
  },
  updateJourneyStage(contactid, stage) {
    // return axios.patch(`/crm/v3/objects/contacts/${contactid}?&properties='prospect_stages'`, stage).then(resp => resp.data)
    //return axios.post(`/crm/v3/objects/contacts/search?&properties=${contactprops}`, payload).then(resp => resp.data)
    //return axios.patch(`/prospects/${contactid}?properties='lifecycle_stage_status'`, stage).then(resp => resp.data)
    return axios
      .patch(`/prospects/${contactid}?properties='lead_stage'`, stage)
      .then((resp) => resp.data);
  },
  getOwners() {
    return getOwnersDb().then((resp) => resp);
  },
  getOwnersAPI() {
    // return axios.get(`/crm/v3/owners?`).then(resp => resp.data)
    return axios.get(`/owners?limit=500`).then((resp) => resp.data);
  },
  getOwnerswithProps(emailinp) {
    // return axios.get(`/crm/v3/owners?&email=${emailinp}`).then(resp => resp.data)
    // return axios.get(`/owners?email=${emailinp}`).then(resp => resp.data) alopama
    return axios.post(`/owners`, { email: emailinp }).then((resp) => resp.data);
  },
  createTask(payload) {
    return axios.post(`/engagements`, payload).then((resp) => resp.data);
    // return axios.post(`/engagements/v1/engagements?`,payload).then(resp => resp.data)
  },
  patchTask(engId, payload) {
    return axios
      .patch(`/engagements/${engId}`, payload)
      .then((resp) => resp.data);
    // return axios.post(`/engagements/v1/engagements?`,payload).then(resp => resp.data)
  },
  createNote(
    prospectId,
    payload: meetingNotePayload
  ): Promise<meetingNoteApiRespSingle> {
    return createNewMeetingNote(prospectId, payload).then((resp) => resp);
  },
  getNotesforRM(RMId: number) {
    // return axios.get(`/engagements/meetingnote/rm/${RMId}`).then(resp => resp.data)//av
    return axios.get(`/engagements/meetingnote/rm`).then((resp) => resp.data);
  },
  createNoteAPI(
    prospectId,
    payload: meetingNotePayload
  ): Promise<meetingNoteApiRespSingle> {
    return axios
      .post(`/engagements/meetingnote/${prospectId}`, payload)
      .then((resp) => resp.data);
  },
  GetMeetingNoteById(noteId: string): Promise<meetingNoteApiRespSingle> {
    return getNoteById(parseInt(noteId)).then((resp) => resp);
  },
  GetMeetingNoteByIdAPI(noteId) {
    return axios
      .get(`/engagements/meetingnote/details/${noteId}`)
      .then((resp) => resp.data);
  },
  UpdateMeetingNoteById(
    noteId,
    payload: meetingNotePayload
  ): Promise<meetingNoteApiRespSingle> {
    return updateNoteById(noteId, payload).then((resp) => resp);
  },
  UpdateMeetingNoteByIdAPI(
    noteId,
    payload: meetingNotePayload
  ): Promise<meetingNoteApiRespSingle> {
    return axios
      .patch(`/engagements/meetingnote/details/${noteId}`, payload)
      .then((resp) => resp.data);
  },
  GetNoteByProspectId(prospectId, onboardingId) {
    return getNotesByProspId(
      parseInt(prospectId),
      parseInt(onboardingId || 0)
    ).then((resp) => resp);
  },
  GetNoteByProspectIdAPI(prospectId, onboardingId) {
    return axios
      .get(`/engagements/meetingnote/${prospectId}?mandateId=${onboardingId}`)
      .then((resp) => resp.data);
  },
  GetNotesInsights(prospectId: string): Promise<meetingNoteApiRespSingle> {
    // return axios.get(`/engagements/meetingnote/details/${prospectId}`).then(resp => resp.data)
    return getNoteById(parseInt(prospectId)).then((resp) => resp);
  },
  GetDashboardCountry(rmId, startDate, endDate, type) {
    // return axios.get(`/dashboard/country/${rmId}?startDate=${startDate}&endDate=${endDate}&type=${type}`).then(resp => resp.data)//av
    return axios
      .get(
        `/dashboard/country?startDate=${startDate}&endDate=${endDate}&type=${type}`
      )
      .then((resp) => resp.data);
  },
  getPersona(rmId, startDate, endDate, type) {
    return axios
      .get(
        `/dashboard/persona?startDate=${startDate}&endDate=${endDate}&type=${type}`
      )
      .then((resp) => resp.data);
  },
  getDashboardStats(rmId, startDate, endDate, type) {
    // return axios.get(`/dashboard/statistics/${rmId}?startDate=${startDate}&endDate=${endDate}&type=${type}`).then(resp => resp.data)//alopama
    return axios
      .get(
        `/dashboard/statistics?startDate=${startDate}&endDate=${endDate}&type=${type}`
      )
      .then((resp) => resp.data);
  },
  getDashboardProspMeet(rmId, startDate, endDate) {
    // return axios.get(`/dashboard/prospectmeetings/${rmId}?startDate=${startDate}&endDate=${endDate}`).then(resp => resp.data)// alopama
    return axios
      .get(
        `/dashboard/prospectmeetings?startDate=${startDate}&endDate=${endDate}`
      )
      .then((resp) => resp.data);
  },
  GetToDo(rmId, type) {
    // return axios.get(`/dashboard/rmtodos/${rmId}`).then(resp => resp.data)
    // return axios.get(`/dashboard/rmtodos/${rmId}?type=${type}`).then(resp => resp.data)//alopama
    return axios
      .get(`/dashboard/rmtodos?type=${type}`)
      .then((resp) => resp.data);
  },
  GetEngagement(engagementId) {
    return axios.get(`/engagements/${engagementId}`).then((resp) => resp.data);
  },
  GetMeetings(rmId, type) {
    // return axios.get(`/dashboard/rmmeetings/${rmId}?type=${type}`).then(resp => resp.data)//alopama
    return axios
      .get(`/dashboard/rmmeetings?type=${type}`)
      .then((resp) => resp.data);
  },
  getCommitmentForecast(RMId: number, StartDate: string, EndDate: string) {
    // return axios.get(`/dashboard/commitments/${RMId}?startDate=${StartDate}&endDate=${EndDate}`)//alopama
    return axios.get(
      `/dashboard/commitments?startDate=${StartDate}&endDate=${EndDate}`
    );
    // return axios.get(`/dashboard/commitments/47735531?startDate=${StartDate}&endDate=${EndDate}`)
  },
  getClientCommitmentForecast(
    RMId: number,
    StartDate: string,
    EndDate: string
  ) {
    // return axios.get(`/dashboard/client-commitments/${RMId}?startDate=${StartDate}&endDate=${EndDate}`) //alopama
    return axios.get(
      `/dashboard/client-commitments?startDate=${StartDate}&endDate=${EndDate}`
    );
    // return axios.get(`/dashboard/commitments/47735531?startDate=${StartDate}&endDate=${EndDate}`)
  },
  getTotalCommitmentForecast(RMId: number, StartDate: string, EndDate: string) {
    // return axios.get(`/dashboard/total-commitment-forecast/${RMId}?startDate=${StartDate}&endDate=${EndDate}`)//av
    return axios.get(
      `/dashboard/total-commitment-forecast?startDate=${StartDate}&endDate=${EndDate}`
    );
    // return axios.get(`/dashboard/commitments/47735531?startDate=${StartDate}&endDate=${EndDate}`)
  },
  getOnboardedClients(RMemail, teamId, StartDate: string, EndDate: string) {
    //    return axios.get(`/dashboard/onboarded-clients?rmEmail=${RMemail}&teamId=${teamId}&startDate=${StartDate}&endDate=${EndDate}`)//av
    return axios.get(
      `/dashboard/onboarded-clients?teamId=${teamId}&startDate=${StartDate}&endDate=${EndDate}`
    );
  },
  //dashboard/onboarded-clients?rmEmail=s.shehadeh@tfoco.com&teamId=1844148&startDate=01-JAN-2014&endDate=31-DEC-2014
  //staging/api/v1/targets/8888
  getTopTargets(rmId) {
    // return axios.get(`/targets/${rmId}`).then(resp => resp.data)//av
    return axios.get(`/targets`).then((resp) => resp.data);
  },
  setTopTargetsinStorage(prospect_list) {
    return setTopTargets(prospect_list);
  },
  getTTfromStore() {
    return getTopTargetsfromStorage();
  },
  getTopTargetContacts(payLoad) {
    return axios.post(`prospects/batch`, payLoad).then((resp) => resp.data);
  },
  postTopTargets(payload) {
    return axios.post(`/targets/`, payload).then((resp) => resp.data);
  },
  deleteMeetingNote(noteId) {
    return deleteNoteById(noteId).then((resp) => resp);
  },
  deleteMeetingNoteAPI(noteId) {
    return axios
      .delete(`/engagements/meetingnote/details/${noteId}`)
      .then((resp) => resp.data);
  },
  deleteTask(taskId) {
    return axios.delete(`/engagements/${taskId}`).then((resp) => resp.data);
  },
  getProspConv(RMId: number, StartDate: string, EndDate: string) {
    // return axios.get(`/dashboard/conversions/${RMId}?startDate=${StartDate}&endDate=${EndDate}`)//av
    return axios.get(
      `/dashboard/conversions?startDate=${StartDate}&endDate=${EndDate}`
    );
  },
  getAssociations(prospectId) {
    return axios.get(`/associations/${prospectId}`).then((resp) => resp.data);
  },
  createAssociation(prospectId, payload) {
    return axios
      .post(`/associations/${prospectId}`, payload)
      .then((resp) => resp.data);
  },
  getClientDetails(clientId) {
    return axios
      .get(`dashboard/clientdetail/${clientId}`)
      .then((resp) => resp.data);
  },
  getClientFundRaised(RMemail, StartDate, EndDate) {
    // return axios.get(`/dashboard/client-funds-raised?rmEmail=${RMemail}&startDate=${StartDate}&endDate=${EndDate}`)//av
    return axios.get(
      `/dashboard/client-funds-raised?startDate=${StartDate}&endDate=${EndDate}`
    );
  },
  getGeneralFundRaised(RMemail, StartDate, EndDate, teamId) {
    // return axios.get(`/dashboard/client-funds-raised?rmEmail=${RMemail}&startDate=${StartDate}&endDate=${EndDate}&teamId=${teamId}&type=generalfund`)//av
    return axios.get(
      `/dashboard/client-funds-raised?startDate=${StartDate}&endDate=${EndDate}&teamId=${teamId}&type=generalfund`
    );
  },
  getClientAUM(rmEmail) {
    return axios
      .get(`/dashboard/client-aum?rmEmail=${rmEmail}`)
      .then((resp) => resp.data); // av: in client AUM we need rmEmail
  },
  getClientCommitment(rmId, StartDate, EndDate) {
    // return axios.get(`/dashboard/client-commitments?rmId=${rmId}&startDate=${StartDate}&endDate=${EndDate}`) //alopama
    return axios.get(
      `/dashboard/client-commitments?rmId=${rmId}&startDate=${StartDate}&endDate=${EndDate}`
    );
  },
  getClientMeetings(rmId, StartDate, EndDate) {
    // return axios.get(`/dashboard/client-meetings/${rmId}?startDate=${StartDate}&endDate=${EndDate}`) //alopama
    return axios.get(
      `/dashboard/client-meetings?startDate=${StartDate}&endDate=${EndDate}`
    );
  },
  getClientStatistics(rmEmail) {
    // return axios.get(`/dashboard/client-statistics?rmEmail=${rmEmail}`).then(resp => resp.data) //alopama
    return axios.get(`/dashboard/client-statistics`).then((resp) => resp.data);
  },
  getClientOutflow(RmEmail: string, StartDate: string, EndDate: string) {
    // return axios.get(`/dashboard/client-outflow?rmEmail=${RmEmail}&startDate=${StartDate}&endDate=${EndDate}`)
    return axios.get(
      `/dashboard/client-outflow?startDate=${StartDate}&endDate=${EndDate}`
    );
  },
  getClientDetailReinvestment(
    ClientId: string,
    StartDate: string,
    EndDate: string
  ) {
    return axios.get(
      `/dashboard/reinvestment?clientId=${ClientId}&startDate=${StartDate}&endDate=${EndDate}&type=client`
    );
  },
  getClientDashboardReinvestment(
    RMEmail: string,
    StartDate: string,
    EndDate: string
  ) {
    // return axios.get(`/dashboard/reinvestment?startDate=${StartDate}&endDate=${EndDate}&rmEmail=${RMEmail}&type=clientDashREYTD`)//av
    return axios.get(
      `/dashboard/reinvestment?startDate=${StartDate}&endDate=${EndDate}&type=clientDashREYTD`
    );
  },
  getClientDashboardReinvestmentDetail(
    RMEmail: string,
    StartDate: string,
    EndDate: string
  ) {
    // return axios.get(`/dashboard/reinvestment?startDate=${StartDate}&endDate=${EndDate}&rmEmail=${RMEmail}&type=reinvestmentDetails`).then(resp => resp.data)//av
    return axios
      .get(
        `/dashboard/reinvestment?startDate=${StartDate}&endDate=${EndDate}&type=reinvestmentDetails`
      )
      .then((resp) => resp.data);
    //return axios.get(`/dashboard/client-outflow?rmEmail=${RmEmail}&startDate=${StartDate}&endDate=${EndDate}`)
  },
  getGeneralDashboardReinvestment(
    RMEmail: string,
    StartDate: string,
    EndDate: string,
    teamid,
    type
  ) {
    // return axios.get(`/dashboard/reinvestment?startDate=${StartDate}&endDate=${EndDate}&rmEmail=${RMEmail}&teamId=${teamid}&type=${type}`)//av
    return axios.get(
      `/dashboard/reinvestment?startDate=${StartDate}&endDate=${EndDate}&teamId=${teamid}&type=${type}`
    );
  },
  getValutionDate() {
    return axios.get(`/dashboard/valuationdate`);
  },
  getCDPEngagement(contactId) {
    return axios
      .get(`/engagements/cdp?key=${contactId}`)
      .then((resp) => resp.data);
  },
  getUncalledCommitments(rmEmail, teamId) {
    // return axios.get(`/dashboard/uncalled-commitment?rmEmail=${rmEmail}&teamId=${teamId}`)//av
    return axios.get(`/dashboard/uncalled-commitment?teamId=${teamId}`);
  },
  getGeneralMeetings(rmId, teamId, StartDate: string, EndDate: string) {
    // return axios.get(`dashboard/general-meetings?rmId=${rmId}&teamId=${teamId}&startDate=${StartDate}&endDate=${EndDate}`)//av
    return axios.get(
      `dashboard/general-meetings?teamId=${teamId}&startDate=${StartDate}&endDate=${EndDate}`
    );
  },
  getGeneralAUM(rmEmail, teamId, type, StartDate: string, EndDate: string) {
    // return axios.get(`dashboard/client-funds-raised?rmEmail=${rmEmail}&teamId=${teamId}&startDate=${StartDate}&endDate=${EndDate}&type=${type}`)//av
    return axios.get(
      `dashboard/client-funds-raised?teamId=${teamId}&startDate=${StartDate}&endDate=${EndDate}&type=${type}`
    );
  },
  updatelocation(paylaod) {
    return axios.post(`/owners/logininfo`, paylaod).then((resp) => resp.data);
  },
  getlocation(rmEmail) {
    // return axios.get(`/owners/logininfo?rmEmail=${rmEmail}`) //alopama
    return axios.get(`/owners/logininfo`);
  },
  getCalendarEvent(rmEmail, startDateTime, endDateTime) {
    // return axios.get(`https://0ql5n0x7z7.execute-api.eu-west-1.amazonaws.com/staging/api/v1/absher-auth/mscalendar/${rmEmail}?startDateTime=${startDateTime}&endDateTime=${endDateTime}&$top=400`).then(resp => resp.data)
    // return axios.get(`/absher-auth/mscalendar/${rmEmail}?startDateTime=${startDateTime}&endDateTime=${endDateTime}&$top=400`).then(resp => resp.data)//av
    return axios
      .get(
        `/absher-auth/mscalendar?startDateTime=${startDateTime}&endDateTime=${endDateTime}&$top=400`
      )
      .then((resp) => resp.data);
  },
  createCalendarEvent(payload, rmEmail) {
    // return axios.post(`https://0ql5n0x7z7.execute-api.eu-west-1.amazonaws.com/staging/api/v1/absher-auth/mscalendar/${rmEmail}`,payload).then(resp => resp.data)
    // return axios.post(`/absher-auth/mscalendar/${rmEmail}`,payload).then(resp => resp.data)//av
    return axios
      .post(`/absher-auth/mscalendar`, payload)
      .then((resp) => resp.data);
  },
  getCalendarEventDetail(rmEmail, eventId) {
    // return axios.post(`https://0ql5n0x7z7.execute-api.eu-west-1.amazonaws.com/staging/api/v1/absher-auth/mscalendar/${rmEmail}/${eventId}`).then(resp => resp.data)
    // return axios.post(`/absher-auth/mscalendar/${rmEmail}/${eventId}`).then(resp => resp.data)//av
    return axios
      .post(`/absher-auth/mscalendar/${eventId}`)
      .then((resp) => resp.data);
  },
  updateCalendarEventDetail(rmEmail, eventId, payload) {
    // return axios.patch(`https://0ql5n0x7z7.execute-api.eu-west-1.amazonaws.com/staging/api/v1/absher-auth/mscalendar/${rmEmail}/${eventId}`,payload).then(resp => resp.data)
    // return axios.patch(`/absher-auth/mscalendar/${rmEmail}/${eventId}`,payload).then(resp => resp.data)//av
    return axios
      .patch(`/absher-auth/mscalendar/${eventId}`, payload)
      .then((resp) => resp.data);
  },
  cancelCalendarEvent(eventId, rmEmail) {
    // return axios.post(`https://0ql5n0x7z7.execute-api.eu-west-1.amazonaws.com/staging/api/v1/absher-auth/mscalendar/maveric1@tfoco.com/${eventId}/cancel`).then(resp => resp.data)
    // return axios.post(`/absher-auth/mscalendar/${rmEmail}/${eventId}/cancel`).then(resp => resp.data)//av
    return axios
      .post(`/absher-auth/mscalendar/${eventId}/cancel`)
      .then((resp) => resp.data);
  },
  updateAssociation(assocId, relType_1, relType_2) {
    return axios
      .patch(
        `/associations/details/${assocId}?relationShipType1=${relType_1}&relationShipType2=${relType_2}`
      )
      .then((resp) => resp.data);
  },
  deleteAssociation(rowId) {
    return axios
      .delete(`/associations/details/${rowId}`)
      .then((resp) => resp.data);
  },

  getWeeklyUpdateData() {
    return axios
      .get(
        // `https://rm-app-be-cs.azurewebsites.net/api/materialList?section=WeeklyUpdates` new stg url
        `https://rm-app-be-cs-prod.azurewebsites.net/api/materialList?section=WeeklyUpdates` //new prod url
      )
      .then((resp) => resp.data);
  },
  getDigitalUpdate() {
    return axios
      .get(
        `https://rm-app-be-cs-prod.azurewebsites.net/api/materialList?section=DigitalProducts`
      )
      .then((resp) => resp.data);
  },
  getInvestmentUpdate() {
    return axios
      .get(
        `https://rm-app-be-cs-prod.azurewebsites.net/api/materialList?section=InvestmentProducts`
      )
      .then((resp) => resp.data);
  },
  getWeeklyMarketDetails(id: number) {
    return axios
      .get(
        `https://rm-app-be-cs-prod.azurewebsites.net/api/materialDetails?id=${id}`
      )
      .then((resp) => resp.data);
  },
  getInvestmentDetails(id: number) {
    return axios
      .get(
        `https://rm-app-be-cs-prod.azurewebsites.net/api/materialDetails?id=${id}`
      )
      .then((resp) => resp.data);
  },
  getDigitaltDetails(id: number) {
    return axios
      .get(
        `https://rm-app-be-cs-prod.azurewebsites.net/api/materialDetails?id=${id}`
      )
      .then((resp) => resp.data);
  },
  getAllDeals() {
    return axios
      .get(`https://rm-app-be-cs-prod.azurewebsites.net/api/dealList`)
      .then((resp) => resp.data);
  },
  getSingleDeal(id: number) {
    return axios
      .get(
        `https://rm-app-be-cs-prod.azurewebsites.net/api/dealDetails?dealId=${id}`
      )
      .then((resp) => resp.data);
  },
  sendDealAnalysisData(payload) {
    return axios
      .post(
        `https://rm-app-be-cs-prod.azurewebsites.net/api/dealAnalysis`,
        payload
      )
      .then((resp) => resp.data);
  },
  getDealAnalysisData(rmid, dealid) {
    return axios
      .get(
        `https://rm-app-be-cs-prod.azurewebsites.net/api/dealAnalysis?rmid=${rmid}&dealid=${dealid}`
      )
      .then((resp) => resp.data);
  },
  updateDealAnalysisData(id, payload) {
    return axios
      .put(
        `https://rm-app-be-cs-prod.azurewebsites.net/api/dealAnalysis?id=${id}`,
        payload
      )
      .then((resp) => resp.data);
  },
};
