import React, { useState } from "react";
import {
  IonAvatar,
  IonCol,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonRow,
  IonText,
  IonToolbar,
} from "@ionic/react";
import { search } from "ionicons/icons";
import { useHistory } from "react-router-dom";
import ProfPopover from "../ProfileModal/ProfPopover";
import useWindowDimensions from "src/components/useWindowDimensions";

function iOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

interface ProfPopup {
  open: boolean;
  event: Event | undefined;
}

const SearchHeader: React.FC = () => {
  const [ProfPopovermenu, setProfPopovermenu] = useState<ProfPopup>({
    open: false,
    event: undefined,
  });
  const history = useHistory();
  const triggerSearch = () => {
    history.push("/prospects/search");
  };
  const { height, width } = useWindowDimensions();
  return (
    <React.Fragment>
      <IonHeader>
        <IonToolbar>
          {iOS() === true ? (
            <IonGrid className="rootgrid margintopn10">
              <IonRow>
                <IonCol>
                  <IonImg
                    className="ion-text-start"
                    src="/assets/brand_logo.svg"
                    style={{ width: 75 }}
                  />
                </IonCol>
                <IonCol>
                  <IonGrid className="ion-float-right">
                    <IonRow>
                      <IonIcon
                        color="white"
                        icon={search}
                        size="small"
                        onClick={triggerSearch}
                        className="trig-srch"
                      ></IonIcon>
                      <IonText
                        className="srchplaceholder ion-margin-horizontal trig-srch"
                        onClick={triggerSearch}
                      >
                        {width > 575
                          ? "Search for any prospect or client"
                          : "Search"}
                      </IonText>
                      <IonAvatar
                        onClick={(e) => {
                          setProfPopovermenu({
                            open: true,
                            event: e.nativeEvent,
                          });
                        }}
                        style={{ width: 24, height: 24 }}
                      >
                        <img src="/assets/empty-avatar.svg" alt="" />
                      </IonAvatar>
                    </IonRow>
                  </IonGrid>
                </IonCol>
              </IonRow>
            </IonGrid>
          ) : (
            <IonGrid className="rootgrid">
              <IonRow>
                <IonCol>
                  <IonImg
                    className="ion-text-start"
                    src="/assets/brand_logo.svg"
                    style={{ width: 75 }}
                  />
                </IonCol>
                <IonCol>
                  <IonGrid className="ion-float-right">
                    <IonRow>
                      <IonIcon
                        color="white"
                        icon={search}
                        size="small"
                        onClick={triggerSearch}
                        className="trig-srch"
                      ></IonIcon>
                      <IonText
                        className="srchplaceholder ion-margin-horizontal trig-srch"
                        onClick={triggerSearch}
                      >
                        {width > 575
                          ? "Search for any prospect or client"
                          : "Search"}
                      </IonText>
                      <IonAvatar
                        onClick={(e) => {
                          setProfPopovermenu({
                            open: true,
                            event: e.nativeEvent,
                          });
                        }}
                        style={{ width: 24, height: 24 }}
                      >
                        <img src="/assets/empty-avatar.svg" alt="" />
                      </IonAvatar>
                    </IonRow>
                  </IonGrid>
                </IonCol>
              </IonRow>
            </IonGrid>
          )}
        </IonToolbar>
      </IonHeader>
      <ProfPopover
        open={ProfPopovermenu.open}
        event={ProfPopovermenu.event}
        setShowPopover={() =>
          setProfPopovermenu({ open: false, event: undefined })
        }
      />
    </React.Fragment>
  );
};

export default SearchHeader;
