import { phoneLandscape, school } from "ionicons/icons"
import Nationality from "src/components/PhCountryCodes/Nationality"

const prepPayload = (values) => {

    let prefTime: Array<string> = []
    let prefChannel: Array<string> = []
    let eduObj: any = {
        edu: []
    }
    const {
        salutation,
        firstName,
        middleName,
        lastName,
        arbfirstName,
        arbmiddleName,
        arblastName,
        gender,
        agegrp,
        email,
        priphnum,
        whtphnum,
        addln1,
        addln2,
        city,
        country,
        nationality,
        company,
        position,
        sector,
        sow,
        exp_wealth_range,
        bio,
        meeting_notes,
        PrefSMS,
        PrefWht,
        Prefemail,
        Prefphcall,
        // acdInst1,
        // acdInst2,
        // acdInst3,
        // acdInst4,
        // acdInst5,
        // degree1,
        // degree2,
        // degree3,
        // degree4,
        // degree5,
        persona,
        source,
        type,
        jrnystg,
        status,
        finsophis,
        techsophis,
        phCode,
        phCodeWht,
        bldnum,
        ofcnum,
        flrnum,
        degree,
        acdInst,
        pol_exp,
        sharia_comp,
        exp_closing_prob,
        exp_close_amt,
        inv_obj,
        cadence,
       // assigned_rm,
       onboarding_id,
        hubspot_owner_id,
        source_of_name,
        exp_mnth_close,
        PrefMor,
        PrefNoon,
        PrefEve,
        cust_date_lc_stage
    } = values
    
    // console.log(values);
    PrefSMS ? prefChannel.push("SMS") : prefChannel= [...prefChannel]
    PrefWht ? prefChannel.push("Whatsapp") : prefChannel= [...prefChannel]
    Prefemail ? prefChannel.push("Email") : prefChannel= [...prefChannel]
    Prefphcall  ? prefChannel.push("Call") : prefChannel= [...prefChannel]

    
    PrefMor ? prefTime.push("Morning") : prefTime = [...prefTime]
    PrefNoon ? prefTime.push("Afternoon") : prefTime = [...prefTime]
    PrefEve ? prefTime.push("Evening") : prefTime = [...prefTime]

    // acdInst1 && acdInst1 !== "" ? eduObj.edu.push({ institution: acdInst1, degree: degree1}) : eduObj = {...eduObj}
    // acdInst2 && acdInst2 !== "" ? eduObj.edu.push({ institution: acdInst2, degree: degree2}) : eduObj = {...eduObj}
    // acdInst3 && acdInst3 !== "" ? eduObj.edu.push({ institution: acdInst3, degree: degree3}) : eduObj = {...eduObj}
    // acdInst4 && acdInst4 !== "" ? eduObj.edu.push({ institution: acdInst4, degree: degree4}) : eduObj = {...eduObj}
    // acdInst5 && acdInst5 !== "" ? eduObj.edu.push({ institution: acdInst5, degree: degree5}) : eduObj = {...eduObj}


    return {
        properties: {
            hubspot_owner_id: hubspot_owner_id,
            //assigned_rm:assigned_rm,
            title_salutation: salutation,
            firstname: firstName,
            middle_name: middleName,
            lastname: lastName,
            first_name_arabic: arbfirstName,
            arabic_last_name: arblastName,
            arabic_middle_name: arbmiddleName,
            gender: gender,
            age_range: agegrp,
            email: email,
            // phone: priphnum && priphnum !== "" && priphnum !== null ? phCode+" "+priphnum: '',
            // mobilephone: whtphnum && whtphnum !== "" && whtphnum !== null ? phCodeWht+" "+whtphnum: '',
            phone:priphnum,
            mobilephone:whtphnum,
            address_line_1: addln1,
            address_line_2: addln2,
            city: city,
            country: country,
            nationality_1_: nationality,
            company: company,
            position: position,
            industry_: sector,
            source_of_wealth: sow ? sow.join(";"): "",
            expected_wealth_range: exp_wealth_range,
            bio_profile: bio,
            general_remarks: meeting_notes,
            preferred_channel: prefChannel.join(";"),
            // additional_qualification_degree: JSON.stringify(eduObj),
            school: acdInst,
            degree: degree,
            segment: persona,
            contact_type: source,
            //type: type,
            // prospect_stages: jrnystg,            
           lifecyclestage : type=="Client"?"customer":"",
            //lifecycle_stage_status:  type=="Client"?"Client 2":jrnystg,
            lead_stage:  type=="Client"?"COMMITTED CAPITAL":jrnystg,
            // prospect_status: status,
            hs_lead_status: status,
            financial_sophistication: finsophis,
            technology_sophistication: techsophis,
            building_details: bldnum,
            // office_number: ofcnum,
            // floor_number: flrnum,
            politically_exposed_persons_pep: pol_exp,
            sharia_compliant: sharia_comp,
            expected_closing_probability: exp_closing_prob ,
            amount_status: Number(exp_close_amt.replace(/[^0-9.-]+/g,"")),
            expected_month_of_closing: exp_mnth_close,
            // investment_objectives: inv_obj,
            // meeting_cadence: cadence,
            source_of_name: source_of_name,
            preferred_time_of_day: prefTime.join(";"),
            onboarding_id:onboarding_id,
            hs_lifecyclestage_customer_date:cust_date_lc_stage
        }
    }
}

export default prepPayload