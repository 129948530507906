import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonCard,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
  IonSlide,
  IonSlides,
} from "@ionic/react";
import calendarschedule from "src/icons/calendarschedule.svg";
import CryptoJS from 'crypto-js';
import api from "src/api";
import moment from "moment";
import { Plugins } from "@capacitor/core";
import useWindowDimensions from "src/components/useWindowDimensions";
import chevronBackCircle from "src/icons/sliderprev.svg"
import chevronForwardCircle from "src/icons/slidernext.svg"
import "./UpcomingSchedule.css"
const { Storage, Device } = Plugins;

const styles = {
  upcomingSchdulecard: {
    background: "#4B3933",
    marginInlineStart: 0,
    marginInlineEnd: 0,
    margin: 0,
    width: "100%",
  },
  slide: {
    marginTop: 16,
    marginRight: -32,
    marginLeft: -32,
  },
};
const decrypt = (decryptVlaue) => {
  let passphrase = "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9"
  const bytes = CryptoJS.AES.decrypt(decryptVlaue, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};
const getUserId = async () => {
  const ret: any = await Storage.get({ key: "userId" });
  return JSON.parse(decrypt(ret.value)).id;
};


const UpcomingSch = ((type) => {
  const { height, width } = useWindowDimensions();
  let slidewidth = 400;
  if (width < 576) slidewidth = 380;
  const [deviceInfo, setdeviceInfo] = useState<any>();
  const slideOpts = deviceInfo && deviceInfo.platform === "web" && width > 1024 ? {
    slidesPerView: 3,
    // cssMode:true,
    spaceBetween: 16,
    simulateTouch: false,
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev',
    navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  }
  }:{
    initialSlide: 0.5,
    speed: 400,
    // slidesPerView: 2,
    // slidesPerGroup: 3,
    // centeredSlides: true,
    // centeredSlidesBounds: true,
    width: slidewidth,
    freeMode: true,
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    },
    spaceBetween: 16,
  };
  

//const UpcomingSch = ((type) => {

    // const [userId, setUserId] = React.useState<any>(); 
    const [upcomingSch, setUpcomingSch] = React.useState<any>([]);
    const [upcomingSchData, setUpcomingSchData] = React.useState<any>([]);

   
    const [actSlide, setactSlide] = useState<any>({
      len: 0,
      ind: 0
    });

  const slideRef = React.createRef<HTMLIonSlidesElement>();

  // getUserId().then(userId => {
  //    // console.log(userId);
  //     setUserId(userId)
  // })

  // let rmId = 47735531
  // useEffect(() => {
  //   Device.getInfo().then(info => {
  //     setdeviceInfo(info)
  //   })
  //   getUserId().then((userId) => {
  //     api.GetMeetings(userId,type.type).then((data) => {
  //       if (data && data.length > 0) {
  //         setactSlide({
  //           len: data.length,
  //           ind: 0
  //         })
  //         setUpcomingSch(
  //           data
  //             .filter(
  //               (r) =>
  //                 moment(r.activityDate).format("x") >= moment().startOf("day").format("x")
  //             )
  //             .sort((a, b) => {
  //               return moment(a.activityDate).isAfter(moment(b.activityDate))
  //                 ? 1
  //                 : -1;
  //             })
  //             .filter((a, i) => i < 5)
  //         );
  //       }
  //     });
  //   });
  // }, []);

  const getUserEmail = async () => {
    const ret: any = await Storage.get({ key: "userId" })
    const temp = JSON.parse(decrypt(ret.value))
    return temp && temp.email ? temp.email : null
}
useEffect(() => {

      Device.getInfo().then(info => {
          setdeviceInfo(info)
       })
    getUserEmail().then(useremail => { 

    let today = new Date()
    let date = new Date();
    date.setDate(date.getDate() + 7);
    let sevenDate = new Date(date)
    let startDateTime = moment(today).format("YYYY-MM-DD") + "T00:00:00" 
    let endDateTime = sevenDate.getFullYear() + "-" + moment(sevenDate).format("MM") + "-" + moment(sevenDate).format("DD") + "T23:59:59"

    // console.log(startDateTime)
    // console.log(endDateTime)
    api.getCalendarEvent(useremail,startDateTime,endDateTime) //startDateTime,endDateTime
        .then((response) => {
            // console.log(response)
            if(response && response.value && response.value.length > 0)
            {
              setactSlide({
                len: response.value.length,
                ind: 0
              }) 
              setUpcomingSch(
                response.value
                  // .filter((r) =>moment(r.start.dateTime).format("x") >= moment(today).format("x"))
                  .sort((a, b) => {
                    return moment(a.start.dateTime).format("x") >= moment(b.start.dateTime).format("x")
                      ? 1
                      : -1;
                  })
                  // .filter((a, i) => i < 5)
                  
              );
              // console.log(response.value
              //   .sort((a, b) => {
              //     return moment(a.start.dateTime).format("x") >= moment(b.start.dateTime).format("x")
              //       ? 1
              //       : -1;
              //   })
              //   // .filter((a, i) => i < 5)
              //   )
            }
        })

    })
},[])

  return upcomingSch.length > 0 ? (
    <div className="temp-cont">
      <IonSlides ref={slideRef} options={slideOpts} style={styles.slide} class="slides-mobile"
        className="slider-upsch"
        onIonSlideDidChange={(e) => {
          slideRef.current?.getActiveIndex().then((ind) => {
            slideRef.current?.length().then(len => {
              setactSlide({
                len,
                ind
              })
            })
          })
        }}
        >
        {upcomingSch.map((val, i) => (
          <IonSlide key={i}>
            <UpcomingSchCard detail={val} />
          </IonSlide>
        ))}
      </IonSlides>
      {
          deviceInfo && deviceInfo.platform === "web" && width > 1024 ? 
          <div className="next-prev-cont-upsch">
          {
            actSlide.len > 0 && actSlide.ind === 0 ? null :
            <IonButton
            className="swiper-button-prev-upsch"
            fill="clear"
            onClick={() => {
              slideRef.current?.slidePrev()
            }}
            >
              <IonIcon 
                icon={chevronBackCircle}
                color="gold"
                />
            </IonButton>
          }
          {
          actSlide.len > 0 && actSlide.ind + 3 >= actSlide.len ? null :
          <IonButton
            className="swiper-button-next-upsch"
            fill="clear"
            onClick={() => {
              slideRef.current?.slideNext()
            }}
            >
            <IonIcon
              color="gold"
              icon={chevronForwardCircle}
              />
          </IonButton>
          }
        </div>
        : null
      }
    </div>
  ) : (
    <IonRow>
      <IonLabel color="greyscale">No data found</IonLabel>
    </IonRow>
  );
});

const UpcomingSchCard = ({ detail }) => {

  const attendeeName = ((detail) => {
    let attLen = detail.attendees.length
    let name =  ""
    let email = ""
    if(attLen > 0)
    {
      for(let i = 0; i < attLen; i++)
      {
        if(name === "")
          name = detail.attendees[i].emailAddress.name
        else
          name = name + "," + detail.attendees[i].emailAddress.name

        if(email === "")
          email = detail.attendees[i].emailAddress.address
        else
          email = email + "," + detail.attendees[i].emailAddress.address
      }
    }
    return name ? name : email ? email : "Attendees not defined"
  })


  return (
    <IonCard
      style={styles.upcomingSchdulecard}
      color="alt8"
      class="scheduleIcon mobilewidth"
    >
      <IonItem lines="none" color="alt8">
        <IonIcon icon={calendarschedule} style={{width:36, height:36}}/>
        <IonLabel class="ion-padding">
          <p className="marginbottom5 lineheight120 white boldText">
            {detail.subject}
          </p>
          <p className="marginbottom5 lineheight120 font14 grayscale5">{attendeeName(detail)}</p>
          <p className="lineheight120 font12 grayscale5">
            {moment(detail.start.dateTime).subtract(new Date(detail.start.dateTime).getTimezoneOffset(),"minutes").format("LT")} -{" "}
            {moment(detail.end.dateTime).subtract(new Date(detail.end.dateTime).getTimezoneOffset(),"minutes").format("LT")} |{" "}
            {moment(detail.start.dateTime).format("ddd, ll")}
          </p>
        </IonLabel>
      </IonItem>
    </IonCard>
  );
};

export default UpcomingSch;
