import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonBackButton,
  IonButtons,
  IonPage,
  IonText,
  IonRow,
  IonGrid,
  IonLoading,
  IonPopover,
  IonCard,
  IonCol,
  IonButton,
} from "@ionic/react";
import "./CreateProspect.css";
import CryptoJS from "crypto-js";
import { chevronBack } from "ionicons/icons";
import BasicInfo from "./BasicInfo";
import OtherInfo from "./OtherInfo";
import FinancialInfo from "./FinancialInfo";
import ContactInform from "./ContactInform";
import { Formik } from "formik";
import { Form } from "formik";
import { StepButton } from "./NavigateButton";
import api from "src/api";
import prepPayload from "./prepPayload";
import FormSegments from "src/components/FormSegments/FormSegments";
import * as Yup from "yup";
import { RouteComponentProps } from "react-router";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";

import { Plugins } from "@capacitor/core";
const { Storage, Device } = Plugins;

const decrypt = (decryptVlaue) => {
  let passphrase =
    "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9";
  const bytes = CryptoJS.AES.decrypt(decryptVlaue, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};

const getUserId = async () => {
  const ret: any = await Storage.get({ key: "userId" });
  const temp = JSON.parse(decrypt(ret.value));
  return temp ? temp : null;
};

const getUserEmail = async () => {
  const ret: any = await Storage.get({ key: "userId" });
  const temp = JSON.parse(decrypt(ret.value));
  return temp && temp.email ? temp.email : null;
};

// const retComp = (segVal) => {
//   switch (segVal) {
//     case "basicinfo":
//       return <BasicInfo />
//     case "contactinform":
//       return <ContactInform />
//     case "financialinfo":
//       return <FinancialInfo />
//     case "otherinfo":
//       return <OtherInfo />
//     default:
//       return null
//   }
// }

// const showStep = (step) => {
//   switch (step) {
//     case 1:
//       return <BasicInfo />
//     case 2:
//       return <ContactInform />
//     case 3:
//       return <FinancialInfo />
//     case 4:
//       return <OtherInfo />
//     default:
//       return null
//   }
// }

const styles = {
  toolbar: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
  },
};
// const segs = [
//   {
//     label: "Basic Info",
//     value: "basicinfo"
//   },
//   {
//     label: "Contact Info",
//     value: "contactinform"
//   },
//   {
//     label: "Financial Info",
//     value: "financialinfo"
//   },
//   {
//     label: "Other Info",
//     value: "otherinfo"
//   }

// ]

interface ContactDetailPageProps
  extends RouteComponentProps<{
    id?: string;
  }> {}

const CreateProspect: React.FC<ContactDetailPageProps> = ({
  match,
  history,
}) => {
  // const selectedVal = (e) => {
  //     console.log(e)
  // }
  const [ErrorMsg, setErrorMsg] = useState<string>("Default Error Message");
  const [showError, setShowError] = useState({
    open: false,
    event: undefined,
  });

  const hist = useHistory();

  const renderStep = (
    step,
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    isedit,
    hubOwners,
    setFieldTouched,
    handleBlur
  ) => {
    switch (step) {
      case 1:
        return (
          <BasicInfo
            salutationlist={salutationlist}
            agegrplist={ageRange}
            jrnystglist={jStage}
            statuslist={statvals}
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            hubOwners={hubOwners}
            setFieldValue={setFieldValue}
            personaList={personaList}
            typelist={typelist}
            srclist={srclist}
            genderlist={genderlist}
            handleBlur={handleBlur}
          />
        );
      case 2:
        return (
          <ContactInform
            values={values}
            errors={errors}
            touched={touched}
            sourceOfName={sourceOfName}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            handleBlur={handleBlur}
          />
        );
      case 3:
        return (
          <FinancialInfo
            values={values}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            comppostn={comppostn}
            sector={sector}
            sow={sow}
            wealthRange={wealthRange}
            clsProb={clsProb}
            errors={errors}
            touched={touched}
            polexpP={polexpP}
            handleBlur={handleBlur}
          />
        );
      default:
        return (
          <OtherInfo
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            isedit={isedit}
            handleBlur={handleBlur}
          />
        );
    }
  };
  const [step, setStep] = useState(1);
  const [isLoading, setLoading] = useState<boolean>(false);

  const [ageRange, setageRange] = useState<any>([]);
  const [jStage, setjStage] = useState<any>([]);
  const [statvals, setstatvals] = useState<any>([]);
  const [personaList, setpersonaList] = useState<any>([]);
  const [typelist, settypelist] = useState<any>([
    { val: "Prospect", label: "Prospect" },
    { val: "Client", label: "Client" },
  ]);
  const [srclist, setsrclist] = useState<any>([]);
  const [genderlist, setgenderlist] = useState<any>([]);
  const [salutationlist, setsalutationlist] = useState<any>([]);
  const [polexpP, setpolexpP] = useState<any>([]);

  const [comppostn, setcomppostn] = useState<any>([]);
  const [sector, setsector] = useState<any>([]);
  const [sow, setsow] = useState<any>([]);
  const [wealthRange, setwealthRange] = useState<any>([]);
  const [clsProb, setclsProb] = useState<any>([]);
  const [sourceOfName, setsourceOfName] = useState<any>([]);
  const [formData, setformData] = useState<any>({});

  const [hubOwners, setOwners] = useState<any>([]);

  const [ownerforEdit, setownerforEdit] = useState<any>();
  const [assignedRM, setAssignedRM] = useState<any>();

  const [currentCadence, setcurrentCadence] = useState<string>("");
  const prospectParam = hist.location;
  let prospectData: any = prospectParam.state;

  const checkNCreateCadence = (values, Operation, contactId) => {
    const returnTimeStamp = (cadence) => {
      let today = moment(new Date()).format("YYYY-MM-DD");
      return cadence === "Every Month"
        ? moment(today)
            .add(1, "month")
            .add(8, "h")
            .format("x")
        : cadence === "Every Quarter"
        ? moment(today)
            .add(3, "months")
            .add(8, "h")
            .format("x")
        : moment(today)
            .add(6, "months")
            .add(8, "h")
            .format("x");
    };
    // console.log("CURRENT",currentCadence )
    // console.log("VLAUES",values.cadence )
    if (
      (Operation === "Create" && values.cadence !== "Meeting as Needed") ||
      (Operation === "Edit" && currentCadence !== values.cadence)
    ) {
      getUserId().then((Obj) => {
        const taskPayload = {
          engagement: {
            active: true,
            type: "TASK",
            ownerId: Obj.id,
            timestamp: returnTimeStamp(values.cadence),
            priority: "None",
          },
          associations: {
            contactIds: [contactId],
          },
          metadata: {
            status: "NOT_STARTED",
            subject: `Schedule a meeting with ${values.firstName} ${values.lastName}`,
            body: `${values.meeting_notes}`,
            taskType: "TODO",
            forObjectType: "CONTACT",
            reminders: [returnTimeStamp(values.cadence)],
          },
        };
        return api.createTask(taskPayload);
        // .then((resp) => {
        //   console.log(resp);
        // });
      });
    }
  };

  const handleSubmit = (values, errors) => {
    Device.getInfo().then((deviceInfo) => {
      getUserEmail().then((useremail) => {
        getUserId().then((Obj) => {
          setStep((step) => step + 1);
          // if(step === 2){
          //   if(values.priphnum.length != 10){
          //     errors.setErrors({
          //       PhnErrorCheck: "Phone number should be 10 digit",
          //     });
          //   }
          // }
          if (step === 4) {
            //prep properties
            setLoading(true);
            const payload = prepPayload(values);
            // console.log(payload.properties.hubspot_owner_id);
            // console.log(payload.properties);
            if (
              match.path === "/prospect/edit" ||
              match.path === "/client/edit"
            ) {
              if (
                payload.properties.lifecyclestage == "customer" ||
                payload.properties.hubspot_owner_id !== Obj.id
              ) {
                //console.log(match.params.id);
                api.getTTfromStore().then((resp) => {
                  let topTargetProspects = resp;
                  let ContactIdArray: any = [];
                  //console.log(resp)
                  if (
                    topTargetProspects &&
                    topTargetProspects !== null &&
                    topTargetProspects.length > 0
                  ) {
                    // console.log(topTargetProspects);
                    topTargetProspects.map((prosList) => {
                      ContactIdArray.push(prosList);
                    });
                    let updContArray: any = [];
                    let temprank = 0;
                    let tempflag = "N";
                    for (let i = 0; i < ContactIdArray.length; i++) {
                      if (ContactIdArray[i].contactid == prospectData.id) {
                        tempflag = "Y";
                      } else {
                        if (tempflag === "Y") {
                          temprank = i;
                        } else {
                          temprank = i + 1;
                        }
                        updContArray.push({
                          contactid: ContactIdArray[i].contactid,
                          rank: temprank,
                        });
                      }
                    }
                    let payloads: any = {
                      rm_id: Obj.id,
                      created_date: "2020-12-19T09:38:26",
                      updated_date: "2020-12-10T11:40:08",
                      prospect_list: JSON.stringify(updContArray),
                    };
                    // console.log(updContArray);
                    //update db
                    api.postTopTargets(payloads).then((resp) => {
                      let temp =
                        updContArray !== "" && updContArray !== null
                          ? updContArray
                          : [];
                      api
                        .setTopTargetsinStorage(JSON.stringify(temp))
                        .then(() => {
                          api.getTTfromStore().then((resp) => {});
                        });
                    });
                  }
                });
              }
              api.updateProspect(prospectData.id, payload).then((resp) => {
                // console.log(payload)
                if (payload.properties.lifecyclestage == "customer") {
                  if (deviceInfo.platform == "web") {
                    window.globalThis.firebase.analytics().setUserId(useremail);
                    window.globalThis.firebase
                      .analytics()
                      .logEvent("select_content", {
                        user_name: useremail,
                        content_type: "ClientUpdate",
                        item_id: resp.id,
                        device_id: deviceInfo ? deviceInfo.uuid : "",
                        platform: deviceInfo ? deviceInfo.platform : "",
                        device_model: deviceInfo ? deviceInfo.model : "",
                        device_type: deviceInfo ? deviceInfo.osVersion : "",
                        device_os: deviceInfo ? deviceInfo.operatingSystem : "",
                      });
                  } else {
                    FirebaseAnalytics.setUserId(useremail);
                    FirebaseAnalytics.logEvent("select_content", {
                      user_name: useremail,
                    });
                    FirebaseAnalytics.logEvent("select_content", {
                      content_type: "ClientUpdate",
                    });
                    FirebaseAnalytics.logEvent("select_content", {
                      item_id: resp.id,
                    });
                    FirebaseAnalytics.logEvent("select_content", {
                      device_id: deviceInfo ? deviceInfo.uuid : "",
                    });
                    FirebaseAnalytics.logEvent("select_content", {
                      platform: deviceInfo ? deviceInfo.platform : "",
                    });
                    FirebaseAnalytics.logEvent("select_content", {
                      device_model: deviceInfo ? deviceInfo.model : "",
                    });
                    FirebaseAnalytics.logEvent("select_content", {
                      device_type: deviceInfo ? deviceInfo.osVersion : "",
                    });
                    FirebaseAnalytics.logEvent("select_content", {
                      device_os: deviceInfo ? deviceInfo.operatingSystem : "",
                    });
                  }
                } else {
                  if (deviceInfo.platform == "web") {
                    window.globalThis.firebase.analytics().setUserId(useremail);
                    window.globalThis.firebase
                      .analytics()
                      .logEvent("select_content", {
                        user_name: useremail,
                        content_type: "UpdateProspect",
                        item_id: resp.id,
                        device_id: deviceInfo ? deviceInfo.uuid : "",
                        platform: deviceInfo ? deviceInfo.platform : "",
                        device_model: deviceInfo ? deviceInfo.model : "",
                        device_type: deviceInfo ? deviceInfo.osVersion : "",
                        device_os: deviceInfo ? deviceInfo.operatingSystem : "",
                      });
                  } else {
                    FirebaseAnalytics.setUserId(useremail);
                    FirebaseAnalytics.logEvent("select_content", {
                      user_name: useremail,
                    });
                    FirebaseAnalytics.logEvent("select_content", {
                      content_type: "UpdateProspect",
                    });
                    FirebaseAnalytics.logEvent("select_content", {
                      item_id: resp.id,
                    });
                    FirebaseAnalytics.logEvent("select_content", {
                      device_id: deviceInfo ? deviceInfo.uuid : "",
                    });
                    FirebaseAnalytics.logEvent("select_content", {
                      platform: deviceInfo ? deviceInfo.platform : "",
                    });
                    FirebaseAnalytics.logEvent("select_content", {
                      device_model: deviceInfo ? deviceInfo.model : "",
                    });
                    FirebaseAnalytics.logEvent("select_content", {
                      device_type: deviceInfo ? deviceInfo.osVersion : "",
                    });
                    FirebaseAnalytics.logEvent("select_content", {
                      device_os: deviceInfo ? deviceInfo.operatingSystem : "",
                    });
                  }
                }
                checkNCreateCadence(values, "Edit", prospectData.id);
                setLoading(false);
                hist.push(`/contactinfo/${prospectData.id}`);
              });
            } else {
              api.createProspect(payload).then((resp) => {
                //console.log(resp)
                if (resp.message) {
                  setStep(4);
                  setLoading(false);
                  setErrorMsg(resp.message);
                  setShowError({
                    open: true,
                    event: undefined,
                  });
                } else {
                  // console.log("HELLO THERE" + JSON.stringify(resp));
                  if (deviceInfo.platform == "web") {
                    window.globalThis.firebase.analytics().setUserId(useremail);
                    window.globalThis.firebase
                      .analytics()
                      .logEvent("select_content", {
                        user_name: useremail,
                        content_type: "NewProspect",
                        item_id: resp.id,
                        device_id: deviceInfo ? deviceInfo.uuid : "",
                        platform: deviceInfo ? deviceInfo.platform : "",
                        device_model: deviceInfo ? deviceInfo.model : "",
                        device_type: deviceInfo ? deviceInfo.osVersion : "",
                        device_os: deviceInfo ? deviceInfo.operatingSystem : "",
                      });
                  } else {
                    FirebaseAnalytics.setUserId(useremail);
                    FirebaseAnalytics.logEvent("select_content", {
                      user_name: useremail,
                    });
                    FirebaseAnalytics.logEvent("select_content", {
                      content_type: "NewProspect",
                    });
                    FirebaseAnalytics.logEvent("select_content", {
                      item_id: resp.id,
                    });
                    FirebaseAnalytics.logEvent("select_content", {
                      device_id: deviceInfo ? deviceInfo.uuid : "",
                    });
                    FirebaseAnalytics.logEvent("select_content", {
                      platform: deviceInfo ? deviceInfo.platform : "",
                    });
                    FirebaseAnalytics.logEvent("select_content", {
                      device_model: deviceInfo ? deviceInfo.model : "",
                    });
                    FirebaseAnalytics.logEvent("select_content", {
                      device_type: deviceInfo ? deviceInfo.osVersion : "",
                    });
                    FirebaseAnalytics.logEvent("select_content", {
                      device_os: deviceInfo ? deviceInfo.operatingSystem : "",
                    });
                  }

                  checkNCreateCadence(values, "Create", resp.id);
                  setLoading(false);
                  hist.push(`/contactinfo/${resp.id}`);
                }
              });
            }
          }
        });
      });
    });
  };

  const retValSchema = () => {
    return step === 1
      ? Yup.object().shape({
          firstName: Yup.string()
            .max(255)
            .required("Please enter a first name in English"),
          lastName: Yup.string()
            .max(255)
            .required("Please enter a last name in English"),
          arbfirstName: Yup.string()
            .max(255)
            .matches(/^[\u0600-\u06FF]/, "Please enter Arabic Characters only"),
          // .required("Please enter a first name in Arabic"),
          arbmiddleName: Yup.string()
            .max(255)
            .matches(/^[\u0600-\u06FF]/, "Please enter Arabic Characters only"),
          arblastName: Yup.string()
            .max(255)
            .matches(/^[\u0600-\u06FF]/, "Please enter Arabic Characters only"),
          // .required("Please enter a last name in Arabic"),
          persona: Yup.string()
            .max(255)
            .required("Please select a persona"),
        })
      : step === 2
      ? Yup.object().shape({
          email: Yup.string()
            .email("Plese Enter a valid email")
            .max(255)
            .required("Please  enter email Address"),

          priphnum: Yup.string()
            .matches(/^\+*[0-9]+$/, "Please enter only numbers")
            .required("Please enter Primary Phone"),
        })
      : step === 3
      ? Yup.object().shape({
          exp_closing_prob: Yup.string().required(
            "Please select a expected closing probability"
          ),
          exp_mnth_close: Yup.string().required(
            "Please select a expected month of closing"
          ),
          exp_close_amt: Yup.string().required(
            "Please select a expected closing amount"
          ),
        })
      : Yup.object().shape();
  };

  let mpath = match.path;
  let mcontactId = prospectData?.id;

  useEffect(() => {
    getUserId().then((userObj) => {
      setLoading(true);
      api.getContactPropertiesByProperty("age_range").then((resp) => {
        if (resp?.type === "enumeration") {
          setageRange(
            resp.options.map((o) => {
              return { val: o.value, label: o.label };
            })
          );
        }
      });
      // api.getContactPropertiesByProperty("prospect_stages").then(resp => {
      //   if (resp.type === "enumeration") {
      //     setjStage(resp.options.map(o => { return { val: o.value, label: o.label } }))
      //   }
      // })
      api.getContactPropertiesByProperty("lead_stage").then((resp) => {
        let filterset11 = resp?.options.filter(
          (item) =>
            item.label == "New Lead" ||
            item.label == "Cleared Lead" ||
            item.label == "SQL" ||
            item.label == "Booked Meeting" ||
            item.label == "Meeting face 2 face" ||
            item.label == "RISK QUESTIONNAIRE ANSWERED" ||
            item.label == "OFFER SENT" ||
            item.label == "15 DAYS TO CLOSE" ||
            item.label == "30 DAYS TO CLOSE" ||
            item.label == "60+ DAYS TO CLOSE" ||
            item.label == "COMMITTED CAPITAL" ||
            item.label == "INACTIVE" ||
            item.label == "LOST" ||
            item.label == "Competition" ||
            item.label == "Disqualified" ||
            item.label == "Not interested SQL" ||
            item.label == "Unresponsive SQL"
        );
        if (resp?.type === "enumeration") {
          setjStage(
            // resp.options.map((o) => {
            filterset11.map((o) => {
              return { val: o.value, label: o.label };
            })
          );
        }
      });
      // api.getContactPropertiesByProperty("prospect_status").then(resp => {
      //   if (resp.type === "enumeration") {
      //     setstatvals(resp.options.map(o => { return { val: o.value, label: o.label } }))
      //   }
      // })
      // api.getContactPropertiesByProperty("hs_lead_status").then(resp => {
      //   if (resp.type === "enumeration") {
      //     setstatvals(resp.options.map(o => { return { val: o.value, label: o.label } }))
      //   }
      // })
      setstatvals([
        { val: "Active", label: "Active" },
        { val: "Dormant", label: "Dormant" },
        { val: "Inactive", label: "Inactive" },
        { val: "Not Qualified", label: "Not Qualified" },
        { val: "Dead - do not contact", label: "Dead - do not contact" },
      ]);
      api.getContactPropertiesByProperty("segment").then((resp) => {
        if (resp?.type === "enumeration") {
          setpersonaList(
            resp?.options.map((o) => {
              return { val: o.value, label: o.label };
            })
          );
        }
      });
      // api.getContactPropertiesByProperty("type").then(resp => {
      //   if (resp.type === "enumeration") {
      //     settypelist(resp.options.map(o => { return { val: o.value, label: o.label } }))
      //   }
      // })
      api.getContactPropertiesByProperty("contact_type").then((resp) => {
        if (resp?.type === "enumeration") {
          setsrclist(
            resp.options.map((o) => {
              return { val: o.value, label: o.label };
            })
          );
        }
      });
      api.getContactPropertiesByProperty("gender").then((resp) => {
        if (resp?.type === "enumeration") {
          setgenderlist(
            resp.options.map((o) => {
              return { val: o.value, label: o.label };
            })
          );
        }
      });
      api.getContactPropertiesByProperty("title_salutation").then((resp) => {
        if (resp?.type === "enumeration") {
          setsalutationlist(
            resp.options.map((o) => {
              return { val: o.value, label: o.label };
            })
          );
        }
      });

      api.getContactPropertiesByProperty("position").then((resp) => {
        if (resp?.type === "enumeration") {
          setcomppostn(
            resp.options.map((o) => {
              return { val: o.value, label: o.label };
            })
          );
        }
      });
      api.getContactPropertiesByProperty("industry_").then((resp) => {
        if (resp?.type === "enumeration") {
          setsector(
            resp.options.map((o) => {
              return { val: o.value, label: o.label };
            })
          );
        }
      });
      api.getContactPropertiesByProperty("source_of_wealth").then((resp) => {
        if (resp?.type === "enumeration") {
          setsow(
            resp.options.map((o) => {
              return { val: o.value, label: o.label };
            })
          );
        }
      });
      api
        .getContactPropertiesByProperty("expected_wealth_range")
        .then((resp) => {
          if (resp?.type === "enumeration") {
            setwealthRange(
              resp.options.map((o) => {
                return { val: o.value, label: o.label };
              })
            );
          }
        });
      api
        .getContactPropertiesByProperty("expected_closing_probability")
        .then((resp) => {
          if (resp?.type === "enumeration") {
            setclsProb(
              resp.options.map((o) => {
                return { val: o.value, label: o.label };
              })
            );
          }
        });
      api.getContactPropertiesByProperty("source_of_name").then((resp) => {
        if (resp?.type === "enumeration") {
          setsourceOfName(
            resp.options.map((o) => {
              return { val: o.value, label: o.label };
            })
          );
        }
      });
      api
        .getContactPropertiesByProperty("politically_exposed_persons_pep")
        .then((resp) => {
          if (resp?.type === "enumeration") {
            setpolexpP(
              resp.options.map((o) => {
                return { val: o.value, label: o.label };
              })
            );
          }
        });

      api
        .getOwners()
        .then((resp) => {
          // console.log(JSON.stringify(resp) + "QWERTY");
          setOwners(resp.results);

          if (mpath === "/prospect/edit" || mpath === "/client/edit") {
            api
              .getContactDetails(mcontactId)
              .then((resp) => {
                // console.log(resp)
                const {
                  politically_exposed_persons_pep,
                  title_salutation,
                  firstname,
                  middle_name,
                  lastname,
                  first_name_arabic,
                  arabic_last_name,
                  arabic_middle_name,
                  gender,
                  age_range,
                  email,
                  phone,
                  mobilephone,
                  address_line_1,
                  address_line_2,
                  city,
                  country,
                  nationality_1_,
                  company,
                  position,
                  industry_,
                  source_of_wealth,
                  expected_wealth_range,
                  bio_profile,
                  general_remarks,
                  preferred_channel,
                  degree,
                  school,
                  segment,
                  contact_type,
                  type,
                  prospect_stages,
                  // lifecycle_stage_status,
                  lead_stage,
                  prospect_status,
                  hs_lead_status,
                  financial_sophistication,
                  technology_sophistication,
                  building_details,
                  politically_exposed_persons_pep_,
                  sharia_compliant,
                  expected_closing_probability,
                  amount_status,
                  hubspot_owner_id,
                  investment_objectives,
                  meeting_cadence,
                  assigned_rm,
                  source_of_name,
                  expected_month_of_closing,
                  preferred_time_of_day,
                  hs_lifecyclestage_customer_date,
                } = resp.properties;

                //   const getAssignedRM = (hubspot_owner_id) =>{
                //     let tempOwner: any = []
                //     if(hubspot_owner_id){
                //       api.getOwners().then((resp) => {
                //         tempOwner = resp.results.filter((obj) => obj.id === parseInt(hubspot_owner_id));
                //         console.log(tempOwner)
                //         return (tempOwner[0].firstName && tempOwner[0].lastName !== ""
                //         ? `${tempOwner[0].firstName} ${tempOwner[0].lastName}`
                //         : tempOwner[0].email)
                //     })
                //   }
                // }
                // let eduObj = additional_qualification_degree ?  JSON.parse(additional_qualification_degree) : ''
                setcurrentCadence(meeting_cadence);

                setformData({
                  hubspot_owner_id: hubspot_owner_id,
                  salutation: title_salutation,
                  // additional_qualification_degree: additional_qualification_degree,
                  assigned_rm:
                    hubspot_owner_id === ""
                      ? ""
                      : userObj.fName && userObj.fName !== ""
                      ? `${userObj.fName} ${userObj.lName}`
                      : userObj.email,
                  firstName: firstname,
                  middleName: middle_name,
                  lastName: lastname,
                  arbfirstName: first_name_arabic,
                  arbmiddleName: arabic_middle_name,
                  arblastName: arabic_last_name,
                  persona: segment,
                  source: contact_type,
                  type: hs_lifecyclestage_customer_date ? "Client" : "Prospect",
                  agegrp: age_range,
                  // jrnystg: lifecycle_stage_status,
                  jrnystg: lead_stage,
                  // status: prospect_status,
                  status: hs_lead_status,
                  gender: gender,
                  techsophis: technology_sophistication,
                  finsophis: financial_sophistication,
                  email: email,
                  // priphnum: phone !== null && phone !== "" ? phone.split(" ")[1] : "",
                  priphnum: phone,
                  phCode:
                    phone !== null && phone !== ""
                      ? phone.split(" ")[0]
                      : "+966",
                  phCodeWht:
                    mobilephone !== null && mobilephone !== ""
                      ? mobilephone.split(" ")[0]
                      : "+966",
                  // whtphnum:
                  //   mobilephone !== null && mobilephone !== ""
                  //     ? mobilephone.split(" ")[1]
                  //     : "",
                  whtphnum: mobilephone,
                  addln1: address_line_1,
                  addln2: address_line_2,
                  city: city,
                  country: country,
                  nationality: nationality_1_,
                  bldnum: building_details,
                  // ofcnum: office_number,
                  // flrnum: floor_number,
                  Prefphcall:
                    preferred_channel !== null
                      ? preferred_channel.split(";").includes("Call")
                      : false,
                  Prefemail:
                    preferred_channel !== null
                      ? preferred_channel.split(";").includes("Email")
                      : false,
                  PrefSMS:
                    preferred_channel !== null
                      ? preferred_channel.split(";").includes("SMS")
                      : false,
                  PrefWht:
                    preferred_channel !== null
                      ? preferred_channel.split(";").includes("Whatsapp")
                      : false,
                  PrefMor: preferred_time_of_day
                    ? preferred_time_of_day.includes("Morning")
                    : false,
                  PrefNoon: preferred_time_of_day
                    ? preferred_time_of_day.includes("Afternoon")
                    : false,
                  PrefEve: preferred_time_of_day
                    ? preferred_time_of_day.includes("Evening")
                    : false,
                  company: company,
                  position: position,
                  sector: industry_,
                  sow: source_of_wealth.split(";"),
                  exp_wealth_range: expected_wealth_range,
                  bio: bio_profile,
                  exp_closing_prob: expected_closing_probability,
                  exp_mnth_close: expected_month_of_closing,
                  exp_close_amt: amount_status,
                  // inv_obj: investment_objectives,
                  acdInst: school,
                  degree: degree,
                  meeting_notes: general_remarks,
                  cadence: "Meeting as Needed",
                  pol_exp: politically_exposed_persons_pep,
                  sharia_comp: sharia_compliant === "true" ? true : false,
                  // acdInst1: eduObj !== null && eduObj.edu && eduObj.edu[0] ? eduObj.edu[0].institution : '',
                  // acdInst2: eduObj !== null && eduObj.edu && eduObj.edu[1] ? eduObj.edu[1].institution : '',
                  // acdInst3: eduObj !== null && eduObj.edu && eduObj.edu[2] ? eduObj.edu[2].institution : '',
                  // acdInst4: eduObj !== null && eduObj.edu && eduObj.edu[3] ? eduObj.edu[3].institution : '',
                  // acdInst5: eduObj !== null && eduObj.edu && eduObj.edu[4] ? eduObj.edu[4].institution : '',
                  // degree1: eduObj !== null && eduObj.edu && eduObj.edu[0] ? eduObj.edu[0].degree : '',
                  // degree2: eduObj !== null && eduObj.edu && eduObj.edu[1] ? eduObj.edu[1].degree : '',
                  // degree3: eduObj !== null && eduObj.edu && eduObj.edu[2] ? eduObj.edu[2].degree : '',
                  // degree4: eduObj !== null && eduObj.edu && eduObj.edu[3] ? eduObj.edu[3].degree : '',
                  // degree5: eduObj !== null && eduObj.edu && eduObj.edu[4] ? eduObj.edu[4].degree : '',
                  source_of_name: source_of_name,
                });
                setLoading(false);
              })
              .catch((error) => {
                console.error(error);
                setLoading(false);
              });
          } else {
            setformData({
              // hubspot_owner_id: userObj.id,
              salutation: "",
              assigned_rm:
                userObj.fName && userObj.fName !== ""
                  ? `${userObj.fName} ${userObj.lName}`
                  : userObj.email,
              firstName: "",
              middleName: "",
              lastName: "",
              arbfirstName: "",
              arbmiddleName: "",
              arblastName: "",
              persona: "",
              source: "TFO",
              type: "Prospect",
              agegrp: "31 - 40",
              jrnystg: "New Lead",
              status: "Active",
              gender: "Yes",
              techsophis: "High",
              finsophis: "High",
              email: "",
              priphnum: "",
              phCode: "+966",
              phCodeWht: "+966",
              whtphnum: "",
              addln1: "",
              addln2: "",
              city: "",
              country: "Saudi Arabia",
              nationality: "",
              bldnum: "",
              ofcnum: "",
              flrnum: "",
              Prefphcall: false,
              Prefemail: false,
              PrefSMS: false,
              PrefWht: false,
              PrefMor: true,
              PrefNoon: false,
              PrefEve: false,
              company: "",
              position: "",
              sector: "",
              sow: "",
              exp_wealth_range: "",
              bio: "",
              exp_closing_prob: "",
              exp_mnth_close: "",
              exp_close_amt: "",
              // inv_obj: 'Growth',
              acdInst: "",
              degree: "",
              meeting_notes: "",
              cadence: "Meeting as Needed",
              pol_exp: "No",
              sharia_comp: true,
              // acdInst1: '',
              // acdInst2: '',
              // acdInst3: '',
              // acdInst4: '',
              // acdInst5: '',
              // degree1: '',
              // degree2: '',
              // degree3: '',
              // degree4: '',
              // degree5: '',
              source_of_name: "",
            });
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    });
  }, [mcontactId, mpath]);

  const validate = (values) => {
    const errors = {};
    return errors;
  };

  const goback = () => {
    setStep((step) => step - 1);
  };

  // const [selSegment, setselSegment] = useState<any>("basicinfo");
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode="md" style={styles.toolbar}>
          <IonButtons slot="start">
            {/* <IonIcon icon="arrowBack"></IonIcon> */}
            <IonBackButton
              style={{ textTransform: "none" }}
              color="white"
              text={
                match.path === "/prospect/edit"
                  ? "Prospect Profile"
                  : match.path === "/client/edit"
                  ? "Client Profile"
                  : "Prospect List"
              }
              icon={chevronBack}
            ></IonBackButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRow
          class="margin-set mt-6"
          style={{ marginTop: 24, marginLeft: 32 }}
        >
          <IonText
            class="fs-28"
            style={{ fontWeight: 300, fontSize: 28 }}
            color="greyscale"
          >
            {match.path === "/prospect/edit"
              ? "Edit Prospect"
              : match.path === "/client/edit"
              ? "Edit Client"
              : "Create Prospect"}
          </IonText>
        </IonRow>
        <FormSegments actSeg={step} />
        <IonRow class="margin-set">
          <IonGrid>
            <Formik
              enableReinitialize
              initialValues={{ ...formData }}
              onSubmit={handleSubmit}
              validate={validate}
              validationSchema={retValSchema()}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                setFieldValue,
                setFieldTouched,
                handleBlur,
              }) => (
                <Form>
                  {renderStep(
                    step,
                    values,
                    errors,
                    touched,
                    handleChange,
                    setFieldValue,
                    match.path === "/prospect/edit" ||
                      match.path === "/client/edit"
                      ? true
                      : false,
                    hubOwners,
                    setFieldTouched,
                    handleBlur
                  )}
                  <StepButton
                    step={step}
                    goback={goback}
                    isEdit={
                      match.path === "/prospect/edit" ||
                      match.path === "/client/edit"
                        ? true
                        : false
                    }
                    path={match.path}
                  />
                </Form>
              )}
            </Formik>
            {/* <Segments 
        buttons={segs}
        selectedValue={setselSegment}        
      />     
      {
          retComp(selSegment)
      }  */}

            {/* <BasicInfo></BasicInfo>
      <ContactInform></ContactInform> */}
          </IonGrid>
        </IonRow>
        <IonPopover
          isOpen={showError.open}
          cssClass="my-custom-classA popover-class-A"
          event={undefined}
          // onDidDismiss={e => setShowPopover({ open: false, event: undefined })}
          mode="md"
        >
          <IonCard style={{ margin: 0, color: "" }}>
            <div>
              <IonGrid class="popoverGridClass_A">
                <IonRow
                  style={{
                    color: "#828282",
                    justifyContent: "center",
                    fontWeight: 400,
                    fontSize: 32,
                  }}
                  class="ion-text-center ion-margin-top ion-margin-bottom"
                >
                  {ErrorMsg}
                </IonRow>
                <IonRow
                  class="ion-text-center ion-margin-bottom"
                  style={{
                    color: "#B99855",
                    cursor: "pointer",
                    marginTop: "60px",
                    borderTop: "solid 1px rgba(185, 152, 85,0.2)",
                    justifyContent: "center",
                  }}
                >
                  <IonCol
                    style={{ border: "solid 2px rgba(185, 152, 85,0.2)" }}
                    onClick={() =>
                      setShowError({ open: false, event: undefined })
                    }
                  >
                    <IonButton class="ConfirmationButton" fill="clear">
                      OK
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </IonCard>
        </IonPopover>
      </IonContent>
      <IonLoading
        cssClass="my-custom-loading-class"
        isOpen={isLoading}
        onDidDismiss={() => setLoading(false)}
        message={"Please wait..."}
      />
    </IonPage>
  );
};

export default CreateProspect;
