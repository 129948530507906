import React, { useEffect, useState } from "react";
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import useWindowDimensions from "src/components/useWindowDimensions";
import CustomRoutes from "src/router";
import { briefcase } from "ionicons/icons";
import grid from "src/icons/dashboard.svg";
import bar from "src/icons/bar.svg";
import calendar from "src/icons/calendar.svg";
import list from "src/icons/todo.svg";
// import prospects from "src/icons/prospects.svg";
// import briefcase from "src/icons/briefcase.svg";
import "./App.css";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
import { Plugins } from "@capacitor/core";
/* Theme variables */
import "./theme/variables.css";
import history from "./api/history";
import { datadogRum } from "@datadog/browser-rum";
const { Device } = Plugins;
const styles = {
  label: {
    fontSize: 14,
  },
};

datadogRum.init({
  applicationId: "0a17c0f0-87d7-449b-8c83-7e1fc7560a47",
  clientToken: "pub363fc1f12e3083d59d2e762a2686e359",
  site: "datadoghq.eu",
  service: "rm-app",
  env: "prod",
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sampleRate: 100,
  trackInteractions: true,
  defaultPrivacyLevel: "mask-user-input",
});

datadogRum.startSessionReplayRecording();
const App: React.FC = () => {
  const { height, width } = useWindowDimensions();
  const [currLoc, setcurrLoc] = useState<any>("");
  const location = [
    "/dashboard",
    "/calendar",
    "/todo",
    "/portfolio",
    "/material",
  ];
  const [desktopHeader, setDesktopHeader] = useState(false);
  const [deviceInfo, setdeviceInfo] = useState<any>();
  const getPath = (pathname) => {
    if (!(currLoc !== "/login" && pathname !== "/login")) {
      setcurrLoc(pathname);
    }
    location.includes(pathname)
      ? setDesktopHeader(false)
      : setDesktopHeader(true);
  };
  useEffect(() => {
    Device.getInfo().then((info) => {
      setdeviceInfo(info);
    });
  }, []);

  return (
    <IonApp class="main-app-cls">
      <IonReactRouter history={history}>
        <IonTabs>
          <IonRouterOutlet>
            <CustomRoutes getPath={getPath} />
          </IonRouterOutlet>
          <IonTabBar
            slot={
              deviceInfo && deviceInfo.platform === "web" && width > 1024
                ? "top"
                : "bottom"
            }
            className="pos-abs-header tab-bar"
            hidden={
              deviceInfo && deviceInfo.platform === "web" && width > 1024
                ? desktopHeader
                : currLoc === "/login"
                ? true
                : false
            }
          >
            <IonTabButton className="tab-btn" tab="dashboard" href="/dashboard">
              <IonIcon color="greyscale" icon={grid} />
              <IonLabel color="greyscale" style={styles.label}>
                Dashboard
              </IonLabel>
            </IonTabButton>
            <IonTabButton className="tab-btn" tab="calendar" href="/calendar">
              <IonIcon color="greyscale" icon={calendar} />
              <IonLabel color="greyscale" style={styles.label}>
                Calendar
              </IonLabel>
            </IonTabButton>
            <IonTabButton className="tab-btn" tab="todo" href="/todo">
              <IonIcon color="greyscale" icon={list} />
              <IonLabel color="greyscale" style={styles.label}>
                To-Do
              </IonLabel>
            </IonTabButton>
            <IonTabButton className="tab-btn" tab="material" href="/material">
              <IonIcon color="greyscale" icon={bar} />
              <IonLabel color="greyscale" style={styles.label}>
                Material
              </IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
