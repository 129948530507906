import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonRow,
  IonToast,
} from "@ionic/react";
import moment from "moment";
import { closeOutline } from "ionicons/icons";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { fireBaseLog } from "./FireBase";
type InvestmentCardProps = {
  imgSrc: string;
  subtitle: string;
  title: string;
  publishDate: string;
  routerPath: string;
  filename: string;
  section: string;
};
export default function InvestmentCard({
  imgSrc,
  subtitle,
  title,
  publishDate,
  routerPath,
  filename,
  section,
}: InvestmentCardProps) {
  const [showToast, setShowToast] = useState(false);
  const style = {
    cardRow: {
      alignItems: "center",
      justifyContent: "space-between",
      padding: "16px",
    },
  };
  const router = useHistory();
  return (
    <IonCard style={{ marginLeft: 0, background: "#1A1A1A" }}>
      {/* <img alt="Silhouette of mountains" src={imgSrc} className="heroImage" /> */}
      <IonRow style={style.cardRow}>
        <IonCol style={{ flex: "1 0 0%", overflow: "hidden" }}>
          <IonCardHeader style={{ padding: 0 }}>
            <IonCardTitle className="InvcardTitle">{title}</IonCardTitle>
            <IonCardSubtitle className="InvcardSubTitle">
              Date: {moment(publishDate).format("DD/MM/YY")}
            </IonCardSubtitle>
          </IonCardHeader>
        </IonCol>
        <IonCol size="auto">
          <span
            onClick={() => {
              if (routerPath == "No Pdf file found") {
                setShowToast(true);
                return;
              }
              const isPdfArr = filename.split(".");
              if (isPdfArr[isPdfArr.length - 1] == "pdf") {
                if (window) {
                  window.open(routerPath, "_blank");
                }
              } else {
                fireBaseLog({
                  title: `${section} Product Update`,
                  time_spent: title,
                });
                let a = document.createElement("a");
                a.href = filename;
                a.setAttribute("download", filename);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
              }
            }}
            style={{
              textDecoration: "none",
              fontWeight: 400,
              fontSize: "14px !important",
              lineHeight: "120%",
              color: "#B99855",
              cursor: "pointer",
            }}
          >
            View details
          </span>
          <IonToast
            isOpen={showToast}
            onDidDismiss={() => setShowToast(false)}
            message={routerPath}
            color="danger"
            buttons={[
              {
                icon: closeOutline,
                role: "cancel",
              },
            ]}
            duration={1500}
            position="top"
            cssClass="custom-toaster"
          />
        </IonCol>
      </IonRow>
    </IonCard>
  );
}
