import React, {useState} from 'react';
import { IonPopover, IonList, IonItem } from '@ionic/react';
import "./EditNotePopover.css"
import DeleteConfirm from "./DeleteConfirm"
import { useHistory } from "react-router-dom";
const styles = {
    list: {
        padding: 0,
        
    },
    item: {
        "--background:": "#1a1a1a",
        fontSize:14
    }
}

interface MenuProps {
    open: boolean;
    event: Event | undefined;
    setShowPopover: Function
    contactId: string;
    noteId: string;
    delRefreshNotes: Function;
}

interface ShwConfState {
    open: boolean;
    event: Event | undefined;
  }

const EditNoteMenu: React.FC<MenuProps> = ({
    open,
    event,
    setShowPopover,
    contactId,
    noteId,
    delRefreshNotes
}) => {
    
    const delRefresh = () => {
        delRefreshNotes()
    }

    const [showConfirm, setShowConfirm] = useState<ShwConfState>({
        open: false,
        event: undefined,
      });
      const history = useHistory()

    return (
        <React.Fragment>
        <IonPopover
            isOpen={open}
            showBackdrop={true}
            cssClass='popover-class-A'
            event={event}
            onDidDismiss={e => setShowPopover({ open: false, event: undefined })}
            mode="ios"
        >
            <IonList style={styles.list} color="step-100">
                <IonItem detailIcon="none" lines="full" 
                style={styles.item} 
               // routerLink={`/meetingnotedetailsedit/${contactId}/${noteId}`}
               onClick={(e)=>history.push({
                pathname: '/meetingnotedetailsedit',
                state: {contId:contactId,noteId:noteId}
              })}
                >
                    Edit</IonItem>
                <IonItem style={styles.item} lines="none" onClick={(e) => {
                    setShowConfirm({ open: true, event: undefined}) 
                    setShowPopover({ open: false, event: undefined }) 
                }}>Delete</IonItem>
            </IonList>
        </IonPopover>
            <DeleteConfirm
            open={showConfirm.open}
            event={undefined}
            setShowPopover={() => setShowConfirm({ open: false, event: undefined})}
            noteId={noteId}
            delRefresh={delRefresh}
        />
        </React.Fragment>
    );
}
export default EditNoteMenu