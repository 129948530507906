import React, { useEffect, useState } from "react";
import {
  IonCol,
  IonGrid,
  IonRow,
  IonLoading,
  IonText,
  IonIcon,
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonImg,
  IonAvatar,
} from "@ionic/react";
import useWindowDimensions from "src/components/useWindowDimensions";
import { chevronForwardOutline, search } from "ionicons/icons";
import { useHistory } from "react-router-dom";
import VideoCard from "../../components/Material/VideoCard";
import InvestmentCard from "../../components/Material/InvestmentCard";
import "./Material.css";
import api from "src/api";
const min = 0;
const max = 3;
interface ProfPopup {
  open: boolean;
  event: Event | undefined;
}
export default function Material() {
  const router = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [weeklyUpdateData, setWeeklyUpdateData] = useState<any>([]);
  const [digitalUpdateData, setDigitalUpdateData] = useState<any>([]);
  const [invUpdateData, setInvUpdateData] = useState<any>([]);
  const { width } = useWindowDimensions();
  const [ProfPopovermenu, setProfPopovermenu] = useState<ProfPopup>({
    open: false,
    event: undefined,
  });
  function iOS() {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }
  useEffect(() => {
    getWeeklyUpdateData();
    getInvestmentUpdates();
    getDigitalUpdates();
  }, []);
  const triggerSearch = () => {
    router.push("/prospects/search");
  };
  const getWeeklyUpdateData = () => {
    setLoading(true);
    return api
      .getWeeklyUpdateData()
      .then((response) => {
        if (response.stories.length > 0) {
          let minDataSet = response.stories;
          const sortedData = minDataSet.sort(function(a, b) {
            return (
              new Date(a.content.PublishDate).valueOf() -
              new Date(b.content.PublishDate).valueOf()
            );
          });
          setWeeklyUpdateData(sortedData.reverse().splice(min, max));
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const getInvestmentUpdates = () => {
    setLoading(true);
    return api
      .getInvestmentUpdate()
      .then((response) => {
        if (response.stories.length > 0) {
          const data = response.stories;
          const sortedData = data.sort(function(a, b) {
            return (
              new Date(a.content.PublishDate).valueOf() -
              new Date(b.content.PublishDate).valueOf()
            );
          });
          setInvUpdateData(sortedData.reverse().splice(min, max));
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const getDigitalUpdates = () => {
    setLoading(true);
    return api
      .getDigitalUpdate()
      .then((response) => {
        if (response.stories.length > 0) {
          const data = response.stories;
          const sortedData = data.sort(function(a, b) {
            return (
              new Date(a.content.PublishDate).valueOf() -
              new Date(b.content.PublishDate).valueOf()
            );
          });
          setDigitalUpdateData(sortedData.reverse().splice(min, max));
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  const handleIndividualVideoClick = (id: number, title: string) => {
    router.push(`/weeklyUpdateVideo/${id}`);
  };
  return (
    // <React.Fragment>
    <IonPage>
      <IonHeader>
        <IonToolbar>
          {iOS() === true ? (
            <IonGrid className="rootgrid margintopn10">
              <IonRow>
                <IonCol>
                  <IonImg
                    className="ion-text-start"
                    src="/assets/brand_logo.svg"
                    style={{ width: 75 }}
                  />
                </IonCol>
                <IonCol>
                  <IonGrid className="ion-float-right">
                    <IonRow>
                      <IonIcon
                        color="white"
                        icon={search}
                        size="small"
                        onClick={triggerSearch}
                        className="trig-srch"
                      ></IonIcon>
                      <IonText
                        className="srchplaceholder ion-margin-horizontal trig-srch"
                        onClick={triggerSearch}
                      >
                        {width > 575
                          ? "Search for any prospect or client"
                          : "Search"}
                      </IonText>
                      <IonAvatar
                        onClick={(e) => {
                          setProfPopovermenu({
                            open: true,
                            event: e.nativeEvent,
                          });
                        }}
                        style={{ width: 24, height: 24 }}
                      >
                        <img src="/assets/empty-avatar.svg" alt="" />
                      </IonAvatar>
                    </IonRow>
                  </IonGrid>
                </IonCol>
              </IonRow>
            </IonGrid>
          ) : (
            <IonGrid className="rootgrid">
              <IonRow>
                <IonCol>
                  <IonImg
                    className="ion-text-start"
                    src="/assets/brand_logo.svg"
                    style={{ width: 75 }}
                  />
                </IonCol>
                <IonCol>
                  <IonGrid className="searchCss ion-float-right">
                    <IonRow>
                      <IonIcon
                        color="white"
                        icon={search}
                        size="small"
                        onClick={triggerSearch}
                        className="trig-srch"
                      ></IonIcon>
                      <IonText
                        className="srchplaceholder ion-margin-horizontal trig-srch"
                        onClick={triggerSearch}
                      >
                        {width > 575
                          ? "Search for any prospect or client"
                          : "Search"}
                      </IonText>

                      <IonAvatar
                        onClick={(e) => {
                          setProfPopovermenu({
                            open: true,
                            event: e.nativeEvent,
                          });
                        }}
                        style={{ width: 24, height: 24 }}
                      >
                        <img src="/assets/empty-avatar.svg" alt="" />
                      </IonAvatar>
                    </IonRow>
                  </IonGrid>
                </IonCol>
              </IonRow>
            </IonGrid>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent style={{ "--background": "none" }}>
        <IonLoading
          cssClass="my-custom-loading-class"
          isOpen={isLoading}
          onDidDismiss={() => setLoading(false)}
          message={"Please wait..."}
        />
        <IonGrid class="" style={{ marginTop: 24 }}>
          <IonRow
            class="mt-6 margin-set m-marginleft-12 m-marginright-12 dashgrid-mobile"
            style={{ marginBottom: 40 }}
          >
            <span
              className=""
              style={{
                fontWeight: 300,
                fontSize: 24,
                lineHeight: "120%",
                color: "#C7C7C7",
              }}
            >
              Material
            </span>
          </IonRow>
          <IonRow class="ion-margin-top margin-set m-marginleft-12 m-marginright-12 dashgrid-mobile">
            <IonRow
              className="ion-justify-content-between ion-align-items-center"
              style={{ width: "100%", paddingRight: 10, marginBottom: 15 }}
            >
              <IonText
                color="greyscale"
                style={{
                  fontSize: "20px",
                  fontWeight: 400,
                  lineHeight: "120%",
                  color: "#FFFFFF",
                }}
              >
                Weekly Market Updates
              </IonText>
              <a
                href="/weeklyVideoSeeMore"
                style={{ color: "rgb(191, 161, 100)", textDecoration: "none" }}
              >
                <IonRow
                  className="ion-align-items-center"
                  style={{ width: "auto", margin: 0 }}
                >
                  <IonText
                    style={{
                      fontWeight: 500,
                      fontSize: "17.5px",
                      lineHeight: "25px",
                      color: "#B99855",
                    }}
                  >
                    See all
                  </IonText>
                  <IonIcon
                    style={{ marginLeft: "8px" }}
                    icon={chevronForwardOutline}
                  ></IonIcon>
                </IonRow>
              </a>
            </IonRow>
            <IonGrid style={{ padding: "0px" }}>
              <IonRow className="weeklyRow">
                {weeklyUpdateData.map((item, i) => (
                  <IonCol
                    style={{ padding: "0px", cursor: "pointer" }}
                    size="12"
                    size-md="6"
                    size-xl="4"
                    onClick={(e) => {
                      handleIndividualVideoClick(item.id, item.name);
                    }}
                    className="weeklyCol"
                    key={i}
                  >
                    <VideoCard
                      imgSrc={item.content.PosterImage.filename}
                      descp={item.content.Description}
                      title={item.content.Title}
                      publishDate={item.content.PublishDate.slice(0, 10)}
                      duration={item.content.EstimatedDuration}
                    />
                  </IonCol>
                ))}
              </IonRow>
            </IonGrid>
          </IonRow>
          <IonRow class="ion-margin-top margin-set m-marginleft-12 m-marginright-12 dashgrid-mobile">
            <IonRow
              className="ion-justify-content-between ion-align-items-center"
              style={{ width: "100%", paddingRight: 10, marginBottom: 5 }}
            >
              <IonText
                color="greyscale"
                style={{
                  fontSize: "20px",
                  fontWeight: 400,
                  lineHeight: "120%",
                  color: "#FFFFFF",
                }}
              >
                Investment Product Updates
              </IonText>
              <a
                href="/investmentUpdate"
                style={{ color: "rgb(191, 161, 100)", textDecoration: "none" }}
              >
                <IonRow
                  className="ion-align-items-center"
                  style={{ width: "auto", margin: 0 }}
                >
                  <IonText
                    style={{
                      fontWeight: 500,
                      fontSize: "17.5px",
                      lineHeight: "25px",
                      color: "#B99855",
                    }}
                  >
                    See all
                  </IonText>
                  <IonIcon
                    style={{ marginLeft: "8px" }}
                    icon={chevronForwardOutline}
                  ></IonIcon>
                </IonRow>
              </a>
            </IonRow>
            <IonGrid style={{ padding: "0px" }}>
              <IonRow>
                {invUpdateData.map((item, i) => (
                  <IonCol
                    style={{ paddingLeft: "0px" }}
                    size="12"
                    size-md="6"
                    size-xl="4"
                    key={i}
                  >
                    <InvestmentCard
                      imgSrc={item.content.BannerImage.filename}
                      subtitle={"title"}
                      title={item.content.Title}
                      publishDate={item.content.PublishDate.slice(0, 10)}
                      routerPath={
                        item.content.Document
                          ? `/investmentProductDetails/${item.id}`
                          : "No Pdf file found"
                      }
                      filename={
                        item.content.Document
                          ? item.content.Document.filename
                          : ""
                      }
                      section={"Investment"}
                    />
                  </IonCol>
                ))}
              </IonRow>
            </IonGrid>
          </IonRow>
          <IonRow class="ion-margin-top margin-set m-marginleft-12 m-marginright-12 dashgrid-mobile">
            <IonRow
              className="ion-justify-content-between ion-align-items-center"
              style={{ width: "100%", paddingRight: 10, marginBottom: 5 }}
            >
              <IonText
                color="greyscale"
                style={{
                  fontSize: "20px",
                  fontWeight: 400,
                  lineHeight: "120%",
                  color: "#FFFFFF",
                }}
              >
                Digital Product Updates
              </IonText>
              <a
                href="/digitalProductUpdate"
                style={{ color: "rgb(191, 161, 100)", textDecoration: "none" }}
              >
                <IonRow
                  className="ion-align-items-center"
                  style={{ width: "auto", margin: 0 }}
                >
                  <IonText
                    style={{
                      fontWeight: 500,
                      fontSize: "17.5px",
                      lineHeight: "25px",
                      color: "#B99855",
                    }}
                  >
                    See all
                  </IonText>
                  <IonIcon
                    style={{ marginLeft: "8px" }}
                    icon={chevronForwardOutline}
                  ></IonIcon>
                </IonRow>
              </a>
            </IonRow>
            <IonGrid style={{ padding: "0px" }}>
              <IonRow>
                {digitalUpdateData.map((item, i) => (
                  <IonCol
                    style={{ paddingLeft: "0px" }}
                    size="12"
                    size-md="6"
                    size-xl="4"
                    key={i}
                  >
                    <InvestmentCard
                      imgSrc={item.content.BannerImage.filename}
                      subtitle={"title"}
                      title={item.content.Title}
                      publishDate={item.content.PublishDate.slice(0, 10)}
                      routerPath={
                        item.content.Document
                          ? `/digitalProductDetails/${item.id}`
                          : "No Pdf file found"
                      }
                      filename={
                        item.content.Document
                          ? item.content.Document.filename
                          : ""
                      }
                      section={"Digital"}
                    />
                  </IonCol>
                ))}
              </IonRow>
            </IonGrid>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
}
