import React, { createRef, useState } from 'react';
import moment from "moment";
import CalendarPop from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import enUS from 'rc-calendar/lib/locale/en_US';
import 'rc-calendar/assets/index.css';
import './CustDatePicker.css';

const CustDatePicker: React.FC<any> = (props) => {
    let dateFormat = props.dateFormat;
    let pickerStyle = props.PickerStyle;
    let inputStyle = props.InputPickStyle;

    const [open,setOpen] = useState(false);
    const [isMouseDown,setIsMouseDown] = useState(false);
    const calendarContainerRef = createRef<any>();
    const format = dateFormat?dateFormat:'YYYY-MM-DD HH:mm:ss';
    
    
    const calendarPopup = (<CalendarPop
        locale={enUS}
        style={{ zIndex: 1001 }}
        className="tfo-calender-pop"
        dateInputPlaceholder="please input"
        format={format}
        defaultValue={moment()}
        showToday={false}
        showDateInput={false}
        mode={props.mode}
        disabledDate={props.disabledDate}
    />);

    const onFocus = () => {
        if (!open && isMouseDown) {
        // focus from a "click" event, let the picker trigger automatically open the calendar
        setIsMouseDown(false);
        } else {
        // focus not caused by "click" (such as programmatic or via keyboard)
        setOpen(true);
        }
    }

    const onMouseDown = () => {
        setIsMouseDown(true);
    }

    const onChange = (value) => {
        // console.log('DatePicker change: ', (value && value.format(format)));
        props.onSetDateVal(value);
    }

    const onOpenChange = (open) => {
        setOpen(open);
    }

  return (
    <DatePicker
    animation="slide-up"
    calendar={calendarPopup}
    value={moment(props.date)}
    format={format}
    onChange={onChange}
    getCalendarContainer={calendarContainerRef.current}
    onOpenChange={onOpenChange}
    open={open}
    // style={{ zIndex: 1001 }}
    style={pickerStyle}
    
  >
    {
      () => {
        return (
          <span tabIndex={0} onMouseDown={onMouseDown} onFocus={onFocus}>
            <input
            type="text"
              placeholder="please select"
              style={inputStyle}
              className={props.InputClassName?props.InputClassName:'tfo-calendar-picker-input tfo-input'}
              disabled={false}
              readOnly
              tabIndex={-1}
            //   className="tfo-calendar-picker-input tfo-input"
              value={moment(props.date).format(dateFormat)}
            />
            <div ref={calendarContainerRef} />
          </span>
        );
      }
    }
  </DatePicker>
  );
};

export default CustDatePicker;
