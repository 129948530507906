import {
    SQLiteDatabaseConfig,
    SQLite,
  } from "@ionic-native/sqlite"
import { parse } from "path";
import api from "src/api";
import { getdirtyMeetingNotesQry } from ".";

const DbConfig: SQLiteDatabaseConfig = {
    name: "data.db",
    location: "default",
};

interface MeetingNoteCreateAPI {
    createdBy: number;
    updatedBy: number;
    description: string;
    meetingDateTime: number;
    meetingEndDateTime: number;
    clientType: string;
    meetingTopic: string;
    meetingType: string;
    notes: string;
    gvc: string;
    invObjective: string;
    invLiquidity: string;
    optLocalEconomy: string;
    optIntlEconomy: string;
    optCorebusiness: string;
    apetiteIntlExposure: string;
    liquidityEvent: string;
    followupTaskId: number;
    hubspotMeetingId: number;
    attendees: string;
}

interface RespObjInterfaceArr {
    prospectId: string;
    Payload: MeetingNoteCreateAPI
}

const getdirtyMeetingNotes: (Op: "INSERT"|"UPDATE") => Promise<RespObjInterfaceArr[]> = async (FilterOperation) => {
    const dbInstance = await SQLite.create(DbConfig);
    return dbInstance.executeSql(getdirtyMeetingNotesQry, [FilterOperation]).then((dirtyRes) => {
        // console.log("Dirty Results"+JSON.stringify(dirtyRes))
        const respObj: RespObjInterfaceArr[] = []
        if(dirtyRes.rows?.length > 0) {
            for(var i=0;i < dirtyRes.rows.length; i++) {
                const temp = dirtyRes.rows.item(i)           
                const {
                    apetiteIntlExposure,
                    attendees,
                    clientType,
                    createdBy,
                    description,
                    followupTaskId,
                    gvc,
                    hubspotMeetingId,
                    invLiquidity,
                    invObjective,
                    liquidityEvent,
                    meetingDateTime,
                    meetingEndDateTime,
                    meetingTopic,
                    meetingType,
                    notes,
                    optCorebusiness,
                    optIntlEconomy,
                    optLocalEconomy,
                    updatedBy,
                    prospectId
                } = temp               
                respObj.push({
                    prospectId: prospectId,
                    Payload:{
                        apetiteIntlExposure,
                        attendees,
                        clientType,
                        createdBy: parseInt(createdBy),
                        description,
                        followupTaskId: parseInt(followupTaskId),
                        gvc,
                        hubspotMeetingId: parseInt(hubspotMeetingId),
                        invLiquidity,
                        invObjective,
                        liquidityEvent,
                        meetingDateTime: parseInt(meetingDateTime),
                        meetingEndDateTime: parseInt(meetingEndDateTime),
                        meetingTopic,
                        meetingType,
                        notes,
                        optCorebusiness,
                        optIntlEconomy,
                        optLocalEconomy,
                        updatedBy: parseInt(updatedBy)
                    }
                })
            }
        }
        return respObj
    })
}

const syncMeetingNoteCreation: (inp: RespObjInterfaceArr[]) => Promise<any> = (CreateMeetingNoteArr) => {
    let prom: Promise<any>[] = []
    CreateMeetingNoteArr.forEach((payload) => {

        prom.push(new Promise((res) => {
            api.createNoteAPI(payload.prospectId, {
                ...payload.Payload,
                createdBy: String(payload.Payload.createdBy),
                updatedBy: String(payload.Payload.updatedBy)
            }).then(response => {
                // console.log("Line 114"+ JSON.stringify(response))
                res(response)
            }).catch(e => console.log("Line 116"+JSON.stringify(e)))
        }))
    })
    
    return Promise.all(prom).then((vals) => {
        return vals
    }).catch(e => console.log(JSON.stringify(e)))
}

export default {
    getdirtyMeetingNotes,
    syncMeetingNoteCreation
}