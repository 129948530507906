import React, { CSSProperties, RefObject, useEffect, useState } from 'react'
import * as d3 from 'd3'
import './Line.css'

const margin = 20

var monthsArr = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
]


interface stylesI {
  root: CSSProperties;
  svg: CSSProperties;
  path: CSSProperties;
  pathA: CSSProperties;
  axis: CSSProperties;
  gridline: CSSProperties;
  axistext: CSSProperties;
  gridlineTemp: CSSProperties;
  area: CSSProperties;
}

const styles: stylesI = {
  root: {
    position: "relative",
    width: "100%",
    height: "100%",
    overflowY: "hidden",
    overflowX: "scroll"
  },
  svg: {
    fill: "#fff",
    fillOpacity: 1.5,
    zIndex: 0,
    position: "absolute",
    left: 96,
    bottom: 0,
    filter: "drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7))"
  },
  path: {
    stroke: "#72758E",
    strokeWidth: 3,
    fill: "none",
    filter: "url(#shadow2)",
  },
  pathA: {
    stroke: "#F7B198",
    strokeWidth: 3,
    fill: "none",
    filter: "url(#shadow2)",
  },
  axis: {
    stroke: "#fff"
  },
  gridline: {
    stroke: "rgb(255, 255, 255, 0.05)"
  },
  gridlineTemp: {
    opacity: 1,
    stroke: "#fff"
  },
  axistext: {
    fill: "#AAAAAA",
    fillOpacity: 0.9,
    fontSize: 12,
    textAnchor: "middle",
    fontWeight: "normal"
  },
  area: {
    fill: "linear-gradient(180deg, rgba(255, 255, 255, 0.2) 25.16%, rgba(255, 255, 255, 0) 143.4%)",
    fillOpacity: 0.2
  }
}


const decimalformalFunc = (Num) => {
  let temp = Num.toString().split(".");
  let retVal = 0;
  if (temp[1]) {
    retVal = Num.toFixed(0);
  } else {
    retVal = Num;
  }
  return retVal;
};

const retformatamt = (clsAmt) => {
  let closingamt_disp: number = 0;
  Number.isNaN(clsAmt) || clsAmt === null || clsAmt === ""
    ? (closingamt_disp = 0)
    : (closingamt_disp = parseInt(clsAmt));
  return Math.abs(Number(closingamt_disp)) >= 1.0e9
    ? decimalformalFunc(Number(closingamt_disp) / 1.0e9) + "b"
    : // Six Zeroes for Millions
    Math.abs(Number(closingamt_disp)) >= 1.0e6
    ? decimalformalFunc(Number(closingamt_disp) / 1.0e6) + "m"
    : // Three Zeroes for Thousands
    Math.abs(Number(closingamt_disp)) >= 1.0e3
    ? decimalformalFunc(Number(closingamt_disp) / 1.0e3) + "k"
    : decimalformalFunc(Number(closingamt_disp));
};

const retAmtforTicks = (clsAmt) => {
  let closingamt_disp: number = 0;
  (Number.isNaN(clsAmt)) || clsAmt === null || clsAmt === "" ? closingamt_disp = 0 : closingamt_disp = parseInt(clsAmt)
  return Math.abs(Number(closingamt_disp)) >= 1.0e+9

    ? Number(closingamt_disp) / 1.0e+9 + "b"
    // Six Zeroes for Millions 
    : Math.abs(Number(closingamt_disp)) >= 1.0e+6

      ? Number(closingamt_disp) / 1.0e+6 + "m"
      // Three Zeroes for Thousands
      : Math.abs(Number(closingamt_disp)) >= 1.0e+3

        ? Number(closingamt_disp) / 1.0e+3 + "k"

        : Number(closingamt_disp);
}

interface LineChartProps {
  CardTitle: string;
  width: number;
  height: number;
  chartData: [number, number, number, number][][][][];
  tf: string
}

const GroupBarChart: React.FC<LineChartProps> = ({
  CardTitle,
  width,
  height,
  chartData,
  tf
}) => {

  const refA: RefObject<SVGSVGElement> = React.createRef<SVGSVGElement>()

  const h = height - 2 * margin, w = width - 2 * margin

  
  const [yTicks, setyTicks] = useState<any>();
  const [xTicks, setxTicks] = useState<any>();
  const [xGridLines, setxGridLines] = useState<any>();
  const [yGridLines, setyGridLines] = useState<any>();


  useEffect(() => {
    if (chartData[0].length > 0) {
      const maxValueArr: any = [];
      const minValueArr: any = [];
      for (let i = 0; i < 12; i++) {
        maxValueArr.push(Math.max(Number(chartData[0][i][2]), Number(chartData[0][i][3]), Number(chartData[0][i][4])));
        minValueArr.push(Math.min(Number(chartData[0][i][2]), Number(chartData[0][i][3]), Number(chartData[0][i][4])))
      }
      //chartData.forEach((data, i) => {


      var ActualData = [
        {
          month: "JAN",
          monthNum: 1,
          income: Number(chartData[0][0][2]),
          year: Number(chartData[0][0][1]),
          capital: Number(chartData[0][0][3]),
          gain: Number(chartData[0][0][4]),
        },
        {
          month: "FEB",
          monthNum: 2,
          income: Number(chartData[0][1][2]),
          year: Number(chartData[0][1][1]),
          capital: Number(chartData[0][1][3]),
          gain: Number(chartData[0][1][4]),
        },
        {
          month: "MAR",
          monthNum: 3,
          income: Number(chartData[0][2][2]),
          year: Number(chartData[0][2][1]),
          capital: Number(chartData[0][2][3]),
          gain: Number(chartData[0][2][4]),
        },
        {
          month: "APR",
          monthNum: 4,
          income: Number(chartData[0][3][2]),
          year: Number(chartData[0][3][1]),
          capital: Number(chartData[0][3][3]),
          gain: Number(chartData[0][3][4]),
        },
        {
          month: "MAY",
          monthNum: 5,
          income: Number(chartData[0][4][2]),
          year: Number(chartData[0][4][1]),
          capital: Number(chartData[0][4][3]),
          gain: Number(chartData[0][4][4]),
        },
        {
          month: "JUN",
          monthNum: 6,
          income: Number(chartData[0][5][2]),
          year: Number(chartData[0][5][1]),
          capital: Number(chartData[0][5][3]),
          gain: Number(chartData[0][5][4]),
        },
        {
          month: "JUL",
          monthNum: 7,
          income: Number(chartData[0][6][2]),
          year: Number(chartData[0][6][1]),
          capital: Number(chartData[0][6][3]),
          gain: Number(chartData[0][6][4]),
        },
        {
          month: "AUG",
          monthNum: 8,
          income: Number(chartData[0][7][2]),
          year: Number(chartData[0][7][1]),
          capital: Number(chartData[0][7][3]),
          gain: Number(chartData[0][7][4]),
        },
        {
          month: "SEP",
          monthNum: 9,
          income: Number(chartData[0][8][2]),
          year: Number(chartData[0][8][1]),
          capital: Number(chartData[0][8][3]),
          gain: Number(chartData[0][8][4]),
        },
        {
          month: "OCT",
          monthNum: 10,
          income: Number(chartData[0][9][2]),
          year: Number(chartData[0][9][1]),
          capital: Number(chartData[0][9][3]),
          gain: Number(chartData[0][9][4]),
        },
        {
          month: "NOV",
          monthNum: 11,
          income: Number(chartData[0][10][2]),
          year: Number(chartData[0][10][1]),
          capital: Number(chartData[0][10][3]),
          gain: Number(chartData[0][10][4]),
        },
        {
          month: "DEC",
          monthNum: 12,
          income: Number(chartData[0][11][2]),
          year: Number(chartData[0][11][1]),
          capital: Number(chartData[0][11][3]),
          gain: Number(chartData[0][11][4]),
        }
      ];


      var dateVar = new Date();
      var monthVar = dateVar.getMonth();
      if (tf.toString().includes("Q1")) {

        monthsArr = [];
        monthsArr.push("Jan");
        // monthsArr.push("");
        monthsArr.push("Feb");
        // monthsArr.push("");
        monthsArr.push("Mar");
        ActualData.splice(3, 9);
        ActualData[0].monthNum = 1;
        ActualData[1].monthNum = 2;
        ActualData[2].monthNum = 3;
      }
      else if (tf.toString().includes("Q2")) {
        ActualData.splice(0, 3);
        ActualData.splice(3, 8);
        monthsArr = [];
        monthsArr.push("Apr");
        // monthsArr.push("");
        monthsArr.push("May");
        // monthsArr.push("");
        monthsArr.push("Jun");
        ActualData[0].monthNum = 1;
        ActualData[1].monthNum = 2;
        ActualData[2].monthNum = 3;
      }
      else if (tf.toString().includes("Q3")) {
        ActualData.splice(0, 3);
        ActualData.splice(3, 8);
        monthsArr = [];
        monthsArr.push("Jul");
        // monthsArr.push("");
        monthsArr.push("Aug");
        // monthsArr.push("");
        monthsArr.push("Sep");
        ActualData[0].monthNum = 1;
        ActualData[1].monthNum = 2;
        ActualData[2].monthNum = 3;
      }
      else if (tf.toString().includes("Q4")) {
        ActualData.splice(0, 6);
        ActualData.splice(0, 3);
        monthsArr = [];
        monthsArr.push("Oct");
        // monthsArr.push("");
        monthsArr.push("Nov");
        // monthsArr.push("");
        monthsArr.push("Dec");
        ActualData[0].monthNum = 1;
        ActualData[1].monthNum = 2;
        ActualData[2].monthNum = 3;
      }
      else if (tf.toString().includes("Last 30 days")) {

        monthsArr = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec"
        ]
        if (dateVar.getDate() === 31) {
          monthsArr = monthsArr.splice(monthVar, 1);
          ActualData = ActualData.splice(monthVar, 1);
          ActualData[0].monthNum = 1;
        }
        else {
          monthsArr = monthsArr.splice(monthVar - 1, 2);
          ActualData.splice(0, monthVar - 1);//0,5
          ActualData.splice(3, 8);
          ActualData[0].monthNum = 1;
          ActualData[1].monthNum = 2;
        }
      }
      else if (tf.toString().includes("Year-to-Date")) {
        monthsArr = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec"
        ]
        monthsArr.splice(monthVar + 1, 12 - monthVar);
        // console.log(ActualData);
      }
      else {
        monthsArr = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec"
        ]
      }

      const svg = d3.select(refA.current);

      const margin:number = 20;

      const chart = svg.append('g').attr('transform', `translate(${margin}, ${margin})`);

      // const groups = ActualData.map(d => d.month);
      const subgroups = ["income", "capital", "gain"];

      // color palette = one color per subgroup
      const color: any = d3.scaleOrdinal().domain(subgroups).range(['#B99855', '#40412F', '#44344C']);

      const TextColor:any = d3.scaleOrdinal().domain(subgroups).range(['#FFFFFF', '#FFFFFF', '#FFFFFF']);

      const xScale = d3.scaleLinear()
        .domain([0, 13]) //domain: [min,max] of a
        .range([20, 660 * 2])
      // .range([20, 660])


      // Another scale for subgroup position
      const xSubgroup: any = d3.scaleBand()
        .domain(subgroups)
        .range([0, 30 * subgroups.length]) // for 3 bars
        // .range([0, 30])
        // .padding(0.05)



      const yScale = d3.scaleLinear()
        .domain([Math.min(...minValueArr), Math.max(...maxValueArr)])
        .range([height - 40, 20]).nice(); //[llowest,highest]

      const xG = d3.scaleLinear()
        .domain([0, 15]) //domain: [min,max] of a
        .range([0, 700 * 2])

      const yG = d3.scaleLinear()
        .domain([Math.min(...minValueArr), Math.max(...maxValueArr)]) // domain [0,max] of b (start from 0)
        .range([0, 700]).nice();


      setxGridLines(xG.ticks(14).map((d, i) => (
        <g key={i} transform={`translate(${xScale(d) + (15)},${h + (margin)})`}>
          <line style={styles.gridline} className='gridline' y1={2400} y2={-2400} x1='0' x2='0' transform="translate(0,-25)" />
        </g>
      )))

      setyGridLines(yG.ticks(6).map((d, i) => (
        <g key={i} transform={`translate(${margin},${yScale(d)})`}>
          <line style={styles.gridline} className='gridline' x1={-2400} x2={2400} y1='0' y2='0' transform="translate(-5,0)" />
        </g>
      )))

      setyTicks(yScale.ticks(6).map((d, i) => {
        return (
          yScale(d) > 10 && yScale(d) < h ?
            <g key={i} transform={`translate(${margin + 30},${yScale(d)})`}>
              <text style={styles.axistext} x="-12" y="5">
                {
                  // xFormat(d)
                  retAmtforTicks(d)
                }
              </text>
              {/* <line x1='0' x2='5' y1='0' y2='0' transform="translate(-5,0)" /> */}
              {/* <line style={styles.gridline} className='gridline' x1='0' x2={w - margin} y1='0' y2='0' transform="translate(-5,0)" /> */}

            </g>
            : null
        )
      }
      ))


      setxTicks(xScale.ticks().map((d, i) => (
        // x(d) > margin && x(d) < w ?
        <g key={i} transform={`translate(${xScale(d) + (84 * 2)},${h + (margin)})`}>
          <text style={styles.axistext}>{monthsArr[i]}</text>
          <line x1='0' x2='0' y1='0' y2='5' transform="translate(0,-20)" />
          {/* <line style={styles.gridline} className='gridline' y1='0' y2={margin - h} x1='0' x2='0' transform="translate(0,-25)" /> */}
        </g>
        // : null
      )))

     chart.append('g')
        .attr('transform', `translate(0, ${height})`)
        .call(d3.axisBottom(xScale));

      chart.append('g')
        .append("text")
        .attr('class', 'grid')

      // zero 0 line on Y Axis
      // chart.append("line")
      //   .attr("x1", -12)
      //   .attr("y1", yScale(0) - margin)//so that the line passes through the y 0
      //   .attr("x2", width)
      //   .attr("y2", yScale(0) - margin)//so that the line passes through the y 0
      //   .style("fill", "rgb(255, 255, 255, 0.05)");
      // chart.append('g')
      // .attr('class', 'grid')

      const barGroups = chart.append('g').selectAll('g')
        .data(ActualData)
        .join("g")
        .attr("transform", d => `translate(${xScale(d.monthNum)}, 0)`)

      barGroups.selectAll('rect')
        .data(function (d) { return subgroups.map(function (key) { return { key: key, value: d[key] }; }); })
        .join('rect')
        .attr('class','bar')
        .attr('x', d => xSubgroup(d.key))
        .attr('y', d => yScale(Math.max(0, d.value)) - margin)
        .attr("width", xSubgroup.bandwidth())
        .attr('height', d => Math.abs(yScale(d.value) - yScale(0)))
        .attr('fill', d => color(d.key))

        // text for values
        barGroups
        .selectAll("text")
        .data(function (d) { return subgroups.map(function (key) { return { key: key, value: d[key] }; }); })
        .join("text")
        .attr('class','value font12')
        .attr('x', d => xSubgroup(d.key) + 30 /2)
        .attr('y',  d => yScale(d.value/2) - margin)
        .attr('text-anchor', 'middle')
        .text(function(d){         
          return  d.value ? retformatamt(d.value) : "" })
        .attr("fill", d => TextColor(d.key))

    }

  }, [])

  return (
    <div style={styles.root}>

      <svg width={width} height={height} className="labelssvg" style={{ position: "absolute", bottom: 0, left: 96 }}>
        <g className="axis-labels">
          {xGridLines}
        </g>
        <g className="axis-labelsy">
          {yGridLines}
        </g>
      </svg>

      <svg width={width} height={height} className="axessvg" style={styles.svg} ref={refA}>
        {/* <svg width={width} height={height} className="axessvg" > */}
        <g className="axis-labels">
          {xTicks}
        </g>*
        <g className="axis-labels">
          {yTicks}
        </g>
        {/*  <defs>
                    {/* <filter id="shadow">
                        <feGaussianBlur result="coloredBlur" stdDeviation="3.5" />
                        <feMerge>
                            <feMergeNode in="coloredBlur" />
                            <feMergeNode in="SourceGraphic" />
                        </feMerge>
                    </filter>
                    <filter id="shadow2">
                        <feDropShadow dx="0" dy="10" stdDeviation="4.5"
                            floodColor="black" />
                    </filter> 
                </defs>*/}
      </svg>
      
    </div>
  )
}

export default GroupBarChart