import {
  SQLiteObject,
  SQLiteDatabaseConfig,
  SQLite,
} from "@ionic-native/sqlite";
import CryptoJS from 'crypto-js';
import {
  createTables,
  insertMeetingNote,
  getNotesByProspectId,
  deleteNotesForProspect,
  getNotesById,
  updateMeetingNote,
  deleteNoteByIdSQL,
  markForDeletion,
  dropTables,
} from "src/offlineUtils";
import { meetingNoteApiRespMultiple, meetingNoteApiRespSingle, meetingNotePayload, sqmeetingNotePayload } from "src/types";
import { Plugins, Network, NetworkStatus } from "@capacitor/core";
import api from "src/api";

const { Device, Storage } = Plugins;

const DbConfig: SQLiteDatabaseConfig = {
  name: "data.db",
  location: "default",
};

const decrypt = (decryptVlaue) => {
  let passphrase = "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9"
  const bytes = CryptoJS.AES.decrypt(decryptVlaue, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};

const getUserId = async () => {
  const ret: any = await Storage.get({ key: "userId" });
  const temp = JSON.parse(decrypt(ret.value));
  return temp && temp.id ? temp.id : null;
};

export const noteTableCreate = async () => {
  const info = await Device.getInfo();
  //check for platform - if browser then return response from api else perform dboperations
  if (info.platform === "android" || info.platform === "ios") {
    return SQLite.create(DbConfig)
      .then(async (db: SQLiteObject) => {
        await db.executeSql(dropTables,[]);
        SQLite.create(DbConfig).then((db) => {
          db.executeSql(createTables,[]).then((val) => {
            // console.log("meeting note table created:"+ JSON.stringify(val))
          }).catch((e) => {
            console.log("Error creating meeting note table"+ JSON.stringify(e))
          })
        })
      })
      .catch((e) => console.log(e));
  } else {
    return null
  }
};

export const loadMeetingNotesForRM: () => Promise<any> = async () => {
  const userId = await getUserId();
  return api.getNotesforRM(parseInt(userId)).then((response: meetingNoteApiRespMultiple[]) => {
    return insertOperFunc(response, undefined, undefined, 0, "LOAD").then((val) => {
      return val
    }).catch(e => console.log("Error Loading - Line 60"+ JSON.stringify(e)))
  })
}


const insertOperFunc = async (apiResp: meetingNoteApiRespMultiple[], prospectId: number|undefined, db: SQLiteObject|undefined, dirtyFlag: 0| 1,  Operation: "LOAD" | "INSERT") => {
  const userId = await getUserId();
  let prom: Array<Promise<any>> = [];
  const dba: SQLiteObject = await SQLite.create(DbConfig);
  apiResp.forEach(async (element: meetingNoteApiRespMultiple) => {
    prom.push(
      new Promise((resolve) => {
        insertMeetingNotes({
          createdBy: userId,
          updatedBy: userId,
          meetingDateTime: String(element.meetingDateTime),
          clientType: element.clientType,
          meetingTopic: element.meetingTopic,
          meetingType: element.meetingType,
          rowId: element.rowId,
          prospectId: prospectId ? String(prospectId) : element.prospectId,
          createdDate: String(element.createdDate),
          updatedDate: String(element.updatedDate),
          apetiteIntlExposure: String(element.apetiteIntlExposure),
          attendees: String(element.attendees),
          description: element.description,
          followupTaskId: String(element.followupTaskId),
          gvc: element.gvc,
          hubspotMeetingId: element.hubspotMeetingId,
          invLiquidity: element.invLiquidity,
          invObjective: element.invObjective,
          liquidityEvent: element.liquidityEvent,
          meetingEndDateTime: element.meetingEndDateTime,
          notes: element.notes,
          optCorebusiness: element.optCorebusiness,
          optIntlEconomy: element.optIntlEconomy,
          optLocalEconomy: element.optLocalEconomy,
          dirtyFlag: dirtyFlag,
          Operation: Operation
        })
          .then(() => {
            resolve("success");
          })
          .catch((e) => {
            resolve(JSON.stringify(e));
          });
      })
    );   
  });

  return Promise.all(prom)
    .then(async (allval) => {
      const data = await dba.executeSql(getNotesByProspectId, [prospectId]);
      // console.log("Line 99"+data)
      let temp: any = [];
      for (let x = 0; x < data.rows.length; x++) {
        temp.push(data.rows.item(x));
      }
      return temp;
    })
    .catch((e) => {
      console.log(e);
      return [];
    });
}


const updateOperFunc = async (apiResp: meetingNoteApiRespMultiple[], noteId: any, db: SQLiteObject, dirtyFlag: 0| 1,  Operation: "UPDATE"| "LOAD") => {
  const userId = await getUserId();
  let prom: Array<Promise<any>> = [];
  apiResp.forEach(async (element: meetingNoteApiRespMultiple) => {
    prom.push(
      new Promise((resolve) => {
        updMeetingNotes({
          createdBy: userId,
          updatedBy: userId,
          meetingDateTime: String(element.meetingDateTime),
          clientType: element.clientType,
          meetingTopic: element.meetingTopic,
          meetingType: element.meetingType,
          rowId: noteId,
          prospectId: element.prospectId,
          createdDate: String(element.createdDate),
          updatedDate: String(element.updatedDate),
          apetiteIntlExposure: String(element.apetiteIntlExposure),
          attendees: String(element.attendees),
          description: element.description,
          followupTaskId: String(element.followupTaskId),
          gvc: element.gvc,
          hubspotMeetingId: element.hubspotMeetingId,
          invLiquidity: element.invLiquidity,
          invObjective: element.invObjective,
          liquidityEvent: element.liquidityEvent,
          meetingEndDateTime: element.meetingEndDateTime,
          notes: element.notes,
          optCorebusiness: element.optCorebusiness,
          optIntlEconomy: element.optIntlEconomy,
          optLocalEconomy: element.optLocalEconomy,
          dirtyFlag: dirtyFlag,
          Operation: Operation
        })
          .then(() => {
            resolve("success");
          })
          .catch((e) => {
            resolve(JSON.stringify(e));
          });
      })
    );   
  });

  return Promise.all(prom)
    .then(async (allval) => {
      const data = await db.executeSql(getNotesById, [noteId]);
      let temp: any = [];
      for (let x = 0; x < data.rows.length; x++) {
        temp.push(data.rows.item(x));
      }
      return temp;
    })
    .catch((e) => {
      console.log(e);
      return [];
    });
}

export const insertMeetingNotes = async (Input: sqmeetingNotePayload) => {
  const db: SQLiteObject = await SQLite.create(DbConfig)
    const {
      rowId,
      prospectId,
      clientType,
      createdBy,
      createdDate,
      meetingDateTime,
      meetingTopic,
      meetingType,
      updatedBy,
      updatedDate,
      description,
      meetingEndDateTime,
      notes,
      gvc,
      invObjective,
      invLiquidity,
      optLocalEconomy,
      optIntlEconomy,
      optCorebusiness,
      apetiteIntlExposure,
      liquidityEvent,
      followupTaskId,
      hubspotMeetingId,
      attendees,
      dirtyFlag,
      Operation
    } = Input;
    const val = await db.executeSql(insertMeetingNote, [
      rowId,
      prospectId,
      clientType,
      createdBy,
      createdDate,
      meetingDateTime,
      meetingTopic,
      meetingType,
      updatedBy,
      updatedDate,
      description,
      meetingEndDateTime,
      notes,
      gvc,
      invObjective,
      invLiquidity,
      optLocalEconomy,
      optIntlEconomy,
      optCorebusiness,
      apetiteIntlExposure,
      liquidityEvent,
      followupTaskId,
      hubspotMeetingId,
      attendees,
      dirtyFlag,
      Operation
    ]);
    // console.log("Meeting Data Inserted: " + JSON.stringify(val));
};

export const updMeetingNotes = (Input: sqmeetingNotePayload) => {
  return SQLite.create(DbConfig).then(async (db: SQLiteObject) => {
    const {
      rowId,
      prospectId,
      clientType,
      createdBy,
      createdDate,
      meetingDateTime,
      meetingTopic,
      meetingType,
      updatedBy,
      updatedDate,
      description,
      meetingEndDateTime,
      notes,
      gvc,
      invObjective,
      invLiquidity,
      optLocalEconomy,
      optIntlEconomy,
      optCorebusiness,
      apetiteIntlExposure,
      liquidityEvent,
      followupTaskId,
      hubspotMeetingId,
      attendees,
      dirtyFlag,
      Operation
    } = Input;
    const val = await db.executeSql(updateMeetingNote, [
      apetiteIntlExposure,
      optLocalEconomy,
      createdBy,
      meetingDateTime,
      updatedBy,
      clientType,
      description,
      followupTaskId,
      gvc,
      hubspotMeetingId,
      invLiquidity,
      invObjective,
      liquidityEvent,
      meetingEndDateTime,
      meetingTopic,
      meetingType,
      notes,
      optCorebusiness,
      optIntlEconomy,
      attendees,
      dirtyFlag,
      Operation,
      rowId
    ]);
    // console.log("Meeting Data Updated: " + JSON.stringify(val));
  });
};

export const getNotesByProspId = async (prospectId: number,onboardingId: number) => {
  const network: NetworkStatus = await Network.getStatus();
  const info = await Device.getInfo();
  //check for platform - if browser then return response from api else perform dboperations
  if (!(info.platform === "android" || info.platform === "ios")) {
    return api.GetNoteByProspectIdAPI(prospectId,onboardingId);
  } else {
    const db: SQLiteObject = await SQLite.create(DbConfig);
    //check network connection
    if(network.connected) {//connected
      
      const apiResp = await api.GetNoteByProspectIdAPI(prospectId,onboardingId);//call api
      const delOper = await db.executeSql(deleteNotesForProspect, [prospectId]);//delete notes for prospect from sqlite
      // console.log(JSON.stringify(delOper))
      // await db.close()
      insertOperFunc(apiResp, prospectId, db, 0, "LOAD");//insert records retrieved from api into sqlite
      return apiResp;
      
    } else {//network diconnected
      
      return db.executeSql(getNotesByProspectId, [String(prospectId)]).then((res) => {
        // console.log("108:" + JSON.stringify(res));
        if (res.rows?.length > 0) {
          let temp: any = [];
          for (let x = 0; x < res.rows.length; x++) {
            let tempItem: {
              [key:string]: string;
            }= res.rows.item(x)

            const {
              createdDate,
              followupTaskId,
              rowId,
              updatedDate ,
              meetingDateTime
            } = tempItem

            let pushObj = {
              ...tempItem,
              createdDate: parseInt(createdDate),
              followupTaskId: parseInt(followupTaskId),
              rowId : parseInt(rowId),
              updatedDate: parseInt(updatedDate)   ,
              meetingDateTime: parseInt(meetingDateTime)           
            } as meetingNoteApiRespSingle
            temp.push(pushObj);
          }
          // console.log("MEETING NOTE RESP"+ JSON.stringify(temp))
          return temp
        } else {
          return []
        }
      }).catch((e) => {
        console.error(JSON.stringify(e))
      });//execute sqlite query for prospect
      

    }
  }
};

export const createNewMeetingNote: (arg0: number, arg1: meetingNotePayload) => Promise<meetingNoteApiRespSingle> = async (prospId: number, payload: meetingNotePayload) => {
  const network: NetworkStatus = await Network.getStatus();
  const info = await Device.getInfo();
  //check for platform - if browser then create note directly via api else perform dboperations
  if (!(info.platform === "android" || info.platform === "ios")) {
    return await api.createNoteAPI(prospId, payload)
  } else {
    const db: SQLiteObject = await SQLite.create(DbConfig);
    //check network connection
    if(network.connected) {//connected
      
      const apiResp: meetingNoteApiRespSingle = await api.createNoteAPI(prospId, payload);//call api

      const { apetiteIntlExposure, optLocalEconomy, createdBy, meetingDateTime, updatedBy, attendees, clientType, description, followupTaskId, gvc, hubspotMeetingId, invLiquidity, invObjective, liquidityEvent, meetingEndDateTime, meetingTopic, meetingType, notes, optCorebusiness, optIntlEconomy, updatedDate, createdDate, rowId, prospectId, externalAttendees } = apiResp

      const insertRecDbPayload: meetingNoteApiRespMultiple[] = [{
        apetiteIntlExposure,
        attendees,
        optIntlEconomy,
        optCorebusiness,
        notes,
        meetingType,
        meetingTopic,
        meetingEndDateTime,
        liquidityEvent,
        invObjective,
        invLiquidity,
        hubspotMeetingId,
        gvc,
        followupTaskId,
        description,
        clientType,
        updatedBy,
        meetingDateTime,
        createdBy,
        optLocalEconomy,
        createdDate,
        prospectId,
        rowId,
        updatedDate,
        externalAttendees
      }]

      try {
        await insertOperFunc(insertRecDbPayload, prospId, db, 0, "LOAD");//insert new record into sqlite
      } catch(e) {
        console.error(e)
      }
      return apiResp;
      
    } else {//network diconnected

      const { apetiteIntlExposure,optLocalEconomy,createdBy,meetingDateTime,updatedBy,clientType,description,followupTaskId,gvc,hubspotMeetingId,invLiquidity,invObjective,liquidityEvent,meetingEndDateTime,meetingTopic,meetingType,notes,optCorebusiness,optIntlEconomy,attendees,externalAttendees } = payload

      const insertRecDbPayload: meetingNoteApiRespMultiple[] = [{
        apetiteIntlExposure,
        attendees,
        optIntlEconomy,
        optCorebusiness,
        notes,
        meetingType,
        meetingTopic,
        meetingEndDateTime: String(meetingEndDateTime),
        liquidityEvent,
        invObjective,
        invLiquidity,
        hubspotMeetingId: String(hubspotMeetingId),
        gvc,
        followupTaskId,
        description,
        clientType,
        updatedBy,
        meetingDateTime,
        createdBy,
        optLocalEconomy,
        createdDate: 0,
        prospectId: String(prospId),
        rowId: 0,
        updatedDate: 0,
        externalAttendees: externalAttendees?externalAttendees:""
      }]

      try {
        await insertOperFunc(insertRecDbPayload, prospId, db, 1, "INSERT");//insert new record into sqlite - set dirty 1
      } catch(e) {
        console.error(e)
      }


      return {
        apetiteIntlExposure,
        attendees,
        optIntlEconomy,
        optCorebusiness,
        notes,
        meetingType,
        meetingTopic,
        meetingEndDateTime: String(meetingEndDateTime),
        liquidityEvent,
        invObjective,
        invLiquidity,
        hubspotMeetingId: String(hubspotMeetingId),
        gvc,
        followupTaskId,
        description,
        clientType,
        updatedBy,
        meetingDateTime,
        createdBy,
        optLocalEconomy,
        createdDate: 0,
        prospectId: String(prospId),
        rowId: 0,
        updatedDate: 0
      } as meetingNoteApiRespSingle
    }
  }
}

export const getNoteById : (arg0: number) => Promise<meetingNoteApiRespSingle> = async (noteId) => {
  const network: NetworkStatus = await Network.getStatus();
  const info = await Device.getInfo();
  //check for platform - if browser then return response from api else perform dboperations
  if (!(info.platform === "android" || info.platform === "ios")) {
    return api.GetMeetingNoteByIdAPI(noteId);
  } else {
    const db: SQLiteObject = await SQLite.create(DbConfig);
    //check network connection
    if(network.connected) {//connected
      
      return api.GetMeetingNoteByIdAPI(noteId);//call api
      
    } else {//network diconnected
      
      const res = await db.executeSql(getNotesById, [noteId]);//execute sqlite query for prospect
      if (res.rows?.length > 0) {
        return res.rows.item(0)
      } else {
        return {}
      }
    }
  }
}

export const updateNoteById: (arg0: any, arg1: meetingNotePayload) => Promise<meetingNoteApiRespSingle> = async (noteId, payload) => {
  
  const network: NetworkStatus = await Network.getStatus();
  const info = await Device.getInfo();
  //check for platform - if browser then update note directly via api else perform dboperations
  if (!(info.platform === "android" || info.platform === "ios")) {
    return await api.UpdateMeetingNoteByIdAPI(noteId, payload)
  } else {
    const db: SQLiteObject = await SQLite.create(DbConfig);
    //check network connection
    if(network.connected) {//connected
      
      const apiResp: meetingNoteApiRespSingle = await api.UpdateMeetingNoteByIdAPI(noteId, payload);//call api

      const { apetiteIntlExposure, optLocalEconomy, createdBy, meetingDateTime, updatedBy, attendees, clientType, description, followupTaskId, gvc, hubspotMeetingId, invLiquidity, invObjective, liquidityEvent, meetingEndDateTime, meetingTopic, meetingType, notes, optCorebusiness, optIntlEconomy, updatedDate, createdDate, rowId, prospectId, externalAttendees } = apiResp

      const updateRecDbPayload: meetingNoteApiRespMultiple[] = [{
        apetiteIntlExposure,
        attendees,
        optIntlEconomy,
        optCorebusiness,
        notes,
        meetingType,
        meetingTopic,
        meetingEndDateTime,
        liquidityEvent,
        invObjective,
        invLiquidity,
        hubspotMeetingId,
        gvc,
        followupTaskId,
        description,
        clientType,
        updatedBy,
        meetingDateTime,
        createdBy,
        optLocalEconomy,
        createdDate,
        prospectId,
        rowId,
        updatedDate,
        externalAttendees
      }]

      try {
        await updateOperFunc(updateRecDbPayload, noteId, db, 0, "LOAD");//insert new record into sqlite
      } catch(e) {
        console.error(e)
      }
      return apiResp;
      
    } else {//network diconnected

      const { apetiteIntlExposure,invLiquidity,invObjective,liquidityEvent,meetingEndDateTime,meetingTopic,meetingType,optIntlEconomy,optCorebusiness,notes,clientType,attendees,hubspotMeetingId,gvc,followupTaskId,description,updatedBy,meetingDateTime,createdBy,optLocalEconomy, externalAttendees } = payload

      const updateRecDbPayload: meetingNoteApiRespMultiple[] = [{
        apetiteIntlExposure,
        attendees,
        optIntlEconomy,
        optCorebusiness,
        notes,
        meetingType,
        meetingTopic,
        meetingEndDateTime: String(meetingEndDateTime),
        liquidityEvent,
        invObjective,
        invLiquidity,
        hubspotMeetingId: String(hubspotMeetingId),
        gvc,
        followupTaskId,
        description,
        clientType,
        updatedBy,
        meetingDateTime,
        createdBy,
        optLocalEconomy,
        createdDate: 0,
        prospectId: "temp",
        rowId: 0,
        updatedDate: 0,
        externalAttendees: externalAttendees?externalAttendees:""
      }]

      try {
        await updateOperFunc(updateRecDbPayload, noteId, db, 1, "UPDATE");//insert new record into sqlite - set dirty 1
      } catch(e) {
        console.error(e)
      }

      return {
        apetiteIntlExposure,
        attendees,
        optIntlEconomy,
        optCorebusiness,
        notes,
        meetingType,
        meetingTopic,
        meetingEndDateTime: String(meetingEndDateTime),
        liquidityEvent,
        invObjective,
        invLiquidity,
        hubspotMeetingId: String(hubspotMeetingId),
        gvc,
        followupTaskId,
        description,
        clientType,
        updatedBy,
        meetingDateTime,
        createdBy,
        optLocalEconomy,
        createdDate: 0,
        prospectId: "temp",
        rowId: 0,
        updatedDate: 0
      } as meetingNoteApiRespSingle
    }
  }
}

export const deleteNoteById : (noteId: number) => any = async (noteId) => {
  const network: NetworkStatus = await Network.getStatus();
  const info = await Device.getInfo();
  //check for platform - if browser then return response from api else perform dboperations
  if (!(info.platform === "android" || info.platform === "ios")) {
    return api.deleteMeetingNoteAPI(noteId)
  } else {
    const db: SQLiteObject = await SQLite.create(DbConfig);
    //check network connection
    if(network.connected) {//connected
      
      try {
        await api.deleteMeetingNoteAPI(noteId);//call api
        return await db.executeSql(deleteNoteByIdSQL, [noteId]);//execute sqlite delete query for meeting note
      } catch (e) {
        console.error(e)
      }
    } else {//network diconnected
      return await db.executeSql(markForDeletion, [noteId]);//execute markfordel sqlite query for meeting note
    }
  }
}