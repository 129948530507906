import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonButton,
  IonButtons,
  IonIcon,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonAvatar,
  IonLabel,
  IonText,
  IonLoading,
} from "@ionic/react";
import { chevronBack, chevronDown, ellipse } from "ionicons/icons";
import CryptoJS from 'crypto-js';
import { useHistory } from "react-router";
import useWindowDimensions from "src/components/useWindowDimensions";
import api from "src/api";
import { Plugins } from "@capacitor/core";
const { Storage } = Plugins;
//const [isLoading, setLoading] = React.useState(false);

const styles = {
  toolbar: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
  },
  select: {
    "--padding-start": "8px",
    color: "#B99855",
    textAlign: "right",
  },

  grid: {
    marginLeft: 32,
    marginRight: 32,
    marginTop: 32,
  },
  item: {
    "--background": "#222222",
    marginBottom: 2,
    borderRadius: 8,
  },
  desc: {
    fontFamily: "Almarai",
    fontSize: 16,
    color: "#828282",
    marginBottom: 8,
  },
  refnam: {
    fontWeight: 300,
    fontSize: 16,
    paddingLeft:5,
    color: "#828282",
  },
  period: {
    fontSize: 6,
    marginLeft: 4,
    marginRight: 4,
    marginTop: 8,
    color: "#828282",
  },
  label: {
    marginTop: 14.5,
    marginBottom: 14.5,
    
  },
  headertile: {
      fontWeight: 300,
       fontSize: 28 ,
       paddingRight:20, 
       paddingLeft:20,
  },
  title: {
    fontWeight: "700",
  },
};
const options = {
  cssClass: "my-custom-interface",
};
const decrypt = (decryptVlaue) => {
  let passphrase = "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9"
  const bytes = CryptoJS.AES.decrypt(decryptVlaue, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};
const getUserId = async () => {
  const ret: any = await Storage.get({ key: "userId" });
  return JSON.parse(decrypt(ret.value)).id;
};


interface contentarr {
  engName: string;
  arabicname: string;
  avatarpath: string;
  referalnam:string;
  id:number;
}

const ProspectReferral: React.FC<any>= ({ location,match }) => {
  const hist = useHistory();
 
  const[type,settype]=useState(location.state.type);
  const imgbaseuri = "https://cdn2.hubspot.net/";
  const [referralarr,setreferralarr] = useState<Array<contentarr>>([]);
  const [count,setcount] = useState<number>(0);
  let temprefarr:any = [];
  useEffect(() => {
    //api.getDashboardStats('47735531','01-MAR-1990','01-MAR-2021').then(resp => {
        //setLoading(true);
        getUserId().then((userId) => {
            api.getDashboardStats(userId,location.state.ProspStatDate.startdate,location.state.ProspStatDate.enddate,type).then(resp =>{
              //api.getDashboardStats("55058067",location.state.ProspStatDate.startdate,location.state.ProspStatDate.enddate,type).then(resp =>{
                //console.log(resp);
                if(resp.length > 0){
                  setcount(resp.length);
                  let payload: any = {
                    inputs: [],
                    properties: [
                      "first_name_arabic,arabic_last_name,arabic_middle_name,hs_avatar_filemanager_key",
                    ],
                  };

                 resp.forEach((opt,index) => {                   
                    if(opt.prospectId)  
                    {
                      payload.inputs.push({ id: opt.prospectId })
                    }
                  });
                  // console.log(payload);
                
                  api.getTopTargetContacts(payload).then((response) => {
                      //console.log(response.results);                      
                        if(resp)
                        {
                          resp.map((res, key) => {      
                          let arfullname = response.results.filter(
                            (a) => parseInt(res.prospectId)=== parseInt(a.id)
                            )[0].properties;
                            //console.log(arfullname);
                          temprefarr.push({
                            engName: res.prospectMiddleName !== "" && res.prospectMiddleName !== null ? `${res.prospectFirstName} ${res.prospectMiddleName} ${res.prospectLastName}` : `${res.prospectFirstName} ${res.prospectLastName}`,
                            referalnam: res.refMiddleName !== "" && res.refMiddleName !== null ? `${res.refFirstName} ${res.refMiddleName} ${res.refLastName}` : `${res.refFirstName} ${res.refLastName}`,
                             avatarpath:arfullname.hs_avatar_filemanager_key,
                             arabicname:arfullname.arabic_middle_name !== "" && arfullname.arabic_middle_name !== null ? `${arfullname.first_name_arabic} ${arfullname.arabic_middle_name} ${arfullname.arabic_last_name}` : `${arfullname.first_name_arabic} ${arfullname.arabic_last_name}`,
                             id:res.prospectId
                           })
                        })
                      }
                          // console.log(temprefarr);
                          setreferralarr(temprefarr);
                  })
                  
                //     // setdroppedCnt('-');
                //     // setrefCnt('-');
                //     // settimetoConvert('-');
                //     // setdroppedCnt(resp[0].droppedCount?resp[0].droppedCount:'-');
                //     // setrefCnt(resp[0].referrals?resp[0].referrals:'-')
                //     // settimetoConvert(resp[0].avergeDaysToClose?resp[0].avergeDaysToClose:'-')
                    //setLoading(false);
                 }
            })
        })
    },[])

    const history = useHistory()

    function sendToProfile(id): void {
            history.push("/contactinfo/"+id);      
    }  

  const { height, width } = useWindowDimensions();
  return (
    <IonPage>
       {/* <IonLoading
      cssClass="my-custom-loading-class"
      isOpen={isLoading}
      onDidDismiss={() => setLoading(false)}
      message={"Please wait..."}
    /> */}
      <IonHeader>
        <IonToolbar mode="md" style={styles.toolbar}>
          <IonButtons slot="start">
            <IonButton
              style={{ textTransform: "none" }}
              color="white"
              // routerLink="/dashboard"
              onClick={() => {
                hist.push(`/dashboard`, { to: "prospects" });
              }}
            >
              <IonIcon icon={chevronBack}></IonIcon>
              Prospect Dashboard
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent 
      style={{ "--background": "none" }}>
        <IonGrid class="ml-13 mr-13" >
        <IonRow>
            <IonCol
              class="grayscale4 mobile-reim-title fs-28 mt6"
              style={styles.headertile}
              size-xs="8"
              size-sm="9"
              sizeXl="10"
            >
              {count}
              {
                type == "referral"?" Prospects Referrals": " Prospects Dropped"
              }
              
            </IonCol>
          </IonRow>
          <IonRow  >
            <IonCol class="mt-6 margin-set mobile-top-content"
            style={{paddingRight:20,paddingLeft:20}}>
              {referralarr.map((referral, key) => (
                <IonItem
                  className="CardIonItem"
                  style={styles.item}
                  lines="none"
                  key={key}
                >
                  <IonAvatar className="asocAvatar" slot="start">
                    <img
                      src={
                        referral.avatarpath !== "" &&
                        referral.avatarpath !== null
                          ? `${imgbaseuri}${referral.avatarpath}`
                          : "/assets/empty-avatar.svg"
                      }
                      alt=""
                    />
                  </IonAvatar>
                  <IonLabel style={styles.label}>
                    <IonRow>
                      <IonCol size-xs="12"
              size-sm="9">
                        <IonText style={styles.title}>
                          {referral.engName}{" "}
                        </IonText>
                        <IonIcon class= "fs-6" style={styles.period} icon={ellipse}></IonIcon>
                        <IonText style={styles.desc}>
                          {" "}
                          {referral.arabicname}{" "}
                        </IonText>
                      </IonCol>
                      <IonCol 
              size-sm="3"></IonCol>
                    </IonRow>
                    {
                       type == "referral"?
                        <IonRow>
                        <IonText style={styles.refnam}>
                          Refferal: {referral.referalnam} 
                        </IonText>
                      </IonRow>:null
                    }                    
                  </IonLabel>
                  <IonText
                    style={{ cursor: "pointer" }}
                    class="font14 primarygold"
                    cust-id={referral.id}
                    //cust-id="123"
                    onClick={(event) => {
                     sendToProfile((event.target as HTMLInputElement).getAttribute('cust-id'))
                     // sendToProfile((event.target as HTMLInputElement))               
                       }}
                    // routerLink={`/contactinfo/${tcontact.contId}`}
                  >
                    View Profile
                  </IonText>
                </IonItem>
              ))}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ProspectReferral;