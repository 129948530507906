import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonCard,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
  IonSlide,
  IonSlides,
  IonText,
} from "@ionic/react";
import checktodo from "src/icons/checktodo.svg";
import CryptoJS from 'crypto-js';
import "./NextToDos.css";
import api from "src/api";
import moment from "moment";
import { useHistory } from "react-router-dom";
import chevronBackCircle from "src/icons/sliderprev.svg"
import chevronForwardCircle from "src/icons/slidernext.svg"
import { Plugins } from "@capacitor/core";
import useWindowDimensions from "src/components/useWindowDimensions";
const { Storage, Device } = Plugins;

const styles = {
  next5todocard: {
    background: "#1A1A1A",
    marginInlineStart: 0,
    marginInlineEnd: 0,
    marginLeft: 0,
    marginTop: 0,
    marginBottom: 0,
    width: "100%",
  },
  slide: {
    marginTop: 16,
    marginRight: -32,
    marginLeft: -32,
  },
  griditem: {
    width: "100%",
    paddingLeft: 16,
    paddingRight: 16,
  },
  checktodoIcon: {
    width: 36,
    height: 36,
  },
};

function camelize(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toUpperCase() : word.toLowerCase();
    })
    .replace(/\s+/g, "");
}
const decrypt = (decryptVlaue) => {
  let passphrase = "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9"
  const bytes = CryptoJS.AES.decrypt(decryptVlaue, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};
const getUserId = async () => {
  const ret: any = await Storage.get({ key: "userId" });
  const temp = JSON.parse(decrypt(ret.value));
  return temp && temp.id ? temp.id : null;
};

const NextTodos = ((type) => {
  const { height, width } = useWindowDimensions();
  const [deviceInfo, setdeviceInfo] = useState<any>();
  let slidewidth = 400;
  if (width < 576) slidewidth = 380;
  const slideOpts =  deviceInfo && deviceInfo.platform === "web" && width > 1024 ? {
    slidesPerView: 3,
    // cssMode:true,
    spaceBetween: 16,
    simulateTouch: false,
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev',
    navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  }
  }:{
    initialSlide: 0,
    speed: 400,
    // slidesPerView: 2,
    // slidesPerGroup: 3,
    // centeredSlides: true,
    // centeredSlidesBounds: true,
    width: slidewidth,
    height: 150,
    freeMode: true,
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    },
    spaceBetween: 16,
  };

  const [upcomingTodo, setUpcomingTodo] = React.useState<any>([]);
  
  const [actSlide, setactSlide] = useState<any>({
    len: 0,
    ind: 0
  });

  const slideRef = React.createRef<HTMLIonSlidesElement>();

  useEffect(() => {
    Device.getInfo().then(info => {
      setdeviceInfo(info)
    })
    getUserId().then((userId) => {
      api.GetToDo(userId,type.type).then((data) => {
        let manipResp: Array<any> = []
        setactSlide({
          len: data.length,
          ind: 0
        })
        let temp = data
          .filter(
            (r) =>
              moment(r.activityDate).format("x") >=
              moment(new Date()).format("x")
          )
          .sort((a, b) => {
            return moment(a.activityDate).isAfter(moment(b.activityDate))
              ? 1
              : -1;
          })
          .filter((obj, i) => i < 5);

        let contactPayload: any = {
          inputs: [],
          properties: ["email,phone,firstname,lastname"],
        };

        temp.forEach((r) => {
          contactPayload.inputs.push({
            id: r.associationContactIds ? r.associationContactIds : 0,
          });
        });

        api
          .getTopTargetContacts(contactPayload)
          .then((batchresp) => {
            if (batchresp.results && batchresp.results.length > 0) {
              manipResp = temp.map((respInd) => {
                return {
                  ...respInd,
                  contProps: batchresp.results.filter(
                    (o) => o.id === respInd.associationContactIds
                  )[0],
                };
              });
            } else {
              manipResp = [...temp];
            }
            setUpcomingTodo(manipResp);
          })
          .catch((e) => {
            manipResp = [...temp];
            setUpcomingTodo(manipResp);
            console.log(e);
          });
      });
    });
  }, []);

  return upcomingTodo.length > 0 ? (
    <div className="temp-cont">
      <IonSlides ref={slideRef} options={slideOpts} class="slides-mobile" style={styles.slide}
        className="slider-nextodo"
        onIonSlideDidChange={(e) => {
          slideRef.current?.getActiveIndex().then((ind) => {
            slideRef.current?.length().then(len => {
              setactSlide({
                len,
                ind
              })
            })
          })
        }}
      >
        {upcomingTodo.map((val, i) => (
          <IonSlide key={i}>
            <Card detail={val} />
          </IonSlide>
        ))}
      </IonSlides>
      {
         deviceInfo && deviceInfo.platform === "web" && width > 1024 ? 
          <div className="next-prev-cont-nextodo">
          {
            actSlide.len > 0 && actSlide.ind === 0 ? null :
            <IonButton
            className="swiper-button-prev-upsch"
            fill="clear"
            onClick={() => {
              slideRef.current?.slidePrev()
            }}
            >
              <IonIcon 
                icon={chevronBackCircle}
                color="gold"
                />
            </IonButton>
          }
          {
          actSlide.len > 0 && actSlide.ind + 3 >= actSlide.len ? null :
          <IonButton
            className="swiper-button-next-upsch"
            fill="clear"
            onClick={() => {
              slideRef.current?.slideNext()
            }}
            >
            <IonIcon
              color="gold"
              icon={chevronForwardCircle}
              />
          </IonButton>
          }
        </div>
        : null
      }
    </div>
  ) : (
    <IonRow>
      <IonLabel color="greyscale">No data found</IonLabel>
    </IonRow>
  );
});

const Card = ({ detail }) => {
  const history = useHistory();

  return (
    <IonCard style={styles.next5todocard} class="next5Icon">
      <IonGrid>
        <IonRow>
          <IonItem lines="full" style={styles.griditem}>
            <IonIcon style={styles.checktodoIcon} icon={checktodo} />
            <IonLabel class="ion-padding">
              <p
                className="marginbottom5 lineheight120 white boldText"
                onClick={() => {
                  history.push(
                    `/view/todo/${detail.associationContactIds}/${detail.engagementId}`
                  );
                }}
              >
                {detail.metaDataSubject ? detail.metaDataSubject : "-"}
              </p>
              <p className="marginbottom5 lineheight120 font14 grayscale5">
                {detail.contactMiddleName !== "" &&
                detail.contactMiddleName !== null
                  ? `${detail.contactFirstName} ${detail.contactMiddleName} ${detail.contactLastName}`
                  : `${detail.contactFirstName} ${detail.contactLastName}`}
              </p>
              {/* <p className="lineheight120 font12 grayscale5">
                Priority:{" "}
                {detail.taskPriority ? detail.taskPriority : "-"}{" "}
                | Type: {detail.type ? detail.type : "-"}
              </p> */}
              <p className="lineheight120 font12 grayscale5">
                {// `Due :${moment(detail.activityDate).format("ddd, MMM DD, YYYY")}`
                `Priority ${camelize(
                  detail.metaDataPriority
                )} | Type: ${camelize(detail.metaDataTaskType)}`}
              </p>
            </IonLabel>
          </IonItem>
        </IonRow>
        <IonRow>
          <IonButton fill="clear" color="gold" size="small" mode="ios">
            {/*{detail.type === "EMAIL" ?
              <a className="todoanchor" href={"mailto:" + detail.contactemail}>Email</a> :
              detail.type === "CALL" ?
                <a className="todoanchor" href={"tel:" + detail.contactcall}>Call</a> :
                'Schedule'
            }*/}
            {detail.metaDataTaskType === "EMAIL" &&
            detail.contProps &&
            detail.contProps.properties ? (
              <a
                className="todoanchor"
                href={"mailto:" + detail.contProps.properties.email}
              >
                Email
              </a>
            ) : detail.metaDataTaskType === "CALL" &&
              detail.contProps.properties ? (
              <a
                className="todoanchor"
                href={"tel:" + detail.contProps.properties.phone}
              >
                Call
              </a>
            ) : (
              <IonText
                onClick={() => {
                  history.push(`/newevent/${detail.contProps.id}`, {
                    contactName: `${detail.contProps.properties.firstname} ${detail.contProps.properties.lastname}`,
                    from: "Dashboard",
                    contId: detail.contProps.id,
                  });
                }}
              >
                Schedule
              </IonText>
            )}
          </IonButton>
        </IonRow>
      </IonGrid>
    </IonCard>
  );
};

export default NextTodos;
