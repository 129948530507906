import {
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonRow,
  IonText,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import saudiarabia from "src/icons/saudiarabia.svg";
import CryptoJS from 'crypto-js';
import kuwait from "src/icons/kuwait.svg";
import world from "src/icons/world.svg";
import bahrain from "src/icons/bahrain.svg";
import { Plugins } from "@capacitor/core";
import api from "src/api";
// import api from "src/api";
const { Storage } = Plugins;

const styles = {
  item: {
    "--background": "#222222",
    marginBottom: 2,
    borderRadius: 8,
    width: "100%",
    marginRight: 32,
  },
  countryname: {
    fontWeight: 400,
    fontSize: 14,
    color: "#828282",
  },
  provincename: {
    fontWeight: 400,
    fontSize: 12,
    color: "#FFFFFF",
    lineHeight: "120%",
  },
  provincecount: {
    fontWeight: 400,
    fontSize: 12,
    color: "#828282",
    float: "right",
    marginRight: 32,
  },
  provinceptg: {
    fontWeight: 400,
    fontSize: 18,
    color: "#FFFFFF",
    float: "right",
  },
};

const decrypt = (decryptVlaue) => {
  let passphrase = "eyJpZCI6MTA5OTA3ODczLCJmTmFtZSI6Ik1hdmVyaWMiLCJsTmFtZSI6IlRGT0NPIiwiZW1haWwiOiJtYXZlcmljMUB0Zm9jby5jb20iLCJ0ZWFtcyI6IiJ9"
  const bytes = CryptoJS.AES.decrypt(decryptVlaue, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};
const getUserId = async () => {
  const ret: any = await Storage.get({ key: "userId" });
  return JSON.parse(decrypt(ret.value)).id;
};

const ProspectGeo: React.FC<any> = ({countryarray,type,countrydatesarr}) => {
  const [saudiCnt, setsaudiCnt] = useState(0);
  const [kuwaitCnt, setkuwaitCnt] = useState(0);
  const [bahrainCnt, setbahrainCnt] = useState(0);
  const [worldCnt, setworldCnt] = useState(0);
  const [isLoading, setLoading] = React.useState(false);
  //const countryarr = countryarray.countryarr;
  const [countryarr,setcountryarr]=useState<any>([]);
  const [totalCntryCnt, settotalCntryCnt] = useState(0);
  const [saudistatesarray, setsaudistatesarray] = useState<any>([]);
  const [worldstatesarray, setworldstatesarray] = useState<any>([]);
  const[ProspCntryDate,setProspCntryDate] = useState(countrydatesarr);
  
//const countryarr = countryarray?countryarray.countryarr:[];
  useEffect(() => {
    //api.GetDashboardCountry(userId,ProspCntryDate.startdate,ProspCntryDate.enddate).then(resp => {
    setLoading(true);
    getUserId().then((userId) => {
      api.GetDashboardCountry(userId,ProspCntryDate.startdate,ProspCntryDate.enddate, type).then(resp => {        
        setcountryarr(resp);
        let tempcnt: number = 0;
        let tempothercnt: number = 0;
        let tempsaudicnt: number = 0;
        let tempbahraincnt: number = 0;
        let tempkuwaitcnt: number = 0;
        let saudistatearr: any = [];
        let worldstatearr: any = [];
        
        setsaudiCnt(0);
        setkuwaitCnt(0);
        setbahrainCnt(0);
        setworldCnt(0);
        if (resp) {
          resp.map((obj) => {
            if (obj.country === "Saudi Arabia") {
              tempsaudicnt = tempsaudicnt + parseInt(obj.count);
              saudistatearr.push(obj);
            } else if (obj.country === "Kuwait") {
              tempkuwaitcnt = tempkuwaitcnt + parseInt(obj.count);
            } else if (obj.country === "Bahrain") {
              tempbahraincnt = tempbahraincnt + parseInt(obj.count);
            } else {
              tempothercnt = tempothercnt + parseInt(obj.count);
              worldstatearr.push(obj);
            }
            tempcnt = tempcnt + parseInt(obj.count);
          });
        }
        setsaudiCnt(tempsaudicnt);
        setbahrainCnt(tempbahraincnt);
        setkuwaitCnt(tempkuwaitcnt);
        setworldCnt(tempothercnt);
        settotalCntryCnt(tempcnt);
        setsaudistatesarray(saudistatearr);
        setworldstatesarray(worldstatearr);
        setLoading(false);
      })
    })
    //api.GetDashboardCountry('47735531','01-MAR-1990','01-MAR-2021').then(resp => {
     
    
    
    //})
  }, [type]);

  return (
    <React.Fragment>
      <IonGrid>
        <IonRow
          class="mobile-geo-margin"
          style={{ alignItems: "center", matginTop: 25, marginBottom: 63 }}
        >
          <IonCol size="2" sizeXl="1" style={{ textAlign: "right", paddingRight: 16 }}>
            <IonIcon
              style={{ height: 50, width: 50, matginTop: 8 }}
              icon={saudiarabia}
            />
          </IonCol>
          <IonCol size="4" style={{ textAlign: "left" }}>
            <IonText style={{ fontSize: 43, fontWeight: 300 }}>
              {saudiCnt + kuwaitCnt + bahrainCnt + worldCnt === 0
                ? 0
                : ((saudiCnt * 100) / totalCntryCnt).toFixed(1)}
              %
            </IonText>
            <br />
            <IonText style={styles.countryname}>Saudi Arabia</IonText>
          </IonCol>

          <IonCol size-xs="12" pushXl="1" size-sm="6">
            {saudistatesarray &&
            saudistatesarray !== null &&
            saudistatesarray.length > 0 ? (
              saudistatesarray.map((obj, i) => (
                <IonRow key={i}>
                  <IonItem lines="none" style={styles.item}>
                    <IonCol size="7">
                      <IonText style={styles.provincename}>
                        {obj.state ? obj.state : "Saudi Arabia"}
                      </IonText>
                    </IonCol>

                    <IonCol size="3">
                      <IonText style={styles.provincecount}>
                        ({obj.count})
                      </IonText>
                    </IonCol>
                    <IonCol size="2">
                      <IonText style={styles.provinceptg}>
                        {saudiCnt + kuwaitCnt + bahrainCnt + worldCnt === 0
                          ? 0
                          : ((obj.count * 100) / totalCntryCnt).toFixed(1)}
                        %
                      </IonText>
                    </IonCol>
                  </IonItem>
                </IonRow>
              ))
            ) : (
              <IonCol size="6">
                <IonRow>
                  <IonItem lines="none" style={styles.item}>
                    <IonCol size="7">
                      <IonText style={styles.provincename}>
                        Saudi Arabia
                      </IonText>
                    </IonCol>

                    <IonCol size="3">
                      <IonText style={styles.provincecount}>(0)</IonText>
                    </IonCol>
                    <IonCol size="2">
                      <IonText style={styles.provinceptg}>0%</IonText>
                    </IonCol>
                  </IonItem>
                </IonRow>
              </IonCol>
            )}
          </IonCol>
        </IonRow>
        <IonRow
          class="mobile-geo-margin"
          style={{ alignItems: "center", matginTop: 25, marginBottom: 63 }}
        >
          <IonCol size="2" sizeXl="1" style={{ textAlign: "right", paddingRight: 16 }}>
            <IonIcon
              style={{ height: 50, width: 50, matginTop: 8 }}
              icon={bahrain}
            />
          </IonCol>
          <IonCol size="4" style={{ textAlign: "left" }}>
            <IonText style={{ fontSize: 43, fontWeight: 300 }}>
              {saudiCnt + kuwaitCnt + bahrainCnt + worldCnt === 0
                ? 0
                : ((bahrainCnt * 100) / totalCntryCnt).toFixed(1)}
              %
            </IonText>
            <br />
            <IonText style={styles.countryname}>Bahrain</IonText>
          </IonCol>

          <IonCol size-xs="12" pushXl="1" size-sm="6">
            <IonRow>
              <IonItem lines="none" style={styles.item}>
                <IonCol size="7">
                  <IonText style={styles.provincename}>Bahrain</IonText>
                </IonCol>

                <IonCol size="3">
                  <IonText style={styles.provincecount}>({bahrainCnt})</IonText>
                </IonCol>
                <IonCol size="2">
                  <IonText style={styles.provinceptg}>
                    {saudiCnt + kuwaitCnt + bahrainCnt + worldCnt === 0
                      ? 0
                      : ((bahrainCnt * 100) / totalCntryCnt).toFixed(1)}
                    %
                  </IonText>
                </IonCol>
              </IonItem>
            </IonRow>
          </IonCol>
        </IonRow>
        <IonRow
          class="mobile-geo-margin"
          style={{ alignItems: "center", matginTop: 25, marginBottom: 63 }}
        >
          <IonCol size="2" sizeXl="1" style={{ textAlign: "right", paddingRight: 16 }}>
            <IonIcon
              style={{ height: 50, width: 50, matginTop: 8 }}
              icon={kuwait}
            />
          </IonCol>
          <IonCol size="4" style={{ textAlign: "left" }}>
            <IonText style={{ fontSize: 43, fontWeight: 300 }}>
              {saudiCnt + kuwaitCnt + bahrainCnt + worldCnt === 0
                ? 0
                : ((kuwaitCnt * 100) / totalCntryCnt).toFixed(1)}
              %
            </IonText>
            <br />
            <IonText style={styles.countryname}>Kuwait</IonText>
          </IonCol>

          <IonCol size-xs="12" pushXl="1" size-sm="6">
            <IonRow>
              <IonItem lines="none" style={styles.item}>
                <IonCol size="7">
                  <IonText style={styles.provincename}>Kuwait</IonText>
                </IonCol>
                <IonCol size="3">
                  <IonText style={styles.provincecount}>({kuwaitCnt})</IonText>
                </IonCol>
                <IonCol size="2">
                  <IonText style={styles.provinceptg}>
                    {saudiCnt + kuwaitCnt + bahrainCnt + worldCnt === 0
                      ? 0
                      : ((kuwaitCnt * 100) / totalCntryCnt).toFixed(1)}
                    %
                  </IonText>
                </IonCol>
              </IonItem>
            </IonRow>
          </IonCol>
        </IonRow>
        <IonRow
          class="mobile-geo-margin"
          style={{ alignItems: "center", matginTop: 25, marginBottom: 63 }}
        >
          <IonCol size="2" sizeXl="1" style={{ textAlign: "right", paddingRight: 16 }}>
            <IonIcon
              style={{ height: 50, width: 50, matginTop: 8 }}
              icon={world}
            />
          </IonCol>
          <IonCol size="4" style={{ textAlign: "left" }}>
            <IonText style={{ fontSize: 43, fontWeight: 300 }}>
              {saudiCnt + kuwaitCnt + bahrainCnt + worldCnt === 0
                ? 0
                : ((worldCnt * 100) / totalCntryCnt).toFixed(1)}
              %
            </IonText>
            <br />
            <IonText style={styles.countryname}>World</IonText>
          </IonCol>

          <IonCol size-xs="12" pushXl="1" size-sm="6">
            {worldstatesarray &&
            worldstatesarray !== null &&
            worldstatesarray.length > 0 ? (
              worldstatesarray.map((val, i) => (
                <IonRow key={i}>
                  <IonItem lines="none" style={styles.item}>
                    <IonCol size="7">
                      <IonText style={styles.provincename}>
                        {val.country ? val.country : "-"} 
                        {val.state ? "- "+val.state : ""}
                      </IonText>
                    </IonCol>
                    <IonCol size="3">
                      <IonText style={styles.provincecount}>
                        ({val.count})
                      </IonText>
                    </IonCol>
                    <IonCol size="2">
                      <IonText style={styles.provinceptg}>
                        {totalCntryCnt === 0
                          ? 0
                          : ((val.count * 100) / totalCntryCnt).toFixed(1)}
                        %
                      </IonText>
                    </IonCol>
                  </IonItem>
                </IonRow>
              ))
            ) : (
              <IonCol size="6">
                <IonRow>
                  <IonItem lines="none" style={styles.item}>
                    <IonCol size="7">
                      <IonText style={styles.provincename}>World</IonText>
                    </IonCol>
                    <IonCol size="3">
                      <IonText style={styles.provincecount}>(0)</IonText>
                    </IonCol>
                    <IonCol size="2">
                      <IonText style={styles.provinceptg}>0%</IonText>
                    </IonCol>
                  </IonItem>
                </IonRow>
              </IonCol>
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
    </React.Fragment>
  );
};

export default ProspectGeo;
